import React from 'react';

import { Grid } from '@material-ui/core';

const withGrid = params => WrappedComponent => props => (
  <Grid item {...params}>
    <WrappedComponent {...props} />
  </Grid>
);

export default withGrid;
