/* eslint-disable indent */
import React from 'react';
import { Link } from 'react-router-dom';
import { moment } from '@bit/dev-lba.lib.local-globals/moment';
import {
  data as transacStatus,
  T_REGLE,
} from '@bit/dev-lba.lib.local-globals/transactionStatus';
import CustomSelect from '../CustomInputs/CustomSelect';
import { MenuItem } from '@material-ui/core';
import DatePicker from '../DatePicker';

export const columns = () => [
  {
    title: 'Id',
    render: (data) => (
      <Link
        to={`/${
          !isNaN(data ? data.id : '') ? 'intervention' : 'savIntervention'
        }/${data ? data.id : ''}`}
        target="_blank"
        onClick={(event) => {
          event.preventDefault();
          window.open(
            `/${
              !isNaN(data ? data.id : '') ? 'intervention' : 'savIntervention'
            }/${data ? data.id : ''}`
          );
        }}
      >
        {data ? data.id : ''}
      </Link>
    ),
  },
  {
    title: 'Date d\'ajout',
    field: 'date.ajout',
    render: (data) =>
      data ? moment(data.date && data.date.ajout).format('LLL') : '',
    editable: 'never',
  },
  {
    title: 'Montant',
    field: 'Montant',
    render: (data) => (data && data.montant ? `${data.montant} €` : ''),
    editable: 'never',
  },
  {
    field: 'status',
    title: 'Status',
    render: (data) =>
      data ? transacStatus.find((e) => e._id === data.status).name : '',
    editComponent: (props) => (
      <CustomSelect
        path={['']}
        disabled={props.rowData.status === T_REGLE}
        value={props.rowData.status}
        setData={(p, v) => props.onChange(v)}
        fullWidth
      >
        {transacStatus.map((e) => (
          <MenuItem key={e} value={e._id}>
            {e.name}
          </MenuItem>
        ))}
      </CustomSelect>
    ),
  },
  {
    field: 'date.transaction',
    title: 'Date de transaction',
    render: (data) =>
      data ? moment(data.date && data.date.transaction).format('LLL') : '',
    editComponent: (props) => (
      <DatePicker
        classNamesDate={{
          overlay: { position: 'relative' },
        }}
        disabled={props.rowData.status === T_REGLE}
        startDate={props && new Date(props.rowData.date.transaction)}
        onChange={(v) => props.onChange(v.toISOString())}
      />
    ),
  },
];
