import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Close, Done } from '@material-ui/icons';
import green from '@material-ui/core/colors/green';
import { DOCVAL, DOCREF } from '@bit/dev-lba.lib.local-globals/docStatus';
import stopPropagation from '../../utils/stopPropagation';

const styles = {
  container: {
    position: 'absolute',
    zIndex: 3,
    top: 5,
    right: 10,
    left: 0,
    display: 'flex',
    justifyContent: 'flex-end'
  }
};


const ValidationButton = ({ classes,
  sendStatus, fileName, loading }) =>
  loading ? (
    <div className={classes.container}>
      <CircularProgress size={30} />
    </div>
  ) : (
    <div className={classes.container}>
      <Fab
        size='small'
        color="primary"
        style={{ backgroundColor: green[500], marginRight: 5 }}
        onClick={stopPropagation(() => sendStatus(fileName, DOCVAL))}
      >
        <Done />
      </Fab>
      <Fab
        size='small'
        color="secondary"
        onClick={stopPropagation(() => sendStatus(fileName, DOCREF))}
      >
        <Close />
      </Fab>
    </div>
  );

export default withStyles(styles)(ValidationButton);

