import { List } from 'immutable';
import api from '../api';
import notifSystem from '../notifSystem';
import store from '../store';
import { setDialog } from './dialog';

export const getStatData = ({ from, to, mode, user }) =>
  Promise.all([
    api.stats.get(`quality/notation/global/${user}`, {
      from,
      to,
      mode,
    }),
    api.stats.get(`quality/notation/${user}`, {
      from,
      to,
    }),
    api.stats.get(`quality/notation/objectifs/${user}`, {
      from,
    }),
  ])
    .then(([res1, res2, res3]) => ({
      global: res1.body().data().data,
      counterData: res2.body().data().counters,
      achievedGoals: res3.body().data().result[0] || {
        day: 0,
        week: 0,
        month: 0,
      },
    }))
    .catch(() => {
      notifSystem.error('Echec', 'Une erreur s\'est produite');
    });

const validateObjectif = ({ user, newGoals }) =>
  api.stats.custom('commercial/changePersonalObjectif').post({
    goals: newGoals,
    _id: user
  }).then(() => {
    notifSystem.success(
      'Succès',
      'Les paramètres d\'objectifs ont bien été mis à jour'
    );
  }).catch(() => notifSystem.error('Echec', 'Une erreur s\'est produite'));


export const objectifDialog = ({ setData, users, newGoals }) => {
  store.dispatch(
    setDialog({
      name: 'ObjectifQualityDialog',
      open: true,
      dialogProps: {
        title: 'Objectifs personnels',
      },
      contentProps: {
        users,
        newGoals,
        user: List.isList(users) && (users.first() || {})._id
      },
      actions: [
        {
          children: 'Enregistrer',
          color: 'primary',
          onClick: (data, close) => {
            validateObjectif(data).then(() => {
              setData('newGoals', data.newGoals);
              close();
            });
          }
        }
      ]
    })
  );
};

export const sendReviewDemand = ({ interId, updateData, email }) =>
  api
    .one('interventions', interId)
    .custom('sendNotationRequest')
    .post({ email })
    .then(() => {
      updateData(['date', 'sendDemandNote'], new Date());
      notifSystem.success(
        'Opération réussie',
        'La demande a bien été envoyée'
      );
    })
    .catch(() =>
      notifSystem.error('Erreur', 'la demande n\'a pas pu être envoyée')
    );

export const sendAvisVerifies = ({
  interId, email, firstName, lastName, date
}) => api
  .interventions
  .custom('sendAvisVerifies')
  .post({
    interId,
    email,
    firstName: firstName.trim(),
    lastName: lastName.trim(),
    date
  })
  .then(() =>
    notifSystem.success(
      'Avis Vérifiés',
      'Email envoyé vers le client pour avoir son avis'
    )
  )
  .catch(e =>
    notifSystem.error('Erreur',
      e.response ? e.response.data : 'l\'email n\'a pas pu être envoyé')
  );

export const sendAvisTrustpilot = ({
  interId, email, firstName, lastName
}) => api
  .interventions
  .custom('sendAvisTrustpilot')
  .post({
    interId,
    email,
    firstName,
    lastName
  })
  .then(() =>
    notifSystem.success(
      'Avis Trustpilot',
      'Email envoyé vers le client pour avoir son avis'
    )
  )
  .catch(() =>
    notifSystem.error('Erreur', 'l\'email n\'a pas pu être envoyé')
  );

export const verifNotationDialog = (notation, cb = e => e) =>
  store.dispatch(
    setDialog({
      name: 'CustomDialog',
      open: true,
      hideClose: true,
      dialogProps: {
        title: `Attention ${
          (notation.getIn(['client', 'note']) || 0) < 5 ?
            'la note est inferieure a 5' : ''
        }` +
          `${notation.get('hasNotAnswered') ?
            'le client n\'a pas répondu' : ''}, voulez-vous vraiment ` +
        'envoyer une invitation.',
        middle: true, middleAll: true
      },
      contentProps: {
        content: null,
        boxProps: {
          width: '100%'
        }
      },
      actions: [
        {
          children: 'Non',
          color: 'secondary',
          onClick: (data, close) => {
            close();
          }
        },
        {
          children: 'Oui',
          color: 'primary',
          onClick: (data, close) => {
            if (cb) {
              cb();
            }
            close();
          }
        }
      ]
    })
  );
