import React from 'react';

import {
  NOT_CONFIRMED, CHIEF
} from '@bit/dev-lba.lib.local-globals/permissions';
import componentLoader from '../../componentLoader';

const BtoBCampaign = React.lazy(() =>
  componentLoader(() => import('../../views/BtoBCampaign'))
);
const BtoBCSV = React.lazy(() =>
  componentLoader(() => import('../../views/BtoBCSV'))
);
const DemarcheBtoB = React.lazy(() =>
  componentLoader(() => import('../../components/DemarcheBtoB'))
);
const ListBtoB = React.lazy(() =>
  componentLoader(() => import('../../views/List/ListBtoB'))
);
const AjoutBtoB = React.lazy(() =>
  componentLoader(() => import('../../views/AjoutBtoB'))
);

export default [
  {
    path: '/btob/demarche',
    component: DemarcheBtoB,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/btob/csv',
    component: BtoBCSV,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/btob/campaign',
    component: BtoBCampaign,
    restrictions: () => CHIEF,
  },
  {
    path: '/btob/list/:filter/:query',
    component: ListBtoB,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/btob/list/:filter',
    component: ListBtoB,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/btob/list',
    component: ListBtoB,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/btob/:id',
    component: AjoutBtoB,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/btob',
    component: AjoutBtoB,
    restrictions: () => NOT_CONFIRMED,
  },
];
