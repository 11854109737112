import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import CustomSelect from '../CustomInputs/CustomSelect';

const paths = {
  civility: ['civility'],
};

class Civility extends PureComponent {

  render() {
    const { civility, setData } = this.props;
    return (
      <CustomSelect grid xs={12} sm={6} md={3} lg={3} xl={2}
        path={paths.civility}
        value={civility}
        label='Civilité'
        setData={setData}
        id='civilite'
        requiredText={true}
      >
        <MenuItem value={'Monsieur'}>Monsieur</MenuItem>
        <MenuItem value={'Madame'}>Madame</MenuItem>
      </CustomSelect>
    );
  }
}

Civility.propTypes = {
  civility: PropTypes.string.isRequired,
  setData: PropTypes.func.isRequired
};

Civility.defaultProps = {
  civility: ''
};

export default Civility;
