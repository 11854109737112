import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import categories from '@bit/dev-lba.lib.local-globals/iStatus';

const styles = theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  }
});

class SelectDisplayStatus extends PureComponent {
  render() {
    const { classes, status } = this.props;
    return (
      <FormControl className={classes.formControl}>
        <InputLabel style={{ fontSize: 30, fontWeight: 'bold' }}>
        Status</InputLabel>
        <Select
          value={status}
          onChange={this.props.handleChangeStatus}
          inputProps={{
            name: 'mode',
            id: 'modeId',
          }}
          style={{ marginTop: 35 }}
        >
          {categories.data.map(e =>
            <MenuItem key={e._id} value={e._id}>{e.name}</MenuItem>)}
        </Select>
      </FormControl>
    );
  }
}

SelectDisplayStatus.propTypes = {
  classes: PropTypes.object.isRequired,
  handleChangeStatus: PropTypes.func.isRequired,
  status: PropTypes.number.isRequired
};

export default withStyles(styles)(SelectDisplayStatus);
