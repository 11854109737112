import React from 'react';
import {
  FormControlLabel,
  Typography,
  Box,
  Checkbox
} from '@material-ui/core';
import { setDialogContentProp } from '../../actions/dialog';
import { connect } from 'react-redux';
import { compose } from 'recompose';

const text = [
  'Vous êtes sur le point d\'accéder à une liste réservé ' +
      'au chef de zone.',
  'Dans le cas, où vous n\'avez plus d\'intervention ' +
      'à démarcher dans votre market, ' +
      'merci de vous référer à ce dernier.',
  'Vous ne pouvez accéder au pré-market ' +
      'uniquement dans les cas suivants :',
  ' - Mon responsable n\'est pas là aujourd\'hui',
  ' - Mon responsable m\'a demandé exceptionnellement ' +
      'd\'aller dans le pré-market',
  ' - Mon responsable n\'est pas en poste'
];
const setAccepted = setDialogContentProp('accepted');

const mapStateToProps = ({
  dialog: {
    contentProps: {
      accepted,
    },
  },
}) => ({
  accepted,
});

const mapDispatchToProps = { setAccepted };

const PreMarketWarnDialog = ({ setAccepted, accepted }) => (
  <Box>
    {text.map((e, i) =>
      <Typography
        key={i}
        variant='subtitle1'
        children={e}
      />
    )}
    <FormControlLabel
      style={{ marginTop: 10 }}
      control={<Checkbox
        checked={accepted}
        onChange={e => setAccepted(e.target.checked)}
      />}
      label={
        <Typography
          variant='subtitle1'
          children={'J\'atteste sur l\'honneur que ' +
        'je suis dans l\'une de ces situations'}
        />
      }
    />
  </Box>
);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(PreMarketWarnDialog);
