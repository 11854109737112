import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import CustomPhoneInput from '../CustomInputs/CustomPhoneInput';

const paths = {
  tel1: ['tel1'],
  tel2: ['tel2'],
  tel3: ['tel3']
};

class Tels extends PureComponent {
  render() {
    const {
      setData,
      tel1,
      tel2,
      tel3
    } = this.props;
    return (
      <Grid container key="1" spacing={3}>
        <CustomPhoneInput grid xs={12} sm={4} lg={3} capsLocked
          label="Téléphone 1"
          value={tel1}
          path={paths.tel1}
          setData={setData}
          searchPhone
        />
        <CustomPhoneInput grid xs={12} sm={4} lg={3} capsLocked
          label="Téléphone 2"
          value={tel2}
          path={paths.tel2}
          setData={setData}
        />
        <CustomPhoneInput grid xs={12} sm={4} lg={3} capsLocked
          label="Téléphone 3"
          value={tel3}
          path={paths.tel3}
          setData={setData}
        />
      </Grid>
    );
  }
}

Tels.propTypes = {
  tel1: PropTypes.string.isRequired,
  tel2: PropTypes.string.isRequired,
  tel3: PropTypes.string.isRequired,
  setData: PropTypes.func.isRequired
};

Tels.defaultProps = {
  tel1: '',
  tel2: '',
  tel3: ''
};

export default Tels;
