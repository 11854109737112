import {
  P_NOT,
  P_ENC,
  P_PAY,
  P_DET,
  P_CAU,
  P_VRF,
  P_FOU,
  P_MAJ,
} from '@bit/dev-lba.lib.local-globals/paidStatus';
import {
  SAV_CLOT,
  SAV_REF
} from '@bit/dev-lba.lib.local-globals/SAVStatus';
import { I_VRF } from '@bit/dev-lba.lib.local-globals/iStatus';

export default collection => ({
  listInterventionApayer: collection === 'interventions'
    ? ({
      title: 'Liste intervention à payer',
      paidStatus: [P_ENC, P_CAU, P_VRF, P_MAJ],
      match: {
        $and: [
          {
            $or: [
              { paid: { $exists: false } },
              { paid: { $nin: [P_PAY, P_DET, P_NOT] } },
            ],
          },
          {
            $or: [
              { isRegle: true },
              {
                $and: [
                  { isRegle: { $ne: true } },
                  { onSitePayment: true },
                  { 'date.ajout': { $gt: new Date(2019, 0, 16) } },
                ],
              },
            ],
          },
        ],
        status: I_VRF,
        artisan: { $exists: true },
      },
    }) : ({
      title: 'SAV Interventions à payer',
      paidStatus: [P_ENC, P_CAU, P_VRF, P_MAJ],
      match: {
        artisan: { $exists: true },
        status: { $in: [SAV_CLOT, SAV_REF] },
        $and: [
          {
            $or: [
              { paid: { $exists: false } },
              { paid: { $nin: [P_PAY, P_DET, P_NOT] } },
            ],
          },
          {
            paid: { $ne: P_PAY },
            'infoDescSAV.SAVRemun': true,
            'infoDescSAV.SAVRemunPrice': { $gt: 0 }
          }
        ]

      },
    }),
  relFour: {
    title: 'Relance Fournitures',
    paidStatus: [P_FOU],
    match: {
      $and: [
        {
          $or: [{ paid: P_FOU }, { firstPaid: P_FOU }],
        },
      ],
      artisan: { $exists: true },
    },
  },
});
