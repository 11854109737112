import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Map } from 'immutable';
import { compose } from 'redux';
import { connect } from 'react-redux';

import {
  DialogTitle,
  Button,
  Grid,
  FormControlLabel,
  Switch,
  CircularProgress,
} from '@material-ui/core';

import AdvanceContentPercent from './AdvanceContentPercent';
import AdvanceContentAmount from './AdvanceContentAmount';

import MultiPayments from './MultiPayments';
import PaymentDeferred from './PaymentDeferred';
import TransactionSended from './TransactionSended';
import {
  permit
} from '@bit/dev-lba.lib.local-globals/restrictions';
import {
  HEADCHIEF,
} from '@bit/dev-lba.lib.local-globals/permissions';

import {
  L_ADVD,
  data,
} from '@bit/dev-lba.lib.local-globals/paymentCBActionTypes';
import {
  paymentCBActionTypes
} from '@bit/dev-lba.lib.local-globals/getDataById';
import PaymentContent from './PaymentContent';
import {
  divideCurrencyEvenly
} from '@bit/dev-lba.lib.local-globals/comptaCalcules';
import { moment } from '@bit/dev-lba.lib.local-globals/moment';
import {
  defaultMailText
} from '@bit/dev-lba.lib.local-globals/AdvPaymentConstants';
import Money from '../../../../utils/Money';
import { WHATSUP } from '@bit/dev-lba.lib.local-globals/smsMode';
import MultiOney from './MultiOney';
import MultiAlma from './MultiAlma';

const styles = {
  buttonDiv: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  container: {
    width: '100%'
  },
  box: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  switchRight: {
    textAlign: 'right'
  },
  dialogTitle: {
    padding: '0 0 16px',
    width: '100%'
  }
};
const dataPaymentActions = data
  .filter((e) => e.title);

const getButtons = (handleType, props) =>
  dataPaymentActions.map((elem, id) => [
    <Grid item xs={elem.width || 6} lg={elem.width || 6}>
      <Button
        fullWidth
        key={id}
        children={elem.name}
        startIcon={elem.Icon ? <elem.Icon /> : null}
        variant="contained"
        size="large"
        color={elem.color}
        disabled={elem.display(props)}
        onClick={() => handleType(elem._id, elem.typeId)}
      />
    </Grid>,
  ]);

const component = (dialogComponent, props) =>
  [
    <AdvanceContentPercent {...props} />,
    <AdvanceContentAmount {...props} />,
    <PaymentContent {...props} />,
    <MultiOney {...props} />,
    <MultiPayments {...props} />,
    <PaymentDeferred {...props} />,
    <MultiAlma {...props} />,
  ][dialogComponent - 1];

const initState = ({ advancePayment, user }) => ({
  dialogType: null,
  dialogComponent: null,
  noSecure: false,
  date: null,
  mode: WHATSUP,
  activeStep: 0,
  paymentDate: new Date(),
  payments: [],
  loading: false,
  advancePayment: advancePayment || new Map(),
  userId: user._id
});

const mapStateToProps = ({ users, userId }) => ({
  users,
  user: users.find(u => u._id === userId)
});

class Content extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...initState(props),
      pass: !!props.transaction.login,
    };
  }

  handleType = (dialogType, dialogComponent) => {
    this.setState({ dialogType, dialogComponent }, () =>
      this.props.setPaymentState(this.state));
  };

  setPourcent = (path, v) => {
    const otherPaymentType = path[1] === 'pourcent'
      ? 'montant'
      : 'pourcent';
    this.setState(({ advancePayment }) => ({
      advancePayment: advancePayment.merge({
        [path[1]]: v,
        [otherPaymentType]: 0
      })
    }), () => {
      this.props.setPourcent(path, v);
      const mail = defaultMailText({
        id: this.props.id,
        products: (this.props.products || new Map()).toJS(),
        helps: this.props.helps,
        advPayment: (this.state.advancePayment || new Map())
          .toJS(),
      });
      this.setMail(mail);
    });
  }

  setMail = (mail) => {
    this.setState(({ advancePayment }) => ({
      advancePayment: advancePayment.setIn(['mail'], mail)
    }), () => this.props.setMail(mail));
  }

  componentDidMount() {
    const { initStateAgain, setPaymentState, setLoadingPayment, setPass } =
      this.props;
    initStateAgain(() => {
      this.setState(initState(this.props));
      setPaymentState(initState(this.props));
    });
    setPaymentState(this.state);
    setLoadingPayment((loading) => {
      this.setState({ loading });
    });
    setPass((pass) => this.setState({
      pass
    }));
  }

  handleChange = (name) => (v) => {
    const { finalPrice, tva, advancePaymentMontant = 0 } = this.props;
    const update = { [name]: v };
    if (name === 'mode') {
      update.payments = divideCurrencyEvenly(
        Math.round(finalPrice * ((tva / 100) + 1)) - advancePaymentMontant,
        v
      ).map((e, i) => ({
        ttc: Money.toString(e),
        date:
          i === 0
            ? moment().toDate()
            : moment().add(i, 'month').startOf('month').toDate(),
      }));
    }
    this.setState(update, () =>
      this.props.setPaymentState(this.state));
  };

  updatePayments = (index, payment) => {
    const newPayements = this.state.payments
      .map((e, i) => i === index ? payment : e);
    this.setState({ payments: newPayements }, () =>
      this.props.setPaymentState(this.state));
  }

  render() {
    const {
      classes,
      users,
      user,
      transaction,
      id,
      ...props
    } = this.props;
    const {
      dialogType,
      dialogComponent,
      pass = !!transaction.login,
      noSecure,
      loading,
      advancePayment,
      ...othersStates
    } = this.state;

    return (
      [
        <DialogTitle className={classes.dialogTitle}>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              {(dialogComponent &&
                  paymentCBActionTypes[dialogType] &&
                  (paymentCBActionTypes[dialogType].title ||
                    paymentCBActionTypes[dialogType].name)) ||
                  'Choisissez le mode de paiement à envoyer'}
            </Grid>
            <Grid item xs={4} className={classes.switchRight}>
              <FormControlLabel
                control={
                  <Switch
                    checked={!noSecure}
                    onChange={() => this.handleChange('noSecure')(!noSecure)}
                    name="noSecure"
                    color="primary"
                    disabled={!permit(user, { auth: HEADCHIEF })}
                  />
                }
                label={`${!noSecure
                  ? 'activé'
                  : 'non activé'}
                  3D secure (bouton reservé au chef d'équipe)`
                }
              />
            </Grid>
          </Grid>
        </DialogTitle>,
        loading ? <Grid container justify="center" alignItems="center">
          <Grid item>
            <CircularProgress size={50} />
          </Grid>
        </Grid> : null,
        !loading && pass && transaction.type ? (
          <TransactionSended
            transaction={transaction} users={users} id={id} />
        ) : (
          !loading && <div
            className={`${
              dialogType !== L_ADVD ? classes.buttonDiv : ''
            } ${classes.container}`}
          >
            {dialogComponent ? (
              component(dialogComponent, {
                handleChange: this.handleChange,
                updatePayments: this.updatePayments,
                transaction,
                ...othersStates,
                ...props,
                id,
                advancePayment: advancePayment,
                setPourcent: this.setPourcent,
                setMail: this.setMail
              })
            ) : (
              <Grid container spacing={1}>
                {getButtons(this.handleType, this.props)}
              </Grid>
            )}
          </div>
        )
      ]);
  }
}

export default compose(withStyles(styles))(
  connect(mapStateToProps)(Content)
);
