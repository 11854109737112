import { moment } from '@bit/dev-lba.lib.local-globals/moment';
const DATE_AND_LOGIN_REGEX = /(?: le)? le \d.+/;

export function formatHistory({
  users,
  elem,
  interSavComments = [],
  recouvrement,
  signalements = [],
  histories = []
}) {
  const findUserLogin = (id) => {
    const user = users.find((u) => u._id === id);

    return user ? user.login : 'Automatique';
  };
  return {
    users,
    interSavComments,
    recouvrement,
    signalements: signalements.map((report) => ({
      type: report.signal.name,
      date: moment(report.date.creation).format('L [à] HH:mm'),
      user: findUserLogin(report.login.creation),
      message: `${report.description}`,
      obj: { style: { color: report.quarantaine ? 'red' : 'inherit' } },
    })),
    findUserLogin,
    histories: elem
      ? [
        {
          type: 'Devis',
          key: 'devis',
          obj: { id: elem.id },
        },
        elem.login.artisan && {
          type: 'Devis artisan',
          key: 'devis',
          user: null,
          obj: {
            artisanId: elem.artisan,
            artisanName:
              elem.currentArtisan && elem.currentArtisan.companyName,
          },
        },
        {
          type: 'Intervention',
          key: 'intervention',
          obj: {
            artisanId: elem.artisan,
            artisanName:
             elem.currentArtisan && elem.currentArtisan.companyName
          },
        },
        ...histories.map((e) => ({
          type: e.text.replace(DATE_AND_LOGIN_REGEX, ''),
          user: e.userId || e.artisanId,
          date: e.date,
          obj: e.data,
        })),
        ...((elem.histories || {}).envoiFacture || []).map((e) => ({
          type: 'Facture envoyée',
          user: e.login,
          date: e.date
        })),
      ]
        .filteredMap(
          ({
            type,
            key,
            date = elem.date[key],
            user = elem.login[key],
            sortingDate = date,
            obj = {},
            message,
          }) => ({
            type,
            sortingDate: new Date(sortingDate),
            date: moment(date).format('L [à] HH:mm'),
            user: findUserLogin(user),
            obj,
            message,
          }),
          (e) =>
            e && (e.date || elem.date[e.key]) && (e.user || elem.login[e.key])
        )
        .sortBy('sortingDate')
      : [],
  };
}
