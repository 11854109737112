import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import notifSystem from '../../notifSystem';
import LineFormat from './LineFormat';
import UIList from '@material-ui/core/List';
import api from '../../api';
import { Types } from './ListInfo';

class CommentList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      calls: []
    };
    this.setComment = this.setComment.bind(this);
    this.load = this.load.bind(this);
  }

  loaded = false;

  load(artisanId, prospId = null, candidatId = null) {
    let finds = [{ artisanId }];
    if (prospId){
      finds.push({ prospId });
    }
    if (candidatId){
      finds.push({ candidatId });
    }
    api.appels.getAll({
      query: JSON.stringify({
        $or: finds
      }),
    }).then(res => {
      const calls = res.body().map(e => (e.data()));
      this.setState({
        calls: calls.sort((a, b) => new Date(b.date) - new Date(a.date))
      });
    }).catch(() => notifSystem
      .error('Erreur', 'Les appels n\'ont pas pu être récupérés'));
  }

  async setComment(com, id) {
    let calls = this.state.calls.slice(0);
    let callId = calls.findIndex(e => e._id === id);
    let post = {
      appelId: id
    };
    if (!calls[callId].locked) {
      post.comment = com.trim();
    }
    try {
      const res = await api.custom('appels/comment').post(post);
      if (callId >= 0) {
        calls[callId].locked = res.body().data();
        this.setState({
          calls
        });
      }
      notifSystem
        .success('Commentaire', 'Le commentaire a bien été modifié');
    } catch (e) {
      notifSystem
        .error('Erreur', 'Le commentaire n\'a pas pu être modifié');
      throw e;
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.markerList !== this.props.markerList
      || nextProps.artisanId !== this.props.artisanId) {
      this.load(nextProps.artisanId, nextProps.prospId, nextProps.candidatId);
    }
  }

  render() {
    const { artisanId, prospId, candidatId } = this.props;
    const { calls } = this.state;

    if (!this.loaded && artisanId) {
      this.loaded = true;
      this.load(artisanId, prospId, candidatId);
      return null;
    }
    return (
      <Grid item xs={12} sm={12}>
        <UIList>
          {calls &&
            calls.map((e, id) => (
              <LineFormat
                setComment={this.setComment}
                type={Types.Calls}
                key={id}
                info={e}
              />
            ))
          }
        </UIList>
      </Grid>
    );
  }
}

export default CommentList;
