import React from 'react';

import Absences from '../Absences';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

const AbsencesDialog = ({ open, callback, defaultText }) => (
  <Dialog open={open}>
    <DialogTitle>
      Absences de l'artisan {defaultText.companyName
        || `${defaultText.firstname} ${defaultText.name}`}
    </DialogTitle>
    <DialogContent>
      <Absences
        callback={callback}
        artisanId={defaultText._id}
      />
    </DialogContent>
    <DialogActions>
      {!defaultText.returnAfterAdd &&
      <Button onClick={callback}>
        Fermer
      </Button>}
      {defaultText.handleReturnStep &&
        <Button onClick={() => defaultText.handleReturnStep('absences')}>
        Retour
        </Button>}
    </DialogActions>
  </Dialog>
);

export default AbsencesDialog;
