import React from 'react';
import api from '../api';
import notifSystem from '../notifSystem';
import store from '../store';
import { setDialog, closeDialog } from './dialog';
import { openPDF } from '../utils/function';
import { OpenInNew } from '@material-ui/icons';
import IphoneDialog from '../components/Dialogs/IphoneDialog';
import { data as categories } from '@bit/dev-lba.lib.local-globals/categories';
import { moment } from '@bit/dev-lba.lib.local-globals/moment';

export const count = (query, collection) =>
  api[collection]
    .getAll({
      call: 'countDocuments',
      query: JSON.stringify(query),
    })
    .then((res) => res.body().data())
    .catch(() => {
      notifSystem.error('Erreur', 'Impossible de récupérer le compteur');
      return 0;
    });

export function informNewVersion() {
  setTimeout(() => {
    localStorage.setItem(
      'newVersion',
      JSON.stringify({
        status: true, forceUpdate: moment().add(1, 'h').toDate()
      })
    );
    window.dispatchEvent(new Event('storage'));
  }, 1000 * 5);
}

export function newVersionDialog() {
  return store.dispatch(
    setDialog({
      name: 'VerficationProp',
      open: true,
      hideClose: true,
      dialogProps: {
        title: '⚠️  Information  ⚠️',
        middle: true,
        fullScreenOn: false,
        disableEscapeKeyDown: true,
        disableBackdropClick: true,
      },
      contentProps: {
        message:
          'Une nouvelle version est disponible\n' +
          'Redémarrage automatique, veuillez patienter',
      },
    })
  );
}

export function checkMessagesToAnswer({ link, history }) {
  return store.dispatch(
    setDialog({
      name: 'VerficationProp',
      open: true,
      hideClose: true,
      dialogProps: {
        title: 'Messages à répondre',
        middle: true,
        fullScreenOn: false,
        disableEscapeKeyDown: true,
        disableBackdropClick: true,
      },
      contentProps: {
        message:
          'Veuillez répondre aux messages reçus.',
      },
      actions: [
        ...(link ? [{
          children: 'Répondre',
          color: 'primary',
          onClick: (s, close) => {
            history.push(link);
            close();
          }
        }, {
          children: <>
          Rép Nouvel onglet <OpenInNew style={{ marginLeft: 5 }} />
          </>,
          color: 'primary',
          onClick: (s, close) => {
            window.open(link);
            close();
          }
        }] : []),
        {
          children: 'Plus tard',
          color: 'secondary',
          onClick: (d, close) => close(),
        }
      ].filter(Boolean)
    })
  );
}

export const openPreview = (obj, title, withLoading = true) => {
  if (withLoading) {
    store.dispatch(
      setDialog({
        name: 'LoadingDialog',
        open: true,
        dialogProps: { title: 'Prévisualisation en cours' },
      })
    );
  }
  return api
    .all('pdfhtmlform')
    .post(obj)
    .then((res) => openPDF(
      `data:application/pdf;base64,${res.body().data()}`, title))
    .catch(() => {
      if (!obj?.data?.devis?.id && obj?.data?.devis?.isCaldeo) {
        notifSystem.error('Erreur caldeo',
          'Veuillez enregistrer le devis d\'abord');
      } else {
        notifSystem.error('Erreur system',
          'Le fichier n\'a pas pu être généré.');
      }
    })
    .finally(() => withLoading && store.dispatch(closeDialog()));
};

export const openAssignDialog = (props, data, collection, cb) =>
  store.dispatch(
    setDialog({
      name: 'AssignDialog',
      open: true,
      contentProps: {
        ajout: (data.login || {}).ajout
      },
      dialogProps: {
        title: 'Séléctionner un utilisateur',
        middle: true,
        middleAll: true
      },
      actions: [{
        children: 'Assigner',
        onClick: (r, close) => {
          if (r.userSelected) {
            if (r.userSelected._id === (data.login || {}).ajout) {
              close();
              return notifSystem.success(
                'Opération réussie',
                'Informations sauvegardées'
              );
            }
            return api[collection]
              .patch(data._id, { login: { ajout: r.userSelected._id } })
              .then(() => {
                if (cb) {
                  cb(data, r.userSelected);
                }
                return notifSystem.success(
                  'Opération réussie',
                  'Informations sauvegardées'
                );
              }).catch(e => notifSystem.error(
                e.name, e.response ? e.response.data : e)
              ).finally(() => close());
          }
          return notifSystem.error(
            'Erreur',
            'Veuillez séléctionner un utilisateur valide'
          );
        }
      }]
    })
  );

export function dialogIphone({ props, message, data, demandeId }) {
  return props.setDialog(
    IphoneDialog,
    true,
    {
      text: message,
    },
    (newMessage) => {
      props.setDialog(null, false, '', null);
      if (!newMessage || typeof newMessage !== 'string') {
        return;
      }
      return api.sms
        .custom('askPhotoMMS')
        .post({ newMessage, data, demandeId })
        .then(() =>
          notifSystem.success('Opération réussie',
            'Le SMS a été envoyé'))
        .catch(() => notifSystem.error('Erreur',
          'Le SMS n\'a pas pu être envoyé'));
    }
  );
}

export function demandePhoto(
  props,
  title,
  { tel = '', categorie = '', zipCode = '', demandeId } = {},
) {
  return setDialog({
    name: 'DemandePhoto',
    open: true,
    hideClose: true,
    dialogProps: {
      title: title,
      middle: true,
      fullScreenOn: false,
      disableEscapeKeyDown: true,
    },
    contentProps: {
      tel: tel || '',
      categorie: categorie
        ? categories.find(e => e._id === categorie).name
        : '',
      zipCode: zipCode || ''
    },
    actions: [
      {
        children: 'Annuler',
        color: 'secondary',
        onClick: (r, close) => close(),
      },
      {
        children: 'Envoyer le SMS',
        onClick: (data, close) => {
          const { categorie, zipCode, tel } = data;
          const zipCodeRegex = /^[0-9]{5}$/;
          const telRegex = /^(?:07|06|\+336|\+337)/;
          if (tel && categorie && zipCode) {
            if (!zipCodeRegex.test(zipCode) || !telRegex.test(tel)) {
              return notifSystem.error('Erreur',
                'Le code postal ou le numéro de téléphone n\'est pas valide');
            }
            close();
            const user = store
              .getState()
              .users.find((e) => e._id === store.getState().userId);
            const message = `${user.alias} ${categorie} ${zipCode}`;
            return dialogIphone({ props, message, data, demandeId });
          }
          return notifSystem.error('Erreur',
            'Veuillez remplir tous les champs');
        }
      }
    ]
  });
}

export const openCSVDialog = ({ uploadData, ...props }) =>
  store.dispatch(
    setDialog({
      name: 'DialogUploadCSV',
      open: true,
      contentProps: props,
      dialogProps: {
        title: 'Importation CSV',
        maxWidth: 'md',
      },
      actions: [{
        children: 'Importer',
        onClick: ({ data, month, }, close) => {
          if (month && data && data.length) {
            uploadData(data, close);
          } else if (!month) {
            notifSystem.error('Aucun mois sélectionné',
              'Veuillez sélectionner un mois');
          } else {
            notifSystem.error('Aucune donnée importer',
              'Veuillez ajouter un fichier CSV valid');
          }
        }
      }]
    })
  );

export function customConfirmDialog({
  title = 'Etes-vous sûr de faire cette action ?',
  dialogProps = {},
  contentProps = {},
  cb = e => e
}) {
  return store.dispatch(
    setDialog({
      name: 'ConfirmDialog',
      open: true,
      hideClose: true,
      dialogProps: {
        title,
        ...dialogProps,
      },
      contentProps,
      actions: [
        {
          children: 'annuler',
          color: 'secondary',
          onClick: (data, close) => close(),
        },
        {
          children: 'Oui',
          color: 'primary',
          onClick: (data, close) => {
            cb();
            close();
          },
        },
      ],
    })
  );
}
