import company from '@bit/dev-lba.lib.local-globals/company';
import {
  moneyToString,
  addressToString,
  clientToString,
  complementAdress,
  dateToString,
} from './functions';
import { externeNumber } from '@bit/dev-lba.lib.local-globals/numbers';

export const generateSmsSAV = (inter, user) => {
  const date = new Date((inter.date && inter.date.savDate) ||
    new Date());
  const login = (user && user.alias) || '';
  const num = user && user.ligne.replace(/\+33/, '0').match(/.{2}/g).join('.');
  const desc = (inter.infoDescSAV && inter.infoDescSAV.desc) || '';
  const price = (inter.infoDescSAV && inter.infoDescSAV.SAVRemun) ?
    `${moneyToString(inter.infoDescSAV.SAVRemunPrice)} T.T.C` :
    '';
  return (`SAV ${inter.id}\n` +
  `${dateToString(date)}\n` +
  `${clientToString(inter.client)}\n` +
  `${addressToString(inter.client.address)}\n` +
  `${complementAdress(inter.client)}` +
  `Tel: ${externeNumber(inter)} (OS ${inter.id})\n` +
  `${desc}\n` +
  `${inter.infoDescSAV && inter.infoDescSAV.consignes ?
    `Consignes : ${inter.infoDescSAV.consignes}\n` : ''}\n` +
  `${price}\n` +
  `${login} : ${num}\n` +
  `Support : ${company.lines.telSAV}\n`);
};
