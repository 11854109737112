import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  Card,
  CardHeader,
  CardContent
} from '@material-ui/core';

import { M_PEN } from '@bit/dev-lba.lib.local-globals/moStatus';
import TwoColumnList from '../List/TwoColumnList';

const styles = {
  noMargin: {
    margin: 0
  },
  button: {
    margin: 5
  }
};

const ModificationsRowContent = ({
  classes,
  data: { _id, status, dataId, changes, modifications },
  acceptModification,
  declineModification,
  reload
}) =>
  <Card>
    <CardHeader
      classes={{ action: classes.noMargin }}
      title="Modifications"
      action={
        status === M_PEN ?
          <React.Fragment>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              children="Accepter"
              onClick={() => acceptModification(_id, dataId, changes, reload)}
            />
            <Button
              className={classes.button}
              variant="contained"
              color="secondary"
              children="Refuser"
              onClick={() => declineModification(_id, reload)}
            />
          </React.Fragment> : null
      }
    />
    <CardContent>
      <TwoColumnList
        data={modifications}
      />
    </CardContent>
  </Card>;

export default withStyles(styles)(ModificationsRowContent);
