export const getLineOptions = function(data, color, title, month) {

  return {
    title: {
      text: title,
      subtext: month,
      x: 'center'
    },
    xAxis: {
      type: 'category',
      data: Object.keys(data)
    },
    yAxis: {
      type: 'value'
    },
    color: color,
    series: [{
      data: Object.values(data),
      type: 'line',
    }],
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
  };
};
