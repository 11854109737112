import React from 'react';
import { DialogTitle, FormControlLabel, Grid, Switch } from '@material-ui/core';
import CustomTextField from '../CustomInputs/CustomTextField';
import { withStyles } from '@material-ui/core/styles';
import MoneyInput from '../CustomInputs/MoneyInput';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { setDialogContentProp } from '../../actions/dialog';
const setBillNumber = setDialogContentProp('billNumber');
const setWarrantyPrice = setDialogContentProp('warrantyPrice');
const setEnabled = setDialogContentProp('enabled');

const styles = () => ({
  switch: { display: 'flex' }
});

const mapStateToProps = ({
  dialog: {
    contentProps: {
      billNumber,
      warrantyPrice,
      enabled
    }
  }
}) => ({
  billNumber,
  warrantyPrice,
  enabled
});

const mapDispatchToProps = {
  setBillNumber,
  setWarrantyPrice,
  setEnabled
};

const WarrantyDialog = ({
  classes,
  billNumber,
  warrantyPrice,
  enabled,
  setBillNumber,
  setWarrantyPrice,
  setEnabled
}) => (
  <Grid container spacing={1} >
    <Grid item xs={9}>
      <DialogTitle className={classes.dialogTitle}>
        <Grid children='Signaler une demande de garantie'/>
      </DialogTitle>
    </Grid>
    <Grid item xs={3} className={classes.switch}>
      <FormControlLabel
        control={
          <Switch
            value={enabled}
            checked={enabled}
            name="noSecure"
            color="primary"
            onChange={() => setEnabled(!enabled)}
            label={'Activé'}
          />
        }
        label={'Activé'}
      />
    </Grid>
    {enabled
      ? [<Grid item xs={6}>
        <CustomTextField
          value={billNumber}
          setData={(p, v) => setBillNumber(v)}
          label="N° Facture"
          variant="outlined"
        />
      </Grid>,
      <Grid item xs={6}>
        <MoneyInput
          defaultValue={warrantyPrice}
          value={warrantyPrice}
          label="Montant avoir attendu"
          setData={(p, v) => setWarrantyPrice(v)}
          variant="outlined"
        />
      </Grid>]
      : ''
    }
  </Grid>
);
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(WarrantyDialog);
