import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography
} from '@material-ui/core';

const styles = {
  divider: {
    borderBottom: '1px solid #c5c5c5',
    width: '100%',
    height: 1,
  },
  cell: {
    padding: 5,
    margin: 'auto 0',
  },
  title: {
    backgroundColor: '#dcdcdc',
  }
};

const GridedTypography = ({ classes = {}, sm, ...props }) =>
  <Grid item xs={12} className={classes.grid} sm={sm || true}>
    <Typography
      className={classes.typography}
      component="div"
      {...props}
    />
  </Grid>;

const TwoColumnList = ({ classes, data = {} }) =>
  <Grid container className={classes.container} spacing={1}>
    {Object.entries(data).map(([k, v]) =>
      <React.Fragment key={k}>
        <GridedTypography
          classes={{ grid: classes.title, typography: classes.cell }}
          variant="subtitle1"
          sm={4}
          children={k}
        />
        <GridedTypography
          classes={{ typography: classes.cell }}
          sm={8}
          children={v}
        />
        <div className={classes.divider} />
      </React.Fragment>
    )}
  </Grid>;

export default withStyles(styles)(TwoColumnList);
