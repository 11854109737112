import React, { Component } from 'react';
import { getOption } from './options/globalOptions';
import ReactEcharts from 'echarts-for-react';
import { moment } from '@bit/dev-lba.lib.local-globals/moment';
import { getLogin, getCustomFromUser } from '../../../../utils/function';
import notifSystem from '../../../../notifSystem';

class AbstractGraph extends Component {

  state = {
    data: null
  }

  componentDidMount() {
    this.doRequest();
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.doRequest();
    }
  }

  doRequest = () => {
    const { mode, from, to, user, status,
      display, categories, source, users = [],
      fn, errorMessage, actions, subStatus } = this.props;
    const findUser = users.find(e => e._id === user) || {};
    actions[fn](
      {
        mode,
        from,
        to,
        display,
        user,
        status: JSON.stringify(status),
        subStatus: JSON.stringify(subStatus),
        categories: JSON.stringify(categories),
        findUser: {
          _id: findUser._id,
          supportIds: findUser.supportIds,
        },
        users: JSON.stringify(
          findUser
            ? [...(findUser.supportIds || []), findUser._id]
            : users.reduce((e, v) => [...e, v._id, ...(v.supportIds || [])], [])
        ),
        source,
        agent: getCustomFromUser(user, 'multiLignes'),
      },
      (result) => this.setState({ data: result.data }),
      () => notifSystem.error('Erreur', errorMessage)
    );
  }

  render() {
    const { mode, from, to, user, colors, disposition, legend, customOption =
    (data) => getOption({
      data,
      labels: Object.keys(data),
      mode,
      dates: [moment(from), moment(to)],
      title: getLogin(user),
      legend,
      colors: colors,
      disposition
    }) } = this.props;

    const { data } = this.state;

    return (
      <React.Fragment>
        {data && <ReactEcharts
          notMerge={true}
          lazyUpdate={true}
          option={customOption(data, user)}/>}
      </React.Fragment>
    );
  }

}

export default AbstractGraph;
