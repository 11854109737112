import React from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import ReactEcharts from 'echarts-for-react';
import { moment } from '@bit/dev-lba.lib.local-globals/moment';
import StatsPanel from './StatsPanel';

class PopupStatsPanel extends React.Component {

  state = {
    mode: 'month',
    from: moment().startOf('month').toDate(),
    to: moment().endOf('month').toDate()
  }

  componentDidMount() {
    const { from, to, mode } = this.state;
    this.props.doRequests(from, to, mode);
  }

  onDatesChange = (from, to, mode ) => {
    this.setState({ from, to, mode }, () =>
      this.props.doRequests(from,
        to, mode));
  }

  render() {
    const { open = false, onClose, title, options,
      body, ready } = this.props;
    const { from, to, mode, size = 'md' } = this.state;
    return (
      <Dialog open={open} onClose={onClose}
        maxWidth={size}
        fullWidth={true}
      >
        <DialogContent>
          <StatsPanel
            defaultState={{ from, to, mode }}
            onDatesChange={this.onDatesChange}
            body={body}
            title={title}
          >
            {<ReactEcharts showLoading={!ready} notMerge
              lazyUpdate option={options}/>}
          </StatsPanel>
        </DialogContent>
      </Dialog>
    );
  }

}

export default PopupStatsPanel;
