import React from 'react';
import {
  green,
  red,
  amber,
  deepOrange,
  blue,
  teal,
  grey,
  brown,
  indigo,
} from '@material-ui/core/colors';
import { MenuItem, Select, Grid, Typography, Card } from '@material-ui/core';
import {
  CancelOutlined,
  Schedule,
  Money as MoneyIcon,
  MoneyOff,
  PermContactCalendarOutlined,
  DescriptionOutlined,
  Timer,
  TrendingUp,
  TrendingDown,
} from '@material-ui/icons';

import Money from '../../../../utils/Money';

const icons = (props) => ({
  Annulées: <CancelOutlined {...props} />,
  'En cours': <Schedule {...props} />,
  'En cours de reglement': <MoneyIcon {...props} />,
  Pertes: <MoneyOff {...props} />,
  'Prises de contact': <PermContactCalendarOutlined {...props} />,
  'Prises en charge': <DescriptionOutlined {...props} />,
  'Révision lourde': <TrendingDown {...props} />,
  'Révision légère': <TrendingUp {...props} />,
  'Délai de résolution': <Timer {...props} />,
});

const colors = {
  Annulées: red[500],
  'En cours': deepOrange[500],
  'En cours de reglement': amber[500],
  Pertes: grey[500],
  'Prises de contact': blue[500],
  'Prises en charge': teal[500],
  'Révision lourde': brown[500],
  'Révision légère': green[500],
  'Délai de résolution': indigo[500],
};

export default ({
  classes,
  displayType,
  number = 0,
  name,
  mode,
  handleChange,
  reqAllStats,
  handleDialogOpen,
  isCA,
}) => (
  <Grid item xs={4}>
    <Card
      className={classes.paper}
      style={{
        borderTop: `4px solid ${colors[name]}`,
      }}
      elevation={4}
      onClick={() => handleDialogOpen(name)}
    >
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        {['Prises en charge', 'Prises de contact'].includes(name) ? (
          [
            <Grid key={0} item xs={5}>
              <Typography variant={'caption'} className={classes.title}>
                {name}
              </Typography>
            </Grid>,
            mode === 'day' && <Grid key={1} item xs={1}>
              <Select
                value={displayType}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                onChange={handleChange('displayType', reqAllStats)}
              >
                <MenuItem value={24}>24</MenuItem>
                <MenuItem value={48}>48</MenuItem>
                <MenuItem value={72}>72</MenuItem>
              </Select>
            </Grid>,
          ]
        ) : (
          <Grid item xs={6}>
            <Typography variant={'caption'} className={classes.title}>
              {name}
            </Typography>
          </Grid>
        )}
        <Grid item xs={4}>
          <Typography variant="subtitle2" className={classes.number}>
            {isNaN(number) || name === 'Prises de contact'
              ? number
              : (isCA && Money.toString(number * 100, true)) || number}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          {
            icons({
              style: { color: colors[name] },
            })[name]
          }
        </Grid>
      </Grid>
    </Card>
  </Grid>
);
