import {
  Map,
  List
} from 'immutable';
import {
  OLDCHAUDIERE_FETCH
} from '../constants/ActionTypes';

const initialState = {
  tree: new Map(),
  array: new List()
};
export default (state = initialState, action) => {
  switch (action.type) {
    case OLDCHAUDIERE_FETCH: {
      return {
        array: action.payload,
        tree: action.payload
          .groupBy(e => e.get('type'))
      };
    }
    default:
      return state;
  }
};
