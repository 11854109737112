import React from 'react';
import { HEADCHIEF } from '@bit/dev-lba.lib.local-globals/permissions';
import { getLogin, getCustomFromUser } from '../../../../utils/function';
import { formatDate } from '@bit/dev-lba.lib.local-globals/moment';
import Paper from '@material-ui/core/Paper';
import { genChip } from '../../../../utils/chips';
import {
  H_ANN,
  H_VAL,
  H_PREV,
  H_REF,
  data as holidaysData,
} from '@bit/dev-lba.lib.local-globals/hStatus';
import api from '../../../../api';
import notifSystem from '../../../../notifSystem';
import { S_RH } from '@bit/dev-lba.lib.local-globals/services';
import {
  services as getServices
} from '@bit/dev-lba.lib.local-globals/getDataById';
import CustomMaterialTable from '../../../CustomMaterialTable';

const update = (holidays) => {
  api.users
    .custom('/updateHolidays')
    .post({ holidays })
    .then(() => {
      notifSystem.success('Succes', 'Demande de congés mis a jours');
    })
    .catch(() => notifSystem.error('Erreur', 'Une erreur est survenue'));
};

const customFilter = (value1 = '', term = '') =>
  value1.toUpperCase().includes(term.toUpperCase());

const HolidaysView = ({ data, user }) => {
  if (user.permission > HEADCHIEF && user.service !== S_RH) {
    data = data.filter((e) => e.user === user._id);
  }
  return (
    <CustomMaterialTable
      key="1"
      title="Congés"
      columns={[
        {
          title: 'Login',
          field: 'user',
          render: (holiday = {}) => getLogin(holiday.user),
          customSort: (holidayA, holidayB) =>
            getLogin(holidayA.user) - getLogin(holidayB.user),
          customFilterAndSearch: (term = '', holiday) =>
            customFilter(getLogin(holiday.user), term),
        },
        {
          title: 'Demande',
          field: 'creation',
          render: (holiday = {}) => formatDate(holiday.creation),
          customSort: (holidayA, holidayB) =>
            new Date(holidayA.creation) - new Date(holidayB.creation),
        },
        {
          title: 'Debut',
          field: 'from',
          render: (holiday = {}) => formatDate(holiday.from),
          customSort: (holidayA, holidayB) =>
            new Date(holidayA.from) - new Date(holidayB.from),
        },
        {
          title: 'Fin',
          field: 'to',
          render: (holiday = {}) => formatDate(holiday.to),
          customSort: (holidayA, holidayB) =>
            new Date(holidayA.to) - new Date(holidayB.to),
        },
        {
          title: 'Service',
          field: 'user',
          render: (holiday = {}) =>
            getServices[getCustomFromUser(holiday.user, 'service')].name,
          customSort: (holidayA, holidayB) =>
            getCustomFromUser(holidayA.user, 'service') -
              getCustomFromUser(holidayB.user, 'service'),
        },
        {
          title: 'Status',
          field: 'user',
          customFilterAndSearch: (term = '', holiday) =>
            customFilter(
              holidaysData.find((e) => e._id === holiday.status).name,
              term
            ),
          render: (holiday = {}) =>
            genChip(holidaysData.find((e) => e._id === holiday.status)),
          customSort: (holidayA, holidayB) =>
            holidayA.status - holidayB.status,
        },
      ]}
      data={data}
      components={{
        Container: (props) => <Paper {...props} elevation={0} />,
      }}
      actions={[
        (holidays) => {
          const disabled = user.permission >= HEADCHIEF ||
            holidays.some(h => (H_PREV === h.status && user.service !== S_RH) ||
            [H_ANN, H_REF, H_VAL].includes(h.status));
          const newHolidays = holidays.map(h => ({
            creation: h.creation,
            userId: h.user,
            status: user.service === S_RH ? H_VAL : H_PREV,
          }));
          return {
            icon: 'check',
            tooltip: 'Accepter',
            iconProps: { color: disabled ? 'disabled' : 'primary' },
            onClick: () => update(newHolidays),
            disabled,
          };
        },
        (holidays) => {
          const disabled = holidays.some(h =>
            [H_ANN, H_REF].includes(h.status) ||
            (H_PREV === h.status && user.service !== S_RH) ||
            (h.status === H_VAL && user.service !== S_RH)
          );
          const newHolidays = holidays.map(h => ({
            creation: h.creation,
            userId: h.user,
            status: user.service === S_RH
              ? H_REF
              : (user._id === h.user && H_ANN) || H_REF
          }));
          return ({
            icon: 'clear',
            tooltip: 'Refuser',
            iconProps: { color: disabled ? 'disabled' : 'secondary' },
            onClick: () => update(newHolidays),
            disabled
          });
        },
      ]}
      options={{
        actionsColumnIndex: -1,
        selection: true,
      }}
    />
  );
};

export default HolidaysView;
