import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { moment } from '@bit/dev-lba.lib.local-globals/moment';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { withStyles, ThemeProvider } from '@material-ui/core/styles';
import { blue, orange } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Warning } from '@material-ui/icons';

import { data as sData, S_RES } from '@bit/dev-lba.lib.local-globals/sStatus';

import { actions, formatReport } from '../Signalements/utils';
import CommentList from '../List/CommentList';

const styles = theme => ({
  typo: {
    fontWeight: 'bold'
  },
  align: {
    textAlign: 'center'
  },
  textField: {
    width: 400
  },
  tableRow: {
    borderLeftWidth: 10,
    borderLeftStyle: 'solid'
  },
  quarantaine: {
    borderLeftColor: 'red',
  },
  notSolved: {
    borderLeftColor: 'DarkOrange'
  },
  solved: {
    borderLeftColor: 'green',
  },
  IconWarningOrigin: {
    color: 'DarkOrange'
  },
  IconWarningQuarantaine: {
    color: 'red'
  },
  iconDiv: {
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    fontSize: 17,
    fontWeight: 500
  },
  userInfos: {
    fontSize: 16
  },
  description: {
    [theme.breakpoints.down('md')]: {
      width: '80%'
    },
    padding: '20px',
    lineHeight: 1.2,
    wordBreak: 'break-word',
    fontSize: 19,
    fontWeight: 500,
  }
});

const InfoChip = ({ elem }) =>
  <Chip
    label={elem.name}
    style={{
      margin: 5,
      width: '100%',
      color: 'white',
      display: 'inline-flex',
      backgroundColor: elem.color['500']
    }}
  />;

const getThemeFromColor = color => outerTheme => ({
  ...outerTheme,
  palette: {
    ...outerTheme.palette,
    primary: {
      main: color[300],
      contrastText: color[50]
    }
  }
});

const ActionButton = ({
  button,
  itemSize,
  elem,
  users,
  user,
  launchRequest,
  setDialog
}) => {
  const { name, color, action } = button;

  return <Grid item xs={6} sm={itemSize} style={{ textAlign: 'center' }}>
    <ThemeProvider theme={getThemeFromColor(color)}>
      <Button
        style={{ width: '100%' }}
        color="primary"
        variant="outlined"
        onClick={() => action({
          users,
          user,
          setDialog,
          reloadCurrent: launchRequest,
          launchRequest
        }, elem)}
        children={name}
      />
    </ThemeProvider>
  </Grid>;
};

const mapStateToProps = ({ users, userId }) => ({
  users,
  user: users.find(u => u._id === userId)
});

class ReportTab extends Component {
  state = {
    currentDialog: null,
    elem: null,
    callbacks: null
  }

  generateButtons = selected => {
    const { users, user, launchRequest } = this.props;
    const availableActions = actions.filter(button =>
      button.color
      && (!button.customVisible
        || button.customVisible(button, {
          selected,
          user,
          users
        })));

    return availableActions.map((button, i) =>
      <ActionButton key={i}
        button={button}
        itemSize={parseInt(12 / availableActions.length, 10)}
        elem={selected}
        users={users}
        user={user}
        launchRequest={launchRequest}
        setDialog={this.setDialog}
      />
    );
  }

  setDialog = (currentDialog = null, report = null, callbacks = null) =>
    this.setState({
      currentDialog,
      report,
      callbacks
    })

  render() {
    const {
      report,
      classes,
      users,
      user
    } = this.props;
    const {
      currentDialog: Dialog,
      callbacks
    } = this.state;

    const format = formatReport(report, users, user._id);
    const chipsData = [
      sData.find(e => e._id === format.status),
      format.managedBy && format.managedBy._id === user._id
        && {
          name: 'Pris en charge',
          color: orange
        },
      format.recipients.some(u => u._id === user._id)
        && {
          name: 'Destinataire',
          color: blue
        },
      (!format.managedBy || format.managedBy._id !== user._id)
        && format.inform
        && (format.inform.notification || format.inform.mail)
        && {
          name: 'Suivi',
          color: orange
        }
    ];
    return [
      <Grid container spacing={1} key={1}
        className={classnames(
          format.status === S_RES
            ? classes.solved
            : ((format.quarantaine && classes.quarantaine)
          || classes.notSolved),
          classes.tableRow
        )}
      >
        <Grid item xs={3} sm={2}
          container spacing={1} justify='space-between'
          className={classes.iconDiv}>
          <Grid item xs={4}>
            <Warning
              className={format.quarantaine
                ? classes.IconWarningQuarantaine
                : classes.IconWarningOrigin}
            />
          </Grid>
          {format.counter && <Grid item xs={8}>
            <Button
              variant="outlined"
              color="primary"
              disabled
              children={format.counter}
            />
          </Grid>}
        </Grid>
        <Grid item xs={8} sm={2}>
          <Typography className={classes.title} color="primary" gutterBottom>
            {format.osId ?
              <Link
                to={`/intervention/${format.osId}`}
                activeclassname="active"
                children={`${format.name} [#${format.osId}]`}
              /> : format.name
            }
          </Typography>
          <br />
          <Typography
            className={classes.userInfos}
            variant="caption"
            gutterBottom
          >
            <div>
              {`Signalé par 
              ${format.emittedBy.name || 'indisponible'} 
              ${moment(format.creationDate).format('[le] L [à] HH:mm')}`}
            </div>
            {!!format.modifiedDate &&
              <div>
                {moment(format.modifiedDate).format('[Modifié le] L [à] HH:mm')}
              </div>}
            {!!format.managedBy &&
              <div>
                {`Pris en charge par 
                ${format.managedBy.name || 'indisponible'}
                ${moment(format.managedDate).format('[le] L [à] HH:mm')}`}
              </div>}
            {format.status === S_RES &&
              <div>
                {`Réglé par 
                ${(format.solvedBy && format.solvedBy.name) || 'indisponible'} 
                ${moment(format.solvedDate).format('[le] L [à] HH:mm')}`}
              </div>}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={7}>
          <Typography
            className={classes.description}
            align="center"
            gutterBottom
          >
            {format.description}
          </Typography>
          <CommentList comments={format.comments} />
        </Grid>
        <Grid item xs={12} sm={1}>
          {chipsData.map((e, i) => e ? <InfoChip key={i} elem={e} /> : null)}
        </Grid>
        <Grid item xs={12} sm={12}>
          <Grid container spacing={4}>
            {this.generateButtons(format)}
          </Grid>
        </Grid>
      </Grid>,
      !!Dialog && <Dialog key={2}
        styles={styles}
        elem={format}
        callbacks={callbacks}
      />];
  }
}

export default compose(
  withStyles(styles, { withTheme: true }))(
  connect(mapStateToProps)(ReportTab));
