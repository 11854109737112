import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import { OpenInNew, Person } from '@material-ui/icons';
import blue from '@material-ui/core/colors/blue';
import { formatDate } from '@bit/dev-lba.lib.local-globals/moment';
import { Typography } from '@material-ui/core';
import {
  data as sStatus, S_UNM
} from '@bit/dev-lba.lib.local-globals/sStatus';
import { getLogin } from '../../../utils/function';
import { genChip } from '../../../utils/chips';
import CustomMaterialTable from '../../CustomMaterialTable';

const styles = {
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
};

const columnsSignal = [{
  'title': 'Date',
  render: (row) => formatDate(row.date, 'DD/MM/YYYY')
},
{
  'title': 'Signalement',
  render: (row) => row?.signal?.name
},
{
  'title': 'Description',
  render: (row) => row?.description
},
{
  'title': 'Emis par',
  render: (row) => getLogin(row?.login?.creation)
},
{
  'title': 'status',
  render: (row) => {
    const statusData = sStatus.find(s =>
      s._id === (row.status || S_UNM));
    return genChip(statusData);
  }
}];

export const GenCommentItem = withStyles(styles)(
  ({ comment, date, user, classes }) =>
    <ListItem button>
      <ListItemAvatar>
        <Avatar className={classes.avatar}>
          <Person />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={comment}
        secondary={
          `${getLogin(user)}, ${formatDate(date, 'LLL')}`
        }
      />
    </ListItem>);


const DisplaySignalements = ({ signalements }) => (
  <CustomMaterialTable
    columns={columnsSignal}
    data={signalements}
    options={{
      toolbar: false,
      sorting: true,
      paging: false,
      actionsColumnIndex: -1,
    }}
    actions={[
      {
        tooltip: 'Page signalements',
        icon: (...p) => <OpenInNew { ...p } />,
        onClick: () => window.open('/signalements')
      },
    ]}
    detailPanel={({ rowData = {} }) =>
      rowData.comments && rowData.comments.length ?
        rowData.comments.map(({ comment, user, creation }) =>
          <GenCommentItem
            comment={comment}
            user={user}
            date={creation}
          />) :
        <Typography
          children="Aucun commentaire trouvé"
          component="p"
          variant='caption'
          align='center'
        />
    }
  />
);

export default DisplaySignalements;
