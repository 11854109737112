import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import FormHelperText from '@material-ui/core/FormHelperText';

class CustomTextField extends Component {
  state = {
    value: this.props.value || '',
    prevValue: this.props.value,
    prevPropsValue: this.props.value
  }

  static getDerivedStateFromProps(props, state) {
    const update = {};

    if (props.value !== state.prevPropsValue) {
      update.prevPropsValue = props.value;
      if (props.value !== state.prevValue) {
        update.value = props.value;
        update.prevValue = props.value;
      }
      return update;
    }
    return null;
  }

  timeout = null

  setData = () => {
    const {
      elemPos,
      setData,
      path
    } = this.props;
    const { value } = this.state;

    this.promisifiedSetState({ prevValue: value })
      .then(() => elemPos >= 0
        ? setData(path, value, elemPos)
        : setData(path, value)
      );
  }

  queueUpdate = () => {
    const { customTimeOut = 500 } = this.props;
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(this.setData, customTimeOut);
  }

  handleChange = ({ target: { value } }) => {
    const {
      capsLocked,
      type
    } = this.props;
    if (type === 'number') {
      value = value !== '' && !isNaN(value) ? parseFloat(value) : value;
    } else if (capsLocked) {
      value = value.toUpperCase();
    }
    this.promisifiedSetState({ value })
      .then(this.queueUpdate);
  }

  render() {
    const {
      xs,
      sm,
      md,
      lg,
      xl,
      grid,
      requiredText,
      classGrid,
      texttransform,
      maxLength,
      inputProps,
      multiline = true,
      ...props
    } = this.props;
    delete props.capsLocked;
    delete props.setData;
    const { value = '' } = this.state;
    const input = <React.Fragment>
      <TextField
        fullWidth
        multiline={multiline}
        onChange={this.handleChange}
        onContextMenu={e => e.stopPropagation()}
        inputProps={{
          ...inputProps,
          style: { textTransform: texttransform || 'uppercase' },
          maxLength
        }}
        required={requiredText}
        {...props}
        value={value}
      />
      {!!maxLength && <FormHelperText>
        {value.length}/{maxLength}
      </FormHelperText>}
    </React.Fragment>;
    return grid ?
      <Grid
        className={classGrid}
        item
        xs={xs}
        sm={sm}
        md={md}
        lg={lg}
        xl={xl}
        children={input}
      /> : input;
  }
}

CustomTextField.propTypes = {
  path: PropTypes.arrayOf(PropTypes.string),
  label: PropTypes.string,
  setData: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.objectOf([
      PropTypes.string,
      PropTypes.number
    ])),
    PropTypes.string,
    PropTypes.number
  ]),
  grid: PropTypes.bool,
  capsLocked: PropTypes.bool,
  classGrid: PropTypes.string,
  texttransform: PropTypes.string
};

export default CustomTextField;
