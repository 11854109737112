import React, { PureComponent } from 'react';
import { compose } from 'recompose';

import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  panelDetailsStyle: { display: 'contents' },
  panelSummaryStyle: {},
  expansionPanelStyle: { margin: `${theme.spacing(2)}px 0px` },
});

const withExpansionPanel = (
  WrappedComponent,
  options = {
    elevation: 2
  }
) =>
  class extends PureComponent {
    state = {
      expanded: !!this.props.expanded,
    };

    render() {
      const {
        expansionTitle,
        summaryChild,
        classes: {
          panelSummaryStyle,
          panelDetailsStyle,
          expansionPanelStyle,
          ...classes
        },
        noMargin,
        ...otherProps
      } = this.props;
      const { expanded } = this.state;
      return (
        <Accordion
          elevation={options.elevation}
          className={!noMargin ? expansionPanelStyle : ''}
          onChange={() => this.setState({ expanded: !expanded })}
          expanded={expanded}
        >
          <AccordionSummary
            className={panelSummaryStyle}
            expandIcon={<ExpandMore />}
            children={summaryChild ||
              <Typography variant="subtitle1" children={expansionTitle} />
            }
          />
          <AccordionDetails className={panelDetailsStyle}>
            {expanded &&
              <WrappedComponent
                classes={classes}
                expansionTitle={expansionTitle}
                {...otherProps}
              />
            }
          </AccordionDetails>
        </Accordion>
      );
    }
  };

export default compose(
  withStyles(styles),
  withExpansionPanel
);
