import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

import { getTime } from '../../../utils/date';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import { getLStorageDataByIndex } from '../../../utils/localStorgeFunctions';

const styles = theme => ({
  selects: {
    minWidth: 103,
    width: '50%',
    maxWidth: 230,
    marginRight: 30,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      width: '45%',
      margin: '10px 15px 0'
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
      width: '90%',
      marginRight: 15,
      marginLeft: 15,
    }
  }
});

export class Selects extends Component {
  state = {
    index: ''
  };

  /**
   * selected Index from array list of Data stored in local storage
   */
  setData = (event) => {
    const { type } = this.props;
    this.setState({
      index: event.target.value
    }, () => {
      try {
        const data = getLStorageDataByIndex(event.target.value, type);
        localStorage.setItem(
          `tmpData${type}_new`,
          JSON.stringify(data)
        );
      } catch (error) {
        localStorage.removeItem(`tmpData${type}`);
        localStorage.removeItem(`tmpData${type}_new`);
        return localStorage.removeItem(`saved${type}`);
      }
    });
    this.props.history.push(`/${this.props.path}`);
  }

  /**
   * Format data for displaying
   * @param {Object} data - either devis or inter
   */
  getData = (data) => ({
    id: data.id || '',
    cv: (data.client || {}).civility,
    name: (data.client || {}).name,
    cp: ((data.client || {}).address || {}).zipcode,
    h: getTime(new Date((data.date || {}).ajout))
  })

  /**
   * collect inter/devis data from local storage
   */
  getList = () => {
    const { type } = this.props;
    if (localStorage.getItem(`saved${type}`)){
      const list = JSON.parse(localStorage.getItem(`saved${type}`));
      return list.map(e => this.getData(e));
    }
  }

  render() {
    const { path, classes } = this.props;
    const dataArr = this.getList() || [];

    return dataArr.length ? (
      <FormControl className={classes.selects}>
        <InputLabel>Liste {path}</InputLabel>
        <Select
          label={`Liste ${path}`}
          onChange={this.setData}
          value={this.state.index}
        >
          {dataArr && dataArr.map((e, i) =>
            <MenuItem value={i} key={i}>
              ({(e.id ? `OS ${e.id}` : e.h)})
               - {e.cv} {e.name} {e.cp ? `(${e.cp})` : ''}
            </MenuItem>
          )}
        </Select>
      </FormControl>
    ) : null;
  }
}

export default compose(
  withRouter,
  withStyles(styles)
)(Selects);
