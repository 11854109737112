import React from 'react';

import { S_RH } from '@bit/dev-lba.lib.local-globals/services';

import {
  ADMIN, NOT_CONFIRMED
} from '@bit/dev-lba.lib.local-globals/permissions';
import componentLoader from '../../componentLoader';

const ListHoldays = React.lazy(() =>
  componentLoader(() => import('../../views/ListHoldays'))
);

export default [
  {
    path: '/users/holidays',
    component: ListHoldays,
    restrictions: ({ service }) =>
      ({
        [S_RH]: NOT_CONFIRMED,
      }[service] || ADMIN),
  },
];
