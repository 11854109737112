import React, { Component } from 'react';
import { moment } from '@bit/dev-lba.lib.local-globals/moment';
import notifSystem from '../../../../notifSystem';
import CustomMaterialTable from '../../../CustomMaterialTable';
import genRows from './genRows';
import actions from '../actions';

const header = {
  pot: { nb: 'Potentiel' },
  potPourcentage: '%',
  recu: { nb: 'Reçu' },
  recuPourcentage: '%',
};

const firstElement = {
  name: '',
  first: header,
  second: header,
  third: header,
};

const columns = (from) => [
  {
    title: 'Nom',
    align: 'center',
    render: (data) => [data.name],
  },
  {
    title: moment(from).subtract(2, 'month').format('MMMM').capitalize(),
    align: 'center',
    colSpan: 4,
  },
  {
    title: moment(from).subtract(1, 'month').format('MMMM').capitalize(),
    align: 'center',
    colSpan: 4,
  },
  {
    title: moment(from).format('MMMM').capitalize(),
    align: 'center',
    colSpan: 4,
  },
];

class StatsTableSupport extends Component {
  state = {
    data: [],
    loading: false
  };

  componentDidMount() {
    this.doRequest();
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.doRequest();
    }
  }

  doRequest = () => {
    const { from, to, user, users, fn, errorMessage, actions } = this.props;
    const newUser = users.find((e) => e._id === user) || {};
    this.setState({ loading: true });
    actions[fn](
      {
        from,
        to,
        findUser: {
          _id: newUser._id,
          supportIds: newUser.supportIds,
        },
      },
      (result) =>
        this.setState({
          data: [
            firstElement,
            ...result.data,
          ],
          loading: false
        }),
      () => {
        this.setState({ loading: false });
        notifSystem.error('Erreur', errorMessage);
      }
    );
  };

  openIntersDialog = (commissions) => {
    const { user } = this.state;
    actions.openCommisionIntersDialog({ user, commissions });
  }

  render() {
    const { data, loading } = this.state;
    return (
      <CustomMaterialTable
        components={genRows(this.openIntersDialog)}
        columns={columns(this.props.from)}
        data={data}
        isLoading={loading}
        options={{
          pageSize: 6,
          headerStyle: { textAlign: 'center' },
          rowStyle: { textAlign: 'center' },
          toolbar: false,
          search: false,
          sorting: true,
        }}
      />
    );
  }
}

export default StatsTableSupport;
