import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { paymentMethods } from '@bit/dev-lba.lib.local-globals/getDataById';
import { fromJS } from 'immutable';
import PrintFiles from '../../../PrintFiles';
import { DIARTI, DFARTI, DBON } from '@bit/dev-lba.lib.local-globals/docTypes';
import Rating from '@material-ui/lab/Rating';
import fetchTexts from '../fetchText';
import MoneyInput from '../../../CustomInputs/MoneyInput';
import PaymentMethod from '../../../InfoIntervention/PaymentMethod';

const compTER = (
  {
    proposition: {
      rating,
      tva,
      finalPrice,
      ratingComments,
      paymentMethod,
      matReturned,
      devisToDo,
      suppliesPrice,
      comments,
    },
    dropbox,
    onSitePayment,
    status
  },
  classes,
  props = {}
) => {
  const {
    setData = e => e,
    restrictions,
    showInput
  } = props;
  const payment =
    typeof paymentMethod === 'number' &&
    paymentMethods[paymentMethod] &&
    paymentMethods[paymentMethod].fullName;
  return [
    <Grid
      key={'Artisan updates'}
      container
      item
      xs={8}
      spacing={3}
      children={[
        !onSitePayment
          ? {
            data: {
              title: 'Mode de règlement : ',
              text: 'Paiement à distance',
            }
          }
          : {
            ...(showInput ? {
              childrens: <MoneyInput
                label="Prix final"
                defaultValue={finalPrice}
                value={finalPrice || 0}
                path={'finalPrice'}
                setData={setData}
                requiredText={true}
              />
            } : {
              data: {
                title: 'Prix final : ',
                text: `${(finalPrice / 100).toFixed(2)} HT`,
                smallText: `${tva || ''}%`,
              }
            }),
          },
        {
          data:
            suppliesPrice && onSitePayment
              ? {
                title: 'Prix fourniture : ',
                text: `${(suppliesPrice / 100).toFixed(2)} HT`,
              }
              : {
                title: 'J\'ai avancé de la fourniture : ',
                text: matReturned ? 'Oui' : 'Non',
              }
        },
        onSitePayment &&
          !!payment && {
          ...(showInput ? {
            childrens: <PaymentMethod
              onSitePayment={onSitePayment}
              restrictions={restrictions}
              paymentMethod={paymentMethod}
              interStatus={status}
              setData={setData}
            />
          } : {
            data: {
              title: 'Methode de paiement : ',
              text: payment || '',
            }
          })
        },
        {
          data: {
            title: 'Devis supplémentaire :',
            text: devisToDo ? 'Oui' : 'Non',
          },
        },
        comments && {
          data: {
            title: 'Commentaire',
            text: comments,
          }
        },
        !!rating && {
          data: { title: 'Notation:' },
          childrens: [
            <Rating size="large" value={rating || 0} readOnly />,
            ratingComments && (
              <Typography className={classes.keys}>
                Commentaire de notation : {ratingComments}
              </Typography>
            ),
          ]
        },
      ].map(fetchTexts(classes))}
    />,
    dropbox && (
      <Grid key={'DropBox'} item xs={12} sm={4} md={4}>
        <PrintFiles
          notDelete
          type={[DFARTI, DIARTI, DBON]}
          path="interventions"
          dropbox={fromJS(dropbox || [])}
        />
      </Grid>
    ),
  ];
};

export default compTER;
