import React, { Component } from 'react';
import { moment } from '@bit/dev-lba.lib.local-globals/moment';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { data as iStatusData } from '@bit/dev-lba.lib.local-globals/iStatus';
import { data as dStatusData } from '@bit/dev-lba.lib.local-globals/dStatus';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import blue from '@material-ui/core/colors/blue';
import { compose } from 'redux';

const statusArray = {
  devis: dStatusData,
  interventions: iStatusData,
};

const styles = theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  content: {
    textAlign: 'center',
    overflow: 'hidden',
  },
  button: {
    margin: theme.spacing(1),
  },
  link: {
    textDecoration: 'none',
    color: blue[500],
  },
});

const mapStateToProps = state => ({
  users: state.users,
});

class DialogGetClientNumber extends Component {
  handleClick = (confirm = false) => {
    if (typeof this.props.callback === 'function') {
      this.props.callback(confirm);
    }
  };

  render() {
    const {
      maxWidth,
      confirmBox,
      closeBox,
      defaultText,
      classes,
      users,
      open,
    } = this.props;
    const { data, name } = defaultText;
    return (
      <div className={classes.root}>
        <Dialog
          onEscapeKeyDown={() => this.handleClick(false)}
          open={open}
          maxWidth={maxWidth}
        >
          {name.map(e =>
            data[e] && data[e].length ? (
              <React.Fragment>
                <DialogContent className={classes.content}>
                  <DialogTitle>
                    Liste des {e} ayant le même numéro de client
                  </DialogTitle>
                </DialogContent>
                <DialogContent>
                  <List>
                    {data[e].map(value => (
                      <ListItem
                        key={value.id}
                        dense
                        className={classes.listItem}
                      >
                        <ListItemText primary={`${e} n ${value.id}`} />
                        <ListItemText
                          primary={`Status ${value.status &&
                            statusArray[e].find(e => e._id === value.status)
                              .name}`}
                        />
                        <ListItemText
                          primary={`Ajouté le ${moment(value.date.ajout).format(
                            'L'
                          )} par ${users &&
                            users.find(e => e._id === value.login.ajout) &&
                            users.find(e => e._id === value.login.ajout)
                              .login}`}
                        />
                        <Button variant="outlined" color="primary">
                          <Link
                            className={classes.link}
                            target="_blank"
                            to={`/${e === 'interventions' ?
                              'intervention' : e}/${value.id}/`}
                          >
                            Voir {e}
                          </Link>
                        </Button>
                      </ListItem>
                    ))}
                  </List>
                </DialogContent>
                {name.length === 1 && (
                  <DialogContent className={classes.content}>
                    <DialogTitle>Voulez-vous créer {e} ?</DialogTitle>
                  </DialogContent>
                )}
              </React.Fragment>
            ) : null
          )}

          <DialogActions>
            {name.length === 1 ? (
              [
                <Button onClick={() => this.handleClick(true)} color="primary">
                  {confirmBox}
                </Button>,
                <Button
                  onClick={() => this.handleClick(false)}
                  color="secondary"
                >
                  {closeBox}
                </Button>,
              ]
            ) : (
              <Button onClick={() => this.handleClick(false)} color="primary">
                OK
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps))(DialogGetClientNumber);

DialogGetClientNumber.defaultProps = {
  maxWidth: 'sm',
  confirmBox: 'Oui',
  closeBox: 'Annuler',
};
