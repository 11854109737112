import React, { PureComponent } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import ReactEcharts from 'echarts-for-react';

import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import notifSystem from '../../../notifSystem';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';

import StatsPanel from '../common/panel/StatsPanel';
import { red, blue, grey } from '@material-ui/core/colors';

import api from '../../../api';
import { getOption } from '../common/graph/options/globalOptions';
import { permit } from '@bit/dev-lba.lib.local-globals/restrictions';
import { moment } from '@bit/dev-lba.lib.local-globals/moment';

const styles = (theme) => ({
  dateDiv: {
    width: '100px',
    marginRight: '8px',
  },
  root: {
    height: '71%',
    paddingTop: 20,
  },
  paperTrust: {
    padding: theme.spacing(4),
    marginBottom: theme.spacing(2),
    height: 145,
  },
  formDiv: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginTop: '1rem',
    marginBottom: '20px',
  },
  button: {
    margin: '1%',
  },
  menuHeight: {
    maxHeight: 300,
  },
  loadingDiv: {
    display: 'flex',
    position: 'absolute',
    width: '100%',
    height: 500,
    backgroundColor: '#fff',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const mapStateToProps = (state) => {
  const user = state.users.find((user) => user._id === state.userId);

  return {
    userId: state.userId,
    user: user,
  };
};

class AppelsStats extends PureComponent {
  constructor(props) {
    super(props);
    const mode = 'day';
    this.state = {
      loading: false,
      mode,
      panel: [
        {
          id: 0,
          title: 'Date de début',
          display: true,
          open: false,
          from: moment()
            .startOf('day')
            .subtract(mode === 'day' ? 'week' : mode, 1)
            .toDate(),
          to: moment()
            .endOf('day')
            .subtract(mode === 'day' ? 'week' : mode, 1)
            .toDate(),
        },
        {
          id: 1,
          title: 'Date de fin',
          display: true,
          open: false,
          from: moment().startOf('day').toDate(),
          to: moment().endOf('day').toDate(),
        },
      ],
      usersSelected: props.users
        ? props.users.reduce(
          (e, v) =>
            v.appel
              ? [{ multiLignes: v.multiLignes, userId: v._id }, ...e]
              : e,
          []
        )
        : [],
    };
  }

  componentDidMount() {
    this.sendData();
  }

  sendData() {
    const { usersSelected, mode, panel } = this.state;
    const { user } = this.props;
    const condition = permit(user, { key: 'adminAcces' });
    try {
      this.handleChangeLoading(true);
      api.stats
        .custom('commercial/appels')
        .get({
          initialDates: panel[0],
          compareDates: panel[1],
          mode,
          usersSelectedLignes:
            condition && usersSelected.length
              ? usersSelected.map((e) => e.multiLignes).flat()
              : user && user.multiLignes,
        })
        .then((result) =>
          this.setState({
            data: result.body().data().data,
          })
        )
        .finally(() => this.handleChangeLoading(false));
    } catch (e) {
      notifSystem.error('Erreur', 'Les stats ne peuvent pas etre charger');
    }
  }

  selectUser = ({ target: { value } }) => {
    const array = value.reduce((arr, curr) => {
      const elem = arr.find((e) => e.userId === curr.userId);
      return !elem ? arr.concat(curr) : arr.filter((e) => e !== elem);
    }, []);
    this.setState({ usersSelected: array }, this.sendData);
  };

  onDatesChange = (panel, mode) => {
    this.setState({ panel, mode }, this.sendData);
  };

  handleChangeLoading = (loading) => {
    this.setState({ loading });
  };

  render() {
    let { data, user, usersSelected, mode, loading, panel } = this.state;
    const { users, classes } = this.props;
    return (
      <React.Fragment>
        <div className={classes.root}>
          <StatsPanel
            title={'Statistiques d\'appels uniques'}
            onDatesChange={this.onDatesChange}
            multiDates={true}
            defaultState={{
              mode,
              from: panel[0].from,
              to: panel[0].to,
            }}
            body={
              <React.Fragment>
                {users &&
                  users.length > 1 &&
                  !permit(user, { key: 'adminAcces' }) && (
                  <form className={classes.formDiv}>
                    <Select
                      labelId="Commercial"
                      multiple={true}
                      value={usersSelected}
                      onChange={this.selectUser}
                      MenuProps={{
                        classes: { paper: classes.menuHeight },
                      }}
                      renderValue={() => 'Commercial'}
                    >
                      {users.map((d) => (
                        <MenuItem
                          key={d._id}
                          value={{
                            multiLignes: d.multiLignes,
                            userId: d._id,
                          }}
                        >
                          <Checkbox
                            checked={usersSelected.some(
                              (e) => e.userId === d._id
                            )}
                          />
                          {d.login}
                        </MenuItem>
                      ))}
                    </Select>
                  </form>
                )}
              </React.Fragment>
            }
          >
            {loading ? (
              <div className={classes.loadingDiv}>
                <CircularProgress ize={50} color="secondary" />
              </div>
            ) : (
              data && (
                <ReactEcharts
                  notMerge={true}
                  lazyUpdate={true}
                  style={{ height: 480 }}
                  option={getOption({
                    data,
                    mode,
                    top: 'auto',
                    dates: [moment(panel[0].from), moment(panel[0].to)],
                    colors: [
                      blue[500],
                      blue[500],
                      red[500],
                      grey[500],
                      red[500],
                    ],
                    disposition: ['line', 'line', 'line', 'line', 'line'],
                    selected: [true, true, true, false, true],
                    lineStyle: [{ type: 'dotted' }, { type: 'dotted' }],
                  })}
                />
              )
            )}
          </StatsPanel>
        </div>
      </React.Fragment>
    );
  }
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps)
)(AppelsStats);
