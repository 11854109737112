import React, { Component } from 'react';
import { connect } from 'react-redux';

import { ADMIN } from '@bit/dev-lba.lib.local-globals/permissions';
import { permit } from '@bit/dev-lba.lib.local-globals/restrictions';

import SelectUsers from './SelectUsers';

let state = {
  userIds: [],
  services: []
};

/**
 * withUserTab is a higher-order component used to display a user tab selector
 * above of the different lists throughout the application
 *
 * @param {Object} options - Options to custom user tab behaviour
 * @param {Number} [options.auth] - Auth level to display user tab selector
 * @param {Function} [options.filterUsers] - Filter users to display
 * @returns {React.Node}
 */
export default function withUserTab({
  auth = ADMIN,
  filterUsers = u => u
} = {}) {
  const mapStateToProps = ({ users, userId }) => ({
    selectedUser: users.find(u => u._id === userId),
    users: users.filter(filterUsers),
  });

  return WrappedComponent => connect(mapStateToProps)(class extends Component {
    state = state

    handleChange = newState => {
      this.setState({ ...newState });
      state = newState;
    }

    render() {
      const { userIds, services } = this.state;
      const { selectedUser, users } = this.props;

      return selectedUser && permit(selectedUser, { auth }) ?
        <React.Fragment>
          <SelectUsers
            users={users}
            selectedServices={services}
            selectedUsers={userIds}
            handleChange={this.handleChange}
          />
          <WrappedComponent {...this.props} userIds={userIds} />
        </React.Fragment>
        : <WrappedComponent {...this.props} />;
    }
  });
}
