import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { grey, red, blue, brown } from '@material-ui/core/colors';
import DisplayCardTable from './DisplayCardTable';

const styles = theme => ({
  paper: {
    padding: theme.spacing(1),
    overflow: 'auto'
  },
  emptyPaper: {
    height: '99%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  IconTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#FAFAFA'
  },
  iconCard: {
    padding: theme.spacing(1),
    textAlign: 'center'
  },
  title: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    fontSize: '1.2rem',
    color: grey[500]
  },
  titleText: {
    fontSize: '15px'
  }
});

class AnimationReseau extends PureComponent {

  render() {
    const { AnimationReseauData, classes } = this.props;
    return (
      <Grid container spacing={4} direction="column">
        <Grid item xs>
          <Grid item xs>
            <Paper className={classes.paper}>
              <Typography className={classes.title}
                variant="h6" align='left'>
                ANIMATION RESEAU :
              </Typography>
              <Grid container spacing={4}>
                <DisplayCardTable
                  headerData={['Nom', 'Nombre d\'interventions']}
                  bodyData={AnimationReseauData}
                  ToFitch={['name', 'I_ANN']} color={red[500]}
                  name={'ANNULATION'} orderBy={'I_ANN'}
                />
                <DisplayCardTable
                  headerData={['Nom', 'Nombre d\'interventions']}
                  bodyData={AnimationReseauData}
                  ToFitch={['name', 'I_VRF']} color={brown[500]}
                  name={'A VERIFIER'} orderBy={'I_VRF'}
                />
                <DisplayCardTable
                  headerData={['Nom', 'Nombre d\'interventions']}
                  bodyData={AnimationReseauData}
                  ToFitch={['name', 'I_APR']} color={blue[500]}
                  name={'A PROGRAMMER'} orderBy={'I_APR'}
                />
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

AnimationReseau.propTypes = {
  classes: PropTypes.object.isRequired,
  AnimationReseauData: PropTypes.array.isRequired,
};

export default withStyles(styles)(AnimationReseau);
