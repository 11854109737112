import React from 'react';
import VerificationComponent from './VerificationComponent';
import RecapStatus from './RecapStatus';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Grid from '@material-ui/core/Grid';

import Button from '@material-ui/core/Button';

const steps = [
  {
    label: 'Vérification de l\'éligibilité du client',
    component: VerificationComponent,
    pass: ({ mode, date }) => !mode || !date,
  },
  {
    label: 'Status du client',
    component: RecapStatus,
    pass: () => true,
  },
];


export default ({ handleChange, activeStep, ...props }) => (
  <Card elevation={0}>
    <CardContent>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step) => (
          <Step key={step.label}>
            <StepLabel>{step.label}</StepLabel>
            <StepContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <step.component {...props} handleChange={handleChange} />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    disabled={activeStep === 0}
                    onClick={() => handleChange('activeStep')(activeStep - 1)}
                  >
                    Revenir
                  </Button>
                  {activeStep !== steps.length - 1 && <Button
                    disabled={step.pass(props)}
                    variant="contained"
                    color="primary"
                    onClick={() => handleChange('activeStep')(activeStep + 1)}
                  >
                    Verification
                  </Button>}
                </Grid>
              </Grid>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </CardContent>
  </Card>
);
