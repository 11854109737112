export default {
  userList: {
    width: '100%',
  },
  userGrid: {
    borderRight: '1px solid grey',
    heigth: 600,
    overflow: 'auto'
  },
  statsGrid: {
    padding: 40,
    paddingTop: 0
  },
  graph: {
    marginTop: 40
  },
  sheet: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 40,
    width: '60%',
    background: 'white',
    margin: '0 auto',
    marginBottom: '0.5cm',
    boxShadow: '0 0 0.5cm rgba(0,0,0,0.5)'
  },
  topTitle: {
    color: '#1B2150',
    fontWeight: 'bold',
    fontSize: 'large',
    textAlign: 'center'
  },
  comments: {
    marginBottom: 150
  },
  textBottom: {
    verticalAlign: 'text-bottom'
  },
  liNumeric: {
    listStyleType: 'decimal'
  },
  liVoid: {
    listStyleType: 'none'
  },
  sheetPaper: {
    width: '40.7cm',
    backgroundColor: 'white',
    position: 'relative'
  },
  star: {
    color: '#ffed42',
  },
  sheetFooter: {
    backgroundColor: '#1B2150',
    position: 'absolute',
    bottom: 0,
    height: 50,
    width: '100%',
    backgroundImage: '-webkit-linear-gradient(-30deg, #1B2150 80%, white 20%)'
  },
  sheetTitleDiv: {
    textAlign: 'center',
    height: '5%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  sheetTitle: {
    border: '3px solid #1B2150',
    padding: '5mm'
  },
  sheetLeft10: {
    marginLeft: '10mm'
  },
  sheetLeft5: {
    marginLeft: '5mm'
  },
  sheetTitleText: {
    color: '#1B2150',
    fontWeight: 'bold',
    fontSize: 'large'
  },
  sheetTitleRed: {
    color: '#c00000',
    fontWeight: 'bold',
    fontSize: 'large'
  },
  sheetCounterDiv: {
    paddingLeft: '10mm',
    paddingRight: '10mm',
    width: '100%'
  },
  sheetTable: {
    width: '100%',
    marginBottom: '10mm'
  },
  downloadButton: {
    position: 'absolute',
    right: 5
  },
  logo: {
    position: 'absolute',
    height: '80%',
    bottom: 5,
    right: 5
  },
  input: {
    width: '80%'
  },
  comment: {
    color: 'grey'
  },
  table: {
    width: '90%',
    margin: 'auto',
    borderSpacing: 0
  },
  tr: {
    '& > td:first-child': {
      whiteSpace: 'nowrap',
      paddingRight: 5
    },
    '& > td': {
      verticalAlign: 'top',
      borderBottom: '1px solid #c1c1c1',
      paddingTop: 4
    },
    '& > td:last-child': {
      paddingLeft: 5
    },
  },
  multiCharts: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    flexWrap: 'wrap'
  }

};
