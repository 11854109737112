export const displayDocument = (pdf) =>
  window.open('').document.write(`
        <html style="background-color: rgb(204, 204, 204);">
          <body style="
            width: 100%;
            height: 100%;
            position: absolute;
            bottom: 0;
            right: 0;
            overflow: hidden;
          ">
            <embed
              width="100%"
              height="100%"
              type="application/pdf"
              src="${pdf}"
            ></embed>
          </body>
        </html>`);
