import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import IconButton from '@material-ui/core/IconButton';
import { Delete, AddBox, HighlightOff } from '@material-ui/icons';
import Badge from '@material-ui/core/Badge';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { grey } from '@material-ui/core/colors';
import Notification from './Notification';

class NotificationList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      notifications: props.notifications,
      index: 20
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (state.notifications.size !== props.notifications.size) {
      return {
        notifications: props.notifications.slice(0, state.index),
        baseNotifications: props.notifications
      };
    }
    return null;
  }

  handleClick = (event, notificationIds) => {
    const target = event.currentTarget;
    this.setState({ anchorEl: target });
    this.props.updateNotifications(notificationIds);
  };

  addMore = () => {
    const { index } = this.state;
    this.setState({
      index: index + 10
    });
  }

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleRemove = (array) => {
    if (array.length) {
      this.props.deleteNotifications(array);
    }
  };

  handleRemoveAll = () => {
    this.handleRemove(this.props.notifications.map(n => n._id).toArray());
  };

  render() {
    const { icon, classes } = this.props;
    const { notifications, index } = this.state;
    const { anchorEl } = this.state;
    let counter = 0;
    const notificationIds = this.props.notifications.reduce((arr, n) => {
      if (!n.seen) {
        counter++;
        arr.push(n._id);
      }
      return arr;
    }, []);
    let color = 'default';
    if (anchorEl) {
      color = 'inherit';
    } else if (counter > 0) {
      color = 'secondary';
    }

    return (
      <div>
        <IconButton color={color}
          onClick={(event) => this.handleClick(event, notificationIds)}
        >
          {counter > 0 ? <Badge badgeContent={counter} color={'secondary'}>
            {icon}
          </Badge> : icon}
        </IconButton>
        <Menu id="notifications"
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={Boolean(anchorEl)}
          disableAutoFocusItem
          onClose={this.handleClose}
          className={classes.menu}
          MenuListProps={{
            className: classes.menuList
          }}
          PaperProps={{
            style: {
              maxHeight: 400,
              width: 600,
              overflowY: 'auto',
            },
          }}>
          <MenuItem onClick={this.handleRemoveAll}
            className={classes.boxContent}
            style={{
              backgroundColor: notifications.size > 0 ? grey[200] :
                'default'
            }}>
            <ListItemIcon>
              {notifications.size > 0 ? <Delete /> : <HighlightOff />}
            </ListItemIcon>
            <ListItemText primary={notifications.size > 0 ?
              'Supprimer les notifications' : 'Aucune notification'} />
          </MenuItem>
          {notifications.map((n, index) =>
            <NotificationDisplayer key={index}
              notification={n} handleRemove={this.handleRemove} />
          )}
          {notifications.size >= index && <MenuItem onClick={this.addMore}
            className={classes.boxContent}
            style={{ backgroundColor: 'default' }}>
            <ListItemIcon>
              <AddBox />
            </ListItemIcon>
            <ListItemText primary={'Voir plus'} />
          </MenuItem>}
        </Menu>
      </div>
    );
  }
}

const NotificationDisplayer = withStyles(styles)(
  ({ notification, handleRemove, classes }) => {
    let link = null;
    if (notification.link) {
      link = notification.link;
    } else if (notification.intervention) {
      link = `/intervention/${notification.intervention}`;
    } else if (notification.devis) {
      link = `/devis/${notification.devis}`;
    } else if (notification.artisan) {
      link = `/artisan/${notification.artisan}/recap`;
    } else if (notification.savIntervention) {
      link = `/savIntervention/${notification.savIntervention}`;
    }
    return (
      <MenuItem className={classes.displayer}>
        {link ? <Link to={link} className={classes.noLink}>
          <Notification notification={notification}/>
        </Link> :
          <Notification notification={notification}/>
        }
        <ListItemSecondaryAction>
          <IconButton onClick={() => handleRemove([notification._id])}>
            <Delete />
          </IconButton>
        </ListItemSecondaryAction>
      </MenuItem>
    );
  }
);

export default NotificationList;
