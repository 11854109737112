import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {
  S_JUR,
  S_COMPTA,
  S_SATCL,
  S_BTOB,
} from '@bit/dev-lba.lib.local-globals/services';
import Planning from '../../components/Stats/SchudleStats/planning';
import { UserCard, TrustBox } from '../../components/Stats';
import RecouvrementStats from '../../components/Stats/Recouvrement/index';
import StatsComptabilite from '../../components/Stats/Comptabilite';
import { ADMIN } from '@bit/dev-lba.lib.local-globals/permissions';
import { permit } from '@bit/dev-lba.lib.local-globals/restrictions';
import SatisfactionClient from '../../components/Stats/SatisfactionClient';
import Btob from '../../components/Stats/Btob';
import UserHolidaysConfig from '../../components/Users/holidays';
import ObjectifsContainer from './utils/ObjectifsContainer';

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(2) * 2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    overflow: 'auto',
  },
});

const MinimalDashboard = ({ user, users, classes }) => (
  <Grid container spacing={2}>
    {permit(user, { key: 'defaultDashBoard' }) && (
      <Grid container spacing={2} item xs={12}>
        <Grid
          item
          xs={12}
          md={12}
          lg={user.service === S_SATCL && user.permission === ADMIN ? 6 : true}
        >
          {UserCard && <UserCard user={user} />}
        </Grid>
        {[S_COMPTA, S_SATCL].includes(user.service) &&
          (user.service !== S_SATCL || user.permission === ADMIN) && (
          <Grid item xs={12} md={12} lg={user.service === S_SATCL ? 6 : true}>
            <ObjectifsContainer
              user={user}
              allUsers={users}
              personal={user.service === S_SATCL}
              noGlobal={user.service === S_SATCL}
              forceService={user.service}
            />
          </Grid>
        )}
      </Grid>
    )}
    {user && user.service === S_COMPTA && (
      <Grid container spacing={2} item xs={12}>
        <Grid item md={12}>
          <Paper>
            <StatsComptabilite users={users} user={user} />
          </Paper>
        </Grid>
      </Grid>
    )}
    {user && user.service === S_SATCL && (
      <Grid container spacing={2} item xs={12}>
        <Grid item md={12}>
          <SatisfactionClient className={classes.paperMargin} users={users} />
        </Grid>
      </Grid>
    )}
    {user && user.service === S_JUR && (
      <Grid container spacing={2} item xs={12}>
        <Grid item md={12}>
          <RecouvrementStats
            user={user}
            users={
              user.permission !== ADMIN
                ? [user]
                : users.filter((u) => u.act && u.service === S_JUR)
            }
          />
        </Grid>
      </Grid>
    )}
    {user && user.service === S_BTOB && (
      <Grid container spacing={2} item xs={12}>
        <Grid item md={12}>
          <Btob />
        </Grid>
      </Grid>
    )}
    {permit(user, { key: 'externeCompta' }) && [
      permit(user, { key: 'defaultDashBoard' }) && (
        <Grid container spacing={2} item xs={12} key="trustBox">
          <Grid item md={12}>
            <Paper className={classes.paper}>
              <TrustBox />
            </Paper>
          </Grid>
        </Grid>
      ),
      <Grid container spacing={2} item xs={12} key="planning">
        <Grid item md={12}>
          <Planning SchudleStatsData={user} />
        </Grid>
        {permit(user, { key: 'defaultDashBoard' }) && (
          <Grid item md={12}>
            <UserHolidaysConfig />
          </Grid>
        )}
      </Grid>,
    ]}
  </Grid>
);

export default withStyles(styles)(MinimalDashboard);
