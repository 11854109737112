import api from '../api';
import store from '../store';
import { setDialog } from './dialog';
import { createGoogleCalendar } from './googleCalendar';
import { getAchatMail } from '@bit/dev-lba.lib.local-globals/achatMail';
import notifSystem from '../notifSystem';
import { C_ACHAT } from '@bit/dev-lba.lib.local-globals/commentTypes';
import { List, Map } from 'immutable';
import { postComment } from './inter';
import { A_DEM, A_DEMT } from '@bit/dev-lba.lib.local-globals/statusAchat';

const createReminderAchat = (inter, date, user) => {
  api.interventions.patch(inter._id, {
    date: {
      sstRappelAchat: date,
    },
    login: {
      sstRappelAchat: user._id
    },
    ...(inter.statusAchat === A_DEMT ? { statusAchat: A_DEM } : {})
  }).then(() => notifSystem.success('Opération réussi',
    'Le rappel a bien été ajouté'))
    .catch(() => notifSystem.error('Erreur',
      'Le rappel n\'a pas pu être rajouté'));
};

export const addReminderDialog = ({ user }, inter) => {
  const name = `${inter.currentArtisan.companyName ||
    inter.currentArtisan.name || ''}`;
  const title = `Rappel programmer pour ${name}`;
  store.dispatch(setDialog({
    name: 'ReminderDialog',
    open: true,
    dialogProps: {
      title,
    },
    contentProps: {
      date: inter.date.sstRappelAchat || new Date()
    },
    actions: [
      {
        children: 'Enregistrer',
        color: 'primary',
        onClick: async (data, close) => {
          createGoogleCalendar(
            data.date, title,
            `Relance concernant l'intervention n°${
              inter.id} (${name})`);
          await createReminderAchat(inter, data.date, user);
          close();
        },
      }
    ]
  }));
};

const sendMail = (id, mailData) => {
  api.achats.custom(`sendMail/${id}`)
    .post(mailData)
    .then(() => notifSystem.success('Opération réussi',
      'Le mail a bien été envoyé'))
    .catch(() => notifSystem.error('Erreur',
      'Le mail n\'a pas pu être envoyé'));
};

export const openMailDialog = ({ id }, mailType) => {
  const mail = getAchatMail(id)[mailType];
  store.dispatch(
    setDialog({
      name: 'DialogMailSST',
      open: true,
      dialogProps: {
        maxWidth: 'md',
      },
      contentProps: {
        message: mail.body,
        titleArtisan: mail.title,
      },
      actions: [
        {
          children: 'Envoyer',
          color: 'primary',
          onClick: ({ message, titleArtisan, attachments }, close) => {
            if (message && titleArtisan) {
              sendMail(id,
                { message, title: titleArtisan, attachments, mailType });
              close();
            } else {
              notifSystem.error('Erreur', 'Un des champs est manquant');
            }
          },
        },
      ],
    })
  );
};

const addComment = ({ merge, setData }, comment, close) => {
  const oldComments = merge.get('comments');
  return postComment(merge.get('_id'), comment, { type: C_ACHAT })
    .then((res) => {
      const pushedComment = res.body().data().data;
      setData(['comments'], oldComments.push(new Map(pushedComment)));
      close();
      notifSystem.success('Opération réussi',
        'Le commentaire a bien été ajouté');
    })
    .catch(() =>
      notifSystem.error('Erreur', 'Veuillez ajouter un commentaire')
    );
};

export const openCommAchat = ({ merge, setData }) => {
  store.dispatch(setDialog({
    name: 'DialogStatusWithComment',
    open: true,
    dialogProps: {
      title: 'Commentaire Achat'
    },
    contentProps: {
      withComment: true,
      comments: merge.get('comments', new List())
        .filter(e => e.get('type') === C_ACHAT),
    },
    actions: [
      {
        children: 'Envoyer',
        color: 'primary',
        onClick: ({ comment }, close) => {
          if (comment) {
            addComment({ merge, setData }, comment, close);
          } else {
            notifSystem.error('Erreur', 'Veuillez ajouter un commentaire');
          }
        },
      },
    ],
  }));
};

export const changeStatus = (id, status, currStatus) =>
  api.achats.custom(`changeStatus/${id}`)
    .post({ status, currStatus })
    .then(() => notifSystem.success(
      'Opération réussie',
      'Le statut a bien été modifié'
    ))
    .catch(() => notifSystem.error(
      'Erreur',
      'Le statut n\'a pas pû être modifié'
    ));

