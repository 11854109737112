import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Description, Comments, Recipients } from './RowComponent';
const styles = {
  item: {
    padding: '1%',
  }
};

const ListRowContent = (props) =>
  <Grid container spacing={1}>
    <Grid item style={styles.item} xs={12} md={3}>
      <Description {...props} />
    </Grid>
    <Grid item style={styles.item} xs={12} md={6}>
      <Comments {...props} />
    </Grid>
    <Grid item style={styles.item} xs={12} md={3}>
      <Recipients {...props} />
    </Grid>
  </Grid>;

export default ListRowContent;
