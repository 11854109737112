export const Types = {
  Text: 0,
  Chip: 1,
  RefId: 2,
  Money: 3,
  artisanRef: 4,
  History: 5,
  Icon: 6,
  Rectangle: 7,
  Stars: 8,
  Date: 9,
  Pourcentage: 10,
  Rib: 11,
  DemandeClient: 12,
  DemandeZipCode: 13,
  nomGramdCompte: 14,
  margeBtobPrice: 15,
  margeBtobPercent: 16,
  Sstatus: 17,
  Points: 18,
  SupportPoints: 19,
  DemandeCat: 20,
  Equipe: 21,
  EtatProspection: 22,
  ProspeFound: 23,
  Debrief: 24,
  StepMetierName: 25
};
