import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import {
  Paper,
  MenuItem,
  Grid
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { data as services } from '@bit/dev-lba.lib.local-globals/services';

import { mapStateToProps } from './functions';
import { styles } from './utils';
import Selector from '../../components/Utils/Selector';

const SelectUsers = ({
  classes,
  users,
  selectedUsers,
  selectedServices,
  selectUserOrService,
  generateUserList,
  generateChips
}) =>
  <Paper className={classes.paper}>
    <Grid container spacing={3}>
      <Grid item xs={12} sm={4}>
        <Selector
          multiple
          value={[]}
          handleChange={([v]) => selectUserOrService(v)}
          title="Choisir un utilisateur"
        >
          <MenuItem
            key={0}
            value="all"
            children="Tous"
          />
          {services.map(generateUserList)}
        </Selector>
      </Grid>
      <Grid item xs={12} sm={8}>
        <div className={classes.chips}>
          {generateChips(selectedServices, services, 'secondary')}
        </div>
        <div className={classes.chips}>
          {generateChips(selectedUsers, users, 'primary')}
        </div>
      </Grid>
    </Grid>
  </Paper>;

export default compose(
  withStyles(styles),
  connect(mapStateToProps)
)(SelectUsers);
