import { setDialog } from '../../actions/dialog';
import api from '../../api';
import store from '../../store';

export function openCallTypes(call) {
  store.dispatch(
    setDialog({
      hideClose: true,
      dialogProps: {
        middleAll: true,
        maxWidth: 'md',
        title: 'Quel était le résultat de cet appel ?',
        fullScreenOn: false,
        disableEscapeKeyDown: true,
        disableBackdropClick: true,
      },
      contentProps: { type: call.voiceMail ? 'Répondeur' : 'OK', call },
      name: 'CallTypesDialog',
      open: true
    })
  );
}

export function sendAudioPlayed(call) {
  return api.interventions
    .custom('updateAudio')
    .post({
      name: 'played',
      url: call.urlEnregistrement,
      played: call.played
    });
}

export function sendCallTypes(type, url) {
  return api.interventions
    .custom('updateAudio')
    .post({ name: 'type', url, type });
}
