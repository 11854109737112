/* eslint-disable max-len */
import React from 'react';

const LockIcon = ({ h = '70', color = '#000', moreStyle = null }) => (
  <svg height={h} viewBox="0 0 84 84"
    width={55} fill={color} style={{ ...moreStyle }}>
    <g>
      <path d="M5.2,42.8c0,2.5,2,4.5,4.5,4.5c2.5,0,4.5-2,4.5-4.5s-2-4.5-4.5-4.5C7.3,38.2,5.2,40.3,5.2,42.8z M12.1,42.8
        c0,1.3-1.1,2.4-2.4,2.4c-1.3,0-2.4-1.1-2.4-2.4s1.1-2.4,2.4-2.4C11.1,40.4,12.1,41.4,12.1,42.8z"></path>
      <path d="M32.7,49.4l3.1-3.1l3.1,3.1c0.2,0.2,0.5,0.3,0.7,0.3h2.7c0.3,0,0.5-0.1,0.7-0.3l3.8-3.8c0.4-0.4,0.4-1,0-1.4
        c-0.4-0.4-1-0.4-1.4,0l-3.5,3.5H40l-3.5-3.5c-0.4-0.4-1-0.4-1.4,0l-3.5,3.5h-4c-0.4,0-0.7,0.2-0.9,0.5c-2.3,4.2-6.6,6.8-11.4,6.8
        c-7.1,0-12.9-5.8-12.9-12.9s5.8-12.9,12.9-12.9c4.5,0,8.8,2.4,11.1,6.3c0.2,0.3,0.5,0.5,0.9,0.5h18.3c0.6,0,1-0.5,1-1
        c0-0.6-0.5-1-1-1H27.8c-2.7-4.2-7.5-6.8-12.6-6.8c-8.3,0-15,6.7-15,14.9s6.7,14.9,15,14.9c5.3,0,10.2-2.8,12.9-7.4H32
        C32.2,49.7,32.5,49.6,32.7,49.4z"></path>
      <path d="M63,11.3c-5.8,0-14.3,2.3-22.3,5.9c-0.1,0-0.2,0.1-0.2,0.2c-5.3,2.5-9.6,7.5-11.8,14.1
        c-0.2,0.5,0.1,1.1,0.6,1.3c0.5,0.2,1.1-0.1,1.3-0.6c3-8.8,10-14.5,18-14.5C59.3,17.6,68,28,68,40.8C68,53.6,59.3,64,48.6,64
        c-7.5,0-14.3-5.2-17.5-13.3c-0.2-0.5-0.8-0.8-1.3-0.6c-0.5,0.2-0.8,0.8-0.6,1.3c2.4,6,6.7,10.6,11.8,12.9c0.1,0.1,0.2,0.2,0.3,0.2
        c7.8,3.5,16.2,5.7,21.8,5.7c13.8,0,25-13.2,25-29.5C88,24.5,76.8,11.3,63,11.3z M63,68.2c-3.3,0-7.7-0.8-12.4-2.3
        C61.5,64.7,70,53.9,70,40.8c0-13.1-8.5-23.9-19.4-25.1c4.7-1.4,9.1-2.3,12.4-2.3c12.7,0,23,12.3,23,27.4C86,55.9,75.7,68.2,63,68.2
        z"></path>
      <path d="M46.5,48.2c-0.6,0-1.2,0.5-1.2,1v1.1c0,0.6,0.5,1,1.2,1h3.6c0.6,0,1.2-0.5,1.2-1v-19c0-0.6-0.5-1-1.2-1h-4.7
        c-0.6,0-1.2,0.5-1.2,1v1c0,0.6,0.5,1,1.2,1c0.6,0,1.1-0.4,1.2-1h2.4v16.9h-1.3v-0.1C47.7,48.6,47.2,48.2,46.5,48.2z"></path>
    </g>
  </svg>
);

export default LockIcon;
