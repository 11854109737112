import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';

import ModificationsTable from '../../components/Modifications';
import { schemas } from './schemas';

const styles = {
  paper: {
    padding: 10
  }
};

const ModificationsView = ({ match: { params: { collection } }, classes }) =>
  collection in schemas ?
    <Paper className={classes.paper}>
      <ModificationsTable
        collection={collection}
        schema={schemas[collection]}
      />
    </Paper> : null;

export default withStyles(styles)(ModificationsView);
