import React from 'react';

import { NOT_CONFIRMED } from '@bit/dev-lba.lib.local-globals/permissions';
import componentLoader from '../../componentLoader';

const ListDemandes = React.lazy(() =>
  componentLoader(() => import('../../views/List/ListDemandes'))
);

const ListDevis = React.lazy(() =>
  componentLoader(() => import('../../views/List/ListDevis'))
);

const AjoutDevis = React.lazy(() =>
  componentLoader(() => import('../../views/AjoutDevis'))
);

export default [
  {
    path: '/devis/list/:filter/:query',
    component: ListDevis,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/devis/list/:filter',
    component: ListDevis,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/devis/list',
    component: ListDevis,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/devis/:id/:query',
    component: AjoutDevis,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/devis/:id',
    component: AjoutDevis,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/devis',
    component: AjoutDevis,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/demandes/list/:filter/:query',
    component: ListDemandes,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/demandes/list/:filter',
    component: ListDemandes,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/demandes/list',
    component: ListDemandes,
    restrictions: () => NOT_CONFIRMED,
  },
];
