import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import StatsTelepro from './StatsTelepro';
import Button from '@material-ui/core/Button';
import {
  KeyboardArrowLeft,
  KeyboardArrowRight
} from '@material-ui/icons';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const styles = (theme) => ({
  elem: {
    position: 'absolute',
    top: 56,
    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
      top: 48,
    },
    [theme.breakpoints.up('sm')]: {
      top: 64,
    },
    bottom: 0,
    transition: 'right 0.3s',
    overflowY: 'overlay',
    overflowX: 'hidden'
  },
  button: {
    transition: '0.2s',
    position: 'absolute',
    right: 0,
    width: '0px',
    height: '60px',
    borderTopLeftRadius: '110px',
    borderBottomLeftRadius: '110px',
    boxSizing: 'border-box',
    padding: 0,
    minWidth: 0,
    fontSize: '24px',
    backgroundColor: '#333333 !important',
    color: '#fff !important',
    zIndex: 1,
  },
  StatsTelepro: {
    right: 0,
    width: 250,
    transition: '0.3s',
    backgroundColor: '#333333',
    zIndex: 2,
  },
  divB: {
    width: 30,
    height: 150,
    margin: 'auto',
    position: 'absolute',
    top: 56,
    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
      top: 48,
    },
    [theme.breakpoints.up('sm')]: {
      top: 0,
    },
    bottom: 0,
    transition: 'right 0.3s',
    overflowY: 'overlay',
    overflowX: 'hidden',
  },
  main: {
    backgroundColor: 'lightgray'
  },
});

export class SideBarStats extends Component {

  state = {
    showButton: false,
  }

  handleClick = () => {
    this.props.openStats();
  }

  onMouseLeaveHandler = () => {
    this.setState({
      showButton: false
    });
  }

  onMouseEnterHandler = () => {
    this.setState({
      showButton: true
    });
  }

  render() {
    const {
      classes,
      open,
    } = this.props;
    return (
      [<div
        key={'one'}
        className={classes.divB}
        style={{ right: open ? 250 : 0 }}
        onMouseLeave={this.onMouseLeaveHandler}
        onMouseEnter={this.onMouseEnterHandler} >
        <Button variant="contained" color="primary" aria-label="add"
          className={classes.button}
          style={{
            width: this.state.showButton ? '30px' : '0px',
          }}
          onClick={this.handleClick}>
          { this.state.showButton && (!open
            ? <KeyboardArrowLeft />
            : <KeyboardArrowRight />)
          }
        </Button>
      </div>,
      <div
        key={'two'}
        onMouseLeave={this.onMouseLeaveHandler}
        onMouseEnter={this.onMouseEnterHandler}
        className={classNames(classes.StatsTelepro, classes.elem)}
        style={{ width: open ? 250 : 0 }}>
        <StatsTelepro />
      </div>]
    );
  }
}

SideBarStats.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SideBarStats);
