import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import ReactEcharts from 'echarts-for-react';
import { getOption } from './getOptions';
import SelectDisplayStatus from './SelectDisplayStatus';

const styles = theme => ({
  paper: {
    padding: theme.spacing(3),
  },
});

class StackedBarStats extends PureComponent {
  constructor(props) {
    super(props);
    this.handleChangeStatus = this.handleChangeStatus.bind(this);
  }

  handleChangeStatus(event) {
    this.props.setStatus(event.target.value);
  }

  render() {
    const { stackedBarData, classes, status } = this.props;
    return (
      <Paper className={classes.paper}>
        <SelectDisplayStatus handleChangeStatus={this.handleChangeStatus}
          status={status} />
        {stackedBarData ? <ReactEcharts
          style={{ height: '350px', width: '100%' }}
          option={getOption(stackedBarData)} /> : null}
      </Paper>
    );
  }
}

StackedBarStats.propTypes = {
  classes: PropTypes.object.isRequired,
  status: PropTypes.number.isRequired,
  stackedBarData: PropTypes.array.isRequired
};

export default withStyles(styles)(StackedBarStats);
