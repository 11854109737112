import React, { Component } from 'react';
import { connect } from 'react-redux';
import { call } from '../../utils/call';
import {
  Typography, Tooltip, Grid, DialogTitle, MenuItem
} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { setDialogContentProp } from '../../actions/dialog';
import { remunerationIcons } from '../../utils/IconsDisplay';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';
import CustomSelect from '../CustomInputs/CustomSelect';
import { R_POR, R_FOR } from '@bit/dev-lba.lib.local-globals/remStatus';
import { Map } from 'immutable';

const filters = (remuneration = new Map()) =>
  [
    {
      name: '30% - 39%',
      filter:
        remuneration.get('status') === R_POR &&
        remuneration.getIn(['pourcentage', 'labor']) >= 30 &&
        remuneration.getIn(['pourcentage', 'labor']) < 40,
    },
    {
      name: '40% - 49%',
      filter:
        remuneration.get('status') === R_POR &&
        remuneration.getIn(['pourcentage', 'labor']) >= 40 &&
        remuneration.getIn(['pourcentage', 'labor']) < 50,
    },
    {
      name: '+50%',
      filter:
        remuneration.get('status') === 1 &&
        remuneration.getIn(['pourcentage', 'labor']) >= 50,
    },
    {
      name: 'Forfait',
      filter: remuneration.get('status') === R_FOR,
    },
  ];

const styles = {
  switch: { display: 'flex' },
  dialogTitle: {
    padding: '0 0 16px',
    width: '100%'
  },
  switchRight: {
    textAlign: 'right'
  },
};

const setCheckedArtisans = setDialogContentProp('checkedArtisans');

const mapStateToProps = ({
  dialog: {
    contentProps: {
      formatedArtisans,
      checkedArtisans
    },
  },
}) => ({
  formatedArtisans,
  checkedArtisans
});

const mapDispatchToProps = {
  setCheckedArtisans
};

class PropositionArtisans extends Component {
  state = {
    formatedArtisans: this.props.formatedArtisans
      .filter(e => Math.round(e.artisan.get('dis')) <= 30),
    filterIds: [0, 1, 2, 3],
    checkArtisan: this.props.formatedArtisans
      .filter(e => Math.round(e.artisan.get('dis')) <= 30)
      .map((e) =>
        e.artisan.getIn(['obj', '_id'])
      ),
    selectedDistance: 30
  };

  componentDidMount() {
    this.props.setCheckedArtisans(this.state.checkArtisan);
  }

  filterByRemun(value) {
    const { setCheckedArtisans } = this.props;
    const { formatedArtisans } = this.state;
    const filtered = formatedArtisans
      .filter(
        (e) =>
          value.some(s =>
            filters(e.artisan.getIn(['obj', 'remuneration'])
            )[s].filter)
      )
      .map((e) => e.artisan.getIn(['obj', '_id']));
    this.setState({ filterIds: value, checkArtisan: filtered }, () =>
      setCheckedArtisans(filtered)
    );
  }

  updateCheckedArtisan = (value) => () => {
    const checkArtisan = [...this.state.checkArtisan];
    if (!checkArtisan.includes(value)) {
      checkArtisan.push(value);
    } else {
      checkArtisan.splice(checkArtisan.indexOf(value), 1);
    }
    this.setState({ checkArtisan });
    this.props.setCheckedArtisans(checkArtisan);
  };

  handleDistance = (value) => {
    const nArtisans = this.props.formatedArtisans
      .filter(e => e.artisan.get('dis') <= value);
    this.setState({
      formatedArtisans: nArtisans,
      selectedDistance: value,
      checkArtisan: this.props.formatedArtisans
        .filter(e => Math.round(e.artisan.get('dis')) <= value)
        .map((e) =>
          e.artisan.getIn(['obj', '_id'])
        ),
    }, () => {
      this.props.setCheckedArtisans(this.state.checkArtisan);
    });
  }

  render() {
    const { classes } = this.props;
    const {
      formatedArtisans, checkArtisan, filterIds, selectedDistance
    } = this.state;

    return (
      <>
        <DialogTitle className={classes.dialogTitle}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={8}
              children="Proposer l'intervention à ces artisans:"
            />
            <Grid item xs={2} className={classes.switchRight}>
              <CustomSelect
                label="Distance"
                value={selectedDistance}
                path={['']}
                setData={(p, v) => this.handleDistance(v)}
              >
                {[30, 40, 50].map((e, i) => (
                  <MenuItem key={i} value={e}>
                    {e}
                  </MenuItem>
                ))}
              </CustomSelect>
            </Grid>
            <Grid item xs={2} className={classes.switchRight}>
              <CustomSelect
                label="Remuneration"
                value={filterIds}
                path={['']}
                renderValue={() => 'remunerations...'}
                setData={(p, v) => this.filterByRemun(v)}
                multiple
              >
                {filters().map((e, i) => (
                  <MenuItem key={i} value={i}>
                    <Checkbox checked={filterIds.includes(i)} />
                    {e.name}
                  </MenuItem>
                ))}
              </CustomSelect>
            </Grid>
          </Grid>
        </DialogTitle>
        {formatedArtisans.map((e, i) => (
          <Tooltip title="Appeler" key={i}>
            <Grid
              container
              spacing={4}
              alignItems="center"
              justify="space-between"
            >
              <Grid item xs={1}>
                <Checkbox
                  checked={checkArtisan.includes(
                    e.artisan.getIn(['obj', '_id'])
                  )}
                  value={e.artisan.getIn(['obj', '_id'])}
                  onChange={this.updateCheckedArtisan(
                    e.artisan.getIn(['obj', '_id'])
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Typography
                  onClick={() => call(e.artisan.getIn(['obj', 'tel1']))}
                  variant="subtitle1"
                >
                  {`${e.artisan.getIn(['obj', 'title'])} (${Math.round(
                    e.artisan.getIn(['dis'])
                  )} km)`}
                </Typography>
              </Grid>
              <Grid
                item
                xs={2}
                children={
                  <Typography
                    variant="subtitle2"
                    style={{
                      color: e.status.color['500'],
                      textTransform: 'capitalize',
                    }}
                    children={e.status.name}
                  />
                }
              />
              <Grid
                item
                xs={2}
                children={remunerationIcons[
                  e.artisan.getIn(['obj', 'remuneration', 'status'])
                ](e.artisan.getIn(['obj', 'remuneration']).toJS())}
              />
            </Grid>
          </Tooltip>
        ))}
      </>
    );
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(PropositionArtisans);
