import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import CustomTextField from '../../CustomInputs/CustomTextField';
import CustomSelect from '../../CustomInputs/CustomSelect';
import MoneyInput from '../../CustomInputs/MoneyInput';
import Attachments from '../../Utils/Attachments';
import { setDialogContentProp } from '../../../actions/dialog';
import { withLoading } from '../../../hoc';
import { Grid, MenuItem } from '@material-ui/core';
import {
  genFournisseurList
} from '@bit/dev-lba.lib.local-globals/configFournisseursTypes';

const setAttachments = setDialogContentProp('attachments');
const setFournisseur = setDialogContentProp('fournisseur');
const setSubject = setDialogContentProp('subject');
const setSubjectArtisan = setDialogContentProp('subjectArtisan');
const setMessage = setDialogContentProp('message');
const setMessageArtisan = setDialogContentProp('messageArtisan');

const setPrice = setDialogContentProp('price');
const setDestinataire = setDialogContentProp('destinataire');
const setArtisanMail = setDialogContentProp('artisanMail');
const setPDFComments = setDialogContentProp('PDFComments');
const setCb = setDialogContentProp('cb');

const mapStateToProps = ({
  dialog: {
    contentProps: {
      savIntervention,
      artisanMail,
      attachments,
      subject,
      message,
      price,
      subjectArtisan,
      destinataire,
      messageArtisan,
      cb,
      fournisseur,
    },
  },
  fournisseurs
}) => ({
  attachments,
  savIntervention,
  artisanMail,
  subject,
  message,
  fournisseur,
  price,
  destinataire,
  subjectArtisan,
  messageArtisan,
  cb,
  fournisseurs
});

const mapDispatchToProps = {
  setAttachments,
  setSubject,
  setMessageArtisan,
  setSubjectArtisan,
  setMessage,
  setPrice,
  setDestinataire,
  setCb,
  setFournisseur,
  setArtisanMail,
  setPDFComments
};

const DialogBon = ({
  attachments = [],
  subject,
  message,
  price,
  fournisseur,
  destinataire,
  setAttachments,
  setSubject,
  setSubjectArtisan,
  setMessageArtisan,
  setMessage,
  setPrice,
  messageArtisan,
  subjectArtisan,
  setDestinataire,
  setCb,
  setLoading,
  setFournisseur,
  artisanMail,
  setArtisanMail,
  PDFComments,
  setPDFComments,
  fournisseurs
}) =>
  setCb(setLoading) && (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <CustomTextField
          label="Destinataire email"
          texttransform="none"
          value={destinataire}
          setData={(p, v) => setDestinataire(v)}
          requiredText
        />
      </Grid>
      <Grid item xs={3}>
        <CustomSelect
          label="Fournisseurs"
          texttransform="none"
          value={fournisseur}
          setData={(p, v) => setFournisseur(v)}
          requiredText
          children={genFournisseurList(fournisseurs).map((e) =>
            e.options.map((name, key) => (
              <MenuItem disabled={e.title === name} key={key} value={name}>
                {name}
              </MenuItem>
            ))
          )}
        />
      </Grid>
      <Grid item xs={3}>
        <MoneyInput
          label="Prix total"
          texttransform="none"
          value={price}
          setData={(p, v) => setPrice(v)}
          requiredText
        />
      </Grid>
      <Grid item xs={12}>
        <CustomTextField
          label="Titre du mail"
          texttransform="none"
          value={subject}
          setData={(p, v) => setSubject(v)}
          requiredText
        />
      </Grid>
      <Grid item xs={12}>
        <CustomTextField
          label="Corps du mail"
          value={message}
          texttransform="none"
          rows={15}
          setData={(p, v) => setMessage(v)}
          requiredText
        />
      </Grid>
      <Grid item xs={6}>
        <CustomTextField
          label="Artisans email"
          texttransform="none"
          value={artisanMail}
          setData={(p, v) => setArtisanMail(v)}
          requiredText
        />
      </Grid>
      <Grid item xs={12}>
        <CustomTextField
          label="Titre du mail pour l'artisan"
          texttransform="none"
          value={subjectArtisan}
          setData={(p, v) => setSubjectArtisan(v)}
          requiredText
        />
      </Grid>
      <Grid item xs={12}>
        <CustomTextField
          label="Corps du mail pour l'artisan"
          value={messageArtisan}
          texttransform="none"
          rows={15}
          setData={(p, v) => setMessageArtisan(v)}
          requiredText
        />
      </Grid>
      <Grid item xs={12}>
        <CustomTextField
          label="Commentaire fichier"
          value={PDFComments}
          texttransform="none"
          setData={(p, v) => setPDFComments(v.replace('\n', '<br/>'))}
        />
      </Grid>
      <Grid item xs={12}>
        <Attachments
          data={attachments}
          inputProps={{
            accept: 'application/pdf'
          }}
          name='bonFourniture'
          onAdd={(f, files) => setAttachments(files)}
          onDelete={(f, i, files) => setAttachments(files)}
        />
      </Grid>
    </Grid>
  );

export default compose(
  connect(mapStateToProps,
    mapDispatchToProps),
  withLoading(),
)(DialogBon);
