import React, { PureComponent } from 'react';
import {
  Grid, Button, Box, MenuItem
} from '@material-ui/core';

import {
  MIN_AMOUNT, MIN_AMOUNT_DEVIS
} from '@bit/dev-lba.lib.local-globals/domofinance';
import { permit } from '@bit/dev-lba.lib.local-globals/restrictions';

import MoneyInput from '../../CustomInputs/MoneyInput';
import CustomSelect from '../../CustomInputs/CustomSelect';
import {
  getRange,
  handleDomofinance,
} from '../../../utils/financement';
import CustomCheckbox from '../../CustomInputs/CustomCheckbox';
import { openPreview } from '../../../actions/general';
import CustomLoadingButton from '../../Utils/CustomLoadingButton';

const paths = {
  amount: ['domofinance', 'amount'],
  duration: ['domofinance', 'duration'],
  interest: ['domofinance', 'interest'],
  monthly: ['domofinance', 'monthly'],
  contribution: ['domofinance', 'contribution'],
  report: ['domofinance', 'report'],
  modified: ['domofinance', 'modified'],
  valid: ['domofinance', 'valid'],
};
/**
 * Generate devis for domofinance
 * @param {Object} data - inter/devis data
 * @param {Object} merge - domofinance data
 * @returns {File} - preview pdf file
 */
const handlePreviewDomofinance = (data, merge) => {
  const devis = {
    ...data.toJS(),
    domofinance: merge.get('domofinance').toJS()
  };
  return openPreview({
    method: 'completeDevis',
    info: {
      type: 'devis'
    },
    data: {
      devis
    }
  }, `Devis domofinance n°${data.get('id', '')}`, false);
};

const items = t => t.map((v, i) =>
  <MenuItem key={i} value={v} children={v} />);

class Content extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      rangeData: getRange(props.merge.getIn(paths.amount, 0) / 100),
      amount: 0,
      previewLoading: false
    };
  }

  componentDidMount() {
    const merge = this.props.merge;
    if (merge.getIn(paths.amount, 0)) {
      this.setState({
        rangeData: getRange(this.props.merge.getIn(paths.amount) / 100),
        amount: merge.getIn(paths.amount, 0) -
          merge.getIn(paths.contribution, 0)
      });
    }
  }

  static getDerivedStateFromProps(props, state) {
    const newAmount = props.merge.getIn(paths.amount, 0) -
    props.merge.getIn(paths.contribution, 0);
    if (newAmount !== state.amount) {
      return {
        amount: newAmount
      };
    }
    return null;
  }

  setDomofinance = (amount, duration) => {
    const { setData, merge } = this.props;
    const rangeData = this.state.rangeData;
    amount = amount || merge.getIn(paths.amount, 0);
    duration = duration || rangeData.defaultValue;
    if (!merge.getIn(paths.modified)) {
      setData(paths.modified, true);
    }
    setData(paths.duration, duration || '',
      () => handleDomofinance({
        duration,
        amount: (amount - merge.get(paths.contribution, 0)),
        taux: rangeData.taux,
        setData
      }));
  }

  setAmount = (p, v) => {
    const { setData, merge } = this.props;
    setData(paths.contribution, 0, () => setData(p, v));
    const min = merge.getIn(['combination', 'enableCaldeo'])
      ? MIN_AMOUNT : MIN_AMOUNT_DEVIS;
    if ((v / 100) >= min) {
      this.setState({
        rangeData: getRange(v / 100)
      }, () => this.setDomofinance(v, null));
    }
  }

  setDuration = (p, v) => {
    const { setData } = this.props;
    setData(p, Number(v),
      this.setDomofinance(null, v));
  }

  setContribution = (p, v) => {
    const { setData, merge } = this.props;
    setData(p, v);
    const amount = merge.getIn(paths.amount) - v;
    this.setState({
      amount,
      rangeData: getRange(amount / 100)
    }, () => this.setDomofinance(amount, null));
  }

  handlePreview = () => {
    const { data, merge } = this.props;
    this.setState({
      previewLoading: true
    });
    handlePreviewDomofinance(data, merge)
      .finally(() => this.setState({
        previewLoading: false
      }));
  }

  render () {
    const {
      merge,
      setData,
      errors = [],
      user,
      isCaldeo
    } = this.props;
    const { rangeData, amount, previewLoading } = this.state;
    const duration = merge.getIn(paths.duration, 0);
    const error = errors.find(({ path, error }) =>
      JSON.stringify(path) === JSON.stringify(paths.amount) &&
      error);
    const recAccess = permit(user, { key: 'caldeoSpecial' });

    return (
      <Grid item xs={12}>
        <Box>
          <Grid container spacing={2}>
            <MoneyInput
              path={paths.amount}
              error={!!error}
              label="Montant du prêt"
              helperText={`Montant doit-être supérieur à ${
                merge.getIn(['combination', 'enableCaldeo'])
                  ? MIN_AMOUNT : MIN_AMOUNT_DEVIS} €`}
              defaultValue={amount || 0}
              setData={this.setAmount}
              grid
              xs={12}
              sm={6}
            />
            <CustomSelect
              path={paths.duration}
              label="Durée du crédit"
              disabled={!Object.keys(rangeData).length}
              value={(duration || ' ').toString()}
              setData={this.setDuration}
              children={items(rangeData.range || [])}
              grid
              xs={12}
              sm={6}
            />
            <MoneyInput
              fullWidth
              label={'Calcul des intérêt'}
              helperText={merge.getIn(paths.monthly) ?
                `Calcul: (${(merge.getIn(paths.monthly, 0) / 100).toFixed(2)
                } * ${duration}) - ${(amount / 100).toFixed(2)}` : ''}
              defaultValue={merge.getIn(paths.interest, 0)}
              disabled={!recAccess}
              path={paths.interest}
              setData={setData}
              grid
              xs={12}
              sm={6}
            />
            <MoneyInput
              fullWidth
              label={'Mensualité'}
              defaultValue={merge.getIn(paths.monthly, 0)}
              disabled={!recAccess}
              path={paths.monthly}
              setData={setData}
              grid
              xs={12}
              sm={6}
            />
            <MoneyInput
              label="Apport"
              defaultValue={merge.getIn(paths.contribution, 0)}
              path={paths.contribution}
              setData={this.setContribution}
              grid
              xs={12}
              sm={6}
            />
            <Grid item xs={12} sm={6}>
              {permit(user, { key: 'caldeoSpecial' }) ?
                <CustomCheckbox
                  label={`Dossier ${
                    merge.getIn(paths.valid) ? '' : 'non '}validé`}
                  checked={merge.getIn(paths.valid) || false}
                  path={paths.valid}
                  setData={setData}
                /> : ' '}
            </Grid>
            {!isCaldeo ? <Grid item xs={12} sm={6}>
              <CustomLoadingButton
                fullWidth
                loading={previewLoading}
                variant='contained'
                color="secondary"
                children="Visualiser le devis"
                onClick={this.handlePreview}
              />
            </Grid> : null}
            <Grid item xs={12} sm={isCaldeo ? 12 : 6}>
              <Button
                variant='contained'
                fullWidth
                color={
                  merge.getIn(paths.report) ?
                    'primary' : 'secondary'
                }
                children={
                  merge.getIn(paths.report, false) ?
                    'Avec report' : 'Sans report'
                }
                onClick={() =>
                  setData(paths.report,
                    !merge.getIn(paths.report))}
              />
            </Grid>
          </Grid>
        </Box>
      </Grid>
    );
  }

}
export default Content;
