import React, { PureComponent } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import {
  ThemeProvider,
  withStyles,
  createTheme,
} from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import lesbonsartisansdef from '../../public/lesbonsartisansdef.png';

const styles = {};
styles.login = {
  'margin-top': '30%',
  padding: 20,
  textAlign: 'right',
};
styles.img = {
  position: 'fixed',
  maxWidth: 300,
  top: 25,
  left: 50,
};
styles.marginBottom = {
  marginBottom: 20,
  display: 'flex',
};
styles.displayInput = {
  width: '45%',
  margin: '0 2.5%',
};
styles.marginTopLeft = {
  float: 'left',
};

const initState = {
  username: '',
  password: '',
  error: '',
};

const theme = createTheme({
  palette: {
    primary: blue,
  },
});
class Login extends PureComponent {
  constructor() {
    super();
    this.state = initState;
    this.login = this.login.bind(this);
  }
  login(e) {
    const { username, password } = this.state;
    if (this.props.login(username, password)) {
      this.setState(initState);
    }
    e.preventDefault();
  }

  render() {
    const classes = this.props.classes;
    return (
      <Grid className={classes.login}>
        <img
          src={lesbonsartisansdef}
          alt="logo"
          className={classes.img}
          width={'100%'}
        />
        <form onSubmit={this.login}>
          <ThemeProvider theme={theme}>
            <div className={classes.marginBottom}>
              <TextField
                label="Nom d'utilisateur"
                value={this.state.username}
                className={classes.displayInput}
                onChange={(e) => this.setState({
                  username: e.target.value.toLowerCase()
                })}
              />
              <TextField
                autoComplete="new-password"
                label="Mot de passe"
                type="password"
                value={this.state.password}
                className={classes.displayInput}
                onChange={(e) => this.setState({ password: e.target.value })}
              />
            </div>
            <Button
              variant="contained"
              color="primary"
              className={classes.marginTop}
              type={'submit'}
            >
              Connexion
            </Button>
          </ThemeProvider>
        </form>
      </Grid>
    );
  }
}

export default withStyles(styles)(Login);
