import React from 'react';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';

import { moment } from '@bit/dev-lba.lib.local-globals/moment';
import CustomSelect from '../../../../CustomInputs/CustomSelect';
import DatePicker from '../../../../DatePicker';
import { Typography } from '@material-ui/core';

const years = (() => {
  const start = moment();
  const dates = [];
  for (let index = 0; index < 10; index++) {
    dates.push(moment(start).add(index, 'year').year());
  }
  return dates;
})();

const months = (() => [...Array(12)].map((e, i) => i))();

const classNames = {
  overlay: {
    position: 'relative',
  },
};

export default ({ handleChange, date, paymentDate }) => (
  <Grid container spacing={1}>
    <Grid item xs={12}>
      <Typography variant="subtitle2">Date de carte bancaire</Typography>
    </Grid>
    <CustomSelect
      grid
      xs={6}
      label={'Mois'}
      value={moment(date).month()}
      path={['date']}
      setData={(p, v) =>
        handleChange('date')(
          moment(date || new Date())
            .month(v)
            .toDate()
        )
      }
    >
      {months.map((reason, index) => (
        <MenuItem key={index} value={reason}>
          {reason + 1}
        </MenuItem>
      ))}
    </CustomSelect>
    <CustomSelect
      grid
      xs={6}
      label={'Année'}
      value={moment(date).year()}
      path={['date']}
      setData={(p, v) =>
        handleChange('date')(
          moment(date || new Date())
            .year(v)
            .toDate()
        )
      }
    >
      {years.map((reason, index) => (
        <MenuItem key={index} value={reason}>
          {reason}
        </MenuItem>
      ))}
    </CustomSelect>
    <Grid item xs={12}>
      <Typography variant="subtitle2">Date d'échéance</Typography>
    </Grid>
    <Grid item xs={12}>
      <DatePicker
        dayPickerProps={{
          disabledDays: {
            before: moment().add(1, 'day').toDate(),
          },
        }}
        classNamesDate={classNames}
        label={'Date de paiement'}
        startDate={paymentDate}
        stateName={'date'}
        onChange={(d) => handleChange('paymentDate')(d.toDate())}
      />
    </Grid>
  </Grid>
);
