import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import StatsPanel from '../common/panel/StatsPanel';
import { moment } from '@bit/dev-lba.lib.local-globals/moment';
import api from '../../../api';
import notifSystem from '../../../notifSystem';
import { getLogin } from '../../../utils/function';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';

const styles = () => ({
  table: {
    minHeight: 450,
  },
});

const mapStateToProps = (state) => ({
  grandCompte: state.grandCompte,
});

class TodayStats extends Component {
  state = {
    mode: 'day',
    from: moment().startOf('day').toDate(),
    to: moment().endOf('day').toDate(),
    data: [],
    display: 10,
    page: 0,
  };

  onDatesChange = (from, to, mode) => {
    this.setState({ from, to, mode }, this.doRequests);
  };

  componentDidMount() {
    if (this.props.grandCompte.size) {
      this.doRequests();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.grandCompte !== this.props.grandCompte) {
      this.doRequests();
    }
  }

  doRequests() {
    const { from, to, display, page } = this.state;
    const { users, grandCompte } = this.props;
    api.stats
      .get('commercial/todayStats', {
        from,
        to,
        display,
        page,
        filters: JSON.stringify({
          $or: users.map((e) => ({
            'login.ajout': e._id,
            ...(e.wihoutGrandCompteCA && {
              'billing.grandCompte': {
                $nin: grandCompte.reduce(
                  (acc, curr) =>
                    curr.get('rating') ? acc.concat(curr.get('_id')) : acc,
                  []
                ),
              },
            }),
          })),
        }),
      })
      .then((result) =>
        this.setState({ data: result.body().map((e) => e.data()) })
      )
      .catch(() =>
        notifSystem.error('Erreur', 'Impossible de recuperer les statistiques')
      );
  }

  onChange = (field, value) => {
    this.setState({ [field]: value }, this.doRequests);
  };

  render() {
    const { data, mode, from, page, display, to } = this.state;

    const { users, classes } = this.props;

    return (
      <StatsPanel
        title={'Statistiques du jour'}
        onDatesChange={this.onDatesChange}
        defaultState={{
          mode,
          from,
          to,
        }}
      >
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Commercial</TableCell>
              <TableCell>CA en cours</TableCell>
              <TableCell>CA a programmer</TableCell>
              <TableCell>Intervention</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((user, i) => (
              <TableRow key={user._id}>
                <TableCell component="th" scope="row">
                  {i + 1}
                </TableCell>
                <TableCell component="th" scope="row">
                  {getLogin(user._id)}
                </TableCell>
                <TableCell>{user.CA}</TableCell>
                <TableCell>{user.CA_PROG}</TableCell>
                <TableCell>{user.count}</TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                count={users ? users.length : data.length}
                rowsPerPage={display}
                rowsPerPageOptions={[8, 16]}
                labelRowsPerPage={'Lignes par page:'}
                onChangeRowsPerPage={(e) =>
                  this.onChange('display', e.target.value)
                }
                onChangePage={(e, page) => this.onChange('page', page)}
                page={page}
                labelDisplayedRows={({ from, to, count }) =>
                  `${from}-${to} de ${count}`
                }
              />
            </TableRow>
          </TableFooter>
        </Table>
      </StatsPanel>
    );
  }
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps)
)(TodayStats);
