import React, { Component } from 'react';

import { Grid, Tab, Tabs } from '@material-ui/core';
import CallLogs from './CallLogs';

class CallLogInter extends Component {
  state={ tab: 0 }

  handleChangeTab = (event, tab) => this.setState({ tab });

  render() {
    const { sm, numbers } = this.props;
    const { tab } = this.state;
    return (
      <Grid item xs={12} sm={sm || 4}>
        <Tabs
          value={tab}
          onChange={this.handleChangeTab}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label="JOURNAL D'APPELS ENTRANT" />
          <Tab label="JOURNAL D'APPELS SORTANT" />
        </Tabs>
        <CallLogs
          keyUrl="inter"
          numbers={numbers}
          direction={!tab ? 'inbound' : 'outbound'}
        />
      </Grid>
    );
  }

}


export default CallLogInter;
