import React from 'react';
import { Link } from 'react-router-dom';
import api from '../../api';

import { withStyles } from '@material-ui/core/styles';
import { RadioButtonChecked, RadioButtonUnchecked } from '@material-ui/icons';
import ListItemText from '@material-ui/core/ListItemText';

import {
  D_OUV,
  S_INFO,
  A_DOCA,
  data as nStatus
} from '@bit/dev-lba.lib.local-globals/nStatus';
import { data as docTypes } from '@bit/dev-lba.lib.local-globals/docTypes';

import { getTime, shortDate } from '../../utils/date';
import styles from './styles';
import NotificationChip from './NotificationChip';

const handlers = {
  [D_OUV]: ({ category, deliveredAt, proposals, price }) => ({
    primaryText: `${category} - Envoyé le ${shortDate(new Date(deliveredAt))
    } - ${Number(proposals) >= 1 ? `${proposals} propositions` : price}`,
  }),
  [S_INFO]: ({ artisan, intervention, user, name, comment }) => {
    const artisanLink = artisan ? <Link
      to={`/artisan/${artisan.id}/recap`}
      children={artisan.name}
    /> : null;
    const interventionLink = intervention ? <Link
      to={`/intervention/${intervention}`}
      children={`l'OS ${intervention}`}
    /> : null;

    return {
      secondaryText: (
        <div>
          {`${user} a commenté le signalement ${name}`}
          {(artisanLink || interventionLink) && (
            <React.Fragment>
              {' concernant '}
              {artisanLink}
              {artisanLink && interventionLink && ' et '}
              {interventionLink}
            </React.Fragment>
          )}
          {` : ${comment}`}
        </div>
      ),
    };
  },
  [A_DOCA]: ({ name, types }) => ({
    secondaryText: `${name} a ajouté le(s) document(s) suivant(s): ${types
      .reduce(
        (a, v) => {
          const doc = docTypes.find(e => e._id === v) || {};
          return `${a ? `${a}, ` : ''}${doc.name || 'indisponible'}`;
        }, ''
      )}`
  })
};

const texts = notification => {
  const date = new Date(notification.date);
  let primaryText = `${shortDate(date)} - ${getTime(date)}`;
  let secondaryText = notification.text;
  if (notification.data && handlers[notification.status]) {
    const data = handlers[notification.status](notification.data);
    primaryText += data.primaryText ? ` - ${data.primaryText}` : '';
    secondaryText = data.secondaryText || secondaryText;
  }
  return [primaryText, secondaryText];
};

const handleClickSms = ({ _id, click }) => {
  if (!click) {
    api.notifications.patch(_id, { click: true });
  }
};

const Notification = ({ classes, notification }) => {
  const chipData =
    nStatus.find(s => s._id === notification.status) || nStatus[0];
  const [primaryText, secondaryText] = texts(notification);
  return (
    <div
      onClick={() => handleClickSms(notification)}
      className={classes.boxContent}
    >
      {notification.type === 'sms' && (notification.click ? (
        <RadioButtonUnchecked className={classes.redPoint} color="error" />
      ) : (
        <RadioButtonChecked className={classes.redPoint} color="error" />
      ))}
      <div className={classes.leftDiv}>
        <NotificationChip elem={chipData} />
      </div>
      <div className={classes.rightDiv}>
        <ListItemText
          primary={primaryText}
          secondary={secondaryText}
          primaryTypographyProps={{
            color: 'textSecondary',
            className: classes.primaryTypoStyle,
          }}
          secondaryTypographyProps={{
            color: 'textPrimary',
            className: classes.secondaryTypoStyle,
          }}
          className={classes.wrap}
        />
      </div>
    </div>
  );
};

export default withStyles(styles)(Notification);
