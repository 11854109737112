import React, { PureComponent } from 'react';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Money from '../../utils/Money';
import MinCustomDialog from './MinCustomDialog';
import MoneyInput from '../CustomInputs/MoneyInput';
import { Button, Typography } from '@material-ui/core';
import { connect } from 'react-redux';

const mapStateToProps = ({
  dialog: {
    contentProps: { interventions, finalPrice },
    actions,
  },
}) => ({ interventions, finalPrice, actions });
class SettlingInvoiceDialog extends PureComponent {
  state = {
    data: {
      ...this.props,
      interventions: this.props.interventions.map(e => ({
        ...e,
        finalPrice: e.finalPrice + (((e.tva || 10) / 100) * e.finalPrice),
      })),
    },
    update: false,
  };

  setData = (i, newPrice) => {
    const data = { ...this.state.data };
    data.interventions[i].finalPrice = newPrice;
    data.finalPrice = data.interventions
      .reduce((e, v) => e + (v.finalPrice || 0), 0);
    this.setState({ data, update: true });
  };

  render() {
    const { actions } = this.props;
    const { data, update } = this.state;
    return (
      <MinCustomDialog
        open
        middle
        title="Règlement du relevé"
        actions={[
          <Button
            key={1}
            onClick={actions[1].onClick}
            children={actions[1].children}
          />,
          <Button
            key={2}
            onClick={() => actions[0].onClick(!update || data)}
            children={actions[0].children}
          />,
        ]}
        children={[
          <Typography
            variant="h6"
            children={`Total TTC: ${Money.toString(data.finalPrice)}`}
          />,
          <Table>
            <TableBody
              children={data.interventions.map((e, i) => (
                <TableRow
                  key={e.id}
                  children={[
                    <TableCell key={`${i}-1-${e.id}`} children={e.id} />,
                    <TableCell
                      key={`${i}-2-${e.id}`}
                      children={
                        <MoneyInput
                          label="Montant TTC (€)"
                          defaultValue={e.finalPrice}
                          path={[]}
                          setData={(p, v) => this.setData(i, v)}
                          requiredText={true}
                        />
                      }
                    />,
                  ]}
                />
              ))}
            />
          </Table>,
        ]}
      />
    );
  }
}

export default connect(mapStateToProps)(SettlingInvoiceDialog);
