const Csv = require('./Csv');
const format = require('./format');
class CategorieConverter {
  constructor(input, output, options) {
    this.input = input;
    this.output = output;
    this.categories = options.categories;
    this.sub = options.subCategories;
    this.format = options.format === true;
  }

  getCsvInput(csvClass) {
    const csv = csvClass.toArray();
    return this.input.map((e) => {
      const index = [csv[0].indexOf(e)];
      if (index[0] === -1) {
        throw new Error(`Can't find ${e} in the given CSV`);
      }
      return index;
    });
  }

  formatting(str) {
    return this.format ? format(str) : str;
  }

  toCategorieId(category, sub) {
    const c = this.categories.find(
      (e) => this.formatting(e.name).toLowerCase() === category.toLowerCase()
    );
    if (c === undefined) {
      throw new Error('Unknown category');
    }
    const s = this.sub
      .filter((e) => e.parent === c._id)
      .find((e) => this.formatting(e.name).toLowerCase() === sub.toLowerCase());
    if (s === undefined) {
      throw new Error('Unknown sub category');
    }
    return s._id;
  }

  getCategoriesName(id) {
    const s = this.sub.find((e) => e._id === id);
    if (s === undefined) {
      throw new Error('Unknown sub category');
    }
    const c = this.categories.find((e) => e._id === s.parent);
    if (c === undefined) {
      throw new Error('Unknown category');
    }
    return [
      this.formatting(c.name).toUpperCase(),
      this.formatting(s.name).toUpperCase(),
    ];
  }

  toJson(csv) {
    const input = this.getCsvInput(csv);
    return csv.toArrayWithoutHeader().map((e) => {
      const obj = {};
      obj[this.output[0]] = this.toCategorieId(e[input[0]], e[input[1]]);
      return obj;
    });
  }

  toCsv(json) {
    return new Csv([
      this.input,
      ...json.map((e) => this.getCategoriesName(e[this.output[0]])),
    ]);
  }
}

export default CategorieConverter;
