import React, { Component } from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import {
  Replay as ReplayIcon,
  Description as ExportCSV,
} from '@material-ui/icons';

import ButtonTimeout from '../../ButtonTimeout';
import PropTypes from 'prop-types';
import { permit } from '@bit/dev-lba.lib.local-globals/restrictions';
import { Typography } from '@material-ui/core';

const styles = {
  toolbar: {
    justifyContent: 'flex-end',
    flex: '1 1 100%'
  },
  title: {
    flex: 'auto'
  }
};

class Header extends Component {
  reload = () => {
    this.props.updateTable(this.props.display,
      this.props.page, this.props.filter);
  }

  render() {
    const {
      classes,
      user,
      getCSV,
      title
    } = this.props;
    return (
      <Toolbar className={classes.toolbar}>
        {title ?
          <Typography
            variant="h6"
            component="h3"
            className={classes.title}
            children={title}
          /> :
          null}
        {user && permit(user, { key: 'exportListCSV' }) &&
          <Tooltip title="Export CSV">
            <ButtonTimeout ButtonComp={IconButton} timeout={5}
              onClick={() => getCSV()}
              aria-label="export CSV"
            >
              <ExportCSV />
            </ButtonTimeout>
          </Tooltip>
        }
        <Tooltip title="Recharger">
          <ButtonTimeout ButtonComp={IconButton} timeout={5}
            onClick={this.reload} aria-label="Recharger">
            <ReplayIcon />
          </ButtonTimeout>
        </Tooltip>
      </Toolbar>
    );
  }
}

Header.propTypes = {
  updateTable: PropTypes.func.isRequired,
  filter: PropTypes.object.isRequired,
  page: PropTypes.number.isRequired,
  display: PropTypes.number.isRequired
};

export default withStyles(styles)(Header);
