import { moment } from '@bit/dev-lba.lib.local-globals/moment';
import { onDownload } from '../../../../../utils/download';

const downloadIcon =
  'M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6' +
  '5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 ' +
  '6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM17 ' +
  '13l-5 5-5-5h3V9h4v4h3z';

export const getOption = function ({
  data,
  mode,
  labels,
  colors,
  dates,
  type,
  title,
  disposition,
  legend = true,
  disableToolbox = false,
  formatter,
  top,
  selected,
  lineStyle,
  showLabelOnTop = false,
  labelPoisition = 'top',
  yAxis,
  yAxisIndex
}) {
  let keys = [];
  if (mode === 'day') {
    keys = [
      '6h',
      '7h',
      '8h',
      '9h',
      '10h',
      '11h',
      '12h',
      '13h',
      '14h',
      '15h',
      '16h',
      '17h',
      '18h',
      '19h',
      '20h',
    ];
  } else if (mode === 'week') {
    keys = moment.weekdays(true);
  } else if (mode === 'month') {
    const length = dates[1].diff(dates[0], 'day', false) + 1;
    keys = Array.from({ length }, (v, i) => i + 1);
  } else if (mode === 'year') {
    keys = moment.monthsShort();
  } else if (mode === 'years') {
    keys = [
      '2019',
      '2020',
      '2021',
      '2022',
      '2023',
      '2024',
      '2025',
      '2026',
      '2027',
      '2028',
    ];
  }

  let series = [],
    customData = [],
    customSelected = {};

  Object.keys(data).forEach((v, i) => {
    const element = {
      name: v,
      label: {
        show: showLabelOnTop,
        position: labelPoisition,
      },
      type: disposition && disposition[i] ? disposition[i] : 'bar',
      data: !isNaN(type) ? data[v][type] : data[v],
      ...(lineStyle && lineStyle[i] && { lineStyle: lineStyle[i] }),
      yAxisIndex: yAxisIndex && yAxisIndex[i] && data[v] ? yAxisIndex[i] : 0,
    };
    if (!labels || labels.some((s) => s === v)) {
      series.push(element);
    }
    customData.push(element);
    if (selected) {
      customSelected[v] = selected[i];
    }
  });

  return {
    title: {
      text: title,
      left: 'center',
    },
    legend: {
      show: legend,
      data: labels,
      top: top || '10%',
      selected: customSelected,
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      formatter: formatter,
    },
    toolbox: disableToolbox
      ? {}
      : {
        show: true,
        orient: 'horizontal',
        bottom: 'bottom',
        left: 'center',
        feature: {
          restore: { show: true, title: 'D' },
          myTool2: {
            show: true,
            title: 'Télécharger',
            icon: `path://${downloadIcon}`,
            onclick: () => {
              const dataNames = Object.keys(data);
              let csv = ` ,${dataNames.join(', ')}\n`;
              csv += keys.reduce((e, v, i) => {
                e += `${v}, ${dataNames.map((e) => data[e][i]).join(', ')}\n`;
                return e;
              }, '');
              onDownload(
                {
                  contents: csv,
                  filename: moment().format('L[-LBA]'),
                },
                'csv'
              );
            },
          },
          magicType: { show: true, type: ['line', 'bar'], title: '' },
        },
      },
    xAxis: [
      {
        type: 'category',
        axisTick: { show: false },
        data: keys,
      },
    ],
    ...(yAxis ? { yAxis } : { yAxis: [
      {
        type: 'value',
      },
    ]
    }),
    dataZoom: [
      {
        type: 'inside',
        throttle: 50,
      },
    ],
    ...(colors ? { color: colors } : {}),
    series,
    mode,
    customData,
  };
};
