export const millisecond = 1;
export const second = 1000 * millisecond;
export const minute = 60 * second;
export const hour = 60 * minute;
export const day = 24 * hour;
export const year = 365 * day;

export const days = [
  'lun',
  'mar',
  'mer',
  'jeu',
  'ven',
  'sam',
  'dim'
];

export const months = [
  'jan',
  'fev',
  'mar',
  'avr',
  'mai',
  'juin',
  'juil',
  'aout',
  'sep',
  'oct',
  'nov',
  'dec'
];

export const monthsNumber = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12
];

export const monthsComplete = [
  'janvier',
  'février',
  'mars',
  'avril',
  'mai',
  'juin',
  'juillet',
  'août',
  'septembre',
  'octobre',
  'novembre',
  'décembre'
];

export const hours = [
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20
];

export const durations = [
  { name: 'Jour', label: 'day' },
  { name: 'Semaine', label: 'week' },
  { name: 'Mois', label: 'month' },
  { name: 'Année', label: 'year' },
];

export const todayToString = (date) => (
  `${days[date.getDay() ? date.getDay() - 1
    : date.getDay() + 6]}. ${date.getDate()} ${months[date.getMonth()]}.` +
  ` ${date.getFullYear()}`
);

export const monthToString = (date, monthDecal = 0) => {
  let m = date.getMonth() + monthDecal;
  const year = date.getFullYear() + Math.floor(m / 12);
  m = ((m < 0) ? 12 : 0) + (m % 12);
  return (`${months[m]}. ${year}`);
};

function formatLen(num) {
  return num > 9 ? num : `0${num}`;
}

export function stringToTime(time) {
  const hours = Number(time.substring(0, 2));
  const minutes = Number(time.substring(3, 5));

  return ((hours * 60) + minutes);
}

export function timeToString(time) {
  const hours = formatLen(Math.floor(time / 60));
  const minutes = formatLen(time % 60);
  return `${hours}:${minutes}`;
}
export function dateToString(date) {
  const day = formatLen(date.getDate());
  return `${day} ${monthsComplete[date.getMonth()]} ${date.getFullYear()}`;
}

export function shortDate(date) {
  const day = formatLen(date.getDate());
  return `${day}/${formatLen(date.getMonth() + 1)}/${date.getFullYear()}`;
}

export function getDate(date) {
  const day = formatLen(date.getDate());
  return `${date.getFullYear()}-${formatLen(date.getMonth() + 1)}-${day}`;
}

export function getTime(date) {
  return `${date.getHours().toString().padStart(2, '0')}:` +
    `${date.getMinutes().toString().padStart(2, '0')}`;
}

export function formatDate(date) {
  const format = date.toLocaleDateString('fr');
  return format === 'Invalid Date' ? '' : format;
}

export function formatCompleteDate(date) {
  const format = date.toLocaleString('fr');
  return format === 'Invalid Date' ? '' : format;
}

export function getYearFromNow(from) {
  const today = new Date().getFullYear() + 1;
  const allYear = [from];
  while (from < today) {
    from++;
    allYear.unshift(from);
  }
  return allYear;
}

export function generateTime(date) {
  const time = date.getTime();
  return new Date(time + (2 * hour) + (15 * minute) - (time % (15 * minute)));
}
