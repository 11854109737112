import React, { PureComponent } from 'react';
import { fromJS, List } from 'immutable';

import { withStyles } from '@material-ui/core/styles';

import api from '../../api';
import SmsChat from './SmsChat';
import ws from '../../ws';
import notifSystem from '../../notifSystem';
import { CircularProgress, Grid, Typography } from '@material-ui/core';

const styles = {
  centeredDiv: {
    width: '100%',
    minHeight: 350,
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  span: {
    fontSize: 12,
    minHeight: 20,
    alignItems: 'center',
  },
  toolbar: {
    marginBottom: 2,
    borderTop: '1px solid rgba(0,0,0,0.125)',
  },
  linearDiv: {
    paddingLeft: 24,
    paddingRight: 24,
    marginTop: -19,
  },
  linear: {
    height: 1,
  },
  textField: {
    width: '100%'
  },
};

class SmsContainer extends PureComponent {
  state = {
    display: 10,
    page: 0,
    data: new List(),
    id: this.props.id,
    name: this.props.name,
    filters: this.props.filters,
    canGetMore: true,
    error: null,
    loading: false
  };

  setData = data => {
    if (data) {
      this.setState({
        data,
      });
    } else {
      notifSystem.error('Erreur', 'Données SMS non récupérées');
    }
  };

  loadData = filter => {
    const { data, display, page } = this.state;
    this.setState({ loading: true });
    api.sms
      .getAll({
        query: JSON.stringify(filter),
        display,
        page,
        sort: { date: -1 },
      })
      .then(res => {
        const newData = fromJS(res.body().map(e => e.data()));
        this.setState({
          data: new List([...newData.reverse(), ...data]),
          canGetMore: !!newData.size,
          page,
          error: null,
        });
      })
      .catch(error => {
        this.setState({
          data: new List(),
          canGetMore: false,
          error,
        });
      })
      .finally(() => this.setState({ loading: false }));
  };

  componentDidMount() {
    this.getData(this.props);
    ws.on('update_sms', this.wsUpdate);
  }

  getData = () => {
    const { id, name } = this.state;
    if (id && name) {
      this.loadData({ [name]: id });
    }
  };

  wsUpdate = obj => {
    const { id, data, name } = this.state;

    if (obj[name] === id) {
      this.setState({
        display: 10,
        page: 0,
        data: new List([...data, fromJS(obj)]),
      });
    }
  };

  componentWillUnmount() {
    ws.removeEventListener('update_sms', this.wsUpdate);
  }

  componentDidUpdate(props) {
    if (this.props.id !== props.id) {
      this.setState(
        {
          display: 10,
          page: 0,
          id: this.props.id,
          name: this.props.name,
          filters: this.props.filters,
          data: new List(),
        },
        () => this.getData()
      );
    }
  }

  getMoreMessage = () => {
    this.setState({ page: this.state.page + 1 }, this.getData);
  }

  render() {
    const { classes } = this.props;
    const {
      id, name, index, page, data, error, canGetMore, loading, filters
    } = this.state;
    return error ? (
      <h4
        style={{
          color: 'red',
          lineHeight: 3,
          margin: 0,
          textAlign: 'center',
        }}
      >
        {error.message}
      </h4>
    ) : (
      <div>
        {loading
          ? loading && (
            <Grid container justify="center" alignItems="center">
              <Grid item className={classes.centeredDiv}>
                <CircularProgress size={70} />
                <Typography variant="h6">Chargement en cours...</Typography>
              </Grid>
            </Grid>
          )
          : (!data.size && (
            <div key={1}>
              <div className={classes.centeredDiv}>
                <span className={classes.span}>Aucun message disponible</span>
              </div>
            </div>
          )) ||
        <SmsChat
          key={2}
          {...{
            data,
            name,
            id,
            index,
            classes,
            setData: this.setData,
            getMoreMessage: this.getMoreMessage,
            canGetMore,
            page,
            filters
          }}
        />}
      </div>
    );
  }
}

export default withStyles(styles)(SmsContainer);
