import AbstractGraph from '../../common/graph/AbstractGraph';
import {
  amber,
  blue,
  blueGrey,
  deepOrange,
  deepPurple,
  green,
  grey,
  orange,
  pink,
  purple,
  red,
  yellow
} from '@material-ui/core/colors';
import { getOption } from '../../common/graph/options/globalPieOptions';
import { getLogin } from '../../../../utils/function';
import StatsTableSupport from '../StatsTableSupport';
import StatsSupportByCategory from '../StatsSupportByCategorie';
import AbstractGraphSupportActions from '../AbstractGraphSupportActions';

export const userTabs = () => [
  {
    label: 'Interventions',
    display: AbstractGraph,
    colors: [green[500], orange[500], red[500], blue[500]],
    errorMessage: 'Impossible de charger les statistiques d\'interventions',
    fn: 'getVerifiedIntervention',
    enableCA: true,
    showInDashboard: false,
  },
  {
    label: 'Devis',
    display: AbstractGraph,
    colors: [green[800], blue[800], purple[800]],
    errorMessage: 'Impossible de charger les statistiques de devis',
    fn: 'getDevisCA',
    enableCA: true,
    showInDashboard: false,
  },
  {
    label: 'Artisans',
    display: AbstractGraph,
    colors: [green[500], blue[500]],
    errorMessage: 'Impossible de charger les statistiques d\'artisans',
    fn: 'getArtisanIntervention',
    enableCA: true,
    showInDashboard: true,
  },
  {
    label: 'Signalements',
    display: AbstractGraph,
    errorMessage: 'Impossible de charger les statistiques de signalements',
    colors: [
      pink[400],
      red[800],
      purple[800],
      blue[200],
      red[100],
      blue[900],
      yellow[800],
      blueGrey[500],
      deepOrange[500],
      deepPurple['A700'],
      amber['A400'],
    ],
    fn: 'getSignalements',
    legend: false,
    showInDashboard: true,
  },
  {
    label: 'Groupes (A programmer)',
    display: AbstractGraph,
    errorMessage: 'Impossible de charger les statistiques',
    colors: [red[500], blue[500]],
    fn: 'getToProgram',
    legend: false,
    customOption: (data, user) =>
      getOption({
        data,
        roundRadius: true,
        colors: [green[500], blue[500]],
        format: true,
        label: false,
        title: user ? `Equipe de ${getLogin(user)}` : 'Global',
      }),
    enableCA: true,
    OneDefault: true,
    showInDashboard: true,
  },
  {
    label: 'Envoie par équipe',
    display: AbstractGraph,
    errorMessage: 'Impossible de charger les statistiques',
    fn: 'getTeamSend',
    legend: false,
    colors: [green[500], orange[500], blue[500], red[500]],
    enableCA: true,
    showInDashboard: false,
    enableSubStatus: true,
  },
  {
    label: 'Envoie/Annulation',
    display: AbstractGraph,
    errorMessage: 'Impossible de charger les statistiques',
    fn: 'getAnnulationEnvoi',
    legend: false,
    colors: [green[500], orange[500], red[200], red[800]],
    enableCA: true,
    showInDashboard: false,
    enableSubStatus: false,
  },
  {
    label: 'Demande en ligne',
    display: AbstractGraph,
    errorMessage: 'Impossible de charger les statistiques',
    fn: 'getDemandeEnLigne',
    legend: false,
    colors: [blue[500], red[500], purple[500], blueGrey[900], green[200]],
    enableCA: true,
    showInDashboard: false,
    enableSubStatus: false,
  },
  {
    label: 'Pourcentage artisan',
    display: AbstractGraph,
    errorMessage: 'Impossible de charger les statistiques',
    fn: 'getPercentArtisanSend',
    legend: false,
    customOption: (data) =>
      getOption({
        data,
        roundRadius: true,
        colors: [green[500], orange[500], red[500]],
        format: true,
        label: false,
        formatter: '{d}% : {c}',
      }),
    enableCA: true,
    showInDashboard: false,
    enableSubStatus: false,
  },
  {
    label: 'Mise en relation client',
    display: AbstractGraph,
    errorMessage: 'Impossible de charger les statistiques',
    fn: 'getMiseEnRelationClient',
    customOption: (data) =>
      getOption({
        data,
        roundRadius: true,
        colors: [green[500], orange[500], red[500]],
        format: true,
        label: false,
      }),
    legend: false,
    enableCA: true,
    showInDashboard: false,
    enableSubStatus: false,
  },
  {
    label: 'Pourcentage global artisan',
    display: AbstractGraph,
    errorMessage: 'Impossible de charger les statistiques',
    fn: 'getGlobalArtisanPercent',
    legend: false,
    customOption: (data) =>
      getOption({
        data,
        roundRadius: true,
        colors: [green[500], orange[500], red[500]],
        format: true,
        label: false,
        formatter: '{d}% : {c}',
      }),
    enableCA: true,
    showInDashboard: false,
    enableSubStatus: false,
  },
  {
    label: 'Qualité support',
    display: StatsTableSupport,
    errorMessage: 'Impossible de charger les statistiques',
    fn: 'getCommissionsStats',
    legend: false,
    enableCA: true,
    showInDashboard: false,
    enableSubStatus: false,
    enabledModes: [false, false, true, false],
    mode: 'month',
  },
  {
    label: 'Qualité support par critère',
    display: StatsSupportByCategory,
    errorMessage: 'Impossible de charger les statistiques',
    fn: 'getCommissionsStatsByCategory',
    showInDashboard: false,
    enableSubStatus: false,
    mode: 'month',
  },
  {
    label: 'Commissions',
    display: AbstractGraphSupportActions,
    colors: [grey[500], green[500], blue[500], red[500], red[500]],
    errorMessage: 'Impossible de charger les statistiques d\'interventions',
    fn: 'getManagerCommissionIntervention',
    disposition: ['bar', 'bar', 'bar', 'bar', 'line'],
    enableCA: true,
    legend: false,
    showInDashboard: false,
    enableSubStatus: false,
    mode: 'year',
  },
  {
    label: 'Chiffre d\'affaire pour l\'auteur',
    display: AbstractGraphSupportActions,
    colors: [grey[500], green[500]],
    errorMessage: 'Impossible de charger les statistiques d\'interventions',
    fn: 'getManagerCA',
    enableCA: true,
    showInDashboard: false,
    mode: 'year',
  },
  {
    label: 'Mise à jour artisans',
    display: AbstractGraphSupportActions,
    colors: [blue[500], green[500], deepOrange[500], green[300]],
    errorMessage: 'Impossible de charger les statistiques',
    fn: 'getMajSST',
    enableCA: true,
    showInDashboard: false,
    mode: 'year',
    date: 'date.intervention',
    disposition: ['bar', 'bar', 'bar', 'line'],
    yAxis: [
      {
        type: 'value',
        name: 'Demandes de MAJ',
        position: 'right',
        alignTicks: true,
        axisLine: {
          show: true,
          lineStyle: {
            color: blue[500]
          }
        },
      },
      {
        type: 'value',
        name: 'Interventions',
        position: 'left',
        alignTicks: true,
        axisLine: {
          show: false,
          lineStyle: {
            color: green[500]
          }
        },
      }
    ],
    yAxisIndex: [0, 0, 0, 1]
  },
];
