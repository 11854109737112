import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

const styles = {
  overflow: {
    height: '100%',
    overflowY: 'auto'
  }
};

class WhenBottom extends Component {
  static propTypes = {
    offset: PropTypes.number,
    onTrigger: PropTypes.func.isRequired
  }

  static defaultProps = {
    offset: 0
  }

  loading = false

  handleScroll = ({ target }) => {
    const { offset } = this.props;
    return target.clientHeight + offset > target.scrollHeight - target.scrollTop
      && this.trigger();
  }

  trigger = async () => {
    const { onTrigger } = this.props;

    if (this.loading) {
      return;
    }
    this.loading = true;
    await onTrigger();
    this.loading = false;
  }

  render() {
    const {
      classes,
      children
    } = this.props;

    return <div
      className={classes.overflow}
      onScroll={this.handleScroll}
      children={children}
    />;
  }
}

export default withStyles(styles)(WhenBottom);
