import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import StatsTelepro from './StatsTelepro';
import PropTypes from 'prop-types';


const styles = {
  StatsTelepro: {
    position: 'absolute',
    right: 0,
    left: 0,
    bottom: 0,
    height: 40,
    width: '100%',
    zIndex: 4,
    overflow: 'hidden',
    boxShadow: '6px 2px 5px 3px rgba(0,0,0,0.31)',
    backgroundColor: '#fff'
  }
};

export class SideBarStats extends Component {
  state = {
    showButton: false,
  };

  handleClick = () => {
    this.props.openStats();
  }

  onMouseLeaveHandler = () => {
    this.setState({
      showButton: false
    });
  }

  onMouseEnterHandler = () => {
    this.setState({
      showButton: true
    });
  }

  render() {
    const {
      classes,
      service
    } = this.props;
    return (
      <div className={classes.StatsTelepro}>
        <StatsTelepro service={service} />
      </div>
    );
  }
}

SideBarStats.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SideBarStats);
