import React, { PureComponent } from 'react';
import { Grid, Paper } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import StatsPanel from '../common/panel/StatsPanel';
import { getPieOptions } from './options/getPieOption';
import { getClassementOptions } from './options/getClassementOptions';
import { data as categories } from '@bit/dev-lba.lib.local-globals/categories';
import api from '../../../api';
import { moment } from '@bit/dev-lba.lib.local-globals/moment';
import { getCustomFromUser, getLogin } from '../../../utils/function';
import notifSystem from '../../../notifSystem';
import GenericStats from './genericStats';

import {
  SAV_APR, SAV_AREF, SAV_AVR, SAV_ENC
} from '@bit/dev-lba.lib.local-globals/SAVStatus';

import {
  blue, blueGrey, brown, green,
  orange, purple, red, yellow,
} from '@material-ui/core/colors';

import Counter from '../../Counter/status';

import {
  SAV_APP, SAV_DF, SAV_DFC, SAV_DSAV
} from '@bit/dev-lba.lib.local-globals/ListSAVInters';
import { S_SATCL } from '@bit/dev-lba.lib.local-globals/services';

const styles = {
  card: {
    borderRadius: 10,
    marginBottom: 15,
    position: 'relative',
  },
  button: {
    minWidth: '100%',
  },
  paperMargin: {
    marginBottom: 16
  },
  counters: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    width: '100%',
    justifyContent: 'space-around',
    marginBottom: 10
  }
};

const getCounters = (counters, total) => [
  {
    label: 'total',
    color: blueGrey[900],
    status: [SAV_DFC, SAV_DF],
    value: total
  },
  {
    label: 'Facture',
    color: purple[900],
    status: [SAV_DFC, SAV_DF],
    data: counters.interStatus
  },
  {
    label: 'Attestations',
    color: blue[500],
    status: SAV_APP,
    data: counters.interStatus
  },
  {
    label: 'Demandes',
    color: green[500],
    status: SAV_DSAV,
    data: counters.interStatus
  },
  {
    label: 'A prog',
    color: blue[300],
    status: SAV_APR,
    data: counters.savIntersStatus
  },
  {
    label: 'A verifier',
    color: brown[500],
    status: SAV_AVR,
    data: counters.savIntersStatus
  },
  {
    label: 'En cours',
    color: orange[500],
    status: SAV_ENC,
    data: counters.savIntersStatus
  },
  {
    label: 'A refuser',
    color: red[500],
    status: SAV_AREF,
    data: counters.savIntersStatus
  },
];

class SAVGlobalStats extends PureComponent {
  state = {
    status: '-1',
    from: moment()
      .startOf('month')
      .toDate(),
    to: moment()
      .endOf('month')
      .toDate(),
    mode: 'month',
  };

  componentDidMount() {
    this.doRequests();
  }

  onDatesChange = (from, to, mode) => {
    this.setState({ from, to, mode }, () => this.doRequests());
  };

  onStatusChange = status => {
    this.setState({ status }, () => this.doRequests());
  };

  doRequests = () => {
    const { from, to, mode, status } = this.state;
    const custom = api.stats.custom;

    Promise.all([
      custom(`sav/topFive/${from.getTime()}/${to.getTime()}`).get(),
      custom(`sav/categories/${from.getTime()}/${to.getTime()}/${mode}`).get(),
      custom(`sav/averageTime/${from.getTime()}/${to.getTime()}`).get(),
      custom(`sav/signals/emis/-1/${from.getTime()}/${to.getTime()}`).get(),
      custom(`sav/signals/gere/${status}/${from.getTime()}/${to.getTime()}`)
        .get(),
      custom('sav/signals/counters').get(),
      custom(`sav/refusCloture/${from.getTime()}/${to.getTime()}`).get(),
      custom(`sav/dossierRecouvrement/${from.getTime()}/${to.getTime()}`).get(),
      custom(`sav/averagePrice/${from.getTime()}/${to.getTime()}`).get(),
      custom(`sav/categoryTime/${from.getTime()}/${to.getTime()}`).get()
    ])
      .then(results =>
        this.setState({
          total: results[0].body().data().total,
          topSST: this.arrayToObject(results[0].body().data().sst),
          topUsers: this.arrayToObject(results[0].body().data().users, true),
          repartition: results[1].body().data().toSend,
          averageTime: results[2].body().data().toSend,
          signals: this.arrayToObject(results[3].body().data().result.
            filter(s => this.isSAV(s._id)), true),
          signalsGere: this.arrayToObject(results[4].body().data().result.
            filter(s => this.isSAV(s._id)), true),
          counters: results[5].body().data(),
          refus: this.arrayToObject(results[6].body().data().refus, true),
          cloture: this.arrayToObject(results[6].body().data().cloture, true),
          dossierRecouvrement: results[7].body().data().result,
          averagePrice: this.arrayToObject(results[8].body()
            .data().response, true),
          rdvTime: results[9].body().data().rdvTime
        })
      )
      .catch(() => notifSystem.error('Echec', 'Une erreur s\'est produite'));
  };

  arrayToObject(data, isUser) {
    return !data
      ? {}
      : data.reduce((acc, d) => {
        acc[isUser ? getLogin(d._id) : d._id] = Number(d.count).toFixed(2);
        return acc;
      }, {});
  }

  isSAV = (id) => {
    const service = getCustomFromUser(id, 'service');

    return service && service === S_SATCL;
  };

  toHours = (data) => moment.duration(Object.values(data)
    .reduce((acc, r) => acc + (+r), 0) /
          Object.values(data).length, 'ms')
    .asHours().toFixed(0);

  render() {
    const { classes } = this.props;
    const {
      topSST,
      averagePrice,
      topUsers,
      from,
      to,
      mode,
      repartition,
      averageTime,
      signals,
      signalsGere,
      counters,
      refus,
      cloture,
      dossierRecouvrement,
      rdvTime,
      total
    } = this.state;

    return (
      <Paper className={classes.paperMargin}>
        <StatsPanel
          onDatesChange={this.onDatesChange}
          title={'Statistiques S.A.V'}
          enabledModes={[true, true, true, false]}
          defaultState={{
            mode,
            from,
            to,
          }}
        >

          {counters && <div className={classes.counters}>
            {getCounters(counters, total).map(obj => (
              <Counter key={obj.label} data={obj.data} label={obj.label}
                color={obj.color} status={obj.status} value={obj.value}/>
            ))}
          </div>}
          <Grid
            container
            spacing={3}
            style={{ paddingLeft: 20, paddingRight: 20 }}
          >
            {topSST && <GenericStats
              classes={classes}
              show={topSST}
              option={getClassementOptions({
                title: 'Classement SST',
                labels: Object.keys(topSST),
                data: Object.values(topSST),
                color: green[300]
              })}
            />}

            {topUsers && <GenericStats
              classes={classes}
              show={topUsers}
              option={getClassementOptions({
                title: 'Classement utilisateurs',
                labels:
                      Object.keys(topUsers).sort((a, b) =>
                        topUsers[b] - topUsers[a]),
                data: Object.values(topUsers).sort((a, b) => b - a),
                color: blue[300]
              })}
            />}

            {signals && <GenericStats
              classes={classes}
              show={signals}
              option={getClassementOptions({
                title: 'Signalements emis',
                labels: Object.keys(signals)
                  .sort((a, b) => signals[a] - signals[b]),
                data: Object.values(signals).sort((a, b) => a - b),
                color: blue[500],
                vertical: false
              }
              )}
            />}

            {signalsGere && <GenericStats
              classes={classes}
              status={this.state.status}
              onStatusChange={this.onStatusChange}
              show={signalsGere}
              option={getClassementOptions({
                title: 'Signalements gerés',
                labels: Object.keys(signalsGere)
                  .sort((a, b) => signalsGere[a] - signalsGere[b]),
                data: Object.values(signalsGere).sort((a, b) => a - b),
                color: yellow[800],
                vertical: false
              })}
            />}

            {refus && <GenericStats
              classes={classes}
              show={refus}
              option={getClassementOptions({
                title: 'Refus',
                labels: Object.keys(refus)
                  .sort((a, b) => refus[a] - refus[b]),
                data: Object.values(refus).sort((a, b) => a - b),
                color: green[800],
                vertical: false
              })}
            />}

            {cloture && <GenericStats
              classes={classes}
              show={cloture}
              option={getClassementOptions({
                title: 'Clôture',
                labels: Object.keys(cloture)
                  .sort((a, b) => cloture[a] - cloture[b]),
                data: Object.values(cloture).sort((a, b) => a - b),
                color: blueGrey[500],
                vertical: false
              })}
            />}

            {dossierRecouvrement && <GenericStats
              classes={classes}
              show={dossierRecouvrement}
              option={getPieOptions({
                data: dossierRecouvrement,
                title: 'Contentieux',
                colors: [blue[700], orange[700]]
              })}
            />}

            {averagePrice && <GenericStats
              classes={classes}
              show={averagePrice}
              option={getClassementOptions({
                title: 'Remuneration moyenne',
                labels: Object.keys(averagePrice),
                data: Object.values(averagePrice),
                color: blue[900],
                vertical: false
              })}
            />}

            {rdvTime && <GenericStats
              classes={classes}
              show={rdvTime}
              option={getPieOptions({
                data: rdvTime,
                title: `Temps moyen de RDV | ${
                  this.toHours(rdvTime)
                } h`,
                colors: categories.map(e => e.color[500]),
                isDate: true,
              })}
            />}

            <GenericStats
              classes={classes}
              show={repartition}
              option={getPieOptions({
                data: repartition,
                title: 'Répartition des catégories',
                colors: categories.map(e => e.color[500]),
              })}
            />

            {averageTime && <GenericStats
              classes={classes}
              show={averageTime}
              option={getPieOptions({
                data: averageTime,
                title: `Temps moyen de cloture/refus | ${
                  this.toHours(averageTime)
                } h`,
                colors: categories.map(e => e.color[500]),
                isDate: true,
              })}
            />}

          </Grid>
        </StatsPanel>
      </Paper>
    );
  }
}

export default withStyles(styles)(SAVGlobalStats);
