import React, { Component } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { UserCard, TrustBox } from '../../components/Stats';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import badges from '../../components/partenariatDashboard/badges';
import styles from '../../components/partenariatDashboard/styles';
import api from '../../api';
import { S_PART } from '@bit/dev-lba.lib.local-globals/services';
import UserHolidaysConfig from '../../components/Users/holidays';
import ObjectifsContainer from './utils/ObjectifsContainer';
import CardsPartenariat
from '../../components/partenariatDashboard/CardsPartenariat';
import { getUserTeam } from '../../utils/teams';

const mapStateToProps = ({ users, userId }) => ({
  teamUser: getUserTeam(users, userId),
  user: users.find((e) => e._id === userId),
});

class PartenariatDashboard extends Component {
  state = {
    tmp: '',
    data: {},
  };

  loadStats() {
    const { teamUser } = this.props;
    return api.stats
      .get('partenariat/personal', {
        departments: teamUser && teamUser.areas,
      })
      .then((res) => res.body().data());
  }

  componentDidMount() {
    this.loadStats().then((data) =>
      this.setState({
        data,
      })
    );
  }

  badgeInterGen(classes, categories) {
    return badges(categories).map((b) => {
      const Icon = b.icon;
      return (
        <Grid item xs={3} key={b.name}>
          <Icon w={50} h={50} color="#1b2150" />
          <Typography component="p">
            <span
              className={classnames(classes.block, classes.designNbr)}
              style={{ background: b.color[500] }}
            >
              {b.nbrInter}
            </span>
            <span className={classes.valMarketPrice}>{b.valueMarket}</span>
          </Typography>
        </Grid>
      );
    });
  }

  render() {
    const { user, classes } = this.props;
    const { data } = this.state;

    return (
      <React.Fragment>
        <Grid container spacing={2}>
          <Grid container spacing={2} item>
            <Grid item md={12} lg={8}>
              <UserCard user={user} schudleStats={false}>
                <section
                  className={classnames(
                    classes.centerTxt,
                    classes.customSectionMarket
                  )}
                >
                  <section className={classes.displayBarMarket}>
                    {this.badgeInterGen(classes, data.categories)}
                  </section>
                </section>
              </UserCard>
            </Grid>
            <Grid item md={12} lg={4}>
              <ObjectifsContainer user={user} service={S_PART} />
            </Grid>
          </Grid>
          <Grid
            container
            item
            spacing={1}
            alignContent="stretch"
            alignItems="stretch"
          >
            <Grid item xs={6}>
              <CardsPartenariat />
            </Grid>
            <Grid item xs={6}>
              <UserCard user={user} schudleStats={true} />
            </Grid>
          </Grid>
          <Grid item md={12}>
            <Paper className={classes.paper}>
              <TrustBox />
            </Paper>
          </Grid>
          <Grid item md={12}>
            <UserHolidaysConfig />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(
  connect(mapStateToProps, null)(PartenariatDashboard)
);
