import Stats from '../../views/Stats';
import ReportPage from '../../views/ReportPage';
import Search from '../../views/Search';

import {
  NOT_CONFIRMED,
  USER,
} from '@bit/dev-lba.lib.local-globals/permissions';

import ModificationsView from '../../views/Modifications';
import compta from './compta';
import rh from './rh';
import cti from './cti';
import btob from './btob';
import devis from './devis';
import intervention from './intervention';
import partenariat from './partenariat';
import quality from './quality';
import savIntervention from './savIntervention';
import utils from './utils';
import achat from './achat';

export default [
  ...intervention,
  ...quality,
  ...devis,
  ...partenariat,
  ...compta,
  ...savIntervention,
  ...btob,
  ...rh,
  ...cti,
  ...achat,
  ...utils,
  {
    path: '/modifications/:collection',
    component: ModificationsView,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/signalements',
    component: ReportPage,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/search/:text',
    component: Search,
    restrictions: () => USER,
  },
  {
    path: '/',
    component: Stats,
    restrictions: () => USER,
  },
];
