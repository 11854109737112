import React, { PureComponent } from 'react';
import { moment } from '@bit/dev-lba.lib.local-globals/moment';
import ReactEcharts from 'echarts-for-react';

import { Paper } from '@material-ui/core';
import { blue, green, red } from '@material-ui/core/colors';
import api from '../../../api';
import notifSystem from '../../../notifSystem';
import StatsPanel from '../common/panel/StatsPanel';
import { getOption } from '../common/graph/options/globalOptions';
import { setDialog } from '../../../actions/dialog';
import store from '../../../store';
import { modes, modeIndex } from '@bit/dev-lba.lib.local-globals/stats';
import {
  I_VRF,
  I_ENC,
  I_AVR,
  I_ANN
} from '@bit/dev-lba.lib.local-globals/iStatus';
import Dropdown from '../common/dropdown/dropdown';
import { connect } from 'react-redux';
import { S_INT } from '@bit/dev-lba.lib.local-globals/services';

const mapStateToProps = (state) => ({
  userId: state.userId,
  users: state.users,
});

const filters = selectedUser => ({
  Potentiel: {
    status: { $in: [I_VRF, I_ENC, I_AVR] },
    'priorityFollow': { $elemMatch: { $eq: selectedUser } }
  },
  Reçu: {
    isRegle: true,
    'priorityFollow': { $elemMatch: { $eq: selectedUser } }
  },
  Annulées: {
    status: I_ANN,
    $or: [
      { 'priorityFollow': { $elemMatch: { $eq: selectedUser } } },
      { 'priorityFollowDeleted': { $elemMatch: { $eq: selectedUser } } }
    ],
  }
});

class GraphStatsObjectif extends PureComponent {
  state = {
    mode: 'month',
    from: moment().add(-1, 'month').startOf('month').toDate(),
    to: moment().add(-1, 'month').endOf('month').toDate(),
    selectedUser: '',
    users: []
  };

  componentDidMount() {
    const { users, userId } = this.props;
    this.setState(
      {
        users: users.filter((s) => s.service === S_INT && s.act),
        selectedUser: userId,
      },
      this.doRequests
    );
  }

  onDatesChange = (from, to, mode) => {
    this.setState({ from, to, mode }, this.doRequests);
  };

  handleDialogInterListOpen = ({ dataIndex, seriesName }) => {
    const { mode, from, selectedUser } = this.state;
    let typeMode = modes[mode];
    let index = Object.keys(modeIndex[mode]).find(
      (e) => modeIndex[mode][e] === dataIndex
    );
    if ((typeMode === 'day' && mode === 'week') || typeMode === 'month') {
      index = index - 1;
    } else if (typeMode === 'day') {
      typeMode = 'date';
    }
    const data = {
      ...(filters(selectedUser)[seriesName] || {}),
      'date.ajout': {
        $gt: moment(from).set(typeMode, index).startOf(typeMode).toDate(),
        $lt: moment(from).set(typeMode, index).endOf(typeMode).toDate(),
      },
    };
    return store.dispatch(
      setDialog({
        name: 'InterListDialog',
        open: true,
        hideClose: true,
        dialogProps: {
          title: 'Liste d\'intervention',
          maxWidth: 'xl',
        },
        contentProps: {
          filter: data,
          sort: { finalPrice: -1 },
        },
        actions: [
          {
            children: 'Fermer',
            color: 'secondary',
            onClick: (r, close) => close(),
          },
        ],
      })
    );
  };

  doRequests = () => {
    const { mode, from, to, selectedUser } = this.state;
    api.stats
      .custom('commercial/priorityFollow')
      .get({ mode, from, to, userId: selectedUser })
      .then((result) => this.setState({ data: result.body().data().data }))
      .catch(() =>
        notifSystem.error('Erreur', 'Impossible de recuperer les statistiques')
      );
  };

  render() {
    const { mode, from, to, data, users } = this.state;
    return (
      <React.Fragment>
        <Paper>
          <StatsPanel
            title={'Statistiques suivies prioritaires'}
            onDatesChange={this.onDatesChange}
            defaultState={{ mode, from, to }}
            enabledModes={[false, true, true, true]}
            body={
              <Dropdown
                title={'Login'}
                multiple={false}
                data={users}
                renderKey="login"
                onChange={(e) =>
                  this.setState(
                    {
                      selectedUser: e.target.value,
                    },
                    this.doRequests
                  )
                }
              />
            }
          >
            {data && (
              <ReactEcharts
                notMerge={true}
                lazyUpdate={true}
                style={{ height: 380 }}
                option={getOption({
                  data,
                  mode,
                  dates: [moment(from), moment(to)],
                  colors: [blue[500], green[600], red[500]],
                  disposition: ['bar', 'bar', 'bar'],
                  selected: [true, true, true],
                })}
                onEvents={{ click: this.handleDialogInterListOpen }}
              />
            )}
          </StatsPanel>
        </Paper>
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps)(GraphStatsObjectif);
