import React from 'react';
import { Chip, Avatar } from '@material-ui/core';

const GSMCounter = ({ color, classes, value, Icon }) => (
  <Chip
    className={classes.chip}
    avatar={
      <Avatar style={{ backgroundColor: color }}>
        <Icon className={classes.white} />
      </Avatar>
    }
    label={value}
  />
);

export default GSMCounter;
