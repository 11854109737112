import React from 'react';

import { withStyles } from '@material-ui/core/styles';


import {
  LinearProgress,
  Paper,
} from '@material-ui/core';

import styles from './style';

import ObjectifPersonal from './ObjectifPersonal';
import ObjectifPercent from './ObjectifPercent';

const Objectifs = ({ classes, loading, ...props }) => {
  const ObjectifComp = props.personal ? ObjectifPersonal : ObjectifPercent;
  return <Paper className={classes.root}>
    {loading && <LinearProgress />}
    {!loading && <ObjectifComp {...props} />}
  </Paper>;
};

export default withStyles(styles)(Objectifs);
