import React from 'react';
import { connect } from 'react-redux';

import { Grid, MenuItem, Typography } from '@material-ui/core';

import categories from '@bit/dev-lba.lib.local-globals/categories';

import { setDialogContentProp } from '../../actions/dialog';
import Selector from '../Utils/Selector';
import CustomTextField from '../CustomInputs/CustomTextField';
import CustomSelect from '../CustomInputs/CustomSelect';

const setClientName = setDialogContentProp('clientName');
const setClientFirstName = setDialogContentProp('clientFirstName');
const setClientZip = setDialogContentProp('clientZip');
const setClientPhone = setDialogContentProp('clientPhone');
const setClientCategorie = setDialogContentProp('clientCategorie');
const setDescription = setDialogContentProp('description');
const setClientEmail = setDialogContentProp('clientEmail');
const setClientCivility = setDialogContentProp('clientCivility');
const setClientVoie = setDialogContentProp('clientVoie');
const setClientCity = setDialogContentProp('clientCity');

const decode = str => [...str]
  .filter(char => char.charCodeAt(0) <= 127).join('');

const categoriesMethodsSelect = categories.data
  .map((e) => (
    <MenuItem key={e._id} value={e._id} children={e.name} />
  ));

const mapStateToProps = ({
  dialog: {
    contentProps: {
      clientPhone,
      clientName,
      clientFirstName,
      clientZip,
      clientCategorie,
      description,
      clientEmail,
      clientCivility,
      clientVoie,
      clientCity
    },
  },
}) => ({
  clientPhone,
  clientName,
  clientFirstName,
  clientZip,
  clientCategorie,
  description,
  clientEmail,
  clientCivility,
  clientVoie,
  clientCity
});

const mapDispatchToProps = {
  setClientPhone,
  setClientName,
  setClientZip,
  setClientCategorie,
  setDescription,
  setClientEmail,
  setClientFirstName,
  setClientCivility,
  setClientVoie,
  setClientCity
};

const OnlineDemande = ({
  clientName,
  clientFirstName,
  clientZip,
  clientPhone,
  clientCategorie,
  clientEmail,
  clientCivility,
  description,
  clientVoie,
  clientCity,
  setClientCategorie,
  setClientName,
  setClientFirstName,
  setClientPhone,
  setClientZip,
  setDescription,
  setClientEmail,
  setClientCivility,
  setClientVoie,
  setClientCity
}) => (
  <Grid container spacing={1} alignItems="center">
    <Grid item xs={12}>
      <Typography children="Formulaire de prise de contact" />
    </Grid>
    <Grid item xs={12} sm={12}>
      <Selector
        title={'Catégorie :'}
        value={clientCategorie}
        handleChange={setClientCategorie}
        children={categoriesMethodsSelect}
      />
    </Grid>
    <CustomSelect
      grid
      xs={12}
      sm={4}
      path={['civility']}
      value={clientCivility}
      label="Civilité"
      setData={(p, v) => setClientCivility(v)}
      id="civilite"
      requiredText={true}
    >
      <MenuItem value={'Monsieur'}>Monsieur</MenuItem>
      <MenuItem value={'Madame'}>Madame</MenuItem>
      <MenuItem value={'Société'}>Société</MenuItem>
    </CustomSelect>
    <Grid item xs={12} sm={4}>
      <CustomTextField
        label="Prénom*"
        value={clientFirstName}
        setData={(p, v) => setClientFirstName(decode(v))}
      />
    </Grid>
    <Grid item xs={12} sm={4}>
      <CustomTextField
        label="Nom*"
        value={clientName}
        setData={(p, v) => setClientName(decode(v))}
      />
    </Grid>
    <Grid item xs={12} sm={8}>
      <CustomTextField
        label="Email*"
        value={clientEmail}
        setData={(p, v) => setClientEmail(v)}
      />
    </Grid>
    <Grid item xs={12} sm={4}>
      <CustomTextField
        label="Téléphone*"
        value={clientPhone}
        setData={(p, v) => setClientPhone(v)}
      />
    </Grid>
    <Grid item xs={12} sm={12}>
      <CustomTextField
        label="Numéro et rue*"
        value={clientVoie}
        setData={(p, v) => setClientVoie(decode(v))}
      />
    </Grid>
    <Grid item xs={12} sm={4}>
      <CustomTextField
        label="Code postal*"
        value={clientZip}
        setData={(p, v) => setClientZip(v)}
      />
    </Grid>
    <Grid item xs={12} sm={8}>
      <CustomTextField
        label="Ville*"
        value={clientCity}
        setData={(p, v) => setClientCity(decode(v))}
      />
    </Grid>
    <Grid item xs={12}>
      <CustomTextField
        label="Description du problème"
        rows={5}
        texttransform=" "
        placeholder="Décrivez votre expérience, vos impressions..."
        value={description}
        setData={(p, v) => setDescription(decode(v))}
      />
    </Grid>
  </Grid>
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OnlineDemande);

