import React from 'react';
import CSSTransition from 'react-transition-group/CSSTransition';
import { withTheme } from '@material-ui/core/styles';

class NotifTransition extends React.Component {

  constructor() {
    super();
    this.handleEnter = this.handleEnter.bind(this);
    this.handleEntering = this.handleEntering.bind(this);
    this.handleExit = this.handleExit.bind(this);
    this.addEndListener = this.addEndListener.bind(this);
  }

  autoTimeout = undefined;

  handleEnter(node) {
    node.style.opacity = '0';
    node.style.transform = 'scale(0)';
    node.style.height = '0';
    node.style.marginTop = '0';
  }

  handleEntering(node) {
    const { theme } = this.props;
    const duration = theme.transitions.getAutoHeightDuration(70);
    this.autoTimeout = duration;

    const transformDuration = {
      duration: duration * 0.666,
    };
    node.style.transition = [
      theme.transitions.create('opacity', { duration }),
      theme.transitions.create('transform', transformDuration),
      theme.transitions.create('height', transformDuration),
      theme.transitions.create('margin', transformDuration)
    ].join(',');

    node.style.opacity = '1';
    node.style.transform = 'scale(1)';
    node.style.height = '100%';
    node.style.marginTop = '10px';

    if (this.props.onEntering) {
      this.props.onEntering(node);
    }
  }

  handleExit(node) {
    const { theme } = this.props;
    let duration = theme.transitions.getAutoHeightDuration(70);
    this.autoTimeout = duration;

    const transformDuration = {
      duration: duration * 0.666,
      delay: duration * 0.333,
    };

    node.style.transition = [
      theme.transitions.create('opacity', { duration }),
      theme.transitions.create('transform', transformDuration),
      theme.transitions.create('height', transformDuration),
      theme.transitions.create('margin', transformDuration)
    ].join(',');

    node.style.opacity = '0';
    node.style.transform = 'scale(0)';
    node.style.height = '0';
    node.style.marginTop = '0';

    if (this.props.onExit) {
      this.props.onExit(duration);
    }
  }

  addEndListener(node, next) {
    let timeout;

    if (this.props.timeout === 'auto') {
      timeout = this.autoTimeout || 0;
    } else {
      timeout = this.props.timeout;
    }

    setTimeout(next, timeout);
  }

  render() {
    const {
      appear,
      children,
      rootRef,
      transitionClasses,
      ...other
    } = this.props;

    return (
      <CSSTransition
        {...other}
        classNames={transitionClasses}
        onEnter={this.handleEnter}
        onEntering={this.handleEntering}
        onExit={this.handleExit}
        addEndListener={this.addEndListener}
        appear={appear}
        ref={rootRef}
      >
        {children}
      </CSSTransition>
    );
  }
}

NotifTransition.defaultProps = {
  appear: true,
  transitionClasses: {},
};

export default withTheme(NotifTransition);
