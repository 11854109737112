import React from 'react';
import { PropTypes } from 'prop-types';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

const Footer = ({ send, cancel }) =>
  (<DialogActions>
    <Button onClick={cancel} color="secondary">
      Annuler
    </Button>
    <Button onClick={send} color="primary">
      Valider
    </Button>
  </DialogActions>);

Footer.propTypes = {
  send: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
};
export default Footer;
