import React, { PureComponent } from 'react';
import SmsContainer from '../Sms/SmsContainer';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import store from '../../store';
import { fetch as fetchNotifications } from '../../actions/notifications';
import notifSystem from '../../notifSystem';
import api from '../../api';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import {
  Grid,
  List as ListM,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
} from '@material-ui/core';
import { Sms } from '@material-ui/icons';
import { moment } from '@bit/dev-lba.lib.local-globals/moment';
import { withStyles } from '@material-ui/core/styles';
import WhenBottom from '../../components/Utils/WhenBottom';
import { red } from '@material-ui/core/colors';

const types = [
  { name: 'artisan',
    id: 'artisanId',
    filters: (id) => ({ _id: id })
  },
  { name: 'intervention',
    id: 'interId',
    filters: (id) => ({ _id: id }) },
  { name: 'devis',
    id: 'devisId',
    filters: (id) => ({ _id: id }) },
  { name: 'prosp',
    id: 'prospId',
    filters: (id) => ({ _id: id }) },
  { name: 'demande',
    id: 'demandeId',
    filters: (id) => ({ _id: id }) },
  { name: 'demandePhoto',
    id: 'demandePhoto',
    tabTitle: 'demande de photo',
    filters: (id) => ({ demandePhoto: id, sender: true }) },
];

const styles = {
  listM: {
    borderRight: '1px #00000045 solid',
    height: '490px',
    overflow: 'auto',
  },
};

const getDefaultState = ({ defaultText, notifications }) => {
  const data = types.find((e) => defaultText[e.id]) || {};
  const id = defaultText[data.id];
  return {
    type: data.id || '',
    id,
    name: data.name || '',
    index: 10,
    filters: data.filters(id),
    notifications: notifications
      .filter((n) => n.type === 'sms')
      .sortBy((n) => n.date)
      .reverse()
      .groupBy((x) => x.link)
      .reduce(
        (a, c) =>
          a.concat(
            c
              .sortBy((n) => !n.click)
              .sortBy((n) => n.date)
              .reverse()
              .get(0)
          ),
        []
      ),
  };
};

class SMSDialog extends PureComponent {
  state = getDefaultState(this.props);

  handleChange = (event, type) => {
    const { defaultText } = this.props;
    if (defaultText[type]) {
      this.setState({ type });
    }
  };

  getDate = (n) => {
    const format = moment(n.date).isSame(moment(), 'day')
      ? 'HH:mm'
      : (moment(n.date).isSame(moment(), 'year') && 'D MMM') || 'LLL';
    return moment(n.date).format(format);
  };

  handleClickSms = (notification) => {
    const { updateNotifications } = this.props;
    const { _id, click } = notification;
    const type = types.find((e) => notification[e.name]);
    this.setState({
      id: notification[type.name],
      name: type.name,
      type: type?.id,
      filters: type.filters(notification[type.name])
    });
    if (!click) {
      updateNotifications(_id);
    }
  };

  loadMore = () => {
    const { index } = this.state;
    this.setState({ index: index + 10 });
  }

  render() {
    const { open, callback, classes } = this.props;
    const { type, id, name, index, notifications, filters } = this.state;
    return (
      <Dialog open={open} maxWidth="lg" fullWidth>
        <DialogTitle>Conversation SMS</DialogTitle>
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          centered
          value={type}
          onChange={this.handleChange}
        >
          {types.map((e, i) =>
            <Tab label={e.tabTitle || e.name} key={i} value={e.id} />
          )}
        </Tabs>
        <DialogContent>
          <Grid container>
            <Grid item xs={4} className={classes.listM}>
              <WhenBottom offset={500} onTrigger={this.loadMore}>
                <ListM>
                  {notifications.slice(0, index).map((n, i) => (
                    <ListItem
                      button
                      key={i}
                      onClick={() => this.handleClickSms(n)}
                      style={
                        id === n[name]
                          ? {
                            background: '#e6e6e6',
                            margin: '5px 0px',
                            borderRadius: '10px',
                          }
                          : {}
                      }
                    >
                      <ListItemAvatar>
                        <Avatar
                          style={
                            n.click
                              ? {}
                              : {
                                background: red[500],
                              }
                          }
                        >
                          <Sms />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={n.text.replace('a envoyé un SMS', '')}
                        secondary={this.getDate(n)}
                      />
                    </ListItem>
                  ))}
                </ListM>
              </WhenBottom>
            </Grid>
            <Grid item xs={8}>
              <SmsContainer name={name} id={id} filters={filters}/>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={callback}>Fermer</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = ({ notifications }) => ({
  notifications
});

const mapDispatchToProps = (dispatch) => ({
  updateNotifications: (notificationId) => {
    api.notifications
      .patch(notificationId, { click: true })
      .then(() => {
        const notifications = store.getState().notifications.map((n) => ({
          ...n,
          click: notificationId === n._id ? true : n.click,
        }));
        dispatch(fetchNotifications(notifications));
      })
      .catch(() =>
        notifSystem.error(
          'Notification',
          'Les notifications n\'ont pas pu être mises à jour'
        )
      );
  },
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(SMSDialog);
