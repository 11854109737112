import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';

import { attachFile } from '@bit/dev-lba.lib.local-globals/utils';

import { send } from '../../../actions/devis';
import notifSystem from '../../../notifSystem';
import DatePicker from '../../DatePicker';
import Footer from './Footer';
import devisLetter from '@bit/dev-lba.lib.local-globals/devisLetter';
import {
  getSize,
  isMaxSizeOk,
  isMaxFileOk,
  MAX_FILES,
} from '../../../utils/function';
import { compose } from 'recompose';
import { groupBy } from '@bit/dev-lba.lib.local-globals/functions';

const maxFilesSize = (nb) => (10 - (1.44 * nb)).toFixed(2);

const styles = {
  TextInput: {
    overflow: 'auto',
    maxHeight: 430,
    minHeight: 200,
    height: 435
  }
};

const mapStateToProps = ({ users, userId }) => ({
  user: users.find(u => u._id === userId),
  userId
});

const getDefaultState = ({ data }) => {
  const files = ((data.products || {}).comboAttachments || []).slice();
  const groupedBy = groupBy(data.products.list, 'comboIndex');
  const lengthOfGroups = Object.keys(groupedBy).length;

  return {
    multiline: devisLetter(data),
    date: new Date(),
    files,
    loading: false,
    maxSize: maxFilesSize(lengthOfGroups),
    maxFile: MAX_FILES,
    totalSize: getSize(files),
    isFileOk: isMaxFileOk(files.length, MAX_FILES),
    isSizeOk: isMaxSizeOk(files, maxFilesSize(lengthOfGroups)),
  };
};

class Content extends PureComponent {
  state = getDefaultState(this.props)

  setDate = date => this.setState({ date: new Date(date) })

  send = () => {
    const {
      send: parentSend,
      history,
      user,
      userId
    } = this.props;
    const {
      date,
      multiline,
      files,
      isFileOk,
      isSizeOk
    } = this.state;

    if (isFileOk && isSizeOk) {
      this.promisifiedSetState({ loading: true })
        .then(() => parentSend())
        .then(devis => devis && send(
          { date, multiline, files },
          devis,
          () => {
            this.cancel();
            history.push(
              `/devis/list/ajd/login-ajout=${user ? user.login : ''}=${userId}`
            );
          }
        ))
        .catch(e => notifSystem.error(e.name, e.message));
    }
  }

  cancel = () => this.props.onClose()

  addFile = e =>
    attachFile(e.target.files[0]).then(attachment => {
      const ret = this.state.files.concat(attachment);

      this.setState({
        files: ret,
        totalSize: getSize(ret),
        isFileOk: isMaxFileOk(ret.length),
        isSizeOk: isMaxSizeOk(ret, this.state.maxSize)
      });
    })

  handleChange = (e, name) => this.setState({ [name]: e.target.value })

  handleChipDelete = index => {
    this.state.files.splice(index, 1);
    const ret = this.state.files.slice(0);
    const tmpSize = getSize(ret);
    this.setState({
      files: ret,
      totalSize: tmpSize,
      isFileOk: isMaxFileOk(ret.length, this.state.maxFile),
      isSizeOk: isMaxSizeOk(ret, this.state.maxSize)
    });
  }

  render() {
    const headLine = 'Envoyer le Devis';
    const attachments = 'Pièces jointes';
    const { classes } = this.props;
    const {
      multiline,
      date,
      totalSize,
      maxFile,
      maxSize,
      files,
      isFileOk,
      isSizeOk,
      loading
    } = this.state;

    const up = <Typography
      variant="caption"
      className={classNames({ [classes]: isFileOk })}
      children={`${attachments}: ${Object.keys(files).length}/${maxFile}`}
    />;

    const down = isSizeOk ? (
      <Typography variant="caption">{totalSize}/{maxSize}</Typography>)
      : (
        <Typography variant="caption" style={{ color: 'red' }}>
          {totalSize}/{maxSize}
        </Typography>
      );
    return (
      <Card style={{ overflow: 'auto' }}>
        <CardContent>
          <Typography variant="h5">{headLine}</Typography>
        </CardContent>
        <CardContent>
          <Grid container spacing={3}>
            <Grid key="date" item xs={12} sm={6}>
              <DatePicker
                startDate={date}
                onChange={this.setDate}
                label="Date du devis"
                before={true}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardContent>
          <TextField
            fullWidth
            id="textarea"
            multiline
            value={multiline}
            onContextMenu={(e) => {
              e.stopPropagation();
            }}
            InputProps={{
              style: styles.TextInput
            }}
            rows={30}
            onChange={(event) => this.handleChange(event, 'multiline')}
            label="Corps du mail"
          />
        </CardContent>
        <CardContent style={{ 'minHeight': '100px' }}>
          {up}
          <CardContent>
            <Paper>
              {Object.values(files).map((element, index) => (
                <Chip
                  key={index}
                  label={element.name}
                  onDelete={() => this.handleChipDelete(index)}
                />
              ))}
            </Paper>
          </CardContent>
          <div style={{ 'float': 'right' }}>
            {down}
          </div>
        </CardContent>
        <Footer key = "footer"
          send={this.send}
          cancel={this.cancel}
          addFile={this.addFile}
          loading={loading}
        />
      </Card>

    );
  }
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps)
)(Content);
