import React from 'react';
import TextField from '@material-ui/core/TextField';
import { PropTypes } from 'prop-types';
import MinCustomForm from '../../Utils/MinCustomForm';

const options = [
  { label: 'Oui', value: '1' },
  { label: 'Non', value: '0' }
];
const Another = ({ value, send, classes, setData, setValue }) =>
  ([
    <MinCustomForm
      key={1}
      inline
      classes={classes}
      title="Voulez vous prévenir l'artisan par sms et mail de l'annulation ?"
      value={send}
      handleChange={(e) => setData('send', e.target.value)}
      options={options}
    />,
    <TextField
      key={2}
      label="Raison"
      fullWidth
      value={value}
      onChange={setValue}
      margin="normal"
    />
  ]);
Another.propTypes = {
  setData: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  send: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired
};
export default Another;
