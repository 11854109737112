import React from 'react';
import { setDialog } from '../../../../actions/dialog';
import api from '../../../../api';
import store from '../../../../store';
import DisplaySupportItems from
'../../../Commissions/Globals/DisplaySupportItems';

const defaultRequest = (path, data, onSuccess, onFail) => {
  api.stats
    .get(`support/${path}`, data)
    .then(result => onSuccess(result.body().data()))
    .catch(() => onFail());
};

const getVerifiedIntervention = (data, onSuccess, onFail) => {
  defaultRequest('interventions', data, onSuccess, onFail);
};

const getDevisCA = (data, onSuccess, onFail) => {
  defaultRequest('devis/CA', data, onSuccess, onFail);
};

const getSignalements = (data, onSuccess, onFail) => {
  defaultRequest('signalements', data, onSuccess, onFail);
};

const getService = (data, onSuccess, onFail) => {
  defaultRequest('service', data, onSuccess, onFail);
};

const getToProgram = (data, onSuccess, onFail) => {
  defaultRequest('toProgram', data, onSuccess, onFail);
};

const getArtisanIntervention = (data, onSuccess, onFail) => {
  defaultRequest('artisan', data, onSuccess, onFail);
};
const getInterByTeam = (data, onSuccess, onFail) => {
  defaultRequest('interByTeam', data, onSuccess, onFail);
};

const getTeamSend = (data, onSuccess, onFail) => {
  defaultRequest('teamSend', data, onSuccess, onFail);
};

const getAnnulationEnvoi = (data, onSuccess, onFail) => {
  defaultRequest('envoiAnnuler', data, onSuccess, onFail);
};

const getDemandeEnLigne = (data, onSuccess, onFail) => {
  defaultRequest('demandeEnLigne', data, onSuccess, onFail);
};

const getPercentArtisanSend = (data, onSuccess, onFail) => {
  defaultRequest('percentArtisanSend', data, onSuccess, onFail);
};

const getMajSST = (data, onSuccess, onFail) => {
  defaultRequest('majSST', data, onSuccess, onFail);
};

const getMiseEnRelationClient = (data, onSuccess, onFail) => {
  defaultRequest('relationClient', data, onSuccess, onFail);
};

const getGlobalArtisanPercent = (data, onSuccess, onFail) => {
  defaultRequest('globalArtisanPercent', data, onSuccess, onFail);
};

const getCommissionsStats = (data, onSuccess, onFail) => {
  defaultRequest('commissionsStats', data, onSuccess, onFail);
};

const getCommissionsStatsByCategory = (data, onSuccess, onFail) => {
  defaultRequest('commissionsStatsByCategory', data, onSuccess, onFail);
};

const getManagerCommissionIntervention = (data, onSuccess, onFail) => {
  defaultRequest('managerCommissionIntervention', data, onSuccess, onFail);
};

const getManagerCA = (data, onSuccess, onFail) => {
  defaultRequest('managerCA', data, onSuccess, onFail);
};

const openCommisionIntersDialog = ({
  commissions, user, serviceSupportCommissionData
}) => store.dispatch(
  setDialog({
    name: 'CustomDialog',
    open: true,
    dialogProps: { title: '', middle: true, middleAll: true, maxWidth: 'lg' },
    contentProps: {
      content: <DisplaySupportItems
        commissions={commissions}
        user={user}
        commissionData={serviceSupportCommissionData}
      />,
      boxProps: {
        width: '100%'
      }
    },
  })
);

export default {
  getVerifiedIntervention,
  getDevisCA,
  getSignalements,
  getService,
  getToProgram,
  getArtisanIntervention,
  getTeamSend,
  getAnnulationEnvoi,
  getDemandeEnLigne,
  getPercentArtisanSend,
  getMajSST,
  getMiseEnRelationClient,
  getGlobalArtisanPercent,
  getInterByTeam,
  getCommissionsStats,
  getCommissionsStatsByCategory,
  getManagerCommissionIntervention,
  openCommisionIntersDialog,
  getManagerCA
};
