
export const getOption = ({ data = {}, colors, roundRadius, title,
  format, legend = true, radius = '80%', center = ['50%', '50%'],
  formatter = '{c} ({d}%)', fontSize = 35, titleExtra = {},
  showLabel = true, customLegend }) => ({
  title: {
    text: title,
    x: 'center',
    ...titleExtra
  },
  tooltip: {
    trigger: 'item',
    formatter: '{a} <br/>{b} : {c} ({d}%)'
  },
  legend: { ...(customLegend ? customLegend : {
    show: legend,
    orient: 'vertical',
    left: 'left',
  } ),
  data: Object.keys(data || {})
  },
  series: [
    {
      color: colors,
      name: '',
      type: 'pie',
      radius: roundRadius ? [50, 90] : radius,
      center,
      label: {
        normal: {
          position: format ? 'outside' : 'inner',
          formatter: formatter ? formatter : null,
          fontSize: fontSize ? fontSize : null,
          show: showLabel
        },
      },
      data: Object.keys(data || {}).map(key => ({ name: key,
        value: Array.isArray(data[key]) ? data[key].length : data[key] })),
      itemStyle: {
        emphasis: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)'
        }
      }
    }
  ]
});
