import { Button } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { Cached } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { moment } from '@bit/dev-lba.lib.local-globals/moment';
import { newVersionDialog } from '../../../actions/general';

const useStyles = makeStyles({
  blink: {
    marginRight: 32,
    animation: '$blink 2s linear infinite',
    backgroundColor: red[50],
    '&:hover': {
      backgroundColor: red[50],
    },
  },
  '@keyframes blink': {
    '50%': {
      color: red[300],
      borderColor: red[100],
      backgroundColor: red[100],
    },
  },
});

const NewVersionButton = () => {
  const [newVersion, setNewVersion] = useState(
    JSON.parse(localStorage.newVersion || '{}')
  );
  const [timerId, setTimerId] = useState(0);
  const classes = useStyles();
  let channel = null;
  try {
    channel = new BroadcastChannel('v3');
  } catch (error) {
    channel = null;
  }

  const resetNewVersion = () => {
    localStorage.removeItem('newVersion');
    newVersionDialog();
    return setTimeout(() => window.location.reload(true), 2000);
  };

  const actionLocalStorage = () => {
    setNewVersion(JSON.parse(localStorage.newVersion || '{}'));
  };

  useEffect(() => {
    window.addEventListener('storage', actionLocalStorage);
    window.addEventListener('beforeunload', resetNewVersion);
    if (channel) {
      channel.addEventListener('message', (e) => {
        if (e.data.action === 'reload') {
          resetNewVersion();
        }
      });
    }

    return () => {
      window.removeEventListener('storage', actionLocalStorage);
      window.removeEventListener('beforeunload', resetNewVersion);
      if (channel) {
        channel.close();
      }
      clearTimeout(timerId);
    };
  }, []);


  useEffect(() => {
    if (newVersion.status) {
      const diff = moment(newVersion.forceUpdate).diff(moment(), 'minutes');
      clearTimeout(timerId);
      const timer = setTimeout(resetNewVersion, diff * 60 * 1000);
      setTimerId(timer);
    }
  }, [newVersion]);

  return (
    newVersion.status
      ? <Button
        children="Nouvelle version"
        variant="outlined"
        className={classes.blink}
        endIcon={<Cached />}
        onClick={() => {
          if (channel) {
            channel.postMessage({ action: 'reload' });
          } else {
            resetNewVersion();
          }
        }}
      /> : ''
  );
};

export default NewVersionButton;
