import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Table from '@material-ui/core/Table';

import ListBody from './ListBody';
import ListFooter from './ListFooter';
import ListHeader from './ListHeader';

const mapStateToProps = ({ users, userId }) => ({
  users,
  user: users.find(u => u._id === userId)
});

class ListTable extends Component {
  state = {
    currentDialog: null,
    dialogElem: null,
    dialogCallbacks: null
  }

  setDialog = (
    currentDialog = null,
    dialogElem = null,
    dialogCallbacks = null
  ) =>
    this.setState({
      currentDialog,
      dialogElem,
      dialogCallbacks
    })

  render() {
    const {
      currentDialog: Dialog,
      dialogElem,
      dialogCallbacks
    } = this.state;
    const {
      styles,
      actions,
      user,
      users,
      elems,
      rowElem,
      columns,
      filterKeys,
      keys = filterKeys,
      filter,
      fields,
      sortBy,
      ascOrder,
      count,
      rowsPerPage,
      rowsPerPageOptions,
      page,
      currentRow,
      RowContent,
      displayers,
      headersDisplayers,
      inputDisplayers,
      launchRequest,
      reloadCurrent,
      handleChangeFilter,
      handleChangeField,
      handleChangeSortBy,
      handleChangeRowsPerPage,
      handleChangePage,
      handleClickRow
    } = this.props;

    return <React.Fragment>
      <Table>
        <ListHeader
          styles={styles}
          columns={columns}
          keys={filterKeys}
          formattedKeys={keys}
          filter={filter}
          fields={fields}
          sortBy={sortBy}
          ascOrder={ascOrder}
          headers={headersDisplayers}
          displayers={inputDisplayers}
          launchRequest={launchRequest}
          handleChangeFilter={handleChangeFilter}
          handleChangeField={handleChangeField}
          handleChangeSortBy={handleChangeSortBy}
        />
        <ListBody
          styles={styles}
          actions={actions}
          RowContent={RowContent}
          colSpan={keys.length}
          elems={elems}
          user={user}
          users={users}
          rowElem={rowElem}
          keys={keys}
          currentRow={currentRow}
          displayers={displayers}
          handleClickRow={handleClickRow}
          launchRequest={launchRequest}
          reloadCurrent={reloadCurrent}
          setDialog={this.setDialog}
        />
        <ListFooter
          count={count}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
          page={page}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleChangePage={handleChangePage}
        />
      </Table>
      {!!Dialog && <Dialog
        styles={styles}
        elem={dialogElem}
        callbacks={dialogCallbacks}
      />}
    </React.Fragment>;
  }
}

ListTable.propTypes = {
  elems: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  keys: PropTypes.array,
  filterKeys: PropTypes.array.isRequired,
  filter: PropTypes.object,
  sortBy: PropTypes.string,
  ascOrder: PropTypes.bool,
  count: PropTypes.number,
  rowsPerPage: PropTypes.number
};

ListTable.defaultProps = {
  headersDisplayers: {},
  filterKeys: []
};

export default connect(mapStateToProps)(ListTable);
