import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import grey from '@material-ui/core/colors/grey';
import api from '../../api';
import notifSystem from '../../notifSystem';
import { getType, getLogin, getMomentOrDate } from '../../utils/function';
import {
  FACT_A,
  FACT_S,
  FACT_P,
  PACK_S
} from '@bit/dev-lba.lib.local-globals/historyCat';
import { openPreview } from '../../actions/general';

const style = {
  row: {
    width: '33%',
    backgroundColor: grey[200],
    paddingTop: 10,
    paddingBottom: 10,
    textAlign: 'center',
  },
  rowData: {
    width: '33%',
    textAlign: 'center',
  },
};

function HistoriqueEmpty() {
  return (
    <Grid>
      <Typography style={{ textAlign: 'center', padding: 10 }}>
        Aucun Historique
      </Typography>
    </Grid>
  );
}

export default class Dialogs extends Component {
  constructor() {
    super();
    this.state = {
      history: null,
      visible: true,
      open: false,
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleSend = this.handleSend.bind(this);
  }

  async componentDidMount() {
    const history = await api.history
      .custom('getSpecificHistory')
      .post({
        id: this.props.defaultText._id,
        filter: [FACT_A, FACT_S, FACT_P, PACK_S],
      });
    this.setState({ history: history.body().map(e => e.data()) });
  }

  handleClose() {
    this.setState({ visible: false });
    if (typeof this.props.callback === 'function') {
      this.props.callback(false);
    }
    return false;
  }

  openFacturierPreview = () =>
    openPreview({
      method: 'facturier',
      info: { type: 'facturier', noCondition: true },
      data: [this.props.defaultText]
    }, 'Facturier');

  handleSend() {
    if (this.props.defaultText.id) {
      api
        .one('artisans', this.props.defaultText.id)
        .custom('sendFacturier')
        .post({
          type: FACT_S,
        })
        .then(() => this.openFacturierPreview())
        .catch(e => notifSystem.error(e.name, e.message));
    }
  }

  getRow() {
    const { history } = this.state;
    const data = history.map((e, i) => (
      <ListItem key={i}>
        <Grid style={style.rowData}>{getType(e.categorie)}</Grid>
        <Grid style={style.rowData}>{getMomentOrDate(e.date)}</Grid>
        <Grid style={style.rowData}>{getLogin(e.userId)}</Grid>
      </ListItem>
    ));
    return (
      <List>
        <ListItem>
          <Grid style={style.row}>Action</Grid>
          <Grid style={style.row}>Date</Grid>
          <Grid style={style.row}>Login</Grid>
        </ListItem>
        {data}
      </List>
    );
  }

  render() {
    const {
      closeText,
      buttonPrint,
      buttonEnvoi,
      maxWidth,
      fullWidth,
    } = this.props;
    const { history } = this.state;
    return (
      <div>
        <Dialog
          onEscapeKeyDown={this.handleClose}
          open={this.state.visible}
          maxWidth={maxWidth}
          fullWidth={fullWidth}
        >
          <DialogTitle>Historique Facturier</DialogTitle>
          <DialogContent>
            <List>
              {history && history.length ? (
                this.getRow()
              ) : (
                <HistoriqueEmpty props={this.props} />
              )}
            </List>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.openFacturierPreview()}
              color="secondary"
            >
              {buttonPrint}
            </Button>
            <Button onClick={this.handleSend} color="default">
              {buttonEnvoi}
            </Button>
            <Button onClick={this.handleClose} color="primary">
              {closeText}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

Dialogs.defaultProps = {
  closeText: 'Fermer',
  buttonPrint: 'Imprimer',
  buttonEnvoi: 'Envoyer Facturier',
  maxWidth: 'sm',
  fullWidth: true,
};
