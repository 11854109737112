import React from 'react';
import api from '../../../../../api';
import notifSystem from '../../../../../notifSystem';
import ReactEcharts from 'echarts-for-react';
import { getOption } from '../../../common/graph/options/globalOptions';
import { moment } from '@bit/dev-lba.lib.local-globals/moment';

class CustomGraph extends React.Component {
  state = {
    data: {},
  };

  componentDidMount() {
    this.doRequests();
  }

  componentDidUpdate(prevProps) {
    const { from, to, isMoney } = this.props;
    if (
      from !== prevProps.from ||
      to !== prevProps.to ||
      isMoney !== prevProps.isMoney
    ) {
      this.doRequests();
    }
  }

  doRequests = () => {
    const { from, to, mode, path, isMoney } = this.props;
    api.stats
      .get(`comptabilite/${path}`, {
        from,
        to,
        mode,
        isMoney: +isMoney,
      })
      .then((e) => this.setState({ data: e.body().data() }))
      .catch(() => notifSystem.error('Echec', 'Une erreur s\'est produite'));
  };

  render() {
    const { data } = this.state;
    const { from, to, mode, colors, name } = this.props;

    return (
      <ReactEcharts
        option={getOption({
          data,
          mode,
          dates: [moment(from), moment(to)],
          colors,
          title: name,
        })}
      />
    );
  }
}

export default CustomGraph;
