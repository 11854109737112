export default classes => ({
  menu: {
    display: 'flex',
    marginTop: '35px'
  },
  menuList: {
    paddingTop: 0,
    paddingBottom: 0
  },
  boxContent: {
    display: 'flex',
    alignItems: 'center'
  },
  displayer: {
    height: '100%',
    padding: '0px 7px',
    borderTop: '1px solid #f2f2f2'
  },
  leftDiv: {
    margin: 'auto',
    marginLeft: 12,
    marginRight: 17,
    width: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  devisIcon: {
    display: 'flex',
    justifyContent: 'space-around'
  },
  devisIconSpan: {
    margin: '-12px 0 0 -14px',
    fontSize: 12
  },
  rightDiv: {
    alignItems: 'stretch',
    width: 460
  },
  primaryTypoStyle: {
    marginBottom: 2,
    fontWeight: 400,
    fontSize: '0.75rem',
    letterSpacing: '0.03333em',
  },
  secondaryTypoStyle: {
    [classes.breakpoints.down('sm')]: {
      width: '40%'
    },
    fontWeight: 250,
    fontSize: '0.95rem',
    lineHeight: 1.2,
    letterSpacing: '0.00938em'
  },
  chip: {
    width: '100%',
    color: 'white',
    display: 'inline-flex'
  },
  wrap: {
    whiteSpace: 'normal'
  },
  noLink: {
    textDecoration: 'none'
  },
  redPoint: {
    width: '15px',
    height: '15px',
    margin: 'auto',
    marginRight: '-10px',
    textAlign: 'center'
  }
});
