export const getTeam = (data, users, listen = 'artisans') => {
  if (data.candidatId && data.interPayer <= 9) {
    return {
      login: 'Candidat',
      equipe: 'Candidat',
    };
  }
  const dataAreas = (
    listen === 'artisans' ?
      data.billingAddress.zipcode :
      data.client.address.zipcode
  ).slice(0, 2);
  const chief = users.find(u => u.areas && u.areas.includes(dataAreas)) || {};
  return {
    login: chief.login || 'Non defini',
    equipe: chief.equipe || {},
  };
};


export const getUserTeam = (users, userId) =>
  users.find(
    (e) =>
      (e._id === userId && e.areas?.length) || e.equipe?.ids?.includes(userId)
  );
