import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import {
  Input,
  Button,
  Typography,
  Grid
} from '@material-ui/core';
import { indigo, red } from '@material-ui/core/colors';

import CardItems from './CancelDialog/CardItems';
import McDoStepper from './McDoStepper';
import { compose } from 'recompose';
import { connect } from 'react-redux';

const CATEGORY = 1;
const RAISON = 2;
const COMMENT = 3;

const styles = {
  input: {
    padding: '16px',
    paddingBottom: 0,
  },
};

const mapStateToProps = ({ raisonsArchiveSst }) => ({
  raisonsArchiveSst,
});

const loadRaisonArchiveSst = (filter, raisonsArchiveSst) =>
  raisonsArchiveSst
    .filter(data => Object.keys(filter).every(key => filter[key] === data[key]))
    .map(data => ({
      name: data.name,
      color: data.level === 2 ? red : indigo,
      type: data.type,
      ref: data.ref,
      data,
    }));

class ArchiveSST extends Component {
  state = {
    value: '',
    data: null,
    raison: null,
  };

  componentDidMount() {
    const {
      raisonsArchiveSst,
    } = this.props;

    let newRaisons = raisonsArchiveSst ? raisonsArchiveSst.toJS() : [];
    this.setState({
      data: loadRaisonArchiveSst({ ref: null }, newRaisons),
      step: CATEGORY,
    });
  }

  send = () => {
    const { raison, value } = this.state;
    const comment = value.trim();

    if (comment) {
      this.props.callback({
        _id: raison._id,
        comment,
        motif: raison.name,
      });
    }
  };

  close = () => this.props.callback();

  generateDialogProps = step => {
    switch (step) {
      case CATEGORY:
        return {
          title: 'Choisissez une catégorie',
        };
      case RAISON:
        return {
          title: 'Choisissez une raison',
        };
      case COMMENT:
        return {
          title: 'Création de l\'archive',
          maxWidth: 'sm',
          actions: [<Button onClick={this.send} key={0} children="Valider" />],
        };
    }
  };

  displayInput = () => {
    const { raison } = this.state;
    const { classes } = this.props;

    return (
      <Grid item xs={12}>
        <Typography children={raison.name} />
        <Input
          className={classes.input}
          multiline
          fullWidth
          rows={10}
          onChange={e => this.setState({ value: e.target.value })}
          placeholder="Commentaire"
        />
      </Grid>
    );
  };

  setStep = (raison, setData) => {
    let {
      raisonsArchiveSst,
    } = this.props;

    raisonsArchiveSst = raisonsArchiveSst ? raisonsArchiveSst.toJS() : [];
    this.setState({ raison }, () => {
      const data = loadRaisonArchiveSst(
        { ref: raison.id },
        raisonsArchiveSst
      );
      if (!data.length) {
        setData(raison, COMMENT);
      } else {
        setData(data, RAISON);
      }
    });
  };

  generateContent = (currentData, currentStep, setData) => {
    const { data } = this.state;
    switch (currentStep) {
      case CATEGORY:
        return (
          <CardItems
            name="data"
            display="name"
            setData={(d, raison) => this.setStep(raison, setData)}
            array={currentData}
          />
        );
      case RAISON:
        return (
          <CardItems
            name="data"
            display="name"
            setData={(d, raison) => this.setStep(raison, setData)}
            array={currentData}
          />
        );
      case COMMENT:
        return this.displayInput();
      default:
        if (data) {
          setData(data, CATEGORY);
        }
        return null;
    }
  };

  render() {
    return (
      <McDoStepper
        onClose={this.close}
        generateContent={(...args) => (
          <Grid container spacing={2} justify="center">
            {this.generateContent(...args)}
          </Grid>
        )}
        generateDialogProps={this.generateDialogProps}
      />
    );
  }
}

export default compose(
  connect(mapStateToProps),
  withStyles(styles)
)(ArchiveSST);
