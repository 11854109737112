import { MAIL_REGEX, PHONE_REGEX } from './regexps';

export const checkInfosClient = (keys = [], names = []) => (content = {}) =>
  keys.reduce((a, v, i) => {
    if (v.keys) {
      a.push(
        ...checkInfosClient(
          v.keys,
          names[i]
        )(content[v.key])
      );
    } else if (v.values) {
      if (!v.values.includes(content[v.key])) {
        a.push(names[i]);
      }
    } else if (!content[v]) {
      a.push(names[i]);
    }
    return a;
  }, []);

export const checkPhoneAndMail = (fields = [[], []]) => (content = {}) => {
  const regex = [
    PHONE_REGEX,
    MAIL_REGEX
  ];

  return fields.map((e, i) => e.reduce((a, k) => {
    if (content[k] && !regex[i].test(content[k])) {
      a.push(content[k]);
    }
    return a;
  }, []));
};

export const commonCheckPhoneAndMail = checkPhoneAndMail([
  ['tel1', 'tel2', 'tel3'],
  ['email1', 'email2']
]);
