export const saveAs = (uri, filename) => {
  let link = document.createElement('a');
  if (typeof link.download === 'string') {
    document.body.appendChild(link);
    link.download = filename;
    link.href = uri;
    link.click();
    document.body.removeChild(link);
  } else {
    window.location.replace(uri);
  }
};

export const onDownload = (data, type = 'txt') => {
  if (!data.contents || !data.filename) {
    return false;
  }
  let blob = new Blob([data.contents], { type: 'text/plain' });
  let url = URL.createObjectURL(blob);
  saveAs(url, `${data.filename}.${type}`);
};
