import React from 'react';

import {
  NOT_CONFIRMED
} from '@bit/dev-lba.lib.local-globals/permissions';
import componentLoader from '../../componentLoader';

const ListIntervention = React.lazy(() =>
  componentLoader(() => import('../../views/List/ListIntervention'))
);

export default [
  {
    path: '/quality/list/:filter/:query',
    component: ListIntervention,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/quality/list/:filter',
    component: ListIntervention,
    restrictions: () => NOT_CONFIRMED,
  },
];
