import { moment } from '@bit/dev-lba.lib.local-globals/moment';

export const getOption = function (data, mode) {
  let arr = [];
  if (mode === 'day') {
    arr = [
      '7h',
      '8h',
      '9h',
      '10h',
      '11h',
      '12h',
      '13h',
      '14h',
      '15h',
      '16h',
      '17h',
      '18h',
    ];
  } else if (mode === 'week') {
    arr = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
  } else {
    arr = moment.monthsShort();
  }
  return {
    color: ['#003366', '#e5323e', '#006699', '#4cabce'],
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    legend: {
      data: data.map((e) => e._id),
    },
    toolbox: {
      show: true,
      orient: 'vertical',
      left: 'right',
      top: 'center',
      feature: {
        mark: { show: true },
        saveAsImage: { show: true, title: 'save' },
      },
    },
    calculable: true,
    xAxis: [
      {
        type: 'category',
        axisTick: { show: false },
        data: arr,
      },
    ],
    yAxis: [
      {
        type: 'value',
        axisLabel: {
          formatter: '{value}€',
        },
      },
    ],
    series: data.map((e) => ({
      name: e._id,
      type: 'bar',
      barGap: 0,
      data: delete e._id && Object.values(e).map((n) => n / 100),
    })),
  };
};
