import React, { Component } from 'react';
import { Paper, Grid, Tabs, Tab, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import StatsPanel from '../common/panel/StatsPanel';
import { moment } from '@bit/dev-lba.lib.local-globals/moment';
import UserSelection from '../common/selection/UserSelection';
import styles from './styles';
import { userTabs } from './utils';
import actions from './actions';
import {
  AttachMoneyOutlined,
  MoneyOffOutlined
} from '@material-ui/icons';

import {
  data as categoryData
} from '@bit/dev-lba.lib.local-globals/categories';
import { data as statusData } from '@bit/dev-lba.lib.local-globals/iStatus';
import {
  data as subStatusData,
  AS_HOT, AS_INDSP, AS_INCT
} from '@bit/dev-lba.lib.local-globals/aSubStatus';
import notifSystem from '../../../notifSystem';
import { List } from 'immutable';
import Dropdown from '../common/dropdown/dropdown';

const excludeSubStatus = [AS_HOT, AS_INDSP, AS_INCT];

class SupportStats extends Component {
  state = {
    mode: 'month',
    from: moment().startOf('month').toDate(),
    to: moment().endOf('month').toDate(),
    userSearch: '',
    selectedTab: this.props.selectedTab || 0,
    selectedUsers: this.props.users.length < 2 || this.props.defaultUser ?
      [this.props.defaultUser || this.props.users[0]._id] : [],
    selectedCategories: categoryData.map((e) => e._id),
    selectedStatus: statusData.map((e) => e._id),
    selectedSubStatus: subStatusData
      .filter((e) => !excludeSubStatus.includes(e._id))
      .map((e) => e._id),
    display: true,
    notSend: 0,
    failed: 0,
  };

  componentDidMount() {
    const { selectedLabel, selectedTab, users } = this.props;
    const tabs = userTabs({ user: users[0] });
    if (selectedLabel || selectedTab >= 0) {
      let index = selectedTab;
      if (selectedLabel) {
        const tabIndex = tabs.findIndex(e => e.label === selectedLabel);
        index = tabIndex;
      }
      if (~index) {
        const mode = tabs[index].mode || this.state.mode;
        this.setState({
          selectedTab: index,
          mode,
          from: moment().startOf(mode).toDate(),
          to: moment().endOf(mode).toDate(),
        });
      }
    } else {
      const select = tabs.findIndex(e => !!e.showInDashboard);
      this.setState({ selectedTab: select });
    }
    this.doRequest();
  }

  onDatesChange = (from, to, mode) => {
    this.setState({ from, to, mode }, this.doRequest);
  };

  doRequest = () => {
    const users = this.props.users.map((u) => u._id);
    const { from, to } = this.state;
    actions.getService(
      { from, to, users: JSON.stringify(users) },
      ({ notSend, failed }) => this.setState({ notSend, failed }),
      () =>
        notifSystem.error(
          'Erreur',
          'Impossible de charger les statistiques support'
        )
    );
  };

  updateSearch = (search) => {
    this.setState({ userSearch: search });
  };

  selectTab = (tab) => {
    this.setState({ selectedTab: tab });
  };

  changeArray = (field, input, key) => {
    let data = [...this.state[field]];
    const toCompare = key ? input[key] : input;

    if (data.includes(toCompare)) {
      data = data.filter((u) => u !== toCompare);
    } else {
      data.push(toCompare);
    }
    this.setState({ [field]: data });
  };

  /**
   * Handle selectedUser when using users dropbown
   */
  handleSelectedUser = (selected) => {
    const defaultUser = this.props.defaultUser;
    this.setState({
      selectedUsers: selected.length && defaultUser ? selected : [defaultUser]
    });
  }

  openCommentPanel = (user) => {
    this.setState({ openCommentPanel: true, commentUser: user });
  };

  toggleDisplay = () => {
    this.setState((prev) => ({ display: !prev.display }));
  };

  render() {
    const {
      users, classes,
      selectedTab: defaultTab,
      noTabs,
      selectedLabel,
      title,
      hideIcons,
      displayInput,
    } = this.props;
    const {
      mode,
      from,
      to,
      selectedUsers,
      selectedCategories,
      display,
      selectedStatus,
      userSearch,
      selectedTab,
      notSend,
      failed,
      selectedSubStatus,
    } = this.state;

    const tabs = userTabs({ user: users[0] });
    const tab = tabs[selectedTab];
    const Display = tab.display;
    const tabProps = {
      mode,
      from,
      to,
      categories: selectedCategories,
      status: selectedStatus,
      display,
      source: tab.label,
      fn: tab.fn,
      errorMessage: tab.errorMessage,
      colors: tab.colors,
      disposition: tab.disposition,
      actions,
      users,
      legend: tab.legend,
      customOption: tab.customOption,
      subStatus: selectedSubStatus,
      date: tab.date,
      yAxis: tab.yAxis,
      yAxisIndex: tab.yAxisIndex
    };

    const minimize = !isNaN(defaultTab) || noTabs;

    return (
      <StatsPanel
        onDatesChange={this.onDatesChange}
        enabledModes={tab.enabledModes}
        title={title || selectedLabel || 'Statistiques de Support'}
        subTitle={
          minimize
            ? ''
            : `Non envoyé: ${notSend || 0}%
              / Envoyé et annulé: ${failed || 0}%`
        }
        defaultState={{ mode: tab.mode || mode, from, to }}
        MainComponent={Paper}
        body={
          <React.Fragment>
            {!hideIcons && new List(users).size > 1 && (
              <>
                <Button
                  onClick={this.toggleDisplay}
                  variant="outlined"
                  disabled={!tab.enableCA}
                  color={display ? 'primary' : 'secondary'}
                >
                  {display ? <AttachMoneyOutlined /> : <MoneyOffOutlined />}
                </Button>
                {tab.enableSubStatus && (
                  <Dropdown
                    title={'Sous status'}
                    data={subStatusData.filter(
                      (e) => !excludeSubStatus.includes(e._id)
                    )}
                    isSelected={(e) => selectedSubStatus.includes(e)}
                    onChange={(e) =>
                      this.changeArray('selectedSubStatus', e.target.value[1])
                    }
                  />
                )}
              </>
            )}
            {displayInput && (
              <Dropdown
                title={
                  users.find((e) => selectedUsers.includes(e._id))?.login || ''
                }
                data={users}
                idKey={null}
                renderKey={'login'}
                isSelected={(e) => selectedUsers.includes(e._id)}
                onChange={(e) => this.handleSelectedUser([e.target.value._id])}
                multiple={false}
              />
            )}
          </React.Fragment>
        }
      >
        <Grid container spacing={2} justifyContent="center">
          {!displayInput && new List(users).size > 1 && (
            <Grid item xs={2} className={classes.userGrid}>
              <UserSelection
                classes={classes}
                users={[{ _id: 'tous', login: 'Tous' }, ...users].filter((u) =>
                  u.login.includes(userSearch)
                )}
                updateSearch={this.updateSearch}
                selectedUsers={selectedUsers}
                changeSelectedUsers={(e) =>
                  this.changeArray('selectedUsers', e, '_id')
                }
                onOpenComments={this.openCommentPanel}
              />
            </Grid>
          )}
          <Grid
            item
            xs={minimize ? 12 : 10}
            className={minimize ? null : classes.statsGrid}
          >
            {!minimize && (
              <Tabs
                value={selectedTab}
                onChange={(e, v) => this.selectTab(v)}
                variant="scrollable"
                scrollButtons="auto"
              >
                {tabs.map((tab, i) => (
                  <Tab key={tab.label} label={tab.label} value={i} />
                ))}
              </Tabs>
            )}
            {tab.OneDefault && Display && <Display {...tabProps} />}
            {Display &&
              selectedUsers.map(
                (user, i) =>
                  user && <Display key={i} user={user} {...tabProps} />
              )}
          </Grid>
        </Grid>
      </StatsPanel>
    );
  }
}

export default withStyles(styles)(SupportStats);
