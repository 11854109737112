import React, { PureComponent } from 'react';

class ButtonTimeout extends PureComponent {

  constructor (props) {
    super(props);
    this.onClickTimeout = this.onClickTimeout.bind(this);
    this.state = {
      disabled: false
    };
    this.timeout = null;
  }

  onClickTimeout(e) {
    e.preventDefault();
    this.setState({
      disabled: true
    });

    this.timeout = setTimeout(() => {
      this.setState({ disabled: false });
      this.timeout = null;
    }, this.props.timeout * 1000);
    return this.props.onClick();
  }

  componentWillUnmount() {
    if (this.timeout !== null)
    {
      clearTimeout(this.timeout);
    }
  }

  render() {
    const {
      ButtonComp,
      children,
      ...props
    } = this.props;
    delete props.onClick;
    delete props.timeout;
    return (
      <ButtonComp
        onClick={this.onClickTimeout}
        disabled={this.state.disabled}
        {...props}>
        {children}
      </ButtonComp>
    );
  }
}

export default ButtonTimeout;
