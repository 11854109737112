import {
  Phone,
  AttachMoney,
  Search,
  ShowChart,
  PinDrop,
  Create,
  CallMissed,
} from '@material-ui/icons/';

export default [
  {
    name: 'Intervention service',
    link: '/cti/interService',
    icon: AttachMoney,
  },
  {
    name: 'Statistiques agents',
    link: '/cti/statsAgent',
    icon: Phone,
  },
  {
    name: 'Statistiques services',
    link: '/cti/statsService',
    icon: ShowChart,
  },
  {
    name: 'Appels par départements',
    link: '/cti/mapCalls',
    icon: PinDrop,
  },
  {
    name: 'Recherche',
    link: '/cti/search',
    icon: Search,
  },
  {
    name: 'Modification des lignes OVH',
    link: '/cti/updateOVH',
    icon: Create,
  },
  {
    name: 'Appels manqués',
    link: '/cti/unansweredCalls',
    icon: CallMissed,
  },
];
