/* eslint-disable max-lines */
import React from 'react';
import { AssignmentTurnedIn } from '@material-ui/icons';
import { green, orange } from '@material-ui/core/colors';

import { groupBy } from '@bit/dev-lba.lib.local-globals/functions';
import { I_APR } from '@bit/dev-lba.lib.local-globals/iStatus';
import { DDEV } from '@bit/dev-lba.lib.local-globals/docTypes';
import formatCreatedFrom
from '@bit/dev-lba.lib.local-globals/formatCreatedFrom';
import {
  MIN_AMOUNT_DEVIS
} from '@bit/dev-lba.lib.local-globals/domofinance';


import DialogEnvoiDevis
from '../components/Dialogs/DialogEnvoiDevis/EnvoiDevisFromMenu';
import DialogGetClientNumber from '../components/Dialogs/DialogGetClientNumber';
import ConfirmBox from '../components/Dialogs/ConfirmBox';
import devisLetter
from '@bit/dev-lba.lib.local-globals/devisLetter';
import { DialogList } from '../components/Dialogs/';

import { generateSmsDevis } from '../constants/sms/smsDevis';
import notifSystem from '../notifSystem';
import Money from '../utils/Money';
import { throwIt } from '../utils/function';


import api from '../api';
import { fromJS, Map, List } from 'immutable';
import { setDialog } from './dialog';
import store from '../store';
import collections from '../constants/collections';
import { openAssignDialog, openPreview } from './general';
import { getFinanceConsignes } from '../utils/financement';
import IphoneDialog from '../components/Dialogs/IphoneDialog';
import { checkPhoneAndMail } from '../utils/checkInputs';
import {
  checkProducts, checkIsMobileNumber, checkAndOpenCaldeo
} from './verifications';
import { Button } from '@material-ui/core';

const KEYS_TO_COPY_FOR_INTERVENTION = [
  'id',
  'categorie',
  'client',
  'products.list',
  'products.tva',
  'billing',
  'onSitePayment',
  'combination',
  'finalPrice',
  'demande.id',
  'demande.source',
  'demande.externalId',
  'onSitePayment',
  'remindFacture',
  'dropboxFacture',
  'combination',
  'domofinance',
  'ceeAmount',
  'anahAmount',
  'noHelp',
  'isCaldeo',
];

export function cancel(props, devis, cb) {
  props.setDialog(
    ConfirmBox,
    true,
    {
      title: 'Etes-vous sûr de vouloir annuler le devis ?',
      confirmBox: 'Oui',
      closeBox: 'Annuler',
    },
    (confirmed) => {
      if (confirmed) {
        api
          .one('devis', devis.id)
          .custom('cancel')
          .post()
          .then(() => {
            if (cb) {
              cb();
            }
            notifSystem.success('Opération réussie', 'Devis annulé');
          })
          .catch(() =>
            notifSystem.error('Erreur', 'L\'action n\' pas pu être réalisée')
          );
      }
      props.setDialog(null, false, null, null);
    }
  );
}

const callbackTransfer = async (props, devis, save, resCallBack) => {
  try {
    if (!resCallBack) {
      return props.setDialog(null, false, '', null);
    }
    resCallBack =
      typeof resCallBack === 'string' ? resCallBack.match(/\d+/g) : '';
    if (resCallBack.length) {
      devis = {
        ...devis,
        products: {
          ...devis.products,
          list: devis.products.list.filter(
            (s) => s.comboIndex === Number(resCallBack[0]) - 1
          ),
        },
      };
    }
    if (save) {
      await api.one('devis', devis.id).custom('transfer').post();
      notifSystem.success('Opération réussie', 'Le devis a été transferé');
    } else {
      let merge = fromJS(devis);
      const consignes = getFinanceConsignes(merge);
      if (consignes) {
        merge = merge.setIn(['infoDesc', 'consignes'], consignes);
      }
      const mergeReducer = (v, f) => {
        const value = merge.getIn(f.split('.'));
        if (value) {
          v = v.setIn(f.split('.'), value);
        }
        return v;
      };
      const loginAjout = props.users.find((e) => e.login === devis.login.ajout);
      devis.login.ajout = (loginAjout && loginAjout._id) || devis.login.ajout;
      const createdFrom = merge.getIn(['createdFrom', 'path']);
      props.history.push({
        pathname: `/intervention/${devis.id}`,
        state: {
          copy: {
            ...KEYS_TO_COPY_FOR_INTERVENTION.reduce(
              mergeReducer,
              new Map()
            ).toJS(),
            infoDesc: merge.get('infoDesc', {}),
            date: {
              ajout: new Date(),
              devis: merge.getIn(['date', 'ajout']),
              transfer: merge.getIn(['date', 'transfer']),
              envoiLydia: merge.getIn(['date', 'envoiLydia']),
            },
            login: {
              ajout: devis.login.ajout,
              devis: devis.login.ajout,
              artisan: devis.login.artisan,
              authorisedArtisan: devis.login.authorisedArtisan,
              envoiLydia: devis.login.envoiLydia,
              manager: devis.login.manager
            },
            createdFrom: formatCreatedFrom({
              _id: devis._id,
              id: devis.id,
              collection: collections.devis,
              path: List.isList(createdFrom)
                ? createdFrom.toJS()
                : createdFrom || [],
            }),
            signature: merge.get('signature'),
            status: I_APR,
            devisStatus: devis.status,
            dropboxDevis:
              merge.has('dropbox') &&
              !!merge.get('dropbox').find((e) => e.get('type') === DDEV),
            isTransfer: true,
            announcedPrice: devis.finalPrice,
            transaction: devis.transaction || {},
            service: devis.service || {},
            comments: devis.comments || []
          },
        },
      });
    }
  } catch (error) {
    notifSystem.error('Erreur', 'Devis n\'a pas été transferé');
  }
};

export const openPropositionList = (props, devis, groups) =>
  new Promise(res =>
    props.setDialog(DialogList, true, {
      title: 'Quelle proposition voulez-vous transférer ?',
      data: Object
        .keys(groups)
        .map(e => {
          const price = Money.toString(
            devis
              .products
              .list
              .reduce((v, l) =>
                v + (l.comboIndex === Number(e)
                  ? (l.pu * l.qt)
                  : 0
                ), 0),
            true,
          );

          return `Proposition n°${Number(e) + 1}, Prix : ${price} T.T.C`;
        }),
      Icon: AssignmentTurnedIn,
    }, res)
  );

export async function transfer(props, devis, save) {
  try {
    if (devis.products && devis.products.list) {
      const groups = groupBy(devis.products.list, 'comboIndex');
      if (Object.keys(groups).length > 1) {
        const res = await openPropositionList(props, devis, groups);
        callbackTransfer(props, devis, save, res);
      } else {
        callbackTransfer(props, devis, save, true);
      }
    } else {
      notifSystem.error('Erreur', 'Merci d\'ajouter des produits');
    }
  } catch (error) {
    notifSystem.error('Erreur', 'Devis n\'a pas été transferé');
  }
}

export async function send(props, devis, cb) {
  await api.one('devis', devis.id).custom('send').post({
    date: props.date,
    bodyMail: props.multiline,
    attachments: props.files,
  });
  notifSystem.success('Opération réussie', 'Devis envoyé');
  if (cb) {
    cb();
  }
}

export function sendSMS(props, devis) {
  function smsCallback(message, { mode }) {
    props.setDialog(null, false, '', null);
    if (message) {
      props.save().then((newDevis) =>
        api.devis
          .custom(`${newDevis._id}/sendSMS`)
          .post({
            message,
            mode,
            comboAttachments: devis.products.comboAttachments || []
          })
          .then(() =>
            notifSystem.success('Opération réussie', 'Le SMS à bien été envoyé')
          )
          .catch(() => {
            notifSystem.error('Erreur', 'Le SMS n\'a pu être envoyé.');
          })
      );
    }
  }
  props.setDialog(
    IphoneDialog,
    true,
    {
      text: generateSmsDevis(devis, props.user),
      devisSms: true,
      disabled: true
    },
    smsCallback
  );
}

export function sendFromMenu(props, devis) {
  const callbacks = {
    onClose: () => props.setDialog(null, false, '', null),
    send: () => devis,
    history: props.history,
  };
  checkAndOpenCaldeo(fromJS(devis), {
    type: 'devis',
    saveOnly: true,
    history: props.history,
    userId: props.userId,
    fromList: true,
    cb: (nDevis = devis) =>
      props.setDialog(DialogEnvoiDevis, true, nDevis, callbacks)
  });

}

export function sendSmsFromMenu(props, devis) {
  const checkedPhone = checkPhoneAndMail([['tel1', 'tel2', 'tel3']]);
  if (checkedPhone(devis.client)[0].length) {
    return notifSystem.error('Attention',
      'Le client doit avoir un numéro de téléphone');
  } else if (checkProducts(devis)
    && checkIsMobileNumber(fromJS(devis))) {
    sendSMS({ ...props, save: () => Promise.resolve(devis) }, devis);
  }
}

export function previewDevis(devis) {
  openPreview({
    method: 'completeDevis',
    info: { type: 'devis' },
    data: { devis }
  }, `Devis n° ${devis.id || ''}`);
}

export const checkClientNumber = async (props, obj, name, cb) => {
  const { numbers, isNew } = obj;
  const callbacks = (response) => {
    if (response && cb) {
      cb();
    }
    props.setDialog(null, false, '', null);
  };
  if (isNew) {
    const promises = name.map((e) =>
      api[e].custom('checkNumber').get({ numbers })
    );
    let data = await Promise.all(promises);
    data = name.reduce(
      (e, i, index) => ({
        ...e,
        [i]: data[index].body().map((d) => d.data()),
      }),
      {}
    );
    if (name.find((e) => data[e].length)) {
      props.setDialog(DialogGetClientNumber, true, { data, name }, callbacks);
    } else {
      cb();
    }
  } else {
    cb();
  }
};

export function updateDevis(id, data) {
  return new Promise((resolve, reject) =>
    api.devis.patch(id, data).then(resolve).catch(reject)
  );
}

const saveDomofinance = (domofinance, {
  close, setData, save, history,
  redirect, remindFacture, cb
}) => {
  Promise.all([
    remindFacture !== !domofinance.get('valid', false) &&
      setData('remindFacture', !domofinance.get('valid', false)),
    setData('domofinance', domofinance)
  ].filter(Boolean)).then(() => save(false).then(data => {
    history.push(`/${redirect || 'devis'}/${data.id}`);
    if (cb) {
      cb();
    } else if (close) {
      close();
    }
  }));
};

const sendDomofinance = (data, domofinance, close) => {
  const devisId = data.getIn(['createdFrom', 'collection']) === 'devis'
    && data.getIn(['createdFrom', '_id']);
  if (devisId) {
    updateDevis(devisId, {
      domofinance,
      finalPrice: data.get('finalPrice'),
      products: data.get('products').toJS(),
    }).then(({ body }) =>
      send({
        multiline: devisLetter(body().data())
      }, body().data()).then(() => close()).catch(throwIt)
    ).catch((e) =>
      notifSystem.error('Erreur', (e && e.message) || 'Devis non envoyé'));
  }
};

const checkDomofinancePrice = (domofinance) => {
  if ((domofinance.get('amount', 0) - domofinance.get('contribution', 0))
    >= MIN_AMOUNT_DEVIS * 100) {
    return true;
  }
  notifSystem.error('Erreur domofinance',
    `Le montant ne peut être inférieur à ${MIN_AMOUNT_DEVIS} €`);
  return false;
};

export const openDomofinance = (data, props) =>
  store.dispatch(setDialog({
    name: 'DomofinanceDialog',
    open: true,
    contentProps: {
      data,
      domofinance: data.get('domofinance', new Map()),
    },
    dialogProps: {
      title: 'Domofinance',
      customHeader: <Button
        style={{
          color: data.getIn(['domofinance', 'modified']) ?
            green[500] : orange[500],
          borderColor: data.getIn(['domofinance', 'modified']) ?
            green[500] : orange[500],
        }}
        variant={'outlined'}
        children={data.getIn(['domofinance', 'modified']) ?
          'FINANCEMENT' : 'EXEMPLE'}
      />
    },
    actions: [
      props.redirect === 'intervention' && {
        children: 'Envoyer',
        color: 'primary',
        onClick: (
          { domofinance },
          close
        ) => {
          if (checkDomofinancePrice(domofinance)) {
            if (!domofinance.get('modified')) {
              domofinance = domofinance.set('modified', true);
            }
            saveDomofinance(domofinance, {
              ...props,
              remindFacture: data.get('remindFacture', false),
              cb: () => sendDomofinance(data, domofinance, close)
            });
          }
        }
      },
      {
        children: 'Enregistrer',
        color: 'primary',
        onClick: (
          { domofinance },
          close
        ) => {
          if (checkDomofinancePrice(domofinance)) {
            if (!domofinance.get('modified')) {
              domofinance = domofinance.set('modified', true);
            }
            saveDomofinance(domofinance, {
              ...props,
              remindFacture: data.get('remindFacture', false),
              close
            });
          }
        }
      }
    ].filter(Boolean),
  }));

export const openAssignDevisDialog = (props, devis) =>
  openAssignDialog(props, devis, 'devis');
