import React from 'react';

import {
  Chip,
  Grid
} from '@material-ui/core';
import { genHTMLContent, Typography } from '../../helpers';
import ShowMore from '../ShowMore';
import {
  MSQ_IMM, MSQ_DET
} from '@bit/dev-lba.lib.local-globals/questionTypeMSteps';
import { List } from 'immutable';
import { withStyles } from '@material-ui/styles';
import Dropdown from '../../../Stats/common/dropdown/dropdown';

const styles = {
  gridContainer: {
    margin: 0,
  },
  chip: {
    fontSize: 10,
    width: '100%',
    height: 20,
    padding: 0,
  },
  chipDropDown: {
    fontSize: 10,
    height: 20,
    padding: 0,
  },
};

export default withStyles(styles)(
  ({
    element: { attachements, audio, ...element },
    answers,
    setAnswers,
    restart,
    classes,
  }) => (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h6" component="h5" children={'Bon à savoir'} />
        {genHTMLContent(element.help)}
      </Grid>
      <ShowMore
        element={{ attachements }}
        text={element.help}
        title="Bon à savoir"
      />
      <Grid item xs={12}>
        <Typography
          variant="h6"
          component="h5"
          children={'Questions pour le client'}
        />
        {(element.questions || []).map((e, i) => (
          <>
            <Grid
              className={classes.gridContainer}
              container
              spacing={1}
              alignItems='center'
            >
              <Typography
                key={i}
                style={{
                  whiteSpace: 'nowrap',
                  fontSize: 15,
                  lineHeight: (e.responses || []).length ? '0.5' : '2',
                }}
                children={`${i + 1}. ${e.question}`}
              />
              {e.questionType === MSQ_IMM
                ? [
                  { label: 'Oui', value: '1' },
                  { label: 'Non', value: '0' },
                ].map((l) => (
                  <Grid item>
                    <Chip
                      className={classes.chip}
                      color={
                        answers.has(e.question) &&
                          (+answers.get(e.question) || 0).toString() ===
                            l.value
                          ? 'primary'
                          : 'default'
                      }
                      label={l.label}
                      onClick={() =>
                        !restart && setAnswers([e.question], !!+l.value)
                      }
                    />
                  </Grid>
                ))
                : ''}
              {e.questionType === MSQ_DET && (e.responses || []).length
                ? (e.responses.length <= 2 &&
                    e.responses.map((r) => (
                      <Grid item>
                        <Chip
                          className={classes.chip}
                          color={
                            (e.multipleChoice &&
                              answers.has(e.question) &&
                              answers.get(e.question).includes(r.response)) ||
                            answers.get(e.question) === r.response
                              ? 'primary'
                              : 'default'
                          }
                          label={r.response}
                          onClick={() =>
                            !restart &&
                            setAnswers(
                              [e.question],
                              (e.multipleChoice &&
                                (answers.has(e.question)
                                  ? answers
                                    .get(e.question)
                                    .toggleValue(r.response)
                                  : new List([r.response]))) ||
                                r.response,
                              r.responseType
                            )
                          }
                        />
                      </Grid>
                    ))) || (
                  <Grid item>
                    <Dropdown
                      style={{ height: 20 }}
                      title={
                        answers.get(e.question) &&
                        (e.multipleChoice ? (
                          answers
                            .get(e.question)
                            .map((e) => (
                              <Chip
                                className={classes.chipDropDown}
                                color={'primary'}
                                label={e}
                              />
                            ))
                        ) : (
                          <Chip
                            color={'primary'}
                            className={classes.chip}
                            label={answers.get(e.question)}
                          />
                        ))
                      }
                      data={e.responses}
                      idKey={null}
                      renderKey={'response'}
                      isSelected={(elem) =>
                        answers.has(e.question) && e.multipleChoice
                          ? answers.get(e.question).includes(elem.response)
                          : answers.get(e.question) === elem
                      }
                      onChange={({ target: { value } }) =>
                        !restart && setAnswers(
                          [e.question],
                          (e.multipleChoice &&
                            (answers.has(e.question)
                              ? answers
                                .get(e.question)
                                .toggleValue(value[1].response)
                              : new List([
                                value[1].response,
                              ]))) ||
                            value.response,
                          value.responseType
                        )
                      }
                      multiple={!!e.multipleChoice}
                    />
                  </Grid>
                )
                : ''}
            </Grid>
          </>
        ))}
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="h6"
          component="h5"
          children={'Discours commercial'}
        />
        {genHTMLContent(element.mailText)}
      </Grid>
      <ShowMore
        element={{ audio }}
        text={element.mailText}
        title="Discours commercial"
      />
    </Grid>
  )
);
