import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Autosuggest from 'react-autosuggest';
import classNames from 'classnames';

import api from '../../api';
import notifSystem from '../../notifSystem';

const styles = () => ({
  container: {
    height: 35,
    width: '70%',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 100,
    width: '50%',
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    width: '100%',
  },
  searchInput: {
    borderRadius: 6,
    minWidth: '12.5em',
    width: '100%',
  },
  suggestionsCustomList: {
    boxShadow: '0px 2px 30px 1px rgba(0,0,0,0.14)',
  },
});

function renderInput({
  InputLabelProps, InputProps, classes,
  ref, width, maxWidth, ...other }) {
  return (
    <TextField
      className={classNames(classes.searchInput, classes.adresseText)}
      style={{ width, maxWidth }}
      InputProps={{
        inputRef: ref,
        disableUnderline: true,
        classes: {
          input: classes.input
        },
        ...InputProps
      }}
      InputLabelProps={InputLabelProps}
      {...other}
    />
  );
}

class CustomAddress extends PureComponent {

  state = {
    value: '',
    suggestions: [],
    data: [],
    typingTimeout: 0
  };

  getSuggestions = (newValue) =>
    api.map.custom('getPlaces').get({
      value: JSON.stringify(newValue)
    }).then(e => e.body().data().features)
      .catch(e => {
        notifSystem.error(e.name, e.message);
        return [];
      });

  handleAddressChange = async (value) => {
    this.setState({
      suggestions: await this.getSuggestions(value)
    });
  }

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  }

  renderSuggestion = (suggestion, { isHighlighted }) => (
    <MenuItem button
      selected={isHighlighted}
      component="div">
      <div>
        <span>{suggestion.properties.label}</span>
      </div>
    </MenuItem>
  );

  onValide = (toAdd) => this.props.updateAddress(toAdd, () =>
    this.props.setAddress(toAdd.properties.label));

  onChange = (event, { newValue }) => {
    if (newValue !== undefined) {
      this.props.setAddress(newValue);
    }
  }

  onKeyUp = (e) => {
    const self = this;
    if (self.state.typingTimeout) {
      clearTimeout(self.state.typingTimeout);
    }
    const value = e.target.value;
    if (value.length > 3) {
      self.setState({
        suggestions: [],
        typingTimeout: setTimeout(() => {
          self.handleAddressChange(value);
        }, 500)
      });
    }
  }

  render() {
    const {
      xs,
      sm,
      md,
      lg,
      xl,
      grid,
      classes,
      address,
      placeholder,
      style,
      adresseText,
      InputProps,
      InputLabelProps,
      ...otherProps
    } = this.props;
    const { suggestions } = this.state;
    delete otherProps.setData;
    const inputProps = {
      classes: { ...classes, adresseText },
      placeholder,
      value: address,
      onChange: this.onChange,
      onKeyUp: this.onKeyUp,
      style,
      InputProps,
      InputLabelProps
    };
    const input = <Autosuggest
      theme={{
        container: classes.container,
        suggestionsContainerOpen: `${
          classes.suggestionsContainerOpen}`,
        suggestionsList: `${classes.suggestionsList} ${
          classes.suggestionsCustomList}`,
        suggestion: classes.suggestion,
      }}
      inputProps={inputProps}
      suggestions={suggestions}
      renderInputComponent={renderInput}
      onSuggestionsFetchRequested={e => e}
      onSuggestionsClearRequested={this.onSuggestionsClearRequested}
      getSuggestionValue={({ name }) => name}
      onSuggestionSelected={(e, { suggestion }) => this.onValide(suggestion)}
      renderSuggestionsContainer={({ containerProps, children }) =>
        <Paper {...containerProps} square>{children}</Paper>}
      renderSuggestion={this.renderSuggestion}
    />;
    if (grid) {
      return (
        <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
          {input}
        </Grid>
      );
    }
    return input;
  }
}

CustomAddress.propTypes = {
  path: PropTypes.arrayOf(PropTypes.string),
  label: PropTypes.string,
  setData: PropTypes.func,
  grid: PropTypes.bool,
  capsLocked: PropTypes.bool
};
export default withStyles(styles)(CustomAddress);
