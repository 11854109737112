import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import CardSchudle from './CardSchudle';
import Grid from '@material-ui/core/Grid';
import { yellow, red, grey, orange } from '@material-ui/core/colors';
import api from '../../../api';

const styles = theme => ({
  paper: {
    padding: theme.spacing(2),
    overflow: 'auto'
  },
  emptyPaper: {
    height: '276px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  title: {
    margin: theme.spacing(1),
    fontSize: '1.2rem',
    color: grey[500]
  },
  titleText: {
    fontSize: '15px'
  }
});

class SchudleStats extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      absences: 0,
      lates: 0,
      avertis: 0
    };
  }

  async componentDidMount() {
    const { _id } = this.props.SchudleStatsData;
    if (_id) {
      let res = await api.attendance.get(_id);
      let data = res.body()[0] && res.body()[0].data();
      if (data) {
        this.setState({
          absences: (data.absences || []).filter(e => !e.isCanceled).length,
          lates: data.latesCount || 0,
          avertis: parseInt(data.latesCount / 3, 10)
        });
      }
    }
  }

  render() {
    const { lates, absences, avertis } = this.state;
    return (
      <Grid container item xs>
        <Grid item xs={4}>
          <CardSchudle name={'RETARDS'}
            number={lates} color={yellow[700]} />
        </Grid>
        <Grid item xs={4}>
          <CardSchudle name={'ABSENCES'}
            number={absences} color={orange[800]} />
        </Grid>
        <Grid item xs={4}>
          <CardSchudle name={'AVERTISSEMENTS'}
            number={avertis} color={red[500]} />
        </Grid>
      </Grid>
    );
  }
}

SchudleStats.propTypes = {
  classes: PropTypes.object.isRequired,
  SchudleStatsData: PropTypes.object.isRequired,
};

export default withStyles(styles)(SchudleStats);
