import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { RecordVoiceOver } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  conversation: {
    padding: 16,
    overflow: 'auto',
    maxHeight: '300px'
  },
  speakerOne: {
    color: 'rgb(0, 31, 100)'
  },
  speakerTwo: {
    color: 'rgb(110, 9, 20)'
  },
  flip: {
    transform: 'scaleX(-1)'
  },
};

const AudioDiscussion = ({ classes, transcription, blurry }) => (
  <Grid container item className={classes.conversation}>
    {transcription.split('\n').map((t, i) => i % 2 === 0 ? [
      <Grid item xs={1}><RecordVoiceOver color="primary"/></Grid>,
      <Grid item xs={9}>
        <Typography
          children={t} variant="caption"
          className={`${classes.speakerOne} ${blurry}`}
        />
      </Grid>,
      <Grid item xs={2}></Grid>,
    ] : [
      <Grid item xs={2}></Grid>,
      <Grid item xs={9}>
        <Typography
          children={t} variant="caption"
          className={`${classes.speakerTwo} ${blurry}`}
        />
      </Grid>,
      <Grid item xs={1}>
        <RecordVoiceOver
          className={classes.flip}
          color="secondary"
        />
      </Grid>
    ])}
  </Grid>
);

export default withStyles(styles)(AudioDiscussion);
