import React, { useEffect, useState } from 'react';

import {
  Grid, Typography
} from '@material-ui/core';

import api from '../../api';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator
} from '@material-ui/lab';
import { moment } from '@bit/dev-lba.lib.local-globals/moment';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  HourglassEmpty,
  HourglassFull,
  Pause,
  PlayArrow,
  Update
} from '@material-ui/icons';

const mapStateToProps = ({ users }) => ({ users });

const findUserLogin = (users, id) => {
  const user = users.find((u) => u._id === id);
  return user ? user.login : 'Automatique';
};

const useStyles = makeStyles(({
  heigth: { overflow: 'auto', maxHeight: '300px' }
}));

const DemarchageHistory = ({ id, users }) => {
  const [demarchageHistory, setDemarchageHistory] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    api.history
      .getAll({ name: 'osId', id, type: 'DEMARCHAGE' })
      .then(res => setDemarchageHistory(
        res.body().map(e => e.data())
          .sort((a, b) => new Date(a.date) - new Date(b.date))
          .map(e => ({
            ...e,
            date: moment(e.date).format('L [à] HH:mm'),
            user: findUserLogin(users, e.userId)
          }))
      ));
  }, []);

  const getIcon = (text) => {
    const obj = {
      'Début compteur': <HourglassFull fontSize='small' />,
      'Prolongation': <Update fontSize='small'/>,
      'Pause': <Pause fontSize='small' />,
      'Fin du temps de demarchage': <HourglassEmpty fontSize='small' />,
      'Reprise démarchage': <PlayArrow fontSize='small' />,
    };
    return obj[Object.keys(obj).find((e) => text.includes(e))] || '';
  };

  return (
    <Grid item xs={12}>
      {!!demarchageHistory.length && [
        <Typography key={0} variant="h6" children="Historique Demarchage" />,
        <div key={1} className={classes.heigth}>
          {demarchageHistory.map(({ date, user, text }, i) => (
            <Timeline key={i} >
              <TimelineItem>
                <TimelineOppositeContent>
                  <Typography color="textSecondary">{date}</Typography>
                  <Typography
                    color="textSecondary"
                    children={user || 'Automatique'}
                  />
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot children={getIcon(text)}/>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <Typography variant="body1" children={text} />
                </TimelineContent>
              </TimelineItem>
            </Timeline>
          ))}
        </div>
      ]}
    </Grid>
  );
};

export default connect(mapStateToProps)(DemarchageHistory);
