import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';


const styles = theme => ({
  formControl: {
    margin: theme.spacing(1),
    width: '100%'
  },
  group: {
    margin: `${theme.spacing(1)}px 0`,
    flexDirection: 'row',
    justifyContent: 'center'
  },
});

const Causes = React.memo(({
  value,
  handleChange,
  classes,
  fetchData,
  isPris
}) => (
  <FormControl component="fieldset" className={classes.formControl}>
    <RadioGroup
      className={classes.group}
      value={value ? value.toString() : ''}
      onChange={handleChange} >
      {fetchData.map((e, id) => (
        <FormControlLabel key={id} disabled={!isPris && e.disable}
          value={e._id.toString()} control={<Radio />} label={e.name} />
      ))}
    </RadioGroup>
  </FormControl>));

export default withStyles(styles)(Causes);
