import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import TableCell from '@material-ui/core/TableCell';
import CustomTextField from '../../CustomInputs/CustomTextField';

const InputProps = {
  inputProps: { min: '1' }
};

class ProductQte extends PureComponent {

  inputQT(qt, path, setData) {
    return (
      <CustomTextField
        type='number'
        value={qt}
        InputProps={InputProps}
        path={path}
        setData={setData}
        name={'qt'}
        multiline={false}
      />
    );
  }

  render() {
    const { qt, path, setData, width } = this.props;
    return (
      !isWidthDown('sm', width) ?
        <TableCell padding="checkbox" align={'right'}>
          { this.inputQT(qt, path, setData) }
        </TableCell> :
        this.inputQT(qt, path, setData)
    );
  }
}

ProductQte.defaultProps = {
  qt: 0
};

ProductQte.propTypes = {
  qt: PropTypes.number.isRequired,
  path: PropTypes.arrayOf(PropTypes.string).isRequired,
  setData: PropTypes.func.isRequired
};

export default withWidth()(ProductQte);
