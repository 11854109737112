import React from 'react';

/**
 * @param {Function<Object>|Object} filters
 * @returns {React.FC}
 */
export default function withFilterName(filters) {
  return WrappedComponent => props => {
    const {
      match: { params: { filter: filterName } },
    } = props;
    const componentProps = {
      ...props,
      filterName
    };
    const type = typeof filters;

    if (type === 'function' || type === 'object') {
      componentProps.filter = (type === 'function'
        ? filters()
        : filters
      )[filterName] || null;
    }
    return <WrappedComponent {...componentProps} />;
  };
}
