import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import ReactEcharts from 'echarts-for-react';
import { getOption } from './getOptions';
import { getOptionUser } from './getOptionsUser';

import Typography from '@material-ui/core/Typography';
import { grey, green } from '@material-ui/core/colors';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
  paper: {
    padding: theme.spacing(3),
    minHeight: 450,
    maxHeight: 450,
  },
  title: {
    fontSize: '1.2rem',
    color: grey[500],
  },
  progress: {
    margin: theme.spacing(2),
  },
  block: {
    width: '100%',
    height: 450,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

class ChiffreDaffaires extends PureComponent {
  render() {
    const { EquipesCAData, userCAData, classes, mode } = this.props;
    return (
      <React.Fragment>
        <Paper className={classes.paper}>
          <Typography className={classes.title} variant="h6" align="left">
            Vos chiffres d'affaires personel:
          </Typography>
          {userCAData ? (
            <ReactEcharts
              style={{ width: '100%', height: '450px' }}
              option={getOptionUser(userCAData.result[1], mode, green[500])}
            />
          ) : (
            <div className={classes.block}>
              <CircularProgress />
            </div>
          )}
          {false && (
            <React.Fragment>
              <Typography
                className={classes.title}
                variant="h6"
                align="left"
              >
                Chiffre d'affaires d'équipe:
              </Typography>
              <ReactEcharts
                style={{ width: '100%', height: '100%' }}
                option={getOption(EquipesCAData, mode)}
              />
              <div className={classes.block}>
                <CircularProgress className={classes.progress} />
              </div>
            </React.Fragment>
          )}
        </Paper>
      </React.Fragment>
    );
  }
}

ChiffreDaffaires.propTypes = {
  classes: PropTypes.object.isRequired,
  EquipesCAData: PropTypes.array.isRequired,
  mode: PropTypes.string.isRequired,
};

export default withStyles(styles)(ChiffreDaffaires);
