import React, { PureComponent } from 'react';
import { moment } from '@bit/dev-lba.lib.local-globals/moment';
import { CircularProgress, MenuItem, Paper, Select } from '@material-ui/core';
import {
  red,
  green,
  blue,
  orange,
  purple,
  yellow,
  brown
} from '@material-ui/core/colors';
import { permit } from '@bit/dev-lba.lib.local-globals/restrictions';
import EchartsWithTotal from '../../Utils/EchartsWithTotal';

import { connect } from 'react-redux';

import api from '../../../api';
import { getOption } from '../common/graph/options/globalOptions';
import StatsPanel from '../common/panel/StatsPanel';
import Dropdown from '../common/dropdown/dropdown';
import { withDisplaying } from '../../../hoc';
import { compose } from 'redux';
import { I_VRF, I_AVR } from '@bit/dev-lba.lib.local-globals/iStatus';
import { setDialog } from '../../../actions/dialog';
import { modes, modeIndex } from '@bit/dev-lba.lib.local-globals/stats';
import {
  SOCIETE,
  CURATELLE,
  AGENCE_IMMO,
} from '@bit/dev-lba.lib.local-globals/statusClient';
import { S_BTOB } from '@bit/dev-lba.lib.local-globals/services';
import {
  formatterEchartLabel
} from '../ServicesStats/DisplayRecouvrementRatio';
import GraphStatsObjectif from '../ChiffreDaffaires/GraphStatsObjectif';
import { R_NO } from '@bit/dev-lba.lib.local-globals/recStatus';

const mapStateToProps = ({ users, userId }) => {
  const user = users.find((e) => e._id === userId);
  return {
    users,
    user,
    isAdmin: permit(user),
  };
};

const datesOptions = [
  {
    _id: 'date.ajout',
    name: 'Ajout',
  },
  {
    _id: 'date.intervention',
    name: 'Intervention',
  },
  {
    _id: 'date.verif',
    name: 'Verif',
  },
];

const selectData = [
  {
    _id: 'distance',
    name: 'à distance',
    filterName: 'Distance',
  },
  {
    _id: 'surplace',
    name: 'sur Place',
    filterName: 'Sur place',
  },
];

const clientNames = [
  {
    _id: 'Société',
    name: 'Société',
    filterName: 'Société',
  },
  {
    _id: 'Btob',
    name: 'Btob',
    filterName: 'Btob',
  },
  {
    _id: 'Particulier',
    name: 'Particulier',
    filterName: 'Particulier',
  },
];

const filters = {
  Reçu: {
    isRegle: true,
    status: I_VRF,
  },
  'Total non reçu': {
    isRegle: false,
    status: I_VRF,
  },
  'A verifier jaune': {
    noPaymentReceived: true,
    status: I_AVR,
    'recovery.status': { $ne: R_NO },
  },
  'A verifier marron': {
    noPaymentReceived: { $ne: true },
    status: I_AVR,
    'recovery.status': { $ne: R_NO },
  },
  'Total distance': {
    onSitePayment: false,
    status: I_VRF,
  },
  'Total surplace': {
    onSitePayment: true,
    status: I_VRF,
  },
  'Reçu distance': {
    isRegle: true,
    onSitePayment: false,
    status: I_VRF,
  },
  'Reçu surplace': {
    isRegle: true,
    onSitePayment: true,
    status: I_VRF,
  },
  Distance: {
    isRegle: false,
    onSitePayment: false,
    status: I_VRF,
  },
  'Sur place': {
    isRegle: false,
    onSitePayment: true,
    status: I_VRF,
  },
  Total: {
    $or: [
      { isRegle: true, status: I_VRF },
      {
        isRegle: false,
        status: I_VRF,
      },
    ],
  },
  Société: {
    $or: [
      { 'client.civility': 'Société' },
      { 'billing.clientStatus': { $in: [SOCIETE, AGENCE_IMMO, CURATELLE] } },
    ],
    'billing.grandCompte': { $exists: false },
  },
  Btob: {
    'billing.grandCompte': { $exists: true, $ne: '' },
  },
  Particulier: {
    'client.civility': { $ne: 'Société' },
    'billing.clientStatus': { $nin: [SOCIETE, AGENCE_IMMO, CURATELLE] },
    'billing.grandCompte': { $exists: false },
  },
};

class GlobalStats extends PureComponent {
  constructor() {
    super();
    this.state = {
      mode: 'day',
      from: moment().startOf('day').toDate(),
      to: moment().endOf('day').toDate(),
      date: 'date.ajout',
      stateDetail: 'distance',
      showMode: 'CA',
      loading: true,
      repartitionCA: new Array(3).fill({}),
      clientName: clientNames.map((e) => e._id),
    };
  }

  componentDidMount() {
    this.doRequests();
  }

  handleChange = (name, cb = (f) => f) => (e) => {
    this.setState({ [name]: e.target.value }, cb);
  };

  onDatesChange = (from, to, mode) => {
    this.setState({ from, to, mode, loading: true }, this.doRequests);
  };

  doRequests = () => {
    const { mode, from, to, showMode, date } = this.state;
    const { isAdmin } = this.props;
    api.stats
      .get('recouvrement/globalCA', {
        mode,
        isCA: showMode === 'CA' ? 1 : 0,
        from,
        to,
        date,
      })
      .then((result) => {
        const { data, details } = result.body().data();
        this.setState({
          data: isAdmin
            ? data
            : Object.keys(data)
              .filter((key) => !key.includes('Reçu'))
              .reduce(
                (obj, key) => ({
                  ...obj,
                  [key]: data[key],
                }),
                {}
              ),
          details,
          loading: false,
        });
      });
  };

  handleDialogOpen = (name) => ({ dataIndex, seriesName }) => {
    const { mode, stateDetail, date, from } = this.state;
    const { dispatch } = this.props;
    let typeMode = modes[mode];
    let index = Object.keys(modeIndex[mode]).find(
      (e) => modeIndex[mode][e] === dataIndex
    );
    let filter = filters[seriesName];
    if (name) {
      filter = seriesName.includes('Total')
        ? {
          $and: [
            filters[seriesName.replace('Total ', '')],
            filters[`Total ${stateDetail}`],
          ],
        }
        : {
          $and: [
            filters[seriesName],
            filters[selectData.find((e) => e._id === stateDetail).filterName],
          ],
        };
    }
    if ((typeMode === 'day' && mode === 'week') || typeMode === 'month') {
      index = index - 1;
    } else if (typeMode === 'day') {
      typeMode = 'date';
    }
    const data = {
      ...filter,
      [date]: {
        $gt: moment(from).set(typeMode, index).startOf(typeMode).toDate(),
        $lt: moment(from).set(typeMode, index).endOf(typeMode).toDate(),
      },
    };
    return dispatch(
      setDialog({
        name: 'InterListDialog',
        open: true,
        hideClose: true,
        dialogProps: {
          title: 'Liste d\'intervention',
          maxWidth: 'lg',
        },
        contentProps: {
          filter: data,
          service: seriesName === 'Btob' ? S_BTOB : undefined,
        },
        actions: [
          {
            children: 'Fermer',
            color: 'secondary',
            onClick: (r, close) => close(),
          },
        ],
      })
    );
  };

  changeArray = (field) => (e) => {
    let data = [...this.state[field]];
    const value = e.target.value[1];
    if (data.includes(value)) {
      data = data.filter((u) => u !== value);
    } else {
      data.push(value);
    }
    this.setState({ [field]: data });
  };

  render() {
    const {
      data,
      from,
      to,
      mode,
      showMode,
      loading,
      date,
      stateDetail,
      details = [],
      clientName,
    } = this.state;

    const dates = [moment(from).startOf(mode), moment(to).endOf(mode)];
    const nameSelect = selectData.find((e) => e._id === stateDetail).name;
    const { isAdmin, users } = this.props;
    return [
      <GraphStatsObjectif allUsers={users} key={0} />,
      <Paper key={1}>
        <StatsPanel
          title={'Statistiques d\'interventions non recu'}
          onDatesChange={this.onDatesChange}
          enabledModes={[true, true, true, true, true]}
          defaultState={{
            mode,
            from,
            to,
          }}
          body={
            <React.Fragment>
              <Select
                style={{ marginRight: '1rem' }}
                value={showMode}
                onChange={this.handleChange('showMode', this.doRequests)}
              >
                <MenuItem value={'CA'}>Chiffre affaire</MenuItem>
                <MenuItem value={'NBR'}>Nombre</MenuItem>
              </Select>
              <Dropdown
                title={'Dates'}
                data={datesOptions}
                isSelected={(e) => date === e}
                onChange={this.handleChange('date', this.doRequests)}
                multiple={false}
              />
            </React.Fragment>
          }
        >
          {loading && (
            <form style={{ textAlign: 'center' }}>
              <CircularProgress style={{ margin: '100px' }} />
            </form>
          )}
          {data && !loading ? (
            <EchartsWithTotal
              enabledModes={[true, true, true, true, true]}
              notMerge={true}
              style={{ width: '100%' }}
              option={getOption({
                ...(isAdmin && {
                  formatter: (values) =>
                    formatterEchartLabel({
                      values,
                      data,
                      ratioKey: () => 'Total',
                      keysRationToDisplay: [
                        'Distance',
                        'Sur place',
                        'Total non reçu',
                      ],
                    }),
                }),
                data: data,
                mode,
                labels: Object.keys(data),
                dates,
                colors: [red[800], blue[500], green[500],
                  ...(isAdmin ? [orange[500]] : []), yellow[600], brown[500]],
              })}
              onEvents={{ click: this.handleDialogOpen(false) }}
              keysToDisplay={[
                'Distance',
                'Sur place',
                'Reçu',
                'Total non reçu',
                'A verifier jaune',
                'A verifier marron',
                'Total',
              ]}
              keyRatio={isAdmin ? () => 'Total' : false}
            />
          ) : null}
        </StatsPanel>
        <StatsPanel
          defaultState={{
            mode,
            from,
            to,
          }}
          title={
            `Statistiques d'interventions non recu (détaillée) ${nameSelect}`
          }
          body={
            <React.Fragment>
              <Dropdown
                title={'Type'}
                data={selectData}
                isSelected={(e) => stateDetail === e}
                onChange={this.handleChange('stateDetail')}
                multiple={false}
              />
              <Dropdown
                title={'Client'}
                data={clientNames}
                isSelected={(e) => clientName.includes(e)}
                onChange={this.changeArray('clientName')}
              />
            </React.Fragment>
          }
          displayModePanel={false}
          displayDatesDropper={false}
        >
          {loading && (
            <form style={{ textAlign: 'center' }}>
              <CircularProgress style={{ margin: '100px' }} />
            </form>
          )}

          {data && !loading ? (
            <EchartsWithTotal
              notMerge={true}
              style={{ width: '100%' }}
              option={getOption({
                ...(isAdmin && {
                  formatter: (values) =>
                    formatterEchartLabel({
                      values,
                      data: details[stateDetail],
                      ratioKey: (name) => `Total ${name}`,
                      keysRationToDisplay: clientName,
                    }),
                }),
                data: details[stateDetail],
                mode,
                labels: [
                  ...clientName,
                  ...(clientName.length === 1
                    ? [`Total ${clientName}`]
                    : clientName.map((e) => `Total ${e}`)),
                ],
                dates,
                colors: [
                  red[800],
                  blue[500],
                  green[500],
                  purple[500],
                  orange[500],
                ],
              })}
              onEvents={{ click: this.handleDialogOpen(true) }}
              keyRatio={
                isAdmin
                  ? (name) =>
                    ['Société', 'Btob', 'Particulier'].some((s) => s === name)
                      ? `Total ${name}`
                      : false
                  : false
              }
              keysToDisplay={[
                `Reçu ${stateDetail}`,
                'Société',
                'Btob',
                'Particulier',
                'Total',
              ]}
            />
          ) : null}
        </StatsPanel>
      </Paper>
    ];
  }
}

GlobalStats.displayName = 'GlobalStats';

export default compose(withDisplaying(), connect(mapStateToProps))(GlobalStats);
