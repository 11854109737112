import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { setDialogContentProp } from '../../../actions/dialog';
import Content from './Content';
import { CircularProgress } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import notifSystem from '../../../notifSystem';
import { getCaldeoData } from '../../../actions/caldeo';
import { Map, fromJS, List } from 'immutable';
import { calculateDomofinance } from '../../../utils/financement';
import { compose } from 'recompose';
import { withRouter } from 'react-router';

const styles = {
  loadingContainer: {
    textAlign: 'center'
  }
};

const setCaldeo = setDialogContentProp('data');
const setErrors = (v) => setDialogContentProp('setErrors')(v);

const mapStateToProps = ({
  dialog: {
    contentProps: {
      merge,
      devisId,
      type,
    },
  },
  oldChaudiere,
  userId,
  users,
}) => ({
  merge,
  devisId,
  type,
  oldChaudiere,
  user: users && users.find(e => e._id === userId),
});

const mapDispatchToProps = {
  setCaldeo,
  setErrors
};

const paths = {
  houseType: ['houseType'],
  nbPiece: ['nbPiece'],
  surface: ['surface'],
  oldChaudiere: ['combination', 'oldChaudiere'],
  ceeAmount: ['ceeAmount'],
  anahAmount: ['anahAmount'],
  chaudiereType: ['chaudiereType'],
  dependent: ['dependent'],
  householdStatus: ['householdStatus'],
  amount: ['domofinance', 'amount'],
  report: ['domofinance', 'report'],
  devisDate: ['date', 'caldeoDevis'],
  factureDate: ['date', 'caldeoFacture'],
  comboType: ['combination', 'comboType'],
};

const filterChaudiere = (type, origChaudieres) =>
  !type ? origChaudieres : (origChaudieres
    .filter(e => e.get('type') === Number(type)) || new List()).toJS();

class CaldeoDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      merge: props.merge || new List(),
      tabValue: 0,
      origChaudieres: new List(),
      loading: false,
      chaudiereList: [],
      choosenChaudiere: {},
      chaudiereType: '',
      errors: [],
      previewLoading: false
    };
  }

  getData = () => {
    const { devisId, merge: parentMerge, setCaldeo } = this.props;
    getCaldeoData(devisId).then(data => {
      if (data) {
        const {
          products, combination, signature,
          client, billing, domofinance
        } = parentMerge.toJS();
        this.setState({
          merge: fromJS({
            ...data,
            ...(products ? { products } : {}),
            ...(combination ? { ...{ combination,
              oldChaudiere: combination.oldChaudiere ||
                data.combination.oldChaudiere
            } } : {}),
            ...(signature ? { signature } : {}),
            ...(client ? { client } : {}),
            ...(billing ? { billing } : {}),
            ...(domofinance && (domofinance || {}).amount > 0 ?
              { domofinance } : {}),
          })
        }, () => {
          if ((data.combination || {}).oldChaudiere) {
            this.getChaudiere();
          }
          setCaldeo(this.state.merge);
        });
      }
    }).catch(() =>
      notifSystem.error('erreur system', 'Donnée non récuperé'));
  }

  getChaudiere = () => {
    const { chaudiereList, origChaudieres, merge } = this.state;
    const choosenChaudiere = chaudiereList.length ?
      chaudiereList.find(e => e._id ===
        merge.getIn(paths.oldChaudiere)) : {};
    if (choosenChaudiere) {
      this.setState({
        choosenChaudiere,
        chaudiereList: filterChaudiere(choosenChaudiere.type, origChaudieres),
        chaudiereType: (choosenChaudiere.type || '').toString(),
      });
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (!props.oldChaudiere.array
      .equals(state.origChaudieres)) {
      return {
        origChaudieres: props.oldChaudiere.array,
        chaudiereList: props.oldChaudiere.array.toJS(),
      };
    }
    return null;
  }

  componentDidMount() {
    const { merge, oldChaudiere, type, devisId } = this.props;
    if (type === 'interventions' && devisId) {
      this.getData();
    } else if ((Map.isMap(merge) &&
    merge.getIn(paths.oldChaudiere))
    && oldChaudiere) {
      this.getChaudiere();
    }
    this.props.setErrors((errors) => this.setState({
      errors
    }));
  }

  setLoading = (loading) => {
    this.setState({
      loading
    });
  }

  setLoadingPreview = (previewLoading) => {
    this.setState({
      previewLoading
    });
  }

  setData = (path, value, cb) => {
    const { oldChaudiere, setCaldeo } = this.props;
    const { origChaudieres } = this.state;
    const origChaudiere = origChaudieres || oldChaudiere.array;
    if (path[0] === 'chaudiereType') {
      return this.setState({
        [path[0]]: value,
        chaudiereList: filterChaudiere(value, origChaudiere),
      });
    }
    this.setState((prevState) => ({
      merge: prevState.merge.setIn(path, value)
    }), () => {
      if (cb) {
        cb();
      }
      if (path[1] === 'oldChaudiere') {
        this.getChaudiere();
      }
      if ([paths.ceeAmount[0], paths.anahAmount[0]]
        .includes(path[0])) {
        calculateDomofinance(this.state.merge, this.setData);
      }
      setCaldeo(this.state.merge);
    });
  };

  handleTabChange = (e, newVal) => {
    this.setState({ tabValue: newVal });
  }

  render() {
    const { classes, user, type } = this.props;
    const {
      errors, loading, tabValue, merge, previewLoading,
      ...state
    } = this.state;
    return loading ?
      <div className={classes.loadingContainer}>
        <CircularProgress />
      </div>
      : <Content
        paths={paths}
        errors={errors}
        setData={this.setData}
        saveData={this.saveData}
        handleTabChange={this.handleTabChange}
        tabValue={tabValue}
        merge={merge}
        user={user}
        parentState={state}
        setLoading={this.setLoading}
        setLoadingPreview={this.setLoadingPreview}
        previewLoading={previewLoading}
        type={type}
      />;
  }
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withRouter,
  withStyles(styles)
)(CaldeoDialog);
