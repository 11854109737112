import React, { Component } from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { moment } from '@bit/dev-lba.lib.local-globals/moment';
import { withStyles } from '@material-ui/core/styles';

import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';

import api from '../../../api';
import CallTo from '../../Calls/Calls';
import menuContainer from '../Intervention/interMenuContainer';
import InterMenuInfo from '../Intervention/InterMenuInfo';
import Comments from '../../Comments';
import { C_ACHAT, C_SAV } from '@bit/dev-lba.lib.local-globals/commentTypes';
import { permit } from '@bit/dev-lba.lib.local-globals/restrictions';
import { fromJS } from 'immutable';
import { Timeline } from '@material-ui/lab';
import AudioPlayer from '../../Calls/AudioPlayer';
import Money from '../../../utils/Money';
const {
  A_DEM,
  A_DEMC,
  A_DEMT,
  data
} = require('@bit/dev-lba.lib.local-globals/statusAchat');

const getStatus = (query) => ({
  SSTDemWarranty: A_DEM,
  SSTDemCWarranty: A_DEMC,
  SSTDemTWarranty: A_DEMT,
})[query];

const styles = {
  padding: {
    padding: '12px',
  },
  lineHeight: {
    lineHeight: 'normal',
    overflowWrap: 'break-word',
  },
  title: {
    marginBottom: 4,
    textTransform: 'uppercase',
    fontSize: '1rem',
  },
  a: {
    color: 'blue',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  timeLine: {
    margin: 0,
    padding: '6px 0px',
  },
  timeLineLimit: {
    maxHeight: 300,
    overflow: 'auto',
  },
  table: {
    border: '1px solid #e0e0e0',
    backgroundColor: 'rgb(245, 245, 245)',
  },
};

class AchatMenu extends Component {
  state = {
    open: false,
    data: '',
  };

  getDevis = async (id) => {
    const res = await api.devis.get(id);

    this.setState({
      open: true,
      data: res.body().data(),
    });
  };

  render() {
    const {
      elem = {},
      appels = [],
      findUserLogin,
      users,
      userId,
      user,
      signalements,
      classes,
      histories,
      match: { params: { filter } }
    } = this.props;
    const warranty = elem?.supplies?.reduce((arr, curr) => {
      if (curr.warranty) {
        curr.warranty.map(e => arr.push({ ...e, pro: curr.pro }));
      }
      return arr;
    }, []).filter(e => e.status === getStatus(filter));

    return (
      <React.Fragment>
        <Grid key="achatMenu" container spacing={4} className={classes.padding}>
          {!!signalements.length && (
            <Grid item xs={12} md={4}>
              <Typography key={5} variant="h6" className={classes.title}>
                Signalements
              </Typography>
              {signalements.map((data) => (
                <InterMenuInfo {...data} />
              ))}
            </Grid>
          )}
          {<Grid item xs={12} md={4} >
            <Typography key={5} variant="h6" className={classes.title}>
              Commentaires achat
            </Typography>
            <Comments
              users={users}
              userId={userId}
              id={elem.id}
              interId={elem._id}
              elevation={null}
              title=''
              comments={fromJS(elem?.comments?.filter(e => e.type === C_ACHAT)
                .reverse().slice(0, 4).reverse())}
              hideDelete={!permit(user, { key: 'deleteComments' })}
              type={C_ACHAT}
            />
          </Grid>}
          {<Grid item xs={12} md={4} >
            <Typography key={5} variant="h6" className={classes.title}>
             Commentaires SAV
            </Typography>
            <Comments
              users={users}
              userId={userId}
              id={elem.id}
              interId={elem._id}
              elevation={null}
              title=''
              comments={fromJS(elem?.comments?.filter(e => e.type === C_SAV)
                .reverse().slice(0, 4).reverse())}
              hideDelete={!permit(user, { key: 'deleteComments' })}
              type={C_SAV}
            />
          </Grid>}
          <Grid item xs={12} md={4}>
            {elem.date &&
              elem.login && [
              <Typography key={5} variant="h6" className={classes.title}>
                  Historique
              </Typography>,
              <div key={7} className={classes.timeLineLimit}>
                {histories.map((data, i) => (
                  <Timeline
                    key={i}
                    align="alternate"
                    className={classes.timeLine}
                  >
                    <InterMenuInfo {...data} getDevis={this.getDevis} />
                  </Timeline>
                ))}
              </div>
            ]}
          </Grid>
          <CallTo elem={elem} />
          {!!appels.length && (
            <Grid item xs={12} md={4}>
              <Typography variant="h6" className={classes.title}>
                Historique d'appels
              </Typography>
              {appels.filter(e => e.userId === elem.login.sstRappelAchat)
                .map((e) => (
                  <React.Fragment>
                    <Typography
                      key={e._id}
                      variant="body1"
                      className={classes.lineHeight}
                    >
                      {`${moment(e.date).format('LLL')} : ${
                        e.companyName
                      } a été contacté par ${findUserLogin(e.userId)}`}
                    </Typography>
                    <AudioPlayer url={e.url} />
                  </React.Fragment>
                ))}
            </Grid>
          )}
          {!elem.signalAchat && <Grid item xs={12} md={4}>
            <Typography variant="h6" className={classes.title}>
                Informations Fournitures
            </Typography>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  {[
                    'Fournisseurs',
                    'N° Facture',
                    'Montant avoir attendu',
                    'Status'
                  ].map(title => <TableCell
                    key={title}
                    align="center"
                    children={title}
                  />)
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {warranty.map(e => (
                  <TableRow>
                    <TableCell key={'supplier'} align="center"
                      children={e.pro || 'EDISON'}
                    />
                    <TableCell key={'billNumber'} align="center"
                      children={(e.billNumber).toUpperCase()}
                    />
                    <TableCell key={'price'} align="center"
                      children={Money.toString(e.price, '€')}
                    />
                    <TableCell align="center">
                      {data.find(s => s._id === e.status)?.name}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>}
        </Grid>
      </React.Fragment>
    );
  }
}

export default compose(
  withRouter,
  menuContainer,
  withStyles(styles)
)(AchatMenu);
