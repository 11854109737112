import React from 'react';
import PropTypes from 'prop-types';

import { Grid, MenuItem } from '@material-ui/core';

import api from '../../api';
import CustomTextField from './CustomTextField';
import AutoSuggestInput from '../Utils/AutoSuggestInput';

const getSuggestions = async value => {
  if (value
    && value.length > 2
    && value.length <= 6) {
    const res = await api.arcep.getAll({ tel: value });
    const suggestions = res.body().map(e => e.data());

    if (suggestions.length > 0) {
      return suggestions;
    }
  }
};

const renderSuggestion = (suggestion, { isHighlighted }) =>
  <MenuItem selected={isHighlighted} component='div'>
    <div>
      <span style={{ fontWeight: 300 }}>
        {suggestion.e} - {suggestion.t}
      </span>
    </div>
  </MenuItem>;

const CustomPhoneInput = ({
  searchPhone,
  classGrid,
  grid,
  xs,
  sm,
  md,
  lg,
  xl,
  ...props
}) => {
  const updateData = v => props.setData(props.path, v);
  const input = searchPhone
    ? <AutoSuggestInput
      renderSuggestion={renderSuggestion}
      getSuggestions={getSuggestions}
      getSuggestionValue={s => s.e}
      multiline={false}
      onChange={updateData}
      {...props}
    />
    : <CustomTextField
      multiline={false}
      {...props}
    />;

  return grid ?
    <Grid
      item
      xs={xs}
      sm={sm}
      md={md}
      lg={lg}
      xl={xl}
      className={classGrid}
      children={input}
    /> : input;
};

CustomPhoneInput.propTypes = {
  path: PropTypes.arrayOf(PropTypes.string).isRequired,
  label: PropTypes.string,
  setData: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.objectOf([
      PropTypes.string,
      PropTypes.number
    ])),
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  grid: PropTypes.bool,
  capsLocked: PropTypes.bool,
  classGrid: PropTypes.string,
};

export default CustomPhoneInput;
