import api from '../api';

import { moment } from '@bit/dev-lba.lib.local-globals/moment';
import notifSystem from '../notifSystem';

export const getAllCalls = ({ user, userId, users, endAt, startAt }) =>
  api.records
    .custom('getCallsToday')
    .get({
      startAt: startAt || moment().startOf('day').toDate(),
      endAt: endAt || moment().endOf('day').toDate(),
      ...(userId ? { userId } : {}),
      ...(user
        ? { lignes: user.multiLignes }
        : {
          lignes: users
            .toJS()
            .reduce((acc, curr) => acc.concat(curr.multiLignes || []), []),
        }),
    })
    .then((res) => res.body().data())
    .catch(() =>
      notifSystem.error('Erreur system', 'lors de la récupération des appels')
    );
