import React, { Component } from 'react';
import classnames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Delete } from '@material-ui/icons';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';

import DropboxUpload from '../DropboxUpload';
import ImageList from '../List/ImageList';
import api from '../../api';
import notifSystem from '../../notifSystem';
import {
  data as docTypes,
  DAFAC,
  DBANQ,
  DASSU,
  DINTERRIB,
} from '@bit/dev-lba.lib.local-globals/docTypes';
import { checkDocument } from '../../utils/function';
import { fromJS } from 'immutable';
import NoAssurance from '../NoAssurance';

const styles = {
  flexBox: { display: 'flex', justifyContent: 'space-between' },
  fullWidth: { width: '100%' },
  leftMargin: { marginBottom: 20 },
  icon: { margin: 10 },
};

const defaultKeys =
  docTypes.filter(
    (e) => e.type === 'document' && ![DAFAC, DBANQ, DINTERRIB].includes(e._id)
  ) || [];

const defaultState = () => ({
  deleteCounter: 0,
});

class Documents extends Component {
  state = this.props.keys.reduce((a, v) => {
    a[v.key] = [];
    a[`${v.key}Checked`] = [];
    return a;
  }, defaultState());

  componentDidMount() {
    if (this.props.dropbox) {
      this.updateState(this.props.dropbox);
    }
  }

  updateState = (data, del = false) => {
    const initKeys = del
      ? this.props.keys.reduce((b, k) => {
        b[k.key] = [];
        b[`${k.key}Checked`] = [];
        return b;
      }, {})
      : {};
    this.setState(
      data.reduce(
        (a, v) => {
          const item =
            this.props.keys.find((e) => e._id === v.get('type')) || {};
          a[item.key] = [...(a[item.key] || []), v.toJS()];
          return a;
        },
        {
          dropbox: data,
          ...initKeys,
          ...defaultState(),
        }
      )
    );
  };

  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id) {
      this.getFiles();
    }
  }

  getFiles = async () => {
    const res = await api.artisans.get(this.props.id, {
      field: JSON.stringify({ dropbox: 1 }),
    });
    const dropbox = ((res.body() || {}).data() || {}).dropbox
      ? fromJS(res.body().data().dropbox)
      : this.props.dropbox;
    if (dropbox) {
      this.updateState(dropbox, this.props.keys);
    }
  };

  updateFiles = (newFiles) => {
    api.artisans
      .patch(this.props.underscoreId, {
        dropbox: newFiles,
      })
      .then(
        (res) =>
          ((res.body() || {}).data() || {}).dropbox &&
          this.updateState(fromJS(res.body().data().dropbox), true)
      )
      .catch((e) => notifSystem.error('Erreur', e.message || e));
  };

  deleteCheckTab = () => {
    const { keys } = this.props;
    const { dropbox } = this.state;
    let newFiles = dropbox.toJS();
    keys.map((k) => {
      const checked = this.state[`${k.key}Checked`];
      if (checked.length && newFiles.length) {
        newFiles = newFiles.filter((e) => !checked.includes(e.fileName));
      }
    });
    this.updateFiles(newFiles);
  };

  handleToggle = (key) => (v) => {
    const keyChecked = `${key}Checked`;
    const { [keyChecked]: prevArr, deleteCounter } = this.state;
    const nextArr = this.state[keyChecked].toggleValue(v.name);
    this.setState({
      [keyChecked]: nextArr,
      deleteCounter: deleteCounter + (prevArr.length > nextArr.length ? 1 : -1),
    });
  };

  generateList = (k) => {
    const {
      id,
      underscoreId,
      setData,
      classes,
      supp: { noAssurance },
    } = this.props;
    const { [`${k.key}Checked`]: checked, [k.key]: files } = this.state;
    return (
      <Accordion>
        <AccordionSummary>
          <div className={classnames(classes.flexBox, classes.fullWidth)}>
            <Typography variant="body1" children={k.name} />
            <div className={classes.flexBox}>
              {k._id === DASSU && (
                <NoAssurance
                  artisan={{ noAssurance, _id: underscoreId }}
                  setData={() => setData(['noAssurance'], !noAssurance)}
                />
              )}
              {!!setData && (
                <DropboxUpload
                  id={id}
                  color={checkDocument(files, { type: k._id }, {})}
                  length={files.length + 1}
                  type={k._id}
                  underscoreId={underscoreId}
                  path="artisans"
                />
              )}
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <ImageList
            isSelected={(e) => checked.includes(e.name)}
            handleDelete={this.handleToggle(k.key)}
            id={this.props.id}
            data={files.map(({ link, fileName, status }) => ({
              status,
              url: link,
              name: fileName,
            }))}
            getFile={this.getFiles}
          />
        </AccordionDetails>
      </Accordion>
    );
  };

  render() {
    const { setData, displayTitle, classes, keys } = this.props;
    const { deleteCounter } = this.state;
    return (
      <Card className="cards">
        <CardContent>
          <div className={classnames(classes.flexBox, classes.bottomMargin)}>
            {displayTitle && <Typography variant="h5" children="Documents" />}
            {!!setData && (
              <Fab
                size="small"
                className={classes.icon}
                disabled={!deleteCounter}
                onClick={this.deleteCheckTab}
                color="secondary"
                children={<Delete />}
              />
            )}
          </div>
          {keys.map(this.generateList)}
        </CardContent>
      </Card>
    );
  }
}

Documents.defaultProps = {
  keys: defaultKeys,
  displayTitle: true,
};

export default withStyles(styles)(Documents);
