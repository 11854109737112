import React, { PureComponent } from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import company from '@bit/dev-lba.lib.local-globals/company';

import { logout } from '../../actions/auth';

const styles = theme => ({
  companyName: {
    minWidth: 172,
    marginRight: 20,
    [theme.breakpoints.down('sm')]: {
      flex: 1,
      minWidth: 50
    },
  },
  flex: {
    flex: 1,
  },
  AppBarPos: {
    position: 'relative',
    zIndex: 1,
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
  },
  headerDiv: {
    display: 'flex',
    justifyContent: 'space-between'
  }
});

export class MenuBar extends PureComponent {
  state = {};

  render() {
    const { classes, logout } = this.props;

    return (
      <AppBar position="static" className={classes.AppBarPos}>
        <Toolbar className={classes.headerDiv}>
          <Link className={classes.link} to="/dashboard">
            <Typography
              variant="h6"
              color="inherit"
              className={classes.companyName}
              children={company.name}
            />
          </Link>
          <Button
            color="inherit"
            onClick={logout}
            children="Logout"
          />
        </Toolbar>
      </AppBar>
    );
  }
}

export default compose(
  withRouter,
  withStyles(styles),
  connect(null, { logout })
)(MenuBar);
