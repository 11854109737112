import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { grey } from '@material-ui/core/colors';
import DisplayTable from '../DisplayTable';
import Chip from '@material-ui/core/Chip';
import { moment } from '@bit/dev-lba.lib.local-globals/moment';

const styles = theme => ({
  container: {
    height: 'calc(100% - 10px)',
    marginBottom: 15
  },
  paper: {
    padding: theme.spacing(1),
    overflow: 'auto',
    height: '100%'
  },
  emptyPaper: {
    height: '276px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  title: {
    margin: theme.spacing(1),
    fontSize: '1.2rem',
    color: grey[500],
  },
  titleText: {
    fontSize: '15px'
  },
  tr: {
    height: '56px'
  }
});

class Planning extends PureComponent {
state = {
  absences: 0,
  lates: 0,
  avertis: 0,
};


getDate(day) {
  const dep = moment(day.departure).format('HH[h]mm');
  const arr = moment(day.arrival).format('HH[h]mm');
  return `${arr}-${dep}`;
}

render() {
  const { SchudleStatsData, classes } = this.props;
  let dates =
      SchudleStatsData &&
      SchudleStatsData.schedule &&
      SchudleStatsData.schedule[SchudleStatsData.schedule.length - 1];
  let today = moment()
    .locale('en')
    .format('dddd')
    .toLowerCase();
  return (
    <Grid container spacing={1} direction="column"
      className={classes.container}>
      <Grid item xs>
        {dates && today ? (
          <Paper className={classes.paper}>
            <Typography
              className={classes.title}
              variant="h6"
              align="left"
            >
                  PLANNING :
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs>
                <Grid
                  container
                  direction="column"
                  justify={'center'}
                  style={{ height: '100%' }}
                >
                  <Typography
                    className={classes.titleText}
                    variant="h6"
                    align="center"
                  >
                        PAUSES :
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={10}>
                <DisplayTable
                  classes={classes}
                  headerData={['MATIN', 'MIDI', 'APRES-MIDI']}
                  bodyData={[
                    moment(
                      dates[today]
                        ? new Date(dates[today].morning)
                        : new Date()
                    ).format('HH[h]mm'),
                    moment(
                      dates[today]
                        ? new Date(dates[today].midday)
                        : new Date()
                    ).format('HH[h]mm'),
                    moment(
                      dates[today]
                        ? new Date(dates[today].afternoon)
                        : new Date()
                    ).format('HH[h]mm'),
                  ]}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs>
                <Grid
                  container
                  direction="column"
                  justify={'center'}
                  style={{ height: '100%' }}
                >
                  <Typography
                    className={classes.titleText}
                    variant="h6"
                    align="center"
                  >
                        HORAIRES :
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={10}>
                <DisplayTable
                  classes={classes}
                  headerData={[
                    'LUNDI',
                    'MARDI',
                    'MERCREDI',
                    'JEUDI',
                    'VENDREDI',
                    'SAMEDI',
                  ]}
                  bodyData={[
                    this.getDate(dates.monday),
                    this.getDate(dates.tuesday),
                    this.getDate(dates.wednesday),
                    this.getDate(dates.thursday),
                    this.getDate(dates.friday),
                    this.getDate(dates.saturday),
                  ]}
                />
              </Grid>
            </Grid>
            {dates.saturdayPresences.length ? (
              <Grid container spacing={2}>
                <Grid item xs style={{ padding: '1%' }}>
                  <Grid
                    container
                    direction="column"
                    justify={'center'}
                    style={{ height: '100%' }}
                  >
                    <Typography
                      className={classes.titleText}
                      variant="h6"
                      align="center"
                    >
                          PRESENCE SAMEDIS :
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={8} style={{ padding: '1%' }}>
                  {dates.saturdayPresences.map(e => (
                    <Chip
                      style={{ margin: '0.5%' }}
                      key={e}
                      label={e}
                    />
                  ))}
                </Grid>
              </Grid>
            ) : null}
          </Paper>
        ) : (
          <Paper className={classes.emptyPaper}>
            <Typography
              className={classes.titleText}
              variant="h6"
              align="center"
            >
                  Le PLANNING n'est pas disponible
            </Typography>
          </Paper>
        )}
      </Grid>
    </Grid>
  );
}
}

Planning.propTypes = {
  classes: PropTypes.object.isRequired,
  SchudleStatsData: PropTypes.object.isRequired,
};

export default withStyles(styles)(Planning);
