import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles, withTheme } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import { NavigateBefore, NavigateNext } from '@material-ui/icons';
import { fromJS, List } from 'immutable';
import styles from './styles';
import classNames from 'classnames';
import { compose } from 'recompose';
import { S_INT, S_LEAD } from '@bit/dev-lba.lib.local-globals/services';
import { urlsCounter } from '../utils';


const mapStateToProps = (state, props) => ({
  counter: state.counter,
  users: state.users,
  userId: state.userId,
  teams: state.equipes,
  points: state.points,
  service: props.service
});

const genChip = (elem, chipCss) =>
  elem && <Chip label={elem} className={chipCss} />;

const Intervs = ({ classes, color, e, counter,
  url, path, linkTo = 'intervention' }) => (
  <Avatar style={{ backgroundColor: color }} className={classes.avatar}>
    <Link
      className={classes.link}
      to={`/${linkTo}/list/${url}/login-${path}=${e.login}=${e._id}`}
    >
      {counter}
    </Link>
  </Avatar>
);

const calculsPoints = (objStats = {}) =>
  Object.keys(objStats).reduce(
    (e, v) =>
      e +
      (!isNaN(objStats[v]) && !['totalDifference'].includes(v)
        ? objStats[v]
        : 0),
    0
  );

const services = {
  [S_LEAD]: [S_LEAD, S_INT],
  [S_INT]: [S_LEAD, S_INT],
};

class StatsTelepro extends Component {
  state = {
    indexStats: 0,
    checked: true,
    users: this.props.users.filter(
      (e) =>
        e.act &&
        (services[this.props.service] || [this.props.service]).includes(
          e.service
        )
    ),
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.counter && nextProps.users) {
      const user = nextProps.users.find((e) => e._id === nextProps.userId);
      if (user) {
        const redux = Object.assign({}, this.state);
        this.setState({
          redux,
        });
      }
    }
  }

  getUserCount(login, counter) {
    const ajd = counter[`interventions.counAjd.${login}`] || 0;
    const counVrf = counter[`interventions.counVrf.${login}`] || 0;
    const counAprogAjd = counter[`interventions.counAprogAjd.${login}`] || 0;
    const counEnvAjd = counter[`interventions.counEnvAjd.${login}`] || 0;
    const counAnnAjd = counter[`interventions.counAnnAjd.${login}`] || 0;
    const counAvr = counter[`interventions.counAvr.${login}`] || 0;

    const savEnc = counter[`savInterventions.savEnc.${login}`] || 0;
    const savApr = counter[`savInterventions.savApr.${login}`] || 0;
    const savAref = counter[`savInterventions.savAref.${login}`] || 0;
    const savAvr = counter[`savInterventions.savAvr.${login}`] || 0;
    const savSuivi = counter[`savInterventions.savSuivi.${login}`] || 0;

    return {
      ajd,
      counVrf,
      counAprogAjd,
      counEnvAjd,
      counAnnAjd,
      counAvr,
      savEnc,
      savApr,
      savAref,
      savAvr,
      savSuivi,
    };
  }

  getOrder(users) {
    const { counter, points } = this.props;
    users.forEach((e) => {
      e.objStats = this.getUserCount(e.login, counter);
      e.points = calculsPoints(points.find((p) => p._id === e._id));
    });
    return users.sort((a, b) => b.points - a.points);
  }

  handleBefore = () =>
    this.setState((prev) => ({ indexStats: prev.indexStats - 1 }));
  handleNext = () =>
    this.setState((prev) => ({ indexStats: prev.indexStats + 1 }));

  handleUser = (perso, index) => {
    const { classes, service } = this.props;
    return (
      <div key={index} className={classes.tabs}>
        <div className={classes.row}>
          <Tooltip title={perso.alias && perso.alias.toUpperCase()}>
            <span className={classes.login}>
              {perso.alias && perso.alias.toUpperCase()}
            </span>
          </Tooltip>
          {[S_LEAD, S_INT].includes(service) && (
            <div className={classes.divPoints}>
              <span className={classes.points}>
                <Link
                  className={classes.link}
                  to={`/intervention/list/ajd/login-ajout=${
                    perso.login}=${perso._id}`}
                >
                  {genChip(`${Math.round(perso.points)} Pts`, classes.genChip)}
                </Link>
              </span>
            </div>
          )}
        </div>
        <div className={classes.row}>
          {urlsCounter[service].map((item, key) => (
            <Intervs
              key={key}
              classes={classes}
              color={item.color}
              e={perso}
              counter={perso.objStats[item.url]}
              url={item.url}
              path={item.path}
              linkTo={item.linkTo}
            />
          ))}
        </div>
      </div>
    );
  };

  render() {
    const { classes, userId } = this.props;
    const { indexStats } = this.state;
    let users = this.state.users;
    const user = users.find((e) => e._id === userId);
    if (!users || !user) {
      return null;
    }
    users = users && this.getOrder(List.isList(users) ? users : fromJS(users));
    return (
      <div className={classes.listName}>
        <NavigateBefore
          className={classNames(classes.button, classes.arrowLeft)}
          onClick={() => indexStats >= 1 && this.handleBefore()}
        />
        <div className={classes.list}>
          {new Array(6)
            .fill(0)
            .map((e, i) =>
              this.handleUser(
                users.get((Math.abs(indexStats) + i) % users.size),
                i
              )
            )}
        </div>
        <NavigateNext
          className={classNames(classes.button, classes.arrowRight)}
          onClick={() => users.size > indexStats && this.handleNext()}
        />
      </div>
    );
  }
}

StatsTelepro.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default compose(
  withTheme,
  withStyles(styles),
  connect(mapStateToProps),
)(StatsTelepro);
