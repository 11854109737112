import React from 'react';

import { Button, Grid } from '@material-ui/core';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { closeDialog } from '../../actions/dialog';
import { withStyles } from '@material-ui/core/styles';

const mapStateToProps = ({
  dialog: {
    contentProps: {
      buttons = [],
      callback
    },
  },
}) => ({
  buttons,
  callback
});

const mapDispatchToProps = {
  closeDialog: closeDialog
};

const mergeProps = (state, dispatchers, ownProps) => ({
  ...state,
  ...dispatchers,
  ...ownProps,
  callback: (id) => {
    state.callback(id);
    dispatchers.closeDialog();
  }
});

const styles = {
  gridItem: {
    display: 'flex',
    flexGrow: 1,
    minHeight: 105,
    borderColor: 'red',
    borderWidth: 2
  }
};

const DialogCallBackButton = ({ buttons, callback, classes }) =>
  <Grid container spacing={3} justify="center">
    {buttons.map((e, i) =>
      <Grid item sm={4} key={i} className={classes.gridItem}>
        <Button
          fullWidth
          color="primary"
          style={e.color ? { backgroundColor: e.color[500] } : {}}
          variant="contained"
          children={e.name}
          onClick={() => callback(e._id)}/>
      </Grid>
    )}
  </Grid>;

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps)
)(DialogCallBackButton);
