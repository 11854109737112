import React, { PureComponent } from 'react';
import { Paper } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import GSMCard from './card';
import SMSTable from './table/index';
import * as action from './actions';
import notifSystem from '../../../notifSystem';
import ws from '../../../ws';
import { SMSType } from '@bit/dev-lba.lib.local-globals/smsType';
import api from '../../../api';
import {
  PENDING, FAILED, SEND
} from '@bit/dev-lba.lib.local-globals/smsStatus';
import { moment } from '@bit/dev-lba.lib.local-globals/moment';

class SmsManagement extends PureComponent {
  state = {
    expanded: -1,
    types: this.props.dashboard ? [0] : [0, 1, 2, 3],
    page: 0,
    rowsPerPage: this.props.limit || 25,
    list: [],
    sms: []
  };

  componentDidMount() {
    action.getGSM(
      (response) => this.setState({ list: response }),
      () => notifSystem.error('Erreur', 'impossible de recuperer les GSM')
    );

    this.getAllSMS();

    ws.on('update_sms', this.wsUpdate);
  }

  componentWillUnmount() {
    ws.removeEventListener('update_sms', this.wsUpdate);
  }

  wsUpdate = (data) => {
    const { sms, types, list } = this.state;
    if (data) {
      data.status = SEND;
      const { sms: newSms, list: newList } = action.updateCurrentData(
        data,
        list,
        sms,
        types
      );
      this.setState({
        sms: [...newSms],
        list: [...newList],
      });
    }
  };

  getAllSMS = () => {
    const { types, rowsPerPage, page } = this.state;
    const received = types.includes(0);
    const send = types.includes(1);
    const pending = types.includes(2);
    const failed = types.includes(3);

    let conditions = [];
    if (received) {
      conditions.push({
        type: { $in: [SMSType.MMSIN, SMSType.SMSIN] },
        status: SEND
      });
    }
    if (send) {
      conditions.push({ type: SMSType.SMSOUT, status: SEND });
    }
    if (pending) {
      conditions.push({ type: SMSType.SMSOUT, status: PENDING });
    }
    if (failed) {
      conditions.push({ type: SMSType.SMSOUT, status: FAILED });
    }
    if (types.length) {
      return Promise.all([
        api.sms
          .getAll({
            query: JSON.stringify({
              $or: conditions,
              date: {
                $gt: moment().add(-7, 'day').startOf('day').toDate(),
              },
            }),
            display: rowsPerPage,
            sort: { _id: -1 },
            page: page,
          })
          .then((res) => res.body().map((e) => e.data())),

        api.sms
          .getAll({
            query: JSON.stringify({
              $or: conditions,
              date: {
                $gt: moment().add(-7, 'day').startOf('day').toDate(),
              },
            }),
            call: 'countDocuments',
          })
          .then((res) => res.body().data()),
      ])
        .then(([sms, size]) => {
          this.setState({ sms, size });
        })
        .catch(() =>
          notifSystem.error('Erreur', 'impossible de recuperer les SMS')
        );
    }
  };

  handleChangePage = (event, page) => {
    this.setState({ page }, this.getAllSMS);
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value }, this.getAllSMS);
  };
  selectTypes = (index) => {
    let types = [...this.state.types];

    if (types.includes(index)) {
      types = types.filter((i) => i !== index);
    } else {
      types.push(index);
    }

    this.setState({ types }, this.getAllSMS);
  };

  render() {
    const { classes, dashboard } = this.props;
    const { types, list, sms, rowsPerPage, page, size } = this.state;

    return (
      <Paper>
        {!dashboard && (
          <div className={classes.root}>
            {list &&
              list
                .sort((s) => s.number)
                .map((card, i) => (
                  <GSMCard
                    key={i}
                    classes={classes}
                    card={card}
                    values={[
                      card.received,
                      card.send,
                      card.pending,
                      card.failed,
                    ]}
                  />
                ))}
          </div>
        )}
        <SMSTable
          dashboard={dashboard}
          types={types}
          selectTypes={this.selectTypes}
          sms={sms}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
          rowsPerPage={rowsPerPage}
          page={page}
          size={size}
        />
      </Paper>
    );
  }
}

export default withStyles(styles)(SmsManagement);
