import React from 'react';
import CustomTextField from '../../CustomInputs/CustomTextField';
import { Grid, Hidden } from '@material-ui/core';
import { C_PAC } from '@bit/dev-lba.lib.local-globals/comboType';

const paths = {
  comboName: ['combination', 'name'],
  comboModel: ['combination', 'model'],
  comboPower: ['combination', 'power'],
  comboFjs: ['combination', 'fjs'],
  comboClassR: ['combination', 'classRegul'],
  temperature: ['combination', 'temperature'],
  thermoBrand: ['combination', 'thermostat', 'brand'],
  thermoModel: ['combination', 'thermostat', 'model'],
};

const InfoCombo = ({ merge, setData }) =>
  <>
  <CustomTextField
    label='Nom chaudière'
    path={paths.comboName}
    value={merge.getIn(paths.comboName)}
    setData={setData}
    grid
    xs={12}
    sm={6}
  />
  <CustomTextField
    label='Model chaudière'
    texttransform='none'
    path={paths.comboModel}
    value={merge.getIn(paths.comboModel)}
    setData={setData}
    grid
    xs={12}
    sm={6}
  />
  <CustomTextField
    label='Classe de régulation'
    texttransform='none'
    path={paths.comboClassR}
    value={merge.getIn(paths.comboClassR)}
    setData={setData}
    grid
    xs={12}
    sm={6}
  />
  <CustomTextField
    label='Puissance chaudière'
    texttransform='none'
    path={paths.comboPower}
    value={merge.getIn(paths.comboPower)}
    setData={setData}
    grid
    xs={12}
    sm={6}
    multiline={false}
    placeholder="Puissance kW..."
    fullWidth
    type="number"
  />
  <CustomTextField
    label='Etas fjs'
    texttransform='none'
    path={paths.comboFjs}
    value={merge.getIn(paths.comboFjs)}
    setData={setData}
    grid
    xs={12}
    sm={6}
    key={6}
    multiline={false}
    placeholder="Etas fjs..."
    fullWidth
    type="number"
  />
  {merge.getIn(['combination', 'comboType']) === C_PAC ? <CustomTextField
    label='Température'
    texttransform='none'
    path={paths.temperature}
    value={merge.getIn(paths.temperature)}
    setData={setData}
    grid
    xs={12}
    sm={6}
    multiline={false}
  /> :
    <Hidden xsDown>
      <Grid item sm={6} children='&nbsp;' />
    </Hidden>}
  <CustomTextField
    label='Marque du thermostat'
    texttransform='none'
    path={paths.thermoBrand}
    value={merge.getIn(paths.thermoBrand)}
    setData={setData}
    grid
    xs={12}
    sm={6}
    multiline={false}
  />
  <CustomTextField
    label='Modele thermostat'
    texttransform='none'
    path={paths.thermoModel}
    value={merge.getIn(paths.thermoModel)}
    setData={setData}
    grid
    xs={12}
    sm={6}
    multiline={false}
  />
</>;

export default InfoCombo;
