// @flow
// @inheritedComponent Transition

import React from 'react';
import classNames from 'classnames';
import Transition from 'react-transition-group/Transition';
import { withStyles } from '@material-ui/core/styles';
import { duration } from '@material-ui/core/styles/transitions';

export const styles = (theme) => ({
  container: {
    height: 0,
    overflow: 'hidden',
    transition: theme.transitions.create('height'),
  },
  entered: {
    height: 'auto',
  },
  borderBot: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.075)'
  }
});

const reflow = node => node.scrollTop;

class TableCollapse extends React.Component {
  static defaultProps = {
    appear: false,
    collapsedHeight: '0px',
    transitionDuration: duration.standard,
  };

  constructor(props) {
    super(props);
    this.handleEnter = this.handleEnter.bind(this);
    this.handleEntering = this.handleEntering.bind(this);
    this.handleEntered = this.handleEntered.bind(this);
    this.handleExit = this.handleExit.bind(this);
    this.handleExiting = this.handleExiting.bind(this);
    this.addEndListener = this.addEndListener.bind(this);
  }

  wrapper = null;
  autoTransitionDuration = undefined;

  handleEnter(node) {
    node.style.height = this.props.collapsedHeight;

    if (this.props.onEnter) {
      this.props.onEnter(node);
    }
  }

  handleEntering(node) {
    const { transitionDuration, theme } = this.props;
    const wrapperHeight = this.wrapper ? this.wrapper.clientHeight : 0;

    if (transitionDuration === 'auto') {
      const duration2 = theme.transitions.getAutoHeightDuration(wrapperHeight);
      node.style.transitionDuration = `${duration2}ms`;
      this.autoTransitionDuration = duration2;
    } else if (typeof transitionDuration === 'number') {
      node.style.transitionDuration = `${transitionDuration}ms`;
    } else if (transitionDuration) {
      node.style.transitionDuration = `${transitionDuration.enter}ms`;
    } else {
      // The propType will warn in this case.
    }

    node.style.height = `${wrapperHeight}px`;

    if (this.props.onEntering) {
      this.props.onEntering(node);
    }
  }

  handleEntered(node) {
    node.style.height = 'auto';

    if (this.props.onEntered) {
      this.props.onEntered(node);
    }
  }

  handleExit(node) {
    const wrapperHeight = this.wrapper ? this.wrapper.clientHeight : 0;
    reflow(node);
    node.style.height = `${wrapperHeight}px`;
    reflow(node);

    if (this.props.onExit) {
      this.props.onExit(node);
    }
  }

  handleExiting(node) {
    const { transitionDuration, theme } = this.props;
    const wrapperHeight = this.wrapper ? this.wrapper.clientHeight : 0;

    reflow(node);

    if (transitionDuration === 'auto') {
      const duration2 = theme.transitions.getAutoHeightDuration(wrapperHeight);
      node.style.transitionDuration = `${duration2}ms`;
      this.autoTransitionDuration = duration2;
    } else if (typeof transitionDuration === 'number') {
      node.style.transitionDuration = `${transitionDuration}ms`;
    } else if (transitionDuration) {
      node.style.transitionDuration = `${transitionDuration.exit}ms`;
    } else {
      // The propType will warn in this case.
    }

    reflow(node);

    node.style.height = this.props.collapsedHeight;

    reflow(node);

    if (this.props.onExiting) {
      this.props.onExiting(node);
    }
  }

  addEndListener(node, next) {
    let timeout;

    if (this.props.transitionDuration === 'auto') {
      timeout = this.autoTransitionDuration || 0;
    } else {
      timeout = this.props.transitionDuration;
    }

    setTimeout(next, timeout);
  }

  render() {
    const {
      appear,
      children,
      classes,
      collapsedHeight,
      style,
      colSpan,
      tableSpan,
      ...other
    } = this.props;

    const maxSpan = (tableSpan) ? tableSpan - colSpan : 0;
    return (
      <Transition
        appear={appear}
        onEntering={this.handleEntering}
        onEnter={this.handleEnter}
        onEntered={this.handleEntered}
        onExiting={this.handleExiting}
        onExit={this.handleExit}
        addEndListener={this.addEndListener}
        style={{ minHeight: collapsedHeight, ...style }}
        {...other}
      >
        {state => (
          <tr>
            <td
              key={0}
              colSpan={colSpan}
              className={classes.borderBot}
            >
              <div
                className={classNames(classes.container, {
                  [classes.entered]: state === 'entered',
                })}
              >
                <div ref={node => { this.wrapper = node; }} >
                  {children}
                </div>
              </div>
            </td>
            {maxSpan > 0 &&
            <td key={2} colSpan={maxSpan} className={classes.borderBot} />}
          </tr>
        )}
      </Transition>
    );
  }
}

export default withStyles(styles, {
  withTheme: true,
  name: 'MuiCollapse',
})(TableCollapse);
