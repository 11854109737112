import React from 'react';

import {
  ADMIN, NOT_CONFIRMED
} from '@bit/dev-lba.lib.local-globals/permissions';
import { S_RH } from '@bit/dev-lba.lib.local-globals/services';
import componentLoader from '../../componentLoader';

const InterventionService = React.lazy(() =>
  componentLoader(() =>
    import('../../views/Cti/Components/InterventionService'))
);
const StatsAgents = React.lazy(() =>
  componentLoader(() => import('../../views/Cti/StatsAgents'))
);
const StatsServices = React.lazy(() =>
  componentLoader(() => import('../../views/Cti/StatsServices'))
);
const SearchNumber = React.lazy(() =>
  componentLoader(() => import('../../views/Cti/Search'))
);
const MapCalls = React.lazy(() =>
  componentLoader(() => import('../../views/Cti/MapCalls'))
);
const UpdateOVH = React.lazy(() =>
  componentLoader(() => import('../../views/Cti/UpdateOVH'))
);
const ListTransfromCall = React.lazy(() =>
  componentLoader(() => import('../../views/Cti/ListTransfromCall'))
);
const ListUnansweredCalls = React.lazy(() =>
  componentLoader(() => import('../../views/Cti/ListUnansweredCalls'))
);
export default [
  {
    path: '/cti/interService',
    component: InterventionService,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/cti/statsAgent',
    component: StatsAgents,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/cti/statsService',
    component: StatsServices,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/cti/mapCalls',
    component: MapCalls,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/cti/search',
    component: SearchNumber,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/cti/updateOVH',
    component: UpdateOVH,
    restrictions: () => ADMIN,
  },
  {
    path: '/cti/transformCall',
    component: ListTransfromCall,
    restrictions: ({ service }) =>
      ({
        [S_RH]: NOT_CONFIRMED,
      }[service] || ADMIN),
  },
  {
    path: '/cti/unansweredCalls',
    component: ListUnansweredCalls,
    restrictions: () => ADMIN
  },
];
