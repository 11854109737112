import React from 'react';

export default ({
  comment,
  index,
  downloadDisplay,
  classes,
  editingComment,
  editComment,
  updateEditingComment
}) => {
  if (editingComment === index) {
    return (
      <p
        onMouseLeave={() => updateEditingComment(-1)}
        className={classes.sheetLeft10}
      >
        <li>
          <input
            className={classes.input}
            onChange={event => editComment(event.target.value, index)}
            value={comment}
          />
        </li>
      </p>
    );
  } else if (!downloadDisplay || (downloadDisplay && comment)) {
    return (
      <p
        onMouseEnter={() => updateEditingComment(index)}
        className={classes.sheetLeft10}
      >
        <li>
          {comment || (
            <i className={classes.comment}>Ajouter un commentaire</i>
          )}
        </li>
      </p>
    );
  }

  return null;
};
