import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';

import Dialogs from '../Dialogs';
import ValidationFacture from '../../Reglement/ValidationFacture';
import Encaissement from '../../Reglement/Encaissement';
import Decaissement from '../../Reglement/Decaissement';
import {
  checkDecaissement,
  checkInfo,
  regDefault,
  lineDefault,
  decaissementDefault,
} from '../../Reglement/functions';
import PrintFiles from '../../PrintFiles';
import Supplies from '../../Supplies';
import Comments from '../../Comments';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { Map, List, fromJS } from 'immutable';
import api from '../../../api';
import store from '../../../store';
import notifSystem from '../../../notifSystem';
import CircularProgress from '@material-ui/core/CircularProgress';
import { DINTER } from '@bit/dev-lba.lib.local-globals/docTypes';
import { permit } from '@bit/dev-lba.lib.local-globals/restrictions';
import { deleteCaissement } from '../../../actions/encaissement';
import BillingInformation from '../../BillingInformation';
import CustomSwitch from '../../CustomInputs/CustomSwitch';

const mapStateToProps = state => ({
  userId: state.userId,
  users: state.users,
});

const styles = {
  divLoading: {
    height: 500,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

class ReglementDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: new Map(),
      reglement: new Map(),
      encaissements: new List(),
      decaissements: new List(),
      original: new List(),
      currentArtisan: new Map(),
      save: false,
      update: false,
      loading: false,
      billingEnable: false,
      mailRefund: false,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { data, artisan } = this.props.defaultText || this.props.data || {};
    if (data && nextProps.defaultText.data !== data) {
      const { data } = nextProps;
      this.setContent(data, artisan);
      return true;
    }
    return (
      this.props.open !== nextProps.open ||
      this.state !== nextState ||
      this.props.defaultText.data !== nextProps.defaultText.data ||
      this.props.callback !== nextProps.callback
    );
  }

  componentDidMount() {
    const { defaultText: { data, artisan } } = this.props;
    this.setContent(data, artisan);
  }

  componentDidUpdate() {
    if (this.state.save === true) {
      this.props.callback(true);
    }
  }

  saveContent = async () => {
    try {
      const {
        encaissements,
        decaissements,
        baseReglement,
        update,
        reglement,
        data,
        mailRefund,
        billingEnable
      } = this.state;
      if (
        (encaissements.size && !checkInfo(encaissements)) ||
        (decaissements.size && !checkDecaissement(decaissements))
      ) {
        notifSystem.error('Erreur', 'Les informations sont incorrectes');
        this.setState({ loading: false });
        return false;
      }
      const grandCompteId = data.getIn(['billing', 'grandCompteId']);
      let billingInter = fromJS(this.props.defaultText.data)
        .get('billing').set('billingEnable', billingEnable);
      await this.props.callback({
        encaissements: encaissements.concat(decaissements),
        data: billingEnable ?
          data : data.set('billing', billingInter),
        reglement: reglement.set('grandCompteId', grandCompteId),
        update,
        baseReglement,
        userId: this.props.userId,
        mailRefund,
      });
      this.setState({ loading: false });
      return true;
    } catch (error) {
      this.setState({ loading: false });
      notifSystem.error(
        'Erreur',
        'Les informations n\'ont pas pu être enregistrées'
      );
      return false;
    }
  };

  handleBillingSwitch = (path, value) => {
    this.setState({ billingEnable: value });
    if (!value) {
      this.setInter(['billing'], fromJS({}));
    } else {
      this.setInter(path, value);
    }
  }

  setContent = async (data, artisan) => {
    try {
      const [reg, content] = await Promise.all([
        api.reglements.get(data.id),
        api.encaissements.getAll({
          query: JSON.stringify({
            $or: [{ id: data.id }, { multis: { $elemMatch: { id: data.id } } }],
          }),
        }).then(e => e.body().map(e => e.data())),
      ]);
      let obj = reg.body().data().reglements;
      if (!obj.length) {
        obj.push(regDefault(
          data.id, data.products.tva, data.artisan, data.date.ajout));
      }
      if (!content.length) {
        content.push(
          lineDefault({
            tva: obj[obj.length - 1].tva,
            id: data.id,
            multis: [],
          })
        );
      }
      let contentBilling = content.find(
        (e) =>
          e.type === 1 &&
          e.billing &&
          (e.billing.name ||
            e.billing.firstname ||
            e.billing.email1 ||
            e.billing.tel1) &&
          e.billing.billingEnable
      );

      this.setState({
        reglement: new Map(obj[obj.length - 1]),
        encaissements: new List(content.filter(e => e.type === 2)),
        decaissements: new List(content.filter(e => e.type === 1)),
        original: new List(content),
        data: contentBilling ?
          fromJS(data).set('billing', fromJS(contentBilling.billing))
          : fromJS(data),
        baseReglement: obj[obj.length - 1],
        update: !!reg.body().data().reglements.length,
        currentArtisan: fromJS(artisan),
        billingEnable: (content[0] &&
        content[0].billing &&
        content[0].billing.billingEnable) || false
      });
    } catch (error) {
      throw error;
    }
  };

  onValidate = () => this.setState({ loading: true }, this.saveContent);

  setData = (path, data) => {
    this.setState({
      [path]: data,
      ...(path === 'reglement' ? { update: true } : {}),
    });
  };

  onReject = () => {
    if (typeof this.props.callback === 'function') {
      this.props.callback(false);
    }
    this.setState({ open: true });
  };

  setInter = (path, value) => {
    this.setState((prevState) => {
      path = path instanceof Array ? path : [path];
      const data = prevState.data.setIn(path, value);
      return {
        data
      };
    });
  };

  render() {
    const dialogProps = {
      ...this.props,
      callback: undefined,
    };
    const {
      data,
      reglement,
      encaissements,
      decaissements,
      loading,
      currentArtisan,
      billingEnable,
      mailRefund,
    } = this.state;
    const { users, userId } = this.props;
    const user = users.find(e => e._id === userId);
    const justDisplay = !permit(user, { key: 'menuReglementClient' });
    return (
      <Dialogs
        {...dialogProps}
        closeDisagreeListener={this.onReject}
        closeAgreeListener={!justDisplay && this.onValidate}
        maxWidth={'lg'}
        loading={loading}
        fullWidth
      >
        {loading ? (
          <div style={styles.divLoading}>
            <CircularProgress size={100} />
            <Typography type="title">Envoi en cours ...</Typography>
          </div>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ValidationFacture
                data={data}
                reglement={reglement}
                setData={this.setData}
                justDisplay={justDisplay}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography type="subheading">ENCAISSEMENTS</Typography>
              <Encaissement
                data={data.toJS()}
                tva={reglement.get('tva')}
                line={lineDefault}
                decaissementLine={decaissementDefault}
                setData={this.setData}
                encaissements={encaissements}
                decaissements={decaissements}
                deleteCaissement={deleteCaissement}
                justDisplay={justDisplay}
              />
              {(permit(user, { key: 'toggleDecaissement' }) ||
                (!!encaissements.size && encaissements.some((e) => e._id))) &&
                <Decaissement
                  data={data.toJS()}
                  tva={reglement.get('tva')}
                  line={decaissementDefault}
                  setData={this.setData}
                  decaissements={decaissements}
                  justDisplay={justDisplay}
                  deleteCaissement={deleteCaissement}
                />
              }
            </Grid>
            {!!decaissements.size && decaissements.some((e) => !e._id) && (
              <CustomSwitch
                grid
                xs={12}
                sm={12}
                lg={12}
                label="Mail remboursement client"
                checked={mailRefund}
                path={['mailRefund']}
                setData={(p, v) => this.setData(p[0], v)}
              />
            )}
            {!justDisplay && [
              <Grid item xs={12} sm={12} lg={12}>
                <Supplies
                  merge={fromJS(data)}
                  setData={this.setInter}
                  tva={currentArtisan ? currentArtisan.get('tva') : 10}
                  value={fromJS(data.get('supplies'))}
                  user={store
                    .getState()
                    .users.find((e) => e._id === store.getState().userId)}
                />
              </Grid>,
              <CustomSwitch
                grid
                xs={12}
                sm={12}
                lg={12}
                label="Coordonnées remboursement non identiques au client"
                checked={billingEnable}
                path={['billingEnable']}
                setData={(p, v) =>
                  this.handleBillingSwitch(['billing', ...p], v)}
              />,
              billingEnable && (
                <Grid item xs={12} sm={12} lg={12}>
                  <BillingInformation
                    client={data.get('client')}
                    data={data.get('billing')}
                    remindFacture={data.get('remindFacture', false)}
                    dropboxFacture={data.get('dropboxFacture', false)}
                    parentSetData={this.setInter}
                    setData={(p, v) => this.setInter(['billing', ...p], v)}
                  />
                </Grid>
              ),
              <Grid item xs={12} sm={6}>
                <Comments
                  users={users}
                  userId={userId}
                  id={data.get('interId', data.get('id'))}
                  interId={data.get('interId', data.get('_id'))}
                  comments={fromJS(data.get('comments'))}
                  setData={this.setInter}
                />
              </Grid>,
              <Grid item xs={12} sm={6} lg={6}>
                {data.get('id') && (
                  <PrintFiles
                    type={DINTER}
                    path={isNaN(data.get('id'))
                      ? 'savInterventions'
                      : 'interventions'}
                    id={data.get('id')}
                    underscoreId={data.get('_id')}
                    setData={this.setInter}
                    dropbox={fromJS(data.get('dropbox'))}
                  />
                )}
              </Grid>,
            ]}
          </Grid>
        )}
      </Dialogs>
    );
  }
}

ReglementDialog.propTypes = {
  callback: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(ReglementDialog);
