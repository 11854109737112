import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import { moment } from '@bit/dev-lba.lib.local-globals/moment';
import { getLoginFromPhone } from '../../../../utils/function';
import { styles } from '../../utils';
import api from '../../../../api';
import notifSystem from '../../../../notifSystem';
import AudioPlayer from '../../../../components/Calls/AudioPlayer';

const directions = {
  'outbound': 'Sortant',
  'inbound': 'Entrant'
};

class SearchNumber extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      calls: [],
      page: 0,
      rowsPerPage: 20,
    };

    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
  }

  componentDidMount() {
    api.records
      .custom('search')
      .get({ number: this.props.number })
      .then((result) => {
        const calls = result
          .body()
          .data()
          .calls.sort(
            (a, b) =>
              new Date(b.record.callStart) - new Date(a.record.callStart)
          );
        this.setState({ calls });
      })
      .catch(() =>
        notifSystem.error(
          'Erreur',
          'Impossible de récupérer les données concernant ce numéro'
        )
      );
  }

  handleChangePage(event, page) {
    this.setState({ page });
  }

  handleChangeRowsPerPage(event) {
    this.setState({ rowsPerPage: event.target.value });
  }

  render() {
    const { closeDisagreeListener, open, classes } = this.props;
    const { calls, rowsPerPage, page } = this.state;
    const rows = calls.slice(
      page * rowsPerPage,
      (page * rowsPerPage) + rowsPerPage
    );
    return (
      <Dialog
        onEscapeKeyDown={closeDisagreeListener}
        disableBackdropClick
        maxWidth={false}
        open={open}
      >
        <DialogContent>
          <Table>
            <TableHead>
              <TableRow>
                {['Date', 'Agent', 'Service', 'Direction', 'Audio'].map(
                  (title) => (
                    <TableCell key={title}>{title}</TableCell>
                  )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length ? (
                rows.map((call) => (
                  <TableRow>
                    <TableCell key="callStart">
                      {moment(call.record.callStart).format('L[ à ]HH[h]mm')}
                    </TableCell>
                    <TableCell key="agent">
                      {getLoginFromPhone(
                        call.record.agent.replace(/^0033/, '0')
                      )}
                    </TableCell>
                    <TableCell key="group">{call.service}</TableCell>
                    <TableCell key="group">
                      {directions[call.record.direction] || 'Entrant'}
                    </TableCell>
                    <TableCell key="urlAudio" className={classes.audioDialog}>
                      <AudioPlayer noPadding={true} url={call.record.fileUrl} />
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={3} key="not found">
                    Aucun enregistrements correspondant
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[20, 50, 100]}
                  colSpan={3}
                  count={calls.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDisagreeListener} color="primary">
            Fermer
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(SearchNumber);
