import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import { Close } from '@material-ui/icons';
import Typography from '@material-ui/core/Typography';
import links from '../../../../constants/links';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing * 2,
    position: 'relative'
  },
  closeButton: {
    position: 'absolute',
    right: 10,
    top: 10,
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      <IconButton className={classes.closeButton} onClick={onClose}>
        <Close />
      </IconButton>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing * 2,
  },
}))(MuiDialogContent);

export default function ImagePreview({ open, handleClose, _id, index }) {
  return (
    <Dialog maxWidth={'md'} fullWidth onClose={handleClose} open={open}>
      <DialogTitle onClose={handleClose} />
      <DialogContent dividers>
        <img
          src={
            `${links.origin}/api/imageFromMms?mmsId=${_id}&index=${index}`
          }
          alt={'preview'}
        />
      </DialogContent>
    </Dialog>
  );
}
