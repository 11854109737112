import React, { Component } from 'react';
import {
  Card,
  Typography,
  CardContent,
  LinearProgress,
  Button, Grid
} from '@material-ui/core';
import { grey, red, blue, } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import api from '../../../api';
import Dropdown from '../common/dropdown/dropdown';
import {
  S_INT,
  S_DEVIS,
  S_LEAD,
} from '@bit/dev-lba.lib.local-globals/services';
import { permit } from '@bit/dev-lba.lib.local-globals/restrictions';
import notifSystem from '../../../notifSystem';
import { getEquipes } from '../../../utils/function';
import { CHIEF } from '@bit/dev-lba.lib.local-globals/permissions';
import classNames from 'classnames';

const styles = {
  linearProgress: {
    margin: 7,
    height: 25,
    backgroundColor: grey[200],
  },
  blueDeterminate: {
    background: blue[400],
  },
  redDeterminate: {
    background: red[400],
  },
  card: {
    height: '100%',
  },
};

const mapStateToProps = ({ users, userId }) => {
  const user = users.find((e) => e._id === userId);
  return {
    user,
    users,
    formatedUser: users.find(e => e._id === userId),
    isChief: permit(user, { auth: CHIEF }),
  };
};

class InterSend extends Component {
    state = {
      stats: [],
      sended: 0,
      user: this.props.formatedUser,
      display: false,
      users: getEquipes({
        user: this.props.user,
        users: this.props.users
      })
        .filter((e) => e.act && [S_INT, S_DEVIS, S_LEAD].includes(e.service))
        .map(({ _id, login, service, supportIds = [] }) => ({
          _id,
          login,
          service,
          supportIds,
        }))
    };

    componentDidMount() {
      this.doRequest();
    }

    handleChange = (name, cb = (f) => f) => (e) => {
      this.setState({ [name]: e.target.value, display: false }, cb);
    };

    doRequest() {
      const { display, user = {} } = this.state;
      let users = this.state.users || [];
      if (!display) {
        if (user.service === S_DEVIS) {
          users = users.filter((e) =>
            user === e._id ||
            user.supportIds.includes(e._id)
          );
        } else {
          users = [user];
        }
      }
      if (users.length) {
        api.stats
          .get('commercial/interSend', {
            users: users.map((e) => e._id),
          })
          .then((res) => {
            const data = res.body().data().data;
            this.setState({
              total: data.total,
              sended: data.sended,
            });
          })
          .catch(() =>
            notifSystem.error(
              'Erreur',
              'Impossible de charger les statistiques')
          );
      }
    }

    getColor = (value) => {
      const { classes } = this.props;
      if (value >= 50) {
        return classes.blueDeterminate;
      }
      return classes.redDeterminate;
    };

    render () {
      const { classes, isChief } = this.props;
      const {
        user,
        sended,
        display,
        users
      } = this.state;
      return (
        <Card className={classNames(classes.card, 'cards')}>
          <CardContent>
            <Grid container>
              <Grid container item xs={12} justify="flex-end">
                {isChief &&
                  <>
                    <Dropdown
                      title={user.login}
                      data={users}
                      idKey={null}
                      renderKey={'login'}
                      isSelected={(e) => user === e}
                      onChange={this.handleChange('user', this.doRequest)}
                      multiple={false}
                    />
                    <Button
                      onClick={() =>
                        this.setState({ display: !display }, this.doRequest)}
                      color={display ? 'primary' : 'secondary'}>
                        Tous
                    </Button>
                  </>}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" align={'left'}>
                {isChief && display ?
                  `${Math.round(sended)}% des interventions prévues
                aujourd'hui ont été mises en relation` :
                  `${isChief ? `${user.login} a` : ''}
                ${Math.round(sended)}% de ${isChief ? 'ses' : 'vos'}
                interventions prévues aujourd'hui ont été mise en relation`
                }
              </Typography>
              <LinearProgress
                classes={{ bar1Determinate: this.getColor(Math.round(sended)) }}
                className={classes.linearProgress}
                variant="determinate"
                value={sended}
              />
            </Grid>
          </CardContent>
        </Card>
      );
    }
}

export default connect(mapStateToProps)(withStyles(styles)(InterSend));
