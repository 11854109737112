import React, { Component } from 'react';
import { fromJS } from 'immutable';

import Button from '@material-ui/core/Button';

import Documents from '../../InformationsArtisan/Documents';
import MinCustomDialog from '../MinCustomDialog';
import notifSystem from '../../../notifSystem';

const defaultState = artisan => ({
  merge: fromJS({
    _id: artisan._id,
    id: artisan.id,
    dropbox: artisan.dropbox
  }),
  modified: false
});

class DocumentsDialog extends Component {
  state = defaultState(this.props.defaultText)
  actions = [
    {
      title: 'Enregistrer',
      onClick: this.send
    },
    {
      title: 'Fermer',
      onClick: this.props.callback.close
    }
  ]

  send = () => {
    const { merge, modified } = this.state;
    const { callback } = this.props;

    if (!modified) {
      return callback.close();
    }
    callback.send(merge.get('dropbox').toJS())
      .then(() => {
        callback.close();
        notifSystem.success(
          'Opération réussie',
          'Dossier de l\'artisan mis à jour'
        );
      }).catch(e => notifSystem.error(
        'Erreur',
        e.response ? e.response.data : e.message
      ));
  }

  generateActions = () =>
    this.actions
      .map(({ title, onClick }, i) =>
        <Button
          key={i}
          onClick={onClick || this.send}
          children={title}
        />
      )

  setData = (path, value) => {
    path = path instanceof Array ? path : [path];
    this.setState(prevState => ({
      merge: prevState.merge.setIn(path, fromJS(value)),
      modified: true
    }));
  }

  render() {
    const { merge: artisan } = this.state;
    const { open, defaultText } = this.props;

    return (
      <MinCustomDialog
        maxWidth="md"
        open={open}
        title={`Documents de l'artisan ${defaultText.name}`}
        actions={this.generateActions()}
      >
        <Documents
          displayTitle={false}
          path="artisans"
          setData={this.setData}
          underscoreId={artisan.get('_id')}
          id={artisan.get('id')}
          dropbox={artisan.get('dropbox')}
          supp={{
            noAssurance: artisan.get('noAssurance')
          }}
        />
      </MinCustomDialog>
    );
  }
}

export default DocumentsDialog;
