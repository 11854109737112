import React, { PureComponent } from 'react';
import Grid from '@material-ui/core/Grid';

class TrustBox extends PureComponent {
  componentDidMount() {
    let trustbox = document.getElementById('trustbox');
    if ((window.Trustpilot || {}).loadFromElement) {
      (window.Trustpilot || {}).loadFromElement(trustbox);
    }
  }
  render() {
    return (
      <Grid container spacing={4}>
        <Grid item xs={12} md={10} lg={4} style={{ display: 'contents' }}>
          <div
            style={{ display: 'contents' }}
            id="trustbox"
            className="trustpilot-widget"
            data-locale="fr-FR"
            data-template-id="53aa8912dec7e10d38f59f36"
            data-businessunit-id="5b5e9f050c2e060001a2d3d0"
            data-style-height="140px"
            data-style-width="95%"
            data-theme="light"
            data-stars="1,2,3,4,5"
          >
            <a
              href="https://fr.trustpilot.com/review/www.lesbonsartisans.fr"
              rel="noopener noreferrer"
              target="_blank"
            >
              Trustpilot
            </a>
          </div>
        </Grid>
      </Grid>
    );
  }
}

export default TrustBox;
