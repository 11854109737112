import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import { Cancel } from '@material-ui/icons';
import { red, blue } from '@material-ui/core/colors';
import { PropTypes } from 'prop-types';

const styles = {
  secondaryAvatar: {
    backgroundColor: red[100],
    color: red[600],
  },
  primaryAvatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
};

const DialogList = ({
  callback,
  open,
  defaultText: {
    Icon = Cancel,
    title,
    data = [],
    confirmBox,
    elementCallback = callback,
    color = 'secondary',
  },
  classes,
}) => (
  <Dialog
    onEscapeKeyDown={callback}
    disableBackdropClick={true}
    open={open}
    maxWidth={'md'}
  >
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <List>
        {data.map((e, i) => (
          <ListItem key={i} onClick={() => elementCallback(e, i)}>
            <ListItemAvatar>
              <Avatar
                className={
                  color === 'primary'
                    ? classes.primaryAvatar
                    : classes.secondaryAvatar
                }
              >
                <Icon />
              </Avatar>
            </ListItemAvatar>
            {typeof e === 'string' ? <ListItemText primary={e} /> : e}
          </ListItem>
        ))}
      </List>
    </DialogContent>
    <DialogActions>
      {confirmBox && (
        <Button onClick={callback} color="primary" children={confirmBox} />
      )}
      <Button onClick={() => callback(false)} color="secondary">
        Annuler
      </Button>
    </DialogActions>
  </Dialog>
);

DialogList.propTypes = {
  callback: PropTypes.func.isRequired,
  defaultText: PropTypes.object,
  open: PropTypes.bool,
};

DialogList.defaultProps = {
  callback: (e) => e,
  defaultText: {
    title: 'List title',
    data: [],
    confirmBox: 'Valider',
  },
  open: true,
};

export default withStyles(styles)(DialogList);
