import React from 'react';
import StatsPanel from '../../common/panel/StatsPanel';
import { moment } from '@bit/dev-lba.lib.local-globals/moment';
import CustomGraph from './graph/customGraph';

import { Button } from '@material-ui/core';
import { AttachMoneyOutlined, MoneyOffOutlined } from '@material-ui/icons';
import { blue, green, purple, red } from '@material-ui/core/colors';

const CHARTS = [
  { path: 'marge', colors: [red[800]], name: 'Marge' },
  { path: 'reglementsDetails', colors: [green[300]], name: 'Reglements' },
  {
    path: 'decaissementsDetails',
    colors: [blue[300], green[300]],
    name: 'Décaissements (Avoir / Sans avoir)',
  },
  {
    path: 'decaissementSPODetails',
    colors: [red[300], purple[300]],
    name: 'Décaissements (Sans provision / opposition) ',
  },
  {
    path: 'ajout',
    colors: [blue[300], green[300]],
    name: 'Reglement (Ajouté / validé)',
  },
];

class AdminPage extends React.Component {
  state = {
    from: moment().startOf('month').toDate(),
    to: moment().endOf('month').toDate(),
    mode: 'month',
    isMoney: true,
  };

  onDatesChange = (from, to, mode) => {
    this.setState({ from, to, mode });
  };

  render() {
    const { from, to, mode, isMoney } = this.state;
    return (
      <StatsPanel
        title={'Statistiques de comptabilité'}
        defaultState={{
          from,
          to,
          mode,
        }}
        onDatesChange={this.onDatesChange}
        body={
          <React.Fragment>
            <Button
              onClick={() => this.setState({ isMoney: !isMoney })}
              variant="outlined"
              color={!isMoney ? 'primary' : 'secondary'}
            >
              {!isMoney ? <AttachMoneyOutlined /> : <MoneyOffOutlined />}
            </Button>
          </React.Fragment>
        }
      >
        {CHARTS.map((k, i) => (
          <CustomGraph
            key={i}
            mode={mode}
            from={from}
            to={to}
            isMoney={isMoney}
            path={k.path}
            name={k.name}
            colors={k.colors}
          />
        ))}
      </StatsPanel>
    );
  }
}

export default AdminPage;
