import React, { Component } from 'react';
import { moment } from '@bit/dev-lba.lib.local-globals/moment';
import {
  getLogin, getCustomFromUser, openPDF
} from '../../../../utils/function';
import styles from '../styles';
import { IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import actions from '../actions';
import notifSystem from '../../../../notifSystem';
import classNames from 'classnames';
import { CloudDownload, Star } from '@material-ui/icons';
import Charts from './Charts';
import StatsTable from './StatsTable';
import CommentDisplay from './CommentDisplay';
import CircularProgress from '@material-ui/core/CircularProgress';
import lesbonsartisansdef from '../../../../../public/lesbonsartisansdef.png';

class BalanceSheet extends Component {
  fields = {
    CA: {},
    entities: {},
    expectedTurnover: 0,
    totalIntervention: 0,
    totalDevis: 0,
    totalCalls: 0,
    annulationTurnover: 0,
    totalDIntervention: 0,
    workingDay: 0,
    noCall: 0,
    totalInterventionFailed: 0,
    jobs: {},
    totalTDevis: 0,
    verifiedIntervention: 0,
    signalements: 0,
    topSignalements: [],
    topNotation: [],
  };

  state = {
    fields: Object.assign({}, this.fields),
    lastFields: Object.assign({}, this.fields),
    comments: new Array(3).fill(''),
    editingComment: -1,
    disableDownload: false,
    isReady: false
  };

  componentDidMount() {
    this.doRequest();
  }

  componentDidUpdate({ mode, from, user }) {
    if (
      mode !== this.props.mode ||
      user !== this.props.user ||
      from.getTime() !== this.props.from.getTime()
    ) {
      this.doRequest();
    }
  }

  doRequest = () => {
    const { user, mode, from, to, errorMessage } = this.props;

    this.setState({ isReady: false });

    const data = {
      mode,
      from,
      to,
      display: false,
      user,
      agent: getCustomFromUser(user, 'multiLignes'),
      schedule: actions.getSchedule(user),
    };

    actions.getBalance(
      data,
      fields => this.setState({ fields }, () => {
        actions.getBalance(
          {
            ...data,
            from: moment(from)
              .subtract(1, `${mode}s`)
              .toDate(),
            to: moment(to)
              .subtract(1, `${mode}s`)
              .toDate(),
          },
          lastFields => this.setState({ lastFields, isReady: true }),
          () => notifSystem.error('Erreur', errorMessage),
          Object.keys(this.fields).length
        );
      }),
      () => notifSystem.error('Erreur', errorMessage)
    );

  };

  download = () => {
    this.setState({ disableDownload: true });

    const { fields, lastFields, comments } = this.state;
    const { user, mode } = this.props;

    const options = [];

    [0, 1, 2, 3, 4, 5, 6, 7].map(i => {
      const chart = this[`chart${i}`];
      let instance = chart.getEchartsInstance();
      const option = instance.getOption();
      options.push(option);
    });

    actions.download(
      {
        data: {
          modeTitle: mode === 'week' ? 'Chiffre de la semaine' :
            'Chiffres du mois',
          fields,
          lastFields,
          options,
          comments,
          title: this.getTitle(),
          login: getLogin(user),
        },
      },
      ({ pdf }) => {
        openPDF(pdf);
        this.setState({ disableDownload: false });
      },
      () =>
        notifSystem.error('Erreur', 'Impossible de telecharger') ||
        this.setState({ disableDownload: false })
    );
  };

  editComment = (comment, i) => {
    const comments = [...this.state.comments];
    comments[i] = comment;
    this.setState({ comments });
  };

  updateChart = (name, e) => {
    this[name] = e;
  };

  getTitle() {
    const { from, mode } = this.props;
    const month = moment(from).format('MMMM');
    const year = moment(from).format('YYYY');
    const extra = mode === 'week' ?
      ` - semaine ${moment(from).format('ww')}` : '';
    return `Bilan mensuel - ${month} ${year} ${extra}`;
  }

  current = field => this.state.fields[field];

  last = field => this.state.lastFields[field];

  updateEditingComment = editingComment => this.setState({ editingComment });

  render() {
    const { classes, user, from, to, mode } = this.props;
    const {
      comments,
      editingComment,
      downloadDisplay,
      disableDownload,
      isReady
    } = this.state;

    const current = this.current;
    const last = this.last;

    if (!isReady) {
      return (
        <div className={classes.sheet} style={{ height: 400 }}>
          <CircularProgress style={{ marginTop: (400 / 2) - 20 }} />
        </div>
      );
    }

    return (
      <div className={classes.sheet}>
        <div className={classes.sheetPaper} id={`balance-${user}`}>
          {!downloadDisplay && (
            <IconButton
              disabled={disableDownload}
              onClick={this.download}
              className={classes.downloadButton}
            >
              <CloudDownload />
            </IconButton>
          )}
          <div className={classes.sheetTitleDiv}>
            <span
              className={classNames(classes.sheetTitle, classes.sheetTitleText)}
            >
              {this.getTitle()}
            </span>
          </div>

          <p
            className={classNames(classes.sheetLeft10, classes.sheetTitleText)}
          >
            Commercial : <u>{getLogin(user)}</u>
          </p>
          <p className={classes.sheetLeft5}>
            <b>
              {mode === 'week' ?
                'Chiffre de la semaine' : 'Chiffres du mois'}{' '}
              /&nbsp;
            </b>
            <span className={classes.sheetTitleText}>
              {current('workingDay')} jours de travail
            </span>
          </p>
          <div className={classes.sheetCounterDiv}>
            <StatsTable classes={classes} current={current} last={last} />
          </div>

          <p className={classes.sheetLeft5}>
            <b>Evolution des chiffres</b>
          </p>
          <Charts
            updateChart={this.updateChart}
            from={from}
            current={current}
            to={to}
            classes={classes}
          />

          {!!current('topSignalements').length && (
            <React.Fragment>
              <p className={classNames(classes.topTitle, classes.graph)}>
                TOP 10 SIGNALEMENTS
              </p>
              <ul className={classes.liNumeric}>
                {current('topSignalements').map((s) => (
                  <p className={classes.sheetLeft10}>
                    <li>
                      {s._id} / {s.count}
                    </li>
                  </p>
                ))}
              </ul>
            </React.Fragment>
          )}

          {!!current('topNotation').length && (
            <React.Fragment>
              <p className={classNames(classes.topTitle, classes.graph)}>
                TOP 10 INTERVENTIONS / -3 ETOILES
              </p>
              <table className={classes.table}>
                {current('topNotation').map((i) => (
                  <tr className={classes.tr}>
                    <td className={classes.starTd}>
                      {Array(+i.notation.client.note)
                        .fill(0)
                        .map(() => (
                          <Star
                            className={classNames(
                              classes.textBottom,
                              classes.star
                            )}
                          />
                        ))}
                    </td>
                    <td>
                      <b>{i.id}</b>
                    </td>
                    <td>
                      <i>
                        {i.notation.client.commentaire || 'Pas de commentaire'}
                      </i>
                    </td>
                  </tr>
                ))}
              </table>
            </React.Fragment>
          )}
          <div className={classes.comments}>
            <p className={classes.sheetLeft5}>
              <b>Eléments notables</b>
            </p>
            {comments.map((comment, i) => (
              <CommentDisplay
                classes={classes}
                editingComment={editingComment}
                updateEditingComment={this.updateEditingComment}
                editComment={this.editComment}
                comment={comment}
                index={i}
                downloadDisplay={downloadDisplay}
              />
            ))}
          </div>

          <div className={classes.sheetFooter}>
            <img alt="logo" src={lesbonsartisansdef} className={classes.logo} />
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(BalanceSheet);
