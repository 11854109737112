import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { green } from '@material-ui/core/colors';
import TimePicker from '../TimePicker';
import { moment } from '@bit/dev-lba.lib.local-globals/moment';
import { getTime } from '../../utils/date';
import { permit } from '@bit/dev-lba.lib.local-globals/restrictions';
import { connect } from 'react-redux';
import Dropdown from '../Stats/common/dropdown/dropdown';
import { S_JUR } from '@bit/dev-lba.lib.local-globals/services';

const mapStateToProps = ({ users, userId }) => {
  const user = users.find(u => u._id === userId);

  return {
    users,
    userId,
    user,
    permissionTimer: permit(user, { key: 'accessTimerLitiges' }),
  };
};

class AddDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      save: false,
      open: false,
      os: '',
      loading: false,
      interId: '',
      user: null,
      users: props.users.filter(
        (e) =>
          e.act &&
          e.service === S_JUR &&
          !permit(e, { key: 'accessTimerLitiges' })
      ),
      value: moment('01:30', 'HH:mm').toDate(),
    };
    this.onValidate = this.onValidate.bind(this);
    this.onReject = this.onReject.bind(this);
    this.setData = this.setData.bind(this);
    this.setTimeValue = this.setTimeValue.bind(this);
    this.onValidateAndPris = this.onValidateAndPris.bind(this);
  }

  setTimeValue(e) {
    this.setState({
      value: moment(e.target.value, 'HH:mm').toDate()
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.props.open !== nextProps.open || this.state !== nextState;
  }

  convertTime(timer) {
    let tmp = moment();
    tmp.add(timer.hours(), 'h');
    tmp.add(timer.minutes(), 'm');
    return tmp.toISOString();
  }

  onValidate() {
    if (typeof this.props.callback === 'function') {
      const { user, value } = this.state;
      this.props.callback(
        this.state.os,
        false,
        user && user._id,
        this.convertTime(moment(value))
      );
    }
  }

  onValidateAndPris() {
    if (typeof this.props.callback === 'function') {
      this.props.callback(this.state.os, true);
    }
  }

  onReject() {
    if (typeof this.props.callback === 'function') {
      this.props.callback();
    }
  }

  handleChange = (name) => (e) => {
    this.setState({ [name]: e.target.value });
  };

  setData(e) {
    this.setState({ os: e.target.value });
  }

  render() {
    const { permissionTimer } = this.props;
    const { user, users } = this.state;
    return (
      <Dialog disableBackdropClick={true} open={this.props.open}>
        <DialogTitle>{this.props.defaultText.title}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} justify="center" alignItems="flex-end">
            <Grid item xs={12}>
              <TextField
                type={'string'}
                label="Numero OS"
                onChange={this.setData}
                fullWidth
                value={this.state.os}
              />
            </Grid>
            {permissionTimer && (
              <>
                {' '}
                <Grid item xs={6}>
                  <TimePicker
                    label={'Délai'}
                    name="delay"
                    value={getTime(this.state.value)}
                    onChange={this.setTimeValue}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Dropdown
                    title={user ? user.login : 'Selectionnez un utilisateur'}
                    data={users}
                    idKey={null}
                    renderKey={'login'}
                    isSelected={(e) => user === e}
                    onChange={this.handleChange('user')}
                    multiple={false}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.onValidateAndPris}
            style={{ color: green[500] }}
          >
            Valider et prise en charge
          </Button>
          <Button onClick={this.onReject} color="secondary">
            {'Annuler'}
          </Button>
          <Button onClick={this.onValidate} color="primary">
            Valider
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default connect(mapStateToProps)(AddDialog);
