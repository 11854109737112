const style = (theme) => ({
  displayBarMarket: {
    marginTop: '35px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'stretch',
  },
  block: {
    display: 'block',
  },
  centerTxt: {
    textAlign: 'center',
  },
  designNbr: {
    display: 'inline-block',
    borderRadius: '15px',
    marginTop: '10px',
    padding: '3px 5px',
    fontWeight: 'bold',
    fontSize: '1.2rem',
    color: 'white',
  },
  valMarketPrice: {
    display: 'block',
    marginTop: '5px',
    fontSize: '.800rem',
    color: '#939393',
  },
  badge: {
    position: 'absolute',
    padding: '5px 10px',
    background: 'dodgerblue ',
    fontSize: '1rem',
    color: 'white',
    fontWeight: 600
  },
  posBadgeInter: {
    top: '16px',
    right: '638px',
  },
  customSectionMarket: {
    margin: '0 20px 10px 0',
    borderLeft: '1px #ccc solid',
  },
  enable: {
    background: 'YellowGreen',
  },
  titleTable: {
    padding: '5px 20px',
    background: 'dodgerblue',
    color: 'white',
  },
  formControl: {
    width: '100%',
    position: 'relative',
    bottom: '0',
  },
  paper: {
    padding: theme.spacing(2) * 2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    overflow: 'auto',
  },
});

export default style;
