import React from 'react';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {
  paymentCBActionTypes
} from '@bit/dev-lba.lib.local-globals/getDataById';
import {
  L_PAYMULTI
} from '@bit/dev-lba.lib.local-globals/paymentCBActionTypes';
import DatePicker from '../../../../DatePicker';
import { withStyles } from '@material-ui/core/styles';
import { formatDate, moment } from '@bit/dev-lba.lib.local-globals/moment';
import Money from '../../../../../utils/Money';
import { green } from '@material-ui/core/colors';
import classnames from 'classnames';

const styles = {
  tabCellDate: { padding: 8 },
  tabCellDateGreen: { backgroundColor: green[300] },
};

const header = ['Date d\'échéance', 'Montant'];
const dateStyle = {
  overlay: { position: 'relative' },
};

const RecapStatus = ({
  encaissement,
  date,
  mode,
  payments,
  updatePayments,
  classes,
  transaction,
  dateEnc
}) => {
  if (paymentCBActionTypes[L_PAYMULTI].verif({ date, mode })) {
    return (
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          children={
            <Typography children={
              'Le client est éligible voici le récapitulatif des échéances'
            }/>
          }
        />
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead
                children={
                  <TableRow
                    children={header.map((e) => (
                      <TableCell>{e}</TableCell>
                    ))}
                  />
                }
              />
              <TableBody>
                {!!encaissement && (
                  <TableRow key={'encaissement'}>
                    <TableCell
                      className={classnames(
                        classes.tabCellDate,
                        classes.tabCellDateGreen
                      )}
                    >
                      {formatDate(dateEnc || transaction.date)}
                    </TableCell>
                    <TableCell className={classes.tabCellDateGreen}>
                      {Money.toString(encaissement, true)} TTC
                    </TableCell>
                  </TableRow>
                )}
                {payments.map((row, i) => (
                  <TableRow key={i}>
                    <TableCell className={classes.tabCellDate}>
                      <DatePicker
                        dayPickerProps={{
                          disabledDays: {
                            before:
                              i === 0
                                ? moment().toDate()
                                : moment()
                                  .add(i, 'month')
                                  .startOf('month')
                                  .toDate(),
                            after:
                              i === 0 && !encaissement
                                ? moment().add(7, 'days').toDate()
                                : moment()
                                  .add(i + 1, 'month')
                                  .endOf('month')
                                  .toDate(),
                          },
                        }}
                        classNamesDate={dateStyle}
                        label={`${i + 1} - Date d'échéance`}
                        startDate={row.date}
                        onChange={(v) =>
                          updatePayments(i, {
                            ttc: row.ttc,
                            date: v.toDate(),
                          })
                        }
                      />
                    </TableCell>
                    <TableCell>{row.ttc} € TTC</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    );
  }
  return `Le client n'est pas éligible pour le paiement en ${mode}x`;
};


export default withStyles(styles)(RecapStatus);
