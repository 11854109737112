import React, { PureComponent } from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import PropTypes from 'prop-types';

const styles = theme => ({
  iconCard: {
    padding: theme.spacing(1),
    textAlign: 'center'
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(1),
  },
  IconTitle: {
    fontSize: 12,
    fontWeight: 'bold',
    color: '#FAFAFA'
  },
  title: {
    display: 'flex',
    justifyContent: 'space-evenly',
    marginTop: -7
  },
  titleText: {
    fontSize: '1.2rem'
  },
  cardContent: {
    height: 5,
    padding: '16px 24px 24px'
  }
});

class CardSchudle extends PureComponent {

  render() {
    const { classes, color, number, name } = this.props;
    return (
      <Card className={classes.card}>
        <Paper style={{ backgroundColor: color }}
          className={classes.iconCard}>
          <Typography className={classes.IconTitle} color="textSecondary">
            {name}
          </Typography>
        </Paper>
        <CardContent className={classes.cardContent}>
          <div className={classes.title}>
            <Typography className={classes.titleText}
              variant="h6" align='right'>
              {number}
            </Typography>
          </div>
        </CardContent>
      </Card>
    );
  }
}

CardSchudle.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CardSchudle);
