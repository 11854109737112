import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { compose } from 'recompose';
import withWidth from '@material-ui/core/withWidth';
import { withStyles } from '@material-ui/core/styles';
import CustomAddress from '../CustomInputs/CustomAddress';
import Paper from '@material-ui/core/Paper';

const styles = theme => ({
  inputRWDInvoice: {
    position: 'absolute',
    top: '2em',
    right: '4em',
    width: '40%',
    height: '9%',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      top: '-15px',
      left: '10px',
      width: '95%',
    }
  }
});

class AddressAutoComplete extends PureComponent {
  render() {
    const { setAddress, classes, updateAddress, address } = this.props;
    return (
      <Paper elevation={3}
        className={classes.inputRWDInvoice}>
        <CustomAddress
          style={{
            margin: '4px 10px',
            width: 'calc(100% - 20px)',
          }}
          classes={classes.customAddress}
          updateAddress={updateAddress}
          setAddress={setAddress}
          address={address}
          placeholder='Adresse de facturation'
        />
      </Paper>
    );
  }
}

AddressAutoComplete.propTypes = {
  setAddress: PropTypes.func.isRequired
};

export default compose(
  withStyles(styles),
  withWidth())(AddressAutoComplete);
