import React, { PureComponent } from 'react';

import { withStyles } from '@material-ui/core/styles';
import {
  Paper,
  Grid,
  Checkbox,
  FormControlLabel,
  Button,
  Box,
} from '@material-ui/core';
import { blue, green } from '@material-ui/core/colors';
import { permit } from '@bit/dev-lba.lib.local-globals/restrictions';
import ReactEcharts from 'echarts-for-react';
import {
  getOption as getPieOptions
} from '../../common/graph/options/globalPieOptions';
import { getBarOptions } from './options/getBarOptions';
import { getLineOptions } from './options/getLineOption';
import api from '../../../../api';
import { moment } from '@bit/dev-lba.lib.local-globals/moment';
import ws from '../../../../ws';
import notifSystem from '../../../../notifSystem';
import NonPaidTable from './components/nPaidTable';
import { S_INT, S_LEAD } from '@bit/dev-lba.lib.local-globals/services';
import { formatPrice, getLogin } from '../../../../utils/function';
import StatsPanel from '../../common/panel/StatsPanel';
import PopupStatsPanel from '../../common/panel/popup';
import { AttachMoneyOutlined, MoneyOffOutlined } from '@material-ui/icons';

const styles = (theme) => ({
  box: {
    paddingLeft: 30,
    paddingRight: 30,
  },
  card: {
    borderRadius: 10,
    marginBottom: 15,
    position: 'relative',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
});

class DefaultPage extends PureComponent {
  state = {
    encaissements: new Array(3).fill({}),
    paiements: new Array(3).fill({}),
    cheque: true,
    virement: true,
    detailsOpen: false,
    detailsArgs: null,
    from: moment().startOf('month').toDate(),
    to: moment().endOf('month').toDate(),
    mode: 'month',
    detailsData: null,
    isMoney: false,
  };

  componentDidMount() {
    this.doRequests();
    ws.on('update_encaissements', this.doRequests);
    ws.on('update_paiements', this.doRequests);
  }

  componentWillUnmount() {
    ws.removeEventListener('update_paiements');
    ws.removeEventListener('update_encaissements');
  }

  onDatesChange = (from, to, mode) => {
    this.setState({ from, to, mode }, () => this.doRequests());
  };

  doDetailsRequests = (from = this.lastFrom, to = this.lastTo) => {
    this.lastFrom = from;
    this.lastTo = to;
    api.stats
      .get('comptabilite/encaissementsDetails', {
        from,
        to,
        isMoney: +this.state.isMoney,
      })
      .then((e) => this.setState({ detailsData: e.body().data().response }))
      .catch(() => notifSystem.error('Echec', 'Une erreur s\'est produite'));
  };

  doRequests = () => {
    const { cheque, virement, from } = this.state;
    api.stats
      .get('comptabilite/global', {
        cheque,
        virement,
        from,
      })
      .then((data) => {
        this.parseStats(data.body().data().data, 'encaissements');
        this.parseStats(data.body().data().data2, 'paiements');
      })
      .catch(() => notifSystem.error('Echec', 'Une erreur s\'est produite'));
  };

  handle = (field, value) => {
    this.setState({ [field]: value }, () => {
      this.doRequests();
    });
  };

  parseStats = (data, field) => {
    const obj = [];

    const users = Array.from(
      new Set(data.reduce((a, b) => a.concat(b.users), []))
    );

    const pie = {};
    const bar = {};
    const line = {};

    const days = data.map((i) => i._id);

    data.forEach((i) => {
      i.users.filter((u) => {
        const login = getLogin(u);
        if (login && pie[login]) {
          pie[login]++;
        } else if (login) {
          pie[login] = 1;
        }
      });
    });

    days.sort();

    users.forEach((e) => {
      bar[e] = {};

      days.forEach((day) => {
        bar[e][+day] = data
          .find((i) => i._id === day)
          .users.filter((u) => u === e).length;
      });
    });

    Object.keys(bar).forEach(
      (u) => delete Object.assign(bar, { [getLogin(u)]: bar[u] })[u]
    );

    let lastKey = null;
    days.forEach((day) => {
      line[+day] =
        data.find((i) => i._id === day).users.length + (line[lastKey] || 0);
      lastKey = +day;
    });

    obj.push(pie, bar, line);
    this.setState({ [field]: obj });
  };

  openDetails = (open) => {
    this.setState({ open });
  };

  render() {
    const { classes, users } = this.props;
    const {
      encaissements,
      paiements,
      from,
      to,
      mode,
      open,
      detailsData,
      isMoney,
    } = this.state;

    return (
      <Box className={classes.box}>
        <StatsPanel
          title={'Statistiques de comptabilité'}
          enabledModes={[false, false, true]}
          defaultState={{
            from,
            to,
            mode,
          }}
          onDatesChange={this.onDatesChange}
          body={
            <React.Fragment>
              {permit(this.props.user) && (
                <Button
                  color={'primary'}
                  onClick={() => this.openDetails(true)}
                  variant={'outlined'}
                >
                  Details encaissements
                </Button>
              )}
            </React.Fragment>
          }
        >
          <PopupStatsPanel
            open={open}
            onClose={() => this.openDetails(false)}
            title={'Detail encaissements'}
            doRequests={this.doDetailsRequests}
            options={getPieOptions({
              data: detailsData,
              format: true,
              fontSize: 18,
              formatter: (c) => (isMoney ? formatPrice(c.value) : c.value),
            })}
            ready={detailsData}
            body={
              <React.Fragment>
                <Button
                  onClick={() =>
                    this.setState({ isMoney: !isMoney }, () =>
                      this.doDetailsRequests()
                    )
                  }
                  variant="outlined"
                  color={!isMoney ? 'primary' : 'secondary'}
                >
                  {!isMoney ? <AttachMoneyOutlined /> : <MoneyOffOutlined />}
                </Button>
              </React.Fragment>
            }
          />
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6}>
              <Paper className={classes.card}>
                {encaissements[0] && (
                  <ReactEcharts
                    notMerge={true}
                    lazyUpdate={true}
                    option={getPieOptions({
                      data: encaissements[0],
                      roundRadius: 80,
                      showLabel: false,
                      title: 'Repartition des encaissements',
                    })}
                  />
                )}
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Paper className={classes.card}>
                {paiements[0] && (
                  <ReactEcharts
                    notMerge={true}
                    lazyUpdate={true}
                    option={getPieOptions({
                      data: paiements[0],
                      roundRadius: 80,
                      showLabel: false,
                      customLegend: {
                        orient: 'vertical',
                        right: 'right',
                      },
                      title: 'Repartition des paiements',
                    })}
                  />
                )}
                <FormControlLabel
                  style={{ position: 'absolute', bottom: 40, left: 10 }}
                  control={
                    <Checkbox
                      onChange={(e) =>
                        this.handle('virement', e.target.checked)
                      }
                      checked={this.state.virement}
                      value="virement"
                    />
                  }
                  label="Virement"
                />
                <FormControlLabel
                  style={{ position: 'absolute', bottom: 0, left: 10 }}
                  control={
                    <Checkbox
                      onChange={(e) => this.handle('cheque', e.target.checked)}
                      checked={this.state.cheque}
                      value="cheque"
                    />
                  }
                  label="Cheque"
                />
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Paper className={classes.card}>
                {encaissements[1] && (
                  <ReactEcharts
                    notMerge={true}
                    lazyUpdate={true}
                    option={getBarOptions(
                      encaissements[1],
                      [blue[100], blue[300], blue[500]],
                      'Detail encaissements'
                    )}
                  />
                )}
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Paper className={classes.card}>
                {paiements[1] && (
                  <ReactEcharts
                    notMerge={true}
                    lazyUpdate={true}
                    option={getBarOptions(
                      paiements[1],
                      [green[100], green[300], green[500]],
                      'Detail paiements'
                    )}
                  />
                )}
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Paper className={classes.card}>
                {encaissements[2] && (
                  <ReactEcharts
                    notMerge={true}
                    lazyUpdate={true}
                    option={getLineOptions(
                      encaissements[2],
                      blue[500],
                      'Cumul encaissements'
                    )}
                  />
                )}
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Paper className={classes.card}>
                {paiements[2] && (
                  <ReactEcharts
                    notMerge={true}
                    lazyUpdate={true}
                    option={getLineOptions(
                      paiements[2],
                      green[500],
                      'Cumul paiements'
                    )}
                  />
                )}
              </Paper>
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <NonPaidTable
                users={users
                  .filter((u) => u.act && [S_INT, S_LEAD].includes(u.service))
                  .toJS()}
              />
            </Grid>
          </Grid>
        </StatsPanel>
      </Box>
    );
  }
}

export default withStyles(styles)(DefaultPage);
