import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import {
  Grid, Typography, Button, Card,
  CardContent, List, ListItem, ListItemText
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { getCalls } from '../../actions/inter';
import AudioPlayer from './AudioPlayer';
import { blue } from '@material-ui/core/colors';
import { keyUrls } from './FomatText';

const styles = {
  title: {
    marginBottom: 4,
    textTransform: 'uppercase',
    fontSize: '1rem',
  },
  main: {
    borderRadius: 30
  },
  active: {
    color: blue[500],
    fontWeight: 500
  },
  scroll: {
    overflow: 'auto',
    maxHeight: '250px'
  }
};

const mapStateToProps = ({ users, userId }) => ({
  user: users.find(e => e._id === userId)
});

class CallLogs extends PureComponent {
  state = {
    clientCalls: [],
    selectedAudio: {},
    page: 0,
    limit: false,
  };

  getCallsFromApi = (page, showMore = true) => {
    const { numbers, user, direction, collections } = this.props;
    if (numbers) {
      getCalls({
        numbers,
        user,
        page,
        direction,
        collections,
      }).then((newCalls) =>
        this.setState((prevState) => {
          let calls = [];
          if (showMore) {
            calls = (newCalls || []).length
              ? prevState.clientCalls.concat(newCalls)
              : prevState.clientCalls;
          } else {
            calls = (newCalls || []).length ? newCalls : newCalls;
          }
          return {
            clientCalls: calls,
            selectedAudio: calls[0],
            page,
            limit: newCalls.length >= (page + 1) / calls.length,
          };
        })
      );
    }
  }

  componentDidMount() {
    this.getCallsFromApi(this.state.page);
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(
        prevProps.direction) !== JSON.stringify(this.props.direction)
    ) {
      this.getCallsFromApi(0, false);
    }
  }

  showMore = () => this.getCallsFromApi(this.state.page + 1)

  selectAudio = (value) => {
    this.setState({ selectedAudio: value });
  }

  render() {
    const { classes, title, noPadding, keyUrl } = this.props;
    const { clientCalls, limit, selectedAudio } = this.state;
    const { key } = keyUrls[keyUrl];
    return (
      !!clientCalls.length && (
        <Grid item xs={12}>
          <Typography variant="h6" className={classes.title}
            children={title}
          />
          <Card elevation={2} className={classes.main}>
            <CardContent>
              <List className={classes.scroll}>
                {clientCalls.sort((a, b) =>
                  new Date(b.record.callStart).getTime() -
                  new Date(a.record.callStart).getTime()
                ).map((e, i) => (
                  <ListItem divider button onClick={() => this.selectAudio(e)}>
                    <ListItemText key={i}
                      primaryTypographyProps={
                        e.record[key] === selectedAudio.record[key]
                          ? { className: classes.active }
                          : {}
                      }
                      primary={keyUrls[keyUrl].text(e)}
                    />
                  </ListItem>
                ))}
                <ListItem>
                  {limit && (
                    <Button
                      color="inherit"
                      children="Afficher plus..."
                      fullWidth
                      onClick={() => this.showMore()}
                    />
                  )}
                </ListItem>
              </List>
              {this.state.selectedAudio.record[key] && <AudioPlayer
                url={this.state.selectedAudio.record[key]}
                noPadding={noPadding}
              />}
            </CardContent>
          </Card>
        </Grid>
      )
    );
  }
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps))(CallLogs);
