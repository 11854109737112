import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import notifSystem from '../../notifSystem';
import LineFormat from './LineFormat';
import Select from '@material-ui/core/Select';
import UIList from '@material-ui/core/List';
import { List, fromJS } from 'immutable';
import api from '../../api';
import MenuItem from '@material-ui/core/MenuItem';
import {
  NEU, data as cData
} from '@bit/dev-lba.lib.local-globals/commentsConstants';

class CommentList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comments: [],
      opinion: NEU
    };
    this.postComment = this.postComment.bind(this);
  }

  loaded = false;
  styles = {
    select: {
      float: 'right',
      margin: '20px'
    }
  }

  load({ artisanId, markerList }) {
    const artisan = markerList.find(e => (
      e.getIn(['obj', 'value']) === artisanId
    ));
    if (artisan) {
      this.setState({
        comments: ((artisan.getIn(['obj', 'comments']) || new List())
          .sort((a, b) => new Date(b.get('date')) - new Date(a.get('date'))) ||
          new List()).toJS()
      });
    }
  }

  async postComment(event) {
    const { artisanId, setComment } = this.props;

    if (event.key === 'Enter') {
      if (!artisanId) {
        notifSystem
          .error('Erreur', 'Aucun artisan sélectionné');
        return;
      }
      event.preventDefault();
      if (event.target.value) {
        const commentValue = event.target.value.trim();
        event.target.value = '';
        if (commentValue) {
          event.target.value = '';
          try {
            let comments = this.state.comments.slice(0);
            const res = await api.artisans.custom('comments')
              .post({
                _id: artisanId,
                type: this.state.opinion,
                commentValue,
                date: new Date().getTime()
              });
            comments.push(res.body().data().data);
            comments = fromJS(comments);
            this.setState({ comments }, () => setComment(comments));
            notifSystem
              .success('Commentaire', 'Le commentaire a bien été ajouté');
          } catch (e) {
            notifSystem
              .error('Erreur', 'Le commentaire n\'a pas pu être ajouté');
            throw e;
          }
        }
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.markerList !== this.props.markerList ||
      nextProps.artisanId !== this.props.artisanId) {
      this.load(nextProps);
    }
  }

  componentDidMount() {
    if (!this.loaded && this.props.artisanId) {
      this.loaded = true;
      this.load(this.props);
    }
  }

  render() {
    const {
      comments,
      opinion
    } = this.state;
    return (
      [<Grid key="one" item xs={12} sm={12}>
        <UIList>
          {comments &&
            comments.map((e, id) => (
              <LineFormat
                key={id}
                info={e} />
            ))
          }
        </UIList>
      </Grid>,
      <Grid key="inv" item xs={2} sm={2} md={2}>
        <Select
          value={opinion}
          name={'setOpinion'}
          style={this.styles.select}
          onChange={(event) => {
            this.setState({
              opinion: event.target.value
            });
          }}
        >
          {cData.map(e => (
            <MenuItem key={e._id} value={e._id}>
              {e.name}
            </MenuItem>
          ))}
        </Select>
      </Grid>,
      <Grid key="two" item xs={10} sm={10} md={10}>
        <TextField
          fullWidth
          multiline={true}
          placeholder="Commentaire"
          onContextMenu={(e) => {
            e.stopPropagation();
          }}
          onKeyPress={this.postComment}
          rows={3} />
      </Grid>]
    );
  }
}

export default CommentList;
