import {
  NOTIFICATIONS_FETCH,
  NOTIFICATIONS_UPDATE
} from '../constants/ActionTypes';
import DbWsAction from './models/DbWsAction';

const action = new DbWsAction(
  NOTIFICATIONS_FETCH,
  NOTIFICATIONS_UPDATE
);

export const fetch = action.fetch;
export const update = action.update;
