import {
  List,
  Business,
  Assignment,
  Event
} from '@material-ui/icons/';
import { todayToString } from '../../../utils/date';
import { permit } from '@bit/dev-lba.lib.local-globals/restrictions';


export default [
  {
    name: 'No call',
    icon: List,
    count: true,
    link: (e) =>
      `/intervention/list/countNoCallSupp/login-envoi=${e.login}=${e._id}`,
  },
  {
    name: 'Mise à jour du partenaire',
    icon: Assignment,
    childs: [
      {
        name: 'En attente (général)',
        count: false,
        icon: List,
        link: () => '/intervention/list/propositionAv',
      },
      {
        name: 'En attente',
        count: true,
        icon: List,
        link: (e) =>
          `/intervention/list/propositionAvSupp/login-ajout=${
            e.login}=${e._id}`,
      },
      {
        name: 'Validées',
        count: true,
        icon: List,
        link: (e) =>
          `/intervention/list/propositionValSupp/login-ajout=${
            e.login}=${e._id}`,
      },
      {
        name: 'Refusées',
        count: true,
        icon: List,
        link: (e) =>
          `/intervention/list/propositionRefSupp/login-ajout=${
            e.login}=${e._id}`,
      },
    ],
  },
  {
    name: () => 'Mon equipe',
    icon: Event,
    childs: [
      {
        name: todayToString(new Date()),
        bold: true,
        count: true,
        icon: List,
        link: (e) =>
          `/intervention/list/ajdSupp/login-ajout=${e.login}=${e._id}`,
      },
      {
        name: 'Envoyés',
        icon: List,
        count: true,
        link: (e) =>
          `/intervention/list/envAjdSupp/login-ajout=${e.login}=${e._id}`,
      },
      {
        name: 'A programmer',
        count: true,
        icon: List,
        link: (e) =>
          `/intervention/list/aprogAjdSupp/login-support=${e.login}=${e._id}`,
      },
      {
        name: 'Demande Mise à jour (support)',
        count: true,
        icon: List,
        link: (e) =>
          `/intervention/list/majSupport/login-ajout=${e.login}=${e._id}`,
      },
      {
        name: 'En cours',
        count: true,
        icon: List,
        link: (e) =>
          `/intervention/list/ecoursSupp/login-ajout=${e.login}=${e._id}`,
      },
      {
        name: 'A vérifier',
        icon: List,
        count: true,
        link: (e) =>
          `/intervention/list/avrSupp/login-ajout=${e.login}=${e._id}`,
      },
      {
        name: 'Facture à envoyer',
        icon: List,
        count: true,
        link: (e) =>
          `/intervention/list/factaf/login-ajout=${e.login}=${e._id}`,
      },
      {
        name: 'A vérifier jaune',
        icon: List,
        count: true,
        link: (e) =>
          `/intervention/list/avrfJauneSupp/login-ajout=${e.login}=${e._id}`
      },
    ],
  },
  {
    name: () => 'Mes Interventions',
    icon: Event,
    childs: [
      {
        name: todayToString(new Date()),
        bold: true,
        icon: List,
        count: true,
        link: (e) =>
          `/intervention/list/counSuppAdd/login-ajout=${e.login}=${e._id}`,
      },
      {
        name: 'Envoyés',
        icon: List,
        count: true,
        link: (e) =>
          `/intervention/list/counSuppEnvoi/login-envoi=${e.login}=${e._id}`,
      },
      {
        name: 'A programmer',
        icon: List,
        count: true,
        link: (e) =>
          `/intervention/list/counSuppAprog/login-ajout=${e.login}=${e._id}`,
      },
      {
        name: 'En cours',
        icon: List,
        count: true,
        link: (e) =>
          `/intervention/list/counEncSupport/login-ajout=${e.login}=${e._id}`,
      },
      {
        name: 'A vérifier',
        icon: List,
        count: true,
        link: (e) =>
          `/intervention/list/counSuppAvf/login-ajout=${e.login}=${e._id}`,
      },
    ],
  },
  {
    name: 'Mes Devis',
    icon: Business,
    childs: [
      {
        name: 'Tous les Devis',
        icon: List,
        link: '/devis/list',
      },
      {
        name: todayToString(new Date()),
        bold: true,
        icon: List,
        count: true,
        link: (e) => `/devis/list/ajd/login-ajout=${e.login}=${e._id}`,
      },
      {
        name: 'A envoyer',
        icon: List,
        count: true,
        link: (e) => `/devis/list/env/login-ajout=${e.login}=${e._id}`,
      },
      {
        name: 'En attente',
        icon: List,
        count: true,
        link: (e) => `/devis/list/att/login-ajout=${e.login}=${e._id}`,
      },
      {
        name: 'Acceptés',
        icon: List,
        count: true,
        link: (e) => `/devis/list/acc/login-ajout=${e.login}=${e._id}`,
      },
      {
        name: 'Refusés',
        icon: List,
        count: true,
        link: (e) => `/devis/list/refu/login-ajout=${e.login}=${e._id}`,
      },
    ],
  },
  {
    name: 'Mes demandes',
    icon: List,
    count: true,
    link: (e) =>
      permit(e, { key: 'demandeFilters' })
        ? '/demandes/list'
        : `/demandes/list/all?query="${JSON.stringify({
          source: { $in: e.market },
        }).replace(/\./g, '~')}"`,
  },
];
