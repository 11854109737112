import { EventBusy, List, AddAlert } from '@material-ui/icons/';

import { todayToString } from '../../../utils/date';
import { permit } from '@bit/dev-lba.lib.local-globals/restrictions';

export default [
  {
    name: 'Non traitées',
    icon: List,
    count: true,
    link: (e) =>
      permit(e, { key: 'demandeFilters' })
        ? '/demandes/list/dnt'
        : `/demandes/list/dnt_${e._id}`,
  },
  {
    name: 'Toutes',
    count: true,
    icon: List,
    link: (e) =>
      permit(e, { key: 'demandeFilters' })
        ? '/demandes/list'
        : `/demandes/list/all_${e._id}`,
  },
  {
    name: 'Demandes attribuées',
    icon: List,
    count: true,
    link: () => '/demandes/list/assignedemandes',
  },
  {
    name: 'Mes demandes',
    icon: List,
    childs: [
      {
        name: todayToString(new Date()),
        icon: List,
        count: true,
        link: (e) =>
          permit(e, { key: 'demandeFilters' })
            ? '/demandes/list/ajd'
            : `/demandes/list/ajd_${e._id}`,
      },
      {
        name: 'Rappels',
        icon: AddAlert,
        count: true,
        link: (e) =>
          `/demandes/list/reminders?query="{"login.reminder":"${e._id}"}"`,
      },
      {
        name: 'À traitées plus tard',
        icon: EventBusy,
        count: true,
        link: (e) =>
          `/demandes/list/checkLater?query="{"login.modification":"${e._id}"}"`,
      },
    ],
  },
];
