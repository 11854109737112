import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';

import styles from './styles';
import { S_DSUP } from '@bit/dev-lba.lib.local-globals/nStatus';

const NotificationChip = ({ classes, elem }) => {
  let component = <Chip key={elem.name}
    label={elem.shortName} className={classes.chip}
    style={{ backgroundColor: elem.color['500'] }}
  />;
  if (elem.Icon && elem._id === S_DSUP) {
    component = <div className={classes.devisIcon}>
      <elem.Icon key={elem.name} style={{
        color: elem.color[500]
      }} />
      <span className={classes.devisIconSpan} key={`${elem.name}span`}
        style={{ color: elem.color[500] }} >+1</span>
    </div>;
  } else if (elem.Icon) {
    component = <elem.Icon key={elem.name} style={{
      color: typeof elem.color === 'string' ? elem.color : elem.color[500]
    }} />;
  }
  return component;
};

export default withStyles(styles)(NotificationChip);
