import React from 'react';

import DialogDemandeSAV from './DialogDemandeSAV';

const components = (open, defaultText, callback) => [
  <DialogDemandeSAV
    open={open}
    data={defaultText}
    callback={callback}
  />
];

const ComponentForMenu = ({ open, defaultText, callback }) =>
  components(open, defaultText, callback)[defaultText.type];

export default ComponentForMenu;
