import React from 'react';
import { moment } from '@bit/dev-lba.lib.local-globals/moment';

import {
  Checkbox,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TablePagination,
} from '@material-ui/core';

import {
  artisan as artisanCause
} from '@bit/dev-lba.lib.local-globals/rCauses';

import CustomTextField from '../../../CustomInputs/CustomTextField';
import Money from '../../../../utils/Money';
import { RT_BTOB } from '@bit/dev-lba.lib.local-globals/rTypes';
import withPagination from '../../../../hoc/withPagination';


const InterventionsArray = ({
  classes,
  data,
  setData,
  setCheckedGlobal,
  causes = artisanCause,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
}) => {
  const allChecked = setCheckedGlobal
    && data.every(e => e.get('checked', true));
  const type = data.getIn([0, 'recouvrement', 'type']);

  return <Table className={classes.table}>
    <TableHead>
      <TableRow>
        <TableCell />
        <TableCell>
          OS
          {setCheckedGlobal && <Checkbox
            checked={data.every(e => e.get('checked', true))}
            onChange={() => setCheckedGlobal(!allChecked)}
          />}
        </TableCell>
        <TableCell>Date de verif</TableCell>
        <TableCell>Prix final</TableCell>
        <TableCell>Cause</TableCell>
        {type !== RT_BTOB && <TableCell>Montant du recouvrement</TableCell>}
      </TableRow>
    </TableHead>
    <TableBody>
      {data
        .slice(rowsPerPage * page, rowsPerPage * (page + 1))
        .map((e, i) => (
          <TableRow key={e.id}>
            <TableCell>
              <Checkbox
                checked={e.get('checked', true)}
                onChange={() => setData([(rowsPerPage * page ) + i, 'checked'],
                  !e.getIn(['checked']))}
              />
            </TableCell>
            <TableCell>{e.get('id')}</TableCell>
            <TableCell>
              {moment(e.getIn(['date', 'verif'])).format('LLL')}
            </TableCell>
            <TableCell>
              {Money.toString(e.get('finalPrice'))}
            </TableCell>
            <TableCell>
              {
                <Select
                  value={e.getIn(['recouvrement', 'cause'], '')}
                  onChange={t =>
                    setData([i, 'recouvrement', 'cause'], t.target.value)
                  }
                >
                  {causes.map(c => (
                    <MenuItem value={c._id}>{c.name}</MenuItem>
                  ))}
                </Select>
              }
            </TableCell>
            {type !== RT_BTOB && <TableCell>
              <CustomTextField
                type="tel"
                className={classes.chip}
                value={e.getIn(
                  ['recouvrement', 'priseEnCharge', 'montant'],
                  Money.toString(e.get('finalPrice', 0))
                )}
                path={[i, 'recouvrement', 'priseEnCharge', 'montant']}
                setData={setData}
              />
            </TableCell>}
          </TableRow>
        ))}
    </TableBody>
    <TableFooter>
      <TableRow>
        <TablePagination
          count={data.size}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[10, 25, 50, 100]}
          page={page}
          labelRowsPerPage="Lignes par page:"
          onChangeRowsPerPage={e => handleChangeRowsPerPage(e.target.value)}
          onChangePage={(e, page) => handleChangePage(page)}
          labelDisplayedRows={d => `${d.from}-${d.to} de ${d.count}`}
        />
      </TableRow>
    </TableFooter>
  </Table>;
};

export default withPagination()(InterventionsArray);
