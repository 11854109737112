import React from 'react';
import { setAssurance } from '../../actions/artisan';
import { permit } from '@bit/dev-lba.lib.local-globals/restrictions';
import { Switch, FormControlLabel } from '@material-ui/core';
import stopPropagation from '../../utils/stopPropagation';
import { connect } from 'react-redux';


const mapStateToProps = ({ userId, users }) => ({
  user: users.find(e => e._id === userId),
});

export default connect(mapStateToProps)(({
  user,
  artisan: { _id, noAssurance = false },
  setData = null,
}) =>
  permit(user, { key: 'partenariat' }) && (
    <FormControlLabel
      control={
        <span
          onClick={stopPropagation(() =>
            setData ? setData() : setAssurance(_id, !noAssurance)
          )}
          children={
            <Switch
              checked={noAssurance}
              color={noAssurance ? 'secondary' : 'primary'}
            />
          }
        />
      }
      label={noAssurance ? 'non requis' : 'requis'}
    />
  ));
