import React from 'react';
import Grid from '@material-ui/core/Grid';
import notifSystem from '../notifSystem';
import Button from '@material-ui/core/Button';
import { CloudUpload } from '@material-ui/icons';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withDisplaying, withLoading } from '../hoc';
import { compose } from 'recompose';
import { uploadFile } from '../actions/dropbox';
import {
  data as docTypes, DINTER
} from '@bit/dev-lba.lib.local-globals/docTypes';
import store from '../store';
import { closeDialog, setDialog } from '../actions/dialog';

const DropboxUpload = ({
  buttonName,
  type,
  style,
  color,
  upload,
  length = 1,
  underscoreId,
  id,
  path,
  disableSave,
  loading,
  setLoading,
  disabled,
  getFile = e => e
}) => {

  const addFile = (e) => {
    if (!e.target.files[0]) {
      return;
    }
    const onError = () => {
      setLoading(false);
      notifSystem.error('Erreur', 'Le fichier n\'a pas pu être ajouté');
    };
    const { key } = docTypes.find(e => e._id === type) || {};
    const reader = new FileReader();
    const file = e.target.files[0];
    const extension = (/[^.]*$/).exec(file.name);
    const data = new FormData();
    const time = new Date().getTime();
    const fileName = `${file.name
      .replace(`.${extension}`, '')}-${time}.${extension}`;
    const name = path === 'artisans'
      ? `${key}-${id}-${length}-${time}${extension ? `.${extension}` : ''}`
      : fileName;
    data.append('file', file);
    reader.readAsDataURL(file);
    e.target.value = '';
    reader.onload = () => {
      if (typeof disableSave === 'function') {
        disableSave(true);
      }
      setLoading(true)
        .then(() => uploadFile({
          type,
          fileName: name,
          file: reader.result,
          baseName: `${type}-${id}`,
          underscoreId,
          path
        }))
        .then(res => {
          if (res.body().data().status !== 'OK') {
            return onError();
          }
          setLoading(false);
          notifSystem.success('Upload', 'Le fichier a bien été ajouté');
          getFile(id);
          if (disableSave) {
            disableSave(false);
          }
        }).catch(onError);
    };
  };

  const addFileCategorie = (e) => {
    const onError = () => {
      setLoading(false);
      notifSystem.error('Erreur', 'Le fichier n\'a pas pu être ajouté');
    };
    e.stopPropagation();

    return store.dispatch(setDialog({
      name: 'DialogPrintfileTypes',
      open: true,
      dialogProps: { title: 'Documents présents dans le scan', middle: true },
      contentProps: {
        attachments: [],
        categories: []
      },
      actions: [
        {
          children: 'Ajouter',
          color: 'primary',
          onClick: ({ attachments, categories }) => {
            store.dispatch(setDialog({
              name: 'LoadingDialog',
              open: true,
              dialogProps: { title: 'Importation des fichiers en cours' },
            }));
            attachments.asyncForEach(async attachment => {
              await uploadFile({
                type,
                fileName: attachment.name,
                file: 'data:application/pdf;base64,'.concat(attachment.data),
                id,
                underscoreId,
                path,
                categories
              }).then(() => notifSystem.success('Upload',
                `Le fichier ${attachment.name} a bien été ajouté`))
                .catch(() => notifSystem.error('Upload',
                  `Le fichier ${attachment.name} n'a pas pu être ajouté`));
            }).then(() => {
              store.dispatch(closeDialog());
              if (!attachments.length) {
                return notifSystem.error('Erreur',
                  'Veuillez ajouter un fichier');
              }
              getFile(id);
              if (disableSave) {
                disableSave(false);
              }
            }).catch(onError);
          }
        }
      ],
    })
    );
  };

  return (
    <>
      {type === DINTER ?
        <Button
          variant="contained"
          component="span"
          color="primary"
          onClick={(e) => addFileCategorie(e)}
          disabled={disabled || loading}
          style={{ backgroundColor: color || null }}
        >
          {buttonName && (
            <div style={{ marginRight: 8 }} children={buttonName} />
          )}
          <CloudUpload style={{ fontSize: 20, marginRight: 5 }} />
          {loading && <CircularProgress size={24} />}
        </Button> :
        <Grid style={style}>
          <input
            type="file"
            style={{ display: 'none' }}
            id={type}
            onChange={upload || addFile}
          />
          <label htmlFor={type}>
            <Button
              variant="contained"
              component="span"
              color="primary"
              disabled={loading}
              style={{ backgroundColor: color || null }}
            >
              {buttonName && <div style={{ marginRight: 8 }}
                children={buttonName} />}
              <CloudUpload style={{ fontSize: 20, marginRight: 5 }} />
              {loading && <CircularProgress size={24} />}
            </Button>
          </label>
        </Grid>
      }
    </>
  );
};

DropboxUpload.displayName = 'DropboxUpload';

export default compose(
  withDisplaying(),
  withLoading({ customHandling: true })
)(DropboxUpload);
