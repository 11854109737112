import React, { PureComponent } from 'react';
import { Paper, Grid } from '@material-ui/core';
import HolidaysSelector from './selector';
import HolidaysView from './list';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import {
  H_ANN,
  H_REF,
} from '@bit/dev-lba.lib.local-globals/hStatus';
import { withHeaderTab } from '../../../hoc';
import CustomCalendar from '../../CustomCalendar';
import { filterHolidayUsers } from '../../Utils/FilterHolidayUsers';
import { moment } from '@bit/dev-lba.lib.local-globals/moment';
import ws from '../../../ws';
import { S_RH } from '@bit/dev-lba.lib.local-globals/services';

const mapStateToProps = ({ userId, users }) => {
  const user = users.find(u => u._id === userId);
  return {
    users,
    user,
  };
};

const getDataHoliday = ({ justeValidate, users, user, newUser }) => {
  const data = filterHolidayUsers(users, user, newUser);
  return justeValidate
    ? data.filter((d) => ![H_ANN, H_REF].includes(d.status))
    : data;
};

class UserHolidaysPanel extends PureComponent {
  state = {
    data: [],
    usersList: this.props.users.toJS()
  }

  updateData = user => {
    const { usersList } = this.state;
    this.setState({
      data: getDataHoliday({
        users: usersList,
        user: this.props.user,
        ...(user ? { newUser: user } : {}),
        justeValidate: this.props.justeValidate,
      })
    });
  }

  wsUpdate = ({ updatedHolidays, newHoliday }) => {
    const { data, usersList } = this.state;
    const { user } = this.props;
    if (updatedHolidays && updatedHolidays.length) {
      const nHolidays = updatedHolidays.reduce((acc, holiday) => {
        const index = data.findIndex(h =>
          moment(h.creation).valueOf() === moment(holiday.creation).valueOf() &&
          h.user === holiday.user
        );
        if (~index) {
          return [
            ...acc.slice(0, index),
            {
              ...data[index],
              ...holiday,
              from: new Date(holiday.from),
              to: new Date(holiday.to),
              creation: new Date(holiday.creation),
            },
            ...acc.slice(index + 1)
          ];
        }
        return acc;
      }, data);
      return this.setState({ data: nHolidays });
    }
    const hUser = usersList.find(u => u._id === newHoliday.user);
    if (hUser && (user.service === S_RH || hUser.service === user.service)) {
      return this.setState({ data: [newHoliday, ...data ] });
    }
  }

  componentDidMount() {
    this.updateData();
    ws.on('update_users_holiday', this.wsUpdate);
  }

  componentWillUnmount() {
    ws.removeEventListener('update_users_holiday', this.wsUpdate);
  }

  render() {
    const { tab, user } = this.props;
    const { usersList, data } = this.state;
    return (
      <Paper>
        <Grid container spacing={1}>
          {tab === 0 ? (
            <React.Fragment>
              <Grid item xs={5}>
                <HolidaysSelector
                  user={user}
                  users={usersList.filter(u => u.act)}
                  data={data.filter(e =>
                    moment(e.end).isAfter(moment()) &&
                      ![H_ANN, H_REF].includes(e.status)
                  )}
                />
              </Grid>
              <Grid item xs={7}>
                <HolidaysView
                  data={data}
                  users={usersList}
                  user={user}
                />
              </Grid>
            </React.Fragment>
          ) : (
            <Grid item xs={12}>
              <CustomCalendar
                events={
                  data.filter((d) => ![H_ANN, H_REF].includes(d.status))
                }
                style={{ height: 500, padding: 20 }}
                eventPropGetter={(event) => ({
                  style: {
                    backgroundColor: event.color,
                  },
                })}
              />
            </Grid>
          )}
        </Grid>
      </Paper>
    );
  }
}

export default compose(
  withHeaderTab({
    tabs: [
      {
        label: 'Selection',
        value: 0,
      },
      {
        label: 'Planning',
        value: 1,
      },
    ],
  }),
  connect(mapStateToProps)
)(UserHolidaysPanel);
