import React from 'react';

import { Button, Grid } from '@material-ui/core';


export default ({
  handleChange, mode, finalPrice, tva, advancePaymentMontant
}) => {
  const amount = Math.round(finalPrice * (
    (tva / 100) + 1)) - advancePaymentMontant;
  return <Grid container justifyContent="center" spacing={2}>
    <Grid item xs={12} sm={3}>
      <Button
        variant='contained' fullWidth
        disabled={!(+amount >= 10000 && +amount <= 600000)}
        color={mode === 3 ? 'secondary' : 'primary'}
        onClick={() => handleChange('mode')(3)}
        children={'En 3x'}
      />
    </Grid>
    <Grid item xs={12} sm={3}>
      <Button variant='contained' fullWidth
        disabled={!(+amount >= 20000 && +amount <= 600000)}
        color={mode === 4 ? 'secondary' : 'primary'}
        onClick={() => handleChange('mode')(4)}
        children={'En 4x'}
      />
    </Grid>
  </Grid>;

};
