import React, { Component } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import { TableBody as TableBodyMaterial } from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

class TableBody extends Component {
  shouldComponentUpdate(nP) {
    return !!(
      JSON.stringify(nP.tableData) !== JSON.stringify(this.props.tableData)
      || JSON.stringify(nP.list) !== JSON.stringify(this.props.list)
      || nP.isOpen !== this.props.isOpen
      || this.props.localUpdate
    );
  }

  render() {
    const {
      classes,
      menu,
      name,
      isOpen,
      table,
      keys,
      list,
      idMenu,
      tableData,
      users,
      userId,
      user,
      small,
      isSelected,
      allSelect,
      checkClientNumber,
      setDialog,
      listen,
      setSelected,
      open
    } = this.props;

    return (list &&
      (list.length
        ? list.map(elem =>
          <this.props.row
            listen={listen}
            setDialog={setDialog}
            checkClientNumber={checkClientNumber}
            small={small}
            key={elem.id}
            keys={keys}
            table={Object.values(table)}
            tableData={tableData}
            idMenu={idMenu}
            colSpan={Object.values(table).length}
            setSelected={setSelected}
            openDrop={open}
            open={isOpen}
            user={user}
            menu={menu}
            elem={elem}
            classes={classes}
            users={users}
            userId={userId}
            isSelected={isSelected}
            allSelect={allSelect}
            name={name}
          />
        )
        : <TableBodyMaterial>
          <TableRow>
            <TableCell colSpan={keys.length} className={classes.align}>
              <CircularProgress style={styles.load} />
              <Typography className={classes.loadTitle}>
                Chargement des données...
              </Typography>
            </TableCell>
          </TableRow>
        </TableBodyMaterial>
      )
    ) || <TableBodyMaterial>
      <TableRow>
        <TableCell colSpan={keys.length} className={classes.align}>
          <Typography className={classes.noDataTitle}>
            Aucune donnée trouvée
          </Typography>
        </TableCell>
      </TableRow>
    </TableBodyMaterial>;
  }
}

export default withStyles(styles)(TableBody);
