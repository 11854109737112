class Csv {
  constructor(csv) {
    this.csv = typeof csv === 'string' ? Csv.parseCsv(csv) : csv;
  }
  toString() {
    let str = '';
    for (let i = 0; i < this.csv.length; i++) {
      if (i !== 0) {
        str += '\r\n';
      }
      for (let j = 0; j < this.csv[i].length; j++) {
        if (j !== 0) {
          str += ',';
        }
        str.replace('"', '""');
        str += this.csv[i][j].match(/,|"/)
          ? `"${this.csv[i][j]}"`
          : this.csv[i][j];
      }
    }
    return str;
  }
  toArray() {
    return this.csv;
  }
  toObjectList() {
    let objList = [];
    const names = this.getHeader();
    for (let i = 1; i < this.csv.length; i++) {
      let obj = {};
      for (let j = 0; j < this.csv[i].length; j++) {
        obj[names[j]] = this.csv[i][j];
      }
      objList.push(obj);
    }
    return objList;
  }
  getHeader() {
    return this.toArray().slice(0, 1)[0];
  }
  toArrayWithoutHeader() {
    return this.toArray().slice(1);
  }

  static parseCsv(res) {
    let table = [];
    res.split(/\r\n|\n|\r/).forEach((line) => {
      let tr = [];
      let td = '';
      let inString = false;
      if (line !== '') {
        for (let i = 0; i < line.length; i++) {
          if ([',', ';'].includes(line[i])) {
            tr.push(td);
            td = '';
          } else if (line[i] === '"') {
            if (line[i + 1] === '"' && inString) {
              td += '"';
              i += 1;
            } else {
              inString = !inString;
            }
          } else {
            td += line[i];
          }
        }
        tr.push(td);
        table.push(tr);
      }
    });
    return table;
  }
}

export default Csv;
