import React from 'react';

import Tooltip from '@material-ui/core/Tooltip';
import {
  TurnedIn,
  FolderShared
} from '@material-ui/icons';
import { connect } from 'react-redux';
import { data } from '@bit/dev-lba.lib.local-globals/SAVStatus';
import { green } from '@material-ui/core/colors';

const mapStateToProps = ({ users }, { userSAV }) => {
  const userObj = userSAV && users.find(e => e._id === userSAV);
  return {
    loginSAV: userObj && userObj.login
  };
};

const SAVIcon = ({ statusSAV, loginSAV }) => {
  const status = data.find(c => c._id === statusSAV);
  return ([
    statusSAV && <Tooltip
      key={'statusSAV'}
      title={<span>Status: {status.name || 'untrouvable'}</span>}
      children={<TurnedIn style={{ color: status.color[500], fontSize: 20 }} />}
    />,
    loginSAV && <Tooltip key={'loginSAV'}
      title={<span>Dossier suivi par: {loginSAV || 'untrouvable'}</span>}
      children={<FolderShared
        style={{ color: green[500], fontSize: 25 }} />}
    />
  ]);
};

export default connect(mapStateToProps)(SAVIcon);
