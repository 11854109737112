import {
  OLDCHAUDIERE_FETCH,
  OLDCHAUDIERE_UPDATE
} from '../constants/ActionTypes';
import DbWsAction from './models/DbWsAction';

const action = new DbWsAction(
  OLDCHAUDIERE_FETCH,
  OLDCHAUDIERE_UPDATE
);

export const fetch = action.fetch;
export const update = action.update;
