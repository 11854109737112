import React, { Component } from 'react';
import { shallowEqual } from 'recompose';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import TableBody from '@material-ui/core/TableBody';
import Typography from '@material-ui/core/Typography';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import ListRow from './ListRow';

const styles = {
  tableBody: {
    height: 520,
    overflow: 'auto'
  }
};

class List extends Component {
  shouldComponentUpdate({ elems: newElems, ...nextProps }) {
    const { elems, ...props } = this.props;

    return !shallowEqual(props, nextProps)
      || JSON.stringify(elems) !== JSON.stringify(newElems);
  }

  render() {
    const {
      Row = ListRow,
      elems,
      classes,
      rowElem,
      currentRow,
      ...props
    } = this.props;

    return <TableBody className={classes.tableBody}>
      {elems.length
        ? elems.map(elem => <Row
          {...props}
          rowElem={currentRow === elem._id ? rowElem : null}
          open={elem._id === currentRow}
          key={elem._id}
          elem={elem}
        />)
        : <TableRow>
          <TableCell colSpan={8}>
            <Typography variant="h6" align="center">
                Aucune donnée trouvée
            </Typography>
          </TableCell>
        </TableRow>}
    </TableBody>;
  }
}

List.propTypes = {
  elems: PropTypes.array.isRequired,
  keys: PropTypes.array.isRequired,
  handlers: PropTypes.object
};

List.defaultProps = {
  elems: [],
  keys: [],
  handlers: {}
};

export default withStyles(styles)(List);
