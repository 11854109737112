import React from 'react';
import { Button } from '@material-ui/core';

const StatusCounter = ({ color, label, status, data, value }) => {
  const obj = data ? data.map(e => e._id && e._id.length ? e
    : { ...e, _id: [e._id] })
    .filter(e => {
      if (status.length) {
        return e._id.some(id => status.includes(id));
      }
      return e._id.includes(status);
    }) : null;

  return (<Button variant="outlined"
    style={{ color, borderColor: color }}>{label}  {' : '}
    {obj ? obj.reduce((acc, e) => acc + e.count, 0) : value}</Button>);
};

export default StatusCounter;
