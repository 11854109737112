import React, { Component } from 'react';
import { moment } from '@bit/dev-lba.lib.local-globals/moment';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';

import MinCustomDialog from '../MinCustomDialog';
import notifSystem from '../../../notifSystem';
import { createGoogleCalendar } from '../../../actions/googleCalendar';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Divider } from '@material-ui/core';

const InputBox = ({ placeholder, handleChange, defaultValue }) =>
  <Input
    defaultValue={defaultValue}
    fullWidth
    placeholder={placeholder}
    onContextMenu={(e) => {
      e.stopPropagation();
    }}
    disableUnderline={true}
    onChange={e => handleChange(e.target.value)}
    multiline={true}
    rows={10}
  />;

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  item: {
    padding: '10px',
  },
};

class RelanceDialog extends Component {
  state = {
    comment: this.props.defaultText.defaultValue || '',
    historyComment: '',
    date: moment().add('7', 'd').toDate(),
    loading: false
  }

  generateActions = (load) =>
    [
      {
        children: 'Fermer',
        color: 'secondary',
        onClick: this.props.callback.close
      },
      {
        children: 'Confirmer',
        color: 'primary',
        onClick: this.send
      }
    ].map((buttonProps, i) => {
      if (i === 1 && load) {
        return <CircularProgress size={24} />;
      }
      return <Button
        key={i}
        disabled={load}
        {...buttonProps}
      />;
    });

  send = async () => {
    const { historyComment, comment } = this.state;
    const { callback, defaultText } = this.props;
    let { date } = this.state;
    this.setState({ loading: true });
    if (!comment.trim() || !date || !historyComment.trim()) {
      this.setState({ loading: false });
      return notifSystem.error(
        'Relance',
        'Veuillez indiquer tous les champs demandés'
      );
    }
    await callback.send(comment, date.getTime(), historyComment)
      .then(() => {
        callback.close();
        notifSystem.success(
          'Relance',
          'La relance a bien été enregistrée'
        );
        setTimeout(() => createGoogleCalendar(
          date,
          defaultText.googleCalendarTitle,
          comment,
        ), 1000);
      })
      .catch(e => notifSystem.error('Relance', e.response.data || e.message))
      .finally(() => this.setState({ loading: false }));
    this.setState({ loading: false });
  }

  handleChange = field => value =>
    this.setState({
      [field]: value
    })

  render() {
    const {
      defaultText,
      classes,
      open
    } = this.props;
    const {
      date,
      loading,
      comment,
      historyComment,
    } = this.state;
    return <MinCustomDialog
      middle
      maxWidth="md"
      open={open}
      title={defaultText.title}
      actions={this.generateActions(loading)}
    >
      <React.Fragment>
        <div className={classes.container}>
          <div className={classes.item}>
            <TextField
              label="Prochaine relance"
              defaultValue={
                moment(date).format('YYYY-MM-DDTHH:mm')
              }
              type="datetime-local"
              onChange={event =>
                this.handleChange('date')(new Date(event.target.value))}
            />
          </div>
          <div className={classes.item}>
            <InputBox
              placeholder="Text"
              defaultValue={comment}
              handleChange={this.handleChange('comment')}
            />
          </div>
          <Divider />
          <div className={classes.item}>
            <InputBox
              placeholder="Entrez votre commentaire..."
              value={historyComment}
              handleChange={this.handleChange('historyComment')}
            />
          </div>
        </div>
      </React.Fragment>
    </MinCustomDialog>;
  }
}

export default withStyles(styles)(RelanceDialog);
