import React, { PureComponent } from 'react';
import { attachFile } from '@bit/dev-lba.lib.local-globals/utils';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Footer from './Footer';
import { defaultTextClient, defaultTextArtisan } from './defaultText';
import { withStyles } from '@material-ui/core/styles';
import {
  getSize,
  isMaxSizeOk,
  isMaxFileOk,
  MAX_FILES_SIZE,
  MAX_FILES,
} from '../../../utils/function';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import notifSystem from '../../../notifSystem';
import { MAIL_REGEX } from '../../../utils/regexps';

const styles = {
  TextInput: {
    overflow: 'auto',
    maxHeight: 430,
    minHeight: 200,
    height: 435
  }
};
class Content extends PureComponent {
  constructor(props) {
    super(props);
    const { savIntervention, artisan, artisanMail, text } = props.data;
    this.send = this.send.bind(this);
    this.cancel = this.cancel.bind(this);
    this.addFile = this.addFile.bind(this);
    this.handleChipDelete = this.handleChipDelete.bind(this);
    const textClient = defaultTextClient(savIntervention)[text] || {};
    const mailArtisan = 'artisanMail' in props.data &&
    defaultTextArtisan(savIntervention, artisan,
      props.user)[artisanMail - 1];
    const clientData = savIntervention.client;
    const clientBilling = savIntervention.billing;
    const clientMail = clientData ?
      (clientData.email1 || clientData.email2) :
      (clientBilling.email1 || clientBilling.email2);
    this.state = {
      multiline: textClient.body,
      artisanMail: mailArtisan ? mailArtisan.body : '',
      titleArtisan: mailArtisan ? mailArtisan.title : '',
      clientMail: clientMail ? clientMail : '',
      tab: 0,
      files: [],
      headLine: textClient.title,
      loading: false,
      totalSize: 0,
      maxSize: MAX_FILES_SIZE,
      maxFile: MAX_FILES,
      isSizeOk: true,
      isFileOk: true,
    };
  }

  componentDidMount() {
    if (this.props.data) {
      const { savIntervention, artisan, artisanMail, text } = this.props.data;
      const textClient = defaultTextClient(savIntervention)[text] || {};
      const mailArtisan = 'artisanMail' in this.props.data &&
      defaultTextArtisan(savIntervention, artisan,
        this.props.user)[artisanMail - 1];
      this.setState({
        multiline: textClient.body,
        headLine: textClient.title,
        artisanMail: mailArtisan ? mailArtisan.body : '',
        titleArtisan: mailArtisan ? mailArtisan.title : '',
      });
    }
  }

  checkMail = (str) => {
    if (str !== undefined && str !== '') {
      return (MAIL_REGEX.test(str));
    }
    return true;
  }

  send() {
    const { isFileOk, isSizeOk, headLine, sendMail,
      multiline, files, artisanMail, titleArtisan, clientMail } = this.state;
    if ((sendMail &&
        (!headLine || !clientMail || !this.checkMail(clientMail)))) {
      return notifSystem.error('Erreur',
        'Veuillez ajouter un titre et une adresse mail valide.');
    } else if ((!sendMail && !headLine)) {
      return notifSystem.error('Erreur',
        'Veuillez ajouter un titre.');
    } else if (isFileOk && isSizeOk) {
      this.props.callback(true, sendMail ? { multiline, headLine, clientMail }
        : false, files, { body: artisanMail, titleArtisan });
    }
  }

  cancel() {
    this.props.callback();
  }

  addFile(e) {
    attachFile(e.target.files[0]).then(attachment => {
      const ret = this.state.files.concat(attachment);
      this.setState({
        files: ret,
        totalSize: getSize(ret),
        isFileOk: isMaxFileOk(ret.length),
        isSizeOk: isMaxSizeOk(ret)
      });
    });
  }

  handleChange = name => event => {
    this.setState({
      [name]: name === 'sendMail' ? !this.state.sendMail : event.target.value
    });
  }

  handleChangeTab = (event, tab) => {
    this.setState({ tab });
  }

  handleChecked = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  handleChipDelete(index) {
    this.state.files.splice(index, 1);
    const ret = this.state.files.slice(0);
    const tmpSize = getSize(ret);
    this.setState({
      files: ret,
      totalSize: tmpSize,
      isFileOk: isMaxFileOk(ret.length, this.state.maxFile),
      isSizeOk: isMaxSizeOk(ret, this.state.maxSize)
    });
  }

  render() {
    const attachments = 'Pièces jointes';
    const {
      multiline,
      totalSize,
      maxFile,
      maxSize,
      files,
      isFileOk,
      isSizeOk,
      loading,
      tab,
      artisanMail,
      titleArtisan,
      headLine,
      clientMail
    } = this.state;
    const up = isFileOk ? (
      <Typography variant="caption">
        {attachments}: {Object.keys(files).length}/{maxFile}</Typography>)
      : (<Typography variant="caption" style={{ color: 'red' }}>
        {attachments}: {Object.keys(files).length}/{maxFile}</Typography>);

    const down = isSizeOk ? (
      <Typography variant="caption">{totalSize}/{maxSize}</Typography>)
      : (
        <Typography variant="caption" style={{ color: 'red' }}>
          {totalSize}/{maxSize}
        </Typography>
      );
    return (
      <Paper>
        {artisanMail ? <Tabs
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          onChange={this.handleChangeTab}
        >
          <Tab label="client Mail" value={0} />
          <Tab label="Artisan Mail" value={1} />
        </Tabs> : null}
        <Card style={{ overflow: 'auto' }}>
          <CardContent>
            {tab === 0 && <Grid container spacing={3}>
              <Grid key="switch" item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.sendMail}
                      onChange={this.handleChange('sendMail')}
                      value="checkedA"
                    />
                  }
                  label="Envoyer le mail"
                />
              </Grid>
            </Grid>}
          </CardContent>
          <CardContent>
            {(tab === 0) && <TextField
              fullWidth
              id="text"
              value={clientMail}
              onContextMenu={(e) => { e.stopPropagation(); }}
              onChange={this.handleChange('clientMail')}
              label="Adresse mail du client"
            />}
          </CardContent>
          <CardContent>
            {(tab === 0 || titleArtisan) && <TextField
              fullWidth
              id="text"
              value={headLine}
              onContextMenu={(e) => { e.stopPropagation(); }}
              onChange={this.handleChange('headLine')}
              label="Titre du mail"
            />}
          </CardContent>
          <CardContent>
            <TextField
              fullWidth
              id="textarea"
              multiline
              value={tab === 0 ? multiline : artisanMail}
              onContextMenu={(e) => { e.stopPropagation(); }}
              InputProps={{ style: styles.TextInput }}
              rows={30}
              onChange={this.handleChange(
                [tab === 0 ? 'multiline' : 'artisanMail'])}
              label="Corps du mail"
            />
          </CardContent>
          <CardContent style={{ 'minHeight': '100px' }}>
            {up}
            <CardContent>
              <Paper>
                {Object.values(files).map((element, index) => (
                  <Chip
                    key={index}
                    label={element.name}
                    onDelete={() => this.handleChipDelete(index)}
                  />
                ))}
              </Paper>
            </CardContent>
            <div style={{ 'float': 'right' }}>
              {down}
            </div>
          </CardContent>
          <Footer key = "footer"
            artisan={tab === 0}
            send={this.send}
            cancel={this.cancel}
            addFile={this.addFile}
            loading={loading}
          />
        </Card>
      </Paper>
    );
  }
}

export default withStyles(styles)(Content);
