import React from 'react';

import { Grid, Button, Link, Paper, Typography } from '@material-ui/core';

import DialogTabs from './DialogTabs';
import InfoCombo from './InfoCombo';
import CaldeoData from './CaldeoData';
import Domofinance from '../DomofinanceDialog/Content';
import { permit } from '@bit/dev-lba.lib.local-globals/restrictions';
import CaldeoMPR from './CaldeoMPR';
import { handlePreviewCaldeo } from '../../../actions/caldeo';
import CustomLoadingButton from '../../Utils/CustomLoadingButton';
import company from '@bit/dev-lba.lib.local-globals/company';
import { C_CHA, C_PAC } from '@bit/dev-lba.lib.local-globals/comboType';

const simulatorData = {
  [C_CHA]: {
    link: 'https://pro.primesenergie.fr/client/nouveau/simulation',
    login: 'arnaud.b@lesbonsartisans.fr',
    pass: 'LBAcee'
  },
  [C_PAC]: {
    link: 'https://simulateur.lenergietoutcompris.fr/installateurs',
    login: 'Installateur',
    pass: 'Prime2021!'
  },
};

const Components = ({
  tabValue,
  setData,
  paths,
  merge,
  errors,
  parentState,
  user,
  setLoading,
}) =>
  [
    <CaldeoData
      merge={merge}
      paths={paths}
      setData={setData}
      errors={errors}
      user={user}
      {...parentState}
    />,
    <Domofinance
      merge={merge}
      setData={setData}
      errors={errors}
      paths={paths}
      user={user}
      isCaldeo
    />,
    <InfoCombo merge={merge} setData={setData} paths={paths} />,
    <CaldeoMPR
      merge={merge}
      setData={setData}
      setLoading={setLoading}
      {...parentState}
    />,
  ][tabValue];

const SimulatorLoginGrid = ({ comboType }) => comboType ?
  <Grid component={Paper} item xs={12} sm={12}>
    <Grid container spacing={1} justify="space-around">
      <Grid item xs={12}>
        <Typography variant="body1" align="center">
          {
            '⚠️ Attention ⚠️: Voici Les identifiants ' +
              'afin de pouvoir faire la simulation caldeo'
          }
        </Typography>
      </Grid>
      <Grid item xs={comboType === C_PAC ? 4 : 6}>
        <Typography variant="body1">
            Identifiant : {(simulatorData[comboType] || {}).login}
        </Typography>
        <Typography variant="caption">
            (Respecter les minuscules et les majuscules)
        </Typography>
      </Grid>
      <Grid item xs={comboType === C_PAC ? 4 : 6}>
        <Typography variant="body1">
            Mot de passe: {(simulatorData[comboType] || {}).pass}
        </Typography>
        <Typography variant="caption">
            (Respecter les minuscules et les majuscules)
        </Typography>
      </Grid>
      <Grid
        item
        xs={comboType === C_PAC ? 4 : 12}
        align={comboType === C_PAC ? 'left' : 'center'}
      >
        <Typography
          variant={comboType === C_PAC ? 'body1' : 'caption'}
        >
          {comboType === C_PAC ? `Siret: ${company.siret}` :
            'Après connexion veuillez cliquer sur ' +
            '"OUVRIR UN NOUVEAU DOSSIER"'
          }
        </Typography>
      </Grid>
    </Grid>
  </Grid> : null;

const handlePreview = (merge, choosenChaudiere, setLoadingPreview) => {
  setLoadingPreview(true);
  handlePreviewCaldeo(merge, choosenChaudiere)
    .finally(() => setLoadingPreview(false));
};

const Content = ({
  setData,
  tabValue,
  handleTabChange,
  paths,
  merge,
  errors,
  user,
  parentState = {},
  setLoading,
  setLoadingPreview,
  previewLoading,
  type
}) => {
  const recAccess = permit(user, { key: 'caldeoSpecial' });
  const comboType = merge.getIn(['combination', 'comboType']);
  return (
    <div>
      <DialogTabs
        errors={errors}
        user={user}
        tabValue={tabValue}
        id={merge.get('id')}
        handleTabChange={handleTabChange}
      />
      <Grid container spacing={2}>
        <Components
          errors={errors}
          paths={paths}
          tabValue={tabValue}
          setData={setData}
          merge={merge}
          parentState={parentState}
          user={user}
          setLoading={setLoading}
        />
        <Grid item xs={12} sm={comboType !== C_PAC || recAccess ? 12 : 6}>
          {tabValue !== 3 && <CustomLoadingButton
            fullWidth
            loading={previewLoading}
            color="secondary"
            onClick={() =>
              handlePreview(
                merge,
                parentState.choosenChaudiere,
                setLoadingPreview
              )
            }
            variant="contained"
            children="visualiser le devis"
            disabled={type === 'devis' && !merge.get('id')}
          />}
        </Grid>
        {tabValue === 0 && (
          <>
            <Grid item xs={12} sm={6}>
              <Link
                underline="none"
                target="_blank"
                rel="noreferrer"
                href={(simulatorData[comboType] || {}).link}
              >
                <Button
                  fullWidth
                  color="primary"
                  variant="outlined"
                  children={comboType === C_PAC ?
                    'Simulateur des aides' :
                    'Simulateur CEE'}
                />
              </Link>
            </Grid>
            {comboType !== C_PAC || recAccess ?
              <Grid item xs={12} sm={6}>
                <Link
                  underline="none"
                  target="_blank"
                  rel="noreferrer"
                  href={comboType !== C_PAC ?
                    'https://www.garanka.fr/simulateur-aides-financieres' :
                    'https://www.maprimerenov.gouv.fr/prweb/app/default/H9DF1ufnPCNDOGG8PFgaaW3tLvvaZHE9*/!STANDARD'
                  }
                >
                  <Button
                    fullWidth
                    color="secondary"
                    variant="outlined"
                    children={comboType !== C_PAC ?
                      'Simulateur MaPrimeRénov' : 'Ma prime rénov'}
                  />
                </Link>
              </Grid> : null}
            <SimulatorLoginGrid comboType={comboType} />
          </>
        )}
      </Grid>
    </div>
  );
};

export default Content;
