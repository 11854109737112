import React, { PureComponent } from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

class Footer extends PureComponent {
  render() {
    const { send, cancel, addFile, loading } = this.props;
    return (
      <DialogActions>
        <input
          key={0}
          type="file"
          style={{ display: 'none' }}
          id="CloudUpload"
          onChange={addFile}>
        </input>
        <label key={1} htmlFor="CloudUpload">
          <Button color="default" component="span">
            Ajouter fichier
          </Button>
        </label>
        <Button onClick={cancel} color="secondary">
            Annuler
        </Button>
        <Button
          disabled={loading}
          onClick={send} color="primary">
          {loading && <CircularProgress
            size={24}
          />}
            Valider
        </Button>
      </DialogActions>
    );
  }
}

export default Footer;
