import {
  S_PART,
  S_INT,
  S_JUR,
  S_INFO,
  S_DEVIS,
  S_BTOB,
  S_SATCL,
  S_LEAD,
} from '@bit/dev-lba.lib.local-globals/services';
import Immutable from 'immutable';
import { permit } from '@bit/dev-lba.lib.local-globals/restrictions';
import { red } from '@material-ui/core/colors';
import notifSystem from '../../notifSystem';
import { aVerifierPoint } from '../../utils/pointsFunctions';

export default [
  {
    name: 'Ajout d\'une intervention',
    link: '/intervention',
    customVisible: (props) => {
      const user = props.users.find((e) => e._id === props.userId);
      return (
        Immutable.List.isList(props.users) &&
        user &&
        [S_SATCL, S_INT, S_PART, S_INFO, S_BTOB, S_DEVIS, S_LEAD].includes(
          user.service
        )
      );
    },
    props: () => {
      const { point, condition } = aVerifierPoint();

      return condition
        ? {
          style: { backgroundColor: red[500], color: '#fff' },
          disabled: true,
          onClick: (e) => {
            e.preventDefault();
            notifSystem.warning(
              'Attention',
              `Vous avez ${point} points de retard (dossiers à vérifier)`
            );
          },
        }
        : {};
    },
  },
  {
    name: 'Ajout d\'un artisan',
    link: '/artisan',
    customVisible: (props) =>
      Immutable.List.isList(props.users) &&
      [S_INFO, S_PART, S_BTOB].includes(
        props.users.find((e) => e._id === props.userId).service
      ),
  },
  {
    name: 'Ajout d\'un candidat',
    link: '/candidat',
    customVisible: (props) =>
      Immutable.List.isList(props.users) &&
      [S_INFO, S_PART, S_DEVIS].includes(
        props.users.find((e) => e._id === props.userId).service
      ),
  },
  {
    name: 'Ajout d\'un devis',
    link: '/devis',
    customVisible: (props) =>
      Immutable.List.isList(props.users) &&
      [S_SATCL, S_PART, S_INT, S_DEVIS, S_INFO, S_BTOB, S_LEAD].includes(
        props.users.find((e) => e._id === props.userId).service
      ),
  },
  {
    name: 'Ajout d\'un BtoB',
    link: '/btob',
    customVisible: (props) =>
      Immutable.List.isList(props.users) &&
      [S_INFO, S_BTOB].includes(
        props.users.find((e) => e._id === props.userId).service
      ),
  },
  {
    name: 'Ajout d\'un litige',
    link: '/litige',
    customVisible: (props) =>
      Immutable.List.isList(props.users) &&
      [S_SATCL, S_JUR, S_INFO].includes(
        props.users.find((e) => e._id === props.userId).service
      ),
  },
  {
    name: 'Ajout d\'un recouvrement Client',
    link: '/recouvrementClient',
    customVisible: (props) =>
      Immutable.List.isList(props.users) &&
      [S_JUR, S_INFO].includes(
        props.users.find((e) => e._id === props.userId).service
      ),
  },
  {
    name: 'Ajout d\'un recouvrement Btob',
    link: '/recouvrementBtob',
    customVisible: (props) =>
      Immutable.List.isList(props.users) &&
      [S_JUR, S_INFO].includes(
        props.users.find((e) => e._id === props.userId).service
      ),
  },
  {
    name: 'Ajout d\'une demande',
    link: '/demandes',
    customVisible: (props) =>
      !permit(
        props.users.find((u) => u._id === props.userId),
        { key: 'addDemandeButton' }
      ),
  },
  {
    name: 'Mes Signalements',
    link: '/signalements',
    counterName: 'signalements.ns',
    customVisible: (props) =>
      Immutable.List.isList(props.users) &&
      permit(
        props.users.find((e) => e._id === props.userId),
        {
          key: 'displaySignalementButton',
        }
      ),
  },
  {
    name: 'Demander une photo',
    link: '/demandePhoto',
    customVisible: (props) =>
      Immutable.List.isList(props.users) &&
      [S_INT, S_DEVIS, S_LEAD].includes(
        props.users.find((e) => e._id === props.userId).service
      ),
  },
];
