import React, { Component } from 'react';
import { connect } from 'react-redux';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import LinearProgress from '@material-ui/core/LinearProgress';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import { getAllSignalements } from '../../actions/signalements';
import TabButton from './TabButton';
import SignalementsList from '../../components/Signalements/List';
import {
  baseFilter,
  baseFields,
  reformatFilter,
  generateTabs
} from '../../components/Signalements/utils';

import ws from '../../ws';
import notifSystem from '../../notifSystem';
import StatsPanel from '../../components/Stats/common/panel/StatsPanel';
import { Undo } from '@material-ui/icons';
import { Fab, Paper } from '@material-ui/core';

const styles = {
  card: {
    overflowX: 'auto',
  },
  headline: {
    fontWeigth: 'bold',
    fontSize: 20
  },
  cardContent: {
    marginTop: 5,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
};

const signalementsNotif = {
  notRetrieved: () => notifSystem.error(
    'Signalement',
    'Les signalements n\'ont pas été récupérés'
  )
};

const tabs = generateTabs();

const mapStateToProps = ({ users, userId }) => ({ users, userId });

class ReportPage extends Component {
  state = {
    loading: false,
    selectedTab: 1,
    count: 0,
    resources: [],
    page: 0,
    rowsPerPage: this.props.rowsPerPage || 100,
    filter: { ...baseFilter, ...(this.props.filter || {}) },
    fields: baseFields,
    sortBy: 'date.creation',
    ascOrder: false,
    currentRow: '',
    selectedUser: this.props.userId,
    allUsers: false
  }

  componentDidMount() {
    this.loadSignalements();
    ws.on('update_signalements', this.loadSignalements);
  }

  componentWillUnmount() {
    ws.removeEventListener('update_signalements');
  }

  loadSignalements = () => {
    const {
      selectedTab,
      filter,
      page,
      rowsPerPage,
      sortBy,
      ascOrder,
      selectedUser,
      allUsers
    } = this.state;
    const tab = tabs.find(t => t.id === selectedTab);
    this.setState({ loading: true });
    getAllSignalements(this.props.filter || {
      [tab.group]: allUsers ? undefined : [selectedUser],
      ...tab.match,
      ...reformatFilter(filter, tab.date)
    }, {
      page,
      rowsPerPage,
      sortBy,
      order: ascOrder ? 1 : -1
    }).then(({ result, count }) => this.setState(({ currentRow }) => ({
      resources: result,
      count,
      currentRow: result.some(r => r._id === currentRow) ? currentRow : '',
      loading: false
    }))).catch(() => signalementsNotif.notRetrieved());
  }

  loadSignalement = (id = this.state.currentRow) => {
    getAllSignalements({
      _id: id
    }).then(({ result }) => {
      const resources = JSON.parse(JSON.stringify(this.state.resources));
      const index = resources.findIndex(r => r._id === id);

      if (!~index) {
        return;
      }
      resources[index] = result[0];
      this.setState({
        resources
      });
    });
  }

  handleClickRow = id =>
    this.setState(({ currentRow }) => ({
      currentRow: currentRow === id ? '' : id
    }))

  handleChange = field => value => {
    const update = { [field]: value };

    if (field === 'selectedUser') {
      update.allUsers = value === 'all';
    }
    this.setState(update, this.loadSignalements);
  }

  handleChangeFilter = field => (value, callback) => {
    this.setState(({ filter }) => ({
      filter: {
        ...filter,
        [field]: value
      }
    }), typeof callback === 'function' ? callback : f => f);
    return true;
  }

  handleChangeField = field => (value, callback) => {
    this.setState(({ fields }) => ({
      fields: {
        ...fields,
        [field]: value
      }
    }), typeof callback === 'function' ? callback : f => f);
    return true;
  }

  handleChangeDates = (from, to) =>
    this.setState(({ filter }) => ({
      filter: {
        ...filter,
        date: {
          from: from || filter.from,
          to: to || filter.to
        }
      }
    }), this.loadSignalements)

  handleChangeSortBy = key =>
    this.setState(({ sortBy, ascOrder }) => ({
      sortBy: key,
      ascOrder: key === sortBy ? !ascOrder : true
    }), this.loadSignalements)

  restFilter = () =>
    this.setState({
      filter: baseFilter,
      currentRow: ''
    }, this.loadSignalements)

  render() {
    const {
      loading,
      selectedTab,
      resources,
      count,
      page,
      rowsPerPage,
      filter,
      fields,
      sortBy,
      ascOrder,
      currentRow,
      selectedUser
    } = this.state;
    const {
      users,
      disableTop
    } = this.props;

    const list =
    <Card style={styles.card}>
      {loading ?
        <LinearProgress style={{ height: 3 }} /> :
        <div style={{ height: 3 }} />
      }
      {!disableTop && <CardContent style={styles.cardContent}>
        <Select
          value={selectedUser}
          onChange={e =>
            this.handleChange('selectedUser')(e.target.value)
          }
          style={{ marginRight: 5 }}
        >
          <MenuItem key={'all'} value={'all'}>
          Tous
          </MenuItem>
          {users.map(d => d.act ? (
            <MenuItem key={d._id} value={d._id}>
              {d.login}
            </MenuItem>
          ) : null)}
        </Select>
        <Fab color="primary" onClick={this.restFilter}>
          <Undo />
        </Fab>
      </CardContent>}
      <CardContent>
        <SignalementsList
          reports={resources}
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          filter={filter}
          fields={fields}
          sortBy={sortBy}
          ascOrder={ascOrder}
          currentRow={currentRow}
          launchRequest={this.loadSignalements}
          reloadCurrent={this.loadSignalement}
          handleChangeFilter={this.handleChangeFilter}
          handleChangeField={this.handleChangeField}
          handleChangeSortBy={this.handleChangeSortBy}
          handleChangeRowsPerPage={this.handleChange('rowsPerPage')}
          handleChangePage={this.handleChange('page')}
          handleClickRow={this.handleClickRow}
        />
      </CardContent>
    </Card>;

    return (
      <Paper>
        {!disableTop ?
          <StatsPanel
            range
            defaultState={{
              mode: 'day',
              from: filter.date.from,
              to: filter.date.to
            }}
            onDatesChange={this.handleChangeDates}
            title="Liste des signalements"
            body={
              <React.Fragment>
                {tabs.map(e =>
                  <TabButton
                    {...e}
                    userId={selectedUser}
                    key={e.id}
                    selected={selectedTab === e.id}
                    handleChangeSelectedTab={this.handleChange('selectedTab')}
                  />
                )}
              </React.Fragment>}
            enabledModes={[true, true, true, true]}
            children={list}
          /> : list}
      </Paper>
    );
  }
}

export default connect(mapStateToProps)(ReportPage);
