import React from 'react';
import { Hidden, Grid, MenuItem } from '@material-ui/core';

import {
  data as chauffageType
} from '@bit/dev-lba.lib.local-globals/chauffageType';
import { moment } from '@bit/dev-lba.lib.local-globals/moment';

import { permit } from '@bit/dev-lba.lib.local-globals/restrictions';
import { C_CHA, C_PAC } from '@bit/dev-lba.lib.local-globals/comboType';

import CustomTextField from '../../CustomInputs/CustomTextField';
import CustomSelect from '../../CustomInputs/CustomSelect';
import MoneyInput from '../../CustomInputs/MoneyInput';
import DatePicker from '../../DatePicker';

const helpers = {
  [C_CHA]: {
    cee: '',
    mpr: 'Montant compris entre 650 € et 1 500 €',
  },
  [C_PAC]: {
    cee: '',
    mpr: 'Montant compris entre 1 500 € et 5 000 €',
  },
};

const renderList = t => t.map(v =>
  <MenuItem
    key={v._id}
    value={v._id.toString()}
    children={v.model ? [v.name, v.model].join(' - ') : v.name}
  />);
const items = t => t.map((v, i) =>
  <MenuItem disabled={i === 0} key={v} value={v} children={v} />);

const CaldeoData = ({
  choosenChaudiere = {}, setData,
  merge, chaudiereList = [],
  paths, chaudiereType, errors,
  user
}) => {
  const helperObj = helpers[merge.getIn(paths.comboType, C_CHA)] || {};
  return <>
    {permit(user, { key: 'caldeoSpecial' } ) ? <>
    <Grid item xs={6}>
      <DatePicker
        before={true}
        label="Date du devis"
        startDate={moment(merge.getIn(paths.devisDate,
          merge.getIn(['date', 'envoi']) ||
          merge.getIn(['date', 'ajout']))).toDate()}
        onChange={(v) => setData(paths.devisDate, v.toDate())}
      />
    </Grid>
    <Grid item xs={6}>
      <DatePicker
        before={true}
        label="Date de la facture"
        startDate={moment(merge.getIn(paths.factureDate, moment(
          merge.getIn(paths.devisDate) ||
          merge.getIn(['date', 'envoi']) ||
          merge.getIn(['date', 'ajout'])).add(3, 'days'))).toDate()}
        onChange={(v) => setData(paths.factureDate, v.toDate())}
      />
    </Grid>
    </> : null}
    <CustomSelect
      grid
      xs={12}
      sm={6}
      label='Energie actuelle'
      value={chaudiereType || ' '}
      path={paths.chaudiereType}
      setData={setData}
      children={renderList(chauffageType)}
    />
    <CustomSelect
      grid
      xs={12}
      sm={6}
      label='Chaudière actuelle'
      value={choosenChaudiere._id || ' '}
      path={paths.oldChaudiere}
      error={!!errors.find(({ path, error }) =>
        path[1] && path[1].includes(paths.oldChaudiere[1]) &&
        error)}
      setData={setData}
      children={renderList(chaudiereList)}
    />
    <CustomSelect
      grid
      xs={12}
      sm={6}
      label='Type de logement'
      value={merge.get('houseType', ' ')}
      path={paths.houseType}
      setData={setData}
      error={!!errors.find(({ path, error }) =>
        path.includes(paths.houseType[0]) &&
        error)}
      children={items(['Type de logement', 'Maison', 'Appartement'])}
    />
    <CustomTextField
      grid
      xs={12}
      sm={6}
      label="Nb de personne à charge"
      value={merge.get('dependent', '')}
      path={paths.dependent}
      type="number"
      error={!!errors.find(({ path, error }) =>
        path.includes(paths.dependent[0]) &&
        error)}
      setData={setData}
    />
    <CustomSelect
      grid
      xs={12}
      sm={6}
      label='Status du ménage'
      value={merge.get('householdStatus', ' ')}
      path={paths.householdStatus}
      setData={setData}
      error={!!errors.find(({ path, error }) =>
        path.includes(paths.householdStatus[0]) &&
      error)}
      children={items(
        ['Status du ménage', 'Très modeste', 'Modeste', 'Autre'], true)}
    />
    <CustomTextField
      grid
      xs={12}
      sm={6}
      label="Surface en m²"
      value={merge.get('surface', '')}
      path={paths.surface}
      type="number"
      error={!!errors.find(({ path, error }) =>
        path.includes(paths.surface[0]) &&
        error)}
      setData={setData}
    />
    <CustomTextField
      grid
      xs={12}
      sm={6}
      label="nombre de pièce"
      value={merge.get('nbPiece', '')}
      path={paths.nbPiece}
      type="number"
      error={!!errors.find(({ path, error }) =>
        path.includes(paths.nbPiece[0]) &&
        error)}
      setData={setData}
    />
    <Hidden xsDown>
      <Grid item sm={6} children='&nbsp;' />
    </Hidden>
    <MoneyInput grid xs={12} sm={6}
      label="Montant des CEE"
      defaultValue={merge.get('ceeAmount', null)}
      path={paths.ceeAmount}
      error={!!errors.find(({ path, error }) =>
        path.includes(paths.ceeAmount[0]) &&
        error)}
      helperText={helperObj.cee || ''}
      setData={setData}
      requiredText={true}
    />
    <MoneyInput grid xs={12} sm={6}
      label="Montant Ma Prime rénov"
      defaultValue={merge.get('anahAmount', '')}
      path={paths.anahAmount}
      helperText={helperObj.mpr || ''}
      setData={setData}
      requiredText={true}
    />
  </>;
};

export default CaldeoData;
