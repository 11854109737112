import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import List from '@material-ui/core/List';
import DisplaySignalements, { GenCommentItem } from './DisplaySignalements';
import { withStyles } from '@material-ui/core/styles';
import { withExpansionPanel } from '../../../hoc';

const styles = {
  commentList: {
    maxHeight: 235,
    overflow: 'auto'
  }
};

const ExpansDisplaySignalements = withExpansionPanel(DisplaySignalements);
const ExpansComments = withExpansionPanel(
  withStyles(styles)(({ classes, comments }) =>
    <List className={classes.commentList}>
      {comments.map(({ commentValue, userId, date }) =>
        <GenCommentItem
          comment={commentValue}
          user={userId}
          date={date}
        />)}
    </List>));


const DialogInfoCompta = ({ callback, open, defaultText }) => {
  const { comments, signalements } = defaultText;
  return (
    <Dialog
      onEscapeKeyDown={callback}
      disableBackdropClick
      open={open}
      maxWidth="md"
    >
      <DialogTitle>Informations comptabilité</DialogTitle>
      <DialogContent>
        {comments && comments.length ?
          <ExpansComments
            expanded
            comments={comments}
            expansionTitle="Commentaires"
          /> : ''}
        {signalements && signalements.length ?
          <ExpansDisplaySignalements
            expansionTitle="Signalements"
            expanded
            signalements={signalements} /> : ''}
      </DialogContent>
      <DialogActions>
        <Button onClick={callback} color="primary">
        Vu
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(DialogInfoCompta);
