import React from 'react';
import { connect } from 'react-redux';

import { Input } from '@material-ui/core';

import Attachments from '../Utils/Attachments';
import { setDialogContentProp } from '../../actions/dialog';

const setAttachments = setDialogContentProp('attachments');
const setText = setDialogContentProp('text');

const mapStateToProps = ({
  dialog: { contentProps: {
    attachments = [],
    displayBody,
  } }
}) => ({
  attachments,
  displayBody
});

const mapDispatchToProps = {
  setText: e => setText(e.target.value),
  addFile: (f, files) => setAttachments(files),
  deleteFile: (f, i, files) => setAttachments(files)
};

const PreviewMailDialog = ({
  attachments,
  setText,
  addFile,
  deleteFile,
  displayBody = true
}) =>
  <div>
    {displayBody && <Input onChange={setText} />}
    <Attachments
      data={attachments}
      onAdd={addFile}
      onDelete={deleteFile}
    />
  </div>;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PreviewMailDialog);
