import React, { Component } from 'react';

import { compose } from 'redux';

import { Paper, Button, Chip, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import SearchBtoB from '../../components/BillingInformation/SearchBtoB';

const styles = {
  header: {
    marginBottom: 20,
    padding: 20,
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center'
  },
  name: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 500,
    margin: '0 20px',
  },
  container: {
    height: 'initial',
  },
  searchInput: {
    float: 'none',
    maxWidth: '99%',
  },
  suggestionsContainerOpen: {
    left: 0,
  },
};

const mapStateToProps = ({ grandCompte }) => ({
  grandCompte,
});

const withSelectNextPrev = (filterB2B) => (WrappedComponent) =>
  class extends Component {
    state = {
      index: -1,
      grandCompte: this.props.grandCompte.sort((a, b) =>
        b.get('companyName') < a.get('companyName') ? 1 : -1
      )
    };

    incrementSelect = (num) => {
      this.setState((prevState) => ({
        index: prevState.index + num,
      }));
    };

    setGrandCompte = (selected) => {
      const index = this.state.grandCompte.findIndex(
        (e) => e.get('_id') === selected.get('_id')
      );
      this.setState({ index });
    };

    render() {
      const { classes, ...otherProps } = this.props;
      const { index, grandCompte } = this.state;

      const selected = index !== -1 &&
        grandCompte.get(index % grandCompte.size);
      const name = selected ? selected.get('companyName') : '';
      return (
        <React.Fragment>
          <Paper className={classes.header}>
            <Grid container spacing={2} alignItems='center'>
              <Grid item xs={12}>
                {name && (
                  <Chip
                    label={name}
                    onDelete={() => this.setGrandCompte(new Map())}
                    variant="outlined"
                  />
                )}
              </Grid>
              <Grid item xs>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => this.incrementSelect(-1)}
                >
                  {'<'}
                </Button>
              </Grid>
              <Grid item xs={10}>
                <SearchBtoB
                  classes={{
                    suggestionsContainerOpen: classes.suggestionsContainerOpen,
                    searchInput: classes.searchInput,
                    container: classes.container,
                  }}
                  filterB2B={filterB2B && ((e) => filterB2B(e, this.props))}
                  setGrandCompte={this.setGrandCompte}
                />
              </Grid>
              <Grid item xs>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => this.incrementSelect(1)}
                >
                  {'>'}
                </Button>
              </Grid>
            </Grid>
          </Paper>
          <WrappedComponent
            {...otherProps}
            selected={selected ? selected.toJS() : null}
          />
        </React.Fragment>
      );
    }
  };

export default (filterB2B) =>
  compose(
    connect(mapStateToProps),
    withStyles(styles),
    withSelectNextPrev(filterB2B)
  );
