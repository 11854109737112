import {
  List
} from 'immutable';
import {
  SOUSMETIERS_FETCH
} from '../constants/ActionTypes';

const initialState = new List();
export default (state = initialState, action) => {
  switch (action.type) {
    case SOUSMETIERS_FETCH: {
      return action.payload;
    }
    default:
      return state;
  }
};
