import React from 'react';
import { connect } from 'react-redux';
import {
  Typography,
  Grid,
  Card,
} from '@material-ui/core';
import { blue, red, green } from '@material-ui/core/colors';
import {
  PersonAddDisabled,
  ContactPhone,
  AssignmentReturned,
} from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { getUserTeam } from '../../utils/teams';

const styles = () => ({
  title: {
    fontSize: 17,
    fontWeight: 'bold',
  },
  paper: {
    textAlign: 'center',
    padding: '1%',
    margin: 'auto',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  number: {
    fontSize: 17,
  },
  gridCards: { height: '100% !important' },
});
const mapStateToProps = ({ userId, users, counter }) => ({
  user: getUserTeam(users, userId),
  counter,
});

const data = ({ user }) => [
  {
    Icon: (props) => <PersonAddDisabled {...props} />,
    color: red[500],
    name: 'Artisans en quarantaine',
    counter: `artisans.${user ? `qua_${user._id}` : 'qua'}`,
    link: `/artisan/list/${user ? `qua_${user._id}` : 'qua'}`,
  },
  {
    Icon: (props) => <ContactPhone {...props} />,
    color: blue[500],
    name: 'Débriefs en attente',
    counter: `artisans.${user ? `debr_${user._id}` : 'debrGeneral'}`,
    link: `/artisan/list/${user ? `debr_${user._id}` : 'debr'}`,
  },
  {
    Icon: (props) => <AssignmentReturned {...props} />,
    color: green[500],
    name: 'Envoyées',
    counter: `interventions.${user ? `paenv_${user._id}` : 'paenvGeneral'}`,
    link: `/intervention/list/${
      user ? `paenv_${user._id}` : 'paenvGeneral'
    }`,
  },
];


const CardsPartenariat = ({ classes, counter: counters, ...props }) => (
  <Grid
    container
    spacing={2}
    justify="space-around"
    className={classes.gridCards}
  >
    {data(props).map(({ Icon, name, counter, color, link }) => (
      <Grid item xs={4}>
        <Card
          className={classes.paper}
          style={{
            borderTop: `4px solid ${color}`,
          }}
          elevation={4}
          onClick={() => window.open(link)}
        >
          <Grid container spacing={1} justify="center" alignItems="center">
            <Grid item xs={6}>
              <Typography variant={'caption'} className={classes.title}>
                {name}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle2" className={classes.number}>
                {counters[counter] || 0}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Icon style={{ color }} />
            </Grid>
          </Grid>
        </Card>
      </Grid>
    ))}
  </Grid>
);


export default connect(mapStateToProps)(withStyles(styles)(CardsPartenariat));
