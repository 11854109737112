import React from 'react';
import { PropTypes } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

const styles = (theme) => ({
  root: {
    ...theme.mixins.gutters(),
    padding: theme.spacing(6),
    height: 150,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    cursor: 'grab'
  },
  textLimit: {
    width: '100%',
    color: '#ffffff'
  }
});

const CardItems = ({ display, name, path, array, classes, setData }) =>
  array.map((item, i) => (
    <Grid
      key={i}
      item
      xs={12}
      sm={6}
      md={4}
      lg={3}
      onClick={() => setData(path, item[name])}
    >
      <Paper
        className={classes.root}
        style={{ backgroundColor: item.color ? item.color[500] : blue[500] }}
        elevation={4}
      >
        <Typography
          className={classes.textLimit}
          variant="h4"
          component="h4"
          children={item[display]}
        />
      </Paper>
    </Grid>
  ));

CardItems.propTypes = {
  setData: PropTypes.func.isRequired,
  display: PropTypes.string.isRequired,
  path: PropTypes.string,
  array: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(CardItems);
