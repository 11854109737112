export default {
  artisans: 'artisans',
  appels: 'appels',
  authorizedIps: 'authorizedIps',
  combos: 'combos',
  companies: 'companies',
  devis: 'devis',
  interventions: 'interventions',
  mail: 'mail',
  login: 'login',
  tryAddInters: 'tryAddInters',
  signals: 'signals',
  siret: 'siret',
  sms: 'sms',
  users: 'users',
  cities: 'cities',
  signalements: 'signalements',
  products: 'products',
  supplies: 'supplies',
  counter: 'counter',
  arcep: 'arcep',
  dropbox: 'dropbox',
  absences: 'absences',
  btobs: 'btobs',
  history: 'history',
  attendance: 'attendance',
  recouvrements: 'recouvrements',
  stats: 'stats',
  equipes: 'equipes',
  reglements: 'reglements',
  paiements: 'paiements',
  encaissements: 'encaissements',
  suggestions: 'suggestions',
  contrat: 'contrat',
  notifications: 'notifications',
  communication: 'communication',
  records: 'records',
  transformCall: 'transformCall',
  services: 'services',
  billings: 'billings',
  combinations: 'combinations',
  sousMetiers: 'sousMetiers',
  savInterventions: 'savInterventions',
  annulations: 'annulations',
  propositions: 'propositions',
  fournisseures: 'fournisseures',
  points: 'points',
  prosp: 'prosp',
  grandCompte: 'grandCompte',
  candidats: 'candidats',
  config: 'config',
  configFournisseurs: 'configFournisseurs',
  publicites: 'publicites',
  map: 'map',
  invoices: 'invoices',
  modifications: 'modifications',
  artisanConnection: 'artisanConnection',
  oldChaudiere: 'oldChaudiere',
  demandes: 'demandes',
  confCand: 'confCand',
  confProsp: 'confProsp',
  campagnes: 'campagnes',
  raisonsArchiveSst: 'raisonsArchiveSst',
  usersConnexions: 'usersConnexions',
  paymentsAnnexe: 'paymentsAnnexe',
  transactions: 'transactions',
  emails: 'emails',
  achats: 'achats',
  elasticsearch: 'elasticsearch',
  callLog: 'callLog',
  mailReceived: 'mailReceived',
  suppliesInvoices: 'suppliesInvoices',
  metiersSteps: 'metiersSteps',
};
