import React from 'react';
import InterMenu from '../List/Intervention/InterMenu';
import { connect } from 'react-redux';
import { setDialogContentProp } from '../../actions/dialog';
import collections from '../../constants/collections';

const setProposition = setDialogContentProp('proposition');

const mapStateToProps = ({
  dialog: {
    contentProps: { inter = {} },
  },
}) => ({
  inter,
});

const mapDispatchToProps = {
  setProposition
};

const DialogProposition = ({ inter, setProposition }) => (
  <InterMenu
    name={collections.interventions}
    elem={inter}
    showProposition={true}
    setProposition={setProposition}
  />
);

export default connect(mapStateToProps, mapDispatchToProps)(DialogProposition);
