import React, { PureComponent } from 'react';
import {
  CardContent,
  Chip, Paper,
  Grid, TextField,
  Typography, Button
} from '@material-ui/core';
import { setDialogContentProp } from '../../actions/dialog';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import {
  getSize, isMaxFileOk, isMaxSizeOk, MAX_FILES, MAX_FILES_SIZE
} from '../../utils/function';
import { attachFile } from '@bit/dev-lba.lib.local-globals/utils';

const setDestinataires = setDialogContentProp('destinataires');
const setObjMail = setDialogContentProp('objMail');
const setTitle = setDialogContentProp('title');
const setMessage = setDialogContentProp('message');
const setDesc = setDialogContentProp('desc');
const setObservation = setDialogContentProp('observation');
const setConclusions = setDialogContentProp('conclusions');
const setFiles = setDialogContentProp('files');

const mapStateToProps = ({
  dialog: {
    contentProps: {
      files,
      isFileOk,
      isSizeOk,
      maxFile,
      maxSize,
      totalSize,
      destinataires,
      title,
      objMail,
      message,
      desc,
      observation,
      conclusions,
    },
  },
}) => ({
  files,
  isFileOk,
  isSizeOk,
  maxFile,
  maxSize,
  totalSize,
  destinataires,
  title,
  objMail,
  message,
  desc,
  observation,
  conclusions,
});

const mapDispatchToProps = {
  setDestinataires,
  setObjMail,
  setTitle,
  setMessage,
  setDesc,
  setObservation,
  setConclusions,
  setFiles
};

class AttestationSavDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.addFile = this.addFile.bind(this);
    this.state = {
      totalSize: 0,
      maxSize: MAX_FILES_SIZE,
      maxFile: MAX_FILES,
      isSizeOk: true,
      isFileOk: true,
    };
  }

  addFile(e) {
    attachFile(e.target.files[0])
      .then(attachment => {
        if (attachment.type.includes('image')) {
          const ret = this.props.files.concat(attachment);
          this.setState(
            {
              totalSize: getSize(ret),
              isFileOk: isMaxFileOk(ret.length, this.state.maxFile),
              isSizeOk: isMaxSizeOk(ret, this.state.maxSize)
            },
            () => this.props.setFiles(ret)
          );
        }
      });
  }

  handleChipDelete(index) {
    this.props.files.splice(index, 1);
    const ret = this.props.files.slice(0);
    const tmpSize = getSize(ret);
    this.setState(
      {
        totalSize: tmpSize,
        isFileOk: isMaxFileOk(ret.length, this.state.maxFile),
        isSizeOk: isMaxSizeOk(ret, this.state.maxSize)
      },
      () => this.props.setFiles(ret)
    );
  }

  render() {
    const {
      files,
      destinataires,
      title,
      objMail,
      message,
      desc,
      observation,
      conclusions,
      setDestinataires,
      setObjMail,
      setTitle,
      setMessage,
      setDesc,
      setObservation,
      setConclusions,
    } = this.props;
    const { isFileOk, isSizeOk, maxFile, maxSize, totalSize } = this.state;

    const attachments = 'Pièces jointes';

    const up = isFileOk ? (
      <Typography variant="caption">
        {attachments}: {files.length}/{maxFile}</Typography>)
      : (<Typography variant="caption" style={{ color: 'red' }}>
        {attachments}: {files.length}/{maxFile}</Typography>);

    const down = isSizeOk ? (
      <Typography variant="caption">{totalSize}/{maxSize}</Typography>)
      : (
        <Typography variant="caption" style={{ color: 'red' }}>
          {totalSize}/{maxSize}
        </Typography>
      );

    return (
      <Grid container spacing={1} justify="center" >
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="textarea"
            multiline
            value={destinataires}
            onContextMenu={(e) => { e.stopPropagation(); }}
            rowsMax={3}
            onChange={(e) => setDestinataires(e.target.value)}
            label="Destinataires"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="textarea"
            multiline
            value={objMail}
            onContextMenu={(e) => { e.stopPropagation(); }}
            rowsMax={3}
            onChange={(e) => setObjMail(e.target.value)}
            label="OBJET DU MAIL"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="textarea"
            multiline
            value={title}
            onContextMenu={(e) => { e.stopPropagation(); }}
            rowsMax={3}
            onChange={(e) => setTitle(e.target.value)}
            label="TITRE DU MAIL"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="textarea0"
            multiline
            value={message}
            onContextMenu={(e) => { e.stopPropagation(); }}
            rowsMax={7}
            onChange={(e) => setMessage(e.target.value)}
            label="CORPS DU MAIL"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="textarea1"
            multiline
            value={desc}
            onContextMenu={(e) => { e.stopPropagation(); }}
            rowsMax={3}
            onChange={(e) => setDesc(e.target.value)}
            label="DESCRIPTION"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="textarea2"
            multiline
            rows={5}
            rowsMax={6}
            value={observation}
            onContextMenu={(e) => { e.stopPropagation(); }}
            onChange={(e) => setObservation(e.target.value)}
            label="OBSERVATION"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="textarea3"
            multiline
            value={conclusions}
            onContextMenu={(e) => { e.stopPropagation(); }}
            rows={6}
            onChange={(e) => setConclusions(e.target.value)}
            label="CONCLUSIONS ET PRECONISATIONS"
          />
        </Grid>
        <Grid item xs={12}>
          {up}
          <CardContent>
            <Paper>
              {Object.values(files).map((element, index) => (
                <Chip
                  key={index}
                  label={element.name}
                  onDelete={() => this.handleChipDelete(index)}
                />
              ))}
            </Paper>
          </CardContent>
          <div style={{ 'float': 'right' }}>
            {down}
          </div>
        </Grid>
        <input
          key={0}
          type="file"
          accept=".png, .jpg, .jpeg"
          style={{ display: 'none' }}
          id="CloudUpload"
          onChange={this.addFile}>
        </input>
        <label key={1} htmlFor="CloudUpload">
          <Button color="default" component="span">
            Ajouter fichier
          </Button>
        </label>
      </Grid>
    );

  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(AttestationSavDialog);
