

import React from 'react';
import MaterialTable from '@material-table/core';

export default ({ columns, localization, ...props }) => (
  <MaterialTable
    columns={columns}
    localization={{
      body: {
        emptyDataSourceMessage: 'Aucunes données disponibles',
        filterRow: {
          filterTooltip: 'Filter',
        },
        editRow: {
          deleteText: 'Etes-vous sur de vouloir supprimer ?',
          cancelTooltip: 'Annuler',
          saveTooltip: 'Enregistrer',
        },
        deleteTooltip: 'Supprimer',
        editTooltip: 'Modifier',
        addTooltip: 'Ajouter',
      },
      toolbar: {
        nRowsSelected: '{0} element(s) selectionné(s)',
        searchPlaceholder: 'Rechercher',
      },
      ...localization,
    }}

    {...props}
  />
);
