import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import {
  Typography,
  Grid,
  Box,
  Button,
} from '@material-ui/core';
import {
  data as categories
} from '@bit/dev-lba.lib.local-globals/categories';

import CardItems from './CancelDialog/CardItems';
import McDoStepper from './McDoStepper';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import api from '../../api';
import { closeDialog } from '../../actions/dialog';

const CATEGORY = 1;
const SCAT = 2;
const LITEM = 3;

const styles = {
  input: {
    padding: '16px',
    paddingBottom: 0,
  },
};

const finalKeys = {
  combinations: 'isProduct',
  metiersSteps: 'isFinalStep',
};


const getData = (dataType, filter) => api[dataType].getAll({
  ...filter,
  withOutCache: true
}).then(res => res.body().map(e => e.data && e.data()))
  .then(data => data.map(e => ({
    ...e, data: e
  })));

const getLastElementId = (dataType) => getData(dataType, { limit: 1 })
  .then((e) => e[0].id)
  .catch(() => 0);

const copyElem = (dataType, data) => api[dataType].post([data]);

const duplicate = (dataType, id, newId) =>
  api[dataType].custom('duplicate')
    .post({
      id,
      newId,
    });


const mapStateToProps = ({
  dialog: {
    contentProps: { dataType, copy }
  },
}) => ({
  dataType, copy,
  finalKeyName: finalKeys[dataType]
});

const mapDispatchToProps = {
  closeDialog: closeDialog
};

const mergeProps = (state, dispatchers, ownProps) => ({
  ...state,
  ...dispatchers,
  ...ownProps,
  callback: () => {
    dispatchers.closeDialog();
  }
});

class DialogConfigurateurCopy extends Component {
  state = {
    item: {},
    data: [],
    originalData: [],
    categorie: null,
    arrayIndex: [],
    currentStep: CATEGORY,
  };

  copy = () => {
    const { dataType, copy, callback } = this.props;
    const { data, originalData, arrayIndex, categorie } = this.state;
    const refs = arrayIndex.reduce(
      (acc, v) =>
        `${acc} ${acc ? '/' : ''} ${
          (data.find((e) => e.id === v) || {}).name || ''
        }`,
      ''
    );
    const orders = originalData
      .filter(
        (e) => arrayIndex[arrayIndex.length - 1] === e.ref && 'order' in e
      )
      .sort((e, v) => e.order - v.order);
    getLastElementId(dataType).then((count) => {
      copyElem(dataType, {
        ...copy,
        categorie: categorie,
        _id: undefined,
        path: refs,
        name: `${copy.name} (copie)`,
        id: count + 1,
        modified: true,
        ref: arrayIndex.length ? arrayIndex[arrayIndex.length - 1] : null,
        order: orders.length ? orders[orders.length - 1].order + 1 : 1,
      })
        .then(() => duplicate(dataType, copy.id, count + 1))
        .then(() => callback());
    });
  };

  close = () => this.props.callback();

  generateDialogProps = step => {
    const item = this.state.item;
    switch (step) {
      case CATEGORY:
        return {
          title: 'Choisissez une catégorie',
        };
      case SCAT:
        return {
          title: 'Choisissez un sous-categorie',
        };
      case LITEM:
        return {
          title: item.name,
        };
    }
  };

  setStep = async (item, setData) => {
    const { dataType, finalKeyName } = this.props;
    const {
      currentStep, categorie, data, originalData, arrayIndex
    } = this.state;
    let newData = [...data];
    let original = [...originalData];
    if (currentStep === CATEGORY) {
      original = await getData(dataType, {
        query: JSON.stringify({ categorie: item._id }),
        field: JSON.stringify({ name: 1, id: 1, _id: 1, ref: 1, categorie: 1 })
      });
      newData = original.filter(e => !e[finalKeyName]);
    }
    const update = categorie ? {
      item, arrayIndex: arrayIndex.concat(item.id)
    } : { categorie: item._id,
      data: newData.filter(e => !e[finalKeyName]),
      originalData: original };
    this.setState(update, () => {
      newData = newData.filter(e => (e.ref ===
        (currentStep === CATEGORY ? null : item.id)) &&
        !e[finalKeyName] );
      if (newData.length) {
        this.setState({ currentStep: SCAT });
        setData(newData, SCAT);
      } else {
        this.setState({ currentStep: LITEM });
        setData(newData, LITEM);
      }
    });
  };

  generateContent = (currentData, currentStep, setData) => {
    const { categorie } = this.state;
    switch (currentStep) {
      case CATEGORY:
      case SCAT:
        return (
          <CardItems
            name="data"
            display="name"
            setData={(d, signal) => this.setStep(signal, setData)}
            array={currentData}
          />
        );
      case LITEM:
        return <Box
          width="100%"
          border="1px dashed #000"
          m={2}
          p={4}
          display='flex'
          alignItems='center'
          justifyContent='center'>
          <Typography
            variant='subtitle2'
            children={
              'Cliquer sur \'copier ici\' pour mettre dans cette categorie'
            }
          />
        </Box>;
      default:
        if (!categorie) {
          setData(
            categories.map(e => ({ ...e, id: e._id, data: e, ref: null })),
            CATEGORY);
        }
        return null;
    }
  };

  onRewind = (history) => {
    const lastElem = history[history.length - 1] || {};
    this.setState(({ categorie, arrayIndex }) => ({
      currentStep: lastElem.step || CATEGORY,
      categorie: lastElem.step === CATEGORY ? null : categorie,
      arrayIndex: arrayIndex.slice(0, -1)
    }));
  }

  render() {
    const categorie = this.state.categorie;
    return (
      <McDoStepper
        onClose={this.close}
        generateContent={(...args) => (
          <Grid container spacing={2} justify="center">
            {this.generateContent(...args)}
          </Grid>
        )}
        customHeader={() => categorie &&
          <Button
            color="primary"
            children="Copier ici"
            variant='outlined'
            onClick={() => this.copy()}
          />}
        generateDialogProps={this.generateDialogProps}
        onRewind={this.onRewind}
      />
    );
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
  withStyles(styles))(DialogConfigurateurCopy);
