import React, { PureComponent } from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

class Footer extends PureComponent {
  constructor() {
    super();
    this.state = {
      visible: true
    };
    this.valdite = this.valdite.bind(this);
  }

  valdite() {
    this.setState({ visible: true });
    this.props.send(false);
    setTimeout(() => {
      this.setState({ visible: false });
    }, 5000);
  }
  render() {
    const { cancel, loading, reset,
      addFile, attachements = false } = this.props;
    return (
      <DialogActions>
        {attachements && [<input
          key='files1'
          type="file"
          style={{ display: 'none' }}
          id="CloudUpload"
          onChange={addFile}>
        </input>,
        <label key='files2' htmlFor="CloudUpload">
          <Button color="default" component="span">
            Ajouter fichier
          </Button>
        </label>]}
        <Button
          component="span"
          disabled={loading}
          onClick={reset} color="primary">
          {loading && <CircularProgress
            size={24}
          />}
          Reset
        </Button>
        <Button onClick={cancel} color="secondary">
            Annuler
        </Button>
        <Button
          disabled={loading}
          onClick={this.valdite} color="primary">
          {loading && <CircularProgress
            size={24}
          />}
            Valider
        </Button>
      </DialogActions>
    );
  }
}

export default Footer;
