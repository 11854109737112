import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import notifSystem from '../../notifSystem';
import LineFormat, { LineTypes } from './LineFormat';
import UIList from '@material-ui/core/List';
import api from '../../api';
import { Types } from './ListInfo';

class ActivityList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activity: [],
      index: 15
    };
    this.handleSolveClick = this.handleSolveClick.bind(this);
    this.load = this.load.bind(this);
  }

  loaded = false;

  load(artisanId) {
    if (artisanId) {
      api.history.get('getActivities', { artisanId }).then(res => {
        const activities = res.body().data();
        if (activities) {
          let activity = [];
          Object.keys(activities).forEach(key => {
            activities[key].forEach(e => {
              if (key === 'signalements') {
                e.type = LineTypes.Signalement;
              } else if (key === 'absence') {
                e.type = LineTypes.Absence;
              } else if (key === 'history') {
                e.type = LineTypes.Modification;
              }
            });
            activity = activity.concat(activities[key]);
          });
          this.setState({
            activity: activity.reverse(),
          });
        }
      });
    }
  }

  async handleSolveClick(id, comment) {
    let activity = this.state.activity.slice(0);
    const report = activity[id];
    if (report.solvedDate) {
      try {
        const res = await api.signalements.patch(report._id, {
          isSolved: false,
        });
        activity[id] = res.body().data();
        activity[id].type = Types.Activities;
        this.setState({
          activity
        });
        notifSystem.warning('Attention', 'Le signalement n\'est plus résolu');
      } catch (e) {
        throw e;
      }
    }
    else if (comment.length > 0) {
      try {
        const res = await api.signalements.patch(report._id, {
          isSolved: true,
          comSolved: comment
        });
        activity[id] = { ...res.body().data(), type: Types.Activities };
        this.setState({
          activity
        });
        notifSystem.success('Opération réussie', 'Signalement résolu');
      } catch (e) {
        throw e;
      }
    }
    else {
      notifSystem.error('Erreur',
        'Veuillez entrer un commentaire de résolution');
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.markerList !== this.props.markerList ||
      nextProps.artisanId !== this.props.artisanId) {
      this.load(nextProps.artisanId);
    }
  }

  addMore = () => {
    const { index } = this.state;
    this.setState({
      index: index + 10
    });
  }

  render() {
    const { artisanId } = this.props;
    const { activity, index } = this.state;

    if (!this.loaded && artisanId) {
      this.loaded = true;
      this.load(artisanId);
      return null;
    }
    return (
      <Grid item xs={12} sm={12}>
        <UIList>
          {activity &&
            activity.map((e, id) => (
              id <= index &&
                <LineFormat
                  setComment={this.setComment}
                  type={Types.Activities}
                  handleSolveClick={this.handleSolveClick}
                  key={id}
                  id={id}
                  info={e}/>
            ))
          }
        </UIList>
        <Grid item container sm={12}>
          {activity && activity.length >= index &&
            <Button onClick={this.addMore}
              style={{ margin: 'auto' }}
              variant="outlined"
              color="primary"
              children="Voir plus">
            </Button>}
        </Grid>
      </Grid>
    );
  }
}

export default ActivityList;
