import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

export default class Dialogs extends Component {
  constructor() {
    super();
    this.state = {
      visible: false
    };
  }
  render() {
    const {
      closeDisagreeListener,
      children,
      closeAgreeListener,
      open,
      name,
      agreeText,
      disagreeText,
      maxWidth,
      fullWidth,
      loading = null
    } = this.props;
    return (
      <div>
        <Dialog
          onEscapeKeyDown={closeDisagreeListener}
          disableBackdropClick={true}
          open={open}
          maxWidth={maxWidth}
          fullWidth={fullWidth}
        >
          {name && <DialogTitle>{name}</DialogTitle>}
          <DialogContent>
            {children}
          </DialogContent>
          <DialogActions>
            {closeDisagreeListener && disagreeText &&
              <Button onClick={closeDisagreeListener}
                color="primary">
                {disagreeText}
              </Button>}
            {closeAgreeListener && <Button
              disabled={typeof loading === 'boolean' ?
                loading : this.state.visible}
              onClick={(e) => {
                closeAgreeListener(e);
                this.setState({ visible: true });
                setTimeout(() => {
                  this.setState({ visible: false });
                }, 5000);
                //To FIX
              }}
              color="primary">
              {agreeText}
            </Button>}
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

Dialogs.defaultProps = {
  agreeText: 'Valider',
  disagreeText: 'Annuler',
  maxWidth: 'sm',
  fullWidth: true
};
