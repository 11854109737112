import React, { PureComponent } from 'react';
import TextField from '@material-ui/core/TextField';
import { InsertEmoticon, Send } from '@material-ui/icons';
import Toolbar from '@material-ui/core/Toolbar';
import LinearProgress from '@material-ui/core/LinearProgress';
import IconButton from '@material-ui/core/IconButton';
import notifSystem from '../../notifSystem';
import api from '../../api';
import { connect } from 'react-redux';
import AutoSuggestInput from '../Utils/AutoSuggestInput';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { SUGG_SMS } from '@bit/dev-lba.lib.local-globals/configTypes';
import SuggestionsBar from './SuggestionsBar';

import Picker from 'emoji-picker-react';

const mapStateToProps = ( { users, config, userId } ) => ({
  user: users.find(u => u._id === userId),
  config
});

const myRef = React.createRef(null);

class SmsInput extends PureComponent {
  state = {
    input: '',
    sending: false,
    showEmoji: false,
    valueTab: 0,
    activeSmsSugestion: this.props.config.filter(u => u.type === SUGG_SMS
      && u.enable === true)
  };

  executeScroll = () => myRef && myRef.current &&
  myRef.current.scrollIntoView({ behavior: 'smooth',
    block: 'nearest', inline: 'start' })

  setInput = (e, f = f => f) => {
    this.setState({ input: e }, f);
    this.executeScroll();
  }

  onEmojiClick = (event, emojiObject) => {
    this.setState({ input: this.state.input + emojiObject.emoji });
  };

  send = () => {
    try {
      const { input } = this.state;
      const { name: collection, id, user, filters } = this.props;
      if (!input.trim()) {
        notifSystem.error('Erreur', 'Échec de l\'envoi du message');
      }
      const message = `${input}${
        user ? `\n\n${user.alias}` : ''
      }`;
      this.setState(
        {
          input: '',
          sending: true,
        },
        () => {
          api.one('sms', id)
            .custom('send')
            .post({
              message,
              collection,
              filters: JSON.stringify(filters)
            })
            .finally(() => this.setState({ sending: false }));
        }
      );
    } catch (error) {
      notifSystem.error(error.name, error.message);
    }
  }

  renderInput = (inputProps) => {
    const { classes, autoFocus, value, ref, ...other } = inputProps;

    return (
      <TextField
        autoFocus={autoFocus}
        className={classes.textField}
        value={value}
        inputRef={ref}
        InputProps={{
          classes: { input: classes.input },
          ...other,
        }}
      />
    );
  };

  renderSuggestion = (suggestion, { query, isHighlighted }) => {
    const matches = match(suggestion.text, query);
    const parts = parse(suggestion.text, matches);
    return (
      <MenuItem selected={isHighlighted} component="div">
        <div ref={myRef}>
          {parts.map((part, index) =>
            part.highlight ? (
              <span key={index} style={{ fontWeight: 300 }}>
                {part.text}
              </span>
            ) : (
              <strong key={index} style={{ fontWeight: 500 }}>
                {part.text}
              </strong>
            )
          )}
        </div>
      </MenuItem>
    );
  };

  renderSuggestionsContainer = (options) => {
    const { containerProps, children } = options;
    return (
      <Paper {...containerProps} square>
        {children}
      </Paper>
    );
  };

  getSuggestionValue = (suggestion) => {
    this.setState({ input: suggestion.text });
  };

  loadSuggestions = (v) => {
    const smsFound = this.state.activeSmsSugestion.filter(e =>
      new RegExp(v.escapeRegExp(), 'i').test(e.text));
    return JSON.parse(JSON.stringify(smsFound));
  };

  getSuggestions = async (value) => {
    const suggestions = await this.loadSuggestions(value);
    return suggestions;
  };

  handleChangeTab = (event, newValue) => {
    this.setState({ valueTab: newValue });
  };

  render() {
    const { classes, name } = this.props;
    const { showEmoji, activeSmsSugestion } = this.state;
    return [
      name === 'artisan' && (
        <SuggestionsBar
          send={this.send}
          handleOnClick={this.setInput}
          suggestionsSms={activeSmsSugestion}
        />
      ),
      <Toolbar key={1} className={classes.toolbar}>
        <Grid container>
          <Grid item xs={1}>
            <IconButton
              color="primary"
              onClick={() => this.setState({ showEmoji: !showEmoji })}
            >
              <InsertEmoticon />
            </IconButton>
          </Grid>
          <Grid item xs={10}>
            <AutoSuggestInput
              varname="text"
              renderInputComponent={(props) =>
                this.renderInput({ ...props, classes })
              }
              getSuggestions={this.getSuggestions}
              renderSuggestionsContainer={this.renderSuggestionsContainer}
              getSuggestionValue={this.getSuggestionValue}
              renderSuggestion={this.renderSuggestion}
              placeholder="Message"
              disabled={false}
              value={this.state.input}
              onChange={this.setInput}
              onContextMenu={(e) => e.stopPropagation()}
            />
          </Grid>
          <Grid item xs={1}>
            <IconButton
              color="primary"
              onClick={() => this.send()}
              disabled={this.state.input.trim() === ''}
            >
              <Send />
            </IconButton>
          </Grid>
        </Grid>
      </Toolbar>,
      this.state.sending && (
        <div key={2} className={classes.linearDiv}>
          <LinearProgress className={classes.linear} />
        </div>
      ),
      showEmoji && <Picker onEmojiClick={this.onEmojiClick} />,
    ];
  }
}
export default connect(mapStateToProps)(SmsInput);
