import React, { Component } from 'react';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import {
  Card,
  CardHeader,
  Collapse,
  IconButton
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';

const styles = theme => ({
  flexBox: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center'
  },
  expand: {
    marginLeft: 10,
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
});

class CollapsedCard extends Component {
  state = { open: this.props.defaultOpen || false }

  toggleOpen = () => this.setState(({ open }) => ({ open: !open }))

  render() {
    const { open } = this.state;
    const {
      classes,
      action,
      title,
      children,
      ...props
    } = this.props;

    return <Card className={classes.card} {...props}>
      <CardHeader
        titleTypographyProps={{ variant: 'subtitle1', align: 'left' }}
        title={title}
        action={
          <div className={classes.flexBox}>
            {action}
            <IconButton
              className={classNames(classes.expand, {
                [classes.expandOpen]: open,
              })}
              onClick={this.toggleOpen}
              aria-expanded={open}
              children={<ExpandMore />}
            />
          </div>
        }
      />
      <Collapse in={open} timeout={500} children={children} />
    </Card>;
  }
}

export default withStyles(styles)(CollapsedCard);
