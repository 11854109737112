import React, { Component } from 'react';
import { Paper, Grid, Tabs, Tab } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import StatsPanel from '../common/panel/StatsPanel';
import { moment } from '@bit/dev-lba.lib.local-globals/moment';
import UserSelection from '../common/selection/UserSelection';
import styles from './styles';
import { TABS } from './utils';
import actions from './actions';

import {
  data as categoryData
} from '@bit/dev-lba.lib.local-globals/categories';
import { data as statusData } from '@bit/dev-lba.lib.local-globals/iStatus';
import { data as dStatusData } from '@bit/dev-lba.lib.local-globals/dStatus';

import HeaderBody from './selection/headerBody';

class RHStats extends Component {
  state = {
    mode: 'month',
    from: moment()
      .startOf('month')
      .toDate(),
    to: moment()
      .endOf('month')
      .toDate(),
    userSearch: '',
    selectedTab: 0,
    selectedUsers: [],
    selectedCategories: categoryData.map(e => e._id),
    selectedStatus: statusData.map(e => e._id),
    display: false,
  };

  onDatesChange = (from, to, mode) => {
    this.setState({ from, to, mode });
  };

  updateSearch = search => {
    this.setState({ userSearch: search });
  };

  isDevis = tab => TABS[tab || this.state.selectedTab].label === 'Devis';

  isBM = () => TABS[this.state.selectedTab].label === 'Bilan mensuel';

  isDecote = tab => TABS[tab || this.state.selectedTab].label === 'Decote';

  getMode = () => {
    if (this.isBM()) {
      return [false, true, true, false];
    }
    if (this.isDecote()) {
      return [false, false, false, true];
    }
    return null;
  }

  selectTab = tab => {
    const toUpdate = { selectedTab: tab };
    const isDevis = this.isDevis(tab);
    const lastIsDevis = this.isDevis();

    if (isDevis !== lastIsDevis) {
      toUpdate.selectedStatus = (isDevis ? dStatusData : statusData).map(
        e => e._id
      );
    }
    if (this.isDecote(tab)) {
      toUpdate.from = moment(this.state.from).startOf('year').toDate();
      toUpdate.to = moment(this.state.to).endOf('year').toDate();
      toUpdate.mode = 'year';
    }
    this.setState(toUpdate);
  };

  changeArray = (field, input, key) => {
    let data = [...this.state[field]];
    const toCompare = key ? input[key] : input;

    if (data.includes(toCompare)) {
      data = data.filter(u => u !== toCompare);
    } else {
      data.push(toCompare);
    }
    this.setState({ [field]: data });
  };

  openCommentPanel = user => {
    this.setState({ openCommentPanel: true, commentUser: user });
  };

  toggleDisplay = () => {
    this.setState(prev => ({ display: !prev.display }));
  };

  render() {
    const { users, classes } = this.props;
    const {
      mode,
      from,
      to,
      selectedUsers,
      selectedCategories,
      display,
      selectedStatus,
      userSearch,
      selectedTab,
    } = this.state;

    const isDevis = this.isDevis();
    const tab = TABS[selectedTab];
    const Display = tab.display;

    return (
      <Paper>
        <StatsPanel
          onDatesChange={this.onDatesChange}
          title={'Statistiques ressources humaines'}
          defaultState={{ mode, from, to }}
          enabledModes={this.getMode()}
          body={
            <HeaderBody
              categoryData={categoryData}
              selectedCategories={selectedCategories}
              tab={tab}
              display={display}
              statusData={isDevis ? dStatusData : statusData}
              selectedStatus={selectedStatus}
              changeArray={this.changeArray}
              toggleDisplay={this.toggleDisplay}
            />
          }
        >
          <Grid container>
            <Grid item xs={2} className={classes.userGrid}>
              <UserSelection
                classes={classes}
                users={users.filter(u => u.login.includes(userSearch))}
                updateSearch={this.updateSearch}
                selectedUsers={selectedUsers}
                changeSelectedUsers={e =>
                  this.changeArray('selectedUsers', e, '_id')
                }
                onOpenComments={this.openCommentPanel}
              />
            </Grid>
            <Grid item xs={10} className={classes.statsGrid}>
              <Tabs
                value={selectedTab}
                onChange={(e, v) => this.selectTab(v)}
                centered
              >
                {TABS.map(tab => (
                  <Tab key={tab.label} label={tab.label} />
                ))}
              </Tabs>

              {Display &&
                selectedUsers.map(user => (
                  <Display
                    mode={mode}
                    from={from}
                    to={to}
                    user={user}
                    categories={selectedCategories}
                    status={selectedStatus}
                    display={display}
                    source={tab.label}
                    fn={tab.fn}
                    errorMessage={tab.errorMessage}
                    colors={tab.colors}
                    disposition={tab.disposition}
                    actions={actions}
                  />
                ))}
            </Grid>
          </Grid>
        </StatsPanel>
      </Paper>
    );
  }
}

export default withStyles(styles)(RHStats);
