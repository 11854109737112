import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import {
  LinearProgress,
  Typography,
} from '@material-ui/core';
import Fireworks from 'fireworks/lib/react';

import styles from './style';
import classnames from 'classnames';
import { objectifsMap } from './objectifData';

const fxProps = {
  count: 2,
  interval: 0,
  colors: styles.fxColors,
  calc: (props) => ({
    ...props,
    x: (Math.random() * 300) + 100,
    y: (Math.random() * 200) + 50,
  }),
  particleTimeout: 5000,
  bubbleSizeMaximum: 5,
};

const PercentGroup = ({ classes, percents, titles, doFireworks }) => {
  const getColor = value => {
    if (value < 50) {
      return classes.blueDeterminate;
    }
    if (value < 100) {
      return classes.brownDeterminate;
    }
    return classes.yellowDeterminate;
  };
  return (
    <div className={classes.percentDiv}>
      {titles.map((e, i) => (
        <div key={i}
          className={classnames(
            titles.length > 1 ? classes.halfCard : classes.fullCard)}
        >
          {doFireworks && <Fireworks { ...fxProps } />}
          <Typography variant="subtitle1" align={'left'}>
            &nbsp;&nbsp;
            {titles[i]}: {percents[i] || 0}%
          </Typography>
          <LinearProgress
            classes={{ bar1Determinate: getColor(Number(percents[i])) }}
            className={classes.linearProgress}
            variant="determinate"
            value={Number(percents[i]) > 100 ? 100 : Number(percents[i]) || 0}
          />
        </div>
      ))}
    </div>
  );
};

const ObjectifPercent = ({
  classes,
  data,
  percents,
  ExtraComponent,
  service,
  personal
}) => {
  const hasComponent = ExtraComponent && !!ExtraComponent.props.children;

  let objectifsData = objectifsMap[service];

  let doFireworks;
  if (!data) {
    objectifsData = objectifsData.objectif;
    if (percents) {
      doFireworks = percents.some(e => e >= 100);
    }
  } else {
    objectifsData = objectifsData.personel;
    if (percents) {
      doFireworks = percents.every(e => e >= 100);
    }
  }

  const multiplicator = !data ? 1 : 2;
  return <>
    {!personal && (
      <div
        style={{ alignItems: hasComponent ? 'center' : 'baseline' }}
        className={classes.topDiv}
      >
        <Typography
          className={classes.title}
          variant="h6"
          align={'center'}
          children={data ? 'Objectifs personnel' : 'Objectifs'}
        />
        {ExtraComponent}
      </div>
    )}
    {percents && percents.length
      ? objectifsData.map(
        (d, i) => (
          <PercentGroup
            key={i}
            classes={classes}
            percents={[
              percents[i * multiplicator],
              percents[(i * multiplicator) + 1],
            ]}
            titles={d.titles}
            doFireworks={doFireworks}
          />
        )
      )
      : ''}
  </>;
};

export default withStyles(styles)(ObjectifPercent);
