import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import { Map } from 'immutable';
import SuppliesRows from './SuppliesRows';
import { TVA_A } from '@bit/dev-lba.lib.local-globals/tvaStatus';
import { permit } from '@bit/dev-lba.lib.local-globals/restrictions';
import { downloadAndSignBonSAV } from '../../actions/savIntervention';
import { connect } from 'react-redux';
import { openCommAchat } from '../../actions/achat';

import { moment } from '@bit/dev-lba.lib.local-globals/moment';
import { compose } from 'redux';
import { Grid } from '@material-ui/core';
import CustomLoadingButton from '../Utils/CustomLoadingButton';

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  buttonMargin: {
    margin: '1%'
  },
  saveButton: {
    marginRight: 8
  }
});

const path = ['supplies'];

class BodySupplies extends PureComponent {
  add = (data = {}) => {
    const {
      price = 0,
      suppliesName = `Fourniture${price ? ' auto' : ''}`,
      pro = 'EDISON',
      attachment = ''
    } = data;
    const { setData, user, value } = this.props;
    const time = moment().format('LT').replace(':', 'h');
    const day = moment().format('L');
    const login = `${user.login} ${time} ${day}`;
    let supplie = new Map({
      suppliesDate: login,
      userId: user._id,
      date: new Date(),
      bl: 0,
      suppliesName: suppliesName,
      qt: 1,
      ut: price || 0,
      montant: price,
      pro: pro,
      validate: false,
      attachment
    });
    const newProductState = value.push(supplie);
    setData(path, newProductState);
  }

  popUpBon = () => {
    const { getData, save, downloadAndSignBonSAV } = this.props;
    const inter = {
      id: getData(['id']),
      createdFrom: (getData(['createdFrom']) || new Map()).toJS(),
      dropbox: getData(['dropbox']).toJS(),
    };
    const artisan = (getData(['currentArtisan']) || new Map()).toJS();
    const price = '0';
    downloadAndSignBonSAV({
      price,
      inter,
      artisan,
      add: this.add,
      save,
    });
  };

  setData = (pos, k, v) => {
    const { value, setData } = this.props;
    const res = value.setIn([pos, ...k], v);
    setData(path, res);
  }

  remove = (id) => {
    const { value, setData } = this.props;
    setData(path, value.delete(id));
  }

  swap = (id) => {
    const { value, setData } = this.props;
    setData(
      path,
      value.withMutations((e) => {
        const tmp = e.get(id);
        return e.set(id, e.get(id + 1)).set(id + 1, tmp);
      })
    );
  }

  openDialogCommentaire = () =>
    openCommAchat(this.props)

  render() {
    const {
      classes, value, user, tva, getData,
      showSaveButton,
      save, merge,
      loadingSaveButton
    } = this.props;

    return (
      <div>
        <form className={classes.container} noValidate>
          <Table className={classes.table}>
            <colgroup key="tableStyle">
              {[2, 15, 10, 38, 5, 10, 10, 10, 2].map((e, i) => (
                <col key={i} style={{ width: `${e}%` }} />
              ))}
            </colgroup>
            <TableHead key="tableHeaderLine">
              <TableRow>
                {[
                  '',
                  'Le',
                  'BL',
                  'Désignation',
                  'Quant',
                  'PrixUnit',
                  `Montant ${tva === TVA_A ? 'HT' : 'TTC'}`,
                  'Fournisseur',
                  'fichiers',
                  'Modif',
                ].map((e, i) => (
                  <TableCell key={i}>{e}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody key="tableBodyLine">
              <SuppliesRows
                interId={getData && getData(['id'])}
                data={value}
                setData={this.setData}
                remove={this.remove}
                swap={this.swap}
              />
            </TableBody>
          </Table>
        </form>
        <Grid container alignItems="center">
          <Grid item xs={12} sm={10}>
            <Button
              variant="contained"
              size="small"
              className={classes.buttonMargin}
              onClick={this.add}
            >
              Ajout FOURNITURE
            </Button>
            {permit(user, { key: 'bonsDeCommande' }) && getData && (
              <Button
                variant="contained"
                size="small"
                className={classes.buttonMargin}
                onClick={this.popUpBon}
              >
                Télécharger et signer
              </Button>
            )}
            <Button
              variant="contained"
              size="small"
              disabled={isNaN(merge.get('id'))}
              className={classes.buttonMargin}
              onClick={this.openDialogCommentaire}
            >
              Commentaire Achat
            </Button>
          </Grid>
          {showSaveButton && <Grid item xs={12} sm={2} align='right'>
            <CustomLoadingButton
              variant='contained'
              className={classes.saveButton}
              size="small"
              color='secondary'
              children={'Enregistrer'}
              onClick={() => save()}
              loading={loadingSaveButton}
            />
          </Grid>}
        </Grid>
      </div>
    );
  }
}

export default compose(
  connect(null, { downloadAndSignBonSAV }),
  withStyles(styles)
)(BodySupplies);
