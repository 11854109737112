import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import {
  IconButton,
  CardContent,
  Card,
  CardHeader
} from '@material-ui/core';
import {
  Close,
  ChevronLeft
} from '@material-ui/icons';

import MinCustomDialog from './MinCustomDialog';

const styles = {
  inline: {
    display: 'inline',
    marginLeft: 10
  }
};

const defaultState = ({ data = null, step = null }) =>
  data
    ? { history: [{ data, step }] }
    : { history: [] };

class McDoStepper extends Component {
  state = defaultState(this.props)

  setData = (data, step) => this.setState(({ history }) => ({
    history: history.concat({ data, step })
  }))

  rewind = () => this.setState(({ history }) => ({
    history: history.slice(0, -1)
  }), () => this.props.onRewind &&
    this.props.onRewind(this.state.history))

  renderHeader = title => {
    const { history } = this.state;
    const { classes, onClose } = this.props;

    return history.length > 1 || onClose
      ? <React.Fragment>
        {history.length > 1
          ? <IconButton onClick={this.rewind} children={<ChevronLeft />} />
          : <IconButton onClick={onClose} children={<Close />} />}
        <div className={classes.inline} children={title} />
      </React.Fragment>
      : title;
  }

  render() {
    const { history } = this.state;
    const { data, step } = history[history.length - 1] || {};
    const props = this.props.generateDialogProps(step) || {};
    const {
      generateContent,
      dialog = true,
      customHeader = e => e,
      children = generateContent(data, step, this.setData),
      title = this.renderHeader(props.title)
    } = this.props;

    return dialog
      ? <MinCustomDialog
        middle
        maxWidth="lg"
        open
        {...props}
        customHeader={customHeader && customHeader(this.setData)}
        title={title}
        children={children}
      />
      : <Card>
        <CardHeader title={title} />
        <CardContent children={children} />
      </Card>;
  }
}

export default withStyles(styles)(McDoStepper);
