import React from 'react';
import ReactEcharts from 'echarts-for-react';
import {
  Grid,
  Typography,
  Paper,
  Box
} from '@material-ui/core';

const totalData = (data) => data && data.reduce((curr, acc) => curr + acc, 0);

const formatterTotalRatio = (values, value, getKey) => {
  const key = getKey(value.name);
  const ratioValue = values.find((e) => e.name === key);
  return `(${(totalData(ratioValue?.data)
    ? (totalData(value.data) / totalData(ratioValue?.data)) * 100
    : 0
  ).toFixed(2)}%)`;
};

const EchartsWithTotal = (props) => {
  const { option, keysToDisplay, keyRatio } = props;
  return <Box style={{ padding: 16 }}>
    <Grid container alignItems="center" spacing={2}>
      <Grid item xs={9}>
        <ReactEcharts {...props} />
      </Grid>
      <Grid component={Paper} elevation={4} item xs={3}>
        <Typography align="center" variant="h6" children={'Total'} />
        {option.customData
          .filter(
            (data) =>
              data.data !== undefined &&
              data.data.length &&
              (!keysToDisplay || keysToDisplay.some((s) => s === data.name))
          )
          .map((data, i) => (
            <Grid key={i} container spacing={2}>
              <Grid item xs={6}>
                <Typography align="left" variant="subtitle1">
                  {data.name}:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography align="right" variant="subtitle1">
                  {Math.trunc(totalData(data.data)).toLocaleString()}
                  {keyRatio &&
                    formatterTotalRatio(
                      option.customData,
                      data,
                      keyRatio
                    )}
                </Typography>
              </Grid>
            </Grid>
          ))}
      </Grid>
    </Grid>
  </Box>;
};

export default EchartsWithTotal;
