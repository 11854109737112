import { addAction } from '../services/datadog';

export default () => {
  document.onkeydown = function (e) {
    if (e.keyCode === 123) {
      addAction('Open inspect', { value: e.keyCode });
    }
    if (e.ctrlKey && e.shiftKey && e.keyCode === 'I'.charCodeAt(0)) {
      addAction('Open inspect', { value: e.keyCode });
    }
    if (e.ctrlKey && e.shiftKey && e.keyCode === 'C'.charCodeAt(0)) {
      addAction('Open inspect', { value: e.keyCode });
    }
    if (e.ctrlKey && e.shiftKey && e.keyCode === 'J'.charCodeAt(0)) {
      addAction('Open inspect', { value: e.keyCode });
    }
    if (e.ctrlKey && e.keyCode === 'U'.charCodeAt(0)) {
      addAction('Open inspect', { value: e.keyCode });
    }
  };
  window.matchMedia('print').addListener((mql) => {
    addAction('Open print', { value: mql.matches });
  });

  document.body.addEventListener('contextmenu', () => {
    addAction('Right click', { value: event.target.value });
  });
  document.body.addEventListener('copy', () => {
    addAction('Copy action', { value: event.target.value });
  });
  document.body.addEventListener('cut', () => {
    addAction('Cut action', { value: event.target.value });
  });
  document.body.addEventListener('paste', () => {
    addAction('Paste action', { value: event.target.value });
  });
  document.body.addEventListener('select', () => {
    addAction('Select action', { value: event.target.value });
  });
};
