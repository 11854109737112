import api from './api';
import { loadUsers, updateUser } from './actions/users';
import { fetch as fetchFournisseurs } from './actions/fournisseurs';
import { loadCounter } from './actions/counter';
import { loadUserId } from './actions/userId';
import { loadEquipes } from './actions/equipes';
import { loadPoints } from './actions/points';
import { fetch as fetchNotifications } from './actions/notifications';
import { fetch as fetchProducts } from './actions/products';
import { fetch as fetchGrandComptes } from './actions/grandComptes';
import { fetch as fetchCombos } from './actions/combos';
import { fetch as fetchAnnulations } from './actions/annulations';
import { fetch as fetcConfig } from './actions/config';
import { fetch as fetchSousMetiers } from './actions/sousMetiers';
import { fetch as fetchMetiersSteps } from './actions/metiersSteps';
import { fetch as fetchSignals } from './actions/signals';
import { fetch as fetchConfCand } from './actions/confCand';
import { fetch as fetchConfProsp } from './actions/confProsp';
import { fetch as fetchOldChaudiere } from './actions/oldChaudiere';
import { fetch as fetchRaisonsArchiveSst } from './actions/raisonsArchiveSst';
import { List, fromJS } from 'immutable';
import store from './store';

import { permit } from '@bit/dev-lba.lib.local-globals/restrictions';
import { S_INT, S_LEAD } from '@bit/dev-lba.lib.local-globals/services';

const getUser = (users, userId) => users.find((e) => e._id === userId);

const initUsers = (store, socket) => {
  socket.on('update_users', (data) => {
    api.users.get(data).then((res) => {
      const user = res.body().data();
      store.dispatch(updateUser(user));
    });
  });
  return api.users.getAll({}).then((res) => {
    const users = res.body().map((e) => e.data());
    store.dispatch(loadUsers(users));
    return new List(users);
  });
};

const initUserId = (userId) => {
  store.dispatch(loadUserId(userId));
};

const initCount = (store) => {
  api.counter.get('').then((res) => {
    store.dispatch(loadCounter(res.body().data()));
  });
};

export const initPoints = (store, socket, user) => {
  if ([S_INT, S_LEAD].includes(user.service)) {
    const update = (userId, firstUpdate = true) => {
      if (!firstUpdate && user._id === userId) {
        api.points.getAll({ userId }).then((res) => {
          if (!userId) {
            store.dispatch(loadPoints(res.body().map((e) => e.data())));
          } else {
            const points = store.getState().points;
            const data = res.body().map((e) => e.data())[0];
            if (
              points.some(
                (s) =>
                  s.totalDifference !== data.totalDifference && s._id === userId
              )
            ) {
              store.dispatch(
                loadPoints(points.map((e) => (e._id === userId ? data : e)))
              );
            }
          }
        });
      } else if (firstUpdate) {
        api.points.getAll(user._id === userId ? { userId } : {}).then((res) => {
          store.dispatch(loadPoints(res.body().map((e) => e.data())));
        });
      }
    };
    socket.on('points', (userId) => update(userId, false));
    update();
  }
};

const initCombos = (store, socket, user) => {
  if (permit(user, { key: 'retrieveCombinations' })) {
    const update = () => {
      api.combos.getAll({}).then((res) => {
        const combos = fromJS(res.body().map((e) => e.data()));
        store.dispatch(fetchCombos(combos));
      });
    };
    socket.on('combos', update);
    update();
  }
};

const initOldChaudiere = (store, user) => {
  if (permit(user, { key: 'retrieveCombinations' })) {
    const update = (data) => {
      const oldChaudiere = fromJS(data);
      store.dispatch(fetchOldChaudiere(oldChaudiere));
    };
    api.oldChaudiere.getAll({}).then((res) => {
      const data = res.body().map((e) => e.data());
      update(data);
    });
  }
};

const initAnnulations = (store, socket, user) => {
  if (permit(user, { key: 'retrieveAnnulation' })) {
    const update = (data) => {
      store.dispatch(fetchAnnulations(data));
    };
    socket.on('annulations', update);
    api.annulations.getAll({}).then((res) => {
      update(res.body().map((e) => e.data()));
    });
  }
};

const initProducts = (store, socket) => {
  const update = () => {
    api.products.getAll({}).then((res) => {
      const data = res.body().map((e) => e.data());
      store.dispatch(fetchProducts(fromJS(data)));
    });
  };
  socket.on('products', update);
  update();
};

const initSousMetiers = (store, socket, user) => {
  if (permit(user, { key: 'retrieveSousMetier' })) {
    const update = (data) => {
      store.dispatch(fetchSousMetiers(data));
    };
    socket.on('sousMetiers', update);
    api.sousMetiers.getAll({}).then((res) => {
      const data = Array.isArray(res.body()) && res.body().map((e) => e.data());
      update(data);
    });
  }
};

const initGrandCompte = (store, socket, user) => {
  if (permit(user, { key: 'retrieveGrandCompte' })) {
    const update = (data) => {
      const grandComptes = fromJS(data);
      store.dispatch(fetchGrandComptes(grandComptes));
    };
    socket.on('grandCompte', update);
    api.grandCompte.getAll({}).then((res) => {
      const data = res.body().map((e) => e.data());
      update(data);
    });
  }
};

const initEquipes = (store, user) => {
  if (permit(user, { key: 'retrieveEquipe' })) {
    api
      .one('equipes', '')
      .get()
      .then((res) => {
        store.dispatch(loadEquipes(res.body().data()));
      });
  }
};

const initSignals = (store, socket) => {
  const update = (data) => {
    const signals = fromJS(data);
    store.dispatch(fetchSignals(signals));
  };
  socket.on('signals', update);
  api.signals.getAll({}).then((res) => {
    const data = res.body().map((e) => e.data());
    update(data);
  });
};

const initRaisonsArchiveSst = (store) => {
  const update = (data) => {
    const raisonsArchiveSst = fromJS(data);
    store.dispatch(fetchRaisonsArchiveSst(raisonsArchiveSst));
  };
  api.raisonsArchiveSst.getAll({}).then((res) => {
    const data = res.body().map((e) => e.data());
    update(data);
  });
};

const initFournisseurs = (store) => {
  const update = (data) => {
    store.dispatch(fetchFournisseurs(data));
  };
  api.configFournisseurs.getAll({}).then((res) => {
    const data = res.body().map((e) => e.data());
    update(data);
  });
};

const initConfCand = (store) => {
  const update = (data) => {
    const confCand = fromJS(data);
    store.dispatch(fetchConfCand(confCand));
  };
  api.confCand.getAll({}).then((res) => {
    const data = res.body().map((e) => e.data());
    update(data);
  });
};

const initConfProsp = (store) => {
  const update = (data) => {
    const confProsp = fromJS(data);
    store.dispatch(fetchConfProsp(confProsp));
  };
  api.confProsp.getAll({}).then((res) => {
    const data = res.body().map((e) => e.data());
    update(data);
  });
};

const initConfig = (store, socket) => {
  const update = (data) => {
    store.dispatch(fetcConfig(data));
  };
  socket.on('config', update);
  api.config.getAll({}).then((res) => {
    update(res.body().map((e) => e.data()));
  });
};

const initSMetiersSteps = (store, socket) => {
  const update = () => {
    api.metiersSteps.getAll({
      query: JSON.stringify({ ref: null })
    }).then((res) => {
      store.dispatch(fetchMetiersSteps(res.body().map((e) => e.data())));
    });
  };
  socket.on('metiersSteps', update);
  update();

};
/*
 **  Initializes notifications in store and
 **  waits for notifications update from socket
 */

const initNotifications = (store, userId, socket) => {
  const update = (notification) => {
    notification.socket = true;
    store.dispatch(fetchNotifications(notification));
  };
  socket.on(`notifications ${userId}`, update);
  api
    .all(`notifications/${userId}/getUser`)
    .getAll({})
    .then((res) => store.dispatch(
      fetchNotifications(new List(res.body().map((e) => e.data())))
    ));
};

export const setCount = (key, value) => {
  let counter = store.getState().counter;
  counter[key] = value;
  store.dispatch(loadCounter({ ...counter }));
};

export default function initCache(store, userId, socket) {
  initUserId(userId);
  initUsers(store, socket).then((users) => {
    const user = getUser(users, userId);
    if (user) {
      initCombos(store, socket, user);
      initOldChaudiere(store, user);
      initGrandCompte(store, socket, user);
      initSignals(store, socket, user);
      initConfCand(store, socket, user);
      initConfProsp(store, socket, user);
      initSousMetiers(store, socket, user);
      initAnnulations(store, socket, user);
      initEquipes(store, user);
      initFournisseurs(store, user);
      initPoints(store, socket, user);
      initSMetiersSteps(store, socket, user);
    }
    initCount(store);
    initProducts(store, socket);
    initNotifications(store, userId, socket);
    initConfig(store, socket);
    initRaisonsArchiveSst(store);
  });
}
