import './wdyr';
import { hot } from 'react-hot-loader/root';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import jwt from 'jsonwebtoken';

import { ThemeProvider } from '@material-ui/core/styles';

import './utils/prototypes';

import company from '@bit/dev-lba.lib.local-globals/company';
import { ARTISAN } from '@bit/dev-lba.lib.local-globals/permissions';

import { logout } from './actions/auth';
import initCache from './cache';
import ws from './ws';
import theme from './theme';
import store from './store';
import api from './api';
import Dashboard from './views/Dashboard';
import { initServices } from './services';
import { addUserSession } from './services/datadog';
import ErrorBoundary from './utils/errorBoundary';
import './index.css';

let token;
let timeout;

const onTokenChange = () => {
  const state = store.getState();
  if (state.auth.token !== token) {
    token = state.auth.token;
    api.header('Authorization', `Bearer ${token}`);
    if (token === undefined) {
      ws.logout();
      return;
    }
    const payload = jwt.decode(token);
    if (payload && payload.exp && payload.exp > Date.now() / 1000) {
      clearTimeout(timeout);
      ws.login({ token, payload });
      window.localStorage.setItem('token', token);
      timeout = setTimeout(() => store.dispatch(logout(ws)),
        (payload.exp * 1000) - Date.now()
      );
      if (payload.auth !== ARTISAN)
      {
        initCache(store, payload._id, ws);
        addUserSession(payload);
      }
    } else {
      store.dispatch(logout(ws, payload));
    }
  }
};

store.subscribe(onTokenChange);
onTokenChange();
initServices();
document.title = company.name;

const App = () => (
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <ErrorBoundary>
          <Dashboard />
        </ErrorBoundary>
      </BrowserRouter>
    </ThemeProvider>
  </Provider>
);

export default hot(App);
