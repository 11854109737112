import React from 'react';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';

const styles = () => ({
  textStyle: {
    whiteSpace: 'pre-line',
    textAlign: 'center',
  },
});

const mapStateToProps = ({
  dialog: {
    contentProps: { message },
  },
}) => ({
  message,
});

const VerficationProp = ({ message, classes }) => (
  <Typography
    className={classes.textStyle}
    variant="subtitle1"
    children={message}
  />
);

export default compose(
  connect(mapStateToProps),
  withStyles(styles)
)(VerficationProp);
