/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import { Typography } from '@material-ui/core';

import Money from '../../../utils/Money';

const styles = {
  line: {
    lineHeight: 'normal',
  },
  a: {
    textDecoration: 'underline',
    cursor: 'pointer',
    color: '-webkit-link',
  },
  timelineItem: {
    position: 'inherit'
  }
};

const InterMenuInfo = ({
  type,
  date,
  user,
  obj,
  message,
  text,
  classes,
  getDevis,
}) =>
  !!date && (
    <TimelineItem className={classes.timelineItem}>
      <TimelineOppositeContent>
        <Typography color="textSecondary">{date}</Typography>
        <Typography color="textSecondary">{user}</Typography>
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot />
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        <Typography
          variant="body1"
          style={obj && obj.style ? obj.style : {}}
          className={classes.lineHeight}
        >
          {(type === 'Intervention' && obj && (
            <React.Fragment>
              {type} par{' '}
              <Link to={`/artisan/${obj.artisanId}/recap`}>
                {obj.artisanName}
              </Link>
            </React.Fragment>
          )) ||
            (type === 'Devis' && obj && (
              <React.Fragment>
                {type}{' '}
                <a className={classes.a} onClick={() => getDevis(obj.id)}>
                  {obj.id}
                </a>
              </React.Fragment>
            )) ||
            (type === 'Devis artisan' && obj && (
              <React.Fragment>
                Un devis a été créé par
                <Link to={`/artisan/${obj.artisanId}/recap`}>
                  {obj.artisanName}
                </Link>
              </React.Fragment>
            )) ||
            (type === 'Envoyée' && obj && (
              <React.Fragment>
                Envoyée&nbsp;
                <Link to={`/artisan/${obj.artisanId}/recap`}>
                  {obj.artisanName}
                </Link>
                {!!obj.price && ` à ${Money.toString(obj.price || 0, true)}`}
              </React.Fragment>
            )) ||
            (type === 'Devis supplémentaire réalisé' && obj && (
              <React.Fragment>
                Devis supplémentaire réalisé -&nbsp;
                <Link to={`/devis/${obj.devisId}`}>
                  Devis n°{obj.devisId}
                </Link>
              </React.Fragment>
            )) ||
            `${type || ''}${message || text ? `: ${message || text}` : ''}`}
        </Typography>
      </TimelineContent>
    </TimelineItem>
  );

export default withStyles(styles)(InterMenuInfo);
