import React, { useEffect, useState } from 'react';
import IdleTimer from 'react-idle-timer';
import api from '../../api';
import ws from '../../ws';

const trackingTime = 1000 * 60 * 5;

const TrackingUsers = ({ auth: { decodedToken } }) => {
  const [isIdle, setIsIdle] = useState(false);

  const updateIsIdle = (isIdle, closeTab = false) => {
    api.users.custom(`${decodedToken._id}/isIdle`).post({ isIdle, closeTab });
    setIsIdle(isIdle);
  };

  const onIdle = () => updateIsIdle(true);

  const onActive = () => updateIsIdle(false);

  const handleCloseTab = (e) => {
    e.preventDefault();
    updateIsIdle(true, true);
  };

  useEffect(() => {
    updateIsIdle(false);
    ws.on(`idle_${decodedToken._id}_tabClosed`, () => {
      updateIsIdle(isIdle);
    });
    window.addEventListener('beforeunload', handleCloseTab);
    return () => {
      window.removeEventListener('beforeunload', handleCloseTab);
    };
  }, []);

  return (
    <IdleTimer
      element={document}
      onActive={onActive}
      onIdle={onIdle}
      debounce={250}
      timeout={trackingTime}
      crossTab={{
        emitOnAllTabs: true,
      }}
      startOnMount
    />
  );
};

TrackingUsers.displayName = 'TrackingUsers';

export default TrackingUsers;
