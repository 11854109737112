/* eslint-disable max-lines */
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import { ArrowDropDown, HighlightOff, CheckCircle } from '@material-ui/icons';

import { attachFile } from '@bit/dev-lba.lib.local-globals/utils';
import {
  MAILS_RECOUVRMENT,
  MAIL_TEXT,
  MAILS_LITIGE,
  DEMANDE_SAV,
  MISE_EN_DEMEURE,
  REMISE_COMMERCIALE,
  KEYS_CONCLUSIONS,
  NAMES_CONCLUSIONS,
  ENVOI_EN_LITIGE,
  ENVOI_EN_RECOUVREMENT,
  COURRIER_DERNIER_AVIS_AVANT_POURSUITE,
  MAIL_SIGNIFICATION_IP_HUISSIER,
} from '@bit/dev-lba.lib.local-globals/rConclusions';
import {
  R_DATAS,
  R_EC,
  R_ECR,
  R_RES,
  R_PRE_RES,
} from '@bit/dev-lba.lib.local-globals/rStatus';
import {
  CauseData as causeDataLitige,
  CatData as categorieDataLitige,
} from '@bit/dev-lba.lib.local-globals/lCauses';
import {
  data as causeDataRec,
  CatData as categorieDataRecouvrement,
} from '@bit/dev-lba.lib.local-globals/rCauses';
import { permit } from '@bit/dev-lba.lib.local-globals/restrictions';
import { CHIEF } from '@bit/dev-lba.lib.local-globals/permissions';
import { C_COMPTA } from '@bit/dev-lba.lib.local-globals/commentTypes';
import { E_DEC } from '@bit/dev-lba.lib.local-globals/encaissementType';

import Causes from './Causes';
import SubStatusDevis from './subStatusDevis';
import Resulo from './ResoluComponent';
import CustomSwitch from '../../CustomInputs/CustomSwitch';
import DialogConcl from './DialogConcl';
import Historiques from './Historiques';
import PriseDeContactFooter from './PriseDeContactFooter';
import Decaissement from './ExpandedDecaissement';
import { addEncaissement } from '../../../actions/compta';
import { postComment } from '../../../actions/inter';

import { checkDecaissement } from '../../Reglement/functions';
import api from '../../../api';
import notifSystem from '../../../notifSystem';
import { fromJS, List } from 'immutable';
import { deleteCaissement } from '../../../actions/encaissement';
import { withExpansionPanel } from '../../../hoc';

const styles = (theme) => ({
  center: {
    textAlign: 'center',
  },
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    margin: theme.spacing(1),
  },
  buttons: {
    padding: `${theme.spacing(2)}px 0`,
  },
  button: {
    margin: `${theme.spacing(1)}px 0`,
  },
  chip: {
    margin: theme.spacing(1),
  },
  margin: {
    margin: theme.spacing(1),
  },
});

const mapStateToProps = (state) => ({
  user: state.users.find((u) => u._id === state.userId),
  users: state.users,
});

const ExpandedCommentaires = withExpansionPanel(Historiques);

const GenConclusions = React.memo(({ negociating, classes, setConclusion }) =>
  KEYS_CONCLUSIONS.map((e, id) => {
    const negociatingId = id === 5;
    return (
      <Grid item xs className={classes.center} key={`keyConclusion${id}`}>
        <Button
          variant="outlined"
          color="secondary"
          style={{
            color: negociatingId && negociating && green[500],
            borderColor: negociatingId && negociating && green[500],
            padding: negociatingId ? 10 : 8,
          }}
          onClick={() => setConclusion(id)}
        >
          {NAMES_CONCLUSIONS[id]}
          {negociatingId && negociating && (
            <CheckCircle style={{ color: green[500] }} />
          )}
          {negociatingId && !negociating && <HighlightOff />}
          {id !== 5 && <ArrowDropDown />}
        </Button>
      </Grid>
    );
  })
);
class PriseDeContactDialog extends Component {
  state = {
    element: this.props.defaultText,
    recouvrement: this.props.defaultText.recouvrement,
    loading: false,
    conclusionOpen: false,
    historiqueOpen: false,
    selected: -1,
    decaissements: new List(),
  };

  componentDidMount() {
    this.getEncaissements();
  }

  getEncaissements = async () => {
    const { element: { id }, recouvrement } = this.state;

    try {
      const content = await api.encaissements
        .getAll({
          query: JSON.stringify({
            $or: [{ id }, { multis: { $elemMatch: { id } } }],
            type: E_DEC,
          }),
        })
        .then((e) => e.body().map((e) => e.data()));
      this.setState({
        decaissements: new List(content),
        recouvrement: {
          ...recouvrement,
          conclusions: []
        },
      });
    } catch (error) {
      throw error;
    }
  };

  cancel = () => {
    if (typeof this.props.callback === 'function') {
      this.props.callback(null);
    }
  };

  onValidate = () => {
    const { element, mail, decaissements, recouvrement } = this.state;
    const { user, callback } = this.props;
    if (typeof callback !== 'function') {
      return;
    }
    if (decaissements.size && !checkDecaissement(decaissements)) {
      notifSystem.error('Erreur', 'Les décaissements sont incorrects');
      return;
    }
    const rec = { ...recouvrement };
    if (
      (rec.isLitige && !rec.categorie) ||
      !rec.commentaire ||
      !rec.status ||
      !rec.cause
    ) {
      return notifSystem.error(
        'Pour valider votre prise de contact',
        'Merci de mettre un statut, un commentaire, une cause et une catégorie'
      );
    }
    if ([R_RES, R_PRE_RES].includes(rec.status) && !rec.resuloStatus) {
      return notifSystem.error(
        'Pour valider votre prise de contact',
        'Merci de selectionner une sous-catégorie pour le statut résolu.'
      );
    }
    if (
      !rec.montantEmisDemeure &&
      rec.conclusions.includes(COURRIER_DERNIER_AVIS_AVANT_POURSUITE)
    ) {
      notifSystem.error(
        'Pour valider le dernier avis avant poursuite',
        'Veuillez créer une mise en demeure tout d\'abord.'
      );
      return;
    }
    if (
      rec.conclusions.includes([...MAILS_LITIGE, ...MAILS_RECOUVRMENT]) &&
      (!mail.text ||
        (/<Modifiable>/).test(mail.text) ||
        !mail.desMail ||
        !mail.title)
    ) {
      return notifSystem.error(
        'Erreur mail',
        'Veuillez vérifier le corps du mail,' +
          ' le destinataire, le titre et <Modifiable>'
      );
    }
    if (
      rec.conclusions.includes(ENVOI_EN_RECOUVREMENT) ||
      rec.conclusions.includes(ENVOI_EN_LITIGE)
    ) {
      rec.isPris = false;
      rec.isLitige = rec.conclusions.includes(ENVOI_EN_LITIGE);
      rec.categorie = 0;
      rec.status = 0;
      rec.cause = 0;
      rec.conclusions = [];
    }
    rec.historique = (rec.historique || []).concat({
      userId: user._id,
      comments: rec.commentaire,
      date: new Date(),
      conclusions: rec.conclusions,
    });
    delete rec.commentaire;
    rec.priseDeContact = new Date();
    element.recouvrement = rec;
    callback(element, mail);
    if (decaissements.size) {
      addEncaissement(decaissements.toJS(), user._id, fromJS(element));
      if (element.recouvrement.commentaireCompta) {
        postComment(element._id, element.recouvrement.commentaireCompta, {
          type: C_COMPTA,
        });
      }
    }

    this.setState({ element, recouvrement: rec });
  };

  addFile = (e) => {
    const { mail } = this.state;

    attachFile(e.target.files[0]).then((attachment) =>
      this.setState({
        mail: {
          ...mail,
          attachements: mail.attachements.concat(attachment),
        },
      })
    );
  };

  reset = () => {
    const { recouvrement } = this.state;
    if (recouvrement) {
      return this.setState({
        recouvrement: {
          ...recouvrement,
          commentaire: '',
          negociating: false,
          conclusions: [],
          status: 0,
        },
        selected: -1,
        mail: {},
      });
    }
    this.setState({ selected: -1 });
  };

  handleCheckboxes = (id) => {
    const { user } = this.props;
    const fId = Number(id[0]);
    const { mail, element } = this.state;
    let recouvrement = { ...this.state.recouvrement };
    let ret = [...recouvrement.conclusions];
    const index = ret.indexOf(fId);
    const dataArray = [
      ...(recouvrement.isLitige ? MAILS_LITIGE : MAILS_RECOUVRMENT),
    ];
    if (index === -1) {
      ret.push(fId);
    } else {
      ret.splice(index, 1);
    }
    recouvrement.conclusions = ret;
    if (recouvrement.conclusions.includes(REMISE_COMMERCIALE)) {
      recouvrement.status = R_ECR;
    } else if (
      recouvrement.conclusions.includes(DEMANDE_SAV) ||
      recouvrement.conclusions.includes(MISE_EN_DEMEURE)
    ) {
      recouvrement.status = R_EC;
    }
    const hasMailInCon = ret.find((e) => dataArray.includes(e));
    let findMail = dataArray.includes(fId);
    if (findMail && hasMailInCon) {
      findMail = MAIL_TEXT.find((e) => e._id === fId);
      if (findMail && mail && mail.text) {
        return notifSystem.error(
          'Erreur',
          'Merci de sélectionner un seul mail'
        );
      }
      return this.setState({
        mail: findMail
          ? {
            attachements: [],
            text: findMail.mail(element, user),
            name: findMail.name,
            title: findMail.title || `Intervention n°${element.id}`,
            desMail: recouvrement.conclusions.includes(
              MAIL_SIGNIFICATION_IP_HUISSIER
            )
              ? ''
              : `${
                (Object.keys(element.billing || {}).length
                  ? element.billing
                  : element.client
                ).email1 || ''
              }`,
          }
          : mail,
        recouvrement,
      });
    }
    return this.setState({
      recouvrement,
      mail: hasMailInCon ? mail : {},
    });
  };

  handelChange = (name, v) => {
    this.setState({
      [name]: v,
    });
  };

  setData = (path, value) => {
    const recouvrement = fromJS(this.state.recouvrement);
    this.setState({
      recouvrement: recouvrement.setIn(path, value).toJS(),
    });
  };

  setInterData = (path, value) => {
    const element = fromJS(this.state.element);
    this.setState({
      element: element.setIn(path, value).toJS(),
    });
  };

  setConclusion = (id) => {
    if (id === 5) {
      const recouvrement = { ...this.state.element.recouvrement };
      this.setState({
        recouvrement: {
          ...recouvrement,
          negociating: !recouvrement.negociating,
          status: R_EC,
        },
        selected: -1,
        conclusionOpen: false,
      });
    } else {
      this.setState({
        conclusionOpen: true,
        selected: id,
      });
    }
  };

  deleteComment = async (id) => {
    try {
      let recouvrement = this.state.element.recouvrement;
      if (id > -1) {
        recouvrement.historique.splice(id, 1);
      }
      this.setState({ recouvrement });
      const { _id, ...data } = recouvrement;
      await api.recouvrements.patch(_id, { recouvrement: data });
      return notifSystem.success(
        'Message',
        'Le commentaire a bien été supprimé'
      );
    } catch (error) {
      return notifSystem.error('Erreur',
        'Le commentaire n\'a pas été supprimé');
    }
  };

  handleChipDelete = (index) => {
    let mail = Object.assign({}, this.state.mail);
    mail.attachements.splice(index, 1);
    const ret = mail.attachements.slice(0);
    this.setState({
      mail: {
        ...mail,
        attachements: ret,
      },
    });
  };

  render() {
    const { open, classes, user, users } = this.props;
    const {
      element: { commissionValide, id },
      recouvrement,
      loading,
      selected,
      conclusionOpen = false,
      mail = {},
      decaissements,
      commentaireCompta,
      historiqueOpen,
    } = this.state;
    const [causeDataToFetch, categorieDataToFetch, title] =
      recouvrement.isLitige
        ? [causeDataLitige, categorieDataLitige, 'litige']
        : [causeDataRec, categorieDataRecouvrement, 'recouvrement'];
    return (
      <Dialog open={open} fullWidth maxWidth={'lg'}>
        {!conclusionOpen ? (
          [
            (
              <DialogTitle key="title">
                <Grid container spacing={4}>
                  <Grid item xs={6}>
                    <Typography variant="h6">Prise de contact</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      className={classes.chip}
                      label={`Montant du ${title}`}
                      value={
                        (recouvrement.priseEnCharge &&
                          recouvrement.priseEnCharge.montant) ||
                        0
                      }
                      onChange={(e) =>
                        this.setData(
                          ['priseEnCharge', 'montant'],
                          e.target.value
                        )
                      }
                      disabled={!permit(user, { auth: CHIEF })}
                    />
                  </Grid>
                </Grid>
              </DialogTitle>
            ),
            <DialogContent key="content">
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                className={classes.button}
                children={'Sous-status devis'}
              />
              <Grid container justify="space-around" spacing={2}>
                <SubStatusDevis
                  handleChange={(e) =>
                    this.setData(['subStatusDevis'], Number(e.target.value))
                  }
                  value={recouvrement.subStatusDevis}
                />
              </Grid>
              <Button
                variant="outlined"
                color="secondary"
                fullWidth
                className={classes.button}
                children={`Cause du ${title}`}
              />
              <Causes
                isPris={recouvrement.isPris}
                fetchData={causeDataToFetch}
                handleChange={(e) =>
                  this.setData(['cause'], Number(e.target.value))
                }
                value={recouvrement.cause}
              />
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                className={classes.button}
                children={`Catégorie : ${title}`}
              />
              <Causes
                isPris={recouvrement.isPris}
                fetchData={categorieDataToFetch.filter(
                  (e) => !e.for || e.for?.includes(recouvrement.cause)
                )}
                handleChange={(e) =>
                  this.setData(['categorie'], Number(e.target.value))
                }
                value={recouvrement.categorie}
              />
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                className={classes.button}
                children={'Conclusion'}
              />
              <Grid
                container
                spacing={3}
                justify={'center'}
                className={classes.buttons}
                children={
                  <GenConclusions
                    setConclusion={this.setConclusion}
                    classes={classes}
                    negociating={recouvrement.negociating}
                  />
                }
              />
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                className={classes.button}
                children={`Statut du ${title}`}
              />
              <Grid>
                <Causes
                  isPris={recouvrement.isPris}
                  fetchData={R_DATAS.filter((e) =>
                    permit(user, {
                      key: 'displayStatusPriseDeContactDialog',
                      auth: e.perm,
                    })
                  )}
                  handleChange={(e) =>
                    this.setData(['status'], Number(e.target.value))
                  }
                  value={recouvrement.status}
                />
              </Grid>
              <Grid container spacing={3} justify={'center'}>
                {[R_RES, R_PRE_RES].includes(recouvrement.status) && (
                  <Resulo
                    setData={this.setData}
                    recouvrement={recouvrement}
                  />
                )}
                {recouvrement.status === R_RES && (
                  <CustomSwitch
                    key="2"
                    label="Commission intervention"
                    checked={commissionValide || false}
                    path={['commissionValide']}
                    setData={this.setInterData}
                  />
                )}
              </Grid>
              <Paper className={classes.root} elevation={1}>
                <TextField
                  fullWidth
                  texttransform="none"
                  rows={4}
                  multiline
                  label="Commentaire..."
                  value={recouvrement.commentaire || ''}
                  onChange={(e) =>
                    this.setData(['commentaire'], e.target.value)
                  }
                />
              </Paper>
              <Decaissement
                expansionTitle="Décaissement"
                decaissements={decaissements}
                classes={classes}
                data={{ id: this.state.element.id }}
                setData={(p, v) => this.handelChange('decaissements', v)}
                deleteCaissement={deleteCaissement}
                commentValue={commentaireCompta}
              />
              <ExpandedCommentaires
                expansionTitle={`OS ${id}`}
                deleteComment={
                  permit(user, { key: 'accessComRecouvrement' }) &&
                  this.deleteComment
                }
                key="history"
                inters={[fromJS({ ...this.state.element, checked: true })]}
                users={users}
                classes={classes}
              />
            </DialogContent>,
            !!(mail.attachements || []).length && (
              <DialogContent key="files">
                {Object.values(mail.attachements).map((element, index) => (
                  <Chip
                    key={index}
                    label={element.name}
                    onDelete={() => this.handleChipDelete(index)}
                  />
                ))}
              </DialogContent>
            ),
            <PriseDeContactFooter
              key="footer"
              attachements={!!mail.attachements}
              addFile={this.addFile}
              send={this.onValidate}
              cancel={this.cancel}
              loading={loading}
              reset={this.reset}
              historique={() =>
                this.handelChange('historiqueOpen', !historiqueOpen)
              }
            />,
          ]
        ) : (
          <DialogConcl
            mail={mail}
            facture
            setMail={(path, value) =>
              this.handelChange('mail', { ...mail, [path]: value })
            }
            recouvrement={recouvrement}
            handleCheckboxes={this.handleCheckboxes}
            selected={selected}
            setData={this.setData}
            setConclusionDialog={() =>
              this.handelChange('conclusionOpen', !conclusionOpen)
            }
          />
        )}
      </Dialog>
    );
  }
}
PriseDeContactDialog.defaultProps = {
  validateText: 'Valider',
  cancelText: 'Annuler',
  maxWidth: 'sm',
  fullWidth: false,
};

export default compose(withStyles(styles))(
  connect(mapStateToProps)(PriseDeContactDialog)
);
