import React from 'react';
import Iframe from 'react-iframe';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import { Description } from '@material-ui/icons';

import { PDF_REGEX, IMAGE_REGEX } from '../../utils/regexps';

const styles = {
  container: {
    margin: 'auto',
    width: '100%',
    height: '100%',
    position: 'relative'
  },
  centered: {
    width: '100%'
  },
  noPreview: {
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
    margin: 'auto'
  },
  iframe: {
    borderColor: '#9d9d9d',
  },
  img: {
    height: '100%',
    width: '100%',
    'object-fit': 'contain'
  }
};

const FileDisplayer = ({
  classes,
  content,
  name = '',
  containerProps = {},
  type = (PDF_REGEX.test(name) && 'pdf') || (IMAGE_REGEX.test(name) && 'image')
}) =>
  <div className={classes.container}>
    {(type === 'pdf' &&
      <Iframe
        className={classNames(classes.centered, classes.iframe)}
        src={content}
        name={name}
        height="100%"
        width="80%"
        {...containerProps}
      />)
    || (type === 'image' &&
      <img
        className={classNames(classes.centered, classes.img)}
        src={content}
        alt={name}
        {...containerProps}
      />)
    || <div className={classes.noPreview}>
      <Description className={classes.centered} />
    </div>}
  </div>;

export default withStyles(styles)(FileDisplayer);
