import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Card,
  Typography,
  CardContent,
  Button,
} from '@material-ui/core';
import {
  Work,
  WorkOff
} from '@material-ui/icons';

import api from '../../api';
import notifSystem from '../../notifSystem';

const mapStateToProps = ({ users, userId }) => ({
  user: users.find((e) => e._id === userId),
});

class DemandePresence extends Component {
  state = { user: this.props.user };

  updateConnectToReciveLeads = () => {
    const { user } = this.state;
    const value = !user.connectToReciveLeads;
    return api.users
      .patch(user._id, {
        connectToReciveLeads: value,
      })
      .then(() => {
        this.setState({ user: { ...user, connectToReciveLeads: value } });
        notifSystem.success(
          'Message',
          `Vous etes desormais ${value ? 'connecté(e)' : 'deconnecté(e)'}`
        );
      })
      .catch((e) => {
        notifSystem.error(e.name, e.response ? e.response.data : e.message);
      });
  };

  render() {
    const { user } = this.props;
    return (
      <Card className="cards" style={{ textAlign: 'center', height: '100%' }}>
        <CardContent>
          <Typography variant="subtitle1" align={'center'}>
            {user.connectToReciveLeads
              ? 'Je suis connecté(e)'
              : 'Je ne suis pas connecté(e)'}
          </Typography>
          <Button
            variant="contained"
            color={user.connectToReciveLeads ? 'secondary' : 'primary'}
            endIcon={user.connectToReciveLeads ? <WorkOff /> : <Work />}
            onClick={this.updateConnectToReciveLeads}
          >
            {user.connectToReciveLeads ? 'Désactiver' : 'Activer'}
          </Button>
        </CardContent>
      </Card>
    );
  }
}
export default connect(mapStateToProps)(DemandePresence);
