import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { ThemeProvider, withStyles } from '@material-ui/core/styles';
import { Grid, Hidden } from '@material-ui/core';

import { login as loginAction } from '../actions/auth';
import { login as theme } from '../theme';
import notifSystem from '../notifSystem';
import api from '../api';
import Login from '../components/Login';
import home from '../../public/home.jpg';
import publicIp from 'public-ip';

const styles = {
  main: {
    backgroundColor: 'white',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    zIndex: 300,
    transition: 'opacity 0.5s',
  },
  center: {
    margin: 'auto',
  },
  img: {
    width: '50%',
    height: '100%',
    'background-image': `url("${home}")`,
    'background-size': 'cover',
    'background-repeat': 'non-repeat',
    'background-position': 'right',
  },
};

export class LoginView extends PureComponent {
  constructor() {
    super();
    this.state = {
      error: ''
    };
    this.login = this.login.bind(this);
  }

  async login(login, password) {
    try {
      let ip = '';
      if (['stage', 'production'].includes(process.env.NODE_ENV)) {
        ip = await publicIp.v4();
      }
      const res = await api.login.post({
        ip,
        login,
        password
      });
      if (res.statusCode() === 200) {
        const { token } = res.body().data();
        this.props.login(token);
        this.props.history.push(
          this.props.location?.pathname
            ? this.props.location.pathname
            : '/'
        );
        return true;
      }
    } catch (error) {
      notifSystem.error(error.name, error.message);
      this.setState({
        error
      });
    }
    return false;
  }

  render() {
    const { classes } = this.props;
    return (
      <ThemeProvider theme={theme}>
        <div className={classes.main}>
          <Grid className={classes.center} item xs={12} md={12} lg={5}>
            <Login login={this.login} error={this.state.error}/>
          </Grid>
          <Hidden mdDown>
            <div src={home} alt="logo" className={classes.img} />
          </Hidden>
        </div>
      </ThemeProvider>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  login: token => dispatch(loginAction(token)),
});
export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(LoginView));
