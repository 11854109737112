import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { getEmoji } from '../StatsCalls/getEmoji';
import store from '../../../store';
import { setDialog } from '../../../actions/dialog';

const styles = {
  align: {
    textAlign: 'center',
  },
};

function getSorting(order, orderBy) {
  return order === 'desc'
    ? (a, b) => {
      const newB = Array.isArray(b[orderBy])
        ? b[orderBy].length || 0
        : b[orderBy] || 0;
      const newA = Array.isArray(a[orderBy])
        ? a[orderBy].length || 0
        : a[orderBy] || 0;
      return newB < newA ? -1 : 1;
    }
    : (a, b) => {
      const newB = Array.isArray(b[orderBy])
        ? b[orderBy].length || 0
        : b[orderBy] || 0;
      const newA = Array.isArray(a[orderBy])
        ? a[orderBy].length || 0
        : a[orderBy] || 0;
      return newA < newB ? -1 : 1;
    };
}
const openUniques = (calls) =>
  store.dispatch(
    setDialog({
      name: 'ListCallsDialog',
      open: true,
      hideClose: true,
      dialogProps: {
        maxWidth: 'lg',
      },
      contentProps: {
        title: 'Appels manqués',
        calls,
      },
      actions: [
        {
          children: 'Fermer',
          color: 'secondary',
          onClick: (r, close) => close(),
        },
      ],
    })
  );

const StatsTableBody = ({
  data,
  order,
  orderBy,
  page,
  rowsPerPage,
  classes,
}) => (
  <TableBody>
    {data
      .sort(getSorting(order, orderBy))
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map((n, i) => (
        <TableRow tabIndex={-1} key={i}>
          <TableCell
            className={classes.align}
            padding="checkbox"
            component="th"
            scope="row"
          >
            {n.login}
          </TableCell>
          <TableCell
            className={classes.align}
            padding="checkbox"
            align={'right'}
          >
            {n.total + n.I_ANN * -1}
          </TableCell>
          <TableCell
            className={classes.align}
            padding="checkbox"
            align={'right'}
          >
            {n.I_APR}
          </TableCell>
          <TableCell
            className={classes.align}
            padding="checkbox"
            align={'right'}
          >
            {n.I_ENC}
          </TableCell>
          <TableCell
            className={classes.align}
            padding="checkbox"
            align={'right'}
          >
            {n.I_AVR}
          </TableCell>
          <TableCell
            className={classes.align}
            padding="checkbox"
            align={'right'}
          >
            {n.I_VRF}
          </TableCell>
          <TableCell
            className={classes.align}
            padding="checkbox"
            align={'right'}
          >
            {n.I_ANN ? `-${n.I_ANN}` : n.I_ANN}
          </TableCell>
          <TableCell
            className={classes.align}
            padding="checkbox"
            align={'right'}
          >
            {n.CBRegle}%
          </TableCell>
          <TableCell
            className={classes.align}
            padding="checkbox"
            align={'right'}
          >
            {n.panierMoyen}
          </TableCell>
          <TableCell
            className={classes.align}
            padding="checkbox"
            align={'right'}
            onClick={() => n.callWaiting?.length && openUniques(n.callWaiting)}
          >
            {n.callWaiting?.length || 0}
          </TableCell>
          <TableCell
            className={classes.align}
            padding="checkbox"
            align={'right'}
          >
            {n.uniqueCalls && n.CA
              ? getEmoji(n.CA / 100 / n.uniqueCalls, 50).component
              : 'N/A'}
          </TableCell>
        </TableRow>
      ))}
  </TableBody>
);

StatsTableBody.propTypes = {
  rowsPerPage: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
};

export default withStyles(styles)(StatsTableBody);
