import React from 'react';
import NiceInputPassword from 'react-nice-input-password';
import { TextField, InputLabel, Typography } from '@material-ui/core';
import { Lock } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { red, deepOrange, yellow, green, grey } from '@material-ui/core/colors';

const styles = {
  divStyle: {
    width: '45%',
    margin: '0 2.5%',
  },
};

const levelBarCss = (level) => ({
  height: '8px',
  width: level > 0 ? `${(100 / 4) * level}%` : '100%',
  marginTop: 16,
  transition: 'width 0.5s ease',
  backgroundColor: [
    grey[500],
    red[500],
    deepOrange[500],
    yellow[500],
    green[500],
  ][level],
  borderRadius: 0,
});

const CustomLevelBar = (levels) => <div style={levelBarCss(levels)} />;

const CustomPasswordInput = ({
  value = '',
  onChange,
  label = 'My password field',
  classes,
  showSecurityLevelDescription = false,
  showSecurityLevelBar = false,
}) => (
  <div className={classes.divStyle}>
    <NiceInputPassword
      value={value}
      showSecurityLevelBar={showSecurityLevelBar}
      onChange={onChange}
      LabelComponent={InputLabel}
      InputComponent={TextField}
      showSecurityLevelDescription={showSecurityLevelDescription}
      InputComponentProps={{
        fullWidth: true,
        label,
        InputProps: {
          endAdornment: <Lock />,
        },
        autocomplete: 'new-password',
      }}
      securityLevels={[
        {
          descriptionLabel: <Typography>Au moins un nombre</Typography>,
          validator: /.*[0-9].*/,
        },
        {
          descriptionLabel: (
            <Typography>Au moins un caractère muniscule</Typography>
          ),
          validator: /.*[a-z].*/,
        },
        {
          descriptionLabel: (
            <Typography>Au moins un caractère majuscule</Typography>
          ),
          validator: /.*[A-Z].*/,
        },
        {
          descriptionLabel: <Typography>Au moins 8 caractères</Typography>,
          validator: /^.{8,}$/,
        },
      ]}
      renderLevelBarComponent={CustomLevelBar}
    />
  </div>
);

export default withStyles(styles)(CustomPasswordInput);
