import { moment } from '@bit/dev-lba.lib.local-globals/moment';

export const getOptionUser = function (data, mode, color) {
  let arr = [];
  if (mode === 'day') {
    arr = [
      '7h',
      '8h',
      '9h',
      '10h',
      '11h',
      '12h',
      '13h',
      '14h',
      '15h',
      '16h',
      '17h',
      '18h',
    ];
  } else if (mode === 'week') {
    arr = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
  } else {
    arr = moment.monthsShort();
  }
  return {
    color: [color],
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    toolbox: {
      show: true,
      orient: 'vertical',
      left: 'right',
      top: 'center',
      feature: {
        mark: { show: true },
        saveAsImage: { show: true, title: 'save' },
      },
    },
    calculable: true,
    xAxis: [
      {
        type: 'category',
        axisTick: { show: false },
        data: arr,
      },
    ],
    yAxis: [
      {
        type: 'value',
        axisLabel: {
          formatter: '{value}',
        },
      },
    ],
    series: {
      name: data && data._id,
      type: 'bar',
      data: data && delete data._id && Object.values(data).map((n) => n / 100),
    },
  };
};
