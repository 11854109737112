import React from 'react';
import {
  RESOLU_DATAS,
  T_LIT,
  T_REC
} from '@bit/dev-lba.lib.local-globals/ResoluStatus';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Causes from './Causes';


const Resulo = ({
  recouvrement,
  setData,
}) => (
  <React.Fragment>
    <Grid item xs={6}>
      <TextField
        disabled={true}
        type="number"
        label="Montant prise en charge"
        fullWidth
        value={recouvrement.priseEnCharge
          ? recouvrement.priseEnCharge.montant
          : ''
        }
      />
    </Grid>
    <Grid item xs={6}>
      <TextField
        type="number"
        label="Montant effectivement encaissé"
        onChange={(e) => setData(['montantEncaisse'], Number(e.target.value))}
        fullWidth
        value={recouvrement.montantEncaisse || 0}
      />
    </Grid>
    <Grid item xs={12}>
      <Causes fetchData={RESOLU_DATAS.filter(e =>
        e.type.includes(recouvrement.isLitige ? T_REC : T_LIT)
      )}
      isPris={recouvrement.isPris}
      handleChange={(e) => setData(['resuloStatus'], Number(e.target.value))}
      value={recouvrement.resuloStatus}/>
    </Grid>
  </React.Fragment>);

export default Resulo;
