import React from 'react';
import { TableCell, TableHead, TableRow } from '@material-ui/core';
import { red, green, orange, grey } from '@material-ui/core/colors';
import { TrendingDown, TrendingFlat, TrendingUp } from '@material-ui/icons';

const genIconOrColor = (oldValue = 0, curr = 0, icon = false) => {
  const diff = parseFloat(curr) - parseFloat(oldValue);
  let elem = icon ? <TrendingFlat style={{ color: orange[500] }} /> :
    orange[500];
  if (diff < 0) {
    elem = icon ? <TrendingDown style={{ color: red[500] }} /> : red[500];
  } else if (diff > 0) {
    elem = icon ? <TrendingUp style={{ color: green[500] }} /> : green[500];
  }
  return elem;
};

const months = ['third', 'second', 'first'];

export default (openIntersDialog) => ({
  Header: (props) => <TableHead>
    <TableRow>
      {props.columns.map(e =>
        <TableCell
          align={e.align || ''}
          children={e.title}
          colSpan={e.colSpan || null}
        />
      )}
    </TableRow>
  </TableHead>,
  Row: ({ data, index }) =>
    <TableRow>
      <TableCell children={data.name} />
      {months.map((e, i) => <React.Fragment keys={i}>
        <TableCell
          align="center"
          children={data[e].pot.nb}
          style={{ borderLeft: `2px solid ${grey[200]}` }}
          onClick={() => index && openIntersDialog(data[e].pot.inters)}
        />
        <TableCell align="center" style={{
          color: index > 0 && months[i - 1] && data[months[i - 1]] ?
            genIconOrColor(data[months[i - 1]].potPourcentage,
              data[e].potPourcentage)
            : ''
        }}
        onClick={() => index && openIntersDialog(data[e].pot.inters)}
        >
          {data[e].potPourcentage}
        </TableCell>
        <TableCell align="center" children={data[e].recu.nb}
          onClick={() => index && openIntersDialog(data[e].recu.inters)}

        />
        <TableCell align="center"
          onClick={() => index && openIntersDialog(data[e].recu.inters)}
        >
          {data[e].recuPourcentage}
          {index > 0 && months[i - 1] && data[months[i - 1]] &&
            genIconOrColor(data[months[i - 1]].recuPourcentage,
              data[e].recuPourcentage, true)}
        </TableCell>
      </React.Fragment>)}
    </TableRow>,
  Pagination: () => <></>
});
