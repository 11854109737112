import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import CustomTextField from '../CustomInputs/CustomTextField';

const paths = {
  companyName: ['companyName'],
};

class CompanyName extends PureComponent {
  render() {
    const { setData, companyName } = this.props;
    return (
      <Grid container spacing={3}>
        <CustomTextField grid xs={12} sm={6} lg={5} capsLocked
          label="Nom de Societé"
          value={companyName}
          path={paths.companyName}
          setData={setData}
        />
      </Grid>
    );
  }
}

CompanyName.propTypes = {
  companyName: PropTypes.string.isRequired,
  setData: PropTypes.func.isRequired
};

CompanyName.defaultProps = {
  companyName: '',
};

export default CompanyName;
