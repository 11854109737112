import {
  S_INT,
  S_PART,
  S_COMPTA,
  S_DEVIS,
  S_SATCL,
  S_LEAD
} from '@bit/dev-lba.lib.local-globals/services';

export const objectifEn = {
  Jour: 'day',
  Semaine: 'week',
  Mois: 'month',
  Soir: 'night',
  PaiementCB: 'paymentCB',
};

export const objectifsMap = {
  [S_INT]: {
    personel: [
      { titles: ['Chiffre d\'affaire', 'Annulation'] },
      { titles: ['Interventions', 'Demarchage'] },
      { titles: ['Devis', 'Paiement CB'] },
    ],
    objectif: [
      { titles: ['Jour'] },
      { titles: ['Semaine'] },
      { titles: ['Mois'] },
      { titles: ['Soir'] },
      { titles: ['PaiementCB'] },
    ],
  },
  [S_PART]: {
    objectif: [
      { titles: ['Ajouts par jour'] },
      { titles: ['Ajouts par semaine'] },
      { titles: ['Ajouts par mois'] },
    ],
  },
  [S_COMPTA]: {
    objectif: [{ titles: ['PaiementCB'] }],
  },
  [S_DEVIS]: {
    personel: [
      { titles: ['Chiffre d\'affaire', 'Devis'] },
      { titles: ['Nombre de signalement', 'Intervention envoyé'] },
      { titles: ['Annulation', 'Intervention verifié'] },
    ],
    objectif: [
      { titles: ['Jour'] },
      { titles: ['Semaine'] },
      { titles: ['Mois'] },
      { titles: ['PaiementCB'] },
    ],
  },
  [S_SATCL]: {
    personel: [
      { titles: ['Chiffre d\'affaire', 'Annulation'] },
      { titles: ['Interventions', 'Demarchage'] },
      { titles: ['Devis', 'Paiement CB'] },
    ],
  },
  [S_LEAD]: {
    personel: [
      { titles: ['Chiffre d\'affaire', 'Annulation'] },
      { titles: ['Interventions', 'Demarchage'] },
      { titles: ['Devis', 'Paiement CB'] },
    ],
    objectif: [
      { titles: ['Jour'] },
      { titles: ['Semaine'] },
      { titles: ['Mois'] },
      { titles: ['Soir'] },
      { titles: ['PaiementCB'] },
    ],
  },
};
