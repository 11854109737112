/* eslint-disable max-lines */
import aSubStatus from '@bit/dev-lba.lib.local-globals/aSubStatus';
import categories from '@bit/dev-lba.lib.local-globals/categories';
import status from '@bit/dev-lba.lib.local-globals/iStatus';
import { data as paidStatus } from '@bit/dev-lba.lib.local-globals/paidStatus';
import { Types } from './Types';
import {
  S_BTOB,
  S_INT,
  S_DEVIS,
  S_ACHAT,
  S_PART,
  S_LEAD
} from '@bit/dev-lba.lib.local-globals/services';
import sourceLogoArtisans from '../sourceLogoArtisans';
import { ADMIN } from '@bit/dev-lba.lib.local-globals/permissions';

export const addColumnsByService = (
  service, { users, artisanKeys, permission }
) => {
  switch (service) {
    case S_BTOB: {
      const columns = {
        table: {
          OS: Types.Text,
          Telepro: Types.Text,
          'Cat.': Types.Rectangle,
          Ajout: Types.Text,
          Client: Types.Text,
          Ville: Types.Text,
          Artisan: Types.artisanRef,
          Prevu: Types.Date,
          Prix: Types.Money,
          iStatus: Types.Chip,
          rSST: Types.Chip,
          'Grand Compte': Types.nomGramdCompte,
          Appel: Types.Icon,
        },
        tableData: [
          { class: 'th' },
          { class: '', list: users, key: 'login' },
          { class: '', list: categories.data },
          { class: 'reduce' },
          { class: '' },
          { class: '', name: 'ville' },
          {
            class: 'th',
            name: 'artisan',
            list: [],
            key: 'currentArtisan.subStatus',
            status: aSubStatus.data,
            logoArtisan: sourceLogoArtisans,
            sourceArtisan: 'currentArtisan.source',
          },
          { class: 'reduce' },
          { class: '', key: 'onSitePayment' },
          { class: '', list: status.data },
          {
            class: '',
            name: 'firstPaid',
            key: 'firstPaid',
            list: paidStatus,
          },
          {
            class: 'th',
            name: 'grandCompte',
            list: [],
            key: 'billing.grandCompte',
          },
          { class: '' },
        ],
        keys: [
          'id',
          'login.ajout',
          'categorie',
          'date.ajout',
          [
            'client.civility',
            'client.name',
            'client.firstname',
            'client.society',
          ],
          ['client.address.zipcode', 'client.address.city'],
          artisanKeys,
          'date.intervention',
          'announcedPrice',
          'status',
          'firstPaid',
          'billing.grandCompte',
          'appel',
        ],
        checkType: [
          'number',
          'users',
          'categorie',
          'date',
          ['client.civility', 'client.name', 'client.firstname'],
          ['client.address.city', 'client.address.zipcode'],
          artisanKeys,
          'date',
          'prix',
          'iStatus',
          'number',
          'billing.grandCompte',
          'appel',
        ],
      };
      return columns;
    }
    case S_DEVIS: {
      const columns = {
        table: {
          OS: Types.Text,
          Telepro: Types.Text,
          Envoi: Types.Text,
          'Cat.': Types.Rectangle,
          Ajout: Types.Text,
          Client: Types.Text,
          Ville: Types.Text,
          Panne: Types.StepMetierName,
          Artisan: Types.artisanRef,
          Prevu: Types.Date,
          Prix: Types.Money,
          iStatus: Types.Chip,
          Points: Types.Points,
          'Qualité support': Types.SupportPoints,
          rSST: Types.Chip,
          Appel: Types.Icon,
        },
        tableData: [
          { class: 'th' },
          { class: '', list: users, key: 'login' },
          { class: '', list: users, key: 'login' },
          { class: '', list: categories.data },
          { class: 'reduce' },
          { class: '' },
          { class: '', name: 'ville' },
          { class: '' },
          {
            class: 'th',
            name: 'artisan',
            list: [],
            key: 'currentArtisan.subStatus',
            status: aSubStatus.data,
            logoArtisan: sourceLogoArtisans,
            sourceArtisan: 'currentArtisan.source',
          },
          { class: 'reduce' },
          { class: '', key: 'onSitePayment' },
          { class: '', list: status.data },
          { class: '' },
          { class: '' },
          {
            class: '',
            name: 'firstPaid',
            key: 'firstPaid',
            list: paidStatus,
          },
          { class: '' },
        ],
        keys: [
          'id',
          'login.ajout',
          'login.envoi',
          'categorie',
          'date.ajout',
          [
            'client.civility',
            'client.name',
            'client.firstname',
            'client.society',
          ],
          ['client.address.zipcode', 'client.address.city'],
          ['metiersSteps.name', 'infoDesc.desc'],
          artisanKeys,
          'date.intervention',
          'announcedPrice',
          'status',
          'points',
          'qualite',
          'firstPaid',
          'appel',
        ],
        checkType: [
          'number',
          'users',
          'users',
          'categorie',
          'date',
          ['client.civility', 'client.name', 'client.firstname'],
          ['client.address.city', 'client.address.zipcode'],
          ['metiersSteps.name', 'infoDesc.desc'],
          artisanKeys,
          'date',
          'prix',
          'iStatus',
          'points',
          'qualite',
          'number',
          'appel',
        ],
      };
      return columns;
    }
    case S_LEAD:
    case S_INT: {
      const columns = {
        table: {
          OS: Types.Text,
          Telepro: Types.Text,
          Envoi: Types.Text,
          ...(permission === ADMIN ? { Service: Types.Text } : {}),
          'Cat.': Types.Rectangle,
          Ajout: Types.Text,
          Client: Types.Text,
          Ville: Types.Text,
          Panne: Types.StepMetierName,
          Artisan: Types.artisanRef,
          Prevu: Types.Date,
          Prix: Types.Money,
          iStatus: Types.Chip,
          Points: Types.Points,
          rSST: Types.Chip,
          Appel: Types.Icon,
        },
        tableData: [
          { class: 'th' },
          { class: '', list: users, key: 'login' },
          { class: '', list: users, key: 'login' },
          ...(permission === ADMIN ? [{ class: '' }] : []),
          { class: '', list: categories.data },
          { class: 'reduce' },
          { class: '' },
          { class: '', name: 'ville' },
          { class: '' },
          {
            class: 'th',
            name: 'artisan',
            list: [],
            key: 'currentArtisan.subStatus',
            status: aSubStatus.data,
            logoArtisan: sourceLogoArtisans,
            sourceArtisan: 'currentArtisan.source',
          },
          { class: 'reduce' },
          { class: '', key: 'onSitePayment' },
          { class: '', list: status.data },
          { class: '' },
          {
            class: '',
            name: 'firstPaid',
            key: 'firstPaid',
            list: paidStatus,
          },
          { class: '' },
        ],
        keys: [
          'id',
          'login.ajout',
          'login.envoi',
          ...(permission === ADMIN ? ['service.desc'] : []),
          'categorie',
          'date.ajout',
          [
            'client.civility',
            'client.name',
            'client.firstname',
            'client.society',
          ],
          ['client.address.zipcode', 'client.address.city'],
          ['metiersSteps.name', 'infoDesc.desc'],
          artisanKeys,
          'date.intervention',
          'announcedPrice',
          'status',
          'points',
          'firstPaid',
          'appel',
        ],
        checkType: [
          'number',
          'users',
          'users',
          ...(permission === ADMIN ? ['service.desc'] : []),
          'categorie',
          'date',
          ['client.civility', 'client.name', 'client.firstname'],
          ['client.address.city', 'client.address.zipcode'],
          ['metiersSteps.name', 'infoDesc.desc'],
          artisanKeys,
          'date',
          'prix',
          'iStatus',
          'points',
          'number',
          'appel',
        ],
      };
      return columns;
    }
    case S_PART: {
      const columns = {
        table: {
          OS: Types.Text,
          Telepro: Types.Text,
          Envoi: Types.Text,
          'Cat.': Types.Rectangle,
          Equipe: Types.Equipe,
          Ajout: Types.Text,
          Client: Types.Text,
          Ville: Types.Text,
          Panne: Types.StepMetierName,
          Artisan: Types.artisanRef,
          Prevu: Types.Date,
          Prix: Types.Money,
          iStatus: Types.Chip,
          Points: Types.Points,
          rSST: Types.Chip,
          Appel: Types.Icon,
        },
        tableData: [
          { class: 'th' },
          { class: '', list: users, key: 'login' },
          { class: '', list: users, key: 'login' },
          { class: '', list: categories.data },
          { class: '' },
          { class: 'reduce' },
          { class: '' },
          { class: '', name: 'ville' },
          { class: '' },
          {
            class: 'th',
            name: 'artisan',
            list: [],
            key: 'currentArtisan.subStatus',
            status: aSubStatus.data,
            logoArtisan: sourceLogoArtisans,
            sourceArtisan: 'currentArtisan.source',
          },
          { class: 'reduce' },
          { class: '', key: 'onSitePayment' },
          { class: '', list: status.data },
          { class: '' },
          {
            class: '',
            name: 'firstPaid',
            key: 'firstPaid',
            list: paidStatus,
          },
          { class: '' },
        ],
        keys: [
          'id',
          'login.ajout',
          'login.envoi',
          'categorie',
          'client.address.zipcode',
          'date.ajout',
          [
            'client.civility',
            'client.name',
            'client.firstname',
            'client.society',
          ],
          ['client.address.zipcode', 'client.address.city'],
          ['metiersSteps.name', 'infoDesc.desc'],
          artisanKeys,
          'date.intervention',
          'announcedPrice',
          'status',
          'points',
          'firstPaid',
          'appel',
        ],
        checkType: [
          'number',
          'users',
          'users',
          'categorie',
          'equipeAreasClient',
          'date',
          ['client.civility', 'client.name', 'client.firstname'],
          ['client.address.city', 'client.address.zipcode'],
          ['metiersSteps.name', 'infoDesc.desc'],
          artisanKeys,
          'date',
          'prix',
          'iStatus',
          'points',
          'number',
          'appel',
        ],
      };
      return columns;
    }
    case S_ACHAT: {
      const columns = {
        table: {
          OS: Types.Text,
          Telepro: Types.Text,
          Rappel: Types.Date,
          'Cat.': Types.Rectangle,
          Ajout: Types.Text,
          Client: Types.Text,
          Ville: Types.Text,
          Panne: Types.StepMetierName,
          Artisan: Types.artisanRef,
          Prevu: Types.Date,
          Prix: Types.Money,
          iStatus: Types.Chip,
        },
        tableData: [
          { class: 'th' },
          { class: '', list: users, key: 'login' },
          { class: 'reduce' },
          { class: '', list: categories.data },
          { class: 'reduce' },
          { class: '' },
          { class: '', name: 'ville' },
          { class: '' },
          {
            class: 'th',
            name: 'artisan',
            list: [],
            key: 'currentArtisan.subStatus',
            status: aSubStatus.data,
            logoArtisan: sourceLogoArtisans,
            sourceArtisan: 'currentArtisan.source',
          },
          { class: 'reduce' },
          { class: '', key: 'onSitePayment' },
          { class: '', list: status.data },
        ],
        keys: [
          'id',
          'login.ajout',
          'date.sstRappelAchat',
          'categorie',
          'date.ajout',
          [
            'client.civility',
            'client.name',
            'client.firstname',
            'client.society',
          ],
          ['client.address.zipcode', 'client.address.city'],
          ['metiersSteps.name', 'infoDesc.desc'],
          artisanKeys,
          'date.intervention',
          'announcedPrice',
          'status',
        ],
        checkType: [
          'number',
          'users',
          'date',
          'categorie',
          'date',
          ['client.civility', 'client.name', 'client.firstname'],
          ['client.address.city', 'client.address.zipcode'],
          ['metiersSteps.name', 'infoDesc.desc'],
          artisanKeys,
          'date',
          'prix',
          'iStatus',
        ],
      };
      return columns;
    }
    default: {
      const columns = {
        table: {
          OS: Types.Text,
          Telepro: Types.Text,
          'Cat.': Types.Rectangle,
          Ajout: Types.Text,
          Client: Types.Text,
          Ville: Types.Text,
          Panne: Types.StepMetierName,
          Artisan: Types.artisanRef,
          Prevu: Types.Date,
          Prix: Types.Money,
          iStatus: Types.Chip,
          rSST: Types.Chip,
          Appel: Types.Icon,
        },
        tableData: [
          { class: 'th' },
          { class: '', list: users, key: 'login' },
          { class: '', list: categories.data },
          { class: 'reduce' },
          { class: '' },
          { class: '', name: 'ville' },
          { class: '' },
          {
            class: 'th',
            name: 'artisan',
            list: [],
            key: 'currentArtisan.subStatus',
            status: aSubStatus.data,
            logoArtisan: sourceLogoArtisans,
            sourceArtisan: 'currentArtisan.source',
          },
          { class: 'reduce' },
          { class: '', key: 'onSitePayment' },
          { class: '', list: status.data },
          {
            class: '',
            name: 'firstPaid',
            key: 'firstPaid',
            list: paidStatus,
          },
          { class: '' },
        ],
        keys: [
          'id',
          'login.ajout',
          'categorie',
          'date.ajout',
          [
            'client.civility',
            'client.name',
            'client.firstname',
            'client.society',
          ],
          ['client.address.zipcode', 'client.address.city'],
          ['metiersSteps.name', 'infoDesc.desc'],
          artisanKeys,
          'date.intervention',
          'announcedPrice',
          'status',
          'firstPaid',
          'appel',
        ],
        checkType: [
          'number',
          'users',
          'categorie',
          'date',
          ['client.civility', 'client.name', 'client.firstname'],
          ['client.address.city', 'client.address.zipcode'],
          ['metiersSteps.name', 'infoDesc.desc'],
          artisanKeys,
          'date',
          'prix',
          'iStatus',
          'number',
          'appel',
        ],
      };
      return columns;
    }
  }
};
