import { Send, GetApp, SmsFailed, Timer } from '@material-ui/icons';
import { blue, green, orange, red, grey } from '@material-ui/core/colors';
import { SMSType } from '@bit/dev-lba.lib.local-globals/smsType';
import { SEND, FAILED } from '@bit/dev-lba.lib.local-globals/smsStatus';

const counters = [
  {
    color: green[500],
    Icon: GetApp
  },
  {
    color: blue[500],
    Icon: Send
  },
  {
    color: orange[500],
    Icon: Timer
  },
  {
    color: red[500],
    Icon: SmsFailed
  }
];

const getIconFromMessage = (message) => {
  if (message.status === FAILED) {
    return counters[3];
  } else if ([SMSType.SMSIN, SMSType.MMSIN].includes(message.type)) {
    return counters[0];
  } else if (message.type === SMSType.SMSOUT && message.status === SEND) {
    return counters[1];
  }
  return counters[2];

};

const getColorByGSM = (gsm) => {
  if (!gsm.enabled) {
    return grey[500];
  }

  const { pending } = gsm;

  if (pending < 10) {
    return green[500];
  } else if (pending < 20) {
    return orange[500];
  }

  return red[500];
};

export { counters, getColorByGSM, getIconFromMessage };
