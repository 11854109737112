/* eslint-disable max-lines */
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { fromJS, List } from 'immutable';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { green } from '@material-ui/core/colors';
import { ArrowDropDown, HighlightOff, CheckCircle } from '@material-ui/icons';
import Chip from '@material-ui/core/Chip';

import { attachFile } from '@bit/dev-lba.lib.local-globals/utils';
import { permit } from '@bit/dev-lba.lib.local-globals/restrictions';
import {
  R_DATAS,
  R_RES,
  R_EC,
  R_PRE_RES,
} from '@bit/dev-lba.lib.local-globals/rStatus';
import { FetchData } from '@bit/dev-lba.lib.local-globals/rConclusions';
import api from '../../../../api';
import notifSystem from '../../../../notifSystem';
import Causes from '../Causes';
import CustomTextField from '../../../CustomInputs/CustomTextField';
import PriseDeContactFooter from '../PriseDeContactFooter';
import InterventionsArray from './InterventionsArray';
import Resulo from '../ResoluComponent';
import DialogConcl from './DialogConcl';
import Historiques from '../Historiques';
import withExpansionPanel from '../../../../hoc/withExpansionPanel';
import SubStatusDevis from '../subStatusDevis';


const styles = (theme) => ({
  center: {
    textAlign: 'center',
  },
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    margin: theme.spacing(1),
  },
  buttons: {
    padding: `${theme.spacing(2)}px 0`,
  },
  button: {
    margin: `${theme.spacing(1)}px 0`,
  },
  dialogContentSize: {
    maxHeight: 300,
  },
  paddingComments: {
    padding: '2px 33px 24px',
  },
  table: {
    minWidth: 700,
  },
});

const GenConclusions = ({
  classes,
  id,
  inters,
  setConclusion,
  names,
}) =>
  <Grid item xs={4} className={classes.center}>
    <Button
      variant="outlined"
      color="secondary"
      style={{
        color: id === 2
          && inters.getIn([0, 'recouvrement', 'negociating'])
          && green[500],
        borderColor: id === 2
          && inters.getIn([0, 'recouvrement', 'negociating'])
          && green[500],
        padding: id === 2 ? 10 : 8
      }}
      onClick={() => setConclusion(id)}
    >
      {names[id]}
      {(id === 2
        && (inters.getIn([0, 'recouvrement', 'negociating'])
          ? <CheckCircle style={{ color: green[500] }} />
          : <HighlightOff />
        ))
        || <ArrowDropDown />}
    </Button>
  </Grid>;

const ExpandedInterventions = withExpansionPanel(InterventionsArray);
const ExpandedCommentaires = withExpansionPanel(Historiques);

const mapStateToProps = state => ({
  user: state.users.find(u => u._id === state.userId),
  users: state.users
});

class PriseDeContactDialogSst extends Component {
  state = {
    inters: fromJS(this.props.defaultText.interventions),
    loading: false
  }

  cancel = () => {
    if (typeof this.props.callback === 'function') {
      this.props.callback(null);
    }
  }

  onValidate = () => {
    let { inters, mail } = this.state;
    const {
      callback,
      user,
    } = this.props;
    this.setState({ loading: true });
    if (!inters.getIn([0, 'recouvrement', 'commentaire']) ||
      !inters.getIn([0, 'recouvrement', 'status']) ||
      !inters.getIn([0, 'recouvrement', 'cause'])) {
      return notifSystem.error(
        'Pour valider votre prise de contact',
        'Merci de mettre un statut, un commentaire et une cause'
      );
    }
    if (Object.keys(mail || {}).length && (
      !mail.text || (/<Modifiable>/).test(mail.text) || !mail.desMail || !mail.title
    )) {
      return notifSystem.error(
        'Erreur mail',
        'Veuillez vérifier le corps du mail,' +
        ' le destinataire, le titre et <Modifiable>'
      );
    }
    if (typeof callback === 'function') {
      inters = inters.map(e =>
        e.setIn(['recouvrement', 'historique'],
          e.getIn(['recouvrement', 'historique']).concat([{
            userId: user._id,
            comments: e.getIn(['recouvrement', 'commentaire']),
            date: new Date(),
            conclusions: e.getIn(['recouvrement', 'conclusions'])
          }])));
      callback(inters, mail);
      this.setState({ loading: false });
    }
  }

  handleCauseRecouvrement = (e) => {
    this.setState({ cause: Number(e.target.value) });
  }

  setData = (path, value) => {
    this.setState({
      inters: this.state.inters.setIn(path, value)
    });
  }

  setCheckedGlobal = value => this.setDataGlobal(['checked'], value)

  setDataGlobal = (path, value) => {
    this.setState(({ inters }) => ({
      inters: inters.map(e => e.setIn(path, value))
    }));
  }

  setStateVar = name => e => this.setState({ [name]: e.target.value })

  setConclusion = id => {
    let { inters } = this.state;

    if (id === 2) {
      const status = inters.getIn([0, 'recouvrement', 'negociating'], false);
      this.setDataGlobal(['recouvrement', 'negociating'], !status);
      if (!status) {
        this.setDataGlobal(['recouvrement', 'status'], R_EC);
      }
      return this.setState({
        selected: -1,
        conclusionOpen: false
      });
    }
    this.setState({
      conclusionOpen: true,
      selected: id
    });
  }

  handleCheckboxes = (id) => {
    const fId = Number(id[0]);
    const { user, defaultText: { type } } = this.props;
    const { inters, mail } = this.state;
    const { mailsText, mails } = FetchData[type];
    let ret = inters.getIn([0, 'recouvrement', 'conclusions'],
      new List());
    const index = ret.indexOf(fId);
    if (index === -1) {
      ret = ret.concat([fId]);
    } else {
      ret = ret.delete(index);
    }
    const hasMailInCon = ret.find(e => mails.includes(e));
    let findMail = ret.find(e => mails.includes(e));
    if (findMail && hasMailInCon) {
      findMail = mailsText.find(e => e._id === fId);
      if (findMail && mail && mail.text) {
        return notifSystem.error('Erreur',
          'Merci de désélectionner le premier mail'
        );
      }
      const intervention = inters.toJS();
      return this.setState({
        mail: findMail ? {
          text: findMail.mail(intervention, user),
          name: findMail.name,
          title: findMail.name,
          attachements: [],
          desMail: findMail.desMail(intervention)
        } : mail,
      }, () => this.setDataGlobal(['recouvrement', 'conclusions'], ret));
    }
    return this.setState(
      { mail: hasMailInCon ? mail : {} },
      () => this.setDataGlobal(['recouvrement', 'conclusions'], ret)
    );
  }

  setMail = (path, value) => {
    let mail = Object.assign({}, this.state.mail);
    mail[path] = value;
    this.setState({ mail });
  }

  deleteComment = async (id, index) => {
    try {
      let { inters } = this.state;
      if (id > -1) {
        inters = inters.deleteIn([index, 'recouvrement', 'historique', id]);
      }
      await api.recouvrements.patch(
        inters.getIn([index, 'recouvrement', '_id']),
        {
          recouvrement: {
            id: inters.getIn([index, 'recouvrement', 'id']),
            historique: inters
              .getIn([index, 'recouvrement', 'historique'])
              .toJS(),
            isPris: inters.getIn([index, 'recouvrement', 'isPris']),
          },
        }
      );
      this.setState({ inters });
      return notifSystem.success(
        'Message',
        'Le commentaire a bien été supprimé'
      );
    } catch (error) {
      return notifSystem.error(
        'Erreur',
        'Le commentaire n\'a pas été supprimé'
      );
    }
  }

  reset = () => {
    this.setState({
      inters: fromJS(this.props.defaultText.interventions),
      mail: {}
    });
  }

  setResulo = (path, e) =>
    this.setDataGlobal(path, e);

  handleChipDelete = (index) => {
    let mail = Object.assign({}, this.state.mail);
    mail.attachements.splice(index, 1);
    const ret = mail.attachements.slice(0);
    this.setState({
      mail: {
        ...mail,
        attachements: ret,
      }
    });
  }

  addFile = (e) => {
    const { mail } = this.state;

    attachFile(e.target.files[0]).then(attachment =>
      this.setState({
        mail: {
          ...mail,
          attachements: mail.attachements.concat(attachment)
        }
      })
    );
  }

  render() {
    const {
      open,
      classes,
      loading,
      user,
      users,
      defaultText: { type },
    } = this.props;
    const {
      inters,
      conclusionOpen = false,
      selected = -1,
      mail = {},
    } = this.state;
    const { causes, keys, names, obj } = FetchData[type];

    return (
      <Dialog open={open} fullWidth maxWidth={'md'}>
        {!conclusionOpen ? (
          <React.Fragment>
            <DialogTitle>
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <Typography variant="h6">Prise de contact</Typography>
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent className={classes.dialogContentSize}>
              <ExpandedInterventions
                classes={classes}
                expansionTitle='Interventions'
                causes={causes}
                data={inters}
                setCheckedGlobal={this.setCheckedGlobal}
                setData={this.setData}
              />
            </DialogContent>
            <DialogContent>
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                className={classes.button}
              >
                Conclusion
              </Button>
              <Grid
                container
                spacing={3}
                justify="center"
                className={classes.buttons}
              >
                {keys.map((e, id) => (
                  <GenConclusions
                    key={id}
                    id={id}
                    inters={inters}
                    setConclusion={this.setConclusion}
                    classes={classes}
                    names={names}
                  />
                ))}
              </Grid>
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                className={classes.button}
                children="Statut du recouvrement"
              />
              <Grid>
                <Causes
                  fetchData={R_DATAS.filter(e =>
                    permit(user, {
                      key: 'displayStatusPriseDeContactDialog',
                      auth: e.perm,
                    })
                  )}
                  handleChange={e =>
                    this.setDataGlobal(
                      ['recouvrement', 'status'],
                      Number(e.target.value)
                    )
                  }
                  value={inters.every(e =>
                    e.getIn(['recouvrement', 'status'], '')) ?
                    inters.getIn([0, 'recouvrement', 'status'], '') : ''}
                  isPris={inters.every(e =>
                    e.getIn(['recouvrement', 'isPris'], false))}
                />
              </Grid>
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                className={classes.button}
                children={'Sous-status devis'}
              />
              <SubStatusDevis
                handleChange={e =>
                  this.setDataGlobal(
                    ['recouvrement', 'subStatusDevis'],
                    Number(e.target.value)
                  )
                }
                value={inters.every(e =>
                  e.getIn(['recouvrement'], '')) ?
                  inters.getIn([0, 'recouvrement', 'subStatusDevis'], '') : ''}
              />
              <Grid container spacing={3} justify="center">
                {inters.every(e =>
                  [R_RES, R_PRE_RES].includes(
                    e.getIn(['recouvrement', 'status'], '')
                  )) ? (
                    <Resulo
                      setData={(p, e) =>
                        this.setResulo(['recouvrement', ...p], e)
                      }
                      recouvrement={inters.getIn([0, 'recouvrement']).toJS()}
                    />
                  ) : null}
              </Grid>
              <Paper className={classes.root} elevation={1}>
                <CustomTextField
                  grid
                  xs={12}
                  texttransform="none"
                  rows={4}
                  label="Commentaire..."
                  value={inters.getIn([0, 'recouvrement', 'commentaire'], '')}
                  setData={this.setDataGlobal}
                  path={['recouvrement', 'commentaire']}
                />
              </Paper>
            </DialogContent>
            <DialogContent
              className={classNames(
                classes.dialogContentSize,
                classes.paddingComments
              )}
            >
              <ExpandedCommentaires
                classes={classes}
                expansionTitle='Commentaires'
                deleteComment={this.deleteComment}
                inters={inters}
                users={users}
              />
            </DialogContent>
            {!!(mail.attachements || []).length &&
              < DialogContent key="files">
                {Object.values(mail.attachements).map((element, index) => (
                  <Chip
                    key={index}
                    label={element.name}
                    onDelete={() => this.handleChipDelete(index)}
                  />
                ))}
              </DialogContent>}
            <PriseDeContactFooter
              attachements={!!mail.attachements}
              addFile={this.addFile}
              reset={this.reset}
              send={this.onValidate}
              cancel={this.cancel}
              loading={loading}
            />
          </React.Fragment>
        ) : (
          <DialogConcl
            mail={mail}
            names={names}
            keys={keys}
            obj={obj}
            setMail={this.setMail}
            conclusionOpen={conclusionOpen}
            handleCheckboxes={this.handleCheckboxes}
            selected={selected}
            setDataGlobal={this.setDataGlobal}
            setConclusionDialog={this.setStateVar('conclusionOpen')}
            recouvrement={inters.getIn([0, 'recouvrement'])}
            type={type}
          />
        )}
      </Dialog>
    );
  }
}
PriseDeContactDialogSst.defaultProps = {
  validateText: 'Valider',
  cancelText: 'Annuler',
  maxWidth: 'sm',
  fullWidth: false
};

export default compose(
  connect(mapStateToProps),
  withStyles(styles)
)(PriseDeContactDialogSst);
