import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Immutable from 'immutable';

import {
  withStyles,
  withTheme
} from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { grey } from '@material-ui/core/colors';

import
artisanFilters from '@bit/dev-lba.lib.local-globals/Filters/ArtisanFilters';
import interFilters from '@bit/dev-lba.lib.local-globals/Filters/InterFilters';
import devisFilters from '@bit/dev-lba.lib.local-globals/Filters/DevisFilters';
import savFilters from '@bit/dev-lba.lib.local-globals/Filters/SAVFilters';
import btobFilters from '@bit/dev-lba.lib.local-globals/Filters/BtoBFilters';
import
candidatFilters from '@bit/dev-lba.lib.local-globals/Filters/CandidatFilters';
import
demandesFilters from '@bit/dev-lba.lib.local-globals/Filters/DemandesFilters';

import { data as dataServices } from '@bit/dev-lba.lib.local-globals/services';
import { permit } from '@bit/dev-lba.lib.local-globals/restrictions';
import { USER, ADMIN } from '@bit/dev-lba.lib.local-globals/permissions';

import config from '../../config';
import ListButtons from '../ListButtons';
import {
  cacheDisabled,
} from '../../utils/function';
import { SHOW_ENR } from '@bit/dev-lba.lib.local-globals/configTypes';

const styles = {
  ListIcon: {
    minWidth: 'initial'
  },
  button: {
    width: '80%',
    marginBottom: 12,
    fontSize: '12.5px'
  },
  buttonList: {
    paddingTop: 16,
    textAlign: 'center',
    overflowY: 'hidden',
  },
  bold: {
    color: '#fff',
    fontSize: '13px',
    fontWeight: 'bold',
    lineHeight: '2em'
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
  },
  svg: {
    fill: 'white',
    margin: 0,
  },
  text: {
    color: '#ddd',
    fontSize: '13px',
    lineHeight: '2em'
  },
  linkTxt: {
    padding: '0 8px',
    marginTop: 0,
    marginBottom: 0
  },
  list: {
    padding: 0,
    textAlign: 'left',
  },
  avatarContainer: {
    marginTop: '-16px',
    right: '14px',
    transform: 'scale(0.75, 0.75)',
  },
  avatar: {
    width: '50px',
    height: '30px',
    fontSize: '1rem',
    borderRadius: 0
  },
  buttonAvatar: {
    marginLeft: 8,
    width: 'auto',
    height: 'auto',
    padding: '2px 3px',
    fontSize: 14,
    backgroundColor: 'red',
    borderRadius: 5
  }
};

const mapStateToProps = (state) => ({
  points: state.points,
  counter: state.counter,
  users: state.users,
  userId: state.userId,
  user: state.users.find((u) => u._id === state.userId),
  grandCompte: state.grandCompte,
});

const checkCommuncation = (name) =>
  name !== 'Cti' || !cacheDisabled(SHOW_ENR);

const arrayMatchWords = [
  '/litige',
  '/recouvrementSst',
  '/recouvrementClient',
  '/demandePhoto',
  '/recouvrementBtob',
  '/demandes'
];
const openDefault = {
  '0-2': true,
  '0-3': true,
  '0-4': true,
  '0-5': true,
  '0-6': true,
  '0-7': true,
  '4-1': true,
  '4-2': true,
  '4-3': true,
  '6-3': true,
  '6-4': true,
  '6-5': true,
  '6-6': true,
};
export class Sidebar extends Component {
  state = {
    interventions: interFilters(this.props.users),
    devis: devisFilters(this.props.users),
    artisans: artisanFilters(this.props.users),
    savInterventions: savFilters(this.props.users),
    btobs: btobFilters(this.props.users),
    candidats: candidatFilters(this.props.users),
    demandes: demandesFilters(this.props.users.toJS()),
    open: openDefault,
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (this.state.user
      && (this.state.user.service
      !== nextState.user.service
      || this.state.user.permission
      !== nextState.user.permission))
      || JSON.stringify(nextState.open)
      !== JSON.stringify(this.state.open)
      || JSON.stringify(nextProps.counter)
      !== JSON.stringify(this.props.counter)
      || JSON.stringify(nextProps.points)
      !== JSON.stringify(this.props.points);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.user && props.user !== state.user) {
      const openState = Object.assign({}, state.open);
      if (state.user) {
        delete openState[state.user.service - 1];
      }
      return {
        user: props.user,
        open: {
          ...openState,
          ...dataServices
            .find((e) => e._id === props.user.service)
            .sideBarDefaultOpens.reduce(
              (acc, curr) => ({
                ...acc,
                [
                config.sidebar.buttons
                  .findIndex(s => s.services.includes(curr))
                ]: true
              }),
              {}
            ),
        },
      };
    }
    return null;
  }

  genCounter = (classes, e) => {
    const split = e.link.split('/');
    const counter = this.getCurrentCount(split, e.countMatch);
    if (counter > 0)
    {
      return (<ListItemSecondaryAction className={classes.avatarContainer}>
        <Avatar className={classes.avatar}
          style={this.getCurrentColor(split)}>
          {counter}
        </Avatar>
      </ListItemSecondaryAction>);
    }
    return null;
  }

  getCurrentCount(split, countMatch) {
    let name = `${split[1].replace(/s?$/, 's')}.${countMatch || split[3]}`;
    if (split.length === 5)
    {
      name = `${name}.${split[4].split('=')[1]}`;
    }
    return this.props.counter[name] || '0';
  }

  getCurrentColor(split) {
    let collection = split[1].replace(/s?$/, 's');
    const color = this.state[collection][split[3]]?.color || grey;
    return {
      backgroundColor: color['500'],
      color: '#fff',
    };
  }

  determinedStateSidebar(link) {
    this.props.history.push(link);
    this.props.handlerMobileOpenSideBar();
  }

  handlerOpenList(stateId) {
    const open = Object.assign({}, this.state.open);
    open[stateId] = !open[stateId];
    this.setState({ open });
  }

  genList(list, depth = []) {
    const {
      classes,
      users,
      user,
      userId,
      grandCompte,
    } = this.props;
    list = list instanceof Immutable.List ? list.toJS() : list;
    return user && Array.isArray(list) && list.map((e, id) => {
      const stateId = [...depth, id].join('-');
      const display = permit(user, { key: stateId, auth: USER });
      e = Object.assign({}, e);
      if (typeof e.link === 'function') {
        e.link = e.link(user);
      } else if (typeof e.childs === 'function') {
        e.childs = e.childs({
          users,
          user,
          grandCompte,
        });
      } else if (typeof e.name === 'function') {
        e.name = e.name(user);
      }
      return checkCommuncation(e.name) &&
        (display || user.permission === ADMIN) ? [
          <ListItem
            button
            divider
            key={id}
            style={{
              padding: `3.7px 16px 3.7px ${16 + (depth.length * 8)}px`,
            }}
            onClick={
              e.link ?
                () => this.determinedStateSidebar(e.link) :
                () => this.handlerOpenList(stateId)
            }
          >
            <ListButtons
              data={e} classes={classes} users={users}
              open={this.state.open[stateId]} userId={userId}
              genCounter={this.genCounter}
            />
          </ListItem>,
          e.childs &&
        <Collapse
          in={this.state.open[stateId]}
          timeout="auto"
          unmountOnExit key={`${id}-childs`}
        >
          {this.genList(e.childs, [...depth, id])}
        </Collapse>
        ] : null;
    });
  }

  getLink(e, id) {
    const { user, classes, counter, handlerMobileOpenSideBar } = this.props;
    const userCounter = user && e.counterName
      && counter[`${e.counterName}.${user.login}`];
    const buttonProps = {
      ...(typeof e.props === 'function' ? e.props(this.props) : {}),
    };
    return !arrayMatchWords.includes(e.link) ? (
      <Link
        to={{ pathname: e.link, query: 'new' }}
        className={classes.link}
        key={id}
        {...buttonProps}
      >
        <Button
          {...buttonProps}
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={() => handlerMobileOpenSideBar()}
        >
          {e.name}
          {userCounter ? (
            <Avatar className={classes.buttonAvatar}>{userCounter}</Avatar>
          ) : (
            ''
          )}
        </Button>
      </Link>
    ) : (
      <Button
        key={id}
        variant="contained"
        color="primary"
        onClick={() => this.props.setDialog(e.link)}
        className={classes.button}
      >
        {e.name}
      </Button>
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <div className={classes.buttonList}>
          {config.sidebar.addButton.map(
            (e, id) => e.customVisible(this.props) && this.getLink(e, id)
          )}
          <Divider />
          <List className={classes.list}>
            {this.genList(config.sidebar.buttons)}
          </List>
        </div>
      </React.Fragment>
    );
  }
}

export default compose(
  withTheme,
  withStyles(styles),
  connect(mapStateToProps)
)(Sidebar);
