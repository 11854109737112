export default {
  userList: {
    width: '100%',
  },
  userGrid: {
    heigth: 600,
    overflow: 'auto'
  },
  statsGrid: {
    padding: 40,
    paddingTop: 0
  },
  graph: {
    marginTop: 40
  },
  sheet: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 40
  },
  sheetPaper: {
    width: '210mm',
    height: '297mm',
    backgroundColor: 'white',
    position: 'relative',
    border: '1px solid black'
  },
  sheetFooter: {
    backgroundColor: '#1B2150',
    position: 'absolute',
    bottom: 0,
    height: 50,
    width: '100%',
    backgroundImage: '-webkit-linear-gradient(-30deg, #1B2150 80%, white 20%)'
  },
  sheetTitleDiv: {
    margin: '10mm',
    textAlign: 'center'
  },
  sheetTitle: {
    border: '3px solid #1B2150',
    padding: '5mm'
  },
  sheetLeft10: {
    marginLeft: '10mm'
  },
  sheetLeft5: {
    marginLeft: '5mm'
  },
  sheetTitleText: {
    color: '#1B2150',
    fontWeight: 'bold',
    fontSize: 'large'
  },
  sheetTitleRed: {
    color: '#c00000',
    fontWeight: 'bold',
    fontSize: 'large'
  },
  sheetCounterDiv: {
    paddingLeft: '10mm',
    paddingRight: '10mm',
    width: '100%'
  },
  sheetTable: {
    width: '100%',
    marginBottom: '10mm'
  },
  downloadButton: {
    position: 'absolute',
    right: 5
  },
  logo: {
    position: 'absolute',
    height: '80%',
    bottom: 5,
    right: 5
  },
  input: {
    width: '80%'
  },
  comment: {
    color: 'grey'
  }
};
