import React from 'react';
import { PropTypes } from 'prop-types';

import MoneyInput from '../CustomInputs/MoneyInput';
import { Typography, Grid } from '@material-ui/core';
import { connect } from 'react-redux';

import { setDialogContentProp } from '../../actions/dialog';

const setPrice = setDialogContentProp('price');

const mapDispatchToProps = {
  setPrice,
};
const mapStateToProps = ({
  dialog: {
    contentProps: { subTitle = 'Modification', price = 0 },
  },
}) => ({
  subTitle,
  price,
});

const InputDialog = ({ price, subTitle, setPrice }) => (
  <Grid container spacing={2}>
    <Grid
      item
      xs={12}
      sm={12}
      lg={12}
      children={<Typography variant="subtitle1">{subTitle}</Typography>}
    />
    <Grid
      item
      xs={12}
      sm={12}
      lg={12}
      children={
        <MoneyInput
          label="Prix"
          type="number"
          defaultValue={price}
          path={[]}
          setData={(e, value) => setPrice(value)}
        />
      }
    />
  </Grid>
);

InputDialog.propTypes = {
  title: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(InputDialog);
