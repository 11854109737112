import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Route, withRouter, Switch } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import TrackingUsers from '../components/TrackingUsers';
import Notifications from '../components/Notifications';
import initEvents from '../components/Events';
import Login from './Login';
import Dash from '../components/Dashboard/Dash';
import DialogManager from './DialogManager';
import { getLStorageData } from '../utils/localStorgeFunctions';
import ws from '../ws';
import { checkMessagesToAnswer } from '../actions/general';
import ResetPassword from '../components/ResetPassword';
import LoadingManager from './LoadingManager';

const mapStateToProps = ({ auth }) => ({ auth });

const styles = (theme) => ({
  elem: {
    position: 'absolute',
    top: 56,
    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
      top: 48,
    },
    [theme.breakpoints.up('sm')]: {
      top: 64,
    },
    bottom: 0,
    transition: 'left 0.3s',
    overflowY: 'overlay',
    overflowX: 'hidden',
  },
  sidebar: {
    width: 250,
    backgroundColor: '#333',
    zIndex: 2,
  },
  main: {
    backgroundColor: 'lightgray',
  },
  fullHeight: {
    height: '100%',
  },
});

class Dashboard extends Component {
  state = {
    user: null,
  };

  componentDidMount() {
    try {
      this.props.history.replace({
        state: '',
        search: this.props.location.search,
      });
    } catch (e) {
      return false;
    }
    initEvents();
    if (Object.keys(ws).length && ws.socket) {
      ws.on('customMessage', this.wsUpdate);
    }
  }

  componentWillUnmount() {
    ws.removeEventListener('customMessage', this.wsUpdate);
  }

  wsUpdate = (data) => {
    const { auth = {}, history = {} } = this.props;
    const { decodedToken = {} } = auth;
    if (
      data.type === 'toAnswer' &&
      decodedToken._id === data.userId &&
      !((history.location || {}).pathname || '').includes('openSMS')
    ) {
      checkMessagesToAnswer({ ...data, history });
    }
  };

  static getDerivedStateFromProps() {
    getLStorageData('Inter');
    getLStorageData('Devis');
    return null;
  }

  render() {
    const { classes, auth, history, location } = this.props;
    const connected = auth && auth.token;
    return (
      <React.Fragment>
        <div className={connected ? classes.fullHeight : ''}>
          <Switch>
            {!connected && [
              <Route
                path="/resetPassword/:id"
                render={(props) => <ResetPassword {...props} />}
              />,
              <Route
                render={() => <Login history={history} location={location} />}
              />,
            ]}
            {connected && <Route render={() => <Dash auth={auth} />} />}
          </Switch>
          <Notifications />
        </div>
        <DialogManager />
        <LoadingManager />
        {connected && <TrackingUsers auth={auth} />}
      </React.Fragment>
    );
  }
}

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps)
)(Dashboard);
