import React, { PureComponent } from 'react';
import { Dialog } from '@material-ui/core';
import StatsPanel from '../common/panel/StatsPanel';
import { moment } from '@bit/dev-lba.lib.local-globals/moment';
import ReactEcharts from 'echarts-for-react';
import { getOption } from '../common/graph/options/globalOptions';
import { green } from '@material-ui/core/colors';
import api from '../../../api';

class GrandCompteStats extends PureComponent {

    state = {
      mode: 'year',
      from: moment().startOf('year').toDate(),
      to: moment().endOf('year').toDate(),
    };

    onDatesChange = (from, to, mode) => {
      this.setState({ from, to, mode }, () => this.doRequests());
    }

    componentDidUpdate(prevProps) {
      if (prevProps.grandCompte !== this.props.grandCompte) {
        this.doRequests();
      }
    }

    doRequests = () => {
      const { grandCompte } = this.props;
      const { mode, from, to } = this.state;

      if (grandCompte) {
        api.stats.get('btob/CA', {
          from, to, mode,
          grandCompte: grandCompte._id
        }).then(res => {
          this.setState({ data: res.body().data().data });
        });
      }
    }

    render() {
      const { grandCompte = false, onClose } = this.props;
      const { data, mode, from, to } = this.state;
      return (
        <Dialog
          open={grandCompte}
          onClose={() => onClose()}
          maxWidth={'md'}
          fullWidth={true}
        >
          <StatsPanel
            title={grandCompte ? grandCompte.companyName : ''}
            onDatesChange={this.onDatesChange}
            defaultState={{
              mode,
              from,
              to,
            }}
          >

            {data && <ReactEcharts
              notMerge={true}
              lazyUpdate={true}
              option={getOption({
                data,
                mode,
                labels: Object.keys(data),
                colors: [green[500]],
                dates: [moment(from), moment(to)]
              })}/> }

          </StatsPanel>
        </Dialog>
      );
    }

}


export default GrandCompteStats;
