import React, { PureComponent } from 'react';
import {
  Typography, Button, Paper, CircularProgress
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { DateRange } from '@material-ui/icons';
import Zoom from '@material-ui/core/Zoom';
import Drawer from '@material-ui/core/SwipeableDrawer';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import MomentLocaleUtils from 'react-day-picker/moment';
import { moment } from '@bit/dev-lba.lib.local-globals/moment';
import styles from '../../../../utils/stylesStatsPanel';
import 'moment/locale/fr';
import { modes } from '@bit/dev-lba.lib.local-globals/stats';

const MODE_ADAPTER = {
  day: 'jour',
  week: 'semaine',
  month: 'mois',
  year: 'année',
  years: 'années',
};

const modifiersStyles = {
  selected: {
    color: '#4a90e2',
    backgroundColor: '#f0f8ff',
  },
};

const defaultEnabledModes = [true, true, true, true, false];

class StatsPanel extends PureComponent {
  enabledModes = this.props.enabledModes || defaultEnabledModes;

  futures = [];

  range = this.props.range || [true, false, false, false, true];
  mode = (this.props.defaultState && this.props.defaultState.mode) || 'day';
  defaultState = this.props.defaultState;

  state = {
    mode: this.mode,
    hover: false,
    panel: [
      { id: 0,
        title: 'Date de départ',
        display: true,
        open: false,
        from:
          (this.defaultState?.from) ||
          moment().startOf('day').toDate(),
        to:
          (this.defaultState?.to) ||
          moment().endOf('day').toDate()
      },
      { id: 1,
        title: 'Date de fin',
        display: this.props.multiDates,
        open: false,
        from: moment().startOf('day').toDate(),
        to: moment().endOf('day').toDate()
      }
    ]
  };

  componentDidMount() {
    window.addEventListener('click', this.closePanel);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.defaultState.mode !== this.props.defaultState.mode) {
      this.setState({
        mode: this.props.defaultState.mode
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.closePanel);
  }

  toggleModePanel = (open) => {
    this.futures.forEach((future) => clearTimeout(future));
    this.setState({ hover: open });
  };

  selectMode = (mode) => {
    const { onDatesChange, multiDates } = this.props;
    const { panel } = this.state;
    const initialDates = this.getInitialState(mode);

    if (typeof onDatesChange === 'function' && multiDates) {
      onDatesChange(panel, mode);
    } else if (typeof onDatesChange === 'function') {
      onDatesChange(initialDates.from, initialDates.to, mode);
    }

    this.setState((prevState) => {
      const { multiDates } = this.props;
      let panel = [ ...prevState.panel ];
      const initialFrom = this.getInitialState(mode, panel[0].from);
      const compareFrom = this.getInitialState(mode, panel[0].from, 1);
      panel.forEach((panel, i) => {
        if (i === 0 && multiDates) {
          panel.from = initialFrom.from;
          panel.to = initialFrom.to;
        } else if (i === 1 && multiDates) {
          panel.from = compareFrom.from;
          panel.to = compareFrom.to;
        } else {
          panel.from = initialDates.from;
          panel.to = initialDates.to;
        }
        return panel;
      });
      return { panel, mode };
    });
  };

  UNSAFE_componentWillReceiveProps(props) {
    if (
      props.enabledModes &&
      !props.enabledModes.every((v, i) => v === this.enabledModes[i])
    ) {
      this.enabledModes = props.enabledModes;
    } else if (!props.enabledModes) {
      this.enabledModes = defaultEnabledModes;
    }
  }

  generateModePanel = () => {
    const { classes } = this.props;
    const { mode, hover, panel } = this.state;
    const panelIsOpen = panel.some(e => e.open === true);
    const condition = (m) => m === mode || hover || panelIsOpen;
    return (
      <div
        className={classes.flex}
        onMouseEnter={() => this.toggleModePanel(true)}
        onMouseLeave={() =>
          this.futures.push(setTimeout(() => this.toggleModePanel(false), 3000))
        }
      >
        <div className={classes.verticalAlign}>
          <div className={classes.verticalAlignItem}>
            <div>
              {!hover && !panelIsOpen ? (
                <span>&#x2039;</span>
              ) : (
                <span>&#x203A;</span>
              )}
            </div>
          </div>
        </div>
        {Object.keys(modes)
          .filter((m, i) => this.enabledModes[i])
          .map((m, i) => (
            <Zoom
              key={i}
              in={condition(m)}
              {...(condition(m) ? { timeout: (i + 1) * 300 } : {})}
            >
              <Button
                className={classes.button}
                color={m === mode ? 'primary' : 'default'}
                style={{
                  display: condition(m) ? 'block' : 'none',
                  position: condition(m) ? 'relative' : 'absolute',
                }}
                onClick={() => this.selectMode(m)}
              >
                {MODE_ADAPTER[m]}
              </Button>
            </Zoom>
          ))}
      </div>
    );
  };

  getInitialState = (mode, start = null, add = 0) => ({
    from:
      mode !== 'years'
        ? moment(start || new Date()).startOf(mode).add(mode, add).toDate()
        : moment(start || new Date()).year(2019).startOf(mode)
          .add(mode, add).toDate(),
    to: moment(start || new Date()).endOf(mode).add(mode, add).toDate(),
  });

  handleDayClick = (day, modifiers, i) => {
    if (modifiers.disabled) {
      return;
    }
    const { mode, panel } = this.state;
    const { multiDates, onDatesChange } = this.props;
    const range = DateUtils.addDayToRange(day, panel[i]);

    const rangeEnabled =
      mode !== 'day' &&
      Object.keys(modes).filter((m, i) => m === mode && this.range[i]).length;

    range.from = moment(rangeEnabled ? range.from : day)
      .startOf(mode)
      .toDate();
    range.to = moment(rangeEnabled ? range.to : day)
      .endOf(mode)
      .toDate();

    if (onDatesChange && !multiDates) {
      this.props.onDatesChange(range.from, range.to, mode);
    }

    this.setState(prevState => {
      let panel = [ ...prevState.panel ];
      panel[i].from = range.from;
      panel[i].to = range.to;
      const fromDate = this.getInitialState(mode, panel[0].from, 1);
      const toDate = this.getInitialState(mode, panel[0].to, 1);
      if (i === 0 && multiDates) {
        panel[1].from = fromDate.from;
        panel[1].to = mode === 'day' ? fromDate.to : toDate.to;
      }
      if (onDatesChange && multiDates) {
        this.props.onDatesChange(panel, mode);
      }
      return { panel };
    });
  };

  generateText(from, to, mode, range) {
    let text = '';

    range = range[Object.keys(modes).indexOf(mode)];

    if ((range && !isNaN(to.getTime())) || mode !== 'day') {
      if (!from || isNaN(from.getTime())) {
        text = `Dates sélectionnés: ${to.toLocaleDateString('fr')}`;
      } else {
        text = `Dates sélectionnés du ${from.toLocaleDateString(
          'fr'
        )} au ${to.toLocaleDateString('fr')}`;
      }
    } else if (!range) {
      text = `Dates sélectionnés: ${from.toLocaleDateString('fr')}`;
    } else {
      text = 'Aucune date selectionné';
    }
    return (
      <p style={{ textAlign: 'center' }}>
        {text}
        <b />
      </p>
    );
  }

  openPanel = (e, i) => {
    e.stopPropagation();
    this.setState(prevState => {
      let panel = [ ...prevState.panel ];
      panel.forEach(panel => {
        if (panel.id !== i) {
          panel.open = false;
          return panel;
        }
        panel.open = !panel.open;
      });
      return { panel };
    });
  }

  closePanel = () => {
    this.setState(prevState => {
      let panel = [ ...prevState.panel ];
      panel.forEach(panel => {
        panel.open = false;
        return panel;
      });
      return { panel };
    });
  }

  render() {
    const {
      classes,
      title,
      body,
      children,
      loading,
      subTitle,
      hideMenu,
      displayModePanel,
      displayDatesDropper,
      MainComponent = 'div',
      titleProps,
      multiDates
    } = this.props;
    const { mode, panel } = this.state;

    return (
      <MainComponent className={classes.root}>
        <div className={classes.container}>
          <div style={{ width: hideMenu ? '100%' : '' }}>
            <Typography
              component="h3"
              variant="h6"
              className={classes.title}
              {...titleProps}>
              {title}
            </Typography>
            {subTitle &&
              <Typography variant="body1" className={classes.subTitle}>
                {subTitle}
              </Typography>}
          </div>
          {body}
          {!hideMenu && <div className={classes.rightMenu}>
            {displayModePanel && this.generateModePanel()}
            {displayModePanel && displayDatesDropper && (
              <div className={classes.divider} />
            )}
            {displayDatesDropper && panel.map((e, i) => e.display && (
              <DateRange
                style={{ color: !e.open ? 'grey' : '#2196f3' }}
                onClick={(e) => this.openPanel(e, i)}
                className={classes.dateSettings}
              />
            )) }
          </div>}
        </div>
        {panel.map((e, i) => e.display && e.open && (
          <div
            className={classes.datesPanel}
            style={e.open ? {} : { ...styles.displayNone }}
          >
            <Drawer
              variant="persistent"
              anchor={'right'}
              open={e.open}
              onOpen={(e) => e}
              onClose={() => this.closePanel()}
              classes={{
                paper: classes.drawerPaper,
              }}
              onClick={(e) => e.stopPropagation()}
            >
              <Paper className={classes.drawerHeader}>
                {multiDates && <p
                  style={{ textAlign: 'center', fontWeight: 'bold' }}
                >
                  {e.title}
                </p>}
                {this.generateText(e.from, e.to, mode, this.range)}
                <DayPicker
                  locale="fr"
                  numberOfMonths={2}
                  selectedDays={[e.from, { from: e.from, to: e.to }]}
                  onDayClick={(day, modifiers) =>
                    this.handleDayClick(day, modifiers, i)}
                  localeUtils={MomentLocaleUtils}
                  modifiers={{ start: e.from, end: e.to }}
                  modifiersStyles={mode !== 'day' ? modifiersStyles : {}}
                  showWeekNumbers
                  fixedWeeks={mode !== 'month'}
                  disabledDays={multiDates && [
                    { before: i !== 0 &&
                    moment(panel[0].to).add('day', 1).toDate() }
                  ]}
                />
              </Paper>
            </Drawer>
          </div>))}
        {loading ? (
          <div className={classes.loadingDiv}>
            <CircularProgress size={50} />
          </div>
        ) :
          children
        }
      </MainComponent>
    );
  }
}

StatsPanel.defaultProps = {
  displayModePanel: true,
  displayDatesDropper: true,
};

export default withStyles(styles)(StatsPanel);
