import React from 'react';

import { moment } from '@bit/dev-lba.lib.local-globals/moment';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import {
  paymentCBActionTypes
} from '@bit/dev-lba.lib.local-globals/getDataById';
import { L_PAYDIF } from '@bit/dev-lba.lib.local-globals/paymentCBActionTypes';

export default ({ date, paymentDate }) => {
  if (paymentCBActionTypes[L_PAYDIF].verif({ date, paymentDate })) {
    return (
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          children={
            <Typography
              children={
                'Le client est éligible ' +
                'et sera automatiquement ' +
                `débité le ${moment(paymentDate).format('L')}`
              }
            />
          }
        />
      </Grid>
    );
  }
  return 'Le client n\'est pas éligible pour le paiement différé';
};
