import React from 'react';

import { setDialogContentProp } from '../../../actions/dialog';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import Content from './Content';


const setShift = setDialogContentProp('shift');
const setBudget = setDialogContentProp('budget');
const setWithSupplies = setDialogContentProp('withSupplies');
const setDescription = setDialogContentProp('description');

const mapStateToProps = ({
  dialog: {
    contentProps: {
      shift,
      budget,
      withSupplies,
      description,
    },
  },
}) => ({
  shift,
  budget,
  withSupplies,
  description,
});

const mapDispatchToProps = {
  setShift,
  setBudget,
  setWithSupplies,
  setDescription
};

const PmarInfosDialog = ({
  shift,
  budget,
  withSupplies,
  description,
  setShift,
  setBudget,
  setWithSupplies,
  setDescription,
}) => (
  <Content
    shift={shift}
    budget={budget}
    withSupplies={withSupplies}
    description={description}
    setShift={setShift}
    setBudget={setBudget}
    setWithSupplies={setWithSupplies}
    setDescription={setDescription}
  />
);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(PmarInfosDialog);
