import React from 'react';

import Tooltip from '@material-ui/core/Tooltip';
import red from '@material-ui/core/colors/red';
import { Warning } from '@material-ui/icons';

import {
  R_CLI,
  L_CLI,
  D_REF,
  R_ART,
  R_BTOB,
} from '@bit/dev-lba.lib.local-globals/recStatus';
import { R_DATAS } from '@bit/dev-lba.lib.local-globals/rStatus';
import {
  data as rCausesCli,
  b2b as rCausesBTOB,
} from '@bit/dev-lba.lib.local-globals/rCauses';
import {
  CauseData as lCauses,
  CatData as categories,
} from '@bit/dev-lba.lib.local-globals/lCauses';

const statusToData = {
  [R_CLI]: {
    causes: rCausesCli,
    name: 'Recouvrement client',
  },
  [L_CLI]: {
    causes: lCauses,
    categories,
    name: 'Litige',
  },
  [D_REF]: {
    name: 'Dossier supprimé',
  },
  [R_BTOB]: {
    causes: rCausesBTOB,
    name: 'BTOB',
  },
  [R_ART]: {
    name: 'Recouvrement artisan',
  },
};

const RecoveryIcon = ({ recovery }) => {
  const data = statusToData[recovery.status];
  const category =
    data.categories && data.categories.find((c) => c._id === recovery.category);
  const cause =
    data.causes && data.causes.find((c) => c._id === recovery.cause);
  const status =
    recovery.recStatus && R_DATAS.find((c) => c._id === recovery.recStatus);

  return (
    <Tooltip
      title={
        <span>
          {data.name} <br />
          {status ? `Status - ${status.name}` : ''}
          {!!status && <br />}
          {category ? `Catégorie - ${category.name}` : ''}
          {!!category && <br />}
          {cause ? `Cause - ${cause.name}` : ''}
        </span>
      }
      children={<Warning style={{ color: red[500], fontSize: 20 }} />}
    />
  );
};

export default RecoveryIcon;
