import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import {
  TextField,
  Button,
  Grid
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { setDialogContentProp } from '../../actions/dialog';
import { createGoogleCalendar } from '../../actions/googleCalendar';

const styles = {
  commentBox: {
    width: '98%'
  }
};

const setMessage = setDialogContentProp('message');
const setTime = setDialogContentProp('time');

const mapStateToProps = ({
  dialog: { contentProps: {
    message = '',
    details = '',
    time,
    title,
  } }
}) => ({
  message,
  details,
  time,
  title
});

const mapDispatchToProps = {
  setMessage,
  setTime
};

const GoogleCalendarDialog = ({
  classes,
  time,
  title,
  message,
  details,
  setMessage,
  setTime
}) =>
  <div>
    <Grid container spacing={1}>
      <Grid item xs={12} sm={6}>
        <TextField
          id="google-calendar-datetime"
          label=""
          value={time}
          type="datetime-local"
          InputLabelProps={{ shrink: true }}
          onChange={e => setTime(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => createGoogleCalendar(
            time,
            title,
            details ? `${details}\n\n${message}` : message
          )}
          children="Ajouter sur Google Calendar"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          className={classes.commentBox}
          label="Commentaire"
          multiline
          rows={10}
          onChange={e => setMessage(e.target.value)}
        />
      </Grid>
    </Grid>
  </div>;

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(GoogleCalendarDialog);
