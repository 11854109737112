import React, { PureComponent } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Content from './Content';
import { connect } from 'react-redux';

const mapStateToProps = (state) => ({
  user: state.users.find(e => e._id === state.userId),
});

class DialogDemandeSAV extends PureComponent {
  render() {
    const { open, user, ...props } = this.props;
    return (
      <Dialog open={open} onClose={this.onClose}
        maxWidth="md" fullWidth>
        {user ? <Content user={user} {...props}/> : null}
      </Dialog>);
  }
}

export default connect(mapStateToProps)(DialogDemandeSAV);
