import React, { Component } from 'react';
import { shallowEqual } from 'recompose';

import { withStyles } from '@material-ui/core/styles';
import {
  Typography,
  CircularProgress,
} from '@material-ui/core';

import promisify from '@bit/dev-lba.lib.local-globals/promisify';

import api from '../../api';
import ws from '../../ws';

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    margin: 30
  }
};

export default function withAPISubscription(fetch, actions, {
  shouldReload = (props, prevProps) => !shallowEqual(props, prevProps),
  default: defaultData = [],
  customHandling = false,
  name = 'data',
  deverse = false
} = {}) {
  return WrappedComponent => withStyles(styles)(class extends Component {
    state = {
      loading: false,
      error: false,
      data: defaultData
    }

    constructor(props) {
      super(props);
      this.socketActions = this.formatSocketActions(actions, props);
    }

    componentDidMount() {
      this.reload();
      this.toggleSocketActions();
    }

    componentDidUpdate(prevProps) {
      if (shouldReload(this.props, prevProps)) {
        this.reload();
      }
    }

    componentWillUnmount() {
      this.toggleSocketActions(true);
    }

    formatSocketActions = (actions, props) =>
      actions && actions.length
        ? actions.map(({ action, func = f => f() }) => {
          if (typeof action === 'function') {
            action = action(props);
          }
          return {
            action,
            func: (...args) => func(
              (fetcher = fetch) => this.fetchFromAPI(
                fetcher,
                props,
                ...args
              ),
              props,
              ...args
            )
          };
        })
        : []

    toggleSocketActions = remove =>
      this.socketActions.forEach(({ action, func }) => {
        ws[remove ? 'removeEventListener' : 'on'](action, func);
      })

    fetchFromAPI = (fetcher, props, ...args) => {
      if (this.state.loading) {
        return;
      }
      this.promisifiedSetState({
        loading: true,
        error: false
      })
        .then(() => promisify(fetcher(api, props, ...args)))
        .then(data => this.setState({ loading: false, data }))
        .catch(() => this.setState({ loading: false, error: true }));
    }

    reload = () => this.fetchFromAPI(fetch, this.props)

    render() {
      const { loading, error, data } = this.state;
      const { classes, ...props } = this.props;
      const componentProps = {
        ...props,
        ...(deverse ? data : { [name]: data }),
        reload: this.reload
      };

      if (customHandling) {
        componentProps.error = error;
        componentProps.loading = loading;
      }
      return customHandling
        ? <WrappedComponent {...componentProps} />
        : (error && <Typography
          align="center"
          variant="h4"
          children="Une erreur est survenue, veuillez rechargez la page"
        />) || (loading && <div
          className={classes.container}
          children={<CircularProgress size={80} />}
        />) || <WrappedComponent {...componentProps} />;
    }
  });
}
