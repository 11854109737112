import React from 'react';
import { getOption } from '../common/graph/options/globalOptions';
import { moment } from '@bit/dev-lba.lib.local-globals/moment';
import ReactEcharts from 'echarts-for-react';
import { blue, green, orange, red, teal } from '@material-ui/core/colors';

const GeneralQuality = ({ from, to, mode, global }) =>
  <ReactEcharts
    notMerge={true}
    lazyUpdate={true}
    style={{ width: '100%' }}
    option={getOption({
      data: global,
      labels: Object.keys(global),
      mode,
      colors: [blue[500], green[500], orange[500], teal[500], red[500]],
      dates: [moment(from), moment(to)],
    })}
  />;

export default GeneralQuality;
