import { fromJS, Map } from 'immutable';
import React from 'react';
import api from '../api';
import DisplayMetier from '../components/MetiersSteps/DisplayMetier';
import {
  METIERSSTEPS_FETCH,
  METIERSSTEPS_UPDATE
} from '../constants/ActionTypes';
import store from '../store';
import { setDialog } from './dialog';
import DbWsAction from './models/DbWsAction';
import { Grid } from '@material-ui/core';

const action = new DbWsAction(
  METIERSSTEPS_FETCH,
  METIERSSTEPS_UPDATE
);

export const fetch = action.fetch;
export const update = action.update;

export const displayMetierDialog = ({ title = '', maxWidth = 'lg', content }) =>
  store.dispatch(
    setDialog({
      name: 'CustomDialog',
      open: true,
      dialogProps: {
        title,
        maxWidth: maxWidth,
      },
      contentProps: {
        content,
        boxProps: {
          width: '100%'
        }
      },
    })
  );

export const getElementFromApi = (id) =>
  api.metiersSteps
    .getAll({
      query: JSON.stringify({ id }),
    })
    .then((e) => e.body().map((e) => e.data())[0]);


export const displayMetierInList = async (metiersSteps = {}) => {
  const element = await getElementFromApi(metiersSteps.id);
  const answers = fromJS(metiersSteps.answers || new Map());
  return metiersSteps.id && element && displayMetierDialog({
    title: 'Récapitulatif de votre intervention',
    maxWidth: 'xl',
    content: () => <Grid container spacing={2}>
      <DisplayMetier
        element={element}
        answers={answers}
        setAnswers={(e) => e}
      />
    </Grid>
  });
};
