import { createTheme } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import blue from '@material-ui/core/colors/blue';
import pink from '@material-ui/core/colors/pink';
import { green } from '@material-ui/core/colors';

export const primary = {
  50: '#f2f9e6',
  100: '#e0f1c2',
  200: '#cbe899',
  300: '#b6df70',
  400: '#a6d851',
  500: '#96d132',
  600: '#8ecc2d',
  700: '#83c626',
  800: '#79c01f',
  900: '#68b513',
  A100: '#f2ffe5',
  A200: '#d8ffb3',
  A400: '#beff80',
  A700: '#83c626',
  'contrastDefaultColor': 'dark',
};

export const secondary = {
  50: '#ede6f9',
  100: '#d2c0f0',
  200: '#b497e6',
  300: '#966ddc',
  400: '#804dd5',
  500: '#692ecd',
  600: '#6129c8',
  700: '#5623c1',
  800: '#4c1dba',
  900: '#3b12ae',
  A100: '#e6dfff',
  A200: '#beacff',
  A400: '#9779ff',
  A700: '#835fff',
  'contrastDefaultColor': 'light',
};

export const login = createTheme({
  palette: {
    primary: {
      main: primary[500],
    },
    secondary: {
      main: secondary[500],
    },
    error: red
  },
});

export default createTheme({
  palette: {
    primary: {
      main: blue[500]
    },
    secondary: {
      main: pink[500]
    },
    valid: green[500],
    error: red
  },
  overrides: {
    MuiButton: {
      root: {
        letterSpacing: 'normal',
        padding: '8px 16px'
      },
      text: {
        padding: '8px 16px'
      }
    },
    MuiTypography: {
      h6: {
        fontSize: 21
      },
      caption: {
        color: 'rgba(0, 0, 0, 0.54)'
      },
      body1: {
        fontSize: 14
      },
      body2: {
        fontSize: 16
      }
    },
    MuiTableRow: {
      root: {
        height: 48
      },
      head: {
        height: 56
      }
    },
    MuiListItem: {
      gutters: {
        padding: '12px 24px',
      }
    },
    MuiTableCell: {
      root: {
        fontSize: 13,
        lineHeight: 'normal',
        padding: '0 7px',
        '&:last-child': {
          padding: '0 12px'
        }
      },
      head: {
        padding: '0 12px'
      }
    },
    MuiListItemText: {
      root: {
        marginTop: 0,
        marginBottom: 0
      },
      primary: {
        fontSize: 14
      }
    },
    MuiInputBase: {
      inputMultiline: {
        height: '100%'
      }
    },
    MuiDialogContent: {
      root: {
        padding: '0 24px 24px'
      }
    }
  }
});
