import React, { Component } from 'react';
import {
  Grid, LinearProgress, Button,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { green, grey } from '@material-ui/core/colors';

import { moment } from '@bit/dev-lba.lib.local-globals/moment';

import GeneralQuality from './general';
import StatsPanel from '../common/panel/StatsPanel';
import { List } from 'immutable';
import { getStatData, objectifDialog } from '../../../actions/quality';
import CustomSelect from '../../CustomInputs/CustomSelect';
import { permit } from '@bit/dev-lba.lib.local-globals/restrictions';


const styles = {
  mainPaper: {
    marginTop: 8,
    position: 'relative',
  },
  spacedDiv: {
    width: '100px',
    marginRight: '8px'
  },
  button: {
    margin: '1%'
  },
  progressContainer: {
    margin: 20
  },
  progress: {
    height: '15px'
  },
  counters: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: 10
  },
  countersPaper: {
    height: 90,
    width: 250,
    position: 'relative'
  },
  countersTypo: {
    position: 'absolute',
    bottom: 0,
    width: '100%'
  }
};

const counters = [
  { name: 'Mes notations', key: 0, color: green[500] },
  { name: 'Interventions à noter', key: 1, color: grey[500] },
];

class StatsNotation extends Component {
  constructor(props) {
    super(props);
    const user = (List.isList(props.users) && props.users.first()) || {};
    this.state = {
      from: moment().startOf('month').toDate(),
      to: moment().endOf('month').toDate(),
      global: {},
      mode: 'month',
      userId: user._id,
      counterData: [],
      achievedGoals: {
        day: 0,
        week: 0,
        month: 0
      },
      newGoals: user.goals || {},
      loading: true
    };
  }

  onDatesChange = (from, to, mode) => {
    this.setState((prev) => ({
      from, to,
      mode: mode || prev.mode
    }), this.getData);
  };

  setData = (path, value, refresh) => {
    this.setState({
      [path]: value
    }, () => refresh && this.getData());
  }

  getData = () => {
    const { from, to, mode, userId } = this.state;
    this.setData('loading', true);
    getStatData({ from, to, mode, user: userId })
      .then((data) => this.setState(data))
      .finally(() => this.setData('loading', false));
  }

  componentDidMount() {
    this.getData();
  }

  generateProgressBar = (name, value) => (
    <div className={this.props.classes.progressContainer}>
      <Typography>{name}: {value}%</Typography>
      <LinearProgress className={this.props.classes.progress}
        variant="determinate"
        value={value < 100 ? value : 100} />
    </div>
  )


  openObjectifDialog = () => {
    objectifDialog({
      users: this.props.users,
      newGoals: this.state.newGoals,
      setData: this.setData
    });
  }

  render() {
    const { users, classes, user: currentUser } = this.props;
    const {
      from, to,
      userId, global, newGoals,
      achievedGoals,
      mode, counterData,
      loading
    } = this.state;
    let percents = [0, 0, 0];

    if (userId && newGoals) {
      let userGoals = newGoals || {};
      percents[0] = +(
        Number(achievedGoals.day / (userGoals.nJour || 1) * 100).toFixed(2));
      percents[1] = +(
        Number(achievedGoals.week / (userGoals.nWeek || 1) * 100).toFixed(2));
      percents[2] = +(Number(
        achievedGoals.month / (userGoals.nMonth || 1) * 100).toFixed(2));
    }

    return (
      <Paper className={classes.mainPaper}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <div style={{ margin: 20 }}>
              { currentUser && permit(currentUser, { key: 'adminAccess' }) &&
              <Button onClick={this.openObjectifDialog}
                variant="outlined" color="secondary">
                MODIFIER
              </Button> }
              { this.generateProgressBar('Objectif jour', percents[0]) }
              { this.generateProgressBar('Objectif semaine', percents[1]) }
              { this.generateProgressBar('Objectif mois', percents[2]) }
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography style={{ paddingTop: '1rem' }}
              variant="h6" align='center'>
            Statistiques de notation
            </Typography>
            {users.size > 1 &&
              <CustomSelect
                value={userId}
                path={['userId']}
                fullWidth={false}
                setData={(p, v) => this.setData(p[0], v, true)}
              >
                {
                  users.map((d, i) => (
                    <MenuItem key={i} value={d._id}>{d.login}</MenuItem>
                  ))
                }
              </CustomSelect>
            }
          </Grid>
          <Grid item xs={12} md={4} className={classes.counters}>
            {counters.map((c, i) => (
              <Paper key={i} className={classes.countersPaper}>
                <Typography align="center" variant="h6">
                  {c.name}
                </Typography>
                <Typography className={classes.countersTypo}
                  style={{ color: c.color }}
                  align="center" variant="h3">
                  {counterData ? counterData[c.key] : 0}
                </Typography>
              </Paper>
            ))}
          </Grid>
          <Grid item xs={12}>
            <StatsPanel
              defaultState={{ from, to: new Date(), mode }}
              onDatesChange={this.onDatesChange}
              title={'Statistiques qualité'}
              loading={loading}
            >
              <GeneralQuality
                from={from}
                to={to}
                mode={mode}
                global={global}
              />
            </StatsPanel>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export default withStyles(styles)(StatsNotation);
