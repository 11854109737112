import React, { Component } from 'react';
import { compose } from 'recompose';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { withStyles } from '@material-ui/core/styles';
import { showFilter } from '../../../utils/function';
import Input from '@material-ui/core/Input';
import styles from './styles';

class TableHeader extends Component {

  shouldComponentUpdate(nextProps) {
    return (JSON.stringify(nextProps.keys) !==
      JSON.stringify(this.props.keys) ||
      JSON.stringify(nextProps.table) !== JSON.stringify(this.props.table) ||
      isWidthDown('sm', nextProps.width) !== isWidthDown('sm',
        this.props.width));
  }

  genFilterField(elem) {
    elem = typeof elem === 'object' ? JSON.stringify(elem) : elem;
    return (<TableCell key={elem} className={this.props.classes.input}>
      <Input
        fullWidth
        onKeyPress={this.props.updateFilter}
        name={elem}
      />
    </TableCell>);
  }

  render() {
    const {
      table,
      keys,
      setFilter,
      classes,
      handlerFilterByColumns,
      name,
      width,
      filteredByColumn
    } = this.props;
    const isMobile = isWidthDown('sm', width);
    return (
      <TableHead>
        <TableRow className={classes.ArrowHeader}>
          {Object.keys(table).map(elem => (
            <TableCell
              variant="head"
              className={classes.masterDiv}
              padding='checkbox'
              key={elem}
            >
              {elem}
              { filteredByColumn &&
                showFilter(elem, classes, handlerFilterByColumns, name)
              }
            </TableCell>
          ))}
        </TableRow>
        {
          setFilter && !isMobile && <TableRow>
            {keys.map(elem => (
              this.genFilterField(elem)
            ))}
          </TableRow>
        }
      </TableHead>
    );
  }
}

export default compose(
  withStyles(styles),
  withWidth()
)(TableHeader);
