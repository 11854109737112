import React from 'react';
import { Button, Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import { closeDialog, } from '../../actions/dialog';
import Calls from '../Calls/Calls';
import store from '../../store';
import { callTypes } from '@bit/dev-lba.lib.local-globals/callTypes';
import { sendCallTypes } from '../Calls/callFunctions';

const mapStateToProps = ({
  dialog: {
    contentProps: { call }
  }
}) => ({ call });

const CallTypesDialog = ({ call }) => (
  <Grid container justifyContent="center" spacing={2}>
    <Grid item xs={8}>
      <Calls
        sm={12}
        elem={[call]}
        keyUrl="urlEnregistrement"
        dontPlay={false}
        autoPlay
      />
    </Grid>
    <Grid item xs={12}>
      <Grid container justifyContent="center" spacing={2}>
        {callTypes.map(e =>
          <Grid item>
            <Button
              children={e.label}
              variant="outlined"
              style={{ color: e.color[800], borderColor: e.color[500] }}
              onClick={() => {
                sendCallTypes(e.id, call.urlEnregistrement);
                store.dispatch(closeDialog());
              }}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  </Grid>
);
export default connect(mapStateToProps)(CallTypesDialog);
