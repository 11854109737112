import React, { PureComponent } from 'react';
import StatsPanel from '../../../common/panel/StatsPanel';
import { moment } from '@bit/dev-lba.lib.local-globals/moment';
import { Link } from 'react-router-dom';
import api from '../../../../../api';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Select,
  MenuItem,
  Paper,
  Checkbox,
  TablePagination,
} from '@material-ui/core';
import { getLogin } from '../../../../../utils/function';

class NonPaidTable extends PureComponent {
  state = {
    from: moment().startOf('month').toDate(),
    to: moment().endOf('month').toDate(),
    mode: 'month',
    data: [],
    usersId: [],
    page: 0,
    rowsPerPage: 25,
  };

  selectUsers = (user) => {
    const value = user.currentTarget.dataset.value;

    let usersId = [...this.state.usersId].toggleValue(value);
    this.setState({ usersId }, () => this.doRequests());
  };

  onDatesChange = (from, to, mode) => {
    this.setState({ from, to, mode }, () => this.doRequests());
  };

  doRequests = () => {
    const { usersId, from, to } = this.state;
    api.stats
      .custom('comptabilite/noPaid')
      .get({
        from,
        to,
        users: JSON.stringify(usersId),
      })
      .then((result) => {
        this.setState({ data: result.body().data().data });
      });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  render = () => {
    const { users } = this.props;
    const { mode, from, to, usersId, data, page, rowsPerPage } = this.state;

    return (
      <Paper>
        <StatsPanel
          onDatesChange={this.onDatesChange}
          defaultState={{ mode, from, to }}
          title={'OS verifié et non payé/reglé'}
          body={
            <Select
              multiple
              displayEmpty={true}
              value={usersId}
              renderValue={(selected) =>
                selected && selected.length
                  ? users
                    .filter((e) => selected.includes(e._id))
                    .map((e) => e.login)
                    .join(', ')
                  : 'Sélectionnez les utilisateurs'
              }
              onChange={this.selectUsers}
            >
              {users.map((d) => (
                <MenuItem key={d._id.toString()} value={d._id.toString()}>
                  <Checkbox checked={usersId.includes(d._id)} />
                  {d.login}
                </MenuItem>
              ))}
            </Select>
          }
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Login</TableCell>
                <TableCell align="right">OS</TableCell>
                <TableCell align="right">Prix final</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data
                .slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage)
                .map((r, i) => (
                  <TableRow key={i}>
                    <TableCell>{getLogin(r.login.ajout)}</TableCell>
                    <TableCell align="right">
                      <Link to={`interventions/${r.id}`}>{r.id}</Link>
                    </TableCell>
                    <TableCell align="right">
                      {Number(r.finalPrice / 100).toFixed(2)}
                    </TableCell>
                  </TableRow>
                ))}
              <TableRow>
                <TablePagination
                  count={data.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={this.handleChangePage}
                  onRowsPerPageChange={this.handleChangeRowsPerPage}
                />
              </TableRow>
            </TableBody>
          </Table>
        </StatsPanel>
      </Paper>
    );
  };
}

export default NonPaidTable;
