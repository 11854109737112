import { appel as artisanAppel } from '../../actions/artisan';
import { Map } from 'immutable';

export const artisanNumbers =
  [1, 2, 3].map(e => ({
    name: `Téléphone Artisan ${e}`,
    funcValue: (props, elem) => {
      let tel = '';
      if (props.markerList && props.markerList.size) {
        let artisan = props.markerList.find(
          e => e.getIn(['obj', 'value']) === elem.artisan
        );
        tel = artisan && artisan.getIn(['obj', `tel${e}`]);
      }
      return (
        tel || (elem.currentArtisan && elem.currentArtisan[`tel${e}`])
      );
    },
    customVisible: (elem, props) => !props.match.params.id,
    action: (props, elem) => {
      let tel = '';
      let artisan = (props.markerList || []).find(
        e => e.getIn(['obj', 'value']) === elem.artisan
      );
      if (artisan && props.markerList && props.markerList.size) {
        tel = artisan.getIn(['obj', `tel${e}`]);
      } else if (
        props.selected &&
              props.selected.artisan &&
              elem.currentArtisan &&
              elem.currentArtisan[`tel${e}`]
      ) {
        tel = elem.currentArtisan[`tel${e}`];
      }
      return (
        tel &&
        artisanAppel(
          {
            artisan: new Map((artisan || {}).obj || elem.currentArtisan),
            intervention: new Map(props.selected),
          },
          tel
        )
      );
    },
  }));
