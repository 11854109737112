import React, { Component } from 'react';
import NotationStats from '../Quality';
import SAVStats from '../SAV';
import TabsWithCounter from '../../Utils/TabsWithCounter';

const components = {
  0: (props) => <SAVStats {...props} />,
  1: (props) => <NotationStats {...props} />,
};
const values = ['SAV', 'Qualité'];

export default class SatisfactionClient extends Component {
    state = {
      value: 0
    }

    updateValue = (e, value) => this.setState({ value })

    render() {
      const { value } = this.state;
      return [
        <TabsWithCounter
          value={value}
          centered
          variant="standard"
          onChange={this.updateValue}
          values={values.map((e) => ({ name: e }))}
        />,
        components[value](this.props)
      ];
    }
}
