import React from 'react';
import Grid from '@material-ui/core/Grid';
import fetchText from '../fetchText';

export default ({ proposition: { note, cancelRaison } }, classes) =>
  <Grid
    key={'Artisan updates a prog'}
    container
    item
    xs={8}
    spacing={3}
    children={[
      cancelRaison === 'Remarque'
        ? {
          data: {
            title: 'Remarque : ',
            text: note || '',
          },
        }
        : {
          data: {
            title: 'Raison d\'annulation : ',
            text: cancelRaison || 'raison non trouvé',
          },
        },
    ].map(fetchText(classes))}
  />;
