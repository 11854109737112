import React from 'react';
import { AS_INDSP } from '@bit/dev-lba.lib.local-globals/aSubStatus';
import { call } from '../utils/call';
import { Typography, Grid, Tooltip } from '@material-ui/core';
import getDataById from '@bit/dev-lba.lib.local-globals/getDataById';
import { A_ARC } from '@bit/dev-lba.lib.local-globals/aStatus';
import { getByKey } from '@bit/dev-lba.lib.local-globals/aSecurity';
import { NOT_QUA } from '@bit/dev-lba.lib.local-globals/aLevel';

const ItemComponent = ({ element, status }) => (
  <Tooltip title="Appeler" onClick={() => call(element.getIn(['obj', 'tel1']))}>
    <Grid container spacing={2}>
      <Grid
        item
        xs={4}
        children={
          <Typography variant="subtitle1">
            {`${element.getIn(['obj', 'title'])} ${Math.round(
              element.getIn(['dis'])
            )} km`}
          </Typography>
        }
      />
      <Grid
        item
        xs={4}
        children={
          <Typography
            variant="subtitle2"
            style={{
              color: status.color['500'],
              textTransform: 'capitalize',
            }}
            children={status.name}
          />
        }
      />
    </Grid>
  </Tooltip>
);

export default (marklist, appels) =>
  marklist.reduce((v, e) => {
    if (
      Math.round(e.get('dis')) <= 30 &&
      e.getIn(['obj', 'subStatus']) !== AS_INDSP &&
      e.getIn(['obj', 'level']) === NOT_QUA &&
      !appels.includes(e.getIn(['obj', '_id'])) &&
      !getByKey({ security: e.getIn(['obj', 'security']).toJS() })
    ) {
      const status =
        e.getIn(['obj', 'status']) === A_ARC
          ? getDataById.artisanAStatus[e.getIn(['obj', 'status'])]
          : getDataById.artisanSubStatus[e.getIn(['obj', 'subStatus'])];
      v.push(<ItemComponent element={e} status={status} />);
    }
    return v;
  }, []);
