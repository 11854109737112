import {
  CALL_LOAD_ACTIVITIES_ARTISAN,
  setListActivitiesArtisan
} from '../actions/etatReseau';

import api from '../api';

const loadActivitiesArtisan = (store) => next => (action) => {
  switch (action.type) {
    case CALL_LOAD_ACTIVITIES_ARTISAN: {
      api.history.custom('getHistory')
        .post({ id: action.artisanId })
        .then(history => {
          const result = history.body().map(e => e.data());
          store.dispatch(setListActivitiesArtisan(result));
        }).catch(e => e);
    }
  }
  next(action);
};

export default loadActivitiesArtisan;
