/* eslint-disable max-lines */
import {
  List,
  AttachMoney,
  VisibilityOff,
  Visibility,
  Update,
  Lock,
  Person,
  Gavel,
  VerifiedUser,
  Whatshot,
  Star,
  MonetizationOn,
  Business,
  RateReview,
  CardTravel,
  Autorenew,
  ThumbsUpDown,
  Clear,
} from '@material-ui/icons';
import { monthToString } from '../../../utils/date';
import { CHIEF } from '@bit/dev-lba.lib.local-globals/permissions';
import { S_JUR } from '@bit/dev-lba.lib.local-globals/services';
import { permit } from '@bit/dev-lba.lib.local-globals/restrictions';
import {
  CC_CLOT, CM_CLOT, dataC, dataM
} from '@bit/dev-lba.lib.local-globals/caldeoType';
import collections from '../../../constants/collections';

const baseUrl = '/intervention/recouvrement/';

const getChild = (array, name, user) =>
  array.map(({ link, name: nameOfLink, childs, params }) => ({
    name: `${name} ${nameOfLink}`,
    icon: List,
    ...(childs
      ? { childs: getChild(childs, '', user) }
      : {
        count: true,
        link: `${baseUrl}${link}/${params ?
          params : 'login-recouvrement'}=${user.login}=${user._id}`,
      }
    ),
  }));

const myRecouvrment = (name, array) => ({
  name: `Mes ${name}`,
  icon: List,
  childs: ({ users, user }) =>
    user.permission <= CHIEF &&
      user.service === S_JUR
      ? users
        .filter(e =>
          e.act &&
          e.service === S_JUR &&
          e.permission >= user.permission
        )
        .map(e => ({
          name: e._id === user._id ? `Mes ${name}` : `${name} de ${e.login}`,
          icon: List,
          childs: getChild(array, e._id === user._id ? '' : e.login, e)
        }))
      : getChild(array, '', user),
});

export default [
  {
    name: 'Contentieux Client',
    icon: CardTravel,
    childs: [
      {
        name: 'Recouvrement Client',
        icon: Gavel,
        childs: [
          {
            name: 'Non Pris',
            icon: VisibilityOff,
            childs: [
              {
                name: 'Tout',
                icon: List,
                count: true,
                link: '/intervention/recouvrement/clientNpris',
              },
              {
                name: 'Date définie par le client',
                icon: List,
                count: true,
                link: '/intervention/recouvrement/clientNprisQuality',
              },
              {
                name: 'Date non définie',
                icon: List,
                count: true,
                link: '/intervention/recouvrement/clientNprisQualityNoContact',
              },
              {
                name: 'Retard d\'envoi',
                icon: List,
                count: true,
                link: '/intervention/recouvrement/clientNprisRE',
              },
              {
                name: 'Facture non reçue',
                icon: List,
                count: true,
                link: '/intervention/recouvrement/clientNprisFNR',
              },
              {
                name: 'Facturation identiques',
                icon: List,
                count: true,
                link: '/intervention/recouvrement/clientNprisCFI',
              },
            ],
          },
          {
            name: 'Prise en Charge',
            icon: Visibility,
            childs: [
              {
                name: 'Tout',
                count: true,
                icon: List,
                link: '/intervention/recouvrement/clientPris',
              },
              {
                name: 'Date définie par le client',
                count: true,
                icon: List,
                link: '/intervention/recouvrement/clientQuality',
              },
              {
                name: 'Date non définie',
                count: true,
                icon: List,
                link: '/intervention/recouvrement/clientQualityNoContact',
              },
              {
                name: 'Retard d\'envoi',
                count: true,
                icon: List,
                link: '/intervention/recouvrement/clientRetardEnvoi',
              },
              {
                name: 'Chèque perdu',
                count: true,
                icon: List,
                link: '/intervention/recouvrement/clientChequePerdu',
              },
              {
                name: 'CFI',
                count: true,
                icon: List,
                link: '/intervention/recouvrement/clientCfi',
              },
              {
                name: 'Chèque sans provision',
                count: true,
                icon: List,
                link: '/intervention/recouvrement/clientChequeSansProvision',
              },
              {
                name: 'Pas de contact',
                link: '/intervention/recouvrement/clientPasDeContact',
                count: true,
                icon: List,
              },
              {
                name: 'Facture non reçue',
                count: true,
                icon: List,
                link: '/intervention/recouvrement/clientFactureNonRecue',
              },
            ],
          },
          {
            name: 'Relances',
            count: true,
            icon: Update,
            link: '/intervention/recouvrement/clientRelances',
          },
          {
            name: 'Résolues',
            count: true,
            icon: VerifiedUser,
            link: '/intervention/recouvrement/clientResolues',
          },
          {
            name: 'Pertes',
            count: true,
            icon: Lock,
            link: '/intervention/recouvrement/clientPertes',
          },
          {
            name: 'A Reprendre',
            icon: Autorenew,
            count: true,
            link: '/intervention/recouvrement/clientAReprendre',
          },
          {
            name: 'Résolutions à vérifier',
            icon: ThumbsUpDown,
            count: true,
            link: '/intervention/recouvrement/resolutionsAVerifRecouvrement',
          },
          {
            name: 'Résolutions refusées',
            icon: Clear,
            count: true,
            link: (e) =>
              permit(e, { key: 'preResolution' })
                ? '/intervention/recouvrement/mesResolutionsRef'
                : '/intervention/recouvrement/mesResolutionsRef/' +
                  `login-preResolutionRecouvrement=${e.login}=${e._id}`,
          },
          {
            name: 'Pertes à vérifier',
            icon: ThumbsUpDown,
            count: true,
            link: '/intervention/recouvrement/pertesAVerifRecouvrement',
          },
          {
            name: 'Pertes refusées',
            icon: Clear,
            count: true,
            link: (e) =>
              permit(e, { key: 'preResolution' })
                ? '/intervention/recouvrement/mesPertesRef'
                : '/intervention/recouvrement/mesPertesRef/' +
                  `login-preResolutionRecouvrement=${e.login}=${e._id}`,
          },
          myRecouvrment('recouvrements', [
            {
              name: 'Non pris',
              link: 'mesClientNonPris',
              params: 'login-attributionRecouvrement',
            },
            { name: 'En cours', link: 'mesClientEnCours' },
            { name: 'En SAV', link: 'mesClientSAV' },
            { name: 'Pertes', link: 'mesClientPertes' },
            { name: 'Résolues', link: 'mesClientResolues' },
            { name: 'Injonction de payer', link: 'mesClientInjonction' },
            { name: 'Assignation', link: 'mesClientAssignation' },
          ]),
        ],
      },
      {
        name: 'Litige Client',
        icon: Whatshot,
        childs: [
          {
            name: 'Non Pris',
            count: true,
            icon: VisibilityOff,
            link: '/intervention/recouvrement/litigeClientNpris',
          },
          {
            name: 'Pris En Charge',
            icon: Visibility,
            childs: [
              {
                name: 'Tout',
                count: true,
                icon: List,
                link: '/intervention/recouvrement/litigeClientPris',
              },
              {
                name: 'Avec Reglement',
                icon: List,
                childs: [
                  {
                    name: 'Tout',
                    count: true,
                    icon: List,
                    link: '/intervention/recouvrement/litigeClientAvecRegl',
                  },
                  {
                    name: 'Qualité',
                    count: true,
                    icon: List,
                    link:
                      '/intervention/recouvrement/litigeClientAvecReglQualite',
                  },
                  {
                    name: 'Tarif',
                    count: true,
                    icon: List,
                    link:
                      '/intervention/recouvrement/litigeClientAvecReglTarif',
                  },
                  {
                    name: 'Pas de devis',
                    count: true,
                    icon: List,
                    link: '/intervention/recouvrement/litigeClientAvecReglPD',
                  },
                  {
                    name: 'Risque d\'opposition',
                    count: true,
                    icon: List,
                    link: '/intervention/recouvrement/litigeClientAvecReglRO',
                  },
                  {
                    name: 'Assignation',
                    count: true,
                    icon: List,
                    link: '/intervention/recouvrement/litigeClientAvecReglAs',
                  },
                ],
              },
              {
                name: 'Sans Reglement',
                icon: List,
                childs: [
                  {
                    name: 'Tout',
                    count: true,
                    icon: List,
                    link: '/intervention/recouvrement/litigeClientSansRegl',
                  },
                  {
                    name: 'Qualité',
                    count: true,
                    icon: List,
                    link:
                      '/intervention/recouvrement/litigeClientSansReglQualite',
                  },
                  {
                    name: 'Tarif',
                    count: true,
                    icon: List,
                    link:
                      '/intervention/recouvrement/litigeClientSansReglTarif',
                  },
                  {
                    name: 'Pas de devis',
                    count: true,
                    icon: List,
                    link: '/intervention/recouvrement/litigeClientSansReglPD',
                  },
                  {
                    name: 'Opposition',
                    count: true,
                    icon: List,
                    link: '/intervention/recouvrement/litigeClientSansReglOP',
                  },
                  {
                    name: 'Injonction',
                    count: true,
                    icon: List,
                    link: '/intervention/recouvrement/litigeClientSansReglInj',
                  },
                  {
                    name: 'Assignation',
                    count: true,
                    icon: List,
                    link: '/intervention/recouvrement/litigeClientSansReglAs',
                  },
                ],
              },
              {
                name: 'Plaintes',
                icon: List,
                childs: [
                  {
                    name: 'Tout',
                    count: true,
                    icon: List,
                    link: '/intervention/recouvrement/litigePlaintes',
                  },
                  {
                    name: 'UFC',
                    count: true,
                    icon: List,
                    link: '/intervention/recouvrement/litigePlaintesUFC',
                  },
                  {
                    name: 'Répression Des Fraudes',
                    count: true,
                    icon: List,
                    link: '/intervention/recouvrement/litigePlaintesRF',
                  },
                  {
                    name: 'Police / Gendarmerie',
                    count: true,
                    icon: List,
                    link: '/intervention/recouvrement/litigePlaintesPG',
                  },
                  {
                    name: 'Assurance',
                    count: true,
                    icon: List,
                    link: '/intervention/recouvrement/plaintesAssurance',
                  },
                ],
              },
            ],
          },
          {
            name: 'Résolues',
            count: true,
            icon: VerifiedUser,
            link: '/intervention/recouvrement/litigeClientResolues',
          },
          {
            name: 'Pertes',
            count: true,
            icon: Lock,
            link: '/intervention/recouvrement/litigePertes',
          },
          {
            name: 'A Reprendre',
            icon: Autorenew,
            count: true,
            link: '/intervention/recouvrement/litigeAReprendre',
          },
          {
            name: 'Résolutions à vérifier',
            icon: ThumbsUpDown,
            count: true,
            link: (e) =>
              permit(e, { key: 'preResolution' })
                ? '/intervention/recouvrement/resolutionsAVerifLitige'
                : '/intervention/recouvrement/resolutionsAVerifLitige/' +
                  `login-preResolutionRecouvrement=${e.login}=${e._id}`,
          },
          {
            name: 'Résolutions refusées',
            icon: Clear,
            count: true,
            link: (e) =>
              permit(e, { key: 'preResolution' })
                ? '/intervention/recouvrement/litigeMesResolutionsRef'
                : '/intervention/recouvrement/litigeMesResolutionsRef/' +
                  `login-preResolutionRecouvrement=${e.login}=${e._id}`,
          },
          {
            name: 'Pertes à vérifier',
            icon: ThumbsUpDown,
            count: true,
            link: (e) =>
              permit(e, { key: 'preResolution' })
                ? '/intervention/recouvrement/perteAVerifLitige'
                : '/intervention/recouvrement/perteAVerifLitige/' +
                  `login-preResolutionRecouvrement=${e.login}=${e._id}`,
          },
          {
            name: 'Pertes refusées',
            icon: Clear,
            count: true,
            link: (e) =>
              permit(e, { key: 'preResolution' })
                ? '/intervention/recouvrement/litigeMesPertesRef'
                : '/intervention/recouvrement/litigeMesPertesRef/' +
                  `login-preResolutionRecouvrement=${e.login}=${e._id}`,
          },
          {
            name: 'Intervention -300 €',
            count: true,
            icon: List,
            link: '/intervention/recouvrement/litigeClient300',
          },
          myRecouvrment('litiges', [
            {
              name: 'Non pris',
              link: 'litigeMesClientNonPris',
              params: 'login-attributionRecouvrement',
            },
            {
              name: 'En cours',
              childs: [
                {
                  name: 'Sans reglement',
                  link: 'litigeMesClientSansReglement',
                },
                {
                  name: 'Avec reglement',
                  link: 'litigeMesClientAvecReglement',
                },
                { name: 'Plaintes', link: 'litigeMesClientPlaintes' },
              ],
            },
            { name: 'En SAV', link: 'litigeMesClientSAV' },
            { name: 'Injonction de payer', link: 'litigeMesClientInjonction' },
            { name: 'Assignation', link: 'litigeMesClientAssignation' },
            { name: 'Pertes', link: 'litigeMesClientPertes' },
            { name: 'Résolues', link: 'litigeMesClientResolues' },
          ]),
        ],
      },
      {
        name: 'Recouvrement BTOB',
        icon: Gavel,
        childs: [
          {
            name: 'Non Pris',
            icon: VisibilityOff,
            childs: [
              {
                name: 'Tout',
                icon: List,
                count: true,
                link: '/intervention/recouvrementBTOB/b2bNpris',
              },
              {
                name: 'Retard d\'envoi',
                icon: List,
                count: true,
                link: '/intervention/recouvrementBTOB/b2bNprisRE',
              },
              {
                name: 'Facture non reçue',
                icon: List,
                count: true,
                link: '/intervention/recouvrementBTOB/b2bNprisFNR',
              },
            ],
          },
          {
            name: 'Prise en Charge',
            icon: Visibility,
            childs: [
              {
                name: 'Tout',
                count: true,
                icon: List,
                link: '/intervention/recouvrementBTOB/b2bPris',
              },
              {
                name: 'Retard d\'envoi',
                count: true,
                icon: List,
                link: '/intervention/recouvrementBTOB/b2bRetardEnvoi',
              },
              {
                name: 'Facture non reçue',
                count: true,
                icon: List,
                link: '/intervention/recouvrementBTOB/b2bFactureNonRecue',
              },
            ],
          },
          {
            name: 'Résolues',
            count: true,
            icon: VerifiedUser,
            link: '/intervention/recouvrementBTOB/b2bResolues',
          },
          {
            name: 'Pertes',
            count: true,
            icon: Lock,
            link: '/intervention/recouvrementBTOB/b2bPertes',
          },
          {
            name: 'A Reprendre',
            icon: Autorenew,
            count: true,
            link: '/intervention/recouvrement/b2bAReprendre',
          },
        ],
      },
      {
        name: 'Recouvrement Client Encaissement',
        icon: MonetizationOn,
        childs: [
          {
            name: 'Tout',
            icon: List,
            count: true,
            link: '/intervention/recouvrement/clientReglementAll',
          },
          ...[...Array(12).keys()].map((i) => ({
            name: monthToString(new Date(), -i),
            icon: List,
            count: true,
            link: `/intervention/recouvrement/clientReglement${monthToString(
              new Date(),
              -i
            )
              .replace(/\..+/, '')
              .capitalize()}`,
          })),
        ],
      },
      {
        name: 'Encaissement Litige',
        icon: MonetizationOn,
        childs: [
          {
            name: 'Tout',
            icon: List,
            count: true,
            link: '/intervention/recouvrement/litigeClientReglementAll',
          },
          ...[...Array(12).keys()].map((i) => ({
            name: monthToString(new Date(), -i),
            icon: List,
            count: true,
            link:
              `/intervention/recouvrement/litigeClientReglement${monthToString(
                new Date(),
                -i
              )
                .replace(/\..+/, '')
                .capitalize()}`,
          })),
        ],
      },
    ],
  },
  {
    name: 'Satisfaction client',
    icon: RateReview,
    childs: [
      {
        name: 'Qualité',
        icon: Star,
        childs: [
          {
            name: 'À noter',
            icon: List,
            count: true,
            link: '/intervention/quality/mesInterventions',
          },
          {
            name: 'Grand compte à noter',
            icon: List,
            count: true,
            link: '/intervention/quality/mesInterventionsGc',
          },
          {
            name: 'Notées',
            icon: List,
            count: true,
            link: '/intervention/quality/n',
          },
          {
            name: 'Sans réponse',
            count: true,
            icon: List,
            link: '/intervention/quality/inr',
          },
          {
            name: 'Payeur à contacter',
            icon: List,
            childs: [
              {
                name: 'Tous',
                count: true,
                icon: List,
                link: '/intervention/quality/bc',
              },
              {
                name: '1- Société',
                count: true,
                icon: List,
                link: '/intervention/quality/bcSoc',
              },
              {
                name: '2 - Curatelle',
                count: true,
                icon: List,
                link: '/intervention/quality/bcCur',
              },
              {
                name: '3 - Autre',
                count: true,
                icon: List,
                link: '/intervention/quality/bcAutre',
              },
              {
                name: '4 - Agence immobilière',
                count: true,
                icon: List,
                link: '/intervention/quality/bcAgim',
              },
              {
                name: '5 - Propriétaire',
                count: true,
                icon: List,
                link: '/intervention/quality/bcProp',
              },
            ],
          },
          {
            name: 'Mes notations',
            count: true,
            icon: List,
            link: (e) =>
              `/intervention/quality/w/notation-client-userId=${
                e.login}=${e._id}`,
          },
        ],
      },
      {
        name: 'Aide d\'etat / financement',
        icon: AttachMoney,
        childs: [
          {
            name: 'Crédit d’impôt et aides',
            icon: Business,
            childs: [
              {
                name: 'Non pris',
                count: true,
                icon: List,
                link: '/intervention/list/cald',
              },
              {
                name: 'Prise en charge caldeo',
                icon: List,
                childs: [
                  {
                    name: 'Tout',
                    count: true,
                    icon: List,
                    link: '/intervention/aide/caldPris',
                  },
                  ...dataC
                    .filter((e) => e._id !== CC_CLOT)
                    .map((e) => ({
                      name: e.listTitle,
                      count: true,
                      icon: List,
                      link: `/intervention/aide/cald${e.shortName}`,
                    })),
                ],
              },
              {
                name: 'Prise en charge MPR',
                icon: List,
                childs: [
                  {
                    name: 'Tout',
                    count: true,
                    icon: List,
                    link: '/intervention/aide/mprPris',
                  },
                  ...dataM
                    .filter((e) => e._id !== CM_CLOT)
                    .map((e) => ({
                      name: e.listTitle,
                      count: true,
                      icon: List,
                      link: `/intervention/aide/cald${e.shortName}`,
                    })),
                ],
              },
              {
                name: 'Clôturés',
                icon: List,
                childs: [
                  ...dataC
                    .filter((e) => e._id === CC_CLOT)
                    .map((e) => ({
                      name: e.listTitle,
                      count: true,
                      icon: List,
                      link: `/intervention/aide/cald${e.shortName}`,
                    }))
                    .concat(
                      dataM
                        .filter((e) => e._id === CM_CLOT)
                        .map((e) => ({
                          name: e.listTitle,
                          count: true,
                          icon: List,
                          link: `/intervention/aide/cald${e.shortName}`,
                        }))
                    ),
                ],
              },
              {
                name: 'Solde caldeo',
                count: true,
                icon: List,
                link: '/intervention/aide/sCald',
              },
            ],
          },
          {
            name: 'Domofinance',
            icon: Business,
            childs: [
              {
                name: 'Tout',
                count: true,
                icon: List,
                link: '/intervention/domo/allDomo',
              },
              {
                name: 'Non payes',
                count: true,
                icon: List,
                link: '/intervention/domo/domoNPaid',
              },
              {
                name: 'Payes',
                count: true,
                icon: List,
                link: '/intervention/domo/domoPaid',
              },
            ],
          },
        ],
      },
      {
        name: 'SAV',
        icon: List,
        childs: [
          {
            name: 'Demande de Facture',
            icon: List,
            childs: [
              {
                name: 'Liste de demande',
                icon: List,
                count: true,
                link: () => '/intervention/list/dFact',
              },
              {
                name: 'Demande envoyé',
                icon: List,
                count: true,
                link: () => '/intervention/list/dFE',
              },
            ],
          },
          {
            name: 'Demande de attestation',
            icon: List,
            childs: [
              {
                name: 'List de demande',
                icon: List,
                count: true,
                link: () => '/intervention/list/dAtt',
              },
              {
                name: 'Demande envoyé',
                icon: List,
                count: true,
                link: () => '/intervention/list/dAttE',
              },
            ],
          },
          {
            name: 'Demande de SAV',
            icon: List,
            childs: [
              {
                name: 'Liste de demande',
                icon: List,
                count: true,
                link: () => '/intervention/list/dSAV',
              },
              {
                name: 'Non envoyé',
                icon: List,
                count: true,
                link: () => '/savIntervention/list/nenv',
              },
              {
                name: 'A prog',
                icon: List,
                count: true,
                link: () => '/savIntervention/list/aprog',
              },
              {
                name: 'A prog (partenariat)',
                icon: List,
                count: true,
                link: () => '/savIntervention/list/aprogPart',
              },
              {
                name: 'En cours',
                icon: List,
                count: true,
                link: () => '/savIntervention/list/enc',
              },
              {
                name: 'A vérifier',
                icon: List,
                count: true,
                link: () => '/savIntervention/list/avrf',
              },
              {
                name: 'Clôturé',
                icon: List,
                count: true,
                link: () => '/savIntervention/list/clo',
              },
              {
                name: 'Annulé',
                icon: List,
                count: true,
                link: () => '/savIntervention/list/ann',
              },
              {
                name: 'A refuser',
                icon: List,
                count: true,
                link: () => '/savIntervention/list/aref',
              },
              {
                name: 'Refusé',
                icon: List,
                count: true,
                link: () => '/savIntervention/list/ref',
              },
              {
                name: 'TOTAL',
                icon: List,
                count: true,
                link: () => '/savIntervention/list',
              },
            ],
          },
          {
            name: 'Demande d\'information',
            icon: List,
            childs: [
              {
                name: 'List de demande',
                icon: List,
                count: true,
                link: () => '/intervention/list/dISAV',
              },
              {
                name: 'Demande envoyée',
                icon: List,
                count: true,
                link: () => '/intervention/list/dISAVE',
              },
            ],
          },
          {
            name: 'Listes de modifications',
            icon: List,
            childs: [
              {
                name: 'Interventions SAV',
                icon: List,
                count: true,
                link: () => `/modifications/${collections.savInterventions}`,
              },
              {
                name: 'Interventions',
                icon: List,
                count: true,
                link: () => `/modifications/${collections.interventions}`,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: 'Recouvrement Artisan',
    icon: Person,
    count: true,
    link: '/intervention/recouvrement/recouvrementArtisan',
  },
  {
    name: 'Rib à valider (clients)',
    count: true,
    icon: List,
    link: '/intervention/list/ribClientVal',
  },
  {
    name: 'Liste de chèque sans os',
    icon: List,
    link: '/compta/listRglmtNoOs',
  },
  {
    name: 'Utilisateurs',
    icon: Person,
    link: '/config/users/service',
  },
];
