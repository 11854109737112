import React from 'react';
import { compose } from 'recompose';
import { Paper } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {
  updateTransactions,
  getTransactions
} from '../../../actions/Transactions';
import TransactionsTable from '../../TransactionsTable/index';
import { withDisplaying } from '../../../hoc';

const styles = {
  div: {
    width: '100%'
  },
};

class Transactions extends React.Component {
  state = {
    transactions: [],
  }

  componentDidMount() {
    getTransactions(this.props.inter.id).then(transactions =>
      this.setState({
        transactions
      })
    );
  }

  updateTransactions = (newData, oldData) =>
    updateTransactions(newData, oldData).then(() => {
      getTransactions(this.props.inter.id).then(transactions =>
        this.setState({
          transactions
        })
      );
    })

  render() {
    const { transactions } = this.state;

    return (
      <Paper>
        <TransactionsTable
          title="Transactions"
          data={transactions}
          editable={{
            isEditable: () => true,
            onRowUpdate: this.updateTransactions,
          }}
          options={{
            actionsColumnIndex: -1
          }}
        />
      </Paper>
    );
  }
}

export default compose(withStyles(styles),
  withDisplaying())(Transactions);
