import React, { Component } from 'react';

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import MinCustomDialog from '../MinCustomDialog';
import notifSystem from '../../../notifSystem';


const StandardCheckbox = ({ label, value, handleChange }) =>
  <FormControlLabel
    label={label}
    control={<Checkbox
      checked={value}
      onChange={() => handleChange(!value)}
    />}
  />;

const getDefaultState = inform => ({
  notification: inform ?
    !!inform.notification : true,
  mail: inform ?
    !!inform.mail : false
});
class InformDialog extends Component {
  state = getDefaultState(this.props.elem.inform)

  actions = [
    {
      title: 'Fermer',
      onClick: this.sendInform
    }
  ]

  sendInform = () => {
    const { notification, mail } = this.state;
    const { elem: { inform }, callbacks } = this.props;

    if ((inform
        && inform.notification === notification
        && inform.mail === mail)
      || (!inform
        && notification === false
        && mail === false)) {
      return callbacks.close();
    }
    callbacks.inform(notification, mail)
      .then(() => {
        callbacks.close();
        notifSystem.success(
          'Signalement',
          'Les modifications ont bien été enregistrées'
        );
        if (typeof callbacks.reload === 'function') {
          callbacks.reload();
        }
      }).catch(e => notifSystem.error(
        'Signalement',
        e.response.data || e.message
      ));
  }

  handleChange = field => value =>
    this.setState({
      [field]: value
    })

  render() {
    const { notification, mail } = this.state;
    const { elem: { inform } } = this.props;

    return <MinCustomDialog
      open
      middle
      title={inform && inform.date ?
        `Signalement suivi le ${inform.date}` : 'Signalement non suivi'}
      actions={
        this.actions
          .map(({ title, onClick }, i) =>
            <Button
              key={i}
              onClick={onClick || this.sendInform}
            >
              {title}
            </Button>
          )}
    >
      <StandardCheckbox
        label="Notification"
        value={notification}
        handleChange={this.handleChange('notification')}
      />
      <StandardCheckbox
        label="Mail"
        value={mail}
        handleChange={this.handleChange('mail')}
      />
    </MinCustomDialog>;
  }
}

export default InformDialog;
