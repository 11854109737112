import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import {
  Card,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { permit } from '@bit/dev-lba.lib.local-globals/restrictions';

import { cacheDisabled } from '../../utils/function';
import { SHOW_ENR } from '@bit/dev-lba.lib.local-globals/configTypes';
import AudioPlayer from './AudioPlayer';
import { blue } from '@material-ui/core/colors';
import { keyUrls } from './FomatText';
import { openCallTypes, sendAudioPlayed } from './callFunctions';

const mapStateToProps = ({ users, userId }) => ({
  user: users.find(e => e._id === userId)
});

const styles = {
  title: {
    marginBottom: 4,
    textTransform: 'uppercase',
    fontSize: '1rem',
  },
  main: {
    borderRadius: 30
  },
  active: {
    color: blue[500],
    fontWeight: 500
  },
  scroll: {
    overflow: 'auto',
    maxHeight: '250px'
  }
};

class Calls extends Component {
  state={
    selectedAudio: {}
  }

  componentDidMount() {
    const elems = this.props.elem;
    if (elems && elems.length) {
      this.setState({ selectedAudio: this.props.elem[0] });
    }
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.elem) !== JSON.stringify(this.props.elem)) {
      if (this.props.elem && this.props.elem.length) {
        this.setState({ selectedAudio: this.props.elem[0] });
      }
    }
  }

  selectAudio = (value) => {
    this.setState({ selectedAudio: value });
  }

  render() {
    const {
      elem,
      user,
      classes,
      sm,
      title,
      keyUrl,
      dontPlay,
      autoPlay
    } = this.props;
    const { selectedAudio } = this.state;
    const { key, text } = keyUrls[keyUrl];
    return (
      !cacheDisabled(SHOW_ENR) || permit(user, { key: 'retrieveRecords' })) &&
      elem && !!elem.length ? (
        <Grid item xs={12} sm={sm || 4}>
          <Typography variant="h6" className={classes.title}
            children={title}
          />
          <Card elevation={2} className={classes.main}>
            <CardContent>
              <List className={classes.scroll}>
                {elem.map((e, i) => (
                  <ListItem divider button onClick={() =>
                    e[key] && this.selectAudio(e)
                  }>
                    <ListItemText key={i}
                      primaryTypographyProps={
                        e[key] === selectedAudio[key]
                          ? { className: classes.active }
                          : {}
                      }
                      primary={text(e)}
                    />
                  </ListItem>
                ))}
              </List>
            </CardContent>
            {selectedAudio && <AudioPlayer
              cb={() => {
                if (key === 'urlEnregistrement') {
                  if (!('dontPlay' in this.props)) {
                    if (!('type' in selectedAudio)) {
                      return openCallTypes(selectedAudio);
                    }
                    return sendAudioPlayed(selectedAudio);
                  }
                  return sendAudioPlayed(selectedAudio);
                }
              }}
              dontPlay={'dontPlay' in this.props
                ? dontPlay
                : key === 'urlEnregistrement' && !('type' in selectedAudio)}
              autoPlay={autoPlay}
              user={user}
              url={selectedAudio[key]}
              transcription={selectedAudio.transcription}
            />}
          </Card>
        </Grid>) : null;
  }

}


export default compose(
  withStyles(styles),
  connect(mapStateToProps))(Calls);
