import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

import { withDisplaying } from '../../hoc';

const RELATIVE_NUMBER_REGEX = /^-?(\d+)(\.\d{1,2})?$/;
const POSITIVE_NUMBER_REGEX = /^(\d+)(\.\d{1,2})?$/;
const getDefaultValue = (v) => (v ? (v / 100).toString(10) : '0.00');

class MoneyInput extends PureComponent {
  state = {
    error: false,
    value: getDefaultValue(this.props.defaultValue),
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.defaultValue !== nextProps.defaultValue) {
      this.setState({
        value: getDefaultValue(nextProps.defaultValue),
      });
    }
  }

  onChange = (e) => {
    const { setData, path, canBeNegative, elemPos } = this.props;
    const { error } = this.state;
    let { value } = e.target;
    const valid = (canBeNegative
      ? RELATIVE_NUMBER_REGEX
      : POSITIVE_NUMBER_REGEX
    ).test(value);

    this.setState(error === valid ? { error: !valid, value } : { value });
    if (setData && valid) {
      return elemPos >= 0
        ? setData(path, Math.round(Number.parseFloat(value * 100)), elemPos)
        : setData(path, Math.round(Number.parseFloat(value * 100)));
    }
  };

  render() {
    const {
      xs,
      sm,
      md,
      lg,
      xl,
      grid,
      classGrid,
      requiredText,
      ...props
    } = this.props;
    const { value, error } = this.state;
    delete props.defaultValue;
    delete props.setData;
    delete props.path;
    delete props.canBeNegative;
    const input = (
      <TextField
        {...props}
        value={value}
        error={error || props.error}
        type="number"
        InputProps={{
          ...props.InputProps,
          inputProps: { step: '0.01', min: '0' },
        }}
        fullWidth
        onChange={this.onChange}
        required={requiredText}
      />
    );
    if (grid) {
      return (
        <Grid
          item
          xs={xs}
          sm={sm}
          md={md}
          lg={lg}
          xl={xl}
          className={classGrid}
        >
          {input}
        </Grid>
      );
    }
    return input;
  }
}

MoneyInput.propTypes = {
  path: PropTypes.arrayOf(PropTypes.string).isRequired,
  setData: PropTypes.func.isRequired,
  label: PropTypes.string,
  defaultValue: PropTypes.number,
  classGrid: PropTypes.string,
};

MoneyInput.displayName = 'MoneyInput';

export default withDisplaying()(MoneyInput);
