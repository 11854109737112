import {
  LOGIN,
  LOGOUT
} from '../constants/ActionTypes';
import { stopSessionReplayRecording } from '../services/datadog';

export function login(token) {
  return {
    type: LOGIN,
    token,
    decodedToken: JSON.parse(atob(token.split('.')[1]))
  };
}

export function logout(ws, user) {
  ws.logout(user);
  Object.entries(localStorage).forEach(([key]) =>
    !['firstCall'].includes(key) && localStorage.removeItem(key)
  );
  stopSessionReplayRecording();
  return {
    type: LOGOUT
  };
}
