import React from 'react';
import { Link } from 'react-router-dom';
import { MenuItem, TextField, Typography } from '@material-ui/core';
import { moment } from '@bit/dev-lba.lib.local-globals/moment';
import {
  modes, providers, motifs, types
} from '@bit/dev-lba.lib.local-globals/paymentAnnexes';
import CustomSelect from '../CustomInputs/CustomSelect';
import Money from '../../utils/Money';
import MoneyInput from '../CustomInputs/MoneyInput';
import CustomTextField from '../CustomInputs/CustomTextField';
import { Autocomplete } from '@material-ui/lab';
import {
  P_FRCO
} from '@bit/dev-lba.lib.local-globals/PaymentCategories';
import { getLogin } from '../../utils/function';

export const columns = ({
  hideCheckInput, tab, isArchives, canEdit, fournisseurs
}) => ([
  {
    title: 'Id',
    field: 'id',
    render: (data) => <Link
      to={`/${!isNaN((data ? data.id : ''))
        ? 'intervention'
        : 'savIntervention'}/${(data ? data.id : '')}`}
      target="_blank" onClick={(event) => {
        event.preventDefault();
        window.open(`/${!isNaN((data ? data.id : ''))
          ? 'intervention'
          : 'savIntervention'}/${(data ? data.id : '')}`);
      }}>
      {(data ? data.id : '')}
    </Link>,
    editable: 'never'
  },
  {
    title: 'Utilisateur',
    field: 'login.ajout',
    editable: 'never',
    render: (data) => (data && data.login.ajout ? data.login.ajout : ''),
    exportValue: (data) => data && data.login.ajout ?
      getLogin(data.login.ajout) : ''
  },
  {
    title: 'Date d\'intervention',
    field: 'date.intervention',
    hidden: true,
    exportValue: (data) => (data &&
      data.date.intervention ? moment(data.date.intervention).format('L') : '')
  },
  {
    title: 'Prise en charge contentieux',
    field: 'date.recouvrement',
    hidden: true,
    exportValue: (data) => (data && data.type === P_FRCO &&
      data.date.recouvrement ? moment(data.date.recouvrement).format('L') : '')
  },
  {
    title: 'Date',
    field: 'date.ajout',
    editable: 'never',
    render: (data) => (data && data.date.ajout ?
      moment(data.date.ajout).format('LLL') : ''),
    exportValue: (data) => (data && data.date.ajout ?
      moment(data.date.ajout).format('L') : '')
  },
  {
    title: 'Pré-validation',
    field: 'date.preValidation',
    editable: 'never',
    render: (data) => (data && data.date.preValidation ?
      moment(data.date.preValidation).format('LLL') : ''),
    exportValue: (data) => (data && data.date.preValidation ?
      moment(data.date.preValidation).format('L') : ''),
    hidden: tab === 1
  },
  {
    field: 'numChq',
    title: 'Num CHQ',
    editComponent: (props) => !hideCheckInput ? (
      <CustomTextField
        path={['']}
        value={props.value}
        setData={(p, v) => props.onChange(v)}
        fullWidth
      />
    ) : null,
  },
  {
    field: 'supplier',
    title: 'Fournisseur',
    cellStyle: { width: 180 },
    editComponent: (props) => (
      <Autocomplete
        options={fournisseurs.map(e => e.name)}
        freeSolo
        value={props.value}
        getOptionLabel={option => option}
        onInputChange={(p, v) => props.onChange(v)}
        renderInput={params => (
          <TextField
            {...params}
            fullWidth
          />
        )}
      />
    ),
  },
  {
    field: 'provider',
    title: 'Categorie',
    cellStyle: { width: 120 },
    editComponent: (props) => (
      <Autocomplete
        options={providers}
        freeSolo
        value={props.value}
        getOptionLabel={option => option}
        onInputChange={(p, v) => props.onChange(v)}
        renderInput={params => (
          <TextField
            {...params}
            fullWidth
          />
        )}
      />

    ),
  },
  {
    field: 'mode',
    title: 'Mode',
    editComponent: (props) => (
      <CustomSelect
        path={['']}
        value={props.value}
        setData={(p, v) => props.onChange(v)}
        fullWidth
      >
        {modes.map((e) => (
          <MenuItem key={e} value={e}>
            {e}
          </MenuItem>
        ))}
      </CustomSelect>
    ),
  },
  {
    field: 'reglement',
    title: 'Type',
    render: (data) => data ? data.reglement : '',
    exportValue: (data) => data ? data.reglement : '',
    initialEditValue: types[0],
    editComponent: (props) => (
      <CustomSelect
        path={['']}
        value={props.value}
        disabled={!canEdit}
        setData={(p, v) => props.onChange(v)}
        fullWidth
      >
        {types.map((e) => (
          <MenuItem key={e} value={e}>
            {e}
          </MenuItem>
        ))}
      </CustomSelect>
    ),
  },
  {
    field: 'montant',
    title: tab === P_FRCO ? 'Montant TTC' : 'Montant HT',
    render: (data) => (data && data.montant ?
      Money.toString(data.montant) : ''),
    exportValue: (data) => (data && data.montant ?
      Money.toString(data.montant, true) : ''),
    editComponent: (props) => (
      <MoneyInput
        defaultValue={props.value}
        path={['']}
        setData={(p, v) => props.onChange(v)}
      />
    )
  },
  {
    field: 'tva',
    title: 'TVA',
    render: (data) => data ? data.tva : 0,
    exportValue: (data) => data ? data.tva : 0,
    hidden: tab === P_FRCO,
    editComponent: (props) => (
      <CustomSelect
        path={['']}
        value={props.value}
        setData={(p, v) => props.onChange(v)}
        fullWidth
      >
        {[0, 5.5, 10, 20].map((e) => (
          <MenuItem key={e} value={e}>
            {e}
          </MenuItem>
        ))}
      </CustomSelect>
    ),
  },
  {
    field: 'montantTtc',
    title: 'TTC',
    render: (data) => Money.toString(
      data.montant * (1 + ((data.tva || 0) / 100))
    ),
    exportValue: (data) => Money.toString(
      data.montant * (1 + ((data.tva || 0) / 100)), true
    ),
    hidden: tab === P_FRCO,
    editComponent: (props) => (
      <Typography>
        {props.rowData.montant ? Money.toString(
          props.rowData.montant * (1 + ((props.rowData.tva || 0) / 100))
        ) : Money.toString(0)}
      </Typography>
    )
  },
  {
    field: 'motif',
    title: 'Motif',
    editComponent: (props) => (
      <CustomSelect
        path={['']}
        value={props.value}
        setData={(p, v) => props.onChange(v)}
        fullWidth
      >
        {motifs.map((e) => (
          <MenuItem key={e} value={e}>
            {e}
          </MenuItem>
        ))}
      </CustomSelect>
    ),
  },
  {
    title: 'Motif d\'annulation',
    field: 'motifCancel',
    hidden: !isArchives,
    render: (data) => (data ? (data.motifCancel) : ''),
    exportValue: (data) => (data ? (data.motifCancel) : ''),
  },
  {
    title: 'Mt de la facture TTC',
    field: 'montantInter',
    hidden: true,
    exportValue: (data) => (data &&
      data.montantInter ? Money.toString(data.montantInter, true) : ''),
  },
  {
    title: 'Mt recupéré',
    field: 'recoveredAmount',
    hidden: true,
    exportValue: (data) => data &&
      data.recoveredAmount ? Money.toString(data.recoveredAmount, true) : '',
  }
]);
