import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import {
  MenuItem,
  Select,
} from '@material-ui/core';

import {
  S_INT,
  S_DEVIS,
  S_LEAD,
  data as servicesData,
} from '@bit/dev-lba.lib.local-globals/services';
import styles from './style';
import CallChips from '../StatsCalls/CallChips';
import StatsPanel from '../common/panel/StatsPanel';
import ObjectifPercent from './ObjectifPercent';

const SERVICES = [S_DEVIS, S_INT, S_LEAD];

const ObjectifPersonal = ({
  classes,
  user,
  selectedUser,
  selectUser,
  ExtraComponent,
  canChange,
  onChange,
  service,
  users,
  CA,
  onDatesChange,
  percents,
  isAdmin,
  from, to, mode
}) => {
  if (!selectedUser && selectUser) {
    selectUser(user._id);
  }

  const hasComponent = ExtraComponent && !!ExtraComponent.props.children;
  return (
    <StatsPanel
      classes={{ container: classes.statsContainer }}
      title={'Objectifs personnel'}
      titleProps={{
        className: classes.title,
        style: { textAlign: 'center' }
      }}
      onDatesChange={onDatesChange}
      hideMenu={!isAdmin}
      defaultState={{
        mode,
        from,
        to,
      }}>
      {(hasComponent || canChange) && (
        <div
          style={{ alignItems: hasComponent ? 'center' : 'baseline' }}
          className={classes.topDiv}
        >
          {canChange && (
            <Select
              value={service}
              className={classes.serviceSelect}
              onChange={(e) => onChange(e.target.value)}>
              {servicesData
                .filter((s) => SERVICES.includes(s._id))
                .map((d) => (
                  <MenuItem key={d._id} value={d._id}>
                    {' '}
                    {d.name}
                  </MenuItem>
                ))}
            </Select>
          )}
          {ExtraComponent}
        </div>
      )}
      {percents && percents.length ?
        <ObjectifPercent
          data={true}
          personal={true}
          percents={percents}
          service={service}
        /> : user && selectedUser &&
        <CallChips
          users={users}
          CA={CA}
          startAt={from}
          endAt={to}
          exclude={[
            'Appels totals',
            'Appels manqués',
            'Interventions ajoutées',
            'Interventions en cours',
            'Interventions démarchées et envoyées',
            'Interventions a prog'
          ]}
          userId={selectedUser}
          xs={6}
        />}
    </StatsPanel>
  );
};

export default withStyles(styles)(ObjectifPersonal);
