import React from 'react';
import { moment } from '@bit/dev-lba.lib.local-globals/moment';
import { Calendar, momentLocalizer } from 'react-big-calendar';


const localizer = momentLocalizer(moment);

const config = {
  allDay: 'Journée',
  previous: 'Précédent',
  next: 'Suivant',
  today: 'Aujourd\'hui',
  month: 'Mois',
  week: 'Semaine',
  day: 'Jour',
  agenda: 'Agenda',
  date: 'Date',
  time: 'Heure',
  event: 'Congé',
  showMore: total => `+ ${total} Congés supplémentaires`,
};

const CustomCalendar = props => (
  <Calendar
    localizer={localizer}
    culture={'fr'}
    startAccessor="start"
    endAccessor="end"
    messages={config}
    {...props}
  />
);


export default CustomCalendar;
