import React from 'react';
import { Link } from 'react-router-dom';

import { moment } from '@bit/dev-lba.lib.local-globals/moment';
import getDataById from '@bit/dev-lba.lib.local-globals/getDataById';

import Money from '../../../utils/Money';
import { SupportPoints } from '../../../utils/IconsDisplay';
import CustomMaterialTable from '../../CustomMaterialTable';
import {
  data as comissionsData,
} from '@bit/dev-lba.lib.local-globals/commissions';
import { S_DEVIS } from '@bit/dev-lba.lib.local-globals/services';
import { genChip } from '../../../utils/chips';

const commissionData =
  comissionsData.find(s => s._id.includes(S_DEVIS));

const columns = ({ commissions, user }) => [
  {
    title: 'OS',
    field: 'id',
    render: (row) => <Link
      to={`/intervention/${row.id}/`}
      children={row.id}
    />
  },
  {
    title: 'Date d\'ajout',
    field: 'date.ajout',
    render: (row) => moment(row.date.ajout).format(
      'DD/MM/YYYY[ à ]HH[h]mm (dddd)'
    )
  },
  {
    title: 'Catégories',
    field: 'categorie',
    render: (row) => getDataById.categories[row.categorie].name
  },
  {
    title: 'Prix Final',
    render: (row) => Money.toString(
      commissionData['calculOnePrix'](row)
    )
  },
  {
    title: 'Commission',
    render: (row) => `${Number(
      commissionData['calculOneCommission'](
        row,
        commissions,
        undefined,
        user
      )
    ).toFixed(2)}
    €`
  },
  {
    title: 'status',
    align: 'center',
    render: (row) => genChip(getDataById.intervention[row.status])
  },
  {
    title: 'Qualité support',
    render: (row) => <SupportPoints elem={row} />
  }
];

export default (props) =>
  <CustomMaterialTable
    columns={columns(props)}
    data={props.commissions}
    title='Liste interventions'
    options={{
      sorting: true,
      pageSize: 10
    }}
  />;

