import React, { Component } from 'react';
import { is } from 'immutable';

import api from '../../api';
import notifSystem from '../../notifSystem';

import { withStyles } from '@material-ui/core/styles';

import {
  List,
  ListItem,
  ListItemText,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';

import {
  S_DEVIS, S_INT, S_BTOB, S_LEAD
} from '@bit/dev-lba.lib.local-globals/services';

const styles = {
  list: {
    width: '100%',
    height: 670,
    overflowY: 'auto',
  },
  summary: {
    boxShadow: '0px 1px 6px 0px rgba(0,0,0,0.2)',
  },
  summaryContent: {
    justifyContent: 'space-between',
  },
};

const supportsNotif = {
  notFound: () => notifSystem.error('Support', 'Utilisateur introuvable'),
  notUpdated: () =>
    notifSystem.error(
      'Support',
      'L\'utilisateur n\'a pas été affilié au support'
    ),
};

const computeUsersList = (users, support) =>
  users.map(u => {
    u.isChecked = false;
    if (support && support.supportIds && support.supportIds.includes(u._id)) {
      u.isChecked = true;
    }
    return u;
  });

const UsersList = withStyles(styles)(
  ({ users, selected, handleClick, classes }) => (
    <List className={classes.list}>
      {users.map((u, i) => (
        <ListItem key={i} divider>
          <ListItemText
            primary={
              <FormControlLabel
                aria-label={u.login}
                onClick={() => handleClick(u._id)}
                control={
                  <Checkbox
                    checked={u.isChecked || u._id === selected || false}
                  />
                }
                label={u.login}
                value={u.isChecked || u._id === selected}
              />
            }
          />
        </ListItem>
      ))}
    </List>
  )
);

class Supports extends Component {
  state = {
    prevUsers: [],
    supports: [],
    prevUserSelected: null,
    userSelected: null,
    attributableUsers: [],
  };

  static getDerivedStateFromProps(props, state) {
    if (!is(props.users, state.prevUsers)) {
      return {
        prevUsers: props.users,
        supports: props.users.filter(u => u.service === S_DEVIS && u.act),
        prevUserSelected: null,
        userSelected: null,
        attributableUsers: [],
      };
    }
    if (state.userSelected !== state.prevUserSelected || state.recompute) {
      return {
        prevUserSelected: state.userSelected,
        attributableUsers: computeUsersList(
          state.prevUsers.filter(
            (u) => [S_INT, S_BTOB, S_LEAD].includes(u.service) && u.act
          ),
          state.supports.find((u) => u._id === state.userSelected)
        ),
      };
    }
    return null;
  }

  selectUser = value => (e, expanded) =>
    this.setState({
      userSelected: expanded && value,
    });

  attributeUser = (supportId, userId) => {
    const { users, setUsers } = this.props;
    const supportIndex = users.findIndex(u => u._id === supportId);
    if (supportIndex === -1) {
      return supportsNotif.notFound();
    }
    const support = users.get(supportIndex);
    if (!(support.supportIds instanceof Array)) {
      support.supportIds = [];
    }
    const userIndex = support.supportIds.findIndex(id => id === userId);
    if (userIndex !== -1) {
      support.supportIds.splice(userIndex, 1);
    } else {
      support.supportIds.push(userId);
    }
    api
      .one('users', supportId)
      .custom('supportIds')
      .patch(support.supportIds)
      .then(() => {
        this.setState(
          {
            recompute: true,
          },
          () => {
            if (setUsers) {
              setUsers(users.set(supportIndex, support));
            }
          }
        );
      })
      .catch(() => {
        supportsNotif.notUpdated();
      });
  };

  render() {
    const { supports, attributableUsers, userSelected } = this.state;
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        {supports &&
          supports.map((u, i) => {
            let secondary;
            if (u.supportIds) {
              const length = u.supportIds.length;
              secondary = `${length} ${
                length === 1 ? 'commercial' : 'commerciaux'
              } affilié${length !== 1 ? 's' : ''}`;
            }
            return (
              <Accordion
                key={i}
                expanded={userSelected === u._id}
                onChange={this.selectUser(u._id)}
              >
                <AccordionSummary
                  classes={{
                    root: classes.summary,
                    content: classes.summaryContent,
                  }}
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography variant="subtitle2" children={u.login} />
                  {secondary ? (
                    <Typography variant="caption" children={secondary} />
                  ) : null}
                </AccordionSummary>
                <AccordionDetails>
                  {userSelected && (
                    <UsersList
                      users={attributableUsers}
                      handleClick={id => this.attributeUser(userSelected, id)}
                    />
                  )}
                </AccordionDetails>
              </Accordion>
            );
          })}
      </div>
    );
  }
}

export default withStyles(styles)(Supports);
