import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { pourcent } from '@bit/dev-lba.lib.local-globals/AdvPaymentConstants';
import { Map } from 'immutable';
import { withStyles } from '@material-ui/styles';
import { Collapse } from '@material-ui/core';

const styles = {
  card: {
    overflow: 'auto',
  },
  box: {
    display: 'flex',
    justifyContent: 'space-around',
  },
};

const Content = ({
  advancePayment,
  setPourcent,
  setMail,
  classes,
}) => (
  <Card elevation={0} className={classes.card}>
    <CardContent>
      <div>
        <div className={classes.box}>
          <Select
            value={advancePayment.get('pourcent', 0) || 0}
            onChange={e =>
              setPourcent(['advPayment', 'pourcent'], Number(e.target.value))
            }
          >
            {pourcent.map((value, index) => (
              <MenuItem key={index} value={index}>
                {value}%
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
    </CardContent>
    {
      <Collapse timeout={600}
        in={advancePayment.get('pourcent', 0) !== 0}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="textarea"
                multiline
                value={advancePayment.get('mail', '').replace(/<br>/g, '\n')}
                onContextMenu={e => {
                  e.stopPropagation();
                }}
                InputProps={{
                  style: styles.TextInput,
                }}
                rows={30}
                onChange={e => setMail(e.target.value)}
                label="Corps du mail"
              />
            </Grid>
          </Grid>
        </CardContent>
      </Collapse>
    }
  </Card>
);


Content.defaultProps = {
  advancePayment: new Map()
};

export default withStyles(styles)(Content);
