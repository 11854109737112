import AirIcon from './AirIcon.js';
import LockIcon from './LockIcon.js';
import SwitchLightIcon from './SwitchLightIcon';
import ThermoIcon from './ThermoIcon';
import ToolsIcon from './ToolsIcon';
import WindowIcon from './WindowIcon';
import { data as cat } from '@bit/dev-lba.lib.local-globals/categories';

export default (categories = []) => [
  {
    name: 'Chauffage',
    color: cat.find(e => e.shortName === 'CH').color,
    icon: ThermoIcon,
    valueMarket: `${categories.length === 0 ?
      '0' : (categories[0]['1'] / 100).toFixed(2)} €`,
    nbrInter: categories.length === 0 ? '0' : categories[0]['1_N'],
  },
  {
    name: 'Climatisation',
    color: cat.find(e => e.shortName === 'CL').color,
    icon: AirIcon,
    valueMarket: `${categories.length === 0 ?
      '0' : (categories[0]['6'] / 100).toFixed(2)} €`,
    nbrInter: categories.length === 0 ? '0' : categories[0]['6_N'],
  },
  {
    name: 'Electricité',
    color: cat.find(e => e.shortName === 'EL').color,
    icon: SwitchLightIcon,
    valueMarket: `${categories.length === 0 ?
      '0' : (categories[0]['2'] / 100).toFixed(2)} €`,
    nbrInter: categories.length === 0 ? '0' : categories[0]['2_N'],
  },
  {
    name: 'Plomberie',
    color: cat.find(e => e.shortName === 'PL').color,
    icon: ToolsIcon,
    valueMarket: `${categories.length === 0 ?
      '0' : (categories[0]['8'] / 100).toFixed(2)} €`,
    nbrInter: categories.length === 0 ? '0' : categories[0]['8_N'],
  },
  {
    name: 'Serrurerie',
    color: cat.find(e => e.shortName === 'SR').color,
    icon: LockIcon,
    valueMarket: `${categories.length === 0 ?
      '0' : (categories[0]['3'] / 100).toFixed(2)} €`,
    nbrInter: categories.length === 0 ? '0' : categories[0]['3_N'],
  },
  {
    name: 'Vitrerie',
    color: cat.find(e => e.shortName === 'VT').color,
    icon: WindowIcon,
    valueMarket: `${categories.length === 0 ?
      '0' : (categories[0]['4'] / 100).toFixed(2)} €`,
    nbrInter: categories.length === 0 ? '0' : categories[0]['4_N'],
  }
];
