import React from 'react';
import Dropdown from '../../common/dropdown/dropdown';
import { Button } from '@material-ui/core';
import {
  AttachMoneyOutlined,
  MoneyOffOutlined
} from '@material-ui/icons';

const HeaderBody = ({ categoryData, selectedCategories,
  tab, display, statusData, selectedStatus, changeArray, toggleDisplay }) => (
  <React.Fragment>
    <Dropdown
      title={'Categories'}
      data={categoryData}
      isSelected={(e) => selectedCategories.includes(e)}
      onChange={(e) => changeArray('selectedCategories', e.target.value[1])}
      disabled={!tab.enable[0]}
    />
    <Button
      onClick={toggleDisplay}
      variant="outlined"
      disabled={!tab.enable[1]}
      color={display ? 'primary' : 'secondary'}
    >
      {display ? <AttachMoneyOutlined /> : <MoneyOffOutlined/> }
    </Button>
    <Dropdown
      title={'Status'}
      data={statusData}
      isSelected={(e) => selectedStatus.includes(e)}
      onChange={(e) => changeArray('selectedStatus', e.target.value[1])}
      disabled={!tab.enable[2]}
    />
  </React.Fragment>
);

export default HeaderBody;
