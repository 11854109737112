/* eslint-disable max-lines */
import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import {
  Phone,
  PanTool,
  Clear,
  Add,
  OpenInNew,
  MusicNote,
  PhoneCallback,
  Call,
} from '@material-ui/icons/';
import { ContextMenuTrigger, ContextMenu, MenuItem } from 'react-contextmenu';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { grey, green, orange, red } from '@material-ui/core/colors';
import TableCollapse from '../../../components/TableCollapse';
import { moment } from '@bit/dev-lba.lib.local-globals/moment';
import {
  SECTION_HISTORY,
  SECTION_REASON,
  SECTION_RESOLUTION,
  SECTION_COMMENTS,
  SECTION_CALLEDBACK
} from '@bit/dev-lba.lib.local-globals/historyTransformCall';
import api from '../../../api';
import { getPseudo, getLoginFromPhone } from '../../../utils/function';
import TablePagination from '@material-ui/core/TablePagination';
import withPagination from '../../../hoc/withPagination';
import { call } from '../../../utils/call';
import notifSystem from '../../../notifSystem';
import { withRouter } from 'react-router';
import AudioPlayer from '../../Calls/AudioPlayer';
import AudioDiscussion from '../../Calls/AudioDiscussion';
import { CircularProgress, IconButton, Tooltip } from '@material-ui/core';
import { Replay, AssignmentInd } from '@material-ui/icons';
import {
  openReasonDialog,
  updateTransformCall
} from '../../../actions/transformCall';

const styles = theme => ({
  iconButton: {
    height: 32,
    padding: '0px 0px',
  },
  menuText: {
    padding: '0px 30px 0px 10px',
  },
  contextMenu: {
    zIndex: 100,
    minWidth: 250
  },
  paper: {
    padding: theme.spacing(3),
    overflow: 'auto'
  },
  content: {
    textAlign: 'center'
  },
  title: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    fontSize: '1.2rem',
    color: grey[500]
  },
  progress: {
    margin: theme.spacing(2),
  },
  block: {
    width: '100%',
    height: 300,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  historyCell: {
    padding: 10
  },
  contentHistory: {
    maxHeight: 170,
    maxWidth: 445,
    paddingBottom: 15,
    overflow: 'auto'
  },
  historyText: {
    fontSize: 14,
    fontWeight: 500,
    color: '#333333'
  },
  audio: {
    minWidth: 350
  },
  centeredContainer: {
    minHeight: 350,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
});

const tableCellTitle = [
  'Heure',
  '',
  'Ligne',
  'Agent',
  'Audio',
  'Numéro du client',
  'Action',
  'Raison',
];

const history = [
  'Ecoute de l\'audio',
  'Appel client'
];

const resolution = [
  'Prise de l\'appel',
  'Refus de l\'appel'
];

const ShowMenu = ({ array, isEmpty, classes }) => {
  let getItems =
    <Typography className={classes.historyText} variant="body1">
      {isEmpty}
    </Typography>;
  if (array.length) {
    getItems = array.reverse().map(item =>
      typeof item.action !== 'object' &&
      <Typography className={classes.historyText} variant="body1">
        {`${moment(item.date).format('L[ à ]HH[h]mm')} - 
        ${getPseudo(item.userId)} : ${item.action}`}
      </Typography>
    );
  }
  return (
    <div className={classes.contentHistory}>
      {getItems}
    </div>
  );
};

const ShowReason = ({ array, isEmpty, classes }) => {
  let getReason =
    <Typography className={classes.historyText} variant="body1">
      {isEmpty}
    </Typography>;
  if (array.length) {
    getReason = array.reverse().map(reason =>
      (typeof reason.action === 'object') ?
        <Typography className={classes.historyText} variant="body1">
          {`${isEmpty}`}
        </Typography> :
        <Typography className={classes.historyText} variant="body1">
          {`${reason.action}`}
        </Typography>
    )[0];
  }
  return (
    <div>
      {getReason}
    </div>
  );
};

const returnSection = (call, kind) =>
  call.history && call.history.length
    ? call.history.filter(item => item.categorie === kind)
    : [];
const mapStateToProps = (state) => ({ userId: state.userId });
class TransformCall extends PureComponent {
  state = {
    calls: null,
    callClicked: null,
    open: null,
    reason: null,
    sectionHistory: [],
    sectionReason: [],
    comment: '',
    sectionResolution: [],
    priority: false,
    numberCalls: 0,
    state: 0,
    loading: true
  };

  getTransformCall = (isUpdate = true) => {
    const dateMoment = moment();
    const {
      page,
      rowsPerPage,
      from = dateMoment.startOf('month').toDate(),
      to = dateMoment.endOf('month').toDate(),
      query = {},
    } = this.props;
    const loading = this.state.loading;
    if (!loading) {
      this.setState({ loading: true });
    }
    api.transformCall
      .custom(`call/${this.state.priority}`)
      .get({
        page,
        rowsPerPage,
        from,
        to,
        query: JSON.stringify(query),
      })
      .then((calls) => {
        this.setState({
          calls: calls.body().data().result,
          numberCalls: calls.body().data().counter,
        });
        if (isUpdate) {
          notifSystem.success('Opération réussie', 'Informations sauvegardées');
        }
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  componentDidMount() {
    this.getTransformCall(false);
  }

  componentDidUpdate(prevProps) {
    const { page, rowsPerPage, to, from } = this.props;
    if (prevProps.page !== page ||
      prevProps.rowsPerPage !== rowsPerPage ||
      prevProps.from !== from ||
      prevProps.to !== to) {
      this.getTransformCall(false);
    }
  }

  getColor = (call) => {
    const sectionHistory = returnSection(call, SECTION_HISTORY);
    const sectionResolution = returnSection(call, SECTION_RESOLUTION);
    if (sectionResolution && sectionResolution.length) {
      return green[200];
    } else if (sectionHistory && sectionHistory.length) {
      return orange[200];
    } else if (call.onlyMusic && !call.agentCallBack && !call.clientCallBack) {
      return red[200];
    }
    return 'inherit';
  }

  getIcons = call => (
    <Grid container spacing={1}>
      {call.onlyMusic ? (
        <Grid item>
          <Tooltip
            title="appel mis en attente"
            children={<MusicNote style={{ color: red[400] }} />}
          />
        </Grid>
      ) : ''}
      {call.agentCallBack ? (
        <Grid item>
          <Tooltip
            title="l'agent a rappelé le client"
            children={<Call style={{ color: green[400] }} />}
          />
        </Grid>
      ) : ''}
      {call.clientCallBack ? (
        <Grid item>
          <Tooltip
            title="le client a rappelé"
            children={<PhoneCallback style={{ color: green[400] }} />}
          />
        </Grid>
      ) : ''}
    </Grid>
  )

  updateCall = (call, update) => updateTransformCall(call, update)
    .then(() => {
      this.setState({
        calls: this.state.calls.map(e => e._id === call._id ?
          ({
            ...e,
            history: (call.history || []).concat(update.history)
          }) : e)
      });
    });

  showHistory = (call) => {
    let { open } = this.state;
    this.setState({ open: open !== call ? call : null });
  }

  openReasonDialog = async (reason, comment) => {
    const update = { _openReasonDialog: !this.state._openReasonDialog };
    if (reason && typeof reason !== 'object') {
      update.reason = reason;
      await this.updateCall(this.state.callClicked, {
        history: {
          date: moment(),
          userId: this.props.userId,
          action: reason,
          categorie: SECTION_REASON,
        },
      });
      if (comment) {
        update.comment = comment;
        await this.updateCall(this.state.callClicked, {
          history: {
            date: moment(),
            userId: this.props.userId,
            action: comment,
            categorie: SECTION_COMMENTS,
          },
        });
      }
    }
    this.setState(update);
  }

  listenAudio = (call) => {
    this.updateCall(call, {
      history: {
        date: moment(),
        userId: this.props.userId,
        action: history[0],
        categorie: SECTION_HISTORY,
      },
    });
  }

  takeCall = () => {
    this.updateCall(this.state.callClicked, {
      history: {
        date: moment(),
        userId: this.props.userId,
        action: resolution[0],
        categorie: SECTION_RESOLUTION,
      },
    });
  }

  refuseCAll = () => {
    this.updateCall(this.state.callClicked, {
      history: {
        date: moment(),
        userId: this.props.userId,
        action: resolution[1],
        categorie: SECTION_RESOLUTION,
      },
    });
  }

  call = () => {
    const { callClicked } = this.state;
    call(callClicked.record.callerIdNumber || callClicked.record.callerIdName);
    this.updateCall(callClicked, {
      history: {
        date: moment(),
        userId: this.props.userId,
        action: history[1],
        categorie: SECTION_HISTORY,
      },
    });
  }

  transferTo = (redirectTo, newTab = false) => ({
    selected,
    history
  }) => {

    if (!newTab) {
      return history.push({
        pathname: `/${redirectTo}`,
      });
    }
    const data = {
      date: {
        ajout: new Date(),
      },
      client: {
        tel1: selected.record.callerIdNumber || '',
      },
    };
    localStorage.setItem(
      `tmpData${name[redirectTo]}_new`,
      JSON.stringify(data)
    );
    return window.open(redirectTo);
  };

  isClicked = (call) => {
    this.setState({ callClicked: call });
  }

  loadButtons = () => [
    {
      name: 'Je prend',
      icon: PanTool,
      color: grey,
      handler: this.takeCall,
    },
    {
      name: 'Je refuse',
      icon: Clear,
      color: red,
      handler: this.refuseCAll,
    },
    {
      name: 'Appel client',
      icon: Phone,
      color: green,
      handler: this.call,
    },
    {
      name: 'Créer un devis',
      icon: Add,
      color: grey,
      link: () => this.transferTo(
        'devis',
        true
      )({
        selected: this.state.callClicked,
        history: this.props.history
      }),
      handler: () => this.transferTo(
        'devis',
        false
      )({
        history: this.props.history
      }),
    },
    {
      name: 'Créer une intervention',
      icon: Add,
      color: grey,
      link: () => this.transferTo(
        'intervention',
        true
      )({
        selected: this.state.callClicked,
        history: this.props.history
      }),
      handler: () => this.transferTo(
        'intervention',
        false
      )({
        history: this.props.history
      }),
    },
  ]

  handleNewOpen = (e, elem) => {
    e.stopPropagation();
    elem.link();
  };

  genItem = (elem) => {
    const { classes } = this.props;
    const Link = () => <ListItemSecondaryAction children={
      <IconButton
        edge="end"
        onClick={e => this.handleNewOpen(e, elem)}
        children={<OpenInNew />}
      />
    } />;
    let item =
      <ListItem key={elem.name} button>
        {elem.icon ? <elem.icon style={{ color: elem.color[500] }} /> : ''}
        <ListItemText
          className={classes.menuText}
          primary={elem.name}
        />
        {elem.link && <Link />}
      </ListItem>;
    return (
      <span key={elem.name}>
        <MenuItem
          key={elem.name}
          data={{ name: elem.name }}
          onClick={elem.handler}
        >
          {item}
        </MenuItem>
      </span>
    );
  };

  render() {
    const {
      classes,
      page,
      rowsPerPage,
      handleChangeRowsPerPage,
      handleChangePage,
    } = this.props;
    const {
      calls,
      priority,
      open,
      numberCalls,
      loading
    } = this.state;

    this.buttons = this.loadButtons();

    if (loading) {
      return <div className={classes.centeredContainer}>
        <CircularProgress />
      </div>;
    }

    if (!calls) {
      return <div className={classes.centeredContainer}>
        <Typography variant='caption' children='Aucune appel trouvé' />
      </div>;
    }

    return (
      <Paper className={classes.paper}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Typography className={classes.title} variant="h6" align="left">
            Appel non transformé
            <Button
              color={!priority ? 'secondary' : 'primary'}
              onClick={() =>
                this.setState(
                  (prev) => ({ priority: !prev.priority }),
                  () => this.getTransformCall()
                )
              }
            >
              Prioritaire
            </Button>
          </Typography>
          <Button onClick={this.getTransformCall}>
            <Replay />
          </Button>
        </Grid>
        <Table>
          <TableHead>
            <TableRow>
              {tableCellTitle.map((title) => (
                <TableCell key={title}>{title}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {calls && calls.length ?
              calls.map(call => (
                <React.Fragment>
                  <ContextMenuTrigger
                    holdToDisplay={-1}
                    id="CALLS"
                    collect={() => this.isClicked(call)}
                    renderTag={TableRow}
                    attributes={{
                      onClick: () => this.showHistory(call),
                      style: { backgroundColor: this.getColor(call) },
                    }}
                  >
                    <TableCell key={'callStart'}>
                      {moment(call.record.callStart).format('HH[h]mm')}
                    </TableCell>
                    <TableCell>
                      {this.getIcons(call)}
                    </TableCell>
                    <TableCell key={'serviceCall'}>
                      {call.service || call.group}
                    </TableCell>
                    <TableCell key={'agentCall'}>
                      {getLoginFromPhone(
                        `0${call.record.agent.slice(4)}`,
                        call.record.agent
                      )}
                    </TableCell>
                    <TableCell
                      className={classes.audio}
                      key={`urlAudio ${call.record.fileUrl}`}
                    >
                      <AudioPlayer
                        forceHide
                        value={call.record.fileUrl}
                        cb={() => {
                          this.listenAudio(call);
                        }}
                        transcription={call.transcription}
                        url={call.record.fileUrl}
                      />
                    </TableCell>
                    <TableCell
                      key="callerIdNumber"
                      children={call.record.callerIdNumber}
                    />
                    <TableCell key={'definedReason'}>
                      <Grid container>
                        <IconButton
                          onClick={(e) => {
                            this.isClicked(call);
                            openReasonDialog(this.props.userId,
                              call,
                              this.updateCall
                            );
                            e.stopPropagation();
                          }}
                          children={<AssignmentInd />}
                        />
                      </Grid>
                    </TableCell>
                    <TableCell>
                      <ShowReason
                        array={returnSection(call, SECTION_REASON)}
                        classes={classes}
                        isEmpty="Aucune raison"
                      />
                    </TableCell>
                  </ContextMenuTrigger>
                  {open && (
                    <TableCollapse
                      colSpan={6}
                      in={open === call}
                      transitionDuration="auto"
                      unmountOnExit
                    >
                      <Grid container>
                        <Grid item md={3} className={classes.historyCell}>
                          <Typography className={classes.title} variant="h6">
                            Historique :
                          </Typography>
                          <ShowMenu
                            array={[
                              ...returnSection(call, SECTION_HISTORY),
                              ...returnSection(call, SECTION_CALLEDBACK)
                            ]}
                            classes={classes}
                            isEmpty={'Aucun Historique'}
                          />
                        </Grid>
                        <Grid item md={3} className={classes.historyCell}>
                          <Typography className={classes.title} variant="h6">
                            Raison :
                          </Typography>
                          <ShowMenu
                            array={returnSection(call, SECTION_REASON)}
                            classes={classes}
                            isEmpty={'Aucune Raison'}
                          />
                        </Grid>
                        <Grid item md={3} className={classes.historyCell}>
                          <Typography className={classes.title} variant="h6">
                            Résolution :
                          </Typography>
                          <ShowMenu
                            array={returnSection(call, SECTION_RESOLUTION)}
                            classes={classes}
                            isEmpty={'Aucun Résolution'}
                          />
                        </Grid>
                        <Grid item md={3} className={classes.historyCell}>
                          <Typography className={classes.title} variant="h6">
                            Commentaire :
                          </Typography>
                          <ShowMenu
                            array={returnSection(call, SECTION_COMMENTS)}
                            classes={classes}
                            isEmpty={'Pas de Commentaire'}
                          />
                        </Grid>
                        {call.transcription &&
                        <Grid item md={3} className={classes.historyCell}>
                          <Typography className={classes.title} variant="h6">
                            Transcription :
                          </Typography>
                          <AudioDiscussion
                            transcription={call.transcription}
                          />
                        </Grid>}
                      </Grid>
                    </TableCollapse>
                  )}
                </React.Fragment>
              )) :
              <TableRow>
                <TableCell colSpan={7} align="center">
                  <Typography
                    variant='caption'
                    children='Aucune appel trouvé' />
                </TableCell>
              </TableRow>
            }
            <TablePagination
              colSpan={11}
              page={page}
              count={numberCalls}
              onChangePage={(e, v) => handleChangePage(v)}
              onChangeRowsPerPage={e =>
                handleChangeRowsPerPage(e.target.value)
              }
              rowsPerPage={rowsPerPage}
            ></TablePagination>
          </TableBody>
        </Table>
        <ContextMenu id="CALLS" className="zIndex-100">
          <Paper className="contextMenu">
            <List>
              {this.buttons.map(elem => this.genItem({ ...elem }))}
            </List>
          </Paper>
        </ContextMenu>
      </Paper>
    );
  }
}

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps),
  withPagination()
)(TransformCall);
