import AbstractGraph from '../../common/graph/AbstractGraph';
import { green, blue, red, blueGrey } from '@material-ui/core/colors';
import {
  data as categoryData
} from '@bit/dev-lba.lib.local-globals/categories';
import BalanceSheet from '../sheet/BalanceSheet';
import DecoteGraph from '../graph/DecoteGraph';

const TABS = [
  {
    label: 'Chiffre d\'affaire',
    enable: [true, false, false],
    display: AbstractGraph,
    colors: [blueGrey[500], green[500], blue[500], red[500], red[500]],
    disposition: ['bar', 'bar', 'bar', 'bar', 'line'],
    errorMessage: 'Impossible de charger les statistique de chiffre d\'affaire',
    fn: 'getCA'
  },
  {
    label: 'Interventions',
    enable: [true, true, true],
    display: AbstractGraph,
    colors: [blue[500]],
    errorMessage: 'Impossible de charger les statistique d\'interventions',
    fn: 'getEntity'
  },
  {
    label: 'Devis',
    enable: [true, true, true],
    display: AbstractGraph,
    colors: [red[500]],
    errorMessage: 'Impossible de charger les statistique de devis',
    fn: 'getEntity'
  },
  {
    label: 'Metiers',
    enable: [false, true, true],
    display: AbstractGraph,
    colors: categoryData.map(c => c.color[500]),
    errorMessage: 'Impossible de charger les statistique de metiers',
    fn: 'getJobs'
  },
  {
    label: 'Appels',
    enable: [false, false, false],
    display: AbstractGraph,
    colors: [red[700], blue[700]],
    disposition: ['line', 'line'],
    errorMessage: 'Impossible de charger les statistique d\'appels',
    fn: 'getCalls'
  },
  {
    label: 'Bilan mensuel',
    enable: [false, false, false],
    display: BalanceSheet,
    errorMessage: 'Impossible de charger le bilan mensuel',
  },
  {
    label: 'Decote',
    enable: [false, true, false],
    display: DecoteGraph,
    colors: [blue[500], green[500], red[500]],
    errorMessage: 'Impossible de charger les statistique de devis',
    fn: 'getDecote'
  },
];

const getSum = (data, label) =>
  data[label] &&
    data[label].length ? Number(Number.parseFloat(data[label]
      .reduce((acc, v) => acc + v), 0).toFixed(2)) : 0;

export { TABS, getSum };

