import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import { withDisplaying } from '../../hoc';

class CustomSelect extends PureComponent {
  constructor() {
    super();
    this.setData = this.setData.bind(this);
  }
  setData(e) {
    const { setData, path } = this.props;
    setData(path, e.target.value);
  }
  render() {
    const {
      xs,
      sm,
      md,
      lg,
      xl,
      label,
      children,
      grid,
      requiredText,
      ...otherProps
    } = this.props;
    delete otherProps.setData;
    delete otherProps.path;
    const input = (
      <FormControl required={requiredText} fullWidth>
        {label ? <InputLabel>{label}</InputLabel> : null}
        <Select
          label={label}
          onChange={this.setData}
          {...otherProps}
        >
          {children}
        </Select>
      </FormControl>
    );
    if (grid) {
      return (
        <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
          {input}
        </Grid>
      );
    }
    return input;
  }
}

CustomSelect.propTypes = {
  path: PropTypes.arrayOf(PropTypes.string),
  label: PropTypes.string,
  setData: PropTypes.func.isRequired,
  grid: PropTypes.bool
};

CustomSelect.displayName = 'CustomSelect';

export default withDisplaying()(CustomSelect);
