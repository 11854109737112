import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { withDisplaying } from '../../hoc';

class CustomCheckbox extends PureComponent {
  constructor() {
    super();
    this.setData = this.setData.bind(this);
  }
  setData(e, v) {
    const { setData, path } = this.props;
    setData(path, v);
  }
  render() {
    const { xs, sm, md, lg, xl, label, grid, classGrid,
      CheckboxOnly, ...props } = this.props;
    delete props.setData;
    const input = (CheckboxOnly ?
      <Checkbox color="primary"
        {...props}
        onChange={this.setData}
      /> :
      <FormControlLabel
        control={
          <Checkbox color="primary"
            {...props}
            onChange={this.setData}
          />
        }
        label={label}
      />
    );
    if (grid) {
      return (
        <Grid
          item
          xs={xs}
          sm={sm}
          md={md}
          lg={lg}
          xl={xl}
          className={classGrid}
        >
          {input}
        </Grid>
      );
    }
    return input;
  }
}


CustomCheckbox.displayName = 'CustomCheckbox';

CustomCheckbox.propTypes = {
  path: PropTypes.arrayOf(PropTypes.string),
  setData: PropTypes.func,
  checked: PropTypes.bool,
  label: PropTypes.string,
  grid: PropTypes.bool,
  classGrid: PropTypes.string,
};

export default withDisplaying()(CustomCheckbox);
