import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Send, Close } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { PropTypes } from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import iphoneX from '../../../public/iphoneX-mockup.png';
import {
  SMS,
  WHATSUP,
  data as smsMode,
} from '@bit/dev-lba.lib.local-globals/smsMode';

const styles = {
  papere: {
    height: 570,
    width: 300,
    backgroundImage: `url("${iphoneX}")`,
    backgroundColor: 'transparent',
    backgroundSize: 'cover',
    boxShadow: 'none',
    backgroundRepeat: 'no-repeat',
  },
  content: {
    padding: '74px 48px 8px 39px !important',
    height: 400,
  },
  contentDevis: {
    padding: '74px 48px 8px 39px !important',
    height: 380,
  },
  actions: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    padding: '8px 14px 8px 4px',
  },
  red: {
    color: '#fff',
    backgroundColor: '#f44336',
  },
  redDevis: {
    color: '#fff',
    backgroundColor: '#f44336',
    width: '73%',
    marginLeft: '0% !important',
    marginTop: 5
  },
  green: {
    color: '#fff',
    backgroundColor: '#4caf50',
  },
  icon: {
    marginLeft: '5px',
  },
};

class IphoneDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: (props.defaultText && props.defaultText.text) ||
      props.defaultText
    };
    this.setTextValue = this.setTextValue.bind(this);
    this.onValidate = this.onValidate.bind(this);
    this.onReject = this.onReject.bind(this);
  }
  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.open !== nextProps.open ||
      this.state !== nextState ||
      this.props.defaultText !== nextProps.defaultText ||
      this.props.callback !== nextProps.callback
    );
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.defaultText !== nextProps.defaultText) {
      const text = (nextProps.defaultText && nextProps.defaultText.text) ||
        nextProps.defaultText;
      this.setState({
        value: text
      });
    }
  }

  setTextValue(e) {
    this.setState({
      value: e.target.value
    });
  }
  onValidate(mode) {
    const { callback, defaultText } = this.props;
    if (typeof callback === 'function') {
      callback(this.state.value, {
        mode,
        withMail: (defaultText && defaultText.withMail)
      });
    }
  }
  onReject() {
    if (typeof this.props.callback === 'function') {
      this.props.callback(null, {});
    }
  }

  render() {
    const {
      open,
      name,
      disagreeText,
      agreeText,
      maxWidth,
      fullWidth,
      classes,
      defaultText
    } = this.props;
    const {
      devisSms,
      disabled
    } = defaultText;
    return (
      <Dialog
        onEscapeKeyDown={this.onReject}
        disableBackdropClick={true}
        open={open}
        maxWidth={maxWidth}
        fullWidth={fullWidth}
        PaperProps={{ style: styles.papere }}
      >
        <div>
          {name && <DialogTitle>{name}</DialogTitle>}
          <DialogContent
            className={devisSms ? classes.contentDevis : classes.content}
          >
            <TextField
              disabled={disabled}
              fullWidth
              onChange={this.setTextValue}
              value={this.state.value}
              multiline={true}
              rows={10}
              style={{ height: '100%' }}
              InputProps={{ style: { height: '100%' } }}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              inputProps={{ style: { height: '100%', fontSize: 14 } }}
            />
          </DialogContent>
          <DialogActions className={classes.actions}>
            {devisSms ? (
              smsMode
                .filter((e) => [SMS, WHATSUP].includes(e._id))
                .map((mode) => (
                  <Button
                    onClick={() => this.onValidate(mode._id)}
                    size="small"
                    variant="contained"
                    className={classes.green}
                  >
                    {mode.name}
                    <mode.icon className={classes.icon} />
                  </Button>
                ))
            ) : (
              <Button
                onClick={this.onValidate}
                size="small"
                variant="contained"
                className={classes.green}
              >
                {agreeText}
                <Send className={classes.icon} />
              </Button>
            )}
            <Button
              onClick={this.onReject}
              size="small"
              variant="contained"
              className={devisSms ? classes.redDevis : classes.red}
            >
              {disagreeText}
              <Close className={classes.icon} />
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    );
  }
}

IphoneDialog.defaultProps = {
  agreeText: 'Envoyer',
  disagreeText: 'Annuler',
  maxWidth: 'sm',
  fullWidth: false
};
IphoneDialog.propTypes = {
  callback: PropTypes.func.isRequired,
  defaultText: PropTypes.string
};

export default withStyles(styles)(IphoneDialog);
