import React from 'react';

import {
  Box,
  Divider,
  Grid,
} from '@material-ui/core';
import { Typography } from '../helpers';
import { withStyles } from '@material-ui/styles';
import Money from '../../../utils/Money';
import BuildIcon from '@material-ui/icons/Build';
import CheckIcon from '@material-ui/icons/Check';

const styles = ({ palette }) => ({
  gridContainer: {
    padding: 0
  },
  mainBox: {
    padding: 12,
    borderRadius: 5,
    backgroundColor: palette.primary.main,
  },
  textColor: {
    color: palette.common.white,
  },
  childBackground: {
    backgroundColor: palette.common.white,
    borderRadius: 5,
  },
  subChildBackground: {
    backgroundColor: palette.common.white,
    margin: 4
  },
  listIcons: {
    color: palette.grey[500],
    verticalAlign: 'middle',
    fontSize: 18,
    margin: '1%',
  },
  listIconsCheck: {
    color: palette.success.main,
    verticalAlign: 'middle',
    fontSize: 18,
    margin: '1%',
  },
  normalText: {
    fontWeight: 400,
  },
  priceTitle: {
    padding: 0,
    display: 'inline-flex',
    alignItems: 'center',
  },
  priceText: {
    textAlign: 'right',
    paddingRight: 18,
    whiteSpace: 'nowrap'
  },
});

export default withStyles(styles)(({ classes, element }) => (
  <Grid item xs={4} className={classes.gridContainer}>
    <Box className={classes.mainBox}>
      <Box p={1}>
        <Typography
          variant="h4"
          className={classes.textColor}
          children={element.description}
        />
      </Box>
      <Divider className={classes.textColor} />
      <Box p={1} mb={3}>
        <Grid container spacing={2} className={classes.childBackground}>
          <Grid item xs={6} className={classes.priceTitle}>
            <Typography
              color="primary"
              variant="subtitle2"
              children={'Le tarif estimatif'}
              className={classes.normalText}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography
              color="primary"
              style={{ fontWeight: 'bold' }}
              className={classes.priceText}
              children={`${
                element.minPrice && element.maxPrice
                  ? `entre ${Money.toString(
                    element.minPrice,
                    true
                  )} et ${Money.toString(element.maxPrice, true)}`
                  : `${element.obligDevis ? 'à partir de' : 'Prix fixe'
                  } ${Money.toString(
                    element.maxPrice || element.minPrice,
                    true
                  )}`
              }`}
              align="center"
            />
          </Grid>
        </Grid>
      </Box>
      <Box p={1}>
        <Grid container spacing={1} className={classes.childBackground}>
          <Grid item xs={12} className={classes.subChildBackground}>
            <Typography
              variant={'h6'}
              className={classes.normalText}
              children="Inclus dans la prestation"
            />
          </Grid>
          <Grid item xs={12} className={classes.subChildBackground}>
            {element.prestations && element.prestations.length ? (
              element.prestations.map((e, i) => (
                <Typography key={i}>
                  <CheckIcon className={classes.listIconsCheck} /> {e.name}
                </Typography>
              ))
            ) : (
              <Typography
                component="p"
                variant="caption"
                children="Aucune prestation"
              />
            )}
          </Grid>
          <Grid item xs={12} className={classes.subChildBackground}>
            <Typography
              variant={'h6'}
              className={classes.normalText}
              children="À vendre sur place"
            />
          </Grid>
          <Grid item xs={12} className={classes.subChildBackground}>
            <Grid container>
              {Array.isArray(element.materials) ? (
                element.materials.map((e, i) => <React.Fragment key={i}>
                  <Grid item xs={10} className={classes.priceTitle}>
                    <BuildIcon className={classes.listIcons} />
                    <Typography>{'  '}{e.name}</Typography>
                  </Grid>
                  <Grid item xs={2} style={{ padding: 0 }}>
                    <Typography
                      align="center"
                      color="primary"
                      className={classes.priceText}
                      children={Money.toString(e.price, true)}
                    />
                  </Grid>
                </React.Fragment>)
              ) : (
                <Grid item xs={12}>
                  <Typography
                    component="p"
                    variant="caption"
                    children="Aucun matériaux"
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  </Grid>
));
