import api from '../../../../api';
import { I_ANN, I_VRF } from '@bit/dev-lba.lib.local-globals/iStatus';
import { S_DEVIS } from '@bit/dev-lba.lib.local-globals/services';
import { getSum } from '../utils';
import { moment } from '@bit/dev-lba.lib.local-globals/moment';
import {
  getCustomFromUser, getUsersByService
} from '../../../../utils/function';
import { D_NOT } from '@bit/dev-lba.lib.local-globals/deStatus';

const defaultRequest = (path, data, onSuccess, onFail) => {
  api.stats
    .get(`rh/${path}`, data)
    .then((result) => onSuccess(result.body().data()))
    .catch(() => onFail());
};

const getCA = (data, onSuccess, onFail) => {
  defaultRequest('CA', data, onSuccess, onFail);
};

const getEntity = (data, onSuccess, onFail) => {
  defaultRequest('entity', data, onSuccess, onFail);
};

const getJobs = (data, onSuccess, onFail) => {
  defaultRequest('jobs', data, onSuccess, onFail);
};

const getCalls = (data, onSuccess, onFail) => {
  defaultRequest('calls', data, onSuccess, onFail);
};

const getNoCall = (data, onSuccess, onFail) => {
  defaultRequest('noCall', data, onSuccess, onFail);
};

const workDay = (data, onSuccess, onFail) => {
  defaultRequest('workDay', data, onSuccess, onFail);
};

const getSignalements = (data, onSuccess, onFail) => {
  defaultRequest('signalements', data, onSuccess, onFail);
};

const getTopSignalements = (data, onSuccess, onFail) => {
  defaultRequest('signalements/top', data, onSuccess, onFail);
};

const getTopNotation = (data, onSuccess, onFail) => {
  defaultRequest('notation/top', data, onSuccess, onFail);
};

const download = (data, onSuccess, onFail) => {
  api.stats
    .custom('rh/bilan/download').post(data)
    .then(result => onSuccess(result.body().data()))
    .catch(() => onFail());
};

const getSchedule = user => {
  let schedule = getCustomFromUser(user, 'schedule')[0];

  if (schedule) {
    return Object.keys(schedule).map(key => ({ [key]: schedule[key].work }));
  }

  return null;
};

const balanceRequests = (data, yearDate) => [
  {
    function: getCA,
    data: { ...data, ...yearDate },
    resultKey: 'data',
    key: 'CA',
  },
  {
    function: getEntity,
    data: { ...data, source: 'Interventions', ...yearDate, display: true },
    resultKey: 'data',
    key: 'intervention',
    dataKey: 'Interventions',
  },
  {
    function: getEntity,
    data: { ...data, source: 'Devis', ...yearDate, display: true },
    resultKey: 'data',
    key: 'devis',
    dataKey: 'Devis',
  },
  {
    function: getEntity,
    data: {
      ...data,
      source: 'Interventions',
      ...yearDate,
      key: 'verif',
      display: true,
    },
    resultKey: 'data',
    key: 'verified0',
    dataKey: 'Interventions',
  },
  {
    function: getEntity,
    data: {
      ...data,
      source: 'Interventions',
      ...yearDate,
      status: JSON.stringify([I_VRF]),
      cond: JSON.stringify({
        'login.verif': { $in: getUsersByService(S_DEVIS).map((i) => i._id) },
      }),
      display: true,
    },
    resultKey: 'data',
    key: 'verified1',
    dataKey: 'Interventions',
  },
  {
    function: getEntity,
    data: { ...data, source: 'Interventions', display: true },
    resultKey: 'data',
    key: 'totalIntervention',
    dataParser: (data) => getSum(data, 'Interventions'),
  },
  {
    function: getEntity,
    data: {
      ...data,
      source: 'Interventions',
      display: true,
      cond: JSON.stringify({ aDemarcher: { $ne: D_NOT } }),
    },
    resultKey: 'data',
    key: 'totalDIntervention',
    dataParser: (data) => getSum(data, 'Interventions'),
  },
  {
    function: getEntity,
    data: { ...data, source: 'Devis', display: true },
    resultKey: 'data',
    key: 'totalDevis',
    dataParser: (data) => getSum(data, 'Devis'),
  },
  {
    function: getEntity,
    data: { ...data, source: 'Devis', display: true, key: 'transfer' },
    resultKey: 'data',
    key: 'totalTDevis',
    dataParser: (data) => getSum(data, 'Devis'),
  },
  {
    function: getCalls,
    data,
    resultKey: 'data',
    key: 'totalCalls',
    dataParser: (data) => getSum(data, 'Utilisateur'),
  },
  {
    function: getCalls,
    data,
    resultKey: 'data',
    key: 'totalCalls',
    dataParser: (data) => getSum(data, 'Utilisateur'),
  },
  {
    function: getNoCall,
    data,
    resultKey: 'data',
    key: 'noCall',
  },
  {
    function: getEntity,
    data: {
      ...data,
      source: 'Interventions',
      display: true,
      status: JSON.stringify([I_ANN]),
    },
    resultKey: 'data',
    key: 'totalInterventionFailed',
    dataParser: (data) => getSum(data, 'Interventions'),
  },
  {
    function: getCA,
    data,
    resultKey: 'data',
    key: ['expectedTurnover', 'annulationTurnover'],
    dataParser: [
      (data) => getSum(data, 'Potentiel'),
      (data) => getSum(data, 'Annulé'),
    ],
  },
  {
    function: workDay,
    data,
    resultKey: 'workingDay',
    key: 'workingDay',
  },
  {
    function: getJobs,
    data,
    resultKey: 'data',
    key: 'jobs',
    dataParser: (data) =>
      Object.keys(data).reduce((acc, key) => {
        acc[key] = getSum(data, key);
        return acc;
      }, {}),
  },
  {
    function: getJobs,
    data: { ...data, failed: true },
    resultKey: 'data',
    key: 'failed_jobs',
    dataParser: (data) =>
      Object.keys(data).reduce((acc, key) => {
        acc[key] = getSum(data, key);
        return acc;
      }, {}),
  },
  {
    function: getSignalements,
    data,
    resultKey: 'signalements',
    key: 'signalements',
  },
  {
    function: getTopSignalements,
    data,
    resultKey: 'data',
    key: 'topSignalements',
  },
  {
    function: getTopNotation,
    data,
    resultKey: 'data',
    key: 'topNotation',
  },
];

const getBalance = (data, onSuccess, onFail) => {
  const yearDate = {
    mode: 'year',
    from: moment(data.from)
      .startOf('year')
      .toDate(),
    to: moment(data.to)
      .endOf('year')
      .toDate(),
  };

  const object = {};

  const push = (key, data) => {
    object[key] = data;
    if (
      Object.keys(object).length + 1 ===
      balanceRequests(data, yearDate).reduce(
        (acc, i) => acc + (Array.isArray(i.key) ? i.key.length : 1),
        0
      )
    ) {
      object['entities'] = {
        Interventions: object['intervention'],
        Devis: object['devis'],
      };

      object['verifiedIntervention'] = {
        Verifié: object['verified0'],
        'Verifié par support': object['verified1'],
      };
      onSuccess(object);
    }
  };

  balanceRequests(data, yearDate).forEach(request => {
    request.function(
      request.data,
      (result) => {
        const data = result[request.resultKey];

        if (request.dataParser) {
          if (Array.isArray(request.key)) {
            request.key.forEach((key, i) =>
              push(key, request.dataParser[i](data))
            );
          } else {
            push(request.key, request.dataParser(data));
          }
        } else {
          push(request.key, request.dataKey ? data[request.dataKey] : data);
        }
      },
      () => onFail()
    );
  });
};

export default {
  getCA,
  getEntity,
  getJobs,
  getCalls,
  getBalance,
  getSchedule,
  download
};
