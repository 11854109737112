import React from 'react';

import { AppBar, Tabs, Badge, Tab } from '@material-ui/core';
import collections from '../../constants/collections';

const getTab = value => Object.values(collections).includes(value)
  ? {
    [collections.interventions]: 0,
    [collections.savInterventions]: 1,
  }[value]
  : value;

class TabsWithCounter extends React.PureComponent {
  state = { value: getTab(this.props.value) || 0 };

  componentDidUpdate() {
    if (this.props.value !== this.state.value) {
      this.setState({
        value: getTab(this.props.value)
      });
    }
  }

  handleChange = (e, value) => {
    const { onChange = f => f } = this.props;
    this.setState({ value: getTab(value) }, () => onChange(e, value));
  }

  render() {
    const {
      values,
      noAppBar,
      color = 'primary',
      checkCounter,
      indicatorColor,
      textColor,
      ...props
    } = this.props;
    const { value } = this.state;
    const tabs = <Tabs
      variant="fullWidth"
      scrollButtons="auto"
      {...props}
      value={value}
      indicatorColor={indicatorColor}
      textColor={textColor}
      onChange={this.handleChange}
      children={values.map((m, i) => {
        const label = m.counter || m.showBadge ? (
          <Badge
            badgeContent={m.counter}
            color="secondary"
            children={m.name}
            variant={m.variant}
          />
        ) : (
          m.name
        );

        return checkCounter ?
          m.counter && <Tab key={i} value={i} label={label} /> :
          <Tab key={i} value={i} label={label} />;
      })}
    />;
    return noAppBar ? tabs :
      <AppBar position="static" color={color} children={tabs} />;
  }
}

export default TabsWithCounter;
