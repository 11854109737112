export const getBarOptions = function (data, colors, title, month) {

  if (!Object.keys(data)[0]) {
    return {};
  }

  const series = [];

  Object.keys(data).forEach(u => {
    series.push({
      name: u,
      type: 'bar',
      stack: 'A',
      label: {
        normal: {
          show: false,
        }
      },
      data: Object.values(data[u])
    });
  });

  const option = {
    title: {
      text: title,
      subtext: month,
      x: 'center'
    },
    xAxis: {
      type: 'category',
      data: Object.keys(Object.values(data)[0])
        .map(e => Number(e)).sort((a, b) => a - b)
    },
    yAxis: {
      type: 'value'
    },
    legend: {
      data: Object.keys(data),
      bottom: 0
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    series: series
  };

  return option;
};
