import {
  S_INT,
  S_SATCL,
  S_DEVIS,
  S_LEAD,
} from '@bit/dev-lba.lib.local-globals/services';
import grey from '@material-ui/core/colors/grey';
import orange from '@material-ui/core/colors/orange';
import green from '@material-ui/core/colors/green';
import brown from '@material-ui/core/colors/brown';
import red from '@material-ui/core/colors/red';
import blue from '@material-ui/core/colors/blue';

const defaultCommercial = [
  { path: 'ajout', url: 'ajd', count: 'ajdCount', color: grey[500] },
  { path: 'envoi', url: 'counEnvAjd', color: orange[500] },
  { path: 'verif', url: 'counVrf', color: green[500] },
  { path: 'ajout', url: 'counAvr', color: brown[500] },
  { path: 'cancel', url: 'counAnnAjd', color: red[500] },
  { path: 'ajout', url: 'counAprogAjd', color: blue[500] },
];
export const urlsCounter = {
  [S_INT]: defaultCommercial,
  [S_LEAD]: defaultCommercial,
  [S_SATCL]: [
    { path: 'ajout', url: 'savEnc', color: orange[500],
      linkTo: 'savIntervention' },
    { path: 'ajout', url: 'savApr', color: blue[500],
      linkTo: 'savIntervention' },
    { path: 'ajout', url: 'savAref', color: red[500],
      linkTo: 'savIntervention' },
    { path: 'ajout', url: 'savAvr', color: brown[500],
      linkTo: 'savIntervention' },
    { path: 'sav', url: 'savSuivi', color: green[500],
      linkTo: 'savIntervention' }
  ],
  [S_DEVIS]: [
    { path: 'ajout', url: 'ajd', color: grey[500] },
    { path: 'ajout', url: 'envAjdSupp', color: orange[500] },
    { path: 'verif', url: 'counVrf', color: green[500] },
    { path: 'ajout', url: 'avrSupp', color: brown[500] },
    { path: 'cancel', url: 'counAnnAjd', color: red[500] },
    { path: 'ajout', url: 'counAprogAjd', color: blue[500] }
  ]
};
