import { M_ALL, M_DEC } from '@bit/dev-lba.lib.local-globals/moStatus';

import api from '../api';
import notifSystem from '../notifSystem';

export const acceptModification = (id, collection, dataId, data) =>
  api.modifications
    .patch(id, { status: M_ALL })
    .then(() => api[collection].patch(dataId, data))
    .then(() => notifSystem.success(
      'Opération réussie',
      'Modification validée'
    ))
    .catch(e => notifSystem.error(
      'Erreur',
      e.response ? e.response.data : e.message
    ));

export const declineModification = id =>
  api.modifications
    .patch(id, { status: M_DEC })
    .then(() => notifSystem.success(
      'Opération réussie',
      'Modification refusée'
    ))
    .catch(e => notifSystem.error(
      'Erreur',
      e.response ? e.response.data : e.message
    ));

