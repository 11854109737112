import { moment } from '@bit/dev-lba.lib.local-globals/moment';

export const getOption = function (data, mode) {
  let arr = [];
  if (mode === 'day') {
    arr = [
      '7h',
      '8h',
      '9h',
      '10h',
      '11h',
      '12h',
      '13h',
      '14h',
      '15h',
      '16h',
      '17h',
      '18h',
    ];
  } else if (mode === 'week') {
    arr = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
  } else {
    arr = moment.monthsShort();
  }

  let labels = ['Intervention', 'Devis'];

  let dataSeries = [];

  Object.keys(data).map((objectKey) =>
    dataSeries.push({
      name: labels[Number(objectKey) - 1],
      type: 'bar',
      barGap: 0,
      data: data[objectKey],
    })
  );

  return {
    color: ['#003366', '#e5323e'],
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    legend: {
      data: labels,
    },
    toolbox: {
      show: true,
      orient: 'vertical',
      left: 'right',
      top: 'center',
      feature: {
        saveAsImage: { show: true },
      },
    },
    calculable: true,
    xAxis: [
      {
        type: 'category',
        axisTick: { show: true },
        data: arr,
      },
    ],
    yAxis: [
      {
        type: 'value',
      },
    ],
    series: dataSeries,
  };
};
