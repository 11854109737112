import React from 'react';

import { Button, Grid } from '@material-ui/core';


const buttons = [
  {
    id: 2,
    name: 'En 2x',
    disabled: (amount) => !(+amount >= 10000 && +amount <= 600000)
  },
  {
    id: 3,
    name: 'En 3x',
    disabled: (amount) => !(+amount >= 10000 && +amount <= 600000)
  },
  {
    id: 4,
    name: 'En 4x',
    disabled: (amount) => !(+amount >= 20000 && +amount <= 600000)
  },
  {
    id: 6,
    name: 'En 6x',
    disabled: (amount) => !(+amount >= 150000 && +amount <= 600000)
  },
  {
    id: 10,
    name: 'En 10x',
    disabled: (amount) => !(+amount >= 150000 && +amount <= 600000)
  }
];

export default ({
  handleChange, mode, finalPrice, tva, advancePaymentMontant
}) => {
  const amount = Math.round(finalPrice * (
    (tva / 100) + 1)) - advancePaymentMontant;

  return (
    <Grid container justifyContent="center" spacing={2}>
      {buttons.map((e, i) => (
        <Grid key={i} item xs={12} sm={4}>
          <Button
            variant="contained"
            fullWidth
            disabled={e.disabled(amount)}
            color={mode === e.id ? 'secondary' : 'primary'}
            onClick={() => handleChange('mode')(e.id)}
            children={e.name}
          />
        </Grid>
      ))}
    </Grid>
  );
};
