import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';

import FileDisplayer from '../Utils/FileDisplayer';
import MinCustomDialog from './MinCustomDialog';
import stopPropagation from '../../utils/stopPropagation';

const styles = theme => ({
  icon: {
    position: 'absolute',
    top: 3,
    right: 5,
    '&:hover': {
      cursor: 'pointer',
      color: 'lightgrey'
    }
  },
  paper: {
    width: '85%',
    height: '85%',
    backgroundColor: '#6a6a6a',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '100%'
    }
  },
  displayer: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    overflow: 'auto'
  }
});

const FullscreenFileDialog = ({ classes, file, close }) => (
  <MinCustomDialog
    open
    fullWidth
    maxWidth={false}
    onClose={close}
    onClick={close}
    dialogClasses={{ paper: classes.paper }}
  >
    <React.Fragment>
      <Close
        className={classes.icon}
        onClick={stopPropagation(close)}
        fontSize={'small'}
      />
      <FileDisplayer
        classes={{ centered: classes.displayer }}
        content={file.url}
        name={file.name}
      />
    </React.Fragment>
  </MinCustomDialog>
);

export default withStyles(styles)(FullscreenFileDialog);
