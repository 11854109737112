import React, { PureComponent } from 'react';
import { Button, Grid, Hidden } from '@material-ui/core';
import {
  ThemeProvider,
  withStyles,
  createTheme,
} from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import CustomPasswordInput from './CustomInputs/CustomPasswordInput';
import lesbonsartisansdef from '../../public/lesbonsartisansdef.png';
import home from '../../public/home.jpg';
import notifSystem from '../notifSystem';
import api from '../api';

const styles = {
  main: {
    backgroundColor: 'white',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    zIndex: 300,
    transition: 'opacity 0.5s',
  },
  center: {
    margin: 'auto',
  },
  imgX: {
    width: '50%',
    height: '100%',
    'background-image': `url("${home}")`,
    'background-size': 'cover',
    'background-repeat': 'non-repeat',
    'background-position': 'right',
  },
  login: {
    'margin-top': '30%',
    padding: 20,
    textAlign: 'right',
  },
  img: {
    position: 'fixed',
    maxWidth: 300,
    top: 25,
    left: 50,
  },
  txt: {
    float: 'left',
    maxWidth: '50%',
    textAlign: 'left',
  },
  marginBottom: {
    marginBottom: 20,
    display: 'flex'
  },
  displayInput: {
    width: '45%',
    margin: '2.5% 0',
  },
  marginTopLeft: {
    float: 'left',
  },
};

const initState = {
  password: '',
  verify: '',
  error: '',
};

const theme = createTheme({
  palette: {
    primary: blue,
  },
});

class ResetPassword extends PureComponent {
  constructor() {
    super();
    this.state = initState;
  }

  componentDidMount() {
    const { id: userId } = this.props.match.params;
    this.setState({ userId });
  }

  setPassword = async (password, verify, userId) => {
    if (!password) {
      notifSystem.error('Erreur!', 'Mot de passe non saisi');
      return false;
    }
    if (password !== verify) {
      notifSystem.error('Erreur!', 'Les mots de passe sont différents');
      return false;
    }
    try {
      const res = await api.all('setPassword')
        .post({
          password: password,
          _id: userId
        });
      if (res.statusCode() === 200) {
        notifSystem.success('Succès', 'Mot de passe modifié');
        notifSystem.success('Vous allez être redirigé',
          'Bienvenue chez Les Bons Artisans');
        setTimeout(() => (window.location.assign('/')), 3000 );
      }
    } catch (error) {
      notifSystem.error(error.name, error.message);
    }
    return false;
  }

  changePassword = () => {
    const { password, verify, userId } = this.state;
    if (this.setPassword(password, verify, userId)) {
      this.setState(initState);
    }
  }

  render() {
    const classes = this.props.classes;
    return (
      <ThemeProvider theme={theme}>
        <div className={classes.main}>
          <Grid className={classes.center} item xs={12} md={12} lg={5}>
            <Grid className={classes.login}>
              <img
                src={lesbonsartisansdef}
                alt="logo"
                className={classes.img}
                width={'100%'}
              />
              <div className={classes.marginBottom}>
                <CustomPasswordInput
                  label="Nouveau mot de passe"
                  showSecurityLevelDescription
                  showSecurityLevelBar
                  value={this.state.password}
                  className={classes.displayInput}
                  onChange={
                    ({ value }) => this.setState({ password: value })
                  }
                />
                <CustomPasswordInput
                  label="Vérifier le mot de passe"
                  value={this.state.verify}
                  className={classes.displayInput}
                  onChange={({ value }) => this.setState({ verify: value })}
                />
              </div>
              <Button
                variant="contained"
                color="primary"
                className={classes.marginTop}
                onClick={this.changePassword}
              >
                Valider mon mot de passe
              </Button>
            </Grid>
          </Grid>
          <Hidden mdDown>
            <div src={home} alt="logo" className={classes.imgX} />
          </Hidden>
        </div>
      </ThemeProvider>
    );
  }
}

export default withStyles(styles)(ResetPassword);
