import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Paper } from '@material-ui/core';
import { ClassementTable, UserCard, TrustBox } from '../../components/Stats';
import SupportStats from '../../components/Stats/Support';
import Planning from '../../components/Stats/SchudleStats/planning';
import Supports from '../../components/TableConfigs/Supports';
import { CHIEF } from '@bit/dev-lba.lib.local-globals/permissions';
import UserHolidaysConfig from '../../components/Users/holidays';
import ObjectifsContainer from './utils/ObjectifsContainer';
import {
  S_DEVIS, S_INT
} from '@bit/dev-lba.lib.local-globals/services';

const styles = () => ({
  paper: {
    textAlign: 'center',
    width: '100%',
  },
  paperTrustpilot: {
    padding: '40px 0 0 5px',
    minHeight: '160px',
    textAlign: 'center',
    width: '100%',
    height: 'calc(100% - 45px)',
  },
});

class SupportDashboard extends Component {
  state = {
    panelOpen: false,
    loading: true,
  }

  render() {
    const { user, users, classes } = this.props;
    const isAdmin = user.permission <= CHIEF;
    const nUsers = isAdmin ?
      users.toJS().filter(e => e.act && e.service === S_DEVIS)
        .concat(user) : [user];
    return (
      <Grid container spacing={2}>
        {!(isAdmin && user.service === S_INT) ? (
          <Grid container spacing={2} item xs={12}>
            <Grid item md={12} lg={4}>
              {UserCard && <UserCard user={user} />}
            </Grid>
            <Grid item md={12} lg={8}>
              <ObjectifsContainer
                user={user}
                personal
                allUsers={users}
                service={S_DEVIS}
                route="support"
              />
            </Grid>
          </Grid>
        ) : null}
        <Grid item md={12} lg={6} container spacing={1}>
          <Grid item xs={12}>
            <Paper className={`${classes.paper} cards`}>
              <ClassementTable />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper>
              <SupportStats
                noTabs
                users={nUsers}
                displayInput={isAdmin}
                defaultUser={user._id}
                hideIcons
                selectedLabel={'Qualité support par critère'}
              />
            </Paper>
          </Grid>
        </Grid>
        <Grid item md={12} lg={6} container spacing={1}>
          <Grid item xs={12}>
            <Paper className={`${classes.paperTrustpilot} cards`}>
              <TrustBox />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <SupportStats
              users={nUsers}
              defaultUser={user._id}
              selectedTab={0}
              displayInput={isAdmin}
              hideIcons
              title={'Interventions'}
            />
          </Grid>
          <Grid item xs={12}>
            <SupportStats
              noTabs
              users={nUsers}
              displayInput={isAdmin}
              defaultUser={user._id}
              selectedLabel={'Commissions'}
            />
          </Grid>
        </Grid>
        <Grid item md={12}>
          <SupportStats
            noTabs
            users={nUsers}
            displayInput={isAdmin}
            defaultUser={user._id}
            hideIcons
            selectedLabel="Qualité support"
          />
        </Grid>
        <Grid item md={6}>
          <SupportStats
            noTabs
            users={nUsers}
            hideIcons={true}
            displayInput={isAdmin}
            defaultUser={user._id}
            selectedLabel={'Chiffre d\'affaire pour l\'auteur'}
          />
        </Grid>
        <Grid item md={6}>
          <SupportStats
            noTabs
            users={nUsers}
            displayInput={isAdmin}
            defaultUser={user._id}
            hideIcons
            selectedLabel={'Mise à jour artisans'}
          />
        </Grid>
        <Grid item md={12}>
          <Planning SchudleStatsData={user} />
        </Grid>
        {isAdmin && (
          <Grid item md={12}>
            <Supports users={users} />
          </Grid>
        )}
        <Grid item md={12}>
          <UserHolidaysConfig />
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(SupportDashboard);
