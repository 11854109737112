import React from 'react';
import {
  FormControlLabel,
  Typography,
  Radio,
  Grid,
  FormControl,
  RadioGroup
} from '@material-ui/core';
import CustomTextField from '../../CustomInputs/CustomTextField';
import MoneyInput from '../../CustomInputs/MoneyInput';

const Content = ({
  shift,
  budget,
  withSupplies,
  description,
  setShift,
  setBudget,
  setWithSupplies,
  setDescription,
}) => (
  <Grid container alignItems="center" spacing={2}>
    <Grid item xs={5}>
      <Typography children="Décalage possible ?" />
    </Grid>
    <Grid item xs={7}>
      <FormControl component="fieldset">
        <RadioGroup
          value={shift}
          style={{ display: 'inline' }}
          onChange={(_, val) => setShift(val)}
        >
          {['Non', '24h', '48h', '+72h'].map((e, i) =>
            <FormControlLabel
              key={i}
              value={e}
              label={e}
              control={<Radio />}
            />
          )}
        </RadioGroup>
      </FormControl>
    </Grid>
    <Grid item xs={2}>
      <Typography children="Budget:" />
    </Grid>
    <Grid item xs={2}>
      <MoneyInput grid xs={12}
        defaultValue={budget}
        setData={(p, v) => setBudget(Number(v))}
        requiredText={true}
      />
    </Grid>
    <Grid item xs={1}>
      <Typography children="€" />
    </Grid>
    <Grid item xs={7}>
      <FormControl component="fieldset">
        <RadioGroup
          value={withSupplies}
          style={{ display: 'inline' }}
          onChange={(_, val) => setWithSupplies(val === 'true')}
        >
          {[
            { value: true, label: 'Avec fourniture' },
            { value: false, label: 'Sans fourniture' }
          ].map((e, i) =>
            <FormControlLabel
              key={i}
              value={e.value}
              label={e.label}
              control={<Radio />}
            />
          )}
        </RadioGroup>
      </FormControl>
    </Grid>
    <Grid item xs={5}>
      <Typography children="Info complémentaire:" />
    </Grid>
    <Grid item xs={7}>
      <CustomTextField
        label="Entrer une valeur"
        value={description}
        setData={(p, v) => setDescription(v)}
        rows={3}
        multiline
        rowsMax={10}
        fullWidth
      />
    </Grid>
  </Grid>
);

export default Content;
