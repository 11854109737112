import {
  green,
  blue,
  brown, grey
} from '@material-ui/core/colors';

export default theme => ({
  green: {
    backgroundColor: green[500],
    color: '#fff',
  },
  blue: {
    backgroundColor: blue[500],
    color: '#fff',
  },
  brown: {
    backgroundColor: brown[500],
    color: '#fff',
  },
  grey: {
    backgroundColor: grey[500],
    color: '#fff',
  },
  container: {
    flex: '1 1 auto',
    padding: '0 16px',
  },
  top: {
    display: 'block',
    fontSize: '1rem',
    fontWeight: '400',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif;',
    lineHeight: '1.5em'
  },
  bot: {
    display: 'block',
    fontSize: '0.875rem',
    fontWeight: '400',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif;',
    lineHeight: '1.46429em'
  },
  mobileCardSearch: {
    [theme.breakpoints.down('sm')]: {
      position: 'fixed',
      bottom: -window.screen.height,
      width: '85%',
      height: '85%',
      overflowY: 'scroll',
      background: '#fff',
      borderRadius: '30px',
      borderTop: '2px solid #eaeaea',
      transition: '2s all'
    },
  },
  titleFixed: {
    position: 'fixed',
  },
  endContentCard: {
    marginBottom: '200px',
  },
  animationCardUp1: {
    bottom: '-5%',
  },
  animationCardUp2: {
    bottom: '-15%',
  },
  animationCardUp3: {
    bottom: '-25%',
  },
  devisUp: {
    bottom: '-15%',
  },
  devisDown: {
    bottom: '-65%',
  },
  artisansUp: {
    bottom: '-25%',
  },
  artisansDown: {
    bottom: '-75%',
  },
  fullHeight: {
    height: '100%'
  },
  mmsContainer: {
    maxHeight: 120,
    overflow: 'auto',
    maxWidth: 115,
    marginLeft: '-15px'
  },
  titleAutres: {
    display: 'block',
    fontSize: '1rem',
    fontWeight: '500',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif;',
    lineHeight: '1.5em'
  }
});
