import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MinCustomDialog from './MinCustomDialog';
import { Typography } from '@material-ui/core';
import {
  permit
} from '@bit/dev-lba.lib.local-globals/restrictions';
import {
  S_DEVIS
} from '@bit/dev-lba.lib.local-globals/services';
import notifSystem from '../../notifSystem';

const mapStateToProps = (state) => {
  const user = state.users.find((e) => e._id === state.userId);
  return {
    counter: state.counter,
    user,
    isSupport: state.users.find(
      (e) => e._id === user._id && user.service === S_DEVIS
    ),
    users: state.users,
  };
};

class DialogSupport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      comments: ((props.defaultText || {}).data || []).reverse(),
    };
  }

  setTextValue = e => {
    this.setState({
      value: e.target.value,
    });
  };

  onValidate = () => {
    if (typeof this.props.callback === 'function') {
      this.props.callback(this.state.value);
    }
  };
  onReject = value => {
    if (typeof this.props.callback === 'function') {
      this.props.callback(value);
    }
  };

  steps = {
    1: {
      actions: [
        {
          title: 'Annuler',
          onClick: () => this.onReject(null),
          color: 'secondary',
        },
        {
          title: 'Non',
          onClick: () => this.onReject(true),
          color: 'secondary',
        },
        {
          title: 'Valider',
          onClick: () => !this.state.value
            ? notifSystem.error('Erreur', 'Veuillez saisir un texte.')
            : this.onValidate(),
          color: 'primary',
        },
      ],
      title: 'Y a t-il précision à apporter a votre intervention ?',
    },
    2: {
      actions: [
        {
          title: 'OK',
          onClick: () => this.onReject(null),
          color: 'secondary',
        },
      ],
      title:
        'Vous n\'avez plus de crédit aujourd\'hui pour rajouter au support 😉'
    },
  };

  render() {
    const {
      counter,
      defaultText: { inter },
      isSupport,
      users,
    } = this.props;
    let user = this.props.user;
    if (isSupport) {
      user = users.find(e => [e._id, e.login].includes(inter.login.ajout));
    }
    const points = counter[`interventions.countSupport.${user.login}`] || 0;
    const userPermission = permit(user, { key: 'supportAjout' });
    const permission = userPermission || points < user.support;
    const step = this.steps[permission ? 1 : 2];
    return (
      <MinCustomDialog
        middle
        open
        title={step.title}
        actions={step.actions.map(({ title, onClick, color }, i) => (
          <Button key={i} onClick={onClick} color={color}>
            {title}
          </Button>
        ))}
      >
        {permission ?
          [<Typography
            key='1'
            variant="subtitle1"
            children={`Il vous reste ${(user.support || 0) -
            points} point aujourd'hui`}
          />,
          <TextField
            key='2'
            fullWidth
            onChange={this.setTextValue}
            value={this.state.value}
            multiline={true}
            rowsMax={10}
          />] : null}
      </MinCustomDialog>
    );
  }
}

DialogSupport.propTypes = {
  callback: PropTypes.func.isRequired,
  defaultText: PropTypes.object,
};

export default connect(mapStateToProps)(DialogSupport);
