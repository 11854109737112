import api from '../../../../api';

const UPDATE_FIELDS = ['received', 'pending', 'failed', 'send', 'enabled'];

const getGSM = (onResponse, onFail) => {
  api.sms.get('gsm/devices').then(response => {
    onResponse(response.body().map(e => e.data()));
  }).catch(() => onFail());
};

const updateSMS = (sms, types, data) => {
  if (types.includes(data.type)) {
    sms.unshift(data);
    sms.pop();
  }
  return sms;
};

const updateCounters = (list, data) => {
  if (data && data.counters) {
    data.counters.forEach(counter => {
      const occurence = list.findIndex(l => l.number === counter.number);
      if (occurence >= 0) {
        UPDATE_FIELDS.forEach((field) => {
          list[occurence][field] = counter[field];
        });
      }
    });
  }
  return list;
};


const updateCurrentData = (data, list, sms, types) =>
  ({
    sms: updateSMS(sms, types, data),
    list: updateCounters(list, data)
  });

export { getGSM, updateCurrentData };
