import { Phone, AssignmentTurnedIn, List } from '@material-ui/icons/';
export default [
  {
    name: 'SST à recontacter',
    icon: List,
    childs: [
      {
        name: 'Liste de demande',
        icon: List,
        link: '/achat/list/SSTDem',
      },
      {
        name: 'A rappeler',
        icon: Phone,
        link: '/achat/list/SSTRapp',
      },
      {
        name: 'Demande traitée',
        icon: AssignmentTurnedIn,
        link: '/achat/list/SSTDemT'
      },
    ]
  },
  {
    name: 'Suivi d\'avoir',
    icon: List,
    childs: [
      {
        name: 'Avoir garantie',
        icon: AssignmentTurnedIn,
        childs: [
          {
            name: 'Liste de demande',
            icon: List,
            link: '/achat/list/SSTDemWarranty',
          },
          {
            name: 'En cours',
            icon: List,
            link: '/achat/list/SSTDemCWarranty'
          },
          {
            name: 'Traitée',
            icon: List,
            link: '/achat/list/SSTDemTWarranty'
          },
        ]
      }
    ]
  },
  {
    name: 'Fournitures',
    icon: List,
    childs: [
      {
        name: 'Liste fournitures',
        icon: List,
        link: '/fournitures/list'
      },
      {
        name: 'Factures',
        icon: List,
        link: '/suppliesInvoice',
      },
      {
        name: 'Annulés',
        icon: List,
        count: true,
        link: () => '/intervention/list/fournCanceled',
      },
    ]
  }
];
