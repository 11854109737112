import React, { Component } from 'react';
import { connect } from 'react-redux';
import api from '../../../api';
import List from '../List';
import InterActions from './InterActions';
import InterMenu from './InterMenu';
import ListRow from '../ListRow';
import PropositionActions from '../Proposition/PropositionActions';
import creditAideActions from '../Recouvrement/creditAideActions';
import { List as listData } from '../../../utils/List/ListeInterventions';
import { compose } from 'recompose';
import { withDisplaying } from '../../../hoc';
import { callBackFunction } from '../../Utils/ListFunctions';
import RibMenu from './RibMenu';
import RecouvrementResolutionsAVerifMenu
from '../Recouvrement/RecouvrementResolutionsAVerifMenu';
import AchatMenu from '../Achat/AchatMenu';
import AchatActions from '../Achat/AchatActions';
import AdemarcherActions from '../Intervention/AdemarcherActions';

const mapStateToProps = ({ users, userId, grandCompte }) => ({
  users,
  user: users.find(u => u._id === userId),
  grandCompte,
});

const menus = {
  default: InterMenu,
  rib: RibMenu,
  RecouvrementResolutionsAVerifMenu: RecouvrementResolutionsAVerifMenu,
  AchatMenu: AchatMenu,
};

const actions = {
  default: InterActions,
  PropositionActions: PropositionActions,
  creditAideActions: creditAideActions,
  AchatActions: AchatActions,
  AdemarcherActions: AdemarcherActions,
};

const functionType = [
  {
    type: 'categorie',
    callback: callBackFunction['categorie'],
  },
  {
    type: 'substatus',
    callback: callBackFunction['paid'],
  },
  {
    type: 'iStatus',
    callback: callBackFunction['iStatus'],
  },
  {
    type: 'equipeAreasClient',
    callback: callBackFunction['equipeAreasClient'],
  },
  {
    type: 'appel',
    callback: callBackFunction['appel'],
    fieldsToReset: ['communcations.duration']
  }
];

class InterList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      display: props.count || 25,
      page: 0,
      list: [],
      ...(props.list || listData({
        user: props.user,
        service: props.service
      }).default.dataFormat),
      functionType: props.grandCompte
        ? functionType.concat([
          {
            type: 'billing.grandCompte',
            callback: (value) => callBackFunction['grandCompte'](
              value,
              props.grandCompte.toJS()
            ),
          },
        ])
        : functionType,
      filter: {},
    };
    this.updateTable = this.updateTable.bind(this);
    this.updateUnique = this.updateUnique.bind(this);
    this.loadSeg = this.loadSeg.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      JSON.stringify(nextProps) !== JSON.stringify(this.props) ||
      JSON.stringify(nextState) !== JSON.stringify(this.state)
    );
  }

  componentDidMount() {
    this.load(this.props.filter);
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    let newState = {};
    if (nextProps.users !== this.props.users) {
      let tableData = this.state.tableData;
      tableData[1].list = nextProps.users;

      newState = {
        ...newState,
        tableData,
      };
    }
    if (
      JSON.stringify(nextProps.filter) !== JSON.stringify(this.props.filter)
    ) {
      newState = {
        ...newState,
        ...(await this.updateTableData(
          {
            page: this.state.page,
            display: this.state.display,
            filter:
              nextProps.filter && Object.keys(nextProps.filter).length
                ? nextProps.filter
                : {},
            sort: nextProps.sort || this.state.sort,
          },
          true
        )),
      };
    }
    if (nextProps.count !== this.props.count) {
      newState = {
        ...newState,
        page: 0,
        display: nextProps.count,
      };
    }
    if (JSON.stringify(nextProps.sort) !== JSON.stringify(this.props.sort)) {
      newState = {
        ...newState,
        ...(await this.updateTableData(
          {
            page: this.state.page,
            display: this.state.display,
            filter: this.state.filter,
            sort: nextProps.sort,
          },
          true
        )),
      };
    }
    if (nextProps.grandCompte !== this.props.grandCompte) {
      newState.functionType = this.state.functionType.concat([
        {
          type: 'billing.grandCompte',
          callback: (value) =>
            callBackFunction['grandCompte'](
              value,
              nextProps.grandCompte.toJS()
            ),
        },
      ]);
    }
    if (JSON.stringify(nextProps.list) !== JSON.stringify(this.props.list)) {
      newState = {
        ...newState,
        ...nextProps.list,
      };
    }
    if (Object.keys(newState).length !== 0) {
      this.setState({
        ...newState,
      });
    }
  }

  async loadSeg(display, page, filter, sort) {
    const res = await api.interventions.getAll({
      method: 'seg',
      display: display,
      page: page,
      query: JSON.stringify(filter),
      sort: sort || { id: -1 },
    });
    return res.statusCode() === 200
      ? res.body().map(e => {
        const data = e.data();
        return data;
      })
      : [];
  }

  async updateTableData({ display, page, filter, sort }, reloadC) {
    let count = this.state.count;
    let tableData = JSON.parse(JSON.stringify(this.state.tableData));
    let list = await this.loadSeg(
      display,
      page,
      filter ? filter : this.state.filter,
      sort
    );
    if (reloadC) {
      count = await this.loadCount(filter ? filter : this.state.filter);
    }
    list = !list.length ? null : list;
    return {
      display,
      page,
      list,
      filter,
      count,
      tableData,
    };
  }

  async updateTable(display, page, filter, reloadC) {
    this.setState({
      ...(await this.updateTableData(
        { display, page, filter, sort: this.props.sort },
        reloadC
      )),
    });
  }

  async updateUnique(id) {
    try {
      const element = await api.interventions
        .get(id, {
          query: JSON.stringify(this.props.filter),
        })
        .then((e) => e.body().data());
      let list = this.state.list;
      const i = this.state.list.findIndex((elem) => elem.id === element.id);
      if (i > -1) {
        list = list.map((elem, index) => (index !== i ? elem : element));
        this.setState({ list });
      }
    } catch (e) {
      this.load(this.state.filter);
    }
  }

  async loadCount(filter) {
    const res = await api.interventions.get('', {
      call: 'countDocuments',
      query: JSON.stringify(filter),
    });
    return res.statusCode() === 200 ? res.body().data() : 0;
  }

  async load(filter) {
    let update = {};
    update.list = await this.loadSeg(
      this.state.display,
      this.state.page,
      filter ? filter : {},
      this.props.sort
    );
    update.count = await this.loadCount(filter ? filter : {});
    update.filter = filter;
    update.list = !update.list.length ? null : update.list;
    this.setState(update);
  }

  render() {
    const {
      name,
      listen,
      small,
      handlerFilterByColumns,
      action,
      menu,
      filteredByColumn = true,
      sort,
      title
    } = this.props;
    return (
      <React.Fragment>
        <List
          filteredByColumn={filteredByColumn}
          handlerFilterByColumns={handlerFilterByColumns}
          small={small || false}
          name={name}
          actions={actions[action || 'default']}
          menu={menus[menu || 'default']}
          row={ListRow}
          listen={listen}
          updateUnique={this.updateUnique}
          updateTable={this.updateTable}
          parent={this.state}
          sort={sort}
          title={title}
        />
      </React.Fragment>
    );
  }
}

InterList.displayName = 'InterList';

export default compose(connect(mapStateToProps), withDisplaying())(InterList);
