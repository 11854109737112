import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const styles = {
  align: {
    textAlign: 'center'
  }
};


function getSorting(order, orderBy) {
  return order === 'desc'
    ? (a, b) => (b[orderBy] < a[orderBy] ? -1 : 1)
    : (a, b) => (a[orderBy] < b[orderBy] ? -1 : 1);
}

class StatsTableBodyTeam extends PureComponent {

  render() {
    const { data, order, orderBy, page, rowsPerPage, classes } = this.props;
    return (
      <TableBody>
        {data
          .sort(getSorting(order, orderBy))
          .slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage)
          .map((n, i) => (
            <TableRow tabIndex={-1} key={i}>
              <TableCell
                className={classes.align}
                padding="checkbox"
                component="th"
                scope="row"
              >
                {n.login}
              </TableCell>
              <TableCell
                className={classes.align}
                padding="checkbox"
                align={'right'}
              >
                {n.total}
              </TableCell>
              <TableCell className={classes.align}
                padding="checkbox" align={'right'}>{n.I_APR}</TableCell>
              <TableCell className={classes.align}
                padding="checkbox" align={'right'}>{n.I_ENC}</TableCell>
              <TableCell className={classes.align}
                padding="checkbox" align={'right'}>{n.I_AVR}</TableCell>
              <TableCell className={classes.align}
                padding="checkbox" align={'right'}>{n.I_VRF}</TableCell>
              <TableCell
                className={classes.align}
                padding="checkbox"
                align={'right'}
              >
                {n.CBRegle}%
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    );
  }
}

StatsTableBodyTeam.propTypes = {
  rowsPerPage: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
};


export default withStyles(styles)(StatsTableBodyTeam);
