import React, { PureComponent } from 'react';
import 'moment/locale/fr';
import { moment } from '@bit/dev-lba.lib.local-globals/moment';

import Grid from '@material-ui/core/Grid';

import { permit } from '@bit/dev-lba.lib.local-globals/restrictions';

import DatePicker from '../DatePicker';
import TimePicker from '../TimePicker';
import CustomCheckbox from '../CustomInputs/CustomCheckbox';

import { getTime } from '../../utils/date';
import { withDisplaying } from '../../hoc';
import { checkDailyBlocked } from '../../actions/verifications';
import {
  S_INT,
  S_DEVIS,
  S_LEAD,
} from '@bit/dev-lba.lib.local-globals/services';
import {
  NOT_CONFIRMED,
  SENIOR,
} from '@bit/dev-lba.lib.local-globals/permissions';

const path = {
  date: ['date', 'intervention'],
  dateSAV: ['date', 'savDate'],
  maxHour: ['infoDesc', 'maxHour'],
  modifDate: ['modifDate'],
};

const addTime = (date) => moment(date.getTime()).add(2, 'hours').toDate();

class DateSelector extends PureComponent {
  setTime = (e) => {
    const { setData, absence, type, isSAV, date, maxHour } = this.props;
    const numberPart = e.target.value.split(':');
    const newDate = this.props[e.target.name]
      ? new Date(this.props[e.target.name])
      : new Date(this.props.date);
    newDate.setHours(
      Number.parseInt(numberPart[0], 10),
      Number.parseInt(numberPart[1], 10),
      0,
      0
    );
    if (absence) {
      return setData(newDate, type);
    } else if (e.target.name === 'date') {
      setData(path[isSAV ? 'dateSAV' : 'date'], newDate);
      if (!isSAV && maxHour) {
        setData(
          path.maxHour,
          moment(maxHour)
            .set({
              year: newDate.getFullYear(),
              month: newDate.getMonth(),
              date: newDate.getDate(),
            })
            .toDate()
        );
      }
    } else if (e.target.name === 'maxHour') {
      setData(
        path.maxHour,
        moment(date)
          .set({
            hour: newDate.getHours(),
            minute: newDate.getMinutes(),
          })
          .toDate()
      );
    }
  };

  setDate = (m) => {
    const { date, setData, absence, type, modifDate, isSAV, maxHour } =
      this.props;
    const newDate = new Date(date);
    newDate.setFullYear(m.year(), m.month(), m.date());
    if (absence) {
      return setData(newDate, type);
    }
    if (!isSAV) {
      setData(path.modifDate, modifDate + 1);
    }
    setData(path[isSAV ? 'dateSAV' : 'date'], newDate);
    if (maxHour) {
      const newMaxDate = moment(maxHour).set({
        year: newDate.getFullYear(),
        month: newDate.getMonth(),
        date: newDate.getDate(),
      });
      return setData(path.maxHour, newMaxDate.toDate());
    }
  };

  setData = (p, v) => {
    const {
      setData,
      flexDate,
      flexHour,
      paths,
      definHClient,
      definDClient,
      date = new Date(),
    } = this.props;
    if (
      ((p[0] === 'definHClient' && flexHour) ||
        (p[0] === 'flexHour' && definHClient)) &&
      v
    ) {
      setData(flexHour ? paths.flexHour : paths.definHClient, false);
    }
    if (
      ((path[0] === 'definDClient' && flexDate) ||
        (path[0] === 'flexDate' && definDClient)) &&
      v
    ) {
      setData(flexDate ? paths.flexDate : paths.definDClient, false);
    }
    if (p[0] === 'flexHour' && v) {
      const maxHour = this.props.maxHour || new Date();
      setData(
        path.maxHour,
        moment(maxHour)
          .set({
            year: date.getFullYear(),
            month: date.getMonth(),
            date: date.getDate(),
            hour: date.getHours() + 2,
            minute: Math.trunc(date.getMinutes() / 15) * 15,
          })
          .toDate()
      );
    }
    if (p[0] === 'definHClient' && v) {
      setData(
        path.date,
        moment(date)
          .set({
            hour: 17,
            minute: 0,
            second: 0,
          })
          .toDate()
      );
    }
    return setData(p, v);
  };

  setInterventionDate = (date) => {
    const { setData, artisanBlocked, status } = this.props;
    if (checkDailyBlocked(status, artisanBlocked)) {
      setData(['artisan'], null);
    }
    return this.setDate(date);
  };

  render() {
    const {
      dateLabel,
      absence,
      modifDate,
      user,
      flexHour,
      flexDate,
      definHClient,
      definDClient,
      paths,
      lg,
      xs,
      md,
      isSAV,
      date,
      maxHour,
      restrictions,
    } = this.props;
    const hour = getTime(new Date(date));
    const hour2 = getTime(
      (!isSAV && maxHour && new Date(maxHour)) || addTime(date)
    );
    return [
      absence ? (
        <Grid key="time" item xs={xs || 6} sm={6} lg={lg || 3}>
          <TimePicker
            restrictions={restrictions}
            label={'Heure d\'absence'}
            name="date"
            value={hour}
            onChange={this.setTime}
            size="100%"
          />
        </Grid>
      ) : (
        [
          <Grid
            key="time"
            item
            xs={xs || 12}
            sm={6}
            md={md}
            lg={lg || 3}
            style={{ display: 'flex' }}
          >
            <TimePicker
              restrictions={restrictions}
              label={'Heure d\'intervention'}
              name="date"
              value={hour}
              onChange={this.setTime}
              labelRemoveSpace={true}
              size="100%"
              disabled={definHClient}
            />
            {flexHour && !isSAV && (
              <TimePicker
                restrictions={restrictions}
                label={'  '}
                name="maxHour"
                value={hour2}
                onChange={this.setTime}
                size="90%"
                marginLeft="auto"
              />
            )}
          </Grid>,
          !isSAV ? (
            <Grid key="time2" container item sm={6} lg={3}>
              <CustomCheckbox
                grid
                xs={12}
                restrictions={restrictions}
                label="Horaire flexible +/- 2h"
                checked={flexHour}
                path={paths.flexHour}
                setData={this.setData}
              />
              <CustomCheckbox
                grid
                xs={12}
                restrictions={restrictions}
                label="A définir avec le client"
                disabled={!permit(user, { key: 'modifyDateADefinir' })}
                checked={definHClient}
                path={paths.definHClient}
                setData={this.setData}
              />
            </Grid>
          ) : (
            ''
          ),
        ]
      ),
      <Grid key="time3" item xs={xs || 6} sm={6} md={md} lg={lg || 3}>
        <DatePicker
          restrictions={restrictions}
          label={dateLabel}
          before
          disabled={
            user &&
            [S_INT, S_DEVIS, S_LEAD].includes(user.service) &&
            !permit(user, { key: 'modifyDateSelector' }) &&
            ((user.permission <= NOT_CONFIRMED &&
              user.permission > SENIOR &&
              [S_INT, S_LEAD].includes(user.service) &&
              modifDate > 1) ||
              (user.permission <= SENIOR &&
                [S_INT, S_LEAD].includes(user.service) &&
                modifDate > 2) ||
              (user.service === S_DEVIS && modifDate > 2))
          }
          startDate={date}
          onChange={this.setInterventionDate}
        />
      </Grid>,
      permit(user, { key: 'modifyDateADefinir' }) && !absence && !isSAV ? (
        <Grid key="time4" container item sm={6} lg={3}>
          <CustomCheckbox
            grid
            xs={12}
            restrictions={restrictions}
            label="Date flexible +/- 1j"
            checked={flexDate}
            path={paths.flexDate}
            setData={this.setData}
          />
          <CustomCheckbox
            key="defH"
            grid
            xs={12}
            label="A définir avec le client"
            disabled={!permit(user, { key: 'modifyDateADefinir' })}
            restrictions={restrictions}
            checked={definDClient}
            path={paths.definDClient}
            setData={this.setData}
          />
        </Grid>
      ) : (
        ''
      ),
    ];
  }
}

DateSelector.displayName = 'DateSelector';

export default withDisplaying()(DateSelector);
