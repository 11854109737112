import React, { Component } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
} from '@material-ui/core';
import api from '../../../../api';
import notifSystem from '../../../../notifSystem';
import { S_PART, S_INT, S_LEAD } from '@bit/dev-lba.lib.local-globals/services';

const fields = [
  {
    services: [S_INT, S_LEAD],
    name: 'Jour',
    key: 'dayGoal',
  },
  {
    services: [S_INT, S_LEAD],
    name: 'Semaine',
    key: 'weekGoal',
  },
  {
    services: [S_INT, S_LEAD],
    name: 'Mois',
    key: 'monthGoal',
  },
  {
    services: [S_INT, S_LEAD],
    name: 'Soir',
    key: 'nightGoal',
  },
  {
    services: [S_INT, S_LEAD],
    name: 'Paiement CB',
    key: 'paymentCBGoal',
  },
  {
    services: [S_PART],
    name: 'Objectif d\'ajout par jour',
    key: (user) => `ajoutArtisan_day_${user._id}`,
  },
  {
    services: [S_PART],
    name: 'Objectif d\'ajout par semaine',
    key: (user) => `ajoutArtisan_week_${user._id}`,
  },
  {
    services: [S_PART],
    name: 'Objectif d\'ajout par mois',
    key: (user) => `ajoutArtisan_month_${user._id}`,
  },
];

export default class extends Component {
  state = {};

  handleChange = (name) => (event) => {
    this.setState({
      [name]: Number(event.target.value),
    });
  };

  static getDerivedStateFromProps(props, state) {
    if (state.prevGoals !== props.goals) {
      return {
        ...props.goals,
        prevGoals: props.goals,
      };
    }
    return null;
  }

  validateObjectif = () => {
    const { handleOpen, dataKey, user } = this.props;
    api.stats
      .custom('commercial/changeObjectif')
      .post(
        fields.reduce((acc, curr) => {
          const key =
            typeof curr.key === 'function' ? curr.key(user) : curr.key;
          const field = key.split('_').slice(0, 2).join('_');
          return curr.services.includes(user.service)
            ? { ...acc, [`${dataKey}.${key}`]: this.state[field] }
            : acc;
        }, {})
      )
      .then(() => {
        notifSystem.success(
          'Succès',
          'Vos paramètres d\'objectifs ont bien été mis à jours'
        );
        handleOpen(false, true);
      })
      .catch(() => notifSystem.error('Echec', 'Une erreur s\'est produite'));
  };

  render() {
    const { open, handleOpen, user } = this.props;

    return (
      <Dialog open={open} onClose={() => handleOpen(false)}>
        <DialogTitle>Objectifs</DialogTitle>
        <DialogContent>
          {fields
            .filter(e => e.services.includes(user.service))
            .map(({ name, key }, i) => {
              const newKey = typeof key === 'function' ? key(user) : key;
              return (
                <TextField
                  key={i}
                  autoFocus
                  margin="dense"
                  type="number"
                  label={name}
                  value={
                    this.state[newKey.split('_').slice(0, 2).join('_')] || 0
                  }
                  onChange={
                    this.handleChange(newKey.split('_').slice(0, 2).join('_'))
                  }
                  fullWidth
                />
              );
            })}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleOpen(false)}>ANNULER</Button>
          <Button onClick={this.validateObjectif} color="primary">
            VALIDER
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
