import React, { Component } from 'react';
import {
  Grid,
  DialogTitle,
  TextField,
  Select,
  Checkbox,
  FormControlLabel,
  InputLabel,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import { setDialogContentProp } from '../../actions/dialog';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import { data as servicesData } from '@bit/dev-lba.lib.local-globals/services';
import userPermissions from '@bit/dev-lba.lib.local-globals/userPermissions';
import api from '../../api';
import AutoCompleteSearchBar from '../Utils/AutoCompleteSearchBar';

const makeid = (length) => {
  let result = '';
  let characters = 'abcdefghijklmnopqrstuvwxyz';
  let charactersLength = characters.length;
  for ( let i = 0; i < length; i++ ) {
    result += characters.charAt(Math.floor(Math.random() *
 charactersLength));
  }
  return result;
};

const styles = {
  dialogTitle: {
    padding: '0 0 16px',
    width: '100%'
  }
};

const selects = {
  service: servicesData,
  permission: userPermissions.data,
};

const setUser = setDialogContentProp('newUser');

const mapDispatchToProps = {
  setUser,
};

const mapStateToProps = ({
  dialog: {
    contentProps: { newUser, getSchedule },
  },
}) => ({
  newUser,
  getSchedule,
});

class AddNewUser extends Component {
  state = { ringoverUsers: [] };

  componentDidMount() {
    api.users
      .custom('ringoverUsers')
      .get()
      .then((res) => res.body().map((e) => e.data()))
      .then((ringoverUsers) => {
        this.setState({ ringoverUsers });
      });
  }

  handleChange(value) {
    if (value.login) {
      value.login = value.login.toLowerCase();
    }
    this.props.setUser({
      ...this.props.newUser,
      ...value,
    });
  }

  onValidate(user) {
    this.props.setUser({
      ...this.props.newUser,
      login: `${user.firstname?.toLowerCase()}_${makeid(1)}`,
      email: user.email,
      ligne: String(user.numbers[0]?.number).replace('33', '0'),
      multiLignes: user.numbers.map((e) => String(e.number).replace('33', '0')),
      alias: user.firstname,
      telephoneId: `USER${user.user_id}`,
      service:
        servicesData.find((e) => new RegExp(e.name, 'i').test(user.lastname))
          ?._id || this.props.newUser.service,
    });
  }

  render() {
    const { classes, newUser, getSchedule } = this.props;
    const { ringoverUsers } = this.state;
    const filter = createFilterOptions();
    const lignes = newUser['multiLignes'];
    return (
      <>
        <DialogTitle className={classes.dialogTitle}>
          <Grid container spacing={2} alignItems="flex-start">
            <Grid item xs={6} children="Ajouter un nouvel utilisateur" />
            <Grid item xs={6}>
              <AutoCompleteSearchBar
                placeholder="Importer depuis RINGOVER ..."
                defaultValue={''}
                getSuggestions={(value) =>
                  new Promise((r) =>
                    r(
                      value.length > 3
                        ? ringoverUsers.filter(
                          (s) =>
                            new RegExp(value, 'i').test(s.firstname) ||
                              new RegExp(value, 'i').test(s.lastname) ||
                              new RegExp(value, 'i').test(s.email) ||
                              (s.numbers || []).some((n) =>
                                new RegExp(value, 'i').test(n.number)
                              )
                        )
                        : ''
                    )
                  )
                }
                displaySuggestion={(suggestion) =>
                  `${suggestion.firstname} ${suggestion.numbers
                    ?.map((e) => e.number)
                    .join('/')}`
                }
                onValidate={(importedUser) => this.onValidate(importedUser)}
              />
            </Grid>
          </Grid>
        </DialogTitle>
        <Grid container spacing={4} justifyContent="center" alignItems="center">
          <Grid item xs={3}>
            <InputLabel>Service</InputLabel>
            <Select
              value={newUser.service}
              style={{ marginTop: '1rem', width: 150 }}
              onChange={(e) =>
                this.handleChange({
                  service: e.target.value,
                  schedule: getSchedule(e.target.value),
                })
              }
            >
              {selects['service'].map((type, selectId) => (
                <MenuItem key={selectId} value={type && type._id}>
                  {type.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={3}>
            <InputLabel>Permission</InputLabel>
            <Select
              value={newUser.permission}
              style={{ marginTop: '1rem', width: 150 }}
              onChange={(e) =>
                this.handleChange({ permission: e.target.value })
              }
            >
              {selects['permission'].map((type, selectId) => (
                <MenuItem key={selectId} value={type && type._id}>
                  {type.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={3}>
            <FormControlLabel
              value={newUser.authorizeDistantConnection}
              style={{ marginTop: '1.5rem' }}
              control={
                <Checkbox
                  color="primary"
                  checked={newUser.authorizeDistantConnection}
                  onChange={(e, value) =>
                    this.handleChange({ authorizeDistantConnection: value })
                  }
                />
              }
              label="À distance"
            />
          </Grid>
        </Grid>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={3}>
            <TextField
              fullWidth
              value={newUser.login || ''}
              label="Login"
              placeholder="Entrer une valeur"
              onChange={(e) => this.handleChange({ login: e.target.value })}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth
              value={newUser.email || ''}
              label="Email"
              placeholder="Entrer une valeur"
              onChange={(e) => this.handleChange({ email: e.target.value })}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth
              value={newUser.alias || ''}
              label="Alias"
              placeholder="Entrer une valeur"
              onChange={(e) => this.handleChange({ alias: e.target.value })}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth
              label="Max Vérif"
              value={newUser.maxV || 0}
              type="number"
              placeholder="Entrer une valeur"
              onChange={(e) =>
                this.handleChange({
                  maxV: Number(e.target.value),
                })
              }
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth
              label="Ligne Service"
              value={newUser.ligneService || ''}
              type="number"
              placeholder="Entrer une valeur"
              onChange={(e) =>
                this.handleChange({ ligneService: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth
              label="Ligne"
              value={newUser.ligne || ''}
              type="number"
              placeholder="Entrer une valeur"
              onChange={(e) => this.handleChange({ ligne: e.target.value })}
            />
          </Grid>
          <Grid item xs={3}>
            <Autocomplete
              style={{ width: 200 }}
              disableClearable
              freeSolo
              multiple
              limitTags={2}
              id="multiple-limit-tags"
              value={lignes}
              onChange={(event, newValue) => {
                let multiLignes;
                const types = {
                  Tevent: typeof event.target.value,
                  Tlast: typeof newValue[newValue.length - 1],
                };
                multiLignes = types.Tlast === 'string' ? newValue : multiLignes;
                multiLignes = newValue[newValue.length - 1]?.inputValue
                  ? newValue.map((option) => {
                    if (typeof option === 'string') {
                      return option;
                    }
                    return option.inputValue
                      ? option.inputValue
                      : option.title;
                  })
                  : multiLignes;
                return multiLignes && this.handleChange({ multiLignes });
              }}
              options={lignes.map((e) => ({ title: e }))}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                if (params.inputValue !== '') {
                  filtered.push({
                    inputValue: params.inputValue,
                    title: `Ajouter "${params.inputValue}"`,
                  });
                }
                return filtered;
              }}
              getOptionLabel={(option) => {
                if (typeof option === 'string') {
                  return option;
                }
                return option.inputValue ? option.inputValue : option.title;
              }}
              renderOption={(option) => option.title}
              renderInput={(params) => (
                <TextField {...params} type="number" label="Multilignes" />
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth
              label="Ligne Id"
              value={newUser.telephoneId || ''}
              placeholder="Entrer une valeur"
              onChange={(e) =>
                this.handleChange({ telephoneId: e.target.value })
              }
            />
          </Grid>
        </Grid>
      </>
    );
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(AddNewUser);
