import React, { PureComponent, Suspense } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Route,
  Switch
} from 'react-router-dom';
import { permit } from '@bit/dev-lba.lib.local-globals/restrictions';
import SuspenseLoading from './SuspenseLoading';
import config from '../../config';

const routes = (user) =>
  config.router.map(({ path, component: Component, restrictions }, i) =>
    user && permit(user, { custom: restrictions }) ? (
      <Route
        key={i}
        path={path}
        component={(props, key) => (
          <Suspense key={`Suspense-${key}`} fallback={<SuspenseLoading />}>
            <Component {...props} />
          </Suspense>
        )}
      />
    ) : null
  );

const styles = {
  body: {
    width: '100%',
    height: '100%',
    padding: 16,
    paddingBottom: 0,
    boxSizing: 'border-box',
    margin: 'auto',
  },
};

export class InternalRouter extends PureComponent {
  render() {
    const { classes, user } = this.props;
    return (
      <div className={classes.body}>
        <Switch>
          {routes(user)}
        </Switch>
      </div>
    );
  }
}

export default withStyles(styles)(InternalRouter);
