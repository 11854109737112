import React, { Component } from 'react';
import { moment } from '@bit/dev-lba.lib.local-globals/moment';
import ReactEcharts from 'echarts-for-react';
import { getOption } from './options/decoteOptions';
import { getLogin } from '../../../../utils/function';
import api from '../../../../api';
import notifSystem from '../../../../notifSystem';

class DecoteGraph extends Component {
  state = {
    data: {},
  }

  componentDidMount() {
    this.doRequest();
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props) !== JSON.stringify(prevProps)) {
      this.doRequest();
    }
  }

  doRequest = () => {
    const { user, from, to, display } = this.props;
    return api.stats
      .get('rh/decote', { user, from, to, display })
      .then(result => this.setState(() => ({ ...result.body().data() })))
      .catch(() => notifSystem.error('Echec', 'Une erreur s\'est produite'));
  }
  render() {
    const { mode, from, to, user, colors, disposition,
      customOption = (data) => getOption({
        data, mode, disposition, colors: colors,
        labels: Object.keys(data), dates: [moment(from), moment(to)],
        title: getLogin(user),
      })
    } = this.props;
    const { data } = this.state;

    return (
      <React.Fragment>
        {data && <ReactEcharts
          notMerge
          lazyUpdate
          option={customOption(data, user)}/>}
      </React.Fragment>
    );
  }


}
export default DecoteGraph;
