import notifSystem from '../notifSystem';
import {
  AS
} from '@bit/dev-lba.lib.local-globals/categories';
import {
  R_FOR
} from '@bit/dev-lba.lib.local-globals/remStatus';
import {
  I_DVG,
  I_APR,
  I_VRF,
} from '@bit/dev-lba.lib.local-globals/iStatus';
import { D_NOT } from '@bit/dev-lba.lib.local-globals/deStatus';
import { checkInfosClient } from '../utils/checkInputs';
import { List, Map } from 'immutable';
import { AS_INDSP } from '@bit/dev-lba.lib.local-globals/aSubStatus';
import { NOT_QUA } from '@bit/dev-lba.lib.local-globals/aLevel';
import {
  sendInterestSMS,
} from './inter';
import { openCaldeo } from './caldeo';
import { PROPRIETAIRE } from '@bit/dev-lba.lib.local-globals/statusClient';
import { permit } from '@bit/dev-lba.lib.local-globals/restrictions';
import { calculateAllPrices } from '@bit/dev-lba.lib.local-globals/formatPrice';
import { moment } from '@bit/dev-lba.lib.local-globals/moment';
import { TEL_REGEX } from '../utils/regexps';
import api from '../api';

const siganlVerification = 'Ne souhaite plus travailler avec nous';

export const sendVerification = (merge, user) => {
  const priceArtisan = merge.get('priceArtisan');
  const remStatus = merge.getIn(['remunerationArtisan', 'status']);
  if (!remStatus) {
    return notifSystem.warning(
      'Veuillez attendre le chargement',
      'de la liste d\'artisan'
    );
  }
  if (
    merge.get('categorie') !== AS &&
    merge.get('status') !== I_DVG &&
    remStatus === R_FOR
  ) {
    if (!priceArtisan) {
      return notifSystem.error(
        'Informations Intervention',
        'Veuillez indiquer le prix validé par l\'artisan'
      );
    }
    const price = merge.get('finalPrice', merge.get('announcedPrice'), 0);
    const percent = merge.get('supplies', new List()).size ? 60 : 50;
    const checkPrice = price * (percent / 100);
    if (priceArtisan >= checkPrice &&
      !permit(user, { key: 'resendInstructions' })) {
      return notifSystem.error(
        'Informations Intervention',
        `Le prix artisan ne doit pas excéder ${percent}% du prix final.`
      );
    }
  }
  if (merge.getIn(['block', 'send'])) {
    return notifSystem.error(
      'Informations Intervention',
      'L\'envoi de cette intervention a été bloqué par le support'
    );
  }
  return false;
};

export const checkInfosFacturation = checkInfosClient(
  [
    'name',
    'tel1',
    'email1',
    {
      key: 'address',
      keys: ['city', 'number', 'road', 'zipcode'],
    },
  ],
  [
    'Nom',
    'Téléphone 1',
    'Email 1',
    ['Ville', 'Numéro', 'Adresse', 'Code postal'],
  ]
);

export function checkProducts(data) {
  if (!(data.products
    && data.products.list
    && data.products.list.length)) {
    notifSystem.error('Produits', 'Veuillez ajouter au minimum un produit');
    return false;
  }
  return true;
}

export function checkInfosFacturations(data) {
  const errors = checkInfosFacturation(data.billing);
  if (errors.length) {
    notifSystem.error('Coordonnées de facturation',
      `${errors.join(', ')} incomplets`);
    return false;
  }
  return true;
}

export function checkPrice(price) {
  if (price < 4000) {
    notifSystem.error('Erreur', `La facture ne peut être envoyée
    car le montant est inférieur à 40 € Ht`);
    return false;
  }
  return true;
}

export function checkAwaitingBillRequirements(data, price = data.finalPrice) {
  return (data.onSitePayment || checkInfosFacturations(data))
    && checkProducts(data) && checkPrice(price);
}

export function checkPaymentMethod(inter) {
  if (inter.onSitePayment === true &&
    (inter.status !== I_DVG && !~inter.paymentMethod)) {
    notifSystem.error('Erreur', 'Veuillez sélectionner un mode de réglement.');
    return false;
  }
  return true;
}

export const checkFactIsSent = (merge) => {
  if (
    merge.get('status') === I_VRF &&
    !merge.get('onSitePayment') &&
    !(
      checkAwaitingBillRequirements(merge.toJS()) &&
      merge.hasIn(['date', 'envoiFacture'])
    )
  ) {
    notifSystem.error('Erreur', 'Veuillez envoyer la facture');
    return false;
  }
  return true;
};

export const removeGarbageProperties = (state = new Map()) =>
  state.withMutations(mutableState =>
    mutableState.update('products', new Map(), e =>
      e.withMutations(e =>
        e
          .update('list', new List(), l => l.map(p => p.delete('id')))
          .delete('index')
      )
    )
  );

export const addGarbageProperties = (state = new Map()) =>
  state.withMutations(mutableState =>
    mutableState.update('products', new Map(), e =>
      e.withMutations(e =>
        e
          .update('list', new List(), l => l.map((p, id) => p.set('id', id)))
          .set('index', e.get('list', new List()).size)
          .set('tva', state.getIn(['products', 'tva'], 10))
      )
    )
  );
export const interestSMSCond = (inter, artisan, oldArtisan, user) => {
  if (
    inter &&
    inter.status === I_APR &&
    inter.artisan &&
    inter.artisan !== oldArtisan &&
    inter.categorie !== AS &&
    (!artisan.quarantaineSignal ||
      (artisan.quarantaineSignal !== siganlVerification &&
        artisan.level !== NOT_QUA)) &&
    artisan.subStatus !== AS_INDSP &&
    !artisan.security.interDailyMax.blocked
  ) {
    sendInterestSMS(user, inter);
  }
};

export const checkDailyBlocked = (status, isBlocked) => {
  if ((!status || status === I_APR) && isBlocked) {
    notifSystem.error('Erreur', 'L\'artisan a été désélectionné.');
    return true;
  }
  return false;
};

export const checkNotation = (
  {
    onSitePayment: currentonSitePayment = null,
    notation,
    billing = {},
    date = {},
  },
  { onSitePayment, isRegle, remainCaldeo, billing: billingOrginal }
) => {
  onSitePayment =
    currentonSitePayment !== null ? currentonSitePayment : onSitePayment;
  const conditionNotation = notation
    && !notation.hasNotAnswered
    && ((notation.client
      && notation.client.note > 1
      && !notation.client.commentaire)
      || (notation.artisan
        && notation.artisan.note > 1
        && !notation.artisan.commentaire));

  const conditionBilling =
    (notation && !notation.hasNotAnswered &&
      billing.noContact && !date.billingContact)
    || isRegle
    || onSitePayment
    || remainCaldeo
    || billing.noContact
    || date.billingContact
    || (billingOrginal && billingOrginal.grandCompte)
    || (billingOrginal && billingOrginal.ratingEnable);
  if (!notation || (!conditionNotation && conditionBilling)) {
    return true;
  }
  notifSystem.error(
    'Erreur',
    !conditionBilling
      ? 'Merci de contacter le payeur ou d\'ajouter une date'
      : 'Commentaire obligatoire pour la notation'
  );
  return false;
};

export const checkCaldeo = (merge, type, cond = {}) =>
  merge.getIn(['combination', 'enableCaldeo']) &&
  merge.getIn(['client', 'civility']) !== 'Société' &&
  (merge.get('onSitePayment') ||
  merge.getIn(['billing', 'clientStatus']) === PROPRIETAIRE ||
  !merge.getIn(['billing', 'clientStatus'])) &&
  (cond.showButton || !merge.get('noHelp')) &&
  (type === 'devis' || (type === 'interventions' &&
    merge.getIn(['createdFrom', 'collection']) === 'devis'));

export const checkAndOpenCaldeo = (merge, props) => {
  if (merge.get('isCaldeo', false) &&
  (!merge.getIn(['combination', 'oldChaudiere']) ||
  !merge.hasIn(['login', 'fillCaldeo']))) {
    merge.set('ceeAmount', null);
    return openCaldeo(merge, props);
  }
  return props.cb();
};

export const checkIsMobileNumber = (data) => {
  const tels = [1, 2, 3].map(e => data.getIn(['client', `tel${e}`]));
  if (tels.find(e => TEL_REGEX.test(e))) {
    return true;
  }
  notifSystem.error(
    'Attention',
    'Un des numeros doit commencer par un 06 ou 07.'
  );
};

export const checkAdvance = (merge, newAdvance = 0) => {
  const newAdv = newAdvance / 100;
  const { price } = calculateAllPrices(
    merge.products,
    merge.finalPrice || merge.annoucedPrice || 0
  );
  if (newAdv < 0 || newAdv > price) {
    notifSystem.error('Erreur',
      'L\'acompte est incorrect');
    return false;
  }
  return true;
};

export const checkGeoKey = (data) => {
  if (
    data.client && data.client.address &&
    data.client.address.location &&
    data.client.address.location.coordinates &&
    !data.client.address.location.coordinates.length
  ) {
    notifSystem.error(
      'Adresse Invalide',
      'Veuillez modifier l\'adresse depuis la barre de recherche'
    );
    return false;
  }
  return true;
};

export const verifOnCancel = (inter, user) => {
  const prixFinal = inter.finalPrice;
  const errors = [];
  if (
    !permit(user, { key: 'blockInterPriceZero' }) &&
      inter.date.envoi &&
      +prixFinal <= 0
  ) {
    errors.push('Le prix final HT doit être supérieur à 0');
  }
  if (errors.length > 0) {
    errors.forEach((error) =>
      notifSystem.error('Informations Intervention', error)
    );
    return false;
  }
  return true;
};


export const disableADemarcher = (props) => {
  const { merge, isSAV, original, user } = props;
  const dateToVerify = ['date', isSAV ? 'savDate' : 'intervention'];
  return (merge.hasIn(dateToVerify) &&
    moment(merge.getIn(dateToVerify))
      .isBefore()) ||
    (!isSAV && ![I_APR].includes(
      merge.get('status'))) ||
    (
      original.get('aDemarcher') !== D_NOT &&
      !permit(user, { key: 'chefPart' })
    );
};

export const codeAddressCheck = async (original, current, cb) => {
  try {
    const originalAddr = original.getIn(['client', 'address']);
    const currentAddr = current.getIn(['client', 'address']);
    let address =
       `${(currentAddr && currentAddr.get('number')) ||
         (originalAddr && originalAddr.get('number'))} ` +
       `${(currentAddr && currentAddr.get('road')) ||
         (originalAddr && originalAddr.get('road'))}, ` +
       `${(currentAddr && currentAddr.get('zipcode')) ||
         (originalAddr && originalAddr.get('zipcode'))} ` +
       `${(currentAddr && currentAddr.get('city')) ||
         (originalAddr && originalAddr.get('city'))}`;

    const resp = await api.map.custom('getPlaces').get({
      value: JSON.stringify(address),
    });
    const results = resp.body().data().features;
    const res = {
      type: 'Point',
      coordinates: [
        results[0].geometry.coordinates[0],
        results[0].geometry.coordinates[1],
      ],
    };
    current = current.setIn(['client', 'address', 'location'], res);
    return cb(original, current);
  } catch (error) {
    notifSystem.error('Erreur Map', 'Adresse non reconnue');
  }
};

export const displayVerifButtonOnMAJ = ({ user, selected = {} }) => {
  if (
    (selected.login || {}).demandeUpdate &&
    selected.date.demandeUpdate &&
    selected.proposition
  ) {
    const diffHours = moment().diff(selected.date.demandeUpdate, 'hours');
    return (
      permit(user, { key: 'adminAccess' }) ||
      selected.login.demandeUpdate === user._id ||
      diffHours > 2
    );
  }
  return true;
};

export const checkInfosInterExternal = (merge) => {
  const errors = [];

  if (!merge.infoDesc || !merge.infoDesc.desc) {
    errors.push('Veuillez sélectionner le diagnostic correspondant');
  }
  if (!merge.categorie) {
    errors.push('Veuillez ajouter une catégorie');
  }
  if (errors.length > 0) {
    errors.forEach((error) =>
      notifSystem.error('Informations Intervention', error)
    );
    return false;
  }

  return true;
};
