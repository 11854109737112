import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import {
  Input,
  Button,
  Typography,
  Grid,
  ListItemText,
  TextField
} from '@material-ui/core';
import { indigo } from '@material-ui/core/colors';

import CardItems from './CancelDialog/CardItems';
import McDoStepper from './McDoStepper';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Autocomplete } from '@material-ui/lab';
import { data as aLevel, NOT_QUA } from '@bit/dev-lba.lib.local-globals/aLevel';
import { List } from 'immutable';

const CATEGORY = 1;
const SIGNAL = 2;
const COMMENT = 3;

const styles = {
  input: {
    padding: '16px',
    paddingBottom: 0,
  },
};

const mapStateToProps = ({ signals, userId }) => ({
  signals: signals.filter(e =>
    !e.getIn(['hideFor', 'users'], new List()).includes(userId))
});

const loadSignals = (filter, signals) =>
  signals
    .filter(data => Object.keys(filter).every(key => filter[key] === data[key]))
    .map(data => ({
      name: data.name,
      color: data.level !== NOT_QUA
        ? aLevel.find(e => e._id === data.level).color
        : indigo,
      type: data.type,
      ref: data.ref,
      data,
    }));
const signalSearch = ({ signals, defaultText: { signalType } }, setSignal) => {
  signals = signals ? signals.toJS() : [];
  const filtered = signalType ?
    signals.filter(e => e.type === signalType) : signals;
  const reduced = filtered.reduce((acc, curr) => {
    const childExist = filtered.some(s => curr.id === s.ref);
    if (!childExist) {
      acc = acc.concat(curr);
    }
    return acc;
  }, []);
  return <Autocomplete
    style={{ width: '50%' }}
    options={reduced}
    getOptionLabel={(signal) => signal.name}
    renderOption={(option) => (
      <ListItemText
        variant='caption'
        primary={option.name}
        secondary={option.path}
        onClick={() => setSignal(option)}
      />
    )}
    disableListWrap
    renderInput={(params) => (
      <TextField
        {...params}
        label={'Rechercher un signalement'}
        variant="outlined"
      />)}
  />;
};

class SignalSST extends Component {
  state = {
    value: '',
    data: null,
    signal: null,
    currentStep: CATEGORY,
  };

  componentDidMount() {
    const {
      signals,
      defaultText: { signalType },
    } = this.props;
    let newSignals = signals ? signals.toJS() : [];
    if (signalType) {
      newSignals = newSignals.filter(
        s =>
          !s.ref &&
          loadSignals({ type: signalType }, newSignals)
            .map(e => e.ref)
            .includes(s.id)
      );
    }
    this.setState({
      data: loadSignals({ ref: null }, newSignals),
      step: CATEGORY,
    });
  }

  send = () => {
    const { signal, value } = this.state;
    const comment = value.trim();

    if (comment) {
      this.props.callback({
        _id: signal._id,
        comment,
      });
    }
  };

  close = () => this.props.callback();

  generateDialogProps = step => {
    switch (step) {
      case CATEGORY:
        return {
          title: 'Choisissez une catégorie',
        };
      case SIGNAL:
        return {
          title: 'Choisissez un signalement',
        };
      case COMMENT:
        return {
          title: 'Création du signalement',
          maxWidth: 'sm',
          actions: [<Button onClick={this.send} key={0} children="Valider" />],
        };
    }
  };

  displayInput = () => {
    const { signal } = this.state;
    const { classes } = this.props;

    return (
      <Grid item xs={12}>
        <Typography children={signal.name} />
        <Input
          className={classes.input}
          multiline
          fullWidth
          rows={10}
          onChange={e => this.setState({ value: e.target.value })}
          placeholder="Commentaire"
        />
      </Grid>
    );
  };

  setStep = (signal, setData) => {
    let {
      signals,
      defaultText: { signalType },
    } = this.props;

    signals = signals ? signals.toJS() : [];
    this.setState({ signal }, () => {
      const data = loadSignals(
        { ref: signal.id, ...(signalType ? { type: signalType } : {}) },
        signals
      );
      if (!data.length) {
        this.setState({ currentStep: COMMENT });
        setData(signal, COMMENT);
      } else {
        this.setState({ currentStep: SIGNAL });
        setData(data, SIGNAL);
      }
    });
  };

  generateContent = (currentData, currentStep, setData) => {
    const { data } = this.state;
    switch (currentStep) {
      case CATEGORY:
        return (
          <CardItems
            name="data"
            display="name"
            setData={(d, signal) => this.setStep(signal, setData)}
            array={currentData}
          />
        );
      case SIGNAL:
        return (
          <CardItems
            name="data"
            display="name"
            setData={(d, signal) => this.setStep(signal, setData)}
            array={currentData}
          />
        );
      case COMMENT:
        return this.displayInput();
      default:
        if (data) {
          setData(data, CATEGORY);
        }
        return null;
    }
  };

  onRewind = (history) => {
    const lastElem = history[history.length - 1] || {};
    this.setState({ currentStep: lastElem.step || CATEGORY });
  }

  render() {
    return (
      <McDoStepper
        onClose={this.close}
        generateContent={(...args) => (
          <Grid container spacing={2} justify="center">
            {this.generateContent(...args)}
          </Grid>
        )}
        generateDialogProps={this.generateDialogProps}
        customHeader={(setData) =>
          this.state.currentStep !== COMMENT && signalSearch(
            this.props,
            (signal) => this.setStep(signal, setData)
          )}
        onRewind={this.onRewind}
      />
    );
  }
}

export default compose(connect(mapStateToProps), withStyles(styles))(SignalSST);
