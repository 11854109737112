import React, { PureComponent } from 'react';
import { moment } from '@bit/dev-lba.lib.local-globals/moment';
import classNames from 'classnames';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import ReactEcharts from 'echarts-for-react';

import withWidth from '@material-ui/core/withWidth';
import { Paper, Typography } from '@material-ui/core';
import { blue, blueGrey, green, red } from '@material-ui/core/colors';

import {
  data as categoryData
} from '@bit/dev-lba.lib.local-globals/categories';

import api from '../../../api';
import notifSystem from '../../../notifSystem';
import StatsPanel from '../common/panel/StatsPanel';
import Dropdown from '../common/dropdown/dropdown';
import { getOption } from '../common/graph/options/globalOptions';
import TrustBox from '../TrustBox';
import UserDailyStat from './UserDailyStat';

const styles = (theme) => ({
  paperTrust: {
    padding: theme.spacing(4),
    marginBottom: theme.spacing(2),
    height: 145,
  },
  root: {
    height: '71%',
    paddingTop: 20,
  },
  [theme.breakpoints.down('sm')]: {
    displayNoneRWD: {
      display: 'none',
    },
  },
  progress: {
    margin: theme.spacing(2),
  },
  [theme.breakpoints.up('lg')]: {
    marginRightInput: {
      margin: 8,
    },
    displayInput: {
      marginRight: '1rem',
    },
  },
});

class GraphCATelepro extends PureComponent {
  state = {
    mode: 'day',
    from: moment().startOf('day').toDate(),
    to: moment().endOf('day').toDate(),
    selectedUsers: this.props.user ? [this.props.user._id] : [],
    selectedCategories: categoryData.map((e) => e._id),
  };

  componentDidMount() {
    this.doRequests();
  }

  changeArray = (field, input, key) => {
    let data = [...this.state[field]];
    const toCompare = key ? input[key] : input;

    if (data.includes(toCompare)) {
      data = data.filter((u) => u !== toCompare);
    } else {
      data.push(toCompare);
    }
    this.setState({ [field]: data });
  };

  onDatesChange = (from, to, mode) => {
    this.setState({ from, to, mode }, this.doRequests);
  };

  doRequests = () => {
    const { mode, from, to, selectedUsers, selectedCategories } = this.state;
    api.stats
      .get('rh/CA', {
        mode,
        from,
        to,
        users: JSON.stringify(selectedUsers),
        categories: JSON.stringify(selectedCategories),
      })
      .then((result) => this.setState({ data: result.body().data().data }))
      .catch(() =>
        notifSystem.error('Erreur', 'Impossible de recuperer les statistiques')
      );
  };

  render() {
    const { classes, withTrust, users, user } = this.props;
    let { selectedUsers, selectedCategories, mode, from, to, data } =
      this.state;

    return (
      <React.Fragment>
        <UserDailyStat users={users} user={user} />
        {withTrust && (
          <Paper
            className={classNames(classes.paperTrust, classes.displayNoneRWD)}
          >
            <TrustBox />
          </Paper>
        )}
        <Paper className={classes.root}>
          <Typography variant="h6" align="center">
            Statistiques des commerciaux
          </Typography>
          <StatsPanel
            onDatesChange={this.onDatesChange}
            defaultState={{ mode, from, to }}
            body={
              <React.Fragment>
                <Dropdown
                  title="Categories"
                  data={categoryData}
                  isSelected={(e) => selectedCategories.includes(e)}
                  onChange={(e) =>
                    this.changeArray('selectedCategories', e.target.value[1])
                  }
                />
                {users.length > 1 && (
                  <Dropdown
                    title={
                      selectedUsers.length
                        ? users.find((e) => selectedUsers.includes(e._id)).login
                        : 'Utilisateurs'
                    }
                    multiple={false}
                    data={users}
                    renderKey="login"
                    onChange={(e) =>
                      this.setState(
                        {
                          selectedUsers: [e.target.value],
                        },
                        this.doRequests
                      )
                    }
                  />
                )}
              </React.Fragment>
            }
          >
            {data && (
              <ReactEcharts
                notMerge={true}
                style={{ height: 380 }}
                option={getOption({
                  data,
                  mode,
                  dates: [moment(from), moment(to)],
                  colors: [
                    blueGrey[500],
                    green[500],
                    blue[500],
                    red[500],
                    red[500],
                  ],
                  disposition: ['bar', 'bar', 'bar', 'bar', 'line'],
                })}
              />
            )}
          </StatsPanel>
        </Paper>
      </React.Fragment>
    );
  }
}

export default compose(withStyles(styles), withWidth())(GraphCATelepro);
