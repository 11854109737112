import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import ReactEcharts from 'echarts-for-react';
import { getOption } from '../ChiffreDaffaires/getOptionsInterDevis';

const styles = () => ({
  paper: {
    width: '100%',
    padding: '50px',
    boxSizing: 'border-box'
  },
  stretchDefault: {
    display: 'flex'
  }
});

const InterPerHours = ({ interHoursData, classes }) => (
  <Paper className={classes.paper}>
    {interHoursData ? <ReactEcharts
      className={classes.stretchDefault}
      option={getOption(interHoursData, 'day')} /> : null}
  </Paper>
);

export default withStyles(styles)(InterPerHours);
