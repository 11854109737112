import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import TabsWithCounter from '../../Utils/TabsWithCounter';
import { permit } from '@bit/dev-lba.lib.local-globals/restrictions';
const styles = {
  tabs: {
    marginBottom: 10
  }
};

const filterErrors = (arr, name) => arr.filter(e =>
  e.path.includes(name) || (!name && (['oldChaudiere'].includes(e.path[1]) ||
    !['combination', 'domofinance']
      .some(c => e.path[0].includes(c))))
).length || '';

const DialogTabs = ({ tabValue, handleTabChange, classes, errors, user, id }) =>
  <TabsWithCounter
    value={tabValue}
    centered
    noAppBar
    textColor="primary"
    className={classes.tabs}
    onChange={handleTabChange}
    values={[
      { name: 'Caldeo', counter: filterErrors(errors) },
      { name: 'Domofinance', counter: filterErrors(errors, 'domofinance') },
      { name: 'Combo' },
      (permit(user, { key: 'caldeoSpecial' }) && id ?
        { name: 'Ma Prime' } : null),
    ].filter(Boolean)}
  />;

export default withStyles(styles)(DialogTabs);
