import React from 'react';
import { Select, MenuItem, Checkbox } from '@material-ui/core';

const Dropdown = ({ title, onChange, isSelected, data, style,
  idKey = '_id', renderKey = 'name', disabled = false, multiple = true }) => (
  <Select
    style={style}
    multiple={multiple}
    value={[title]}
    renderValue={selected => selected}
    onChange={(e) => onChange(e)}
    disabled={disabled}
  >
    {
      data.map((d, i) => (
        <MenuItem key={i} value={idKey ? d[idKey] : d}>
          {multiple && <Checkbox checked={isSelected(idKey ? d[idKey] : d)}/>}
          {d[renderKey]}
        </MenuItem>
      ))
    }
  </Select>
);

export default Dropdown;

