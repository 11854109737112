import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Grid, Typography } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { ExpandMore } from '@material-ui/icons';
import {
  data as subStatusDevis,
} from '@bit/dev-lba.lib.local-globals/dSubStatusRecouvrement';

const SubStatusDevis = React.memo(({ value, handleChange }) => (
  subStatusDevis.map((e, id) => {
    const element = e.values.find((s) => s._id === value);
    return (
      <Grid item xs={4} key={id}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Grid container spacing={2}>
              <Grid item xs={9}>
                <Typography>{e.type}</Typography>
              </Grid>
              {element && (
                <Grid item xs={3}>
                  <Typography variant="caption">
                    {element.shortName?.toUpperCase()}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <RadioGroup value={value ? value : ''} onChange={handleChange}>
              <Grid container justify="center">
                {e.values.map((value, id) => (
                  <Grid item xs={6} key={id}>
                    <FormControlLabel
                      value={value._id}
                      control={<Radio />}
                      label={value.shortName}
                    />
                  </Grid>
                ))}
              </Grid>
            </RadioGroup>
          </AccordionDetails>
        </Accordion>
      </Grid>
    );
  })
));

export default (SubStatusDevis);
