import {
  grey,
  brown,
  blue,
  yellow,
  deepPurple,
  red,
  teal,
} from '@material-ui/core/colors';

export default {
  title: {
    fontSize: '1.2rem',
    color: grey[600],
  },
  card: {
    boxSizing: 'border-box',
    width: '100%',
  },
  linearProgress: {
    margin: 7,
    height: 25,
    backgroundColor: grey[200],
  },
  brownDeterminate: {
    background: brown[400],
  },
  blueDeterminate: {
    background: blue[400],
  },
  yellowDeterminate: {
    background: yellow[400],
  },
  fxColors: [blue[500], red[500], deepPurple[500], teal[500]],
  subheading: {
    paddingTop: 10,
    color: grey[500],
    fontWeight: 'bold',
  },
  root: {
    height: '100%',
    position: 'relative',
  },
  percentDiv: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  topDiv: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    paddingTop: 10,
    paddingBottom: 10,
  },
  formControl: {
    position: 'absolute',
    top: -10,
    left: 5,
  },
  halfCard: {
    width: '50%',
    height: '100%',
  },
  fullCard: {
    width: '100%',
    height: '100%',
  },
  serviceSelect: {
    width: '45%'
  },
  statsContainer: {
    padding: 10,
    height: 'unset'
  }
};
