import { DialogTitle, FormControlLabel, Grid, Switch } from '@material-ui/core';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { setDialogContentProp } from '../../actions/dialog';
import MoneyInput from '../CustomInputs/MoneyInput';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  dialogTitle: {
    padding: '0 0 16px',
    width: '100%'
  },
  switchRight: {
    textAlign: 'right'
  },
};

const setAmount = setDialogContentProp('amount');
const setSecure = (v) => setDialogContentProp('noSecure')(v);

const mapStateToProps = ({
  dialog: {
    contentProps: {
      amount,
      noSecure
    },
  },
}) => ({
  amount,
  noSecure
});

const mapDispatchToProps = {
  setAmount,
  setSecure
};

class SendLinkDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      noSecure: props.noSecure
    };
  }

  handleChange = () => this.setState({
    noSecure: !this.state.noSecure
  }, () => this.props.setSecure(this.state.noSecure));

  render() {
    const {
      amount,
      setAmount,
      classes
    } = this.props;
    const { noSecure } = this.state;
    return [
      <DialogTitle className={classes.dialogTitle}>
        <Grid container spacing={2}>
          <Grid item xs={8}
            children={'Envoyer un lien de payement'}
          />
          <Grid item xs={4} className={classes.switchRight}>
            <FormControlLabel
              control={
                <Switch
                  checked={!noSecure}
                  name="noSecure"
                  color="primary"
                  onChange={() => this.handleChange()}
                  label={`${!noSecure ? 'activé' : 'non activé'} 3D secure`}
                />
              }
              label={'3D secure'}
            />
          </Grid>
        </Grid>
      </DialogTitle>,
      <Grid container spacing={1} justify="center" alignItems="center">
        <Grid item xs={6}>
          <MoneyInput
            grid
            value={amount}
            sm={12}
            label="Saisir le montant à payer"
            setData={(p, v) => setAmount(v)}
          />
        </Grid>
      </Grid>
    ];
  }

}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(SendLinkDialog);
