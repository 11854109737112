import React from 'react';
import { moment } from '@bit/dev-lba.lib.local-globals/moment';
import {
  getOption as getPieOption
} from '../../common/graph/options/globalPieOptions';
import { getOption } from '../../common/graph/options/globalOptions';
import ReactEcharts from 'echarts-for-react';
import {
  data as categoryData
} from '@bit/dev-lba.lib.local-globals/categories';
import { green, blue, red, yellow, blueGrey } from '@material-ui/core/colors';

const TABS = [
  {
    colors: [blueGrey[500], green[500], blue[500], red[500], red[500]],
    disposition: ['bar', 'bar', 'bar', 'bar', 'line']
  },
  {
    colors: [blue[500], red[500]],
  },
  {
    colors: [green[500], yellow[900]],
  }
];

const MonthVerifChart = ({ data, to, updateChart, classes }) => {
  const percents = [];
  if (data['Verifié']) {
    const index = to.getMonth();
    for (let i = index; i > index - 4 && i >= 0; i--) {
      percents.unshift({
        'Verifié': data['Verifié'][i],
        'Verifié par support': data['Verifié par support'][i]
      });
    }

    return (
      <div className={classes.multiCharts}>
        {percents.map((p, i) => (
          <ReactEcharts
            ref={e => {
              updateChart(`chart${i + 2}`, e);
            }}
            notMerge={true}
            lazyUpdate={true}
            style={{ height: 400, width: 300 }}
            option={getPieOption({
              data: p,
              radius: '80%',
              legend: false,
              formatter: '{d}%',
              fontSize: 25,
              colors: TABS[2].colors,
              title: moment(to).subtract(i, 'months').format('MMMM')
            })}
          />
        ))}
      </div>
    );
  }

  return null;
};

export default ({ current, from, to, updateChart, classes }) => (
  <React.Fragment>
    {[current('CA'), current('entities')].map(
      (data = {}, i) => (
        <ReactEcharts
          ref={e => {
            updateChart(`chart${i}`, e);
          }}
          notMerge={true}
          lazyUpdate={true}
          style={{ height: 280 }}
          option={getOption({
            data: data,
            labels: Object.keys(data),
            mode: 'year',
            dates: [moment(from), moment(to)],
            colors: TABS[i].colors,
            disposition: TABS[i].disposition,
            disableToolbox: true,
          })}
        />
      )
    )}

    <MonthVerifChart data={current('verifiedIntervention')}
      to={to} updateChart={updateChart} classes={classes}/>

    <ReactEcharts
      ref={e => {
        updateChart('chart6', e);
      }}
      notMerge={true}
      lazyUpdate={true}
      style={{ height: 400 }}
      option={getPieOption({
        data: current('jobs'),
        radius: '60%',
        legend: false,
        format: true,
        formatter: '{b} - {d}%',
        fontSize: 15,
        colors: categoryData.map(c => c.color[500]),
        title: 'CA par metiers',
        titleExtra: { top: -5 }
      })}
    />

    <ReactEcharts
      ref={e => {
        updateChart('chart7', e);
      }}
      notMerge={true}
      lazyUpdate={true}
      style={{ height: 400 }}
      option={getPieOption({
        data: current('failed_jobs'),
        radius: '60%',
        legend: false,
        format: true,
        formatter: '{b} - {d}%',
        fontSize: 15,
        colors: categoryData.map(c => c.color[500]),
        title: 'Annulation par metiers',
        titleExtra: { top: -5 }
      })}
    />
  </React.Fragment>
);
