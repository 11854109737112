import React from 'react';

import {
  S_PART, S_JUR
} from '@bit/dev-lba.lib.local-globals/services';

import {
  NOT_CONFIRMED,
  CHIEF,
  USER,
  ADMIN,
  HEADCHIEF,
  JUNIOR
} from '@bit/dev-lba.lib.local-globals/permissions';
import componentLoader from '../../componentLoader';

const ListArtisan = React.lazy(() =>
  componentLoader(() => import('../../views/List/ListArtisan'))
);
const ListCandidat = React.lazy(() =>
  componentLoader(() => import('../../views/List/ListCandidat'))
);
const ListRelancesArtisan = React.lazy(() =>
  componentLoader(() => import('../../views/List/ListRelancesArtisan'))
);

const RecapArtisan = React.lazy(() =>
  componentLoader(() => import('../../views/RecapArtisan'))
);
const UtilsAssets = React.lazy(() =>
  componentLoader(() => import('../../views/UtilsAssets'))
);
const AjoutArtisans = React.lazy(() =>
  componentLoader(() => import('../../views/AjoutArtisans'))
);
const ListFacturier = React.lazy(() =>
  componentLoader(() => import('../../views/ListFacturier/ListFacturier'))
);
const AjoutCandidat = React.lazy(() =>
  componentLoader(() => import('../../views/AjoutCandidat'))
);
const CandidatConfig = React.lazy(() =>
  componentLoader(() => import('../../components/CandidatConfig'))
);
const EtatReseau = React.lazy(() =>
  componentLoader(() => import('../../containers/EtatReseau'))
);
const CommissionsPART = React.lazy(() =>
  componentLoader(() => import('../../views/Commissions/Partenariats'))
);

const PartenariatStats = React.lazy(() =>
  componentLoader(() => import('../../components/Stats/Partenariat'))
);
const MarketInterList = React.lazy(() =>
  componentLoader(() => import('../../components/ListDemarcharge'))
);
const ArtisanConnection = React.lazy(() =>
  componentLoader(() => import('../../components/ArtisanConnection'))
);
const CandidatStats = React.lazy(() =>
  componentLoader(() => import('../../components/Stats/Candidat'))
);
const MapStats = React.lazy(() =>
  componentLoader(() => import('../../components/Stats/Maps/index'))
);
const RechercheAjout = React.lazy(() =>
  componentLoader(() => import('../../views/MoteurDeRecherche/RechercheAjout'))
);
const MapEquipe = React.lazy(() =>
  componentLoader(() => import('../../components/MapEquipe'))
);
export default [
  {
    path: '/artisan/list/needFacturier',
    component: ListFacturier,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/artisan/utilsAssets',
    component: UtilsAssets,
    restrictions: () => JUNIOR,
  },
  {
    path: '/artisan/list/:filter/:query',
    component: ListArtisan,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/artisan/list/:filter',
    component: ListArtisan,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/artisan/list',
    component: ListArtisan,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/artisan/relance/:filter/:query',
    component: ListRelancesArtisan,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/artisan/:id/recap/:query',
    component: RecapArtisan,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/artisan/:id/recap',
    component: RecapArtisan,
    restrictions: () => USER,
  },
  {
    path: '/artisan/:id',
    component: AjoutArtisans,
    restrictions: ({ service }) =>
      ({
        [S_PART]: NOT_CONFIRMED,
        [S_JUR]: NOT_CONFIRMED,
      }[service] || ADMIN),
  },
  {
    path: '/artisan',
    component: AjoutArtisans,
    restrictions: ({ service }) =>
      ({
        [S_PART]: NOT_CONFIRMED,
        [S_JUR]: NOT_CONFIRMED,
      }[service] || ADMIN),
  },
  {
    path: '/candidat/config/table/:type',
    component: CandidatConfig,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/candidat/list/:filter/:query',
    component: ListCandidat,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/candidat/list/:filter',
    component: ListCandidat,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/candidat/list',
    component: ListCandidat,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/candidat/:id',
    component: AjoutCandidat,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/candidat',
    component: AjoutCandidat,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/partenariat/demarchMarket',
    component: MarketInterList,
    restrictions: () => USER,
  },
  {
    path: '/partenariat/market/:filter',
    component: MarketInterList,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/partenariat/etatreseau',
    component: EtatReseau,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/partenariat/commissions',
    component: CommissionsPART,
    restrictions: () => HEADCHIEF,
  },
  {
    path: '/partenariat/statistiques',
    component: PartenariatStats,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/partenariat/maps',
    component: MapStats,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/partenariat/artisanConnection',
    component: ArtisanConnection,
    restrictions: () => CHIEF,
  },
  {
    path: '/partenariat/mapEquipe',
    component: MapEquipe,
    restrictions: () => CHIEF,
  },
  {
    path: '/partenariat/candidat/statistiques',
    component: CandidatStats,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/prosp/list/:filter',
    component: RechercheAjout,
    restrictions: () => USER,
  },
  {
    path: '/prosp/list/',
    component: RechercheAjout,
    restrictions: () => USER,
  },
];
