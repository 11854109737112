import React from 'react';
import { connect } from 'react-redux';

import { MenuItem } from '@material-ui/core';

import CustomTextField from '../CustomInputs/CustomTextField';
import CustomSelect from '../CustomInputs/CustomSelect';
import { setDialogContentProp } from '../../actions/dialog';

const setNewGoals = setDialogContentProp('newGoals');
const setUser = setDialogContentProp('user');

const parameters = {
  nJour: { title: 'Objectif du jour' },
  nWeek: { title: 'Objectif de la semaine' },
  nMonth: { title: 'Objectif du mois ' },
};

const mapStateToProps = ({
  dialog: {
    contentProps: {
      newGoals,
      users,
      user
    },
  }
}) => ({
  newGoals,
  users,
  user,
});

const mapDispatchToProps = {
  setNewGoals,
  setUser,
};

const ObjectifQualityDialog = ({
  users,
  user,
  newGoals,
  setNewGoals,
  setUser
}) => (
  <>
    <CustomSelect
      value={user}
      setData={(p, v) => setUser(v)}
    >
      {
        users.map((d, i) => (
          <MenuItem key={i} value={d._id}>{d.login}</MenuItem>
        ))
      }
    </CustomSelect>
    {
      user && Object.keys(parameters)
        .map((key, i) => (
          <CustomTextField
            key={i}
            autoFocus={i === 0}
            margin="dense"
            type="number"
            label={parameters[key].title}
            value={newGoals[key] || 0}
            path={key}
            setData={(p, v) => {
              const goals = { ...newGoals };
              goals[p] = v;
              setNewGoals(goals);
            }}
            fullWidth
          />
        ))
    }
  </>
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ObjectifQualityDialog);
