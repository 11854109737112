import React, { Component } from 'react';

import { moment } from '@bit/dev-lba.lib.local-globals/moment';
import MomentLocaleUtils, {
  formatDate,
} from 'react-day-picker/moment';
import TextField from '@material-ui/core/TextField';


import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { withDisplaying } from '../../hoc';
import 'moment/locale/fr';

const overlay = ({ classNames, children, disabled, ...props }) => !disabled &&
  <div className={classNames.overlayWrapper} {...props}>
    <div className={classNames.overlay}>
      {children}
    </div>
  </div>;

class DatePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      from: this.props.startDate,
    };
    this.handleFromChange = this.handleFromChange.bind(this);
    this.handleFromDayChange = this.handleFromDayChange.bind(this);
    this.handleFromKeyPress = this.handleFromKeyPress.bind(this);
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }


  handleFromChange(from, modifiers) {
    if (modifiers.disabled){
      return;
    }
    this.setState({ from });
  }

  handleFromDayChange(from) {
    this.setState({ from });
    this.props.onChange(moment(from), this.props.stateName);
  }

  handleFromKeyPress(e) {
    if (e.key === 'Enter'){
      this.props.onChange(moment(this.state.from), this.props.stateName);
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      from: nextProps.startDate
    });
  }

  render() {
    const { from } = this.state;
    const {
      label,
      before,
      disabled,
      classNamesDate,
      dayPickerProps = {},
      noBasicDate
    } = this.props;
    const modifiers = { start: from };
    const fromInputProps = {
      id: 'from',
      name: 'from',
      label: label,
      onChange: this.handleFromOnChange,
      onKeyPress: this.handleFromKeyPress,
      zindex: 1000,
      disabled: disabled || false,
      readOnly: disabled || false
    };
    return (
      <DayPickerInput
        overlayComponent={({ classNames, children, ...props }) =>
          overlay({
            classNames: { ...classNames, ...classNamesDate },
            children,
            disabled,
            ...props,
          })
        }
        keepFocus={false}
        component={TextField}
        inputProps={fromInputProps}
        placeholder=""
        value={noBasicDate ? '' : `${formatDate(from, 'L', 'fr')}`}
        formatDate={formatDate}
        dayPickerProps={{
          month: from,
          selectedDays: [from, { from }],
          disabledDays: before ? {} : { before: new Date() },
          modifiers,
          numberOfMonths: 1,
          zIndex: 1000,
          onDayClick: this.handleFromChange,
          locale: 'fr',
          localeUtils: MomentLocaleUtils,
          ...dayPickerProps,
        }}
        onDayChange={this.handleFromDayChange}
      />
    );
  }
}

DatePicker.displayName = 'DatePicker';

export default withDisplaying()(DatePicker);
