import React from 'react';

import { red, green } from '@material-ui/core/colors';
import { PersonPin } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';


const styles = () => ({
  active: {
    color: green[500],
  },
  desactive: {
    color: red[500],
  },
});

export default withStyles(styles)(({ user, classes }) => (
  <PersonPin
    className={user.isActiveSession ? classes.active : classes.desactive}
  />
));
