import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  withStyles,
  withTheme
} from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import List from '@material-ui/core/List';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import PropTypes from 'prop-types';
import { S_DEVIS } from '@bit/dev-lba.lib.local-globals/services';
import { compose } from 'recompose';
import { urlsCounter as urls } from '../utils';

const styles = ({
  list: {
    paddingTop: 0
  },
  link: {
    textDecoration: 'none',
    color: '#fff',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    padding: 3
  },
  points: {
    marginTop: 10,
    marginRight: 3,
    color: '#fff',
    fontWeight: 'bold'
  },
  login: {
    fontWeight: 'bold',
    fontSize: 14,
    marginLeft: 8,
    color: '#fff',
  },
  column: {
    display: 'block',
    borderRadius: 5,
    margin: '15px 6px 24px 6px',
  },
  iconCalls: {
    height: 20,
    width: 20,
    margin: 5
  },
  numberCalls: {
    fontSize: 15,
    marginLeft: 5,
    marginRight: 10,
    fontWeight: 'bold',
    color: '#fff'
  },
  divPoints: {
    textAlign: 'end',
    width: '100%',
  },
  genChip: {
    color: 'rgb(51, 51, 51)',
    backgroundColor: 'rgb(255, 255, 255)',
    height: 20,
    fontSize: 12,
    marginBottom: 4
  },
  logo: {
    backgroundColor: 'rgb(158, 158, 158)',
    width: 30,
    height: 30,
    fontSize: 15,
  },
  divider: {
    height: 1,
    backgroundColor: '#252525',
    margin: '-25px 0px -10px 0px'
  }
});

const urlsCounter = urls[S_DEVIS];

const mapStateToProps = (nextProps) => ({
  counter: nextProps.counter,
  users: nextProps.users,
  userId: nextProps.userId,
  teams: nextProps.equipes
});

const genChip = (elem, chipCss) => (elem &&
  <Chip label={elem} className={chipCss}/>
);

const Intervs = ({ link, color, e, counter, url, path }) => (
  <Avatar style={{
    backgroundColor: color,
    width: 22,
    height: 22,
    fontWeight: 'bold',
    fontSize: 11, marginLeft: 5 }} >
    <Link className={link}
      to={`/intervention/list/${url}/login-${path}=${e.login}=${e._id}`}>
      { counter }
    </Link>
  </Avatar>
);

const calculsPoints = (objStats) => {
  let points = objStats.envAjdSupp + objStats.counVrf + objStats.counAprogAjd -
  objStats.avrSupp - (objStats.avrSupp / 10);
  return points;
};

class StatsTelepro extends Component {

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.counter && nextProps.users) {
      const user = nextProps.users.find(e => e._id === nextProps.userId);
      if (user)
      {
        const redux = Object.assign({}, this.state);
        this.setState({
          redux
        });
      }
    }
  }

  getUserCount(login, counter) {
    const ajd = counter[`interventions.counAjd.${login}`] || 0;
    const counVrf = counter[`interventions.counVrf.${login}`] || 0;
    const counAprogAjd = counter[`interventions.counAprogAjd.${login}`] || 0;
    const envAjdSupp = counter[`interventions.envAjdSupp.${login}`] || 0;
    const counAnnAjd = counter[`interventions.counAnnAjd.${login}`] || 0;
    const avrSupp = counter[`interventions.avrSupp.${login}`] || 0;
    return { ajd, counVrf, counAprogAjd, envAjdSupp, counAnnAjd, avrSupp };
  }

  getOrder(users) {
    const { counter } = this.props;
    let objStats;
    users.forEach(e => {
      objStats = this.getUserCount(e.login, counter);
      e.objStats = this.getUserCount(e.login, counter);
      e.points = calculsPoints(objStats);
    });
    return users.sort((a, b) => b.points - a.points);
  }

  render() {
    const { classes, userId } = this.props;
    let users = this.props.users;
    const user = users.find(e => e._id === userId);
    if (!users || !user) {
      return null;
    }
    users = users && users.filter(e => e.service === S_DEVIS && e.act);
    users = users && this.getOrder(users);
    return (
      <List className={classes.list}>
        {users.map((e, id) =>
          <div key={id} >
            <div className={classes.column}>
              <div className={classes.row}>
                <Avatar className={classes.logo}
                  src={e.logo || null}>
                  { e.login[0].toUpperCase() }
                </Avatar>
                <span className={classes.login}>
                  {e.alias && e.alias.toUpperCase()}
                </span>
                <div className={classes.divPoints}>
                  <span className={classes.points}>
                    <Link className={classes.link}
                      to={ `/devis/list/ajd/login-ajout=${e.login}=${e._id}`}>
                      {genChip(`${
                        Math.round(e.points)} Points`, classes.genChip)}
                    </Link>
                  </span>
                </div>
              </div>
              <div className={classes.row}>
                { urlsCounter.map((item, key) =>
                  <Intervs
                    key={key}
                    link={classes.link}
                    color={item.color}
                    e={e}
                    counter={e.objStats[item.url]}
                    url={item.url}
                    path={item.path} />) }
              </div>
            </div>
            <Divider className={classes.divider}/>
          </div>
        )}
      </List>
    );
  }
}

StatsTelepro.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles),
  withTheme,
  connect(mapStateToProps),
)(StatsTelepro);
