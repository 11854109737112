import React, { useState } from 'react';
import { Box, Grid, Paper, Tab, Tabs } from '@material-ui/core';
import Dashboard from './Dashboard';
import SupportDashboard from '../SupportDashboard';

import { permit } from '@bit/dev-lba.lib.local-globals/restrictions';
import { UserCard } from '../../../components/Stats';
import { withStyles } from '@material-ui/styles';
import ObjectifsContainer from '../utils/ObjectifsContainer';
import {
  S_INT,
  S_LEAD,
} from '@bit/dev-lba.lib.local-globals/services';

const styles = (theme) => ({
  conditionalDisplayNoneRWD: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    [theme.breakpoints.between('md', 'lg')]: {
      display: 'block',
    },
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
    },
  },
});

const Objectifs = withStyles(styles)(({ user, classes, allUsers }) =>
  <Grid container spacing={2} item xs={12}>
    <Grid item md={12} lg={4} className={classes.conditionalDisplayNoneRWD}>
      {UserCard && <UserCard user={user} />}
    </Grid>
    <Grid item md={12} lg={8}>
      <ObjectifsContainer user={user} personal allUsers={allUsers} />
    </Grid>
  </Grid>);

function CommercialDashboard({ user, ...props }) {
  const isAdmin = permit(user, { key: 'adminAccess' });
  const [selectedTab, setSelectedTab] = useState(0);
  if (selectedTab === 0) {
    props.users = props.users.filter(e =>
      [S_INT, S_LEAD].filter(Boolean).includes(e.service));
  }

  const handleTabChange = (e, newValue) => {
    setSelectedTab(newValue);
  };

  const DashboardContent = () =>
    <Grid container spacing={2}>
      {!isAdmin ? <Objectifs user={user} {...props} /> : null}
      <Dashboard user={user} {...props} />
    </Grid>;

  return isAdmin ? <>
    <Grid container spacing={2}>
      <Objectifs {...props} user={user} />
      <Grid item xs={12}>
        <Paper square>
          <Tabs
            fullWidth
            value={selectedTab}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleTabChange}
            aria-label="disabled tabs example"
          >
            <Tab label="Commercial" />
            <Tab label="Support" />
          </Tabs>
        </Paper>
      </Grid>
    </Grid>
    {[DashboardContent, SupportDashboard].map((Component, i) =>
      <Box
        role="tabpanel"
        hidden={selectedTab !== i}
        id={`wrapped-tabpanel-${i}`}
        aria-labelledby={`wrapped-tab-${i}`}
        children={<Component user={user} {...props} />}
      />
    )}
   </> : <DashboardContent user={user} {...props} />;
}

export default withStyles(styles)(CommercialDashboard);
