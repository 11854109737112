import React, { useState } from 'react';
import { connect } from 'react-redux';

import { closeDialog } from '../actions/dialog';
import * as dialogs from '../components/Dialogs';
import MinCustomDialog from '../components/Dialogs/MinCustomDialog';
import CustomLoadingButton from '../components/Utils/CustomLoadingButton';

const mapStateToProps = ({ dialog }) => dialog;

const mapDispatchToProps = dispatch => ({
  closeDialog: () => dispatch(closeDialog()),
  dispatch,
});

const mergeProps = (stateProps, dispatchProps) => ({
  ...stateProps,
  ...dispatchProps,
  actions: stateProps.hideClose
    ? stateProps.actions
    : [{
      children: 'Fermer',
      color: 'secondary',
      onClick: dispatchProps.closeDialog
    },
    ...stateProps.actions]
});

const DialogManager = ({
  name,
  open,
  actions,
  dialogProps,
  contentProps,
  closeDialog,
  dispatch,
}) => {
  const [buttonArr, setButtonArr] = useState(
    new Array(actions.length).fill(false)
  );

  const setLoading = (index) => (loading) => {
    const copy = [...buttonArr];
    copy[index] = loading;
    setButtonArr(copy);
  };

  const DialogContent = name === 'emptyOnPurpose'
    ? () => <div />
    : dialogs[name];

  return DialogContent
    ? <MinCustomDialog
      {...dialogProps}
      actions={actions.map(({ onClick, children, hideButton, ...p }, i) =>
        !hideButton || !hideButton(contentProps) ? <CustomLoadingButton
          key={i}
          color="default"
          loading={buttonArr[i]}
          disabled={buttonArr.some(e => !!e)}
          children={typeof children === 'function' ?
            children(contentProps) : children}
          {...p}
          onClick={() => onClick({
            ...contentProps, setLoading: setLoading(i)
          }, closeDialog, dispatch)}
        /> : null
      )}
      open={open}
      onClose={closeDialog}
      children={<DialogContent />}
    />
    : null;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(DialogManager);
