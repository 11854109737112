import {
  USERS_FETCH,
  USER_CONNECT,
  USER_UPDATE,
} from '../constants/ActionTypes';
import api from '../api';
import notifSystem from '../notifSystem';
import store from '../../src/store';
import { setDialog } from './dialog';

export function loadUsers(users) {
  return {
    type: USERS_FETCH,
    users
  };
}

export function userConnect(isActiveSession) {
  return {
    type: USER_CONNECT,
    isActiveSession,
  };
}

export function updateUser(user) {
  return {
    type: USER_UPDATE,
    payload: user,
  };
}

export const disableUser = (id, act) =>
  api
    .users
    .custom(`${id}/disable`)
    .patch({ act })
    .then(() => notifSystem.success(
      'Opération réussie',
      `L'utilisateur a bien été ${!act ? 'désactivé' : 'activé'}`
    ))
    .catch(e => notifSystem.error(
      'Erreur',
      e.response.data
    ));

export const addNewUser = ({
  getSchedule,
  state,
  cb
}) => {
  store.dispatch(
    setDialog({
      name: 'AddNewUser',
      open: true,
      dialogProps: {
        maxWidth: 'md',
      },
      contentProps: {
        getSchedule,
        newUser: {
          login: '',
          email: '',
          alias: '',
          service: 1,
          maxV: 0,
          ligne: '',
          multiLignes: [],
          ligneService: '',
          telephoneId: '',
          act: true,
          permission: 1,
          late: true,
          leave: true,
          schedule: getSchedule(1),
          supportIds: []
        },
      },
      actions: [
        {
          children: 'Ajouter',
          color: 'primary',
          onClick: (data, close) => {
            if (new RegExp(/\./).test(data?.newUser?.login)) {
              notifSystem.error(
                'Erreur',
                'Le login ne doit pas contenir de point'
              );
              return false;
            }
            if (state.find((e) => e.login === data?.newUser?.login)) {
              notifSystem.error(
                'Veuillez changer le login',
                'Un utilisateur avec ce login existe déjà'
              );
              return false;
            }
            cb({ data: [data.newUser, ...state] });
            close();
          },
        },
      ],
    })
  );
};

