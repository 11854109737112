import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

import withWidth, {
  isWidthDown,
  isWidthUp
} from '@material-ui/core/withWidth';
import { withStyles } from '@material-ui/core/styles';

import { RT_CLI, RT_ART, RT_BTOB } from '@bit/dev-lba.lib.local-globals/rTypes';
import { permit } from '@bit/dev-lba.lib.local-globals/restrictions';

import {
  Menubar,
  InternalRouter,
  Sidebar,
} from '../Dashboard';

import RigthSideBarStats from './sidebar/rigth/SideBarStat';
import BottomSideBarStats from './sidebar/bottom/SideBarStat';

import notifSystem from '../../notifSystem';
import { addRecouvrement } from '../../actions/recouvrement';
import { openInterDemande } from '../../actions/inter';
import { demandePhoto } from '../../actions/general';
import { Map } from 'immutable';
import store from '../../store';

const fun = {
  '/litige': (props) =>
    addRecouvrement(props, {
      type: RT_CLI,
      isLitige: true,
      title: 'litige',
    }),
  '/recouvrementSst': (props) =>
    addRecouvrement(props, {
      type: RT_ART,
      isLitige: false,
      title: 'recouvrement SST',
    }),
  '/recouvrementClient': (props) =>
    addRecouvrement(props, {
      type: RT_CLI,
      isLitige: false,
      title: 'recouvrement Client',
    }),
  '/recouvrementBtob': (props) =>
    addRecouvrement(props, {
      type: RT_BTOB,
      isLitige: false,
      title: 'recouvrement Btob',
    }),
  '/demandes': () =>
    store.dispatch(
      openInterDemande(new Map(), false, {
        source: 'Call Center',
        sChoice: 'DEMANDE INTERVENTION',
      })
    ),
  '/demandePhoto': (props) =>
    store.dispatch(
      demandePhoto(props, 'Demande de photo')
    ),
};

const mapStateToProps = ({ userId, users }) => ({
  user: users.find((u) => u._id === userId),
});

const styles = (theme) => ({
  elem: {
    position: 'absolute',
    top: 56,
    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
      top: 48,
    },
    [theme.breakpoints.up('sm')]: {
      top: 64,
    },
    bottom: 0,
    overflowY: 'overlay',
    overflowX: 'hidden',
  },
  sidebar: {
    backgroundColor: '#333',
    zIndex: 3,
    width: 250,
    transition: 'left 0.3s',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginBottom: 0,
    },
  },
  main: {
    left: 0,
    right: 0,
    backgroundColor: 'lightgray',
    transition: 'ease 0.3s',
  },
  SideBarStats: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  sideBarStatsBottomStyle: {
    [theme.breakpoints.up('sm')]: {
      marginBottom: 40,
    },
  },
});

class Dash extends Component {
  state = {
    open: !isWidthDown('xs', this.props.width),
    prevWidth: this.props.width,
    dialogOpen: false,
    search: true,
    input: '',
    openStats: false,
  };

  componentDidMount() {
    document.addEventListener('keydown', this.handleEscapeKey);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleEscapeKey);
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.width !== state.prevWidth &&
      state.open === (props.width !== 'lg' && props.width !== 'xl')
    ) {
      return {
        width: props.width,
        open: !state.open,
      };
    }
    return null;
  }

  openStats = () => this.setState((pS) => ({ openStats: !pS.openStats }));

  handleEscapeKey = (e) => e.keyCode === 27 && this.toggle();

  setDialog = (dialog, open, text, cb) =>
    this.setState({
      dialog,
      dialogOpen: open,
      dialogText: text,
      dialogCb: cb,
    });

  handleClickAccept = () => this.setState({ renderCgv: 2 });

  toggle = () => this.setState(({ open }) => ({ open: !open }));

  detectMobileToTablet = (open) => {
    const width = isWidthDown('xs', this.props.width);
    const kind = !open ? '-' : '';
    const size = width ? '100%' : '250px';

    return `${kind}${size}`;
  };

  updateSearch = (event) => {
    const { search } = this.state;
    const { value } = event.target;

    if (event.key === 'Enter' && value.length >= 3) {
      if (!search) {
        if (new RegExp('^\\d+$').test(value)) {
          this.setState({ openDialog: true });
        } else {
          notifSystem.error(
            'Erreur',
            'Seuls les numéros sont acceptés pour la recherche d\'audio'
          );
        }
      } else {
        this.props.history.push(`/search/${event.target.value}`);
      }
    }
  };

  handlerMobileOpenSideBar = () =>
    isWidthDown('xs', this.props.width) && this.toggle();

  handlerCloseSideBarClickLogo = () => this.setState({ open: false });

  render() {
    const { classes, location, history, width, user } = this.props;
    const { open, dialogOpen, dialogCb, dialogText, openStats } = this.state;
    const sideBarStatsBottom =
      user && permit(user, { key: 'sideBarStatsBottom' });
    const sideBarStatsRight =
      user && permit(user, { key: 'sideBarStatsRight' });
    const sideBar = user && permit(user, { key: 'sideBar' });
    const appBar = user && permit(user, { key: 'appBar' });

    return (
      <div>
        <Menubar
          open
          appBar={appBar}
          toogle={this.toggle}
          handlerCloseSideBarClickLogo={this.handlerCloseSideBarClickLogo}
        />
        <div>
          {appBar && sideBar && (
            <div
              style={{
                left: open ? 0 : this.detectMobileToTablet(open),
              }}
              className={classNames(
                classes.sidebar,
                classes.elem,
                sideBarStatsBottom ? classes.sideBarStatsBottomStyle : ''
              )}
            >
              <Sidebar
                setDialog={(name) => {
                  fun[name]({ setDialog: this.setDialog, user });
                }}
                history={history}
                handlerMobileOpenSideBar={this.handlerMobileOpenSideBar}
              />
            </div>
          )}
          <div
            className={classNames(
              classes.main,
              classes.elem,
              sideBarStatsBottom ? classes.sideBarStatsBottomStyle : ''
            )}
            id="maindiv"
            style={{
              left: sideBar && open ? this.detectMobileToTablet(open) : 0,
              right: 0,
            }}
          >
            {user && <InternalRouter user={user} location={location} />}
          </div>
          {isWidthUp('sm', width) && sideBarStatsBottom && (
            <BottomSideBarStats
              open={openStats}
              openStats={this.openStats}
              service={user.service}
            />
          )}
          {isWidthUp('sm', width) && sideBarStatsRight && (
            <RigthSideBarStats open={openStats} openStats={this.openStats} />
          )}
        </div>
        {dialogOpen && (
          <this.state.dialog
            open={dialogOpen}
            callback={dialogCb}
            defaultText={dialogText}
          />
        )}
      </div>
    );
  }
}

Dash.propTypes = {
  width: PropTypes.string.isRequired
};

export default withRouter(compose(
  withWidth(),
  withStyles(styles),
  connect(mapStateToProps)
)(Dash));
