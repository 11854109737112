import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { ContextMenu, MenuItem } from 'react-contextmenu';
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import {
  OpenInNew,
  ExpandLess,
  ExpandMore
} from '@material-ui/icons';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import { connect } from 'react-redux';
import { Card } from '@material-ui/core';
import { setDialog } from '../../actions/dialog';

const mapStateToProps = state => ({
  user: state.users.find(e => e._id === state.userId),
  users: state.users,
  userId: state.userId,
  counter: state.counter
});

const mapDispatchToProps = {
  setConnectedDialog: setDialog,
};

function getter(obj, arg) {
  arg = arg.split('.');
  arg.forEach(e => {
    if (!obj) {
      return null;
    }
    obj = obj[e];
  });
  return obj;
}

function exists(key, obj) {
  return !key || (obj && key && getter(obj, key));
}

function isVisible(elem, props) {
  if (elem.customVisible) {
    return elem.customVisible(elem, props);
  }
  return exists(elem.visible, props.selected);
}

const styles = () => ({
  iconButton: {
    height: 32,
    padding: '0px 0px',
  },
  menuText: {
    padding: '0px 16px',
  },
  menuItem: {
    marginLeft: 5,
    marginBottom: 5,
    marginRight: 5,
  },
  contextMenu: {
    zIndex: 100,
    minWidth: 250
  },
  notAuthorized: {
    height: 50,
    width: 250,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '3px solid red',
  },
});

class ListContextMenu extends PureComponent {
  state = {
    open: '',
  };

  handleExpand = (e, data) => {
    this.setState({ open: this.state.open === data.name ? '' : data.name });
  };

  handleClick = (e, data) => {
    data.action(
      {
        ...this.props,
        user: this.props.users.find(e => e._id === this.props.userId),
      },
      data.elem
    );
  };

  handleNewOpen = (e, elem) => {
    e.stopPropagation();
    elem.link(this.props.selected);
  };

  genItem = (elem, i) => {
    const { classes, selected } = this.props;
    const name =
      typeof elem.name === 'function' ? elem.name(this.props) : elem.name;
    const Link = () => <ListItemSecondaryAction children={
      <IconButton
        edge="end"
        onClick={e => this.handleNewOpen(e, elem)}
        children={<OpenInNew />}
      />
    } />;
    let item = exists(elem.value, selected) && isVisible(elem, this.props) && (
      <ListItem key={i} button>
        {elem.icon ? <elem.icon /> : ''}
        <ListItemText
          className={classes.menuText}
          primary={name}
          secondary={
            selected && elem.value && getter(this.props.selected, elem.value)
          }
        />
        {elem.link && <Link />}
      </ListItem>
    );
    if (elem.funcValue && selected) {
      const value = elem.funcValue(this.props, selected);
      item = value && isVisible(elem, this.props) && (
        <ListItem button>
          {elem.icon ? <elem.icon /> : ''}
          <ListItemText
            primary={name}
            className={classes.menuText}
            secondary={selected && value}
          />
          {elem.link && <Link />}
        </ListItem>
      );
    }
    return (
      <MenuItem
        key={elem.name}
        data={{ action: elem.action }}
        onClick={this.handleClick}
      >
        {item}
      </MenuItem>
    );
  };

  render() {
    const {
      actions,
      idMenu,
      classes
    } = this.props;
    const { open } = this.state;
    const hasAuthorization = actions.some(e => isVisible(e, this.props));

    return (
      <ContextMenu id={idMenu || 'SIMPLE'} className={classes.contextMenu}>
        <Paper className="contextMenu">
          {hasAuthorization ?
            <List>
              {actions.map(elem => {
                const name =
                  typeof elem.name === 'function'
                    ? elem.name(this.props, elem)
                    : elem.name;
                return (
                  (elem.action && this.genItem({ ...elem, name })) ||
                  (isVisible(elem, this.props) && (
                    <span key={name}>
                      <MenuItem
                        data={{ name: name }}
                        onClick={this.handleExpand}
                        preventClose
                      >
                        <ListItem button>
                          <elem.icon />
                          <ListItemText
                            className={classes.menuText}
                            primary={name}
                          />
                          {open === name ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                      </MenuItem>
                      <Collapse
                        in={open === name}
                        timeout="auto"
                        unmountOnExit
                        children={elem.sub.map(this.genItem)}
                      />
                    </span>
                  ))
                );
              })}
            </List>
            :
            <Card variant="outlined" className={classes.notAuthorized}>
              Accès non autorisé.
            </Card>
          }
        </Paper>
      </ContextMenu>
    );
  }
}

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(ListContextMenu);
