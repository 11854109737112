/* eslint-disable max-lines */
import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { AppBar, Tabs, Tab, Paper } from '@material-ui/core/';

import GlobalStats from './GlobalStats';
import RecouvStats from './RecouvStats';
import AdvanceStats from './AdvanceStats';
import { permit } from '@bit/dev-lba.lib.local-globals/restrictions';

const tabs = [
  {
    _id: 1,
    name: 'Statistiques',
    permit: () => false,
  },
  {
    _id: 2,
    name: 'Statistiques avancées',
    permit: ({ user }) => !permit(user, { key: 'menuStatusRecouvrement' }),
  },
  {
    _id: 3,
    name: 'Global',
    permit: ({ user }) => !permit(user, { key: 'menuStatusRecouvrement' }),
  },
];

const styles = {
  formDiv: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginTop: '1rem',
    marginBottom: '20px',
  },
  selector: {
    margin: '20px',
  },
};

class RecouvrementStats extends PureComponent {
  state = {
    tabValue: 1,
  };

  handleChange = (name) => (e, value) => {
    this.setState({ [name]: value });
  };

  render() {
    const { user } = this.props;
    const { tabValue } = this.state;

    return (
      <Paper>
        <AppBar position="static">
          <Tabs
            value={tabValue}
            onChange={this.handleChange('tabValue')}
            variant="scrollable"
            scrollButtons="auto"
          >
            {tabs.map((e) => (
              <Tab
                disabled={e.permit({ user })}
                value={e._id}
                key={e._id}
                label={e.name}
              />
            ))}
          </Tabs>
        </AppBar>
        {tabValue === 1 && <RecouvStats />}
        {tabValue === 2 && <AdvanceStats user={user} />}
        {tabValue === 3 && <GlobalStats />}
      </Paper>
    );
  }
}

export default withStyles(styles)(RecouvrementStats);
