import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import {
  MISE_EN_DEMEURE_ARTISAN,
  MISE_EN_DEMEURE_BTOB,
  FetchData,
} from '@bit/dev-lba.lib.local-globals/rConclusions';
import Button from '@material-ui/core/Button';
import CustomCheckbox from '../../../CustomInputs/CustomCheckbox';
import Grid from '@material-ui/core/Grid';
import { List } from 'immutable';
import DatePicker from '../../../DatePicker';
import MoneyInput from '../../../CustomInputs/MoneyInput';
import MailRecouvrement from '../MailComponent';

const styles = () => ({
  container: {
    margin: 0
  },
  textField: {
    display: 'block'
  },
  minSize: {
    minWidth: '320px',
    minHeight: '525px'
  }
});

const getComponent = (e, { recouvrement, handleCheckboxes }) =>
  <CustomCheckbox grid xs={6} key={e.id}
    label={e.name}
    checked={!!recouvrement.get('conclusions', new List()).contains(e._id)
    || false}
    path={[e._id.toString()]}
    setData={handleCheckboxes}
  />;


const DialogConcl = (props) => {
  const {
    selected,
    classes,
    recouvrement,
    setConclusionDialog,
    setDataGlobal,
    mail,
    type,
  } = props;
  const { keys, names, obj } = FetchData[type];
  return (
    [<DialogTitle key={0}>
      <Button variant="outlined" color="secondary" fullWidth>
        {names[selected]}
      </Button>
    </DialogTitle>,
    <DialogContent key={1} className={classes.minSize}>
      <Grid container spacing={3} className={classes.container}>
        {![-1, 2].includes(selected) &&
          obj[keys[selected]]
            .map((e) => getComponent(e, props))}
      </Grid>
      {recouvrement.get('conclusions', new List())
        .find(e => [
          MISE_EN_DEMEURE_ARTISAN,
          MISE_EN_DEMEURE_BTOB,
        ].includes(e)) && [
        <Grid key={0} item xs={12}>
          <DatePicker
            startDate={new Date(recouvrement.get('emisDate', new Date()))}
            onChange={(c) =>
              setDataGlobal(['recouvrement', 'emisDate'], c.toDate()) }
            label={'Emis le'} before={true} /> </Grid>,
        <MoneyInput
          label="Montant de mise en demeure (€)" key={1} fullWidth
          margin='normal' grid xs={12} requiredText
          defaultValue={recouvrement.get('montantEmisDemeure', 0)}
          path={['recouvrement', 'montantEmisDemeure']}
          setData={setDataGlobal}
        />]}
      {mail && mail.text && <MailRecouvrement {...props} /> }
    </DialogContent>,
    <DialogActions key={2}>
      <Button onClick={setConclusionDialog} color="primary">
        OK
      </Button>
    </DialogActions>]
  );
};

export default withStyles(styles)(DialogConcl);
