import React from 'react';

import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';

import { counters } from '../utils';
import styles from '../styles';
import IconToolbar from '../../../Utils/IconToolbar';

const SMSTableToolbar = ({
  classes,
  selected,
  selectMode
}) =>
  <Toolbar className={classes.toolbarRoot}>
    <Typography variant="h6" children="Liste des SMS" />
    <IconToolbar
      elems={counters}
      handleClick={(e, i) => selectMode(i)}
      style={(e, i) => ({
        backgroundColor: !selected.includes(i) ? grey[500] : e.color
      })}
    />
  </Toolbar>;

export default withStyles(styles)(SMSTableToolbar);
