import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, LinearProgress, Paper, Typography } from '@material-ui/core';
import { getEmoji } from './getEmoji';
import constant from './constant';
import { getAllCalls } from '../../../actions/calls';
import { setDialog } from '../../../actions/dialog';
import store from '../../../store';

const styles = (theme) => ({
  loadingGrid: {
    paddingTop: theme.spacing(1 / 2)
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  statsAppels: {
    width: '100%',
    margin: 10,
    padding: 3,
    textAlign: 'center',
  },
  titleStats: {
    marginBottom: 5,
    fontSize: 10,
    color: '#fff',
  },
  stats: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#fff',
  },
  emoji: {
    textAlign: 'center',
  },
  lineHeight: {
    lineHeight: 'normal',
    overflowWrap: 'break-word',
  },
  score: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
});

class CallChips extends Component {
  state = {
    loading: true,
  };

  getCalls = () => {
    const { users, userId, endAt, startAt } = this.props;
    const user = users.find((u) => u._id === userId);

    getAllCalls({ user, userId, users, endAt, startAt })
      .then((nCalls) => this.setState({ statsCalls: nCalls }))
      .finally(() => this.setState({ loading: false }));
  };

  componentDidMount() {
    this.getCalls();
  }

  componentDidUpdate(prevProps, prevState) {
    const { startAt, endAt } = prevState;
    const { startAt: nStartAt, endAt: nEndAt, load, withEmoji } = this.props;
    if ((load || withEmoji) && (nStartAt !== startAt || nEndAt !== endAt)) {
      this.setState({ startAt: nStartAt, endAt: nEndAt, loading: true }, () => {
        this.getCalls();
      });
    }
  }

  openUniques = (calls, title) =>
    store.dispatch(
      setDialog({
        name: 'ListCallsDialog',
        open: true,
        hideClose: true,
        dialogProps: {
          maxWidth: 'lg',
        },
        contentProps: {
          title,
          calls,
          startAt: this.props.startAt,
          endAt: this.props.endAt,
        },
        actions: [
          {
            children: 'Fermer',
            color: 'secondary',
            onClick: (r, close) => close(),
          },
        ],
      })
    );

  render() {
    const { statsCalls, loading } = this.state;
    const {
      openInter,
      // openUniques,
      classes,
      xs,
      exclude,
      CA = 0,
      withEmoji = true,
    } = this.props;
    const stats = constant({ statsCalls, CA, openInter });
    const display =
      withEmoji &&
      statsCalls &&
      statsCalls.uniqueCalls &&
      getEmoji(statsCalls.uniqueCalls ? CA / statsCalls.uniqueCalls : 0);
    return loading ? (
      <Grid item xs={12} className={classes.loadingGrid}>
        <LinearProgress />
      </Grid>
    ) : (
      <Grid container className={classes.container}>
        {stats
          .filter((e) => !exclude.includes(e.title))
          .map((e) => (
            <Grid item xs={xs || 6} className={classes.container}>
              <Paper
                className={classes.statsAppels}
                style={{ background: e.color }}
                onClick={() => {
                  if (openInter && e.handleOpen) {
                    return openInter(e.title);
                  }
                  if (e.openUniques) {
                    this.openUniques(e.openUniques, e.title);
                  }
                  return null;
                }}
              >
                <Typography className={classes.titleStats}>
                  {e.title}
                </Typography>
                <Typography className={classes.stats}>
                  {e.stats || 0}
                </Typography>
              </Paper>
            </Grid>
          ))}
        {display
          ? [
            <Grid container spacing={2}>
              <Grid item xs={1}></Grid>
              <Grid item xs={4} className={classes.emoji}>
                {display.component}
              </Grid>
              <Grid item xs={7} className={classes.score}>
                <Typography variant="h6" children={display.title} />
                <Typography
                  variant="subtitle2"
                  children={display.sentence
                    .split('\n')
                    .map((c) => [c, <br />])}
                  className={classes.lineHeight}
                />
              </Grid>
            </Grid>,
          ]
          : ''}
      </Grid>
    );
  }
}

export default withStyles(styles)(CallChips);
