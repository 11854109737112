import { data as SAVStatus } from '@bit/dev-lba.lib.local-globals/SAVStatus';
import {
  data as SAVSubStatus
} from '@bit/dev-lba.lib.local-globals/SAVSubStatus';

import {
  formatBoolean,
  formatDate,
  formatPaymentMethod,
  formatPrice,
  formatProducts,
  formatSupplies,
} from './formatters';
import collections from '../../constants/collections';
import { genChip } from '../../utils/chips';

export const emptyUndefined = (obj) => {
  Object.keys(obj).forEach((k) => obj[k] === undefined && delete obj[k]);
  return obj;
};

export const interventionsSchema = ({
  date: { envoi, intervention } = {},
  client: {
    name,
    firstname,
    society,
    civility,
    email1,
    email2,
    tel1,
    tel2,
    tel3,
    address: { number, road, zipcode, city } = {},
    building,
    code,
    compl,
    floor,
  } = {},
  announcedPrice,
  finalPrice,
  supplies,
  products: { tva, list } = {},
} = {}) =>
  emptyUndefined({
    'Date d\'intervention': formatDate(intervention, true),
    'Date d\'envoi': formatDate(envoi, true),
    Civilité: civility,
    'Nom du client': name,
    'Prénom du client': firstname,
    'Nom de société du client': society,
    'Email 1 du client': email1,
    'Email 2 du client': email2,
    'Téléphone 1 du client': tel1,
    'Téléphone 2 du client': tel2,
    'Téléphone 3 du client': tel3,
    'Numéro de voie': number,
    Voie: road,
    'Code postal': zipcode,
    Ville: city,
    Bâtiment: building,
    Code: code,
    Étage: floor,
    'Complément d\'adresse': compl,
    'Prix annoncé': formatPrice(announcedPrice),
    'Prix final': formatPrice(finalPrice),
    Produits: formatProducts(list),
    'TVA appliquée': tva && `${tva}%`,
    Fournitures: formatSupplies(supplies),
  });

export const savInterventionsSchema = (item = {}) => {
  const {
    status,
    date: { savDate, noContact } = {},
    infoDescSAV: {
      SAVFact,
      SAVFactPrice,
      SAVRemun,
      SAVRemunPrice,
      consignes,
      desc,
      paymentMethod,
    } = {},
  } = item;

  return emptyUndefined({
    ...interventionsSchema(item),
    Statut: genChip(SAVStatus.find((s) => s._id === status)),
    'Sous-statut': genChip(SAVSubStatus.find((s) => s._id === status)),
    'SAV facturé': formatBoolean(SAVFact),
    'Prix de facturation SAV': formatPrice(SAVFactPrice),
    'SAV rémunéré': formatBoolean(SAVRemun),
    'Prix de rémunération SAV': formatPrice(SAVRemunPrice),
    'Description SAV': desc,
    'Consignes SAV': consignes,
    'Date SAV': formatDate(savDate, true),
    'Date d\'impossibilité de contact': formatDate(noContact, true),
    'Méthode de paiement': formatPaymentMethod(paymentMethod),
  });
};

export const schemas = {
  [collections.interventions]: interventionsSchema,
  [collections.savInterventions]: savInterventionsSchema,
};
