import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { ImageList, ImageListItem } from '@material-ui/core';
import { List } from 'immutable';

const getImages = (merge) => {
  if (merge){
    const images = (merge.getIn(['elem', 'obj', 'images'],
      new List()) || new List()).map(e => ({
      photo: e,
      caption: merge.getIn(['elem', 'obj', 'title'], ''),
      subcaption:
        `${( merge.getIn([
          'elem', 'obj', 'description'], '') || '' ).substring(0, 100)} ...`,
      thumbnail: e
    })).toJS();
    if ((merge.getIn(['elem', 'obj', 'img'], undefined) || undefined )){
      images.unshift(
        {
          photo: merge.getIn(['elem', 'obj', 'img']),
          caption: merge.getIn(['elem', 'obj', 'title'], ''),
          subcaption:
            `${( merge.getIn([
              'elem', 'obj', 'description'], '') || '' )
              .substring(0, 100)} ...`,
          thumbnail: merge.getIn(['elem', 'obj', 'img'])
        });
    }
    return images;
  }
};


const mapStateToProps = ({
  dialog: { contentProps: { merge } }
}) => ({ merge });


const styles = () => ({
  textStyle: {
    alignSelf: 'center',
    textAlign: 'center'
  },
  padding: {
    paddingTop: 10
  },
  group: {
    display: 'block',
    textAlign: 'center'
  }
});

const ImageListDialog = ({ merge }) => (
  <ImageList cols={1} rowHeight="auto">
    {getImages(merge).map((item) => (
      <ImageListItem key={item.photo}>
        <img src={item.photo} alt={item.caption} />
      </ImageListItem>
    ))}
  </ImageList>

);

export default compose(
  connect(mapStateToProps),
  withStyles(styles)
)(ImageListDialog);
