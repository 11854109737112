import React from 'react';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';

import CustomSelect from '../../../../CustomInputs/CustomSelect';
import { moment } from '@bit/dev-lba.lib.local-globals/moment';
import { Typography } from '@material-ui/core';

const years = (() => {
  const start = moment();
  const dates = [];
  for (let index = 0; index < 10; index++) {
    dates.push(moment(start).add(index, 'year').year());
  }
  return dates;
})();

const months = (() => [...Array(12)].map((e, i) => i))();

const paymentsModes = [
  {
    _id: 2,
    verif: () => false,
  },
  {
    _id: 3,
    verif: ({ encaissement, decaissement, type }) =>
      type === 'interventions' && ((encaissement - decaissement) > 0),
  },
];

export default ({ handleChange, date, mode, ...props }) => (
  <Grid container spacing={1} alignItems="center">
    <Grid item xs={12}>
      <Typography variant="subtitle2">Date de carte bancaire</Typography>
    </Grid>
    <CustomSelect
      grid
      xs={6}
      label={'Mois'}
      value={date ? moment(date).month() : null}
      path={['date']}
      setData={(p, v) =>
        handleChange('date')(
          moment(date || new Date())
            .month(v)
            .toDate()
        )
      }
    >
      {months.map((reason, index) => (
        <MenuItem key={index} value={reason}>
          {reason + 1}
        </MenuItem>
      ))}
    </CustomSelect>
    <CustomSelect
      grid
      xs={6}
      label={'Année'}
      value={date ? moment(date).year() : ''}
      path={['date']}
      setData={(p, v) =>
        handleChange('date')(
          moment(date || new Date())
            .year(v)
            .toDate()
        )
      }
    >
      {years.map((reason, index) => (
        <MenuItem key={index} value={reason}>
          {reason}
        </MenuItem>
      ))}
    </CustomSelect>
    <Grid item xs={12}>
      <Typography variant="subtitle2">Mode de paiement</Typography>
    </Grid>
    <CustomSelect
      grid
      xs={6}
      value={mode}
      path={['mode']}
      setData={(p, v) => handleChange('mode')(v)}
    >
      {paymentsModes.map((e) => (
        <MenuItem key={e} value={e._id} disabled={e.verif(props)}>
          {e._id}x
        </MenuItem>
      ))}
    </CustomSelect>
  </Grid>
);
