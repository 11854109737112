import React from 'react';
import {
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@material-ui/core';
import { getLoginFromPhone } from '../../../utils/function';
import AudioPlayer from '../../Calls/AudioPlayer';
import { moment } from '@bit/dev-lba.lib.local-globals/moment';

const DetailPanel = ({ rowData, classes }) => (
  <Grid>
    <Grid lg={12}>
      <Table className={classes.uniquesAudios}>
        <TableHead>
          <TableRow>
            {['Heure', 'Agent', 'Audio']
              .map(title => <TableCell key={title} children={title} />)}
          </TableRow>
        </TableHead>
        <TableBody>
          {rowData.records.map(call =>
            <TableRow>
              <TableCell key={'callStart'} >
                {moment(call.callStart).format('HH[h]mm')}
              </TableCell>
              <TableCell key={'agent'}>
                {getLoginFromPhone(call.agent
                  .replace(/^0033/, '0'))}
              </TableCell>
              <TableCell
                key={'urlAudio'}
                style={{
                  minWidth: 410,
                  maxWidth: 410,
                  width: 410
                }}
              >
                <AudioPlayer
                  url={call.fileUrl}
                  noPadding hide
                />
              </TableCell>
            </TableRow>)
          }
        </TableBody>
      </Table>
    </Grid>
  </Grid>
);


export default DetailPanel;
