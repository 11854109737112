import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { moment } from '@bit/dev-lba.lib.local-globals/moment';
import { getIconFromMessage } from '../utils';
import { Avatar } from '@material-ui/core';
import SMSTableHead from './head';
import SMSTableToolbar from './toolbar';
import styles from '../styles';
import ImagePreview from '../image/ImagePreview';
import { data as smsMode } from '@bit/dev-lba.lib.local-globals/smsMode';
import links from '../../../../constants/links';

class SMSTable extends React.Component {
  state = {
    checked: false,
    imageOpen: false,
  };

  toggleChecked = () => this.setState(({ checked }) => ({ checked: !checked }));

  MessageView = ({ text, _id, numberOfImg, fileName }) => (
    <TableCell component="th" scope="row">
      {text && (
        <p>{text}</p>
      )}
      {fileName && new Array(numberOfImg || 1)
        .fill(0).map((_, index) =>
          <a
            href={`${links.origin}/api/` +
            `imageFromMms?mmsId=${_id}&index=${index}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={`${links.origin}/api/` +
              `imageFromMms?mmsId=${_id}&index=${index}`}
              alt="mms"
              style={{ height: '50px' }}
              onClick={() => this.openImage(_id, index)}
            />
          </a>
        )}
    </TableCell>
  );

  openImage = (_id, index) => {
    this.setState({ imageOpen: true, _id, index });
  };

  handleCloseImage = () => {
    this.setState({ imageOpen: false });
  };

  render() {
    const {
      classes,
      selectTypes,
      types,
      sms,
      onChangePage,
      onChangeRowsPerPage,
      rowsPerPage,
      page,
      size,
      dashboard,
    } = this.props;
    const { order, orderBy, checked, imageOpen, _id, index } = this.state;

    return (
      <React.Fragment>
        <ImagePreview
          open={imageOpen}
          handleClose={this.handleCloseImage}
          _id={_id}
          index={index}
        />
        {!dashboard && (
          <SMSTableToolbar
            selected={types}
            checked={checked}
            toggleChecked={this.toggleChecked}
            selectMode={selectTypes}
          />
        )}
        <div className={classes.tableWrapper}>
          <Table className={classes.table}>
            <SMSTableHead
              order={order}
              orderBy={orderBy}
              onSelectAllClick={this.handleSelectAllClick}
              rowCount={size}
            />
            <TableBody>
              {sms &&
                sms
                  .sort((a, b) => b.date - a.date)
                  .map((n) => {
                    const counter = getIconFromMessage(n);
                    return (
                      <TableRow key={n.id}>
                        <TableCell>
                          <Avatar
                            className={classes.tableAvatar}
                            style={{ backgroundColor: counter.color }}
                          >
                            <counter.Icon className={classes.tableIconStyle} />
                          </Avatar>
                        </TableCell>
                        <TableCell>
                          {smsMode.find((e) => e._id === n.mode)?.name}
                        </TableCell>
                        <TableCell>{n.transmitter || n.from}</TableCell>
                        <TableCell>{n.recipient || n.to}</TableCell>
                        <TableCell>
                          {moment(n.date).format('DD/MM/YYYY HH:mm')}
                        </TableCell>
                        <this.MessageView
                          text={n.text}
                          _id={n._id}
                          numberOfImg={n.numberOfImg}
                          fileName={n.fileName}
                          data={n.data}
                          classes={classes}
                        />
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          component="div"
          count={size}
          rowsPerPage={rowsPerPage}
          page={page}
          rowsPerPageOptions={[10, 25, 50, 100]}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          labelRowsPerPage={'SMS par page'}
        />
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(SMSTable);
