import React, { PureComponent } from 'react';
import { moment } from '@bit/dev-lba.lib.local-globals/moment';
import { CircularProgress, MenuItem, Paper, Select } from '@material-ui/core';
import { red, orange, grey, brown } from '@material-ui/core/colors';
import { permit } from '@bit/dev-lba.lib.local-globals/restrictions';
import EchartsWithTotal from '../../Utils/EchartsWithTotal';

import { connect } from 'react-redux';

import api from '../../../api';
import { getOption } from '../common/graph/options/globalOptions';
import StatsPanel from '../common/panel/StatsPanel';
import Dropdown from '../common/dropdown/dropdown';
import { withDisplaying } from '../../../hoc';
import { compose } from 'redux';
import { I_VRF, I_AVR } from '@bit/dev-lba.lib.local-globals/iStatus';
import { setDialog } from '../../../actions/dialog';
import { modes, modeIndex } from '@bit/dev-lba.lib.local-globals/stats';
import {
  formatterEchartLabel
} from '../ServicesStats/DisplayRecouvrementRatio';

const mapStateToProps = ({ users, userId }) => {
  const user = users.find((e) => e._id === userId);
  return {
    users,
    user,
    isAdmin: permit(user),
  };
};

const datesOptions = [
  {
    _id: 'date.ajout',
    name: 'Ajout',
  },
  {
    _id: 'date.intervention',
    name: 'Intervention',
  },
  {
    _id: 'date.verif',
    name: 'Verif',
  },
];

const filters = {
  'Non reçu jaune': {
    isRegle: false,
    noPaymentReceived: true,
    status: I_AVR
  },
  'Non reçu gris': {
    isRegle: false,
    isProforma: true,
    status: I_VRF
  },
  'Non reçu sur place': {
    isRegle: false,
    noPaymentReceived: { $ne: true },
    onSitePayment: true,
    status: I_AVR
  },
  'Non reçu a distance': {
    isRegle: false,
    isProforma: { $ne: true },
    onSitePayment: false,
    status: I_VRF
  },
  'Total a vérifier': {
    noPaymentReceived: { $ne: true },
    status: I_AVR
  },
};

class GlobalStats extends PureComponent {
  constructor() {
    super();
    this.state = {
      mode: 'day',
      from: moment().startOf('day').toDate(),
      to: moment().endOf('day').toDate(),
      date: 'date.ajout',
      showMode: 'CA',
      loading: true,
    };
  }

  componentDidMount() {
    this.doRequests();
  }

  handleChange = (name, cb = (f) => f) => (e) => {
    this.setState({ [name]: e.target.value }, cb);
  };

  onDatesChange = (from, to, mode) => {
    this.setState({ from, to, mode, loading: true }, this.doRequests);
  };

  doRequests = () => {
    const { mode, from, to, showMode, date } = this.state;
    const { isAdmin, isCaldeo } = this.props;
    api.stats
      .get(isCaldeo ?
        'recouvrement/globalCA' :
        'comptabilite/globalCA', {
        mode,
        isCA: showMode === 'CA' ? 1 : 0,
        from,
        to,
        date,
      })
      .then((result) => {
        const { data, details } = result.body().data();
        this.setState({
          data: isAdmin
            ? data
            : Object.keys(data)
              .filter((key) => !key.includes('Reçu'))
              .reduce(
                (obj, key) => ({
                  ...obj,
                  [key]: data[key],
                }),
                {}
              ),
          details,
          loading: false,
        });
      });
  };

  handleDialogOpen = (name) => ({ dataIndex, seriesName }) => {
    const { mode, date, from } = this.state;
    const { dispatch } = this.props;
    let typeMode = modes[mode];
    let index = Object.keys(modeIndex[mode]).find(
      (e) => modeIndex[mode][e] === dataIndex
    );
    let filter = filters[seriesName];
    if (name) {
      filter = {
        $and: [
          filters[seriesName]
        ],
      };
    }
    if ((typeMode === 'day' && mode === 'week') || typeMode === 'month') {
      index = index - 1;
    } else if (typeMode === 'day') {
      typeMode = 'date';
    }
    const data = {
      ...filter,
      [date]: {
        $gt: moment(from).set(typeMode, index).startOf(typeMode).toDate(),
        $lt: moment(from).set(typeMode, index).endOf(typeMode).toDate(),
      },
    };
    return dispatch(
      setDialog({
        name: 'InterListDialog',
        open: true,
        hideClose: true,
        dialogProps: {
          title: 'Liste d\'intervention',
          maxWidth: 'lg',
        },
        contentProps: {
          filter: data,
        },
        actions: [
          {
            children: 'Fermer',
            color: 'secondary',
            onClick: (r, close) => close(),
          },
        ],
      })
    );
  };

  changeArray = (field) => (e) => {
    let data = [...this.state[field]];
    const value = e.target.value[1];
    if (data.includes(value)) {
      data = data.filter((u) => u !== value);
    } else {
      data.push(value);
    }
    this.setState({ [field]: data });
  };

  render() {
    const {
      data,
      from,
      to,
      mode,
      showMode,
      loading,
      date,
    } = this.state;

    const dates = [moment(from).startOf(mode), moment(to).endOf(mode)];
    const { isAdmin } = this.props;
    return (
      <Paper style={{ minHeight: 700 }}>
        <StatsPanel
          title={'Statistiques d\'interventions non recu'}
          onDatesChange={this.onDatesChange}
          enabledModes={[true, true, true, true, true]}
          defaultState={{
            mode,
            from,
            to,
          }}
          body={
            <React.Fragment>
              <Select
                style={{ marginRight: '1rem' }}
                value={showMode}
                onChange={this.handleChange('showMode', this.doRequests)}
              >
                <MenuItem value={'CA'}>Chiffre affaire</MenuItem>
                <MenuItem value={'NBR'}>Nombre</MenuItem>
              </Select>
              <Dropdown
                title={'Dates'}
                data={datesOptions}
                isSelected={(e) => date === e}
                onChange={this.handleChange('date', this.doRequests)}
                multiple={false}
              />
            </React.Fragment>
          }
        >
          {loading && (
            <form style={{ textAlign: 'center' }}>
              <CircularProgress style={{ margin: '100px' }} />
            </form>
          )}
          {data && !loading ? (
            <EchartsWithTotal
              enabledModes={[true, true, true, true, true]}
              notMerge={true}
              style={{ width: '100%' }}
              option={getOption({
                ...(isAdmin && {
                  formatter: (values) =>
                    formatterEchartLabel({
                      values,
                      data,
                      ratioKey: () => 'Total',
                      keysRationToDisplay: [
                        'Non reçu jaune',
                        'Non reçu gris',
                        'Non reçu sur place',
                        'Non reçu a distance',
                        'Total a vérifier',
                      ],
                    }),
                }),
                data: data,
                mode,
                labels: Object.keys(data),
                dates,
                colors: [
                  orange[500], grey[400], red[500], grey[700], brown[500]
                ],
              })}
              onEvents={{ click: this.handleDialogOpen(false) }}
              keysToDisplay={[
                'Non reçu jaune',
                'Non reçu gris',
                'Non reçu sur place',
                'Non reçu a distance',
                'Total a vérifier',
              ]}
              keyRatio={false}
            />
          ) : null}
        </StatsPanel>
      </Paper>
    );
  }
}

GlobalStats.displayName = 'GlobalStats';

export default compose(withDisplaying(), connect(mapStateToProps))(GlobalStats);
