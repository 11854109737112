/* eslint-disable max-lines */
import React, { PureComponent } from 'react';
import ReactEcharts from 'echarts-for-react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { moment } from '@bit/dev-lba.lib.local-globals/moment';
import api from '../../../../api';
import { S_JUR } from '@bit/dev-lba.lib.local-globals/services';
import { withDisplaying } from '../../../../hoc';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { CircularProgress, MenuItem, Select, Grid } from '@material-ui/core';
import { L_CLI } from '@bit/dev-lba.lib.local-globals/recStatus';
import { getOption } from '../../common/graph/options/globalOptions';
import StatsPanel from '../../common/panel/StatsPanel';
import Dropdown from '../../common/dropdown/dropdown';
import CardItem from './cardItem';
import { setDialog } from '../../../../actions/dialog';
import filters from './filters';

const styles = () => ({
  title: {
    fontSize: 17,
    fontWeight: 'bold',
  },
  paper: {
    width: '80%',
    textAlign: 'center',
    padding: '1%',
    margin: 'auto',
    borderRadius: '0px 15px',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  number: {
    fontSize: 17,
  },
});

const mapStateToProps = ({ users, userId }) => ({
  users,
  userId,
});

const datesOptions = [
  {
    _id: 'date.ajout',
    name: 'Ajout',
  },
  {
    _id: 'date.intervention',
    name: 'Intervention',
  },
  {
    _id: 'date.verif',
    name: 'Verif',
  },
  {
    _id: 'date.recouvrement',
    name: 'Prise en charge',
  },
];

const showModes = {
  CA: 'Chiffre d\'affaire',
  NBR: 'Nombres',
};

class LitigesStats extends PureComponent {
  constructor(props) {
    super(props);
    const users = props.users
      .filter((e) => e.act)
      .map(({ _id, login, service }) => ({ _id, login, service }));
    this.state = {
      mode: 'year',
      from: moment().startOf('year').toDate(),
      to: moment().endOf('year').toDate(),
      date: 'date.recouvrement',
      showMode: 'NBR',
      displayType: 24,
      loading: true,
      user: users.find((e) => e._id === props.userId),
      users
    };
  }

  componentDidMount() {
    this.reqAllStats();
  }

  reqAllStats = () => {
    const { from, to, mode, showMode, date, user, displayType } = this.state;
    api.stats
      .get('recouvrement/litigeCounters', {
        mode,
        isCA: showMode === 'CA' ? 1 : 0,
        from,
        to,
        date,
        service: L_CLI,
        user: user._id,
        displayType,
      })
      .then((res) => this.setState(res.body().data()))
      .finally(() => this.setState({ loading: false }));
  };

  onModeChange = (mode) => {
    this.setState({ mode });
  };

  la = (name, cb = (f) => f) => (e) => {
    this.setState({ [name]: e.target.value }, cb);
  };

  onDatesChange = (from, to, mode) => {
    this.setState({ from, to, mode, loading: true }, this.reqAllStats);
  };

  handleDialogOpen = (name, dataIndex) => {
    const { dispatch } = this.props;
    return dispatch(
      setDialog({
        name: 'InterListDialog',
        open: true,
        hideClose: true,
        dialogProps: {
          title: 'Liste d\'intervention',
          maxWidth: 'xl',
        },
        contentProps: {
          filter: filters(this.state, dataIndex)[name]
        },
        actions: [
          {
            children: 'Fermer',
            color: 'secondary',
            onClick: (r, close) => close(),
          },
        ],
      })
    );
  };

  handleChange = (name, cb = (f) => f) => (e) => {
    this.setState({ [name]: e.target.value }, cb);
  };

  render() {
    const { classes } = this.props;
    const {
      from,
      counters,
      to,
      mode,
      showMode,
      displayType,
      loading,
      date,
      user,
      graphic,
      users
    } = this.state;
    const dates = [moment(from).startOf(mode), moment(to).endOf(mode)];
    return (
      <div>
        <StatsPanel
          title={`Statistiques pôle litige - ${showModes[showMode]}`}
          onDatesChange={this.onDatesChange}
          defaultState={{
            mode,
            from,
            to,
          }}
          body={
            <React.Fragment>
              <Select
                style={{ marginRight: '1rem' }}
                value={showMode}
                onChange={this.handleChange('showMode', this.reqAllStats)}
              >
                <MenuItem value={'CA'}>Chiffre d'affaire</MenuItem>
                <MenuItem value={'NBR'}>Nombres</MenuItem>
              </Select>
              <Dropdown
                title={`Date ${datesOptions.find((e) => e._id === date).name}`}
                data={datesOptions}
                isSelected={(e) => date === e}
                onChange={this.handleChange('date', this.reqAllStats)}
                multiple={false}
              />
              <Dropdown
                title={user.login}
                data={[
                  { login: 'All' },
                  ...users.filter((e) => e.service === S_JUR),
                ]}
                idKey={null}
                renderKey={'login'}
                isSelected={(e) => user === e}
                onChange={this.handleChange('user', this.reqAllStats)}
                multiple={false}
              />
            </React.Fragment>
          }
        >
          {loading && (
            <form style={{ textAlign: 'center' }}>
              <CircularProgress style={{ margin: '100px' }} />
            </form>
          )}
          <Grid container spacing={4}>
            {counters &&
              Object.keys(counters)
                .sort()
                .map((e, i) => (
                  <CardItem
                    isCA={showMode === 'CA' ? 1 : 0}
                    handleDialogOpen={this.handleDialogOpen}
                    handleChange={this.handleChange}
                    reqAllStats={this.reqAllStats}
                    mode={mode}
                    key={i}
                    name={e.split('-')[1]}
                    number={counters[e]}
                    displayType={displayType}
                    classes={classes}
                  />
                ))}
            {graphic && !loading && (
              <Grid item xs={12}>
                <ReactEcharts
                  notMerge={true}
                  style={{ width: '100%', marginTop: '3rem' }}
                  option={getOption({
                    title: 'Litiges résolus',
                    data: graphic,
                    mode,
                    labels: Object.keys(graphic),
                    dates,
                  })}
                  onEvents={{
                    click: ({ seriesName, dataIndex }) =>
                      this.handleDialogOpen(seriesName, dataIndex),
                  }}
                />
              </Grid>
            )}
          </Grid>
        </StatsPanel>
      </div>
    );
  }
}

LitigesStats.propTypes = {
  classes: PropTypes.object.isRequired,
};

LitigesStats.displayName = 'LitigesStats';

export default compose(
  withDisplaying(),
  connect(mapStateToProps),
  withStyles(styles)
)(LitigesStats);
