import React from 'react';
import {
  moment,
  formatDate,
} from '@bit/dev-lba.lib.local-globals/moment';
import { connect } from 'react-redux';

import TextField from '@material-ui/core/TextField';

import { setDialogContentProp } from '../../actions/dialog';

const setDate = setDialogContentProp('date');

const mapStateToProps = ({
  dialog: {
    contentProps: {
      date = new Date(),
    },
  },
  userId
}) => ({
  userId,
  date,
});


const mapDispatchToProps = {
  setDate: setDate,
};

const ReminderDialog = ({ date, setDate }) => (
  <TextField
    id="datetime-local"
    label=""
    value={formatDate(date, moment.HTML5_FMT.DATETIME_LOCAL)}
    type="datetime-local"
    InputLabelProps={{ shrink: true }}
    onChange={(e) => setDate(e.target.value)}
  />
);

export default connect(mapStateToProps, mapDispatchToProps)(ReminderDialog);
