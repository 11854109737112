import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { Button, Select, MenuItem, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { moment } from '@bit/dev-lba.lib.local-globals/moment';
import { List } from 'immutable';

import {
  S_INT,
  S_DEVIS,
  S_INFO,
  S_SATCL,
  S_PART,
  S_LEAD,
  data as serviceData,
} from '@bit/dev-lba.lib.local-globals/services';
import { ADMIN } from '@bit/dev-lba.lib.local-globals/permissions';

import Objectifs from '../../../components/Stats/Objectifs';
import {
  objectifsMap,
  objectifEn,
} from '../../../components/Stats/Objectifs/objectifData';
import ChangePanel from '../../../components/Stats/Objectifs/popup/change';
import api from '../../../api';
import notifSystem from '../../../notifSystem';
import { getEquipes } from '../../../utils/function';
import { permit } from '@bit/dev-lba.lib.local-globals/restrictions';

const path = {
  [S_DEVIS]: 'support/objectifs',
  [S_INT]: 'commercial/objectifs',
  [S_LEAD]: 'commercial/objectifs',
  [S_PART]: 'partenariat/objectifs',
};

const mapStateToProps = (state) => ({
  grandCompte: state.grandCompte,
});

const styles = {
  gridStyle: {
    height: '100%',
  },
  extraComp: {
    width: '45%',
  },
};
class ObjectifsContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      service: props.service || S_INT,
      route: props.route || 'commercial',
      commissions: [],
      mode: 'day',
      from: moment().startOf('day').toDate(),
      to: moment().endOf('day').toDate(),
      selectUser: (props.user || {})._id,
    };
  }
  componentDidMount() {
    if (this.props.grandCompte.size) {
      this.doRequests();
    }
  }

  handleOpen = (open, forceUpdate) => {
    this.setState({ panelOpen: open });

    if (forceUpdate) {
      this.doRequests();
    }
  };

  selectUser = (user) => {
    this.setState(
      { selectedUser: user.target ? user.target.value : user },
      this.doRequests
    );
  };

  onChange = (service) => {
    const { allUsers } = this.props;

    let users = allUsers.filter((u) => u.act && u.service === service);
    if (List.isList(users)) {
      users = users.toJS();
    }

    const sData = serviceData.find((s) => s._id === service);

    this.setState(
      {
        service,
        route: sData ? sData.name.toLowerCase() : '',
        selectedUser: users[0]._id,
      },
      this.doRequests
    );
  };

  getFields = (en = false) => {
    const { forceService } = this.props;
    const { service } = this.state;
    const objectifMap = objectifsMap[forceService || service] || {};
    const objectif = objectifMap.objectif || [];
    if (en) {
      return objectif.map((e) => objectifEn[e.titles[0]]);
    }
    return objectif.map((e) => e.titles[0]);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.grandCompte !== this.props.grandCompte) {
      this.doRequests();
    }
  }

  doRequests = () => {
    this.setState({ loading: true });
    const { service, selectedUser, from, to } = this.state;
    const { user, forceService, grandCompte = [], allUsers = [] } = this.props;
    const userSelected = allUsers.find((s) => s._id === selectedUser) || user;
    api.stats
      .get(path[service], {
        user: selectedUser || user._id,
        service: forceService === S_SATCL ? forceService : service,
        fields: this.getFields(true),
        from,
        to,
        ...(userSelected.wihoutGrandCompteCA && {
          grandCompte: grandCompte.reduce(
            (acc, curr) =>
              curr.get('rating') ? acc.concat(curr.get('_id')) : acc,
            []
          ),
        }),
      })
      .then((response) => {
        const data = response.body().data();
        this.setState({ percents: [], ...data, loading: false });
      })
      .catch(() =>
        notifSystem.error('Erreur', 'Impossible de charger les objectifs')
      );
  };

  onDatesChange = (from, to, mode) =>
    this.setState({ from, to, mode }, this.doRequests);

  render() {
    const {
      global,
      CA,
      selectedUser,
      loading,
      panelOpen,
      goals,
      service,
      percents,
      from,
      mode,
      to,
    } = this.state;
    const { user, personal, allUsers, forceService, classes, noGlobal } =
      this.props;
    const sData = serviceData.find((s) => s._id === service);

    const isAdmin = user.permission === ADMIN;
    const isCommOrInf = [S_INT, S_INFO, S_PART, S_LEAD].includes(user.service);
    const gridSize = !noGlobal && personal ? 6 : 12;
    const equipe = getEquipes({ user, users: allUsers });
    return (
      <Grid container spacing={2} className={classes.gridStyle}>
        <ChangePanel
          open={panelOpen}
          handleOpen={this.handleOpen}
          dataKey={sData ? sData.goalsKey : ''}
          goals={goals || {}}
          user={user}
          fields={this.getFields()}
        />
        {personal && (
          <Grid item md={noGlobal ? 12 : 6} lg={noGlobal ? 12 : 6}>
            <Objectifs
              CA={CA}
              percents={percents}
              user={user}
              selectUser={this.selectUser}
              selectedUser={selectedUser}
              personal={personal}
              users={allUsers}
              service={forceService || service}
              canChange={isAdmin && isCommOrInf}
              onChange={this.onChange}
              onDatesChange={this.onDatesChange}
              loading={loading}
              isAdmin={isAdmin}
              from={from}
              mode={mode}
              to={to}
              ExtraComponent={
                allUsers &&
                isCommOrInf &&
                equipe.length > 0 && (
                  <Select
                    value={selectedUser}
                    className={classes.extraComp}
                    onChange={this.selectUser}
                  >
                    {equipe.map((d) => (
                      <MenuItem key={d._id} value={d._id}>
                        {' '}
                        {d.login}
                      </MenuItem>
                    ))}
                  </Select>
                )
              }
            />
          </Grid>
        )}
        {!noGlobal && (
          <Grid
            container
            direction="row"
            spacing={1}
            item
            md={gridSize}
            lg={gridSize}
          >
            <Grid item md={12} lg={12}>
              <Objectifs
                percents={global}
                loading={loading}
                users={allUsers}
                service={forceService || service}
                ExtraComponent={
                  permit(user, { key: 'changeGoals' }) && (
                    <Button
                      variant="outlined"
                      onClick={() => this.handleOpen(true)}
                    >
                      MODIFIER
                    </Button>
                  )
                }
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  }
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps)
)(ObjectifsContainer);
