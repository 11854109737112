/* eslint-disable max-lines */
import React from 'react';
import RecoveryIcon from '../components/Recouvrement/RecoveryIcon';
import SAVIcon from '../components/SAVComponents/SAVIcon';
import constants from '../constants/collections';

import { R_NO } from '@bit/dev-lba.lib.local-globals/recStatus';
import { O_STAR, O_TOP } from '@bit/dev-lba.lib.local-globals/aOptions';
import { CB, ESP } from '@bit/dev-lba.lib.local-globals/paymentMethods';
import amber from '@material-ui/core/colors/amber';
import orange from '@material-ui/core/colors/orange';
import blue from '@material-ui/core/colors/blue';
import red from '@material-ui/core/colors/red';
import blueGrey from '@material-ui/core/colors/blueGrey';
import Tooltip from '@material-ui/core/Tooltip';
import { data as formData } from '@bit/dev-lba.lib.local-globals/formProsp';
import Badge from '@material-ui/core/Badge';
import {
  AccountBalance,
  Call,
  CreditCard,
  CallEnd,
  Edit,
  Domain,
  RemoveRedEye,
  Textsms,
  MailOutline,
  AddAlert,
  AssignmentTurnedIn,
  Assignment,
  ArrowUpward,
  VerifiedUser,
  TouchApp,
  BusinessCenter,
  Sms,
  EventAvailable,
  Visibility,
  RingVolume,
  Euro,
  Comment,
  Star,
  NotificationImportant,
  Phone,
  MonetizationOn,
  Brightness1,
  Cancel,
  CheckCircle,
  Info,
  WorkOutline,
} from '@material-ui/icons';
import Checkbox from '@material-ui/icons/CheckBox';

import green from '@material-ui/core/colors/green';
import grey from '@material-ui/core/colors/grey';
import Typography from '@material-ui/core/Typography';
import { R_FOR, R_POR } from '@bit/dev-lba.lib.local-globals/remStatus';
import {
  L_PAYMULTI,
  L_PAYDIF,
} from '@bit/dev-lba.lib.local-globals/paymentCBActionTypes';
import {
  data as subStatusDevisData
} from '@bit/dev-lba.lib.local-globals/dSubStatusRecouvrement';
import { Avatar, Grid } from '@material-ui/core';
import { D_AR } from '@bit/dev-lba.lib.local-globals/demandeStatus';
import { MIN_DURATION } from '@bit/dev-lba.lib.local-globals/minDuration';
import {
  getPointsDevisCommission,
  reasonsCommissionDevis,
} from '@bit/dev-lba.lib.local-globals/commissions';
import { I_ENC, I_AVR } from '@bit/dev-lba.lib.local-globals/iStatus';
import { displayMetierInList } from '../actions/metiersSteps';
import { REPONDEUR } from '@bit/dev-lba.lib.local-globals/callTypes';

const getSupportUser = (users, loginSupport) =>
  users
    .filter((u) => u.act && u.supportIds.includes(loginSupport))
    .map((u) => u.login)
    .join(' / ') || 'Support';

export const GenTooltipIcon = ({
  Icon,
  text,
  color,
  colorProps,
  fontSize = 20,
  colorNumber = 500,
  ...props
}) => (
  <Tooltip
    title={<span>{text || ''}</span>}
    children={
      <Icon
        {...props}
        color={colorProps}
        style={{ color: color[colorNumber], fontSize }}
      />
    }
  />
);
export const IconsDisplay = {
  recovery: (props) =>
    props.listen !== constants.recouvrements &&
    props.elem.recovery &&
    props.elem.recovery.status !== R_NO && (
      <RecoveryIcon recovery={props.elem.recovery || {}} key={'RecoveryIcon'} />
    ),
  priorityFollow: (props) =>
    props.elem.priorityFollow && (
      <GenTooltipIcon
        Icon={NotificationImportant}
        color={orange}
        text={'Suivi prioritaire'}
        key={'Suivi prioritaire'}
      />
    ),
  star: (props) =>
    (props.elem.favoris || (props.elem.option || []).includes(O_STAR)) && (
      <GenTooltipIcon Icon={Star} text={'Star'} color={amber} key={'Star'} />
    ),
  currentSAVStatus: (props) =>
    (props.elem?.currentSAVStatus || props.elem?.login?.sav) && (
      <SAVIcon
        statusSAV={props.elem.currentSAVStatus}
        userSAV={props.elem?.login?.sav}
        key={'SAVIcon'}
      />
    ),
  candidats: (props) =>
    constants.candidats === props.listen && [
      !!(props.elem.comments || []).length && (
        <GenTooltipIcon
          Icon={Comment}
          text={'Commentaires'}
          color={blue}
          key={'candidatComments'}
        />
      ),
      props.elem.artisanId && (
        <GenTooltipIcon
          Icon={Visibility}
          text={'Voir l\'artisan'}
          color={red}
          onClick={() => window.open(`/artisan/${props.elem.artisanId}`)}
          key={'candidatVisibility'}
        />
      ),
    ],
  topOfList: (props) =>
    (props.elem.favoris || (props.elem.option || []).includes(O_TOP)) && (
      <GenTooltipIcon
        Icon={ArrowUpward}
        color={blueGrey}
        text={'Tête-de-liste'}
        key={'Tête-de-liste'}
      />
    ),
  formData: (props) =>
    !!props.elem.aDemarcher &&
    props.elem.formRp && (
      <div>
        {' '}
        {Object.keys(props.elem.formRp).map((key) => {
          const myForm = formData.find((e) => e.field === key);
          return (
            !!props.elem.formRp[key] && (
              <GenTooltipIcon
                key={key}
                Icon={Badge}
                text={myForm.text}
                colorProps={'secondary'}
                color={red}
                badgeContent={props.elem.formRp[key]}
                fontSize={20}
                onClick={() =>
                  window.open(
                    `/prosp/list/reponse?interSearch=${props.elem._id}`
                  )
                }
                children={
                  <Sms
                    style={{ fontSize: 20, color: myForm.color[500] }}
                    key={key}
                  />
                }
              />
            )
          );
        })}
      </div>
    ),
  sawForm: (props) =>
    !!props.elem.aDemarcher &&
    props.elem.seenByProsp && (
      <GenTooltipIcon
        key={'Vu par prospect'}
        Icon={Badge}
        text={'Vu par prospect'}
        color={red}
        colorProps={'secondary'}
        fontSize={20}
        badgeContent={props.elem.seenByProsp.length}
        onClick={() =>
          window.open(`/prosp/list/vue?searchKey=${props.elem._id}`)
        }
        children={
          <RemoveRedEye
            style={{ fontSize: 20 }}
            color="primary"
            key={'RemoveRedEyeIcon'}
          />
        }
      />
    ),
  supportIcon: (props) =>
    props.elem.login &&
    props.elem.login.support &&
    !props.elem.login.deleteSupport && (
      <GenTooltipIcon
        Icon={TouchApp}
        text={getSupportUser(props.users, props.elem.login.support)}
        color={blue}
        key={'TouchApp'}
      />
    ),
  caldeoIcon: (props) =>
    !props?.elem?.domofinance?.valid &&
    props?.elem?.isCaldeo && (
      <GenTooltipIcon
        Icon={Domain}
        text={'caldeo'}
        color={red}
        key={'caldeo'}
      />
    ),
  domofinanceIcon: (props) =>
    props?.elem?.domofinance?.valid && (
      <GenTooltipIcon
        Icon={AccountBalance}
        text={props?.elem?.isCaldeo ? 'Domofinance & caldeo' : 'Domofinance'}
        color={props?.elem?.isCaldeo ? red : blue}
        key={'domofinance'}
      />
    ),
  supportMajIcon: (props) =>
    props.elem.login &&
    [I_ENC, I_AVR].includes(props.elem.status) &&
    props.elem.login.majSupport && (
      <GenTooltipIcon
        Icon={BusinessCenter}
        text={'Support (mise à jour)'}
        color={blueGrey}
        key={'BusinessCenter'}
      />
    ),
};

export const cbPayment = (props) =>
  ({
    [CB]:
      !props.elem.transaction ||
      !props.elem.date ||
      ![L_PAYMULTI, L_PAYDIF].includes(props.elem.transaction.type) ? (
          <GenTooltipIcon
            Icon={CreditCard}
            colorNumber={500}
            text={
              props.elem.date.envoiLydia
                ? 'Paiement cb envoyé'
                : 'Paiment cb non envoyé'
            }
            color={props.elem.date.envoiLydia ? green : red}
            key={'Carte bancaire'}
          />
        ) : (
          {
            [L_PAYDIF]: (
              <GenTooltipIcon
                Icon={EventAvailable}
                colorNumber={900}
                text={'Paiement différé'}
                color={grey}
                key={'Paiement différé'}
              />
            ),
            [L_PAYMULTI]: (
              <GenTooltipIcon
                Icon={() => (
                  <Avatar className={props.classes.smallAvatar}>3x</Avatar>
                )}
                colorNumber={900}
                text={'Paiement en plusieurs fois'}
                color={grey}
                key={'Paiement en plusieurs fois'}
              />
            ),
          }[props.elem.transaction.type]
        ),
    [ESP]: (
      <GenTooltipIcon
        Icon={MonetizationOn}
        colorNumber={900}
        text={'Paiement en espèces'}
        color={grey}
        key={'Paiement en espèces'}
      />
    ),
  }[props.elem.paymentMethod]);

export const devisIcon = (props) => {
  let devisIconColor =
    props.elem.login && props.elem.login.artisan ? green : blue;
  let devisIconColorNumber =
    props.elem.login && props.elem.login.artisan ? 500 : 700;
  let devisIconTooltip =
    props.elem.login && props.elem.login.artisan
      ? 'Devis artisan'
      : 'Devis transféré';

  return (
    props.elem.login &&
    (props.elem.login.artisan || props.elem.login.devis) && (
      <GenTooltipIcon
        Icon={VerifiedUser}
        key={'devisIcon'}
        text={devisIconTooltip}
        color={devisIconColor}
        colorNumber={devisIconColorNumber}
      />
    )
  );
};

export const subStatusDevisIcon = (props) => {
  const subStatusDevis =
    props.elem &&
    props.elem.recovery &&
    props.elem.recovery.subStatusDevis &&
    subStatusDevisData
      .find((e) =>
        e.values.find(
          (value) => value._id === props.elem.recovery.subStatusDevis
        )
      )
      .values.find((elem) => elem._id === props.elem.recovery.subStatusDevis);

  let iconColor = subStatusDevis && subStatusDevis.color;
  let iconTooltip = subStatusDevis && subStatusDevis.name;

  return (
    props.elem.date &&
    props.elem.recovery &&
    props.elem.recovery.subStatusDevis &&
    props.elem.date.recouvrement && (
      <GenTooltipIcon
        Icon={Assignment}
        key={'devisIcon'}
        text={iconTooltip}
        color={iconColor}
        colorNumber={500}
      />
    )
  );
};

export const validInfosIcon = (props, infoArtisans, infoClients) =>
  props.elem &&
  props.elem.clientListInfo &&
  props.elem.artisanListInfo &&
  infoArtisans.length === props.elem.artisanListInfo.length &&
  infoClients.length === props.elem.clientListInfo.length && (
    <GenTooltipIcon
      Icon={AssignmentTurnedIn}
      text={'Information validée'}
      key={'Information validée'}
      color={green}
      colorNumber={500}
    />
  );

export const editIcon = (props) =>
  props.elem.measurePerformedPayment && (
    <GenTooltipIcon
      Icon={Edit}
      color={'primary'}
      text={'Prise de côte validée'}
      key={'Prise de côte validée'}
    />
  );

export const mailOutlineIcon = (props, i) =>
  'onSitePayment' in props.elem &&
  !props.elem.onSitePayment && (
    <div style={{ textAlign: 'right' }} key={props.tableData[i].key}>
      <MailOutline style={{ fontSize: 20 }} />
    </div>
  );

export const mailOutlinePaddingIcon = (props, i) =>
  'onSitePayment' in props.elem &&
  !props.elem.onSitePayment && (
    <div style={{ textAlign: 'right' }} key={props.tableData[i].key}>
      <MailOutline style={{ width: '0.8em', paddingLeft: 5 }} />
    </div>
  );

const BadgeIcon = ({ elem: { login, communcations = [], appel } }) => {
  const noAudio =
    !login.miseEnRelation &&
    !appel &&
    (!communcations ||
      (communcations && !communcations
        .some(e => e.duration > MIN_DURATION && e.type !== REPONDEUR)));
  if (!noAudio && communcations.every((s) => 'played' in s)) {
    const audios = communcations
      .filter(e => e.duration > MIN_DURATION && e.type !== REPONDEUR);
    if (audios.length && audios.every((e) => e.played)) {
      return <CheckCircle fontSize="small" style={{ color: green[500] }} />;
    }
    return <Cancel fontSize="small" style={{ color: red[500] }} />;
  }
  return '';
};

export const CallIcon = (props) => (
  <Grid container align="center">
    <Grid item xs={12}>
      <Badge badgeContent={<BadgeIcon elem={props.elem} />}>
        <GenTooltipIcon
          key={'GenTooltipIcon'}
          Icon={props.elem.login.miseEnRelation ? RingVolume : Phone}
          text={
            props.elem.login.miseEnRelation ? 'Mise en relation' : 'Appel'
          }
          className={props.classes.icon}
          color={
            props.elem.appel === true &&
            !props.elem.communcations
              .every(e => e.duration < MIN_DURATION || e.type === REPONDEUR)
              ? green
              : red
          }
        />
      </Badge>
    </Grid>
  </Grid>
);

export const SupplieProgressIcon = ({ elem = {}, classes, list = [] }) => {
  const item = list[elem.supplieProgress - 1];
  return item ? (
    <GenTooltipIcon
      key="supplieProgress"
      Icon={Brightness1}
      className={classes.icon}
      fontSize={25}
      color={item.color}
      text={item.name}
    />
  ) : null;
};

export const remunerationIcons = {
  [R_POR]: (remuneration) => (
    <GenTooltipIcon
      Icon={(props) => (
        <Typography {...props}>{remuneration?.pourcentage?.labor} %</Typography>
      )}
      text={'Porcentage'}
      color={blue}
      fontSize={15}
      key={'Porcentage'}
    />
  ),
  [R_FOR]: () => (
    <GenTooltipIcon
      Icon={Euro}
      text={'Forfait'}
      color={red}
      fontSize={15}
      key={'Forfait'}
    />
  ),
};

export const forfaitIcon = (props) =>
  props.elem.remunerationArtisan &&
  props.elem.remunerationArtisan.status === R_FOR &&
  remunerationIcons[R_FOR]();

export const demandesIcons = (props) => {
  const icons = [];
  if (props.elem.comments.length) {
    icons.push(
      <GenTooltipIcon
        Icon={Comment}
        color={'primary'}
        text={'Commentaires'}
        key={'Commentaires'}
      />
    );
  }
  if (props.elem.messages.length) {
    icons.push(
      <GenTooltipIcon
        Icon={Textsms}
        color={'primary'}
        text={'Conversation SMS'}
        key={'Conversation SMS'}
      />
    );
  }
  if (props.elem.called) {
    icons.push(
      <GenTooltipIcon
        Icon={Call}
        color={'primary'}
        text={'Client appelé'}
        key={'Client appelé'}
      />
    );
  }
  if (props.elem.groups && props.elem.groups.length) {
    icons.push(
      <GenTooltipIcon
        Icon={Badge}
        badgeContent={props.elem.groups.length}
        color={'primary'}
        text={'Client appelé par des commerciaux'}
        key={'Client appelé par des commerciaux'}
        children={<CallEnd key={'CallEndIcon'} />}
      />
    );
  }
  if (
    props.elem.status === D_AR &&
    props.elem.login &&
    props.elem.login.reminder
  ) {
    icons.push(
      <GenTooltipIcon
        Icon={AddAlert}
        color={'primary'}
        text={'Rappel programmé'}
        key={'Rappel programmé'}
      />
    );
  }
  return icons;
};
const findPoint = (points, index) => points.find((e) => e._id === index);

export const SupportPoints = ({ elem }) => {
  const points = getPointsDevisCommission(elem);
  return [...Array(5)].map((_, i) => (
    <Tooltip key={`supportPoints-${i}`} title={reasonsCommissionDevis[i + 1]}>
      <Checkbox
        style={{ color: findPoint(points, i + 1) ? green[500] : red[500] }}
      />
    </Tooltip>
  ));
};

export const metiersStepsCounter = (props) => {
  const answers = Object.keys(
    (props.elem.metiersSteps || {}).answers || {}
  ).length;
  const totalQuestions = (props.elem.metiersSteps || {}).totalQuestions || 0;
  const color = answers === totalQuestions ? green : red;
  return (
    <GenTooltipIcon
      Icon={Info}
      text={`${answers}/${totalQuestions}`}
      color={totalQuestions ? color : grey}
      key={'metiersStepsCounter'}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        displayMetierInList(props.elem.metiersSteps || {});
      }}
    />
  );
};

export const grandCompteIcon = (props) => <GenTooltipIcon
  Icon={WorkOutline}
  color={'primary'}
  text={props.elem.billing.companyName}
  key={'grandCompteIcon'}
/>;
