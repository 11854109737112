import React, { Fragment } from 'react';
import { TableCell, TableHead, TableRow } from '@material-ui/core';
import { CheckBox } from '@material-ui/icons';
import { green, red } from '@material-ui/core/colors';
import { MTableBody } from '@material-table/core';

import {
  pricesCommissionDevis,
} from '@bit/dev-lba.lib.local-globals/commissions';

const calculateCommission = (id, nbComm) => `${
  (nbComm ? nbComm * (pricesCommissionDevis[id] || 0) : 0)
    .toFixed(2)} €`;

const PointsCases = ({ index }) => [...Array(5)].map((_, i) => (
  <CheckBox
    style={{ color: i < index ? green[500] : red[500] }}
  />
));

export default ({ totals, openIntersDialog }) => ({
  Header: (props) => <TableHead>
    <TableRow>
      {props.columns.map(e =>
        <TableCell
          align={e.align || ''}
          children={e.title}
          colSpan={e.colSpan || null}
        />
      )}
    </TableRow>
  </TableHead>,
  Row: ({ data }) =>
    <TableRow>
      <TableCell>
        <PointsCases index={data._id} />
      </TableCell>
      {['pot', 'recu'].map((e, i) => <React.Fragment key={i}>
        <TableCell
          align='center'
          children={data[e].nb}
          style={{ cursor: 'pointer' }}
          onClick={() => openIntersDialog(data[e].inters)}
        />
        <TableCell
          align='center'
          style={{ cursor: 'pointer' }}
          children={calculateCommission(data._id, data[e].nb)}
          onClick={() => openIntersDialog(data[e].inters)}
        />
      </React.Fragment>)}
    </TableRow>,
  Body: (props) => <>
        <MTableBody {...props} />
        <TableHead>
          <TableRow>
            <TableCell children={'Total'} />
            {['pot', 'recu'].map((e, i) => <React.Fragment key={i}>
              <TableCell
                align='center' children={totals[e].nb}
                style={{ cursor: 'pointer' }}
                onClick={() => openIntersDialog(totals[e].inters)}
              />
              <TableCell align='center'
                style={{ cursor: 'pointer' }}
                onClick={() => openIntersDialog(totals[e].inters)}
              >
                {(totals[e].price).toFixed(2)} €
              </TableCell>
            </React.Fragment>)}
          </TableRow>
        </TableHead>
      </>
});
