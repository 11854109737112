import React from 'react';
import { moment } from '@bit/dev-lba.lib.local-globals/moment';

import { Grid, Typography } from '@material-ui/core';

import {
  getKeysConclusionsByType,
  getObjConclusionsByType,
} from '@bit/dev-lba.lib.local-globals/rConclusions';

import { getLogin } from '../../../utils/function';
import InterMenuInfo from '../Intervention/InterMenuInfo';

const RecouvrementInfo = ({ recouvrement, classes, elem }) => {
  if (!recouvrement) {
    return null;
  }
  const {
    priseEnCharge,
    montantEncaisse,
    historique = [],
    type,
  } = recouvrement;
  const formatData = [
    {
      date: elem.date.recouvrement,
      user: getLogin(elem.login.recouvrement),
      type:
        priseEnCharge &&
        `Montant prise en charge : ${
          priseEnCharge.montant || 'Le montant n\'est pas defini'
        }`,
    },
    {
      date: elem.date.priseDeContactRecouvrement,
      user: getLogin(elem.login.priseDeContactRecouvrement),
      type:
        montantEncaisse &&
        `Montant encaissé : ${
          montantEncaisse || 'Le montant encaissé n\'est pas defini'
        }`,
    },
    {
      date: elem.date.relanceDate,
      user: getLogin(elem.login.priseDeContactRecouvrement),
      type: 'Dossier relancé',
    },
    ...historique.map(({ userId, date, conclusions = [], comments }) => ({
      user: getLogin(userId),
      date: date,
      type: comments,
      message: conclusions.length
        ? `Conclustions : \n ${conclusions.map((c, i) => {
          const [key, obj] = [
            getKeysConclusionsByType(type),
            getObjConclusionsByType(type),
          ];
          const newKey = key.find((o) => obj[o].some((t) => t._id === c));
          return `${i + 1} : ${obj[newKey].find((t) => t._id === c).name}`;
        })}`
        : '',
    })),
  ];
  return (
    <Grid item xs={12} md={4}>
      <Typography variant="h6" className={classes.title}>
        Commentaires juridique
      </Typography>
      <div className={classes.timeLineLimit}>
        {formatData.sort((a, b) =>
          moment(a.date) - moment(b.date)).map(
          (data) => data.type && data.date ?
            <InterMenuInfo
              {...data}
              date={moment(data.date).format('LLL')}
            /> : ''
        )}
      </div>
    </Grid>
  );
};

export default RecouvrementInfo;
