import React, { PureComponent } from 'react';
import { ChevronRight } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  rowTransition: {
    transition: 'transform .2s'
  }
};

class CollapseArrow extends PureComponent {
  render() {
    const { open, classes } = this.props;
    return (
      <ChevronRight className={classes.rowTransition}
        style={{ transform: `rotate(${open ? 90 : 0}deg)` }}
      />
    );
  }
}

export default withStyles(styles)(CollapseArrow);
