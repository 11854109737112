import { S_RH } from '@bit/dev-lba.lib.local-globals/services';

import { getLogin } from '../../utils/function';
import { data as statusData } from '@bit/dev-lba.lib.local-globals/hStatus';
import { calcBusinessDays } from '@bit/dev-lba.lib.local-globals/moment';

export function filterHolidayUsers(
  users,
  currentUser,
  updateUser = currentUser,
) {
  let userList = [];
  if (currentUser.service === S_RH) {
    userList = users;
  }
  else {
    userList = users.filter((u) => u.service === currentUser.service);
  }
  userList = userList.filter(u => u._id !== updateUser._id);
  return [updateUser, ...userList]
    .filter((u) => u.holidays && u.holidays.length)
    .reduce((v, u) => {
      u.holidays.forEach((holiday) => {
        const statusHoliday = statusData.find(
          (e) => e._id === holiday.status
        );
        if (statusHoliday) {
          v.push({
            status: holiday.status,
            user: u._id,
            start: new Date(holiday.from),
            end: new Date(holiday.to),
            color: statusHoliday.color[500],
            title: `Conges de ${getLogin(u._id)} pour 
              ${calcBusinessDays(holiday.from, holiday.to)} jours | 
              ${statusHoliday.name}`,
            ...holiday,
          });
        }
      });
      return v;
    }, [])
    .sort((a, b) => new Date(b.creation) - new Date(a.creation));
}
