import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Map } from 'immutable';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import { Clear } from '@material-ui/icons';
import Chip from '@material-ui/core/Chip';
import {
  data,
  GRAND_COMPTE,
  PROPRIETAIRE,
  SOCIETE
} from '@bit/dev-lba.lib.local-globals/statusClient';
import { RE_1 } from '@bit/dev-lba.lib.local-globals/grandCompteConstants';
import Civility from './Civility';
import CustomSelect from '../CustomInputs/CustomSelect';
import SearchBtoB from './SearchBtoB';
const { permit } = require('@bit/dev-lba.lib.local-globals/restrictions');

const mapStateToProps = ({ grandCompte, users, userId }) => ({
  comptes: grandCompte,
  user: users.find(e => e._id === userId)
});

const paths = {
  clientStatus: ['clientStatus'],
  tva: ['products', 'tva']
};

const styles = theme => ({
  text: {
    marginLeft: '10px'
  },
  paper: {
    position: 'absolute',
    top: '35%',
    filter: 'brightness(96%)',
    padding: '0 9px'
  },
  button: {
    color: 'red',
    height: '35px',
  },
  buttonPlace: {
    display: 'flex',
    alignSelf: 'center',
    justifyContent: 'flex-start'
  },
  searchBtoB: {
    [theme.breakpoints.down('sm')]: {
      height: 80,
      minWidth: '12.5em'
    }
  },
  infoGrandCompte: {
    alignItems: 'center',
    display: 'flex'
  }
});

const keys = [
  'companyName',
  'address',
  'grandCompte',
  'civility',
  'name',
  'firstname',
  'email1',
  'email2',
  'tel1',
  'tel2',
  'tel3'
];

const StatusClient = ({
  parentSetData,
  setData,
  comptes,
  client,
  clientStatus,
  grandCompte,
  copyInfoClient,
  civility,
  user,
  classes
}) => {

  const setGrandCompte = selected => {
    const tva = selected.get('tva');

    if (tva || tva === 0) {
      parentSetData(paths.tva, selected.get('tva'));
    }
    parentSetData('remindFacture',
      selected.get('remindFacture', selected.remindDelay === RE_1));
    keys.forEach(e =>
      setData([e], selected.get(e === 'grandCompte' ? '_id' : e,
        e === 'address' ? {
          city: '',
          number: '',
          road: '',
          zipcode: '',
          location: {
            type: '',
            coordinates: []
          }
        } : ''))
    );
    ['noCgv', 'noLettreRelance'].forEach(e =>
      setData([e], selected.get(e, false))
    );
    if (selected.get('id')) {
      setData(['grandCompteId'], selected.get('id'));
    }
    setData(['ratingEnable'], !!selected.get('rating'));
  };

  const grandCompteInfo = () => {
    const current = comptes.find((e) => e.get('_id') === grandCompte);

    return !!current &&
      <Chip
        icon={<Clear/>}
        label={current.get('companyName', current.get('name', ''))}
        onDelete={() => setGrandCompte(new Map())}
        variant="outlined"
      />;
  };
  const setDataClient = () => {
    setData(['name'], client.get('name', ''));
    setData(['firstname'], client.get('firstname', ''));
    setData(['tel1'], client.get('tel1', ''));
    setData(['tel2'], client.get('tel2', ''));
    setData(['tel3'], client.get('tel3', ''));
    setData(['email1'], client.get('email1', ''));
    setData(['email2'], client.get('email2', ''));
    setData(['address'], client.get('address', {}));
    if (client.get('civility', '') === 'Société') {
      setData(['clientStatus'], SOCIETE);
      setData(['companyName'], client.get('society', ''));
    } else {
      setData(['clientStatus'], PROPRIETAIRE);
      setData(['civility'], client.get('civility', ''));
    }
  };

  return (
    <Grid container spacing={3}>
      <CustomSelect
        grid
        xs={12}
        sm={6}
        md={3}
        label="Status Client"
        value={clientStatus}
        path={paths.clientStatus}
        setData={setData}
      >
        {data.map((e, i) =>
          <MenuItem
            key={i}
            value={e._id}
            children={e.name}
          />
        )}
      </CustomSelect>
      {clientStatus === GRAND_COMPTE &&
        <Grid item xs={12} sm={6} md={3} className={classes.searchBtoB}>
          <SearchBtoB
            setGrandCompte={setGrandCompte}
            filterB2B={e => e.get('enabled')}
          />
        </Grid>
      }
      {clientStatus === PROPRIETAIRE &&
        <Civility
          civility={civility}
          setData={setData}
        />
      }
      {!!grandCompte &&
        <Grid item xs={12} sm className={classes.infoGrandCompte}>
          {grandCompteInfo()}
        </Grid>
      }
      {copyInfoClient &&
      (permit(user, { key: 'duplicateClientInfo' }) ||
        client.get('civility') === 'Société') &&
        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          className={classes.buttonPlace}
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={setDataClient}
            children="Dupliquer les informations du client"
          />
        </Grid>}
    </Grid>
  );
};

StatusClient.propTypes = {
  clientStatus: PropTypes.number.isRequired,
  setData: PropTypes.func.isRequired,
  setAddress: PropTypes.func
};

StatusClient.defaultProps = {
  clientStatus: '',
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps)
)(StatusClient);
