import React, { PureComponent } from 'react';
import { compose } from 'recompose';
import CollapseArrow from '../../CollapseArrow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

const styles = {
  toggle: {
    width: 30,
    height: 30,
  }
};

class ToogleDesc extends PureComponent {
  render() {
    const { open, toogle, classes, width } = this.props;
    return (
      <TableCell
        padding="checkbox"
      >
        <IconButton onClick={toogle}
          className={isWidthDown('sm', width) ? classes.toggle : null}>
          <CollapseArrow open={open} />
        </IconButton>
      </TableCell>
    );
  }
}

export default compose(
  withStyles(styles),
  withWidth(),
)(ToogleDesc);
