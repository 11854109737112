import React, { PureComponent } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';

const style = {
  content: {
    textAlign: 'center'
  },
};

export default class ActionConfirmBox extends PureComponent {
  state = {
    visible: true
  }

  handleClick = (confirm) => {
    this.setState({ visible: false });
    if (typeof this.props.callback === 'function') {
      if (this.props.obj) {
        this.props.callback(confirm || false, this.props.obj);
      } else {
        this.props.callback(confirm || false);
      }
    }
    return false;
  }

  render() {
    const {
      maxWidth,
      defaultText
    } = this.props;
    const {
      title,
      subtitle,
      confirmBox = 'Oui',
      closeBox = 'Annuler'
    } = defaultText;
    return (
      <div>
        <Dialog
          onEscapeKeyDown={this.handleClick}
          open={this.state.visible}
          maxWidth={maxWidth}
        >
          <DialogContent style={style.content}>
            <DialogTitle children={title} />
            {!!subtitle &&
              subtitle.split('\n').map(e => <Typography children={e} />)}
          </DialogContent>
          <DialogActions>
            {closeBox && (
              <Button
                onClick={() => this.handleClick()}
                color="secondary"
                children={closeBox}
              />
            )}
            {confirmBox && (
              <Button
                onClick={() => this.handleClick(true)}
                color="primary"
                children={confirmBox}
              />
            )}
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

ActionConfirmBox.defaultProps = {
  maxWidth: 'sm',
  defaultText: {
    title: 'Etes-vous sûr ?',
    confirmBox: 'Oui',
    closeBox: 'Annuler'
  }
};
