import React from 'react';
import {
  Card,
  Typography,
  CardContent,
  LinearProgress,
} from '@material-ui/core';
import {
  grey,
  yellow,
  deepOrange,
  red,
  brown,
  blue,
} from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import { aVerifierPoint } from '../../../utils/pointsFunctions';
import { connect } from 'react-redux';
import classNames from 'classnames';

const styles = {
  linearProgress: {
    margin: 7,
    height: 25,
    backgroundColor: grey[200],
  },
  brownDeterminate: {
    background: brown[400],
  },
  blueDeterminate: {
    background: blue[400],
  },
  yellowDeterminate: {
    background: yellow[400],
  },
  redDeterminate: {
    background: red[400],
  },
  orangeDeterminate: {
    backgound: deepOrange[400],
  },
  card: {
    height: '100%',
  }
};

const mapStateToProps = ({ users, userId, points }) => ({
  users,
  userId,
  points,
});

const AverifCounter = ({ classes, users, userId, points }) => {
  const { point, maxV } = aVerifierPoint(users, userId, points);
  const getColor = (value) => {
    if (value > 30) {
      return classes.blueDeterminate;
    }
    if (value > 50) {
      return classes.yellowDeterminate;
    }
    if (value > 70) {
      return classes.orangeDeterminate;
    }
    if (value > 100) {
      return classes.redDeterminate;
    }
    return classes.redDeterminate;
  };
  const pourcentage = point ? 100 - Math.trunc((point / maxV) * 100) : 0;
  return (
    <Card className={classNames(classes.card, 'cards')}>
      <CardContent>
        <Typography variant="subtitle1" align={'left'}>
          Il vous reste {maxV - point} points ({pourcentage}%)
        </Typography>
        <LinearProgress
          classes={{ bar1Determinate: getColor(pourcentage) }}
          className={classes.linearProgress}
          variant="determinate"
          value={pourcentage > 100 ? 100 : pourcentage || 0}
        />
      </CardContent>
    </Card>
  );
};

export default connect(mapStateToProps)(withStyles(styles)(AverifCounter));
