import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import React from 'react';
import {
  permit
} from '@bit/dev-lba.lib.local-globals/restrictions';
import { S_INT, S_LEAD } from '@bit/dev-lba.lib.local-globals/services';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';

import { setManagerSignalement } from '../../actions/signalements';
import CommentList from '../List/CommentList';

const styles = {
  chips: {
    textAlign: 'center',
  },
  chip: {
    margin: '1%'
  },
  text: {
    margin: '1%'
  }
};

export const Description = ({ user, elem, reloadCurrent, users }) => [
  <Typography key="Description" style={styles.text} variant="h6">
    Description :
  </Typography>,
  <Typography key="DescriptionValue" align="center">
    {elem.description}
  </Typography>,
  !!elem.managedBy && (
    <div key="infos">
      <Typography style={styles.text} variant="h6">
        Pris en charge par:
      </Typography>
      <Select
        style={styles.text}
        disabled={!user || !permit(user, { key: 'selectReportManager' })}
        value={elem.managedBy._id}
        onChange={(e) => {
          setManagerSignalement(elem._id, e.target.value).then(() =>
            reloadCurrent(elem._id)
          );
        }}
      >
        {users
          .filter((u) => u.act && ![S_INT, S_LEAD].includes(u.service))
          .map((d, i) => (
            <MenuItem key={i} value={d._id}>
              {d.login}
            </MenuItem>
          ))}
      </Select>
    </div>
  ),
  !!elem.solvedBy && [
    <Typography key="regle" style={styles.text} variant="h6">
      Réglé par:
    </Typography>,
    <Typography key="regleValue" style={styles.text} align="center">
      {elem.solvedBy.name || ''}
    </Typography>,
  ],
];

export const Comments = ({ elem }) => [
  <Typography key='comments' style={styles.text} variant='h6'>
  Commentaires:
  </Typography>,
  <CommentList comments={elem.comments} />
];

export const Recipients = ({ elem }) => [
  <Typography key='destinataires' style={styles.text} variant='h6'>
        Destinataires
  </Typography>,
  <div key='destinatairesValues' style={styles.chips}>
    {elem.recipients.map(({ name }, i) =>
      <Chip style={styles.chip} label={name} key={i} />
    )}
  </div>
];

