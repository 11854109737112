import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import getDataById from '@bit/dev-lba.lib.local-globals/getDataById';
import { CompTER, CompRPROG, CompANN } from './components/';
import styles from './styles';
import MenuHeader from './MenuHeader';
import { compose } from 'recompose';
import { withDisplaying } from '../../../hoc';
import { P_NONE } from '@bit/dev-lba.lib.local-globals/isValidStatus';

const components = [CompTER, CompRPROG, CompANN];

const PropositionMenu = ({
  elem, classes, setProposition, restrictions
}) => {
  const proposition = elem.proposition || {};
  const status = getDataById['propositionInter'][proposition.status] || {};
  const statusColor = status.color && status.color['500'];

  const setData = (p, v) => {
    setProposition({ ...proposition, [p]: v });
  };
  return (
    <React.Fragment>
      <Grid container spacing={4} className={classes.padding}>
        <MenuHeader
          statusColor={statusColor}
          elem={elem}
          status={status}
        />
        {status._id && proposition &&
          components[status._id - 1](elem, classes, {
            setData,
            restrictions,
            showInput: !!setProposition && proposition.isValid === P_NONE
          })}
      </Grid>
    </React.Fragment>
  );
};

export default compose(
  withStyles(styles),
  withDisplaying()
)(PropositionMenu);
