/* eslint-disable max-lines */
import React, { PureComponent } from 'react';
import ReactEcharts from 'echarts-for-react';
import { moment } from '@bit/dev-lba.lib.local-globals/moment';
import api from '../../../api';

import { withDisplaying } from '../../../hoc';
import { compose } from 'redux';
import { connect } from 'react-redux';

import {
  CircularProgress,
  MenuItem,
  Select,
  Grid,
} from '@material-ui/core';
import {
  red,
  green,
  blue,
  purple,
  deepPurple,
  cyan,
  teal,
  yellow
} from '@material-ui/core/colors';

import {
  R_CLI,
  L_CLI,
  R_BTOB,
} from '@bit/dev-lba.lib.local-globals/recStatus';
import { S_BTOB, S_JUR } from '@bit/dev-lba.lib.local-globals/services';
import { getOption } from '../common/graph/options/globalOptions';
import StatsPanel from '../common/panel/StatsPanel';
import Dropdown from '../common/dropdown/dropdown';
import { setDialog } from '../../../actions/dialog';
import {
  R_RES,
  R_PER,
  R_DATAS as rStatus,
} from '@bit/dev-lba.lib.local-globals/rStatus';
import {
  data as subStatusDevisData,
} from '@bit/dev-lba.lib.local-globals/dSubStatusRecouvrement';
import {
  data as clientCauses,
  b2b as btobCauses,
} from '@bit/dev-lba.lib.local-globals/rCauses';
import {
  CauseData as litigeCauses,
  CatData as litigeCategory,
  L_CAUSE_AVEC_REGL,
} from '@bit/dev-lba.lib.local-globals/lCauses';
import { modeIndex, modes } from '@bit/dev-lba.lib.local-globals/stats';

const causes = {
  [R_CLI]: clientCauses,
  [R_BTOB]: btobCauses,
  [L_CLI]: litigeCauses,
};

const res = rStatus.find(e => e._id === R_RES);
const perte = rStatus.find(e => e._id === R_PER);

const mapStateToProps = ({ users, userId }) => ({
  users,
  userId,
});

const datesOptions = [
  {
    _id: 'date.ajout',
    name: 'Ajout',
  },
  {
    _id: 'date.intervention',
    name: 'Intervention',
  },
  {
    _id: 'date.verif',
    name: 'Verif',
  },
  {
    _id: 'date.recouvrement',
    name: 'Prise en charge',
  },
];

const showModes = {
  CA: 'Chiffre d\'affaire',
  NBR: 'Nombres',
};

const resolution = (causeResolved) => {
  if (causeResolved === 'resolved') {
    return { 'recovery.recStatus': res._id };
  } else if (causeResolved === 'unresolved') {
    return { 'recovery.recStatus': { $nin: [res._id, perte._id] } };
  }
  return { 'recovery.recStatus': perte._id };
};

const recoveryCauseCond = (cause, causeResolved) =>
  cause.reduce((acc, cur) => {
    acc[cur.name] = { $and: [
      { 'recovery.cause': cur._id },
      resolution(causeResolved)
    ] };
    return acc;
  }, {});

const recoverySubStatusDevisCond = (data) =>
  data.reduce((acc, cur) => {
    cur.values.map(value => {
      acc[value.name] = { $and: [
        { 'recovery.subStatusDevis': value._id },
      ] };
    });
    return acc;
  }, []);

const recoverySubStatusDevisUser = (data, subStatus, user) =>
  data.reduce((acc, cur) => {
    const curStatus = [cur].find(e => e.type === subStatus);
    if (curStatus) {
      acc[user.login] = { $and: [
        { 'login.recouvrement': user._id },
        { 'recovery.subStatusDevis':
          { $in: curStatus.values.map(e => e._id) }
        },
      ],
      };
    }
    return acc;
  }, []);

const recoveryCategoryLitige = (causeLitige, causeResolved) =>
  litigeCategory
    .filter(e => e.for.includes(causeLitige))
    .reduce((acc, cur) => {
      acc[cur.name] = { $and: [
        { 'recovery.category': cur._id },
        resolution(causeResolved)
      ] };
      return acc;
    }, {});

const filters = ({ recType, user, causeResolved, causeLitige }) => ({
  'Pris en charge': {
    'login.recouvrement': { $exists: true },
  },
  'Non pris en charge': {
    'login.recouvrement': { $exists: false },
  },
  [user.login]: {
    'login.recouvrement': user._id
  },
  [res.name]: {
    'recovery.recStatus': res._id,
  },
  [perte.name]: {
    'recovery.recStatus': perte._id
  },
  'Non résolu': {
    $and: [
      { 'recovery.recStatus': { $ne: res._id } },
      { 'recovery.recStatus': { $ne: perte._id } },
    ],
  },
  ...recoveryCauseCond(causes[recType], causeResolved),
  ...recoveryCategoryLitige(causeLitige, causeResolved),
});

const subStatusFilter = ({ user, subStatus }) => ({
  ...recoverySubStatusDevisUser(subStatusDevisData, subStatus, user ),
  ...recoverySubStatusDevisCond(subStatusDevisData)
});

class RecouvStats extends PureComponent {
  constructor(props) {
    super(props);
    const users = props.users.filter(e => e.act)
      .map(({ _id, login, service }) => ({ _id, login, service }));
    this.state = {
      mode: 'year',
      from: moment().startOf('year').toDate(),
      to: moment().endOf('year').toDate(),
      date: 'date.ajout',
      showMode: 'NBR',
      loading: true,
      repartitionCA: new Array(3).fill({}),
      recType: R_CLI,
      users,
      user: users.find(e => e._id === props.userId),
      causeResolved: 'resolved',
      causeLitige: L_CAUSE_AVEC_REGL,
      subStatus: subStatusDevisData[0].type
    };
  }

  componentDidMount() {
    this.reqAllStats();
  }

  reqRecStats = () => {
    const {
      from,
      to,
      mode,
      recType,
      date,
      showMode,
      user,
      causeResolved,
    } = this.state;
    this.setState({
      loading: true,
    });

    api.stats.get('recouvrement/recouvrStats', {
      mode,
      from,
      to,
      date,
      recType,
      isCA: showMode === 'CA' ? '1' : '0',
      user,
      causeResolved,
    }).then((result) => {
      const { pec, status, cause } = result.body().data();
      this.setState({
        pec,
        cause,
        status,
        loading: false,
      });
    });
  };

  subStatusRecStats = () => {
    const {
      from,
      to,
      mode,
      date,
      showMode,
      recType,
      user,
      subStatus,
    } = this.state;
    this.setState({
      loading: true,
    });

    api.stats.get('recouvrement/subStatusRecStats', {
      mode,
      from,
      to,
      date,
      isCA: showMode === 'CA' ? '1' : '0',
      recType,
      user,
      subStatus,
    }).then((result) => {
      const { data } = result.body().data();
      this.setState({
        loading: false,
        subStatusData: data
      });
    });
  };

  reqRecStatsDetails = () => {
    const {
      from,
      to,
      mode,
      date,
      showMode,
      causeResolved,
      causeLitige,
    } = this.state;
    this.setState({
      loading: true,
    });

    api.stats
      .get('recouvrement/recouvrStatsDetails',
        {
          mode,
          from,
          to,
          date,
          isCA: showMode === 'CA' ? 1 : 0,
          causeResolved,
          causeLitige,
        })
      .then((result) => {
        const { detailedCause } = result.body().data();
        this.setState({
          detailedCause,
          loading: false,
        });
      });
  };

  reqAllStats = () => {
    this.reqRecStats();
    this.subStatusRecStats();
  }

  onModeChange = (mode) => {
    this.setState({ mode });
  };

  handleChange = (name, cb = (f) => f) => (e) => {
    this.setState({ [name]: e.target.value }, cb);
  };

  onDatesChange = (from, to, mode) => {
    this.setState({ from, to, mode, loading: true }, this.reqAllStats);
  };

  onTypeChange = (e) => {
    this.handleChange('recType', this.reqAllStats)(e);
    if (e.target.value === L_CLI) {
      this.reqRecStatsDetails();
    }
  }

  handleDialogOpen = (hasSubStatusFilter) => ({ dataIndex, seriesName }) => {
    const {
      mode,
      date,
      recType,
      user,
      causeResolved,
      causeLitige,
      from,
      subStatus
    } = this.state;
    const { dispatch } = this.props;
    let typeMode = modes[mode];
    let index = Object.keys(modeIndex[mode]).find((e) =>
      modeIndex[mode][e] === dataIndex);
    let filter;
    if (hasSubStatusFilter) {
      filter = subStatusFilter({ user, subStatus })[seriesName];
    } else {
      filter = filters({
        recType,
        user,
        causeResolved,
        causeLitige,
        subStatus
      })[seriesName];
    }
    if ((typeMode === 'day' && mode === 'week') || typeMode === 'month') {
      index = index - 1;
    } else if (typeMode === 'day') {
      typeMode = 'date';
    }
    const data = {
      'recovery.status': recType,
      ...filter,
      [date]: {
        $gt: moment(from).set(typeMode, index).startOf(typeMode).toDate(),
        $lt: moment(from).set(typeMode, index).endOf(typeMode).toDate(),
      },
    };
    return dispatch(
      setDialog({
        name: 'InterListDialog',
        open: true,
        hideClose: true,
        dialogProps: {
          title: 'Liste d\'intervention',
          maxWidth: 'xl',
        },
        contentProps: {
          filter: data,
          service: recType === R_BTOB ? S_BTOB : undefined
        },
        actions: [{
          children: 'Fermer',
          color: 'secondary',
          onClick: (r, close) => close(),
        }],
      })
    );
  };

  render() {
    const {
      pec,
      cause,
      status,
      causeResolved,
      from,
      to,
      mode,
      showMode,
      recType,
      loading,
      date,
      user,
      causeLitige,
      detailedCause,
      subStatus,
      subStatusData,
      users
    } = this.state;

    const dates = [moment(from).startOf(mode), moment(to).endOf(mode)];
    return (
      <React.Fragment>
        <StatsPanel
          title={`Statistiques de recouvrements - ${showModes[showMode]}`}
          onDatesChange={this.onDatesChange}
          defaultState={{
            mode,
            from,
            to,
          }}
          body={
            <React.Fragment>
              <Select
                style={{ marginRight: '1rem' }}
                value={showMode}
                onChange={this.handleChange('showMode', this.reqAllStats)}
              >
                <MenuItem value={'CA'}>Chiffre d'affaire</MenuItem>
                <MenuItem value={'NBR'}>Nombres</MenuItem>
              </Select>
              <Select
                style={{ marginRight: '1rem' }}
                value={recType}
                onChange={this.onTypeChange}
              >
                <MenuItem value={R_CLI}>Recouvrement Client</MenuItem>
                <MenuItem value={L_CLI}>Litige Client</MenuItem>
                <MenuItem value={R_BTOB}>Recouvrement BtoB</MenuItem>
              </Select>
              <Dropdown
                title={`Date ${datesOptions.find((e) => e._id === date).name}`}
                data={datesOptions}
                isSelected={(e) => date === e}
                onChange={this.handleChange('date', this.reqAllStats)}
                multiple={false}
              />
              <Dropdown
                title={user.login}
                data={users.filter((e) => e.service === S_JUR)}
                idKey={null}
                renderKey={'login'}
                isSelected={(e) => user === e}
                onChange={this.handleChange('user', this.reqAllStats)}
                multiple={false}
              />
            </React.Fragment>
          }
        >
          {loading && (
            <form style={{ textAlign: 'center' }}>
              <CircularProgress style={{ margin: '100px' }} />
            </form>
          )}

          {pec && !loading && (
            <ReactEcharts
              notMerge={true}
              style={{ width: '100%', marginTop: '3rem' }}
              option={getOption({
                title: 'Prises en charge',
                data: pec,
                mode,
                labels: Object.keys(pec),
                dates,
                colors: [green[800], red[500], blue[500]],
              })}
              onEvents={{ click: this.handleDialogOpen(false) }}
            />
          )}

          {status && !loading && (
            <ReactEcharts
              notMerge={true}
              style={{ width: '100%', marginTop: '3rem' }}
              option={getOption({
                title: 'Résolutions globales',
                data: status,
                mode,
                labels: Object.keys(status),
                dates,
                colors: [green[800], red[500], blue[500]],
              })}
              onEvents={{ click: this.handleDialogOpen(false) }}
            />
          )}

          <Grid
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Select
              style={{ marginRight: '12rem', marginTop: '3rem' }}
              value={causeResolved}
              onChange={this.handleChange('causeResolved', this.reqRecStats)}
            >
              <MenuItem value={'resolved'}>Résolus</MenuItem>
              <MenuItem value={'perte'}>Perte</MenuItem>
              <MenuItem value={'unresolved'}>Non Résolus</MenuItem>
            </Select>
          </Grid>
          {cause && !loading && (
            <ReactEcharts
              notMerge={true}
              style={{ width: '100%' }}
              option={getOption({
                title: 'Causes recouvrement',
                data: cause,
                mode,
                labels: Object.keys(cause),
                dates,
                colors: [
                  green[800],
                  red[500],
                  blue[500],
                  purple[500],
                  deepPurple[500],
                  cyan[500],
                  teal[500],
                  yellow[500],
                ],
              })}
              onEvents={{ click: this.handleDialogOpen(false) }}
            />
          )}
          {recType === L_CLI && (
            <Grid
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Select
                style={{ marginRight: '12rem', marginTop: '3rem' }}
                value={causeLitige}
                onChange={this.handleChange(
                  'causeLitige',
                  this.reqRecStatsDetails
                )}
              >
                {litigeCauses.map((e) => (
                  <MenuItem key={e._id} value={e._id}>{e.name}</MenuItem>
                ))}
              </Select>
            </Grid>
          )}
          {recType === L_CLI && detailedCause && !loading && (
            <ReactEcharts
              notMerge={true}
              style={{ width: '100%' }}
              option={getOption({
                title: 'Detail litige',
                data: detailedCause,
                mode,
                labels: Object.keys(detailedCause),
                dates,
                colors: [
                  green[800],
                  red[500],
                  blue[500],
                  purple[500],
                  deepPurple[500],
                  cyan[500],
                  teal[500],
                  yellow[500],
                ],
              })}
              onEvents={{ click: this.handleDialogOpen(false) }}
            />
          )}
          <Grid
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Select
              style={{ marginRight: '12rem', marginTop: '3rem' }}
              value={subStatus}
              onChange={this.handleChange('subStatus', this.subStatusRecStats)}
            >
              {subStatusDevisData.map((e, i) => (
                <MenuItem key={i} value={e.type}>
                  {e.type}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          {subStatusData && !loading && (
            <ReactEcharts
              notMerge={true}
              lazyUpdate={true}
              style={{ width: '100%' }}
              option={getOption({
                title: 'Sous-status devis recouvrement',
                data: subStatusData,
                mode,
                labels: Object.keys(subStatusData),
                dates,
                colors: [
                  green[800],
                  red[500],
                  blue[500],
                  purple[500],
                  deepPurple[500],
                  cyan[500],
                  teal[500],
                  yellow[500],
                ],
              })}
              onEvents={{ click: this.handleDialogOpen(true) }}
            />
          )}
        </StatsPanel>
      </React.Fragment>
    );
  }
}

RecouvStats.displayName = 'RecouvStats';

export default compose(
  withDisplaying(),
  connect(mapStateToProps),
)(RecouvStats);
