import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

class DisplayTable extends PureComponent {
  render() {
    const { headerData, bodyData, classes } = this.props;
    return (
      <Table>
        <TableHead>
          <TableRow className={classes.tr}>
            {headerData.map((e, i) =>
              <TableCell key={i} padding='checkbox'>{e}</TableCell>)}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            {bodyData.map((e, i) =>
              <TableCell key={i} padding='checkbox'>{e}</TableCell>)}
          </TableRow>
        </TableBody>
      </Table>
    );
  }
}

DisplayTable.propTypes = {
  headerData: PropTypes.array.isRequired,
  bodyData: PropTypes.array.isRequired,
};

export default DisplayTable;
