import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import NotifTransition from './NotifTransition';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {
  Call, Close, Email, OpenInNew,
  PersonAdd, Reply, Sms, Warning
} from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import CardMedia from '@material-ui/core/CardMedia';
import Tooltip from '@material-ui/core/Tooltip';

import { connect } from 'react-redux';
import { fetch as fetchNotifications } from '../../actions/notifications';
import notifSystem from '../../notifSystem';
import store from '../../store';
import api from '../../api';

import { call } from '../../utils/call';
import AudioPlayer from '../Calls/AudioPlayer';
import { addArtisanFromSMS } from '../../actions/inter';
import { Grid } from '@material-ui/core';

const styles = {
  notif: {
    pointerEvents: 'auto',
    marginTop: 10,
    height: 0,
    minWidth: '600px',
    width: '100%',
    display: 'flex',
    color: '#333',
    transformOrigin: 'bottom left',
  },
  notifIconSection: {
    backgroundColor: '#00000040',
    flex: '0 0 52px',
    position: 'relative',
  },
  icon: {
    color: 'white',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  textSection: {
    fontSize: 14,
    lineHeight: 1.75,
    padding: 10,
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  text: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  root: {
    maxWidth: 350,
    height: 100,
  },
  media: {
    height: 200,
    widit: 200,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
  },
  content: {
    maxHeight: 70,
    overflow: 'auto',
    paddingTop: 0,
    paddingBottom: 0
  }
};

const mapStateToProps = state => ({
  user: state.users.find(e => e._id === state.userId)
});

const PopupNotification = ({
  message,
  classes,
  history,
  updateNotifications,
  closeComponent,
  user,
  isSMS
}) => (
  <Card raised className={classes.root} elevation={24}
    onClick={() => !message.interId && closeComponent()}>
    <CardHeader
      avatar={isSMS ? <Sms color="primary" /> : <Warning color="secondary" />}
      title={message.title}
      action={message.interId ?
        <IconButton aria-label="close" onClick={closeComponent}>
          <Close />
        </IconButton> : null
      }
    />
    {message.path && (
      <CardMedia className={classes.media} image={message.path} />
    )}
    {!!message.msg && (
      <CardContent className={classes.content}>
        <Typography variant="body2" color="textSecondary" component="p">
          {message.msg}
        </Typography>
      </CardContent>
    )}
    {!!message.audio && (
      <CardContent>
        <AudioPlayer url={message.audio} />
      </CardContent>
    )}
    <CardActions disableSpacing
      style={{ justifyContent: isSMS ? 'space-between' : 'center' }}>
      <div style={{ display: 'flex' }}>
        {isSMS && <Tooltip title="Répondre">
          <Button
            startIcon={<Reply />}
            size="small"
            color="primary"
            onClick={() => {
              updateNotifications(message._id);
              history.push(message.link);
              closeComponent();
            }}
          >
          Repondre
          </Button>
        </Tooltip>}
        <Tooltip title="nouvel onglet">
          {isSMS ? <IconButton
            color="primary"
            variant='contained'
            aria-label="OpenInNew"
            onClick={() => {
              updateNotifications(message._id);
              window.open(message.link);
              closeComponent();
            }}
          >
            <OpenInNew />
          </IconButton> :
            <Button
              fullWidth
              variant="contained"
              startIcon={<OpenInNew />}
              size="small"
              color="primary"
              onClick={() => {
                updateNotifications(message._id);
                window.open(message.link);
                closeComponent();
              }}>
            Voir
            </Button>}
        </Tooltip>
      </div>
      {isSMS && <Tooltip title="Appeler l'artisan">
        <Button size="small"
          startIcon={<Call />}
          style={{ paddingLeft: 8 }}
          color="secondary"
          onClick={() => {
            call(message.tel);
            closeComponent();
          }}>
            Appeler
        </Button>
      </Tooltip>}
    </CardActions>
    {message.interId ? <CardActions disableSpacing>
      <Button
        fullWidth
        variant="contained"
        startIcon={<PersonAdd />}
        size="small" color="primary"
        onClick={() => {
          addArtisanFromSMS(message.interId, message.artisan, user, history);
          closeComponent();
        }}>
        Selectionner pour l'intervention
      </Button>
    </CardActions> : null}
  </Card>
);

const AnotherNotifMessage = ({ message, style, classes, closeComponent }) => (
  <Paper
    elevation={5}
    style={style}
    className={classes.notif}
    onClick={closeComponent}
  >
    <div className={classes.notifIconSection}>
      <message.type.icon className={classes.icon} />
    </div>
    <div className={classes.textSection}>
      <div className={classes.text} style={{ fontWeight: 'bold' }}>
        {message.title}
      </div>
      <div className={classes.text}>{message.msg}</div>
    </div>
  </Paper>
);

const EmailMessage = ({ message, classes, closeComponent, history, user }) => (
  <Card raised className={classes.root} elevation={24}
    onClick={() => closeComponent()}>
    <CardHeader
      avatar={<Email color="primary" />}
      title={message.title}
      action={<IconButton aria-label="close" onClick={closeComponent}>
        <Close />
      </IconButton>}
    />
    <CardActions disableSpacing style={{ paddingBottom: 0 }}>
      <Grid container align="center">
        <Grid item xs={message.interId ? 6 : 12}>
          <Tooltip title="Recap">
            <Button
              startIcon={<OpenInNew />}
              size="small"
              color="primary"
              onClick={() => {
                history.push(message.link);
                closeComponent();
              }}
              children="Recap"
            />
          </Tooltip>
          <div style={{ display: 'flex' }}></div>
        </Grid>
        {message.interId ? <Grid item xs={message.interId ? 6 : 12}>
          <Tooltip title="Intervention">
            <Button
              startIcon={<OpenInNew />}
              size="small"
              color="primary"
              onClick={() => {
                history.push(`/intervention/${message.interId}`);
                closeComponent();
              }}
              children="Intervention"
            />
          </Tooltip>
          <div style={{ display: 'flex' }}></div>
        </Grid> : null}
      </Grid>
      <div style={{ display: 'flex' }}></div>
    </CardActions>
    {message.interId && message.selectable ? <CardActions disableSpacing>
      <Button
        fullWidth
        variant="contained"
        startIcon={<PersonAdd />}
        size="small" color="primary"
        onClick={() => {
          addArtisanFromSMS(message.interId, message.artisan, user, history);
          closeComponent();
        }}>
        Selectionner pour l'intervention
      </Button>
    </CardActions> : null}
  </Card>
);

class Notification extends PureComponent {
  constructor() {
    super();
    this.state = { open: true };
    this.removeComponent = this.removeComponent.bind(this);
    this.closeComponent = this.closeComponent.bind(this);
    this.onExit = this.onExit.bind(this);
  }

  componentDidMount() {
    const { duration, interId } = this.props.message;
    if ((!duration || duration >= 0) && !interId) {
      this.timeout = setTimeout(
        this.closeComponent,
        this.props.message.duration || 5000
      );
    }
  }

  closeComponent() {
    clearTimeout(this.timeout);
    this.setState({ open: false });
  }

  removeComponent() {
    this.props.removeNotif(this.props.message.id);
  }

  onExit(duration) {
    if (!this.props.message.interId) {
      this.exitTimeout = setTimeout(this.removeComponent, duration);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
    clearTimeout(this.exitTimeout);
  }

  getNotification({ message, classes, user, updateNotifications, history }) {
    const style = { backgroundColor: message.type.color };
    if (['sms', 'popUp'].includes(message.type.name)) {
      return <PopupNotification
        closeComponent={this.closeComponent}
        message={message}
        classes={classes}
        history={history}
        user={user}
        updateNotifications={updateNotifications}
        isSMS={message.type.name === 'sms'}
      />;
    }
    if (message.type.name === 'email') {
      return <EmailMessage
        closeComponent={this.closeComponent}
        message={message}
        classes={classes}
        history={history}
      />;
    }
    return <AnotherNotifMessage
      closeComponent={this.closeComponent}
      message={message}
      style={style}
      classes={classes}
    />;
  }

  render() {
    const { message } = this.props;
    return (
      message && (
        <NotifTransition in={this.state.open} onExit={this.onExit}>
          {this.getNotification(this.props)}
        </NotifTransition>
      )
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateNotifications: (notificationId) => {
    api.notifications
      .patch(notificationId, { click: true })
      .then(() => {
        const notifications = store.getState().notifications.map((n) => ({
          ...n,
          click: notificationId === n._id ? true : n.click,
        }));
        dispatch(fetchNotifications(notifications));
      })
      .catch(() =>
        notifSystem.error(
          'Notification',
          'Les notifications n\'ont pas pu être mises à jour'
        )
      );
  },
});
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(Notification);
