const map = new Map([
  ['â', 'a'],
  ['à', 'a'],
  ['ç', 'c'],
  ['é', 'e'],
  ['è', 'e'],
  ['ê', 'e'],
  ['ë', 'e'],
  ['î', 'i'],
  ['ï', 'i'],
  ['ô', 'o'],
  ['û', 'u'],
  ['ù', 'u'],
  ['ü', 'u'],
  ['Â', 'A'],
  ['À', 'A'],
  ['Ç', 'C'],
  ['É', 'E'],
  ['È', 'E'],
  ['Ê', 'E'],
  ['Ë', 'E'],
  ['Î', 'I'],
  ['Ï', 'I'],
  ['Ô', 'O'],
  ['Û', 'U'],
  ['Ù', 'U'],
  ['Ü', 'U'],
]);

const converti = str => str.split('').map(c => map.get(c) || c).join('');

export default converti;
