const styles = {
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    padding: 10,
  },
  card: {
    minWidth: 240,
    maxWidth: 240,
    margin: 12,
    position: 'relative',
  },
  chip: {
    minWidth: 88,
    justifyContent: 'flex-start',
  },
  cardTitle: {
    marginBottom: 24,
  },
  stateDiv: {
    height: 10,
  },
  toolbarRoot: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  tableIconStyle: {
    color: 'white',
    height: 18,
    width: 18,
  },
  tableAvatar: {
    height: 28,
    width: 28,
    margin: 5,
    cursor: 'pointer',
  },
  fullWidth: {
    width: '100%',
  },
  formControlLabel: {
    justifyContent: 'space-between',
    margin: 0,
  },
  textWrapLine: {
    whiteSpace: 'pre-line',
  },
  white: {
    color: 'white',
  },
  img: {
    border: '1px solid #ddd',
    borderRadius: '2px',
    padding: '5px',
    width: '120px',
  },
};

export default styles;
