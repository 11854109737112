
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Typography, Tooltip, Grid, IconButton,
} from '@material-ui/core';
import {
  Done, DoneAll, AccessTime,
  SmsFailed, FileCopy, PersonAdd,
} from '@material-ui/icons';

import { moment } from '@bit/dev-lba.lib.local-globals/moment';
import {
  data as smsStatus,
  NOT_SEND,
  SEND,
  FAILED,
  PENDING
} from '@bit/dev-lba.lib.local-globals/smsStatus';
import {
  SMS, WHATSUP, data as smsMode
} from '@bit/dev-lba.lib.local-globals/smsMode';
import { blue, green, blueGrey, grey } from '@material-ui/core/colors';
import AudioPlayer from '../Calls/AudioPlayer';
import { displayDocument } from '../../utils/windowOpenFunction';
import links from '../../constants/links';

const styles = {
  sender: {
    backgroundColor: blue[400],
  },
  receiver: {
    backgroundColor: green[400],
  },
  artisan: {
    backgroundColor: blueGrey[400],
  },
  message: {
    margin: 10,
    maxWidth: '80%',
    wordWrap: 'break-word',
  },
  bubble: {
    padding: '10px 15px',
    borderRadius: 5,
    textAlign: 'left',
    color: '#fff',
    display: 'flex',
    flexDirection: 'column',
  },
  mms: {
    width: '100%',
    borderRadius: 5,
  },
  senderName: {
    whiteSpace: 'break-spaces',
  },
  done: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end'
  },
  addButtonDiv: {
    display: 'flex',
    alignItems: 'center'
  },
  rightMsg: {
    display: 'flex',
    flexDirection: 'row',
  },
  leftMsg: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  iconMode: {
    margin: 'auto 5px',
  },
  document: {
    background: 'rgb(0, 0, 0, 0.03)',
    border: '1px solid rgb(0, 0, 0, 0.05)',
    borderRadius: '5px',
    color: 'white',
  },
  link: {
    textDecoration: 'none',
    margin: 10
  },
  audio: {
    minWidth: 350
  }
};


/**
 * Get style by type
 * @param {Immutable.Map} msg - Immutable.Map of message
 * @param {Number} id - id of document
 * @param {Object} classes - Material-ui classes
 */
const getStyle = (msg, id, { artisan, sender, receiver }) => {
  if (msg.get('sender')) {
    if (id === msg.get('intervention') && msg.has('artisan')) {
      return { color: artisan, name: 'vers l\'artisan' };
    }
    return { color: sender, name: '' };
  }
  return { color: receiver, name: '' };
};

const IconDisplay = ({ message, classes }) => {
  const status = smsStatus.find((e) => e._id === message.get('status'));
  let Icon = {
      [NOT_SEND]: SmsFailed,
      [SEND]: Done,
      [FAILED]: SmsFailed,
      [PENDING]: AccessTime,
    }[message.get('status')],
    color = status?.color[700],
    name = status?.name;
  if (message.get('mode') === WHATSUP) {
    if (message.get('status') === SEND) {
      Icon = DoneAll;
      color = grey[700];
      name = 'Reçu';
    } else if (message.get('status') === PENDING) {
      Icon = Done;
      color = grey[700];
      name = 'Envoyé';
    }
    if (message.get('dateRead')) {
      color = green[700];
      name = 'Vu';
    }
  }
  return (
    <Tooltip title={`Status: ${name}`}>
      <Icon fontSize="small" className={classes.sendIcon} style={{ color }} />
    </Tooltip>
  );
};

const SmsMessage = ({ id, classes, message, selectArtisan }) => {
  const style = getStyle(message, id, classes);
  const sender = message.get('sender');
  const date = new Date(message.get('date'));
  const dateEnvoi = new Date(message.get('dateEnvoi', date));
  const transmitter = message.get('transmitter');
  const format = moment(date).isSame(moment(), 'day') ? 'HH:mm' :
    ((moment(date).isSame(moment(), 'year') && 'D MMM HH:mm') || 'LLL');
  const formatEnvoi = moment(dateEnvoi).isSame(moment(), 'day') ? 'HH:mm' :
    ((moment(date).isSame(moment(), 'year') && 'D MMM HH:mm') || 'LLL');
  const displayDate = moment(date).format(format);
  const displayDateEnvoi = moment(dateEnvoi).format(formatEnvoi);
  const nameChat = transmitter || sender || '';
  const number = (message.get('from') || '').replace(/^\+33/, '0');
  const messageMode = smsMode.find(e =>
    e._id === message.get('mode', SMS)) || {};
  return (
    <div style={{ display: 'flex' }}>
      {sender && <div style={{ flex: 1 }} />}
      <div
        className={classes.message}
        style={sender ? { textAlign: 'right' } : {}}
      >
        <div className={sender ? classes.rightMsg : classes.leftMsg}>
          {!message.get('sender') &&
          message.get('intervention') &&
          message.get('msgType') === 'Accept' &&
          (
            <div className={classes.addButtonDiv}>
              <Tooltip title={'Selectionner pour l\'intervention'}>
                <IconButton
                  onClick={() => selectArtisan(message.toJS())}
                  children={<PersonAdd />}
                />
              </Tooltip>
            </div>
          )}
          <div className={`${style.color} ${classes.bubble}`}>
            <Typography className={classes.senderName} variant="caption" noWrap
              gutterBottom>
              {
                (String(typeof nameChat === 'string' && !sender ?
                  `${nameChat} - ${number}` :
                  nameChat)).toUpperCase()
              }
            </Typography>
            {message.get('fileName') ?
              new Array(message.get('numberOfImg', 1))
                .fill(0).map((_, index) => <a
                  key={index}
                  href={`${links.origin}/api/imageFromMms?mmsId=` +
                `${message.get('_id')}&index=${index}`}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <img
                    src={`${links.origin}/api/imageFromMms?mmsId=` +
                  `${message.get('_id')}&index=${index}`}
                    alt="mms"
                    className={classes.mms}
                    style={{ height: '200px', width: '140px' }}
                  />
                </a>) : message.get('path') && (
                <a
                  href={message.get('path')}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <img
                    src={message.get('path')}
                    alt="mms"
                    className={classes.mms}
                  />
                </a>
              )}
            {message.get('text') && !message.get('document') &&
              message
                .get('text')
                .split(/\n/g)
                .map((e, key) => (
                  <Typography
                    className={classes.senderName}
                    variant="body1"
                    noWrap
                    key={key}
                  >
                    {e}
                  </Typography>
                ))}
            {!!message.get('audio') && (
              <div className={classes.audio}>
                <AudioPlayer noPadding url={message.get('audio')}/>
              </div>
            )}
            {!!message.get('document') && (
              <div
                onClick={() => {
                  displayDocument(message.get('document'));
                }}
                className={classes.link}
              >
                <Grid container spacing={2} className={classes.document}>
                  <Grid item xs={2}>
                    <FileCopy />
                  </Grid>
                  <Grid item xs={10}>
                    <Typography
                      align="center"
                      children={
                        message.get('text') ? message.get('text') : 'Document'
                      }
                    />
                  </Grid>
                </Grid>
              </div>
            )}
            {sender &&
              <div className={classes.done}>
                <Typography
                  align="right"
                  className={classes.senderName}
                  variant="caption"
                  noWrap
                >
                  {displayDate}
                </Typography>
              </div>
            }
            <div className={classes.done}>
              <Typography
                align="right"
                className={classes.senderName}
                variant="caption"
                noWrap
              >
                {displayDateEnvoi}
              </Typography>
              {sender && <IconDisplay message={message} classes={classes} />}
            </div>
          </div>
          <div className={classes.iconMode}>
            {messageMode.icon ? <Tooltip
              title={`Envoyé par ${messageMode.name}`}
            >
              <messageMode.icon />
            </Tooltip> : null}
          </div>
        </div>
      </div>
      {!sender && <div style={{ flex: 1 }} />}
    </div>
  );
};

export default withStyles(styles)(SmsMessage);
