/* eslint-disable max-lines */
import api from '../api';
import notifSystem from '../notifSystem';
import ComponentForMenu
from '../components/Dialogs/DialogsSav/ComponentForMenu';
import {
  SAV_ENC,
  SAV_CLOT,
  SAV_ANN,
  SAV_REF,
} from '@bit/dev-lba.lib.local-globals/SAVStatus';

import { D_MAR } from '@bit/dev-lba.lib.local-globals/deStatus';

import { SAV_DAE } from '@bit/dev-lba.lib.local-globals/ListSAVInters';
import IphoneDialog from '../components/Dialogs/IphoneDialog';
import { generateSmsSAV } from '../constants/sms/smsSAV';
import { setDialog } from './dialog';
import {
  getSAVMail,
  getBonMail,
  M_NCO2,
  M_NCO1,
  M_NTEC,
} from '@bit/dev-lba.lib.local-globals/SAVMail';
import { getSAVSMS, SMS_SST3 } from '@bit/dev-lba.lib.local-globals/SAVSMS';
import store from '../store';
import {
  data as classificationData
} from '@bit/dev-lba.lib.local-globals/classficationSAV';
import {
  defaultTextAttestation
} from '../components/Dialogs/DialogsSav/defaultText';

const sendMailAndSms = (obj, newObj) => {
  newObj.title = `${(newObj.title || '').replace(/{id}/, newObj.savId)}`;
  api.savInterventions
    .custom('sendMail')
    .post({
      newStatus: (newObj.set || {}).status,
      message: obj.message,
      savIntervention: newObj.savIntervention,
      artisan: newObj.artisan,
      title: newObj.title,
      clientMail: newObj.clientMail,
      sms: newObj.sms,
      artisanMail: obj.artisanMail,
      attachments: obj.attachments,
      attachmentsArtisan: newObj.attachmentsArtisan,
      titleArtisan: newObj.titleArtisan,
    })
    .then(() =>
      notifSystem.success('Opération réussie', 'Demande de SAV a été envoyée')
    )
    .catch((e) => notifSystem.error(e.name, e.response ? e.response.data : e));
};

const callback = (obj, props, newObj, cb) => {
  props.setDialog(null, false, '', null);
  if (obj.update) {
    api.savInterventions
      .patch(newObj.savIntervention._id, newObj.set)
      .then(
        () =>
          !obj.message &&
          notifSystem.success('Opération réussie', 'Informations sauvegardées')
      )
      .catch((e) =>
        notifSystem.error(e.name, e.response ? e.response.data : e)
      );
  }
  if (obj.message || newObj.sms || obj.artisanMail) {
    newObj.savId = newObj.savIntervention.id;
    sendMailAndSms(obj, newObj);
  }
  if (cb) {
    cb();
  }
};

const postCallBack = (obj, props, newObj, cb) => {
  props.setDialog(null, false, '', null);
  if (obj.update) {
    api.savInterventions
      .post({ id: newObj.savIntervention.id })
      .then((newSav) => {
        if (!obj.message) {
          notifSystem.success('Opération réussie', 'Informations sauvegardées');
        }
        if (obj.message || newObj.sms) {
          newObj.savId = newSav.body().data().id;
          sendMailAndSms(obj, newObj);
        }
      })
      .catch((e) =>
        notifSystem.error(e.name, e.response ? e.response.data : e)
      );
  }
  if (cb) {
    cb();
  }
};

export const demandeSAV = (props, savIntervention, justAdd) => {
  if (!justAdd) {
    props.setDialog(
      ComponentForMenu,
      true,
      {
        savIntervention,
        text: 0,
        type: 0,
      },
      (update, message, attachments) =>
        update
          ? postCallBack(
            { update, message: message.multiline, attachments },
            props,
            {
              savIntervention,
              title: message.headLine,
              clientMail: message.clientMail
            }
          )
          : props.setDialog(null, false, '', null)
    );
  } else {
    api.savInterventions
      .post({ id: savIntervention.id, justAdd })
      .then(() => {
        notifSystem.success(
          'Opération réussie',
          'L\'intervention a été ajouté en SAV'
        );
      })
      .catch((e) =>
        notifSystem.error(e.name, e.response ? e.response.data : e)
      );
  }
};

export const sendSAV = async (props, savIntervention) => {
  let artisan = await api.artisans.get(savIntervention.artisan);
  artisan = artisan.body().data();
  function smsCallback(sms) {
    if (sms) {
      props.setDialog(
        ComponentForMenu,
        true,
        {
          savIntervention,
          text: 0,
          artisanMail: 1,
          artisan,
          type: 0,
        },
        (update, message, attachments, artisanMail) =>
          update
            ? callback(
              {
                update,
                message: message && message.multiline,
                artisanMail: artisanMail.body,
                attachments,
              },
              props,
              {
                savIntervention,
                set: {
                  interArtisan: {
                    companyName: artisan.companyName,
                    remuneration: artisan.remuneration
                  },
                  artisan: artisan._id,
                  priceArtisan: savIntervention.priceArtisan,
                  savId: savIntervention.id,
                  status: SAV_ENC,
                  date: savIntervention.date,
                  login: {
                    envoi: savIntervention.login.envoi,
                  },
                },
                artisan: {
                  tel: artisan.tel1,
                  companyName: artisan.companyName,
                  email1: artisan.email1,
                  email2: artisan.email2,
                  option: artisan.option,
                  name: artisan.name,
                  firstname: artisan.firstname,
                  billingAddress: artisan.billingAddress
                },
                title: message && message.headLine,
                titleArtisan: `LES BONS ARTISANS : PRISE EN CHARGE SAV - OS ${
                  savIntervention.id}`,
                sms,
                clientMail: message.clientMail
              }
            )
            : props.setDialog(null, false, '', null)
      );
    } else {
      props.setDialog(null, false, '', null);
    }
  }
  props.setDialog(
    IphoneDialog,
    true,
    {
      text: generateSmsSAV(savIntervention, props.user),
    },
    smsCallback
  );
};

export const verifSAV = async (props, savIntervention, cb) => {
  let artisan = await api.artisans.get(savIntervention.artisan);
  artisan = artisan.body().data();
  props.setDialog(
    ComponentForMenu,
    true,
    {
      savIntervention,
      text: 1,
      artisanMail: 2,
      artisan,
      type: 0,
    },
    (update, message, attachments, artisanMail) =>
      update
        ? callback(
          {
            update,
            message: message.multiline,
            attachments,
            artisanMail: artisanMail.body,
          },
          props,
          {
            savIntervention,
            artisan: {
              tel: artisan.tel1,
              name: artisan.name,
              companyName: artisan.companyName,
              email1: artisan.email1,
              email2: artisan.email2,
              option: artisan.option,
            },
            set: {
              status: SAV_CLOT,
              date: savIntervention.date,
              login: {
                verif: savIntervention.login.verif,
              },
              classification: props.classification,
              savId: savIntervention.id,
            },
            title: message.headLine,
            titleArtisan: artisanMail.titleArtisan,
            clientMail: message.clientMail
          }
        )
        : props.setDialog(null, false, '', null),
    cb
  );
};

export const classificationSAV = (props, savIntervention, cb) =>
  store.dispatch(
    setDialog({
      name: 'DialogCallBackButton',
      open: true,
      dialogProps: { title: 'Classification SAV', dividers: true },
      contentProps: {
        buttons: classificationData,
        callback: (classification) =>
          verifSAV({ ...props, classification }, savIntervention, cb),
      },
    })
  );

export const cancelSAV = (props, savIntervention, cb) => {
  props.setDialog(
    ComponentForMenu,
    true,
    {
      savIntervention,
      text: 2,
      type: 0,
    },
    (update, message, attachments) =>
      update
        ? callback({ update, message: message.multiline, attachments }, props, {
          savIntervention,
          set: {
            status: SAV_ANN,
            date: savIntervention.date,
            login: {
              cancel: savIntervention.login.cancel,
            },
            savId: savIntervention.id,
          },
          title: message.headLine,
          clientMail: message.clientMail
        })
        : props.setDialog(null, false, '', null),
    cb
  );
};

export const noContactSAV = (props, savIntervention, cb) => {
  const getCb = (update, message, attachments) =>
    callback(
      {
        update: isNaN(savIntervention.id),
        message: message.multiline,
        attachments,
      },
      props,
      {
        savIntervention,
        set: {
          date: {
            noContact: new Date(),
          },
          login: {
            noContact: props.user._id,
          },
        },
        title: message.headLine,
        clientMail: message.clientMail,
      }
    );
  props.setDialog(
    ComponentForMenu,
    true,
    {
      savIntervention,
      text: 3,
      type: 0,
    },
    (update, message, attachments) =>
      update
        ? getCb(update, message, attachments)
        : props.setDialog(null, false, '', null),
    cb
  );
};

export const suiviSAV = async (props, savIntervention, textId) => {
  const dbKeys = {
    4: 'suiviDossier',
    5: 'suiviVerif',
    6: 'suiviAnnul',
  };
  const getCb = (update, message, attachments, sms = {}) =>
    callback(
      {
        update: isNaN(savIntervention.id),
        message: message.multiline,
        attachments,
      },
      props,
      {
        savIntervention,
        set: {
          date: {
            [dbKeys[textId]]: new Date(),
          },
          login: {
            [dbKeys[textId]]: props.user._id,
          },
        },
        title: message.headLine,
        sms: sms.sms,
        artisan: sms.artisan,
        clientMail: message.clientMail,
      }
    );
  const mailCb = (sms) =>
    props.setDialog(
      ComponentForMenu,
      true,
      {
        savIntervention,
        text: textId,
        type: 0,
      },
      (update, message, attachments) =>
        update
          ? getCb(update, message, attachments, sms)
          : props.setDialog(null, false, '', null)
    );
  if (textId === 6) {
    let artisan = await api.artisans.get(savIntervention.artisan);
    artisan = artisan.body().data();
    props.setDialog(
      IphoneDialog,
      true,
      {
        text: getSAVSMS(savIntervention, props.user)[SMS_SST3],
      },
      (sms) =>
        sms
          ? mailCb({
            sms,
            artisan: {
              tel: artisan.tel1,
              companyName: artisan.companyName,
              email1: artisan.email1,
              email2: artisan.email2,
              option: artisan.option,
            },
          })
          : props.setDialog(null, false, '', null)
    );
  } else {
    mailCb();
  }
};

export const noContactSSTSAV = async (props, savIntervention, second) => {
  let artisan = await api.artisans.get(savIntervention.artisan);
  artisan = artisan.body().data();
  const updateName = second ? 'noContactSST2' : 'noContactSST';
  const mail = getSAVMail({ ...savIntervention, artisan })[
    second ? M_NCO2 : M_NCO1
  ];
  const getCb = ({ m, title }, attachments) =>
    callback(
      {
        update: isNaN(savIntervention.id),
        artisanMail: m,
      },
      props,
      {
        savIntervention,
        artisan: {
          tel: artisan.tel1,
          name: artisan.name,
          companyName: artisan.companyName,
          email1: artisan.email1,
          email2: artisan.email2,
          option: artisan.option,
        },
        set: {
          date: {
            [updateName]: new Date(),
          },
          login: {
            [updateName]: props.user._id,
          },
        },
        titleArtisan: title,
        attachmentsArtisan: attachments,
      }
    );
  store.dispatch(
    setDialog({
      name: 'DialogMailSST',
      open: true,
      dialogProps: {
        maxWidth: 'md',
      },
      contentProps: {
        savIntervention,
        artisan: {
          tel: artisan.tel1,
          name: artisan.name,
          companyName: artisan.companyName,
          email1: artisan.email1,
          email2: artisan.email2,
        },
        message: mail.body,
        titleArtisan: mail.title,
      },
      actions: [
        {
          children: 'Envoyer',
          color: 'primary',
          onClick: ({ message, titleArtisan, attachments }, close) => {
            if (message && titleArtisan) {
              getCb({ m: message, title: titleArtisan }, attachments);
              close();
            } else {
              notifSystem.error('Erreur', 'Un des champs est manquant');
            }
          },
        },
      ],
    })
  );
};

export function sendSavToMarket(props, inter) {
  api.savInterventions
    .patch(inter._id, { aDemarcher: D_MAR })
    .then(() =>
      notifSystem.success('Réussie', 'Intervention SAV envoyé dans le market')
    )
    .catch(e => notifSystem.error(e.name, e.message));
}

export const artisanChangedSAV = async (props, savIntervention) => {
  let artisan = await api.artisans.get(savIntervention.artisan);
  artisan = artisan.body().data();
  const mail = getSAVMail({ ...savIntervention, artisan })[M_NTEC];
  const getCb = ({ m, title }, attachments) =>
    callback(
      {
        update: isNaN(savIntervention.id),
        artisanMail: m,
      },
      props,
      {
        savIntervention,
        artisan: {
          tel: artisan.tel1,
          name: artisan.name,
          companyName: artisan.companyName,
          email1: artisan.email1,
          email2: artisan.email2,
          option: artisan.option,
        },
        set: {
          date: {
            artisanChanged: new Date(),
          },
          login: {
            artisanChanged: props.user._id,
          },
        },
        titleArtisan: title,
        attachmentsArtisan: attachments,
      }
    );
  store.dispatch(
    setDialog({
      name: 'DialogMailSST',
      open: true,
      dialogProps: {
        maxWidth: 'md',
      },
      contentProps: {
        savIntervention,
        artisan: {
          tel: artisan.tel1,
          name: artisan.name,
          companyName: artisan.companyName,
          email1: artisan.email1,
          email2: artisan.email2,
        },
        message: mail.body,
        titleArtisan: mail.title,
      },
      actions: [
        {
          children: 'Envoyer',
          color: 'primary',
          onClick: ({ message, titleArtisan, attachments }, close) => {
            if (message && titleArtisan) {
              getCb({ m: message, title: titleArtisan }, attachments);
              close();
            } else {
              notifSystem.error('Erreur', 'Un des champs est manquant');
            }
          },
        },
      ],
    })
  );
};

export const refuseSAV = (props, savIntervention, cb) => {
  props.setDialog(
    ComponentForMenu,
    true,
    {
      savIntervention,
      text: 7,
      type: 0,
    },
    (update, message, attachments) =>
      update
        ? callback({ update, message: message.multiline, attachments }, props, {
          savIntervention,
          set: {
            status: SAV_REF,
            date: {
              refuse: savIntervention.date.refuse,
            },
            login: {
              refuse: savIntervention.login.refuse,
            },
            savId: savIntervention.id,
          },
          title: message.headLine,
          clientMail: message.clientMail
        })
        : props.setDialog(null, false, '', null),
    cb
  );
};

export function sendAwaitingBillSAV(props, savIntervention) {
  api
    .one('interventions', savIntervention.id)
    .custom('sendBill')
    .post({ isSAV: true })
    .then(() =>
      notifSystem.success(
        'Opération réussie',
        'Facture en attente de règlement envoyée'
      )
    )
    .catch((e) =>
      e.message === 'Not Acceptable'
        ? notifSystem.error(e.name, 'Coordonnées de facturation incomplètes.')
        : notifSystem.error(e.name, e.message)
    );
}

export const DeleteSAV = (props, inter) =>
  api.savInterventions
    .custom('delete')
    .delete({
      id: inter.id,
      query: props.filter || { $in: props.selected.statusSAV },
    })
    .then(() => {
      notifSystem.success('Opération réussie', 'Informations sauvegardées');
    })
    .catch((e) => notifSystem.error(e.name, e.response ? e.response.data : e));

export const updateStatus = (savIntervention, status, message) => {
  api.savInterventions
    .patch(savIntervention._id, { status })
    .then(() => {
      notifSystem.success('Opération réussie', message);
    })
    .catch((e) => {
      notifSystem.error(e.name, e.message);
    });
};

export const updateStatusSAV = (props, inter, statusSAV, deleteStatus) => {
  const updateStatus = inter.statusSAV
    .filter((e) => e !== deleteStatus)
    .concat([statusSAV]);
  if (statusSAV === SAV_DAE) {
    const defaultText = defaultTextAttestation(inter || {});
    store.dispatch(
      setDialog({
        name: 'AttestationSavDialog',
        open: true,
        dialogProps: {
          maxWidth: 'md',
          fullWidth: true,
        },
        contentProps: {
          conclusions: '',
          observation: defaultText.observ,
          message: defaultText.body,
          title: defaultText.title,
          desc: defaultText.desc,
          objMail: defaultText.objMail,
          files: [],
          loading: false,
          destinataires: inter && inter.client
            ? [inter.client.email1, inter.client.email2 ].join(',')
            : ''
        },
        actions: [
          {
            children: 'Envoyer',
            color: 'primary',
            onClick: (data, close) => {
              if (
                !data.message || !data.observation ||
                !data.title || !data.conclusions ||
                !data.desc || !data.destinataires
              )
              {
                return notifSystem.error(
                  'Erreur',
                  'Merci de remplir tous les champs'
                );
              }
              close();
              return api
                .one('interventions', inter._id)
                .custom('sendAttestation')
                .post({
                  statusSAV: updateStatus,
                  ...data,
                })
                .then(() => {
                  notifSystem.success(
                    'Opération réussie',
                    'Informations sauvegardées'
                  );
                })
                .catch((e) =>
                  notifSystem.error(e.name, e.response ? e.response.data : e)
                );
            }
          },
        ],
      })
    );
  } else {
    api.interventions
      .patch(inter._id, { statusSAV: updateStatus })
      .then(() =>
        notifSystem.success('Opération réussie', 'Informations sauvegardées')
      )
      .catch((e) =>
        notifSystem.error(e.name, e.response ? e.response.data : e)
      );
  }
};

export const downloadAndSignBonSAV = ({ price, artisan, inter, add, save }) =>
  setDialog({
    name: 'DialogBon',
    open: true,
    dialogProps: {
      maxWidth: 'md',
    },
    contentProps: {
      ...getBonMail(inter, artisan),
      price,
      artisanMail: artisan.email1,
      cb: f => f,
    },
    actions: [
      {
        children: 'Envoyer',
        color: 'primary',
        onClick: (
          {
            message,
            subject,
            subjectArtisan,
            messageArtisan,
            attachments,
            price,
            destinataire,
            fournisseur,
            artisanMail,
            cb,
            PDFComments = ''
          },
          close
        ) => {
          if (
            message &&
            subject &&
            attachments &&
            subjectArtisan &&
            messageArtisan &&
            price &&
            destinataire &&
            fournisseur &&
            artisanMail
          ) {
            cb(true);
            api.interventions
              .custom('sendBon')
              .post({
                message,
                subject,
                artisanMail,
                inter,
                subjectArtisan,
                messageArtisan,
                attachments,
                destinataire,
                comments: PDFComments
              })
              .then((resp) => {
                const { link } = resp.body().data() || {};
                add({
                  price,
                  pro: fournisseur,
                  attachment: link
                });
                cb(false);
                save();
                close();
                notifSystem.success('Opération réussie', 'Email et bon envoyé');
              })
              .catch((e) =>
                notifSystem.error(e.name, e.response ? e.response.data : e)
              );
          } else {
            notifSystem.error('Erreur', 'Un des champs est manquant');
          }
        },
      },
    ],
  });
