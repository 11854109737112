import { DBANQ } from '@bit/dev-lba.lib.local-globals/docTypes';
import { DOCVAL } from '@bit/dev-lba.lib.local-globals/docStatus';
import { A_ARC } from '@bit/dev-lba.lib.local-globals/aStatus';
import { AS_INDSP } from '@bit/dev-lba.lib.local-globals/aSubStatus';
import notifSystem from '../notifSystem';
import { isValidIBAN, isValidBIC } from 'ibantools';
import api from '../api';

export const checkBankInfo = (merge, current) => {
  const { newIban, newBic, dropbox = [] } = current.toJS();
  const currentNewIban = newIban || newIban === '';
  const currentNewBic = newBic || newBic === '';
  const dropboxType = e => e.get('type') === DBANQ
    && e.get('status') === DOCVAL;
  const ribDoc = merge
    .get('dropbox', dropbox)
    .some(dropboxType);
  const globalNewIban = current.get('newIban', merge.get('iban'));
  const globalNewBic = current.get('newBic', merge.get('bic'));
  const globalDropbox = current.get('dropbox', merge.get('dropbox'))?.toJS();
  if ((newIban === '' || !newIban)
  && (newBic === '' || !newBic)
  && !dropbox.length) {
    return true;
  }
  else if (
    currentNewIban ||
    currentNewBic ||
     !globalDropbox.length ||
     (ribDoc &&
       !merge.get('newIban') &&
       !merge.get('newBic') &&
       !merge.get('iban') &&
       !merge.get('bic'))
  ) {
    if (
      !isValidIBAN(globalNewIban.toUpperCase())
      || !isValidBIC(globalNewBic.toUpperCase())
       || !globalDropbox.length
      || !ribDoc
    ) {
      notifSystem.error(
        'Erreur',
        'Les informations bancaires sont incomplètes'
      );
      return false;
    }
    return true;
  }
  return true;
};

export const codeAddressArtisan = (original, current, cb) => {
  try {
    const originalAddr = original.get('address');
    const currentAddr = current.get('address');
    (currentAddr || originalAddr).forEach((e, i) => {
      let address =
        `${e.get('number')} ` +
        `${e.get('road')}, ` +
        `${e.get('zipcode')} ` +
        `${e.get('city')}`;
      api.map
        .custom('getPlaces')
        .get({
          value: JSON.stringify(address),
        })
        .then((results) => {
          results = results.body().data().features;
          const res = {
            type: 'Point',
            coordinates: [
              results[0].geometry.coordinates[0],
              results[0].geometry.coordinates[1],
            ],
          };
          const addr = e.set('location', res);
          const tabAddr = current.get('address').set(i, addr);
          current = current.setIn(['address'], tabAddr);
        });
    });
    return cb(original, current);
  } catch (error) {
    return notifSystem.error('Erreur Map', 'Adresse non reconnue');
  }
};

export const checkForAppelResultDialog = (artisans, target) => {
  const actualValue = artisans.find(e =>
    e.getIn(['obj', '_id']) === target);
  return actualValue && (actualValue.getIn(['obj', 'status']) === A_ARC ||
    actualValue.getIn(['obj', 'subStatus']) === AS_INDSP);
};
