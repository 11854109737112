import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TableHead from '@material-ui/core/TableHead';
import Tooltip from '@material-ui/core/Tooltip';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableCell from '@material-ui/core/TableCell';
import {
  S_DEVIS,
  S_INT,
  S_LEAD,
} from '@bit/dev-lba.lib.local-globals/services';

const defaultCommercialColumnData = [
  { id: 'login', align: 'inherit', disablePadding: true, label: 'Login' },
  { id: 'total', align: 'right', disablePadding: true, label: 'Total' },
  { id: 'I_APR', align: 'right', disablePadding: true, label: 'A Progr.' },
  { id: 'I_ENC', align: 'right', disablePadding: true, label: 'En Cours' },
  { id: 'I_AVR', align: 'right', disablePadding: true, label: 'A Vérifier' },
  { id: 'I_VRF', align: 'right', disablePadding: true, label: 'Vérifié' },
  { id: 'I_ANN', align: 'right', disablePadding: true, label: 'Annulé' },
  {
    id: 'CBRegle',
    align: 'right',
    disablePadding: true,
    label: 'Reglé par CB',
  },
  {
    id: 'panierMoyen',
    align: 'right',
    disablePadding: true,
    label: 'Panier moyen',
  },
  {
    id: 'callWaiting',
    align: 'right',
    disablePadding: true,
    label: 'Appels manqués',
  },
  { label: 'Etat', align: 'right', disablePadding: true, id: 'etat' },
];

const styles = {
  align: {
    textAlign: 'center',
  },
};

const columnData = {
  [S_INT]: defaultCommercialColumnData,
  [S_LEAD]: defaultCommercialColumnData,
  [S_DEVIS]: [
    { id: 'login', align: 'inherit', disablePadding: true, label: 'Equipe de' },
    { id: 'total', align: 'right', disablePadding: true, label: 'Total' },
    { id: 'I_APR', align: 'right', disablePadding: true, label: 'A Progr.' },
    { id: 'I_ENC', align: 'right', disablePadding: true, label: 'En Cours' },
    { id: 'I_AVR', align: 'right', disablePadding: true, label: 'A Vérifier' },
    { id: 'I_VRF', align: 'right', disablePadding: true, label: 'Vérifié' },
    {
      id: 'CBRegle',
      align: 'right',
      disablePadding: true,
      label: 'Reglé par CB',
    },
  ],
};

class StatsTableHeader extends PureComponent {
  constructor(props) {
    super(props);
    this.createSortHandler = this.createSortHandler.bind(this);
  }

  createSortHandler(property, event) {
    this.props.onRequestSort(event, property);
  }

  render() {
    const { service, order, orderBy, classes } = this.props;

    return (
      <TableHead>
        <TableRow>
          {columnData[service].map(
            (column) => (
              <TableCell
                className={classes.align}
                key={column.id}
                align={column.numeric ? 'right' : 'inherit'}
                padding={column.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === column.id ? order : false}
              >
                <Tooltip
                  title="Sort"
                  placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={order}
                    onClick={(event) =>
                      this.createSortHandler(column.id, event)
                    }
                  >
                    {column.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            ),
            this
          )}
        </TableRow>
      </TableHead>
    );
  }
}

StatsTableHeader.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
};

export default withStyles(styles)(StatsTableHeader);
