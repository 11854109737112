import React from 'react';
import { connect } from 'react-redux';

import { ThemeProvider, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const getThemeFromColor = color => outerTheme => ({
  ...outerTheme,
  palette: {
    ...outerTheme.palette,
    primary: {
      main: color[500],
      contrastText: color[50]
    }
  }
});

const mapStateToProps = ({ counter, users }, { userId }) => {
  const user = users.find(u => u._id === userId);

  return {
    counter,
    login: user ? user.login : ''
  };
};

const styles = {
  textSize: {
    display: 'grid'
  }
};

const TabButton = ({
  counter,
  login,
  selected,
  id,
  name,
  color,
  counterName,
  handleChangeSelectedTab,
  classes
}) =>
  <ThemeProvider
    theme={getThemeFromColor(color)}
  >
    <Button
      variant={selected ? 'outlined' : 'text'}
      onClick={() => handleChangeSelectedTab(id)}
      color="primary"
      classes={{
        label: classes.textSize
      }}
    >
      <Typography color="primary">
        {name}
      </Typography>
      <Typography color="primary">
        {(login && counter[`${counterName}.${login}`]) || 0}
      </Typography>
    </Button>
  </ThemeProvider>;

export default withStyles(styles)(connect(mapStateToProps)(TabButton));
