import React, { Component } from 'react';
import { getOption } from '../common/graph/options/globalOptions';
import ReactEcharts from 'echarts-for-react';
import { moment } from '@bit/dev-lba.lib.local-globals/moment';
import { getLogin } from '../../../utils/function';
import notifSystem from '../../../notifSystem';
import { setDialog } from '../../../actions/dialog';
import { connect } from 'react-redux';
import { modeIndex, modes } from '@bit/dev-lba.lib.local-globals/stats';
import { I_VRF, I_ENC, I_AVR } from '@bit/dev-lba.lib.local-globals//iStatus';
import { P_DET } from '@bit/dev-lba.lib.local-globals/paidStatus';

const filters = ({ user }) => ({
  Reçu: {
    isRegle: true,
    firstPaid: { $ne: P_DET },
    'login.manager': user._id,
  },
  Potentiel: {
    status: { $in: [I_VRF, I_AVR, I_ENC] },
    'login.manager': user._id,
  },
  Envoyées: {
    'login.demandeUpdate': user._id,
  },
  Traitées: {
    'login.demandeUpdate': user._id,
    'proposition.isValid': { $exists: true },
  },
  'Inters totales': {
    'date.intervention': { $exists: true },
    'login.envoi': { $exists: true },
  },
});

const dateFilters = () => ({
  Envoyées: 'date.demandeUpdate',
  Traitées: 'date.demandeUpdate',
  'Inters totales': 'date.intervention'
});

class AbstractGraphSupportActions extends Component {
  state = {
    data: null,
  };

  componentDidMount() {
    this.doRequest();
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.doRequest();
    }
  }

  doRequest = () => {
    const {
      user,
      from,
      to,
      display,
      users = [],
      fn,
      errorMessage,
      actions,
      mode,
    } = this.props;
    const findUser = users.find((e) => e._id === user) || {};
    actions[fn](
      {
        from,
        to,
        display,
        mode,
        findUser: {
          _id: findUser._id,
          supportIds: findUser.supportIds,
        },
        users: JSON.stringify(
          findUser
            ? [...(findUser.supportIds || []), findUser._id]
            : users.reduce((e, v) => [...e, v._id, ...(v.supportIds || [])], [])
        ),
      },
      (result) => this.setState({ data: result.data }),
      () => notifSystem.error('Erreur', errorMessage)
    );
  };

  handleDialogOpen = ({ dataIndex, seriesName }) => {
    const {
      dispatch,
      users,
      mode,
      from,
      user,
      date = 'date.ajout',
    } = this.props;
    const findUser = users.find((e) => e._id === user) || {};
    let typeMode = modes[mode];
    let index = Object.keys(modeIndex[mode]).find(
      (e) => modeIndex[mode][e] === dataIndex
    );
    if ((typeMode === 'day' && mode === 'week') || typeMode === 'month') {
      index = index - 1;
    } else if (typeMode === 'day') {
      typeMode = 'date';
    }
    const data = {
      ...filters({ user: findUser })[seriesName],
      [dateFilters()[seriesName] || date]: {
        $gt: moment(from).set(typeMode, index).startOf(typeMode).toDate(),
        $lt: moment(from).set(typeMode, index).endOf(typeMode).toDate(),
      },
    };
    return dispatch(
      setDialog({
        name: 'InterListDialog',
        open: true,
        hideClose: true,
        dialogProps: {
          title: 'Liste d\'intervention',
          maxWidth: 'lg',
        },
        contentProps: {
          filter: data,
        },
        actions: [
          {
            children: 'Fermer',
            color: 'secondary',
            onClick: (r, close) => close(),
          },
        ],
      })
    );
  };

  render() {
    const {
      mode,
      from,
      to,
      user,
      colors,
      disposition,
      legend,
      yAxis,
      yAxisIndex,
      customOption = (data) =>
        getOption({
          data,
          labels: Object.keys(data),
          mode,
          dates: [moment(from), moment(to)],
          title: getLogin(user),
          legend,
          colors: colors,
          disposition,
          yAxis,
          yAxisIndex
        }),
    } = this.props;

    const { data } = this.state;

    return (
      <React.Fragment>
        {data && (
          <ReactEcharts
            notMerge={true}
            lazyUpdate={true}
            option={customOption(data, user)}
            onEvents={{ click: this.handleDialogOpen }}
          />
        )}
      </React.Fragment>
    );
  }
}

export default connect()(AbstractGraphSupportActions);
