import React from 'react';

import {
  NOT_CONFIRMED,
  USER,
  ADMIN
} from '@bit/dev-lba.lib.local-globals/permissions';
import { S_RH } from '@bit/dev-lba.lib.local-globals/services';
import componentLoader from '../../componentLoader';

const ListIntervention = React.lazy(() =>
  componentLoader(() => import( '../../views/List/ListIntervention'))
);
const ListRelancesIntervention = React.lazy(() =>
  componentLoader(() => import( '../../views/List/ListRelancesIntervention'))
);
const ListRecouvrementIntervention = React.lazy(() =>
  componentLoader(() =>
    import( '../../views/List/ListRecouvrementIntervention'))
);
const ListQualite = React.lazy(() =>
  componentLoader(() => import( '../../views/List/ListQualite'))
);
const ListQualiteInterventions = React.lazy(() =>
  componentLoader(() => import( '../../views/List/ListQualiteInterventions'))
);
const ListBtobIntervention = React.lazy(() =>
  componentLoader(() => import( '../../views/List/ListBtobIntervention'))
);

const RecouvrementBTOB = React.lazy(() =>
  componentLoader(() => import('../../views/Recouvrement/RecouvrementBTOB'))
);
const AjoutIntervention = React.lazy(() =>
  componentLoader(() => import('../../views/AjoutIntervention'))
);

const ListRecouvrementArtisan = React.lazy(() =>
  componentLoader(() => import('../../views/List/ListRecouvrementArtisans'))
);

const ListAides = React.lazy(() =>
  componentLoader(() => import( '../../views/List/ListAides'))
);

const ListDomofinance = React.lazy(() =>
  componentLoader(() => import( '../../views/List/ListDomofinance'))
);

const ListAnn = React.lazy(() =>
  componentLoader(() => import( '../../views/List/ListAnn'))
);

const ListAvrMaj = React.lazy(() =>
  componentLoader(() => import( '../../views/List/ListAvrMaj'))
);

export default [
  {
    path: '/intervention/recouvrementBTOB/:filter',
    component: RecouvrementBTOB,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/intervention/relance/:filter/:query',
    component: ListRelancesIntervention,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/intervention/list/ann',
    component: ListAnn,
    restrictions: ({ service }) => ({
      [S_RH]: NOT_CONFIRMED,
    }[service] || ADMIN)
  },
  {
    path: '/intervention/list/avrMaj/:query',
    component: ListAvrMaj,
    restrictions: () => ADMIN,
  },
  {
    path: '/intervention/quality/mesInterventions',
    component: ListQualite,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/intervention/quality/mesInterventionsGc',
    component: ListQualite,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/intervention/quality/:filter/:query',
    component: ListQualiteInterventions,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/intervention/quality/:filter',
    component: ListQualiteInterventions,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/intervention/recouvrement/recouvrementArtisan',
    component: ListRecouvrementArtisan,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/intervention/recouvrement/:filter/:query',
    component: ListRecouvrementIntervention,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/intervention/recouvrement/:filter',
    component: ListRecouvrementIntervention,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/intervention/btob/:filter/:query',
    component: ListBtobIntervention,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/intervention/btob/:filter',
    component: ListBtobIntervention,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/intervention/demarchage/:filter',
    component: ListBtobIntervention,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/intervention/aide/:filter',
    component: ListAides,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/intervention/domo/:filter',
    component: ListDomofinance,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/intervention/list/:filter/:query',
    component: ListIntervention,
    restrictions: () => USER,
  },
  {
    path: '/intervention/list/:filter',
    component: ListIntervention,
    restrictions: () => USER,
  },
  {
    path: '/intervention/list',
    component: ListIntervention,
    restrictions: () => USER,
  },
  {
    path: '/intervention/interData/',
    component: AjoutIntervention,
    restrictions: () => USER,
  },
  {
    path: '/intervention/:id/:query',
    component: AjoutIntervention,
    restrictions: () => USER,
  },
  {
    path: '/intervention/:id',
    component: AjoutIntervention,
    restrictions: () => USER,
  },
  {
    path: '/intervention',
    component: AjoutIntervention,
    restrictions: () => USER,
  },
];
