import React, { Component } from 'react';
import {
  S_DEVIS,
  S_INT,
  S_LEAD,
  data as iServices,
} from '@bit/dev-lba.lib.local-globals/services';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import StatsTableBody from './StatsTableBody';
import StatsTableBodyTeam from './StatsTableBodyTeam';
import StatsTableHeader from './StatsTableHeader';
import StatsTableFooter from './StatsTableFooter';
import { getLogin } from '../../../utils/function';
import api from '../../../api';
import { List } from 'immutable';
import notifSystem from '../../../notifSystem';
import { connect } from 'react-redux';
import StatsPanel from '../../Stats/common/panel/StatsPanel';
import { moment } from '@bit/dev-lba.lib.local-globals/moment';
import Dropdown from '../common/dropdown/dropdown';
import { permit } from '@bit/dev-lba.lib.local-globals/restrictions';
import { compose } from 'recompose';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

const styles = () => ({
  table: {
    minWidth: 'auto',
  },
});

const mapStateToProps = ({ users, userId }) => ({
  users,
  user: users.find((e) => e._id === userId),
});

const ComponentToDisplay = ({ service, ...props }) =>
  ({
    [S_DEVIS]: <StatsTableBodyTeam {...props} />,
    [S_INT]: <StatsTableBody {...props} />,
    [S_LEAD]: <StatsTableBody {...props} />,
  }[service] || <></>);

const formatData = {
  [S_DEVIS]: (data, users) =>
    users
      .filter((e) => e.service === S_DEVIS && e.act)
      .map((e) => {
        const users = data.filter(
          (user) => user._id === e._id || e.supportIds.includes(user._id)
        );
        const userResults = users.reduce(
          (acc, curr) => {
            acc.I_ANN += curr.I_ANN;
            acc.I_APR += curr.I_APR;
            acc.I_AVR += curr.I_AVR;
            acc.I_ENC += curr.I_ENC;
            acc.I_VRF += curr.I_VRF;
            acc.CBRegle += curr.CBRegle;
            acc.total += curr.total;
            acc.CBRegleTotal += curr.CBRegleTotal;
            return acc;
          },
          {
            I_ANN: 0,
            I_APR: 0,
            I_AVR: 0,
            I_ENC: 0,
            I_VRF: 0,
            CBRegleTotal: 0,
            total: 0,
            CBRegle: 0,
          }
        );
        return {
          login: getLogin(e._id),
          ...userResults,
          CBRegle: (userResults && userResults.CBRegle
            ? (userResults.CBRegle / userResults.CBRegleTotal) * 100 || 0
            : 0
          ).toFixed(2),
        };
      }),
  [S_INT]: (data, users) =>
    users
      .filter((e) => e.service === S_INT && e.act)
      .map((e) => {
        const getInters = data.find((user) => user._id === e._id);
        return {
          login: getLogin(e._id),
          I_ANN: (getInters && getInters.I_ANN) || 0,
          I_APR: (getInters && getInters.I_APR) || 0,
          I_AVR: (getInters && getInters.I_AVR) || 0,
          I_ENC: (getInters && getInters.I_ENC) || 0,
          I_VRF: (getInters && getInters.I_VRF) || 0,
          total: (getInters && getInters.total) || 0,
          CBRegle: (getInters && getInters.CBRegle
            ? (getInters.CBRegle / getInters.CBRegleTotal) * 100 || 0
            : 0
          ).toFixed(2),
          panierMoyen: (getInters && getInters.panierMoyen.toFixed(2)) || 0,
          uniqueCalls: (getInters && getInters.uniqueCalls) || 0,
          CA: (getInters && getInters.CA) || 0,
          callWaiting: getInters && getInters.callWaiting,
        };
      }),
  [S_LEAD]: (data, users) =>
    users
      .filter((e) => [S_LEAD, S_INT].includes(e.service) && e.act)
      .map((e) => {
        const getInters = data.find((user) => user._id === e._id);
        return {
          login: getLogin(e._id),
          I_ANN: (getInters && getInters.I_ANN) || 0,
          I_APR: (getInters && getInters.I_APR) || 0,
          I_AVR: (getInters && getInters.I_AVR) || 0,
          I_ENC: (getInters && getInters.I_ENC) || 0,
          I_VRF: (getInters && getInters.I_VRF) || 0,
          total: (getInters && getInters.total) || 0,
          CBRegle: (getInters && getInters.CBRegle
            ? (getInters.CBRegle / getInters.CBRegleTotal) * 100 || 0
            : 0
          ).toFixed(2),
          panierMoyen: (getInters && getInters.panierMoyen.toFixed(2)) || 0,
          uniqueCalls: (getInters && getInters.uniqueCalls) || 0,
          CA: (getInters && getInters.CA) || 0,
        };
      }),
};

const servicesData = iServices.filter((e) =>
  [S_INT, S_DEVIS, S_LEAD].includes(e._id)
);
class ClassementTable extends Component {
  state = {
    order: 'desc',
    orderBy: 'total',
    page: 0,
    rowsPerPage: 10,
    data: [],
    mode: 'day',
    from: moment().startOf('day').toDate(),
    to: moment().endOf('day').toDate(),
    service: this.props.user.service,
  };

  componentDidMount() {
    this.loadStats();
  }

  loadStats = () => {
    const { users, user } = this.props;
    const { from, to, service } = this.state;
    try {
      if (List.isList(users)) {
        this.handleChangeLoading(true);
        api.stats
          .custom('commercial/nbrInter')
          .get({ from, to, service })
          .then((data) => data.body().map((e) => e.data()))
          .then((res) => {
            this.setState({
              data: formatData[service](res, users, user),
            });
          })
          .finally(() => this.handleChangeLoading(false));
      }
    } catch (e) {
      notifSystem.error('Erreur', 'Les stats ne peuvent pas être chargées');
    }
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }
    this.setState({ order, orderBy });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  onDatesChange = (from, to, mode) => {
    this.setState({ from, to, mode }, this.loadStats);
  };

  handleChangeService = (e) => {
    this.setState({ service: e.target.value }, this.loadStats);
  };

  handleChangeLoading = (loading) => {
    this.setState({ loading });
  };

  render() {
    const {
      order,
      orderBy,
      rowsPerPage,
      page,
      data,
      from,
      to,
      service,
      mode,
      loading,
    } = this.state;
    const { classes, user } = this.props;
    return (
      <StatsPanel
        onDatesChange={this.onDatesChange}
        title={'Classement'}
        defaultState={{ mode, from, to }}
        range={[true]}
        body={
          permit(user) && (
            <Dropdown
              title={'Services'}
              data={servicesData}
              isSelected={(e) => servicesData.find((s) => s._id === e).name}
              multiple={false}
              onChange={this.handleChangeService}
            />
          )
        }
      >
        {loading ? (
          <div className={classes.loadingDiv}>
            <CircularProgress size={50} color="secondary" />
            <Typography variant="h6">Chargement en cours...</Typography>
          </div>
        ) : (
          <Table className={classes.table} aria-labelledby="tableTitle">
            <StatsTableHeader
              order={order}
              orderBy={orderBy}
              mode={mode}
              onRequestSort={this.handleRequestSort}
              handleChangeMode={this.handleChangeMode}
              service={service}
            />
            <ComponentToDisplay
              order={order}
              orderBy={orderBy}
              data={data}
              page={page}
              rowsPerPage={rowsPerPage}
              service={service}
            />
            <StatsTableFooter
              count={List.isList(data) ? data.size : data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              handleChangePage={this.handleChangePage}
              handleChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          </Table>
        )}
      </StatsPanel>
    );
  }
}

ClassementTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  connect(mapStateToProps),
  withStyles(styles)
)(ClassementTable);
