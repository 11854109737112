import React, { PureComponent } from 'react';
import { moment } from '@bit/dev-lba.lib.local-globals/moment';
import ReactEcharts from 'echarts-for-react';

import { Paper } from '@material-ui/core';
import { blue, red, grey, deepOrange, indigo } from '@material-ui/core/colors';
import api from '../../../api';
import notifSystem from '../../../notifSystem';
import StatsPanel from '../common/panel/StatsPanel';
import { getOption } from '../common/graph/options/globalOptions';
import { setDialog } from '../../../actions/dialog';
import store from '../../../store';
import { modes, modeIndex } from '@bit/dev-lba.lib.local-globals/stats';
import {
  I_VRF,
  I_ENC,
  I_AVR,
  I_ANN,
  I_DVG,
  I_PRT,
  I_REF,
} from '@bit/dev-lba.lib.local-globals/iStatus';
import Dropdown from '../common/dropdown/dropdown';
import handlerFilters from '../../List/data/handlerFilterColumns';
import { S_INT } from '@bit/dev-lba.lib.local-globals/services';
import {
  D_REF,
  R_CLI,
  R_BTOB,
} from '@bit/dev-lba.lib.local-globals/recStatus';

const fromatNames = (e) =>
  `${e.seriesName}: ${
    {
      Decote: `${e.value}%`,
      Delta: `${e.value}%`,
      'En cours': `${e.value}k`,
      'Decote de l\'équipe': `${e.value}%`,
      'Sauvegardé à 20h': `${e.value}%`,
      'A la date d\'ajourd\'hui': `${e.value}%`,
    }[e.seriesName] || e.value
  }`;

const filters = () => ({
  Decote: {
    $or: [
      {
        $expr: {
          $lt: ['$finalPrice', '$savedFinalPrice'],
        },
        savedFinalPrice: { $exists: true },
      },
      {
        status: { $in: [I_ANN, I_DVG, I_PRT, I_REF] },
        savedFinalPrice: { $exists: true },
      },
      {
        status: I_VRF,
        isProforma: true,
        isRegle: false,
      },
      {
        status: I_AVR,
        noPaymentReceived: true,
        isRegle: false,
      },
      {
        status: { $in: [I_AVR, I_ENC, I_VRF] },
        noPaymentReceived: { $ne: true },
        isRegle: false,
        onSitePayment: true,
        'date.intervention': { $lt: moment().add(-3, 'week').toDate() },
      },
      {
        status: { $in: [I_AVR, I_VRF] },
        'recovery.status': { $in: [D_REF, R_CLI, R_BTOB] },
        isRegle: false,
      },
    ],
  },
  'En cours': {
    status: { $in: [I_ENC, I_AVR] },
  },
  'Sauvegardé à 20h': {
    status: { $in: [I_VRF, I_ENC, I_AVR] },
  },
  'A la date d\'ajourd\'hui': {
    status: { $in: [I_VRF, I_ENC, I_AVR] },
  },
});

class GraphStatsObjectif extends PureComponent {
  constructor(props) {
    super(props);
    const users = props.allUsers
      .toJS()
      .filter((s) => s.service === S_INT && s.act);
    this.state = {
      mode: 'month',
      from: moment().startOf('month').toDate(),
      to: moment().endOf('month').toDate(),
      selectedUsers: [],
      users,
      towardsSort: true,
      sort: { savedFinalPrice: -1 },
    };
  }

  componentDidMount() {
    this.doRequests();
  }

  onDatesChange = (from, to, mode) => {
    this.setState({ from, to, mode }, this.doRequests);
  };

  handlerFilterByColumns = (e) => {
    const { towardsSort } = this.state;
    let field = e.currentTarget.getAttribute('value');
    this.setState({
      sort: {
        [handlerFilters['Interventions'][field].fields]: !towardsSort ? 1 : -1,
      },
      towardsSort: !towardsSort,
    });
  };

  handleDialogInterListOpen = ({ dataIndex, seriesName }) => {
    const { mode, from, selectedUsers, sort } = this.state;
    let typeMode = modes[mode];
    let index = Object.keys(modeIndex[mode]).find(
      (e) => modeIndex[mode][e] === dataIndex
    );
    if ((typeMode === 'day' && mode === 'week') || typeMode === 'month') {
      index = index - 1;
    } else if (typeMode === 'day') {
      typeMode = 'date';
    }
    const data = {
      ...(filters({ from, typeMode, index })[seriesName] || {}),
      'date.ajout': {
        $gt: moment(from).set(typeMode, index).startOf(typeMode).toDate(),
        $lt: moment(from).set(typeMode, index).endOf(typeMode).toDate(),
      },
      ...(selectedUsers.length && { 'login.ajout': { $in: selectedUsers } }),
    };
    return store.dispatch(
      setDialog({
        name: 'InterListDialog',
        open: true,
        hideClose: true,
        dialogProps: {
          title: 'Liste d\'intervention',
          maxWidth: 'xl',
        },
        contentProps: {
          filter: data,
          handlerFilterByColumns: this.handlerFilterByColumns,
          filteredByColumn: true,
          sort,
          count: 100,
          displayName: 'decote',
        },
        actions: [
          {
            children: 'Fermer',
            color: 'secondary',
            onClick: (r, close) => close(),
          },
        ],
      })
    );
  };

  doRequests = () => {
    const { mode, from, to, selectedUsers } = this.state;
    api.stats
      .custom('commercial/statsObjectifs')
      .get({ mode, from, to, users: selectedUsers })
      .then((result) => this.setState({ data: result.body().data().data }))
      .catch(() =>
        notifSystem.error('Erreur', 'Impossible de recuperer les statistiques')
      );
  };

  selectUser = (e) => {
    const value = e.target.value[1];
    let selectedUsers = [...this.state.selectedUsers];
    const users = this.state.users;
    const chef = users.find((s) => s._id === value && s.equipe?.ids);
    if (chef) {
      selectedUsers =
        selectedUsers.length === chef.equipe.ids.length + 1
          ? []
          : [chef._id, ...chef.equipe.ids];
    } else if (selectedUsers.includes(value)) {
      selectedUsers = selectedUsers.filter((u) => u !== value);
    } else {
      selectedUsers.push(value);
    }

    this.setState({ selectedUsers }, this.doRequests);
  };

  render() {
    const { mode, from, to, data, users, selectedUsers } = this.state;
    return (
      <React.Fragment>
        <Paper>
          <StatsPanel
            title={'Statistiques des objectifs'}
            onDatesChange={this.onDatesChange}
            defaultState={{ mode, from, to }}
            enabledModes={[false, true, true, true]}
            body={
              <Dropdown
                title={'Utilisateurs'}
                isSelected={(e) => selectedUsers.includes(e)}
                data={users}
                renderKey="login"
                onChange={this.selectUser}
              />
            }
          >
            {data && (
              <ReactEcharts
                notMerge={true}
                lazyUpdate={true}
                style={{ height: 380 }}
                option={getOption({
                  data,
                  mode,
                  showLabelOnTop: true,
                  dates: [moment(from), moment(to)],
                  colors: [
                    blue[500],
                    grey[600],
                    red[500],
                    indigo[500],
                    deepOrange[500],
                  ],
                  disposition: ['line', 'line', 'bar', 'bar'],
                  formatter: (params) =>
                    params.map((e) => fromatNames(e)).join('<br/>'),
                  lineStyle: [{ type: 'dotted' }, { type: 'dotted' }],
                  labelPoisition: 'top',
                  selected: [true, true, true, false, false, false],
                })}
                onEvents={{ click: this.handleDialogInterListOpen }}
              />
            )}
          </StatsPanel>
        </Paper>
      </React.Fragment>
    );
  }
}

export default GraphStatsObjectif;
