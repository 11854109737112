import React, { Component } from 'react';
import classnames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import {
  Collapse,
  IconButton,
  Typography,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';

import TwoColumnList from './TwoColumnList';

const styles = theme => ({
  flexBox: {
    display: 'flex',
    padding: 5,
  },
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  title: {
    fontWeight: 'bold',
    margin: 'auto 0 auto 5px',
  }
});

class CollapsedTwoColumnList extends Component {
  state = {
    open: false
  }

  toggleOpen = () =>
    this.setState(({ open }) => ({ open: !open }))

  render() {
    const { open } = this.state;
    const {
      classes,
      title = '',
      data = {}
    } = this.props;

    return <div>
      <div className={classes.flexBox}>
        <IconButton
          className={classnames(classes.expand, {
            [classes.expandOpen]: open,
          })}
          onClick={this.toggleOpen}
          aria-expanded={open}
          children={<ExpandMore />}
        />
        <Typography
          className={classes.title}
          variant="subtitle1"
          children={title}
        />
      </div>
      <Collapse in={open} timeout={500}>
        <TwoColumnList data={data} />
      </Collapse>
    </div>;
  }
}

export default withStyles(styles)(CollapsedTwoColumnList);
