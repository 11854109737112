import React from 'react';
import { divide, formatPrice } from '../../../../utils/function';

const PercentSpan = ({ first, second }) => {
  let percent = divide((first - second) * 100, second);
  percent = !isFinite(percent) || isNaN(percent) ? 0 : percent;
  return (
    <span style={{ color: percent >= 0 ? 'green' : 'red' }}>
      ({percent < 0 || '+'}
      {percent}%)
    </span>
  );
};

export default ({ classes, current, last }) => (
  <table className={classes.sheetTable}>
    <tbody>
      <tr>
        <td>
          <span className={classes.sheetTitleText}>
            {formatPrice(current('expectedTurnover'))}
          </span>{' '}
          <span>
            de chiffre prévisionnel&nbsp;
            <PercentSpan
              first={current('expectedTurnover')}
              second={last('expectedTurnover')}
            />{' '}
          </span>
        </td>
        <td>
          <span className={classes.sheetTitleRed}>
            {formatPrice(current('annulationTurnover'))}&nbsp; (
            {divide(
              current('annulationTurnover') * 100,
              current('expectedTurnover'),
              2
            )}
            %)
          </span>
          <span>
            &nbsp;d'annulation&nbsp;
            <PercentSpan
              first={last('annulationTurnover')}
              second={current('annulationTurnover')}
            />
          </span>
        </td>
      </tr>
      <tr>
        <td>
          <span className={classes.sheetTitleText}>
            {formatPrice(
              current('expectedTurnover') / current('totalIntervention')
            )}
          </span>{' '}
          <span>
            de panier moyen&nbsp;
            <PercentSpan
              first={current('expectedTurnover') / current('totalIntervention')}
              second={last('expectedTurnover') / last('totalIntervention')}
            />
          </span>
        </td>
        <td>
          <span className={classes.sheetTitleRed}>
            {divide(
              current('noCall') * 100,
              current('totalInterventionFailed'),
              2
            )}
            %
          </span>{' '}
          <span>
            de client non appelé&nbsp;
            <PercentSpan
              first={divide(
                last('noCall') * 100,
                last('totalInterventionFailed')
              )}
              second={divide(
                current('noCall') * 100,
                current('totalInterventionFailed')
              )}
            />
          </span>
        </td>
      </tr>
      <tr>
        <td>
          <span className={classes.sheetTitleText}>
            &#126; {divide(current('totalIntervention'), current('workingDay'))}
          </span>
          <span>
            &nbsp;interventions ajouté&nbsp;
            <PercentSpan
              first={divide(
                current('totalIntervention'),
                current('workingDay')
              )}
              second={divide(last('totalIntervention'), last('workingDay'))}
            />
          </span>
        </td>
        <td>
          <span className={classes.sheetTitleText}>
            &#126;
            {divide(current('totalDIntervention'), current('workingDay'), 2)}
          </span>
          <span>
            &nbsp;interventions à démarcher&nbsp;
            <PercentSpan
              first={divide(
                current('totalDIntervention'),
                current('workingDay')
              )}
              second={divide(last('totalDIntervention'), last('workingDay'))}
            />
          </span>
        </td>
      </tr>
      <tr>
        <td>
          <span className={classes.sheetTitleText}>
            &#126;
            {divide(current('totalDevis'), current('workingDay'))}
          </span>
          <span>
            &nbsp;devis ajouté&nbsp;
            <PercentSpan
              first={divide(current('totalDevis'), current('workingDay'))}
              second={divide(last('totngalDevis'), last('workingDay'))}
            />
          </span>
        </td>
        <td>
          <span className={classes.sheetTitleText}>
            &#126;
            {divide(current('totalTDevis'), current('workingDay'))}
          </span>
          <span>
            &nbsp;devis transferé&nbsp;
            <PercentSpan
              first={divide(current('totalTDevis'), current('workingDay'))}
              second={divide(last('totalTDevis'), last('workingDay'))}
            />
          </span>
        </td>
      </tr>
      <tr>
        <td>
          <span className={classes.sheetTitleText}>
            &#126;&nbsp;
            {divide(current('totalCalls'), current('workingDay'))}
          </span>{' '}
          <span>
            appels uniques moyens&nbsp;
            <PercentSpan
              first={divide(current('totalCalls'), current('workingDay'))}
              second={divide(last('totalCalls'), last('workingDay'))}
            />
          </span>
        </td>
        <td>
          <span className={classes.sheetTitleRed}>
            {current('signalements')}
          </span>{' '}
          <span>
            &nbsp;signalements&nbsp;
            <PercentSpan
              first={last('signalements')}
              second={current('signalements')}
            />
          </span>
        </td>
      </tr>
    </tbody>
  </table>
);
