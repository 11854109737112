import React from 'react';

import { NOT_CONFIRMED } from '@bit/dev-lba.lib.local-globals/permissions';

import componentLoader from '../../componentLoader';

const ListAchat = React.lazy(() =>
  componentLoader(() => import('../../views/List/ListAchat'))
);

const ListFournitures = React.lazy(() =>
  componentLoader(() => import('../../views/List/ListFournitures'))
);

const SuppliesInvoicesManager = React.lazy(() =>
  componentLoader(() => import('../../views/SuppliesInvoices'))
);

const ListInvoice = React.lazy(() =>
  componentLoader(() =>
    import('../../components/List/SuppliesInvoices/ListInvoice'))
);


export default [
  {
    path: '/fournitures/list',
    component: ListFournitures,
    restrictions: () => NOT_CONFIRMED
  },
  {
    path: '/suppliesInvoice/:fournisseur/:importName',
    component: ListInvoice,
    restrictions: () => NOT_CONFIRMED
  },
  {
    path: '/suppliesInvoice',
    component: SuppliesInvoicesManager,
    restrictions: () => NOT_CONFIRMED
  },
  {
    path: '/achat/list/:filter/:query',
    component: ListAchat,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/achat/list/:filter',
    component: ListAchat,
    restrictions: () => NOT_CONFIRMED,
  },
];
