import React from 'react';

import { formatDate } from '@bit/dev-lba.lib.local-globals/moment';

import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import {
  L_ADVD,
  L_PAYD,
  L_PAYMULTIONEY,
  L_PAYMULTI,
  L_PAYDIF,
  L_PAYART,
  L_PAYMULTIALMA,
} from '@bit/dev-lba.lib.local-globals/paymentCBActionTypes';
import api from '../../../../api';
import { transactionStatus } from '@bit/dev-lba.lib.local-globals/getDataById';
import { Divider } from '@material-ui/core';

const TransactionsStatus = {
  [L_PAYD]: ({ transaction, user }) => (
    <DialogContent>
      <Typography align="center" variant="subtitle1">
        Une demande de paiement total a dèja été envoyée le{' '}
        {formatDate(transaction.date, 'L')} par{' '}
        {user ? user.login : 'Automatique'}
      </Typography>
      <Typography align="center" variant="subtitle1">
        Voulez-vous faire une nouvelle demande ?
      </Typography>
    </DialogContent>
  ),
  [L_ADVD]: ({ transaction, user }) => (
    <DialogContent>
      <Typography align="center" variant="subtitle1">
        Une demande d'acompte a dèja été envoyée le{' '}
        {formatDate(transaction.date, 'L')} par{' '}
        {user ? user.login : 'Automatique'}
      </Typography>
      <Typography align="center" variant="subtitle1">
        Voulez-vous faire une nouvelle demande ?
      </Typography>
    </DialogContent>
  ),
  [L_PAYDIF]: ({ transactions, transaction, user }) => {
    if (transactions.length) {
      const currentTransaction = transactions[0];
      return (
        <DialogContent>
          <Typography align="center" variant="subtitle1">
            Une demande de paiement différé a dèja été envoyée le{' '}
            {formatDate(transaction.date, 'L')} par{' '}
            {user ? user.login : 'Automatique'}
          </Typography>
          <Typography align="center" variant="subtitle1">
            - Date de prélèvement prévue :{' '}
            {formatDate(currentTransaction.date.transaction)} | Status:{' '}
            {transactionStatus[currentTransaction.status].name}
          </Typography>
          <Typography align="center" variant="subtitle1">
            Voulez-vous faire une nouvelle demande ?
          </Typography>
        </DialogContent>
      );
    }
    return null;
  },
  [L_PAYMULTI]: ({ user, transactions, transaction }) => (
    <DialogContent>
      <Typography align="center" variant="subtitle1">
        Une demande de multi paiements a dèja été envoyée le{' '}
        {formatDate(transaction.date)} par {user ? user.login : 'Automatique'}
      </Typography>
      {transactions.map((e, i) => [
        <Typography align="center" variant="subtitle1">
          - {i + 1}: Date de prélèvement {formatDate(e.date.transaction)} |
          Status: {transactionStatus[e.status].name}
        </Typography>,
        <Divider />,
      ])}
      <Typography align="center" variant="subtitle1">
        Voulez-vous faire une nouvelle demande ?
      </Typography>
    </DialogContent>
  ),
  [L_PAYART]: ({ user, transaction }) => (
    <DialogContent>
      <Typography align="center" variant="subtitle1">
        Une demande de paiement artisan a dèja été envoyée le{' '}
        {formatDate(transaction.date, 'L')} par{' '}
        {user ? user.login : 'Automatique'}
      </Typography>
      <Typography align="center" variant="subtitle1">
        Voulez-vous faire une nouvelle demande ?
      </Typography>
    </DialogContent>
  ),
  [L_PAYMULTIONEY]: ({ transaction, user }) => (
    <DialogContent>
      <Typography align="center" variant="subtitle1">
        Une demande de paiement total via Oney a dèja été envoyée le{' '}
        {formatDate(transaction.date, 'L')} par{' '}
        {user ? user.login : 'Automatique'}
      </Typography>
      <Typography align="center" variant="subtitle1">
        Voulez-vous faire une nouvelle demande ?
      </Typography>
    </DialogContent>
  ),
  [L_PAYMULTIALMA]: ({ transaction, user }) => (
    <DialogContent>
      <Typography align="center" variant="subtitle1">
        Une demande de paiement total via Alma a dèja été envoyée le{' '}
        {formatDate(transaction.date, 'L')} par{' '}
        {user ? user.login : 'Automatique'}
      </Typography>
      <Typography align="center" variant="subtitle1">
        Voulez-vous faire une nouvelle demande ?
      </Typography>
    </DialogContent>
  ),
};

class TransactionSended extends React.Component {
  state = {
    transactions: [],
  };

  componentDidMount() {
    const {
      id,
      transaction: { type },
    } = this.props;
    if ([L_PAYMULTI, L_PAYDIF].includes(type)) {
      api.transactions.getAll({
        query: JSON.stringify({
          id,
        }),
      }).then((e) =>
        this.setState({
          transactions: e.body().map((s) => s.data()),
        })
      );
    }
  }

  render() {
    const {
      transaction: { type, login, url },
      users,
    } = this.props;
    const { transactions } = this.state;
    const ComponentToDisplay = TransactionsStatus[type];
    const user = users.find((e) => e._id === login);
    return [
      <ComponentToDisplay
        key={1}
        transactions={transactions}
        user={user}
        {...this.props}
      />,
      <Typography align="center" component="p" key={2} variant='caption'>
        Pour acceder directement à la page de paiement cliquez{' '}
        <a href={url} rel="noopener noreferrer" target="_blank">
          ici
        </a>
      </Typography>
    ];
  }
}

export default TransactionSended;
