import categories from '@bit/dev-lba.lib.local-globals/categories';

export const getOption = function (dataRadar) {
  let users = dataRadar.map(e => `${e.login} (${e.sumInter})`);
  return ({
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    toolbox: {
      feature: {
        saveAsImage: { show: true, title: 'Télécharger' }
      }
    },
    color: categories.data.map(e => e.color[500] ),
    legend: {
      data: categories.data.map(e => e.shortName ),
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: {
      type: 'value',
      max: 100,
      min: 0,
      axisLabel: {
        formatter: '{value}%'
      }
    },
    yAxis: {
      type: 'category',
      data: users
    },
    series: [
      {
        name: 'PL',
        type: 'bar',
        stack: '总量',
        label: {
          normal: {
            show: true,
            position: 'insideRight',
            formatter: '{c}%',
          },
        },
        data: dataRadar.map(e => (e.pPL ? e.pPL.toFixed() : null))
      },
      {
        name: 'CH',
        type: 'bar',
        stack: '总量',
        label: {
          normal: {
            show: true,
            position: 'insideRight',
            formatter: '{c}%'
          }
        },
        data: dataRadar.map(e => (e.pCH ? e.pCH.toFixed() : null))
      },
      {
        name: 'EL',
        type: 'bar',
        stack: '总量',
        label: {
          normal: {
            show: true,
            position: 'insideRight',
            formatter: '{c}%'
          }
        },
        data: dataRadar.map(e => (e.pEL ? e.pEL.toFixed() : null))
      },
      {
        name: 'SR',
        type: 'bar',
        stack: '总量',
        label: {
          normal: {
            show: true,
            position: 'insideRight',
            formatter: '{c}%'
          }
        },
        data: dataRadar.map(e => (e.pSR ? e.pSR.toFixed() : null))
      },
      {
        name: 'VT',
        type: 'bar',
        stack: '总量',
        label: {
          normal: {
            show: true,
            position: 'insideRight',
            formatter: '{c}%'
          }
        },
        data: dataRadar.map(e => (e.pVT ? e.pVT.toFixed() : null))
      },
      {
        name: 'AS',
        type: 'bar',
        stack: '总量',
        label: {
          normal: {
            show: true,
            position: 'insideRight',
            formatter: '{c}%'
          }
        },
        data: dataRadar.map(e => (e.pAS ? e.pAS.toFixed() : null))
      },
      {
        name: 'CL',
        type: 'bar',
        stack: '总量',
        label: {
          normal: {
            show: true,
            position: 'insideRight',
            formatter: '{c}%'
          }
        },
        data: dataRadar.map(e => (e.pCL ? e.pCL.toFixed() : null))
      },
      {
        name: 'RE',
        type: 'bar',
        stack: '总量',
        label: {
          normal: {
            show: true,
            position: 'insideRight',
            formatter: '{c}%'
          }
        },
        data: dataRadar.map(e => (e.pRE ? e.pRE.toFixed() : null))
      }]
  });
};
