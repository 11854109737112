/* eslint-disable max-len */
import React from 'react';

const SwitchLightIcon = ({ w = '80', h = '70', color = '#000', moreStyle = null }) => (
  <svg
    width={w}
    height={h}
    fill={color}
    viewBox="0 0 84 84"
    style={{ enableBackground: 'new 0 0 481.187 481.187', ...moreStyle }}
  >
    <g>
      <path d="M65.2,8.2H24.7c-1.4,0-2.7,0.5-3.7,1.4c0,0-0.1,0-0.1,0.1l-0.3,0.2c0,0,0,0,0,0l-5.8,5.4l-0.1,0.1
        c-0.9,1.1-1.7,2.9-1.7,4.3v47c0,3.4,2.8,6.1,6.2,6.1l40-0.1c1.1,0,2.6-0.5,3.5-1.3l5.7-4.9c0,0,0,0,0,0c1.5-1,2.4-2.7,2.4-4.6V13.9
        C71,10.8,68.4,8.2,65.2,8.2z M61.6,70c-0.5,0.4-1.5,0.8-2.2,0.8l-40,0.1c-2.3,0-4.3-1.9-4.3-4.2v-47c0-0.9,0.5-2.3,1.1-3l2.7-2.5
        V62c0,3.2,2.6,5.7,5.8,5.7h39.6L61.6,70z M69,62c0,2.1-1.7,3.8-3.8,3.8H24.7c-2.1,0-3.8-1.7-3.8-3.8V13.9c0-1,0.4-2,1.1-2.7
        l0.1-0.1c0.7-0.6,1.6-1,2.6-1h40.4c2.1,0,3.8,1.7,3.8,3.8V62z"></path>
      <path d="M52,23.8c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.2-0.4-0.3-0.7-0.3H39.1c-0.1,0-0.1,0-0.2,0c0,0-0.3,0-0.5,0.3
        c-0.2,0.3-0.2,0.6-0.2,0.6c0,0.1,0,0.4,0,0.8l0,28.2c0,0,0,0.3,0.2,0.6c0.3,0.4,0.6,0.4,0.7,0.4H51c0.2,0,0.4-0.1,0.6-0.2
        c4.5-3.9,4.5-9.8,4.5-10.1c0-0.2-0.1-0.4-0.2-0.6C51.9,37.6,52,24,52,23.8z M50.1,24.8c0,3.1,0.4,12.2,3.3,17.7h-9.6
        c-3.2-4.6-3.6-14.5-3.7-17.7H50.1z M40.1,39.1c0.6,1.8,1.3,3.4,2.2,4.7c-0.1,1-0.4,3.9-2.2,6.5L40.1,39.1z M50.7,51.8h-9.3
        c2.1-2.7,2.6-5.8,2.8-7.3h10C54,46.1,53.2,49.4,50.7,51.8z"></path>
      <path d="M46.2,19.8c0.6,0,1-0.4,1-1v0c0-0.6-0.4-1-1-1s-1,0.5-1,1C45.2,19.4,45.6,19.8,46.2,19.8z"></path>
      <path d="M47.2,57.8c-0.6,0-1,0.4-1,1c0,0.6,0.4,1,1,1s1-0.4,1-1v0C48.2,58.3,47.7,57.8,47.2,57.8z"></path>
    </g>
  </svg>
);

export default SwitchLightIcon;
