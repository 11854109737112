/* eslint-disable no-loop-func */
/* eslint-disable no-await-in-loop */
import { moment } from '@bit/dev-lba.lib.local-globals/moment';
import api from '../api';
import notifSystem from '../notifSystem';
import { throwIt } from '../utils/function';
import {
  createXlsxFile, blobType
} from '@bit/dev-lba.lib.local-globals/xlsxFunctions';
import collections from '../constants/collections';

const MAX_DOC = 10000;
const DISPLAY = 200;

export const createXLSX = (data, header) => {
  try {
    const title = `export_${moment().format('DD-MM-YYYY[_]HH:mm:ss')}`;
    const file = createXlsxFile({ data, header, title });
    const blob = new Blob([file], { type: blobType });
    let url = window.URL.createObjectURL(blob);
    let a = document.createElement('a');
    a.href = url;
    a.download = `export_${
      moment().format('L[_]HH:mm:ss')}.xlsx`;
    a.click();
  } catch (err) {
    throwIt(err);
  }
};

export const genDataAndCSV = async (obj) => {
  const {
    data = [],
    total,
    page = 0,
    collection,
    query,
    header
  } = obj;
  try {
    if (data.length >= total) {
      await createXLSX(data, header);
      return true;
    }
    const newData = await api[collection]
      .custom('getCSV')
      .get({ ...query, page, display: DISPLAY })
      .then((res) =>
        res.body().reduce(
          (a, b) => {
            a = a.concat(b.data());
            return a;
          },
          [...data]
        )
      )
      .catch((e) => throwIt(e));
    if (!newData.length ||
    newData.length === data.length) {
      return throwIt('aucunes données trouvées');
    }
    return genDataAndCSV({ ...obj, data: newData, page: page + 1 });
  } catch (e) {
    throwIt(e);
  }
};

export const additExportFields = {
  [collections.artisans]: {
    'level': 1
  }
};

export const exportList = async (collection, query, header) => {
  try {
    const total = await api[collection].getAll({
      ...query,
      call: 'countDocuments'
    }).then(e => e.body().data());
    if (total <= MAX_DOC) {
      await genDataAndCSV({
        total,
        query,
        collection,
        header
      });
    } else {
      throwIt('La limite d\'export est à 10000');
    }
  } catch (e) {
    notifSystem.error('erreur system', e.message || e);
  }
};
