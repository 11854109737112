import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  Grid,
  FormControl,
  FormControlLabel,
  Checkbox,
  FormGroup
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { setDialogContentProp, closeDialog } from '../../actions/dialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import Attachments from '../Utils/Attachments';
import {
  data as categorie
} from '@bit/dev-lba.lib.local-globals/attachmentsCategorie';

const styles = {
  center: {
    textAlign: 'center',
  },
  margin: {
    margin: '10px 0 20px'
  },
};
const setAttachments = setDialogContentProp('attachments');
const setCategories = setDialogContentProp('categories');

const mapStateToProps = ({
  dialog: {
    contentProps: {
      attachments
    },
  },
}) => ({
  attachments
});

const mapDispatchToProps = {
  addFile: (f, files) => setAttachments(files),
  setCategories: e => setCategories(e),
  deleteFile: (f, i, files) => setAttachments(files),
  closeDialog: closeDialog,
};

class DialogPrintfileTypes extends Component {
  state = {
    checked: []
  }

updateChecked = value => {
  const checked = [...this.state.checked];
  if (!checked.includes(value)) {
    checked.push(value);
  } else {
    checked.splice(checked.indexOf(value), 1);
  }
  this.setState({ checked }, () => this.props.setCategories(checked));
};


render() {
  const { classes,
    loading,
    attachments,
    addFile,
    deleteFile,
  } = this.props;
  return (loading ? <CircularProgress size={24} /> : (
    <Grid container spacing={1} alignItems="center">
      <>
        <Grid xs={12} className={`${classes.center} ${classes.margin}`}>
          <FormControl row component="fieldset" className={classes.formControl}>
            <FormGroup aria-label="position" row>
              {categorie.map((e, i) =>
                <FormControlLabel
                  key={i}
                  control={
                    <Checkbox
                      value={e._id}
                      onChange={() => this.updateChecked(e._id)}
                    />
                  }
                  label={e.name}
                />
              )}
            </FormGroup>
          </FormControl>
        </Grid>
      </>
      <Attachments
        data={attachments}
        onAdd={addFile}
        onDelete={deleteFile}
      />
    </Grid>)
  );
}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(DialogPrintfileTypes));
