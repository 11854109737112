/* eslint-disable max-lines */
import React from 'react';
import { moment } from '@bit/dev-lba.lib.local-globals/moment';
import classNames from 'classnames';
import { compose } from 'redux';
import { fromJS } from 'immutable';
import { ContextMenuTrigger } from 'react-contextmenu';

import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import TableBody from '@material-ui/core/TableBody';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableCollapse from '../TableCollapse';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import blue from '@material-ui/core/colors/blue';
import teal from '@material-ui/core/colors/teal';
import red from '@material-ui/core/colors/red';
import orange from '@material-ui/core/colors/orange';
import yellow from '@material-ui/core/colors/yellow';
import green from '@material-ui/core/colors/green';
import grey from '@material-ui/core/colors/grey';
import Box from '@material-ui/core/Box';
import styles from './Components/styles';
import { SAV_APR, SAV_ENC } from '@bit/dev-lba.lib.local-globals/SAVStatus';
import { NOT_QUA } from '@bit/dev-lba.lib.local-globals/aLevel';
import { AS_QUA } from '@bit/dev-lba.lib.local-globals/aSubStatus';
import { I_APR, I_ANN, I_ENC, I_AVR } from '@bit/dev-lba.lib.local-globals/iStatus';
import { D_PRE } from '@bit/dev-lba.lib.local-globals/deStatus';
import { R_POR } from '@bit/dev-lba.lib.local-globals/remStatus';
import { groupBy } from '@bit/dev-lba.lib.local-globals/functions';
import { data as postmarkStatus } from '@bit/dev-lba.lib.local-globals/postmarkStatus';
import {
  infoArtisans,
  infoClients,
} from '@bit/dev-lba.lib.local-globals/checkListInfoInter';

import CountDown from '../CountDown';
import ChronoLitiges from '../ChronoLitiges';
import Money from '../../utils/Money';
import { BankInfo } from '../BankInfo';
import { genChip } from '../../utils/chips';
import {
  IconsDisplay,
  forfaitIcon,
  CallIcon,
  cbPayment,
  devisIcon,
  subStatusDevisIcon,
  validInfosIcon,
  editIcon,
  mailOutlineIcon,
  mailOutlinePaddingIcon,
  demandesIcons,
  SupportPoints,
  SupplieProgressIcon,
  metiersStepsCounter,
  grandCompteIcon
} from '../../utils/IconsDisplay';
import { Types } from '../../utils/List/Types';
import {
  Person,
  LocalPostOffice,
  RemoveRedEye,
  Star,
  Info,
} from '@material-ui/icons';
import { Badge } from '@material-ui/core';
import { formatTooltipArtisan } from '../../actions/artisan';
import {
  firstCallDiff,
  getGrandCompteMarge,
  getGrandCompteMargePercent,
  getLogin,
} from '../../utils/function';
import { changeStatusColor } from '../Utils/changeStatusColor';
import collections from '../../constants/collections';
import { getTeam } from '../../utils/teams';
import ProgressLine from '../Utils/ProgressLine';
import {
  globalStatus,
  A_CONTACTER,
  G_INT,
} from '@bit/dev-lba.lib.local-globals/statusProsp';
import { D_REF } from '@bit/dev-lba.lib.local-globals/demandeStatus';

import { globalStatusProsp } from '@bit/dev-lba.lib.local-globals/getDataById';
import { displayMetierInList } from '../../actions/metiersSteps';

const avatarStyles = {
  undefined: { backgroundColor: '#000000' },
  1: { backgroundColor: green['500'] },
  2: { backgroundColor: orange['500'] },
};
moment.locale('fr', {
  relativeTime: {
    s: 'quelques s',
    m: 'une m',
    mm: '%d m',
    h: 'une h',
    hh: '%d h',
    d: 'un j',
    dd: '%d j',
  },
});

const civility = {
  Madame: 'Mme.',
  Monsieur: 'M.',
  Société: 'Soc.',
};

function genStatus(elem, short) {
  return (
    elem && (
      <span
        key={elem.name}
        style={{
          color: elem.color['500'],
          display: 'inline-flex',
        }}
      >
        {short ? elem.shortName : elem.name}
      </span>
    )
  );
}

function genButton(elem, short) {
  return (
    elem && (
      <Button
        key={elem.name}
        style={{
          backgroundColor: elem.color[elem.name === 'neutre' ? '600' : '500'],
          maxWidth: short ? '30%' : '',
          color: 'white',
          fontSize: '11px',
          margin: '0.6%',
          width: short ? '' : '100%',
          minWidth: 40,
        }}
      >
        {short ? elem.shortName : elem.name}
      </Button>
    )
  );
}

/*
  If date < 1 day return "il y a %s heures/"
  Else return DD/MM/YYYY
*/
function getMomentOrDate(date, width) {
  if (!date) {
    return;
  }

  if (
    moment(date) < moment().subtract(1, 'days') ||
    moment(date) > moment().add(1, 'days')
  ) {
    return width !== 'lg' && width !== 'xl'
      ? moment(date).format('DD/MM')
      : moment(date).format('DD/MM/YYYY');
  }
  if (moment(date).isAfter(moment().startOf('day')) &&
    moment(date).isBefore(moment())) {
    return `${moment(date).format('HH[h]mm')}`;
  }
  return width !== 'lg' && width !== 'xl'
    ? `${moment(date).format('HH[h]mm')}`
    : moment(date).fromNow();
}

const formatField = {
  login: ({ obj = '', users }) => (
    getLogin(obj.toLowerCase(), undefined, users) || '').toUpperCase(),
  date: ({ obj, width }) => getMomentOrDate(obj, width),
};

function getter(obj, arg, width = '', users) {
  let formatType = '';
  arg = arg.split('.');
  arg.forEach(e => {
    if (!obj) {
      return null;
    }
    if (e === 'date') {
      formatType = 'date';
    }
    if (e === 'login') {
      formatType = 'login';
    }
    obj = obj[e];
  });
  if (typeof obj === 'string') {
    obj =
      arg.includes('civility') && civility[obj]
        ? civility[obj]
        : obj.toUpperCase();
  }
  return formatType ? formatField[formatType]({ obj, width, users }) : obj;
}

function gettermail(obj) {
  const data = postmarkStatus.find(e => e._id === obj.postmarkmail);
  return data ? <LocalPostOffice style={{
    color: data.color ? data.color[500] : null,
    width: '0.8em'
  }} /> : '';
}

function genSourceLogo(props, elem, i) {
  if (props.tableData[i].logoArtisan && props.tableData[i].sourceArtisan) {
    const source = getter(elem, props.tableData[i].sourceArtisan);
    const sourceLogo = props.tableData[i].logoArtisan[source];
    return sourceLogo &&
      <span
        key={'spanLogo'}
        style={{ fontSize: 10, display: 'inline-block', width: '100%' }}
      >
        <Tooltip
          title={source}
          placement="top"
        >
          <img
            src={sourceLogo}
            alt="logo source artisan"
            style={{ width: '30px', height: 'auto' }}
          />
        </Tooltip>
      </span>;
  }
}

function genElemStatus(props, elem, i, subkeys) {
  const text =
    Array.isArray(subkeys) && subkeys.some(k => k.includes('currentArtisan'))
      ? subkeys.map(k => getter(elem, k)).join(' ')
      : getter(elem, subkeys[0]);
  const name = text ? `${text.charAt(0)}_${text.slice(-1)}` : '';
  if (props.tableData[i].status) {
    const chip = genStatus(
      (props.elem &&
        props.elem.status !== I_APR &&
        props.elem.sendArtisan &&
        props.elem.sendArtisan.subStatusArtisanInterv &&
        props.tableData[i].status.find(
          e => e._id === props.elem.sendArtisan.subStatusArtisanInterv
        )) ||
        props.tableData[i].status.find(
          e => e._id === getter(elem, props.tableData[i].key)
        ),
      (props.width !== 'lg' && props.width !== 'xl') || props.small
    );
    return [
      props.elem.artisan &&
      forfaitIcon(props),
      `${text || ''} `,
      <span key={'span'} style={{ fontSize: 10 }}>
        {chip}
      </span>,
      genSourceLogo(props, elem, i)
    ];
  }
  return (props.width !== 'lg' && props.width !== 'xl') || props.small
    ? name
    : getter(elem, subkeys) || '';
}

function genIcon(props, elem, i, key) {
  if (key === 'appel') {
    return <CallIcon {...props}/>;
  } else if (key === 'supplieProgress') {
    return <SupplieProgressIcon
      list={(props.tableData[i] || {}).list}
      elem={props.elem}
      classes={props.classes}
    />;
  } else if (
    props.elem.date &&
    props.elem.date.recouvrementEnd &&
    !props.elem.date.recouvrement
  ) {
    return (
      <CountDown
        timer={props.elem.date.recouvrementEnd}
        recouvrementTimer={true}
      />
    );
  } else if (
    props.elem.date &&
    props.elem.date.ajoutRecouvrement &&
    !props.elem.date.recouvrement
  ) {
    return <ChronoLitiges timer={props.elem.date.ajoutRecouvrement} />;
  }
}

function genDate(key, props, i, classes) {
  const hoursRemaining = moment(props.elem.date.intervention)
    .diff(moment(), 'hours');
  const minutesRemaining = moment(props.elem.date.intervention)
    .diff(moment(), 'minutes');
  if (props.tableData[i].blink
    && (hoursRemaining >= 0 && hoursRemaining <= 2)
    && minutesRemaining <= 120) {
    return <div
      className={classes.blink}
      style={{
        backgroundColor: props.tableData[i].color
      }}>
      {getter(props.elem, key, props.width)}
    </div>;
  } else if (
    key.includes('firstCall') &&
    props.elem.date.ajout && props.elem.date.firstCall
  ) {
    return firstCallDiff(props.elem.date.firstCall, props.elem.date.ajout);
  } else if (
    key.includes('intervention') &&
    !props.elem.definDClient &&
    !props.elem.definHClient
  ) {
    return getter(props.elem, key, props.width);
  }
  return getter(props.elem, key, props.width);
}

function colorRow({
  encaissement = 0,
  decaissement = 0,
  finalPrice = 0,
  announcedPrice = 0,
  products: { tva = 10 } = { tva: 10 },
  date,
  isRegle,
  login,
  pallier,
  locked,
  isMarketList,
}) {
  if (
    encaissement &&
    encaissement - decaissement &&
    encaissement - decaissement < (
      (finalPrice || announcedPrice) * ((tva / 100) + 1)) &&
    date &&
    !isRegle
  ) {
    return teal[50];
  }
  if (isRegle) {
    return green[200];
  }
  if (pallier) {
    return red[200];
  }
  if (locked) {
    return grey[500];
  }
  if (login && login.market && !isMarketList) {
    return grey[300];
  }

  return '';
}

function getTypes(classes) {
  const typeComponents = [
    (key, props, i) =>
      (typeof key === 'string' && key !== 'postmarkmail' && (
        <TableCell
          padding="checkbox"
          style={{ overflow: 'hidden' }}
          className={classNames(
            classes[props.tableData[i].class],
            classes[key === 'id' ? 'width-100' : ''],
            classes.overflowing
          )}
          key={key}
        >
          <div className={classes.avatarContainer}>
            <span>{getter(props.elem, key, props.width, props.users)}</span>
            {key === 'id' &&
              Object.keys(IconsDisplay).map((e) => IconsDisplay[e](props))}
            {key === 'companyName' && genSourceLogo(props, props.elem, i)}
          </div>
        </TableCell>
      )) ||
      (typeof key === 'string' && key === 'postmarkmail' && (
        <TableCell padding="checkbox" key={key}>
          {gettermail(props.elem)}
        </TableCell>
      )) || (
        <TableCell
          padding="checkbox"
          className={classNames(
            classes[props.tableData[i].class],
            classes['overflowing']
          )}
          key={key}
        >
          <Typography
            className={classes['grandCompteTooltip']}
            component="span"
          >
            {key.includes('client.society') &&
              getter(props.elem, key[0]) === 'Soc.'
              ? `${getter(props.elem, key[0])} ${
                getter(props.elem, key[3]) ||
              key
                .slice(1, 3)
                .map((sub) => `${getter(props.elem, sub) || ''} `)
                .join(' ')
              }`
              : key.map((sub) => `${getter(props.elem, sub) || ''} `)}
              {key.includes('client.name') && props.elem.billing &&
                props.elem.billing.grandCompte &&
                grandCompteIcon(props)
              }
            </Typography>
        </TableCell>
      ),
    (key, props, i) => {
      let elem = getter(props.elem, key);
      if (
        props.tableData[i].name === 'SAVSubStatus' &&
        key === 'subStatus' &&
        ![SAV_APR, SAV_ENC].includes(props.elem.status)
      ) {
        elem = '';
      } else if (key === 'subStatus' && props.elem.level !== NOT_QUA) {
        elem = AS_QUA;
      }
      let chips = null;
      if (elem && elem instanceof Array) {
        chips = (
          <div>
            {elem.map((o) =>
              genChip(
                props.tableData[i].list.find((e) => e._id === o),
                (props.width !== 'lg' && props.width !== 'xl') || props.small,
                elem.length
              )
            )}
          </div>
        );
      } else {
        let statusElem =
          props.tableData[i].list.find((e) => e._id === elem) || {};
        if (
          key === 'status' &&
          [collections.interventions, collections.recouvrements].includes(
            props.listen
          )
        ) {
          statusElem = changeStatusColor(props.elem, props.listen)[elem];
        }
        chips = genChip(
          statusElem,
          (props.width !== 'lg' && props.width !== 'xl') || props.small,
          1,
          props.elem && props.elem.emptyCancel && props.elem.status === I_ANN
        );
      }
      return (
        <TableCell padding="checkbox" key={key}>
          {chips}
        </TableCell>
      );
    },
    (key, props, i) => (
      <TableCell
        padding="checkbox"
        style={{
          color:
            props.elem.aDemarcher && props.elem.status === I_APR && red[500],
        }}
        className={classes[props.tableData[i].class]}
        key={key}
      >
        {(props.elem.aDemarcher &&
          props.elem.status === I_APR &&
          `${
            props.elem.aDemarcher === D_PRE
              ? 'A démarcher (en attente)'
              : 'A démarcher'
          } ${
            props.elem.prospSms ? `${props.elem.prospSms} sms reçus` : ''
          }`) ||
          genElemStatus(props, props.elem, i, key) ||
          ''}
      </TableCell>
    ),
    (key, props, i) => {
      if (key === 'finalPrice') {
        const products = getter(props.elem, 'products.list') || [];
        const comboIndex = ((props.elem || {}).products || {}).comboIndex;
        const productsGroup = groupBy(products, 'comboIndex');
        return (
          <TableCell key={key} style={{ padding: '4px 10px' }}>
            <div className={classes.priceDiv}>
              <div className={classes.price}>
                {!isNaN(comboIndex) && Object.keys(productsGroup).length > 1
                  ? ''
                  : `${Money.toString(
                    products.reduce((e, v) => e + (v.qt * v.pu), 0) || 0
                  )} \u20AC`}
              </div>
              {mailOutlinePaddingIcon(props, i)}
            </div>
          </TableCell>
        );
      } else {
        let moeny = Money.toString(getter(props.elem, key) || 0); 
        if (key === 'announcedPrice') {
          moeny = Money.toString(
            getter(
              props.elem,
              props.elem.status !== 4 ? 'announcedPrice' : 'finalPrice'
            ) || 0
          ); 
        } else if (key === 'savedFinalPrice') {
          moeny = Money.toString(
            getter(props.elem, key) || getter(props.elem, 'finalPrice')
          ); 
        }
        return (
          <TableCell
            key={key}
            style={{ minWidth: '180px', padding: '0px 0px' }}
          >
            <Grid
              container
              spacing={1}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={4}>
                <div style={{ textAlign: 'center' }}>
                  {moeny} {'\u20AC'}
                </div>
              </Grid>
              {key === 'announcedPrice' &&
                <Grid container item xs={8} spacing={2}>
                  <Grid item xs={2}>
                    {cbPayment(props)}
                  </Grid>
                  <Grid item xs={2}>
                    {mailOutlineIcon(props, i)}
                  </Grid>
                  <Grid item xs={2}>
                    {devisIcon(props)}
                  </Grid>
                  <Grid item xs={2}>
                    {subStatusDevisIcon(props)}
                  </Grid>
                  <Grid item xs={2}>
                    {validInfosIcon(props, infoArtisans, infoClients)}
                  </Grid>
                  <Grid item xs={2}>
                    {editIcon(props)}
                  </Grid>
                </Grid>
              }
            </Grid>
          </TableCell>
        );
      }
    },
    (key, props, i) => {
      if (
        props.elem.date &&
        props.elem.date.demarchage !== undefined &&
        props.elem.artisan === undefined
      ) {
        const user = props.users.find(
          (e) => props.elem.login && e._id === props.elem.login.demarchage
        );
        return (
          <TableCell
            padding="checkbox"
            style={{ fontWeight: 'bold' }}
            key={key}
          >
            <CountDown
              timer={props.elem.date.demarchageEnd}
              demarchagePause={props.elem.date.demarchagePause}
            >
              <span
                style={{
                  color: blue['500'],
                  display: 'inline',
                  marginLeft: '3px',
                }}
              >
                ({user && user.login})
              </span>
            </CountDown>
          </TableCell>
        );
      }
      return getTypes(props.classes)[Types.RefId](key, props, i);
    },
    (key, props, i) => {
      let avatarStyle = avatarStyles[getter(props.elem, key)] || {
        backgroundColor: red['500'],
      };
      return (
        typeof key === 'string' && (
          <TableCell
            padding="checkbox"
            className={classNames(
              classes[props.tableData[i].class],
              classes.avatarContainer
            )}
            key={key}
          >
            <Avatar style={avatarStyle}>
              {getter(props.elem, key) === undefined
                ? '0'
                : getter(props.elem, key)}
            </Avatar>
          </TableCell>
        )
      );
    },
    (key, props, i) => {
      const elem = getter(props.elem, key);
      return (
        <TableCell
          padding="checkbox"
          className={classes[props.tableData[i].class]}
          key={key}
        >
          {genIcon(props, elem, i, key)}
        </TableCell>
      );
    },
    (key, props, i) => {
      const elem = getter(props.elem, key);
      return (
        props.tableData[i].list && (
          <TableCell
            padding="checkbox"
            style={{ textAlign: 'center' }}
            className={classes[props.tableData[i].class]}
            key={key}
          >
            {(elem && elem instanceof Array && (
              <div>
                {elem.map((o) =>
                  genButton(
                    props.tableData[i].list.find((e) => e._id === o),
                    true
                  )
                )}
              </div>
            )) ||
              genButton(
                props.listen && collections.demandes &&
                elem === D_REF && props.elem.raisonRefus &&
                props.elem.raisonRefus.length < 25
                  ? {
                    ...props.tableData[i].list.find((e) => e._id === elem),
                    name: props.elem.raisonRefus
                  }
                  : props.tableData[i].list.find((e) => e._id === elem),
                (props.width !== 'lg' && props.width !== 'xl') || props.small
              )}
          </TableCell>
        )
      );
    },
    (key, props, i) => {
      const nStars = Number(getter(props.elem, key)) || 0;
      const color =
        (nStars < 3 && red[500]) || (nStars < 4 && orange[500]) || yellow[500];
      return (
        <TableCell
          padding="checkbox"
          style={{ textAlign: 'center' }}
          className={classes[props.tableData[i].class]}
          key={key}
        >
          {[...Array(nStars)].map(
            (e, i) => i < 5 && <Star key={i} style={{ color, fontSize: 15 }} />
          )}
        </TableCell>
      );
    },
    (key, props, i) => (
      <TableCell
        padding="checkbox"
        style={{ textAlign: 'center' }}
        className={classes[props.tableData[i].class]}
        key={key}
      >
        {key.includes('intervention') &&
            props.elem.definDClient &&
            props.elem.definHClient &&
            'RDV à définir'}
        {key.includes('intervention') &&
            !props.elem.definDClient &&
            props.elem.definHClient &&
            `${moment(props.elem.date.intervention).format('L')}
             heure à définir`}
        {key.includes('intervention') &&
            props.elem.definDClient &&
            !props.elem.definHClient &&
            `date à définir
             ${moment(props.elem.date.intervention).format('HH[h]mm')}`}
        {genDate(key, props, i, classes)}
      </TableCell>
    ),
    (key, props, i) => {
      let elem = '';
      if (
        key !== 'remuneration.pourcentage.labor' ||
        props.elem.remuneration.status === R_POR
      ) {
        elem = getter(props.elem, key, props.width);
      }

      return (
        <TableCell
          key={key}
          padding="checkbox"
          style={{ textAlign: 'center' }}
          className={classes[props.tableData[i].class]}
        >
          <Typography
            className={classes.bold}
            style={{
              color:
                (elem.status === R_POR &&
                  ((elem.pourcentage.labor >= 50 && red[500]) ||
                    (elem.pourcentage.labor >= 40 && orange['A200']))) ||
                null,
            }}
            children={
              elem.status === R_POR ? `${elem.pourcentage.labor}%` : 'FOR'
            }
          />
        </TableCell>
      );
    },
    (key, props) => (
      <TableCell key={key} style={{ textAlign: 'center' }}>
        <Button
          aria-label="Add"
          onClick={(e) => {
            e.stopPropagation();
            props.setDialog(BankInfo, true, fromJS(props.elem), () =>
              props.setDialog(null, false, null, null)
            );
          }}
        >
          <RemoveRedEye />
        </Button>
      </TableCell>
    ),
    (key, props, i) => (
      <TableCell
        padding="checkbox"
        style={{ overflow: 'hidden' }}
        className={classNames(
          classes[props.tableData[i].class],
          classes.overflowing
        )}
        key={key}
      >
        <div className={classes.avatarContainer}>
          <div className={classes.extendedIcon}>{demandesIcons(props)}</div>
          <span>
            {!key.slice(1, -1).map(s => getter(props.elem, s)).every(e => e)
              ? getter(props.elem, key[key.length - 1]) || ''
              : key.slice(
                key.includes('client.name') && !!getter(props.elem, key[1])
                  ? 1
                  : 0,
                -1
              )
                .map((sub) => `${getter(props.elem, sub) || ''} `)
                .join(' ')}
          </span>
        </div>
      </TableCell>
    ),
    (key, props, i) => (
      <TableCell
        padding="checkbox"
        style={{ overflow: 'hidden' }}
        className={classNames(
          classes[props.tableData[i].class],
          classes.overflowing
        )}
        key={key}
      >
        <div className={classes.avatarContainer}>
          {props.elem.artisans && props.elem.artisans.length ? (
            <div className={classes.extendedIcon}>
              <Badge badgeContent={props.elem.artisans.length}>
                <Tooltip title={formatTooltipArtisan(props.elem.artisans)}>
                  <Person />
                </Tooltip>
              </Badge>
            </div>
          ) : null}
          <b>{getter(props.elem, key, props.width)}</b>
        </div>
      </TableCell>
    ),
    (key, props, i) => {
      const element = getter(props.elem, key, props.width);
      return (
        <TableCell
          padding="checkbox"
          style={{ overflow: 'hidden' }}
          className={classNames(
            classes[props.tableData[i].class],
            classes.overflowing
          )}
          key={key}
        >
          <div className={classes.avatarContainer}>
            <b>{element}</b>
          </div>
        </TableCell>
      );
    },
    (key, props) => (
      <TableCell
        padding="checkbox"
        style={{ overflow: 'hidden' }}
        className={classes.overflowing}
        key={key}
      >
        <div className={classes.avatarContainer}>
          <b>{getGrandCompteMarge(props.elem)} €</b>
        </div>
      </TableCell>
    ),
    (key, props) => (
      <TableCell
        padding="checkbox"
        style={{ overflow: 'hidden' }}
        className={classes.overflowing}
        key={key}
      >
        <div className={classes.avatarContainer}>
          <b>{getGrandCompteMargePercent(props.elem)} %</b>
        </div>
      </TableCell>
    ),
    (key, props) => {
      let elem = getter(props.elem, key);
      const rac = elem ? elem.split('/').splice(2)[0] : '';
      return (
        <TableCell padding="checkbox" key={key}>
          {rac &&
            genChip(
              { name: rac, color: grey },
              (props.width !== 'lg' && props.width !== 'xl') || props.small,
              rac
            )}
        </TableCell>
      );
    },
    (key, props) => {
      const dateIntervention = moment(
        props.elem?.date?.intervention || new Date()
      );
      const diffrentDate = moment().diff(dateIntervention, 'days') + 1;
      return (
        <TableCell align="center" padding="checkbox" key={key}>
          <Box fontWeight="fontWeightBold">
            {[I_ENC, I_AVR].includes(props.elem?.status) && diffrentDate >= 0
              ? diffrentDate - (props.elem?.nbPointsAverifNotWorking || 0)
              : 0}
          </Box>
        </TableCell>
      );
    },
    (key, { elem }) => (
      <TableCell
        align="center"
        padding="checkbox"
        key={key}
        style={{ width: '10%' }}
      >
        <SupportPoints elem={elem} />
      </TableCell>
    ),
    (key, props, i) => (
      <TableCell
        padding="checkbox"
        style={{ overflow: 'hidden', textAlign: 'center' }}
        className={classNames(
          classes[props.tableData[i].class],
          classes.overflowing
        )}
        key={key}
      >
        <div>
          {genButton(
            props.tableData[i].list.find(
              (e) => e._id === getter(props.elem, 'categorie')
            ),
            true
          )}
        </div>
      </TableCell>
    ),
    (key, props) => (
      <TableCell padding="checkbox">
        {getTeam(
          props.elem,
          props.users.toJS(),
          props.listen
        ).login.toUpperCase()}
      </TableCell>
    ),
    (key, props) => {
      const openAction = (e) => {
        window.open(`/prosp/list?interSearch=${props.elem.id}`);
        e.stopPropagation();
      };
      if (props.elem.aDemarcher === D_PRE) {
        return (
          <TableCell padding="checkbox">
            <Button
              style={{
                textAligne: 'center',
                margin: 'auto',
                display: 'flex',
                width: '50%',
              }}
              variant="outlined"
              onClick={openAction}
            >
              {props.elem?.prosp?.total || 0}
            </Button>
          </TableCell>
        );
      }
      return (
        <TableCell padding="checkbox">
          {props.elem?.date?.demarchageDuration ? (
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={8} onClick={openAction}>
                <ProgressLine
                  visualParts={props.elem?.prosp?.statusCounters
                    .reduce((acc, curr) => {
                      const status = globalStatus.find((s) =>
                        s.childs.includes(curr.status)
                      );
                      const findElement = acc.findIndex(
                        (s) => s._id === status._id
                      );
                      if (~findElement) {
                        acc[findElement].count += curr.count;
                        acc[findElement].percentage += Math.trunc(
                          (100 * curr.count) / props.elem.prosp.total
                        );
                        acc[
                          findElement
                        ].title = `- ${status.name} (${acc[findElement].count})`;
                      } else {
                        acc.push({
                          title: `- ${status.name} (${curr.count})`,
                          percentage: Math.trunc(
                            (100 * curr.count) / props.elem.prosp.total
                          ),
                          color: status.color,
                          count: curr.count,
                          _id: status._id,
                        });
                      }
                      return acc;
                    }, [])
                    .sort((e, v) => v._id - e._id)}
                />
              </Grid>
              <Grid item xs={2}>
                <Typography>
                  {`${
                    props.elem?.prosp?.total
                      ? Math.trunc(
                        100 -
                            ((100 *
                              (props.elem?.prosp?.statusCounters.find(
                                (s) => s.status === A_CONTACTER
                              )?.count || 0)) /
                              props.elem.prosp.total)
                      )
                      : 0
                  }%`}
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <Button
              onClick={openAction}
              fullWidth
              variant="contained"
              color="primary"
            >{`GO (${props.elem?.prosp?.total || 0})`}</Button>
          )}
        </TableCell>
      );
    },
    (key, props) => {
      const status = (props.elem?.prosp?.statusCounters || [])
        .reduce((acc, curr) => {
          if (globalStatusProsp[G_INT].childs.includes(curr.status)) {
            acc.count += curr.count;
            acc.dispoStatus += curr.dispoStatus;
          }
          return acc;
        }, { count: 0, dispoStatus: 0 });
      return (
        <TableCell padding="checkbox">
          <Button
            style={{
              backgroundColor: status.count ? green[500] : red[500],
              textAligne: 'center',
              color: '#fff',
              margin: 'auto',
              display: 'flex',
              width: '50%',
            }}
            variant="contained"
            href={
              `/prosp/list/dispo?interSearch=${
                props.elem.id
              }`
            }
          >
            {status.dispoStatus || 0} / {status.count || 0}
          </Button>
        </TableCell>
      );
    },
    (key, props) => {
      const nb = Object.values(props.elem[key] || {})
        .reduce((acc, curr) => curr.checked ? acc + 1 : acc, 0);
      return (
        <TableCell
          padding="checkbox"
          key={key}
        >
          {`${nb}/${Object.keys(props.elem[key] || {}).length || 10}`}
        </TableCell>
      );
    },
    (key, props) => {
      const name = props.elem.metiersSteps?.name || '';
      return (
        <TableCell padding="checkbox" key={key}>
          <Tooltip title={name || ''}>
            {name ? <Typography style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
              {name.slice(0, 30)}
              {name.length > 30 ? '...' : ''}
              {metiersStepsCounter(props)}
            </Typography> : <Typography />}
          </Tooltip>
        </TableCell>
      );
    },
  ];
  return typeComponents;
}

const ListRow = props => {
  const {
    user,
    elem,
    keys,
    histories,
    appels,
    table,
    tableData,
    users,
    open,
    idMenu,
    colSpan,
    menu: Menu,
    setSelected,
    openDrop,
    classes,
    listen,
    name,
  } = props;

  return (
    <React.Fragment>
      <ContextMenuTrigger
        id={idMenu || 'SIMPLE'}
        holdToDisplay={850}
        collect={() => {
          setSelected(elem);
          return { elem };
        }}
        attributes={{
          style: { cursor: 'pointer' },
          onClick: () => openDrop(props),
        }}
        renderTag={TableBody}
      >
        <TableRow
          hover
          style={{ backgroundColor: colorRow(elem) }}
          children={keys.map((k, i) =>
            getTypes(classes)[table[i]](k, props, i)
          )}
        />
      </ContextMenuTrigger>
      {Menu && (
        <TableCollapse
          colSpan={colSpan}
          in={open === elem.id}
          transitionDuration="auto"
          unmountOnExit
        >
          <Menu
            histories={histories}
            appels={appels}
            elem={elem}
            users={users}
            user={user}
            listen={listen}
            name={name}
            artisan={
              tableData[6] &&
              tableData[6].list &&
              tableData[6].list.find((e) => e._id === elem.artisan)
            }
          />
        </TableCollapse>
      )}
    </React.Fragment>
  );
};

export default compose(withStyles(styles), withWidth())(ListRow);
