import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import {
  pricesCommissionDevis,
  getPointsDevisCommission,
} from '@bit/dev-lba.lib.local-globals/commissions';
import { moment } from '@bit/dev-lba.lib.local-globals/moment';

import genRows from './genRows';
import CustomMaterialTable from '../../../CustomMaterialTable';
import notifSystem from '../../../../notifSystem';
import actions from '../actions';

const columns = [
  {
    title: 'Note',
  },
  {
    title: 'Potentiel',
    colSpan: 2,
    align: 'center'
  },
  {
    title: 'Reçu',
    colSpan: 2,
    align: 'center'
  },
];

const styles = {
  itemsGrid: {
    maxHeight: 300,
    overflow: 'auto'
  }
};

const calculCommissionByCat = (data) =>
  data.reduce((acc, curr) => {
    const _id = getPointsDevisCommission(curr)
      .reduce((acc, curr) => {
        acc = acc + curr.point;
        return acc <= 5 ? acc : 5;
      }, 0);
    const index = acc.findIndex((_, i) => i === _id);
    if (~index) {
      const actual = acc[index];
      acc[index] = {
        nb: actual.nb + 1,
        inters: actual.inters.concat(curr),
      };
    }
    return acc;
  }, [...Array(6)].map(() => ({
    nb: 0, inters: []
  })));

const formatData = ({ recu, potentiels }) => {
  potentiels = calculCommissionByCat(potentiels);
  recu = calculCommissionByCat(recu);

  return [...Array(6)].map((e, i) => ({
    _id: i,
    pot: potentiels[i],
    recu: recu[i]
  }));
};

const getTotals = (data) =>
  data.reduce((acc, item) => {
    acc.pot.nb += item.pot.nb;
    acc.pot.inters = acc.pot.inters.concat(item.pot.inters);
    acc.pot.price += item.pot.nb * (pricesCommissionDevis[item._id] || 0);
    acc.recu.nb += item.recu.nb;
    acc.recu.inters = acc.recu.inters.concat(item.recu.inters);
    acc.recu.price += item.recu.nb * (pricesCommissionDevis[item._id] || 0);
    return acc;
  }, {
    pot: { nb: 0, inters: [], price: 0 },
    recu: { nb: 0, inters: [], price: 0 },
  });

class StatsSupportByCategory extends Component {
  state = {
    mode: 'month',
    from: moment().startOf('month').toDate(),
    data: [],
    totals: {
      pot: { nb: 0, inters: [], price: 0 },
      recu: { nb: 0, inters: [], price: 0 },
    }
  };

  componentDidMount() {
    this.doRequest();
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.doRequest();
    }
  }

  doRequest = () => {
    const { from, to, user, users, mode } = this.props;
    const newUser = users.find((e) => e._id === user) || {};
    actions.getCommissionsStatsByCategory(
      {
        from,
        to,
        findUser: {
          _id: newUser._id,
          supportIds: newUser.supportIds,
        },
        mode
      },
      (result) => {
        const data = formatData(result.data).sort((f, s) => s._id - f._id);
        this.setState({
          data,
          totals: getTotals(data)
        });
      },
      () => notifSystem.error(
        'Erreur',
        'Impossible de charger les statistiques'
      )
    );
  };

  openIntersDialog = (commissions) => {
    const { user } = this.state;

    actions.openCommisionIntersDialog({ user, commissions });
  }

  render() {
    const { data, totals } = this.state;
    return (
      <CustomMaterialTable
        columns={columns}
        components={genRows({
          totals,
          openIntersDialog: this.openIntersDialog
        })}
        data={data}
        options={{
          headerStyle: { textAlign: 'center' },
          cellStyle: { textAlign: 'center' },
          toolbar: false,
          search: false,
          sorting: true,
          pageSize: 6,
          paging: false
        }}
      />
    );
  }
}

export default withStyles(styles)(StatsSupportByCategory);
