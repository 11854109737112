import React, { PureComponent } from 'react';
import { Map } from 'immutable';
import {
  List, ListItem, ListItemText,
} from '@material-ui/core';

import SmsInput from './SmsInput';
import SmsMessage from './SmsMessage';
import { addArtisanFromSMS } from '../../actions/inter';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

const mapStateToProps = ({ users, userId }) =>
  ({ user: users.find(e => e._id === userId) });

class SmsChat extends PureComponent {
  bottom = null;
  itemRefs = {};

  componentDidMount() {
    this.bottom.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'start',
    });
  }

  componentDidUpdate(props) {
    if (!props.page) {
      this.bottom.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      });
    }
  }
  selectArtisan = (sms) => {
    const { history, user } = this.props;
    if (sms.artisan && sms.intervention) {
      addArtisanFromSMS(sms.intervention, sms.artisan, user, history);
    }
  }

  render() {
    const { getMoreMessage, data, canGetMore, id } = this.props;
    return (
      <div style={{ maxHeight: 500, overflow: 'auto' }}>
        {canGetMore && (
          <List component="nav">
            <ListItem button onClick={getMoreMessage}>
              <ListItemText primaryTypographyProps={{
                color: 'textSecondary',
                align: 'center'
              }} primary="Voir Plus" />
            </ListItem>
          </List>
        )}
        {data.map((e, i) =>
          <div ref={el => (this.itemRefs[i] = el)} key={i}>
            <SmsMessage
              id={id}
              message={e}
              selectArtisan={this.selectArtisan}
            />
          </div>
        )}

        <div
          ref={el => (this.bottom = el)}
          children={<SmsInput {...this.props} />}
        />
      </div>
    );
  }
}

SmsChat.defaultProps = {
  data: new Map(),
};

export default withRouter(connect(mapStateToProps)(SmsChat));
