import api from '../api';
import store from '../store';
import { setDialog } from './dialog';
import { moment } from '@bit/dev-lba.lib.local-globals/moment';

export const updateTransformCall = (call, update) => api.transformCall
  .custom(`update/${call.record.id}`)
  .patch({
    billing: call.billing,
    group: call.group,
    update,
  });

export const openReasonDialog = (userId, call, cb) => {
  const func = cb ? cb : updateTransformCall;
  store.dispatch(
    setDialog({
      name: 'ReasonCallDialog',
      open: true,
      dialogProps: {
        title: 'Définissez une raison de l\'appel',
        middle: true,
        middleAll: true,
        maxWidth: 'xs',
        fullWidth: true,
      },
      contentProps: {
        comment: '',
        reason: 'Mise en attente',
      },
      actions: [{
        children: 'Valider',
        color: 'primary',
        onClick: async (p, close) => {
          const { comment, reason } = p;
          await func(call, {
            history: {
              date: moment(),
              userId,
              action: reason,
              categorie: 2,
            },
          });
          if (comment) {
            await func(call, {
              history: {
                date: moment(),
                userId,
                action: comment,
                categorie: 4,
              },
            });
          }
          close();
        }
      }]
    })
  );
};
