import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import DayPicker, { DateUtils } from 'react-day-picker';
import MomentLocaleUtils from 'react-day-picker/moment';
import { moment, formatDate } from '@bit/dev-lba.lib.local-globals/moment';
import 'react-day-picker/lib/style.css';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import api from '../../../../api';
import notifSystem from '../../../../notifSystem';
import Dialog from './Dialog';
import { Grid, MenuItem } from '@material-ui/core';
import CustomSelect from '../../../CustomInputs/CustomSelect';
import {
  data as services, S_JUR, S_SATCL
} from '@bit/dev-lba.lib.local-globals/services';
import {
  permit
} from '@bit/dev-lba.lib.local-globals/restrictions';
import { H_VAL, H_ATT, H_PREV } from '@bit/dev-lba.lib.local-globals/hStatus';
import 'moment/locale/fr';

const styles = {
  dayStyle: {
    textAlign: 'center',
  },
};
/**
 * Returns true if selected dates are between existing holiday
 * @param {array} holidays - holidays taken by a group of users
 * @param {Date} from
 * @param {Date} to
 * @return {Boolean}
 */
const checkConflitDate = (holidays, from, to) =>
  holidays.some(
    (e) =>
      [H_VAL, H_ATT, H_PREV].includes(e.status) &&
      ((moment(from).isBefore(moment(e.after)) &&
        moment(to).isAfter(moment(e.after))) ||
        (moment(from).isBefore(moment(e.before)) &&
          moment(to).isAfter(moment(e.before))) ||
        (moment(from).isBefore(moment(e.after)) &&
          moment(to).isAfter(moment(e.before))))
  );

/**
 * Returns array of object formatted for react day picker disabledDays props
 * @param {array} holidays - holidays taken by a group of users
 * @return {[{after: Date, before: Date}]}
 */
const getDisabledDays = (holidays, users, user) =>
  holidays.reduce((arr, curr) => {
    if (![S_SATCL, S_JUR].includes(user?.service) &&
      users.find(u => u._id === curr.user)?.service === user?.service) {
      arr.push({
        after: moment(curr.start).add(-1, 'm').toDate(),
        before: moment(curr.end).add(1, 'm').toDate(),
        status: curr.status
      });
    }
    return arr;
  }, []);

const CustomButton = ({ color, disabled, text, onClick }) => (
  <Button
    color={color}
    variant="outlined"
    disabled={disabled}
    onClick={onClick}
    fullWidth
  >
    {text}
  </Button>
);
class HolidaysSelector extends PureComponent {
  state = {
    from: null,
    to: null,
  };

  reset = () => {
    this.setState({ from: null, to: null });
  };

  handleDayClick = (day, modifiers = {}) => {
    if (modifiers.disabled) {
      return;
    }
    const range = DateUtils.addDayToRange(day, this.state);
    this.setState(range);
  };

  validate = () => {
    const { from, to, selectedUser } = this.state;
    this.setState({ popup: false });
    api.users
      .custom('createHolidays')
      .post({
        from: moment(from)
          .startOf('day')
          .toDate(),
        to: moment(to)
          .endOf('day')
          .toDate(),
        selectedUser
      })
      .then(() => {
        notifSystem.success(
          'Succes',
          'Votre demande de congés a bien ete pris en compte'
        );
        this.reset();
      })
      .catch(e =>
        notifSystem.error(
          'Erreur',
          e.response
            ? e.response.data
            : e || 'Une erreur est survenue lors de la demande de congés'
        )
      );
  };

  handleChange = (path, value) => this.setState({
    [path]: value
  })

  render() {
    const { from, to, popup, service } = this.state;
    const { classes, data, users, user } = this.props;
    const modifiers = { start: from, end: to };
    return (
      <React.Fragment>
        {popup && (
          <Dialog
            closePopup={() => this.setState({ popup: false })}
            validate={this.validate}
            open={popup}
          />
        )}
        <Grid container spacing={1} justify="center" alignContent="center">
          {permit(user, { key: 'modifyHoliday' }) &&
          <Grid item container alignItems="center" xs={10} spacing={4}>
            <CustomSelect
              grid
              xs={6}
              label="Service"
              path={['service']}
              setData={this.handleChange}
            >
              {services.map((s, i) =>
                <MenuItem key={i} value={s._id}>
                  {s.name}
                </MenuItem>
              )}
            </CustomSelect>
            <CustomSelect
              grid
              disabled={!service}
              xs={6}
              label="Utilisateur"
              path={['selectedUser']}
              setData={this.handleChange}
            >
              {users.filter(u => u.service === service).map((s, i) =>
                <MenuItem key={i} value={s}>
                  {s.login}
                </MenuItem>
              )}
            </CustomSelect>
          </Grid>}
          <Grid item xs={12}>
            <Typography align={'center'} variant="subtitle1">
              {!from &&
                !to &&
                'Veuillez sélectionner le premier jour de congé.'}
              {from && !to && 'Veuillez sélectionner le dernier jour de congé.'}
              {from &&
                to &&
                `Congés pris du ${formatDate(from, 'dddd Do MMMM YYYY')} au
                      ${formatDate(to, 'dddd Do MMMM YYYY')}`}{' '}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.dayStyle}>
            <DayPicker
              localeUtils={MomentLocaleUtils}
              locale="fr"
              className="Selectable"
              selectedDays={[from, { from, to }]}
              modifiers={modifiers}
              disabledDays={[
                { before: new Date() },
                ...(!permit(user, { key: 'modifyHoliday' })
                  ? getDisabledDays(data, users, user)
                  : []),
              ]}
              onDayClick={this.handleDayClick}
              numberOfMonths={2}
            />
          </Grid>
          <Grid item xs={4}>
            <CustomButton
              color={'primary'}
              disabled={!(from && to) ||
                (!permit(user, { key: 'modifyHoliday' }) &&
                checkConflitDate(getDisabledDays(data, users, user), from, to))
              }
              text={'Valider'}
              onClick={() => this.setState({ popup: true })}
            />
          </Grid>
          <Grid item xs={4}>
            <CustomButton
              color={'secondary'}
              disabled={!(from || to)}
              text={'Reinitialiser'}
              onClick={this.reset}
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(HolidaysSelector);
