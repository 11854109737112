/* eslint-disable max-len */

import React from 'react';

const ToolsIcon = ({
  w = '80',
  h = '70',
  color = '#000',
  moreStyle = null,
}) => (
  <svg
    version="1.1"
    x="80px"
    y="80px"
    width={w}
    height={h}
    fill={color}
    viewBox="0 0 84 84"
    style={{ ...moreStyle }}
  >
    <g>
      <path
        d="M35.5,23.8H14.4c-0.9,0-1.8,0.4-2.5,1c-0.7,0.7-1,1.6-1,2.5v2.5c0,1,0.4,1.9,1,2.5c0.5,0.5,1.1,0.8,1.8,1
      l0,4.5c0,1.1,0.9,2,2,2c0,0,1.3,0,3,0l7,7.7c0.2,0.2,0.4,0.8,0.4,1.1v24.9c0,2.8,2.3,5.1,5.1,5.1c2.8,0,5.1-2.3,5.1-5.1l0-30.1
      c0-0.8,0.2-2,0.4-2.8c1.6-6.3,2.4-10,2.4-10.9v-2.5C39.1,25.4,37.5,23.8,35.5,23.8z M15.7,37.8C15.6,37.8,15.6,37.8,15.7,37.8
      l0-4.5h10.5c0,0,0,0,0,0v4.4c0,0,0,0,0,0C25.4,37.8,15.7,37.8,15.7,37.8z M37.1,29.8c0,0.5-0.6,3.5-2.4,10.4
      c-0.2,0.9-0.4,2.4-0.4,3.3l0,30.1c0,1.7-1.4,3.1-3.1,3.1s-3.1-1.4-3.1-3.1V48.7c0-0.8-0.4-1.9-0.9-2.5l-5.8-6.4c2.3,0,4.4,0,4.7,0
      c1.1,0,2-0.9,2-2v-4.4c0-1.1-0.9-2-2-2H14.4c-0.4,0-0.8-0.2-1.1-0.5c-0.3-0.3-0.5-0.7-0.5-1.1v-2.5c0-0.4,0.2-0.8,0.5-1.1
      c0.3-0.3,0.7-0.5,1.1-0.5h21.1c0.9,0,1.6,0.7,1.6,1.6V29.8z"
      />
      <path
        d="M16.5,22.7c0.6,0,1-0.4,1-1V10.4c0-1,0.8-2.3,1.8-2.8l0.1-0.1c0,0,2.6-1.6,5.2-0.5c0.7,0.3,6.3,2.8,9.1,4.1
      c0.4,0.2,0.8,0.8,0.8,1.3v1.1c0,0.3-0.3,0.6-0.6,0.6l-8.8,0c-1.4,0-2.6,1.1-2.6,2.5l0,5.1c0,0.5,0.4,1,1,1c0.6,0,1-0.4,1-1l0-5.1
      c0-0.3,0.3-0.6,0.6-0.6l8.8,0c1.4,0,2.6-1.1,2.6-2.5v-1.1c0-1.2-0.9-2.6-2-3.1c-0.3-0.2-8.3-3.7-9.2-4.1c-3.3-1.3-6.5,0.4-6.9,0.7
      c-1.6,0.8-2.9,2.8-2.9,4.6v11.3C15.5,22.2,15.9,22.7,16.5,22.7z"
      />
      <path
        d="M23.5,46.9c-0.5,0-1,0.5-1,1V52c0,0.9-0.7,1.6-1.6,1.6h-1.9c-0.9,0-1.6-0.7-1.6-1.6V41.7c0-0.6-0.4-1-1-1
      s-1,0.5-1,1V52c0,2,1.6,3.6,3.6,3.6h1.9c2,0,3.6-1.6,3.6-3.6v0v-4.1C24.5,47.4,24.1,46.9,23.5,46.9z"
      />
      <path
        d="M28,19.7c-0.7,0-1.3,0.3-1.7,0.8c-0.5,0.5-0.7,1.3-0.7,2c0,0.6,0.4,1.1,1,1.1h0c0.5,0,1-0.5,1-1.1
      c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.2-0.2,0.4-0.2H34c0.1,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.3,0.2,0.4c0,0.6,0.4,1.1,1,1.1h0
      c0.5,0,1-0.5,1-1.1c0-0.8-0.3-1.5-0.7-2c-0.5-0.5-1.1-0.8-1.7-0.8H28z"
      />
      <path
        d="M70,21.3l-12.1-5.6c-0.3-0.1-0.4-0.5-0.3-0.8l2.9-6.5c0.3-0.7,0.3-1.4,0-1.9c-0.4-0.6-1.2-0.9-2-0.7
      c-7,1.7-9.2,7.8-9.9,10.4c-0.3,1.2,0,2.8,0.5,3.7l6.3,13.1c0.3,0.7,0.6,1.9,0.5,2.6l-1.7,36.1c-0.1,1.9,0.6,3.7,1.9,5
      c1.2,1.3,2.8,2,4.5,2h0c1.7,0,3.3-0.7,4.5-1.9c1.3-1.3,1.9-3.1,1.9-5l-1.2-33.7c0-0.7,0.2-2,0.5-2.7l4.8-10.7
      C71.9,23.4,71.3,21.9,70,21.3z M69.5,23.9l-4.8,10.7c-0.4,0.9-0.7,2.5-0.7,3.5l1.2,33.7c0,1.4-0.4,2.6-1.3,3.5
      c-0.8,0.9-1.9,1.3-3.1,1.3c0,0-0.1,0-0.1,0c-1.2,0-2.3-0.5-3.1-1.4c-0.9-0.9-1.4-2.2-1.3-3.6l1.7-36.1c0-1.1-0.3-2.6-0.7-3.5
      L50.9,19c-0.3-0.7-0.5-1.8-0.3-2.4c0.5-2.1,2.3-7.1,7.7-8.8c0.3-0.1,0.4,0.1,0.3,0.3L55.8,14c-0.6,1.3,0,2.9,1.3,3.5l12.1,5.6
      C69.5,23.2,69.6,23.6,69.5,23.9z"
      />
      <path
        d="M69.3,10.5c-0.5-0.5-1.1-0.7-1.8-0.6c-0.7,0.1-1.3,0.6-1.7,1.3l-2.2,4.9c-0.2,0.5,0,1,0.5,1.3
      c0.5,0.2,1,0,1.3-0.5l2.2-4.9c0.1-0.1,0.2-0.2,0.2-0.2c0,0,0,0,0.1,0c1.1,1.1,2.3,3.4,2.2,7.8c0,0.5,0.4,1,0.9,1c0,0,0,0,0,0
      c0.5,0,0.9-0.4,0.9-0.9C72.2,14.7,70.7,12,69.3,10.5z"
      />
      <path
        d="M59.1,27.6c0.1,0.1,0.3,0.1,0.4,0.1c0.4,0,0.8-0.2,1-0.6l1.9-3.9c0.2-0.5,0-1.1-0.5-1.4
      c-0.5-0.2-1.2,0-1.4,0.5l-1.9,3.9C58.4,26.7,58.6,27.3,59.1,27.6z"
      />
      <path
        d="M60.6,27.2c-0.2,0.5,0,1.1,0.5,1.4c0.1,0.1,0.3,0.1,0.4,0.1c0.4,0,0.8-0.2,1-0.6l1.9-3.9
      c0.2-0.5,0-1.1-0.5-1.4c-0.5-0.2-1.2,0-1.4,0.5L60.6,27.2z"
      />
      <path
        d="M66.9,24.8c-0.5-0.2-1.2,0-1.4,0.5l-1.9,3.9c-0.2,0.5,0,1.1,0.5,1.4c0.1,0.1,0.3,0.1,0.4,0.1
      c0.4,0,0.8-0.2,1-0.6l1.9-3.9C67.6,25.6,67.4,25,66.9,24.8z"
      />
      <path
        d="M61.7,68.9c-0.4-0.2-0.8-0.3-1.2-0.3c-1.2,0-2.2,0.7-2.7,1.7c-0.3,0.7-0.4,1.5-0.1,2.3
      c0.3,0.8,0.8,1.3,1.6,1.7c0.4,0.2,0.8,0.3,1.2,0.3c1.2,0,2.2-0.7,2.7-1.7c0.3-0.7,0.4-1.5,0.1-2.3C63,69.9,62.5,69.3,61.7,68.9z
        M61.5,72.1c-0.2,0.5-0.9,0.8-1.4,0.5c-0.3-0.1-0.5-0.3-0.6-0.6c-0.1-0.3-0.1-0.6,0-0.8c0.2-0.4,0.6-0.6,1-0.6c0.2,0,0.3,0,0.4,0.1
      c0.3,0.1,0.5,0.3,0.6,0.6C61.6,71.6,61.6,71.9,61.5,72.1z"
      />
    </g>
  </svg>
);

export default ToolsIcon;
