const Csv = require('./Csv');

export default class StrConverter {
  constructor(input, output) {
    this.input = input;
    this.output = output;
  }
  getCsvInput(csvClass) {
    const csv = csvClass.toArray();
    return this.input.map((e) => {
      const index = [csv[0].indexOf(e)];
      if (index[0] === -1) {
        throw new Error(`Can't find ${this.input} in the given CSV`);
      }
      return index;
    });
  }

  toJson(csv) {
    const input = this.getCsvInput(csv);
    return csv.toArrayWithoutHeader().map((e) => {
      const obj = {};
      obj[this.output[0]] = e[input[0]];
      return obj;
    });
  }
  toCsv(json) {
    return new Csv([this.input, ...json.map((e) => [e[this.output[0]]])]);
  }
}
