import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  PlayArrow,
  Pause,
  VolumeUp,
  VolumeOff,
  RecordVoiceOver
} from '@material-ui/icons/';
import IconButton from '@material-ui/core/IconButton';
import { Grid, Slider, Typography } from '@material-ui/core';
import { moment } from '@bit/dev-lba.lib.local-globals/moment';
import PropTypes from 'prop-types';
import AudioDiscussion from './AudioDiscussion';
import { Description } from '@material-ui/icons';
import notifSystem from '../../../../front/src/notifSystem';
import { permit } from '@bit/dev-lba.lib.local-globals/restrictions';

const styles = {
  iconSmall: {
    fontSize: 20,
    color: '#000'
  },
  iconCenter: {
    display: 'flex',
    alignItems: 'center',
    padding: '0px 8px'
  },
  back: {
    borderRadius: 30,
    padding: 12
  },
  slider: {
    color: '#000'
  },
  track: {
    height: 3
  },
  thumb: {
    height: 0,
    '&:focus, &:hover, &$active': {
      height: 12,
      boxShadow: 'inherit',
    },
  },
  volumeThumb: {
    height: 12,
  },
  blurry: {
    filter: 'blur(0.2rem)',
    userSelect: 'none',
  }
};
class AudioPlayer extends Component {
  state = {
    play: false,
    audio: this.props.url,
    showTranscription: !!this.props.transcription,
    hide: this.props.hide || false,
    isPlayable: false
  };

  retrieveAudio = () => {
    this.audio = new Audio(this.props.url);
    this.setState({
      audio: this.props.url,
      showTranscription: !!this.props.transcription
    });
    if (this.audio) {
      this.audio.addEventListener('canplay',
        () => this.setState({ isPlayable: true }));
      this.autoPlay();
      this.audio.addEventListener('ended',
        () => this.setState({ play: false }));
      this.audio.addEventListener('loadeddata', () => this.setState({
        volume: this.audio.volume,
        duration: this.audio.duration
      }));
      this.audio.addEventListener('durationchange', () => {
        if (this.audio.duration !== Infinity) {
          this.setState({
            duration: this.audio.duration
          });
        }
      });
      this.audio.addEventListener('timeupdate', () => this.setState({
        currentTime: this.audio.currentTime
      }));
    }
  }

  componentDidMount() {
    this.retrieveAudio();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.url !== this.props.url) {
      this.audio.pause();
      this.setState({ play: false, isPlayable: false, currentTime: 0 });
      this.retrieveAudio();
    }
  }

  copieLink = (e, link) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(link);
      notifSystem.success('Message', 'Le lien a bien été copié');
    }
    e.stopPropagation();
  };

  componentWillUnmount() {
    this.audio.pause();
  }

  formatDuration = (duration) =>
    duration && moment.utc(duration * 1000).isValid()
      ? moment.utc(duration * 1000).format('m:ss')
      : '0:00' || '0:00'

  autoPlay = () => {
    if (this.props.autoPlay) {
      this.setState({ play: true }, () => {
        this.audio.play();
        if (this.props.cb) {
          this.props.cb();
        }
      });
    }
  }

  togglePlay = (event) => {
    const { dontPlay } = this.props;
    if (!dontPlay) {
      event.stopPropagation();
      this.setState({ play: !this.state.play }, () => {
        if (this.state.play) {
          if (this.props.cb) {
            this.props.cb();
          }
          this.audio.play();
        } else {
          this.audio.pause();
        }
      });
    } else if (this.props.cb) {
      this.props.cb();
    }
  };

  mute = (event) => {
    event.stopPropagation();
    const { volume } = this.state;
    return volume
      ? this.setState({ volume: 0 }, () => {
        this.audio.volume = 0;
      })
      : this.setState({ volume: 1 }, () => {
        this.audio.volume = 1;
      });
  }

  handleChangeVolume = (event, newValue) => {
    this.setState({ volume: newValue / 100 }, () => {
      this.audio.volume = newValue / 100;
    });
  };

  handleChangeTime = (event, newValue) => {
    this.setState({
      currentTime: ((newValue * this.state.duration) / 100)
    }, () => {
      this.audio.currentTime = ((newValue * this.state.duration) / 100);
    });
  };

  show = () => this.setState({
    showTranscription: !this.state.showTranscription
  })

  render() {
    const {
      classes,
      transcription,
      noPadding,
      noCopyIcon,
      forceHide
    } = this.props;
    const {
      volume,
      currentTime,
      duration,
      showTranscription,
      mouseOn,
      hide,
      audio,
      isPlayable
    } = this.state;
    return (<Grid container className={noPadding ? '' : classes.back}>
      <Grid item className={classes.iconCenter} xs={1}>
        <IconButton size="small"
          disabled={!this.state.duration || !audio || !isPlayable}
          onClick={this.togglePlay}
          children={this.state.play
            ? <Pause className={classes.iconSmall} />
            : <PlayArrow className={classes.iconSmall} />}
        />
      </Grid>
      <Grid item className={classes.iconCenter} xs={3}>
        <Typography children={`${this.formatDuration(this.state.currentTime)
        } / ${this.formatDuration(this.state.duration)}`}
        />
      </Grid>
      <Grid container item xs={transcription ? 6 : 7}
        onClick={(event) => event.stopPropagation()}
      >
        <Grid item xs={mouseOn ? 4 : 10} className={classes.iconCenter}>
          <Slider
            disabled={
              (!this.state.duration || this.state.duration === Infinity)
              || !audio
              || !isPlayable
            }
            classes={{ root: classes.slider, thumb: classes.thumb }}
            value={currentTime === 0 ? 0 : currentTime * 100 / duration || 0}
            onChange={this.handleChangeTime}
            aria-labelledby="continuous-slider"
          />
        </Grid>
        <Grid container item xs={mouseOn ? 8 : 1}
          onMouseLeave={() => this.setState({ mouseOn: false })}
          onMouseEnter={() => this.setState({ mouseOn: true })}
        >
          {mouseOn && <Grid className={classes.iconCenter} item xs={9}>
            <Slider
              disabled={!this.state.duration || !audio || !isPlayable}
              classes={{ thumb: classes.volumeThumb, track: classes.track }}
              value={volume === 0 ? 0 : volume * 100 || 100}
              onChange={this.handleChangeVolume}
              aria-labelledby="continuous-slider"
            />
          </Grid>}
          <Grid item xs={1} className={classes.iconCenter}>
            <IconButton onClick={this.mute} size="small" >
              {volume
                ? <VolumeUp className={classes.iconSmall} />
                : <VolumeOff className={classes.iconSmall} />}
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      {transcription && <Grid item className={classes.iconCenter} xs={1}>
        <IconButton onClick={this.show} size="small">
          <RecordVoiceOver className={classes.iconSmall} />
        </IconButton>
      </Grid>}
      {!noCopyIcon ? <Grid item className={classes.iconCenter} xs={1}>
        <IconButton
          size="small"
          onClick={(e) => this.copieLink(e, this.props.url)}
          children={<Description className={classes.iconSmall} />}
        />
      </Grid> : ''}
      {transcription && showTranscription && !hide && !forceHide &&
        <AudioDiscussion transcription={transcription}
          blurry={!permit(this.props.user, { key: 'accesCallToAndBackText' })
            && classes.blurry } />}
    </Grid>
    );
  }
}

AudioPlayer.PropTypes = {
  url: PropTypes.string.isRequired,
  transcription: PropTypes.string
};

export default withStyles(styles)(AudioPlayer);
