import React from 'react';
import { connect } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import { setDialogContentProp } from '../../actions/dialog';
import CustomTextField from '../CustomInputs/CustomTextField';

const setMotifCancel = setDialogContentProp('motifCancel');

const mapStateToProps = ({
  dialog: {
    contentProps: {
      defaultText,
      motifCancel,
    },
  },
}) => ({
  defaultText,
  motifCancel,
});


const mapDispatchToProps = {
  setMotifCancel
};

const ConfirmDialog = ({
  defaultText = { subtitle: '', warningMessage: '' },
  motifCancel,
  setMotifCancel
}) => <Grid container justifyContent="center">
  <Grid item xs={12}>
    {defaultText.warningMessage && defaultText.warningMessage.length
      ? <Typography
        align="center"
        variant="subtitle2"
        children={`⚠️⚠️ ${defaultText.warningMessage} ⚠️⚠️`}
      />
      : ''}
  </Grid>
  <Grid item xs={12}>
    {!!defaultText.subtitle &&
      defaultText.subtitle.split('\n')
        .map(e => <Typography align="center" children={e} />)}
  </Grid>
  <Grid item xs={10}>
    {defaultText.hasTextField &&
      <CustomTextField
        grid xs={12}
        label={defaultText.label || 'Motif*'}
        value={motifCancel}
        setData={(p, v) => setMotifCancel(v)}
        {...(defaultText.textFieldProps || {})}
      />}
  </Grid>
</Grid>;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmDialog);
