import React from 'react';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

const rows = [
  { id: 'type', label: '' },
  { id: 'mode', label: 'Type' },
  { id: 'from', label: 'Émetteur' },
  { id: 'to', label: 'Destinataire' },
  { id: 'date', label: 'Date' },
  { id: 'message', label: 'Message' },
];

const SMSTableHead = () => (
  <TableHead>
    <TableRow>
      {rows.map(row => (
        <TableCell key={row.id}>
          {row.label}
        </TableCell>
      ), this)}
    </TableRow>
  </TableHead>
);

export default SMSTableHead;
