/* eslint-disable max-len */
import React from 'react';

const ThermoIcon = ({ w = '80', h = '70', color = '#000', moreStyle = null }) => (
  <svg height={h} viewBox="0 0 84 84" width={w} fill={color} style={{ ...moreStyle }}>
    <g>
      <path d="M57.4,61.1c-0.2-0.2-0.5-0.6-0.5-0.9V9.9C57,6.6,54.4,4,51.1,4c-3.2,0-5.8,2.7-5.8,5.9v50.3
        c0,0.3-0.2,0.7-0.5,0.9c-2.6,2-4,5-4,8.3c0,5.8,4.6,10.5,10.3,10.5s10.3-4.7,10.3-10.5C61.5,66.1,60,63.1,57.4,61.1z M51.1,78.2
        c-4.8,0-8.6-3.9-8.6-8.8c0-2.7,1.2-5.3,3.4-6.9C46.5,62,47,61,47,60.2V9.9c0-2.3,1.9-4.2,4.1-4.2c2.3,0,4.1,1.9,4.1,4.2v50.3
        c0,0.8,0.5,1.7,1.1,2.2c2.1,1.7,3.4,4.2,3.4,6.9C59.8,74.2,55.9,78.2,51.1,78.2z"></path>
      <path d="M52.4,66V11.7c0-0.5-0.4-0.9-0.9-0.9c-0.5,0-0.9,0.4-0.9,0.9V66c-1.2,0.4-2.1,1.5-2.1,2.8c0,1.6,1.3,3,3,3
        c1.7,0,3-1.3,3-3C54.5,67.5,53.6,66.4,52.4,66z"></path>
      <path d="M64.6,52.8h-4.2c-0.5,0-0.9,0.4-0.9,1s0.4,1,0.9,1h4.2c0.5,0,0.9-0.4,0.9-1S65.1,52.8,64.6,52.8z"></path>
      <path d="M67.7,44.8h-7.4c-0.4,0-0.8,0.4-0.8,1s0.4,1,0.8,1h7.4c0.4,0,0.8-0.4,0.8-1S68.1,44.8,67.7,44.8z"></path>
      <path d="M60.4,38.8h4.2c0.5,0,0.9-0.4,0.9-1s-0.4-1-0.9-1h-4.2c-0.5,0-0.9,0.4-0.9,1S59.9,38.8,60.4,38.8z"></path>
      <path d="M67.7,28.8h-7.4c-0.4,0-0.8,0.4-0.8,1s0.4,1,0.8,1h7.4c0.4,0,0.8-0.4,0.8-1S68.1,28.8,67.7,28.8z"></path>
      <path d="M60.4,22.8h4.2c0.5,0,0.9-0.4,0.9-1s-0.4-1-0.9-1h-4.2c-0.5,0-0.9,0.4-0.9,1S59.9,22.8,60.4,22.8z"></path>
      <path d="M67.7,13.8h-7.4c-0.4,0-0.8,0.4-0.8,1s0.4,1,0.8,1h7.4c0.4,0,0.8-0.4,0.8-1S68.1,13.8,67.7,13.8z"></path>
      <path d="M35.5,38.9c-3.6-6.3-4.9-8.6-4.3-13.3c0.1-1-0.2-1.6-0.4-1.9c-0.3-0.3-0.7-0.5-1.2-0.5
        c-2.4,0-5.8,4.9-7.6,14.3c0,0.2-0.2,0.3-0.4,0.1c-0.2-0.2-0.3-0.4-0.4-0.5c-0.9-1-1.6-1.9-2.5-1.9c-0.3,0-0.6,0.1-0.8,0.3
        c-3.5,3.1-5.4,7.5-5.4,12.5c0,7.6,4.2,15.7,11.6,22.2c0.2,0.1,0.4,0.2,0.6,0.2c0.2,0,0.4-0.1,0.6-0.2c0.3-0.3,0.4-0.7,0.2-1.1
        c-0.1-0.1-6.7-11.6-1.9-20.6c0.1-0.2,0.4-0.3,0.6-0.2c0.4,0.2,0.9,0.3,1.4,0.3c1.8,0,3-1.4,3.5-4.1c0-0.2,0.2-0.3,0.3,0
        c1.4,2.8,2.8,7.7-0.2,12.5c-0.2,0.3-0.2,0.8,0.1,1.1c0.3,0.3,0.7,0.3,1.1,0.1c0.1-0.1,11.9-7.5,6.4-17.2
        C36.3,40.3,35.9,39.6,35.5,38.9z M32.5,54.4c-0.2,0.2-0.3,0.1-0.2-0.1c1.8-7-3-13.2-3.1-13.2c-0.2-0.3-0.6-0.4-1-0.3
        c-0.4,0.1-0.6,0.4-0.6,0.8c-0.1,3.5-0.9,5.5-2.1,5.5c-0.6,0-1.3-0.4-1.5-0.6c-0.2-0.2-0.4-0.2-0.7-0.2c-0.3,0-0.5,0.2-0.6,0.4
        c-4.2,6.3-2.8,13.8-1,18.6c0.1,0.2,0,0.3-0.1,0.1c-4.7-5.4-7.3-11.5-7.3-17.2c0-4.2,1.5-8,4.2-10.7c0.2-0.2,0.5-0.2,0.6,0
        c0.3,0.3,0.6,0.6,0.8,0.9c0.9,1,1.6,1.8,2.4,1.8c0.3,0,1.1-0.1,1.3-1.4c1.6-8.9,4.3-12.6,5.5-13.6c0.2-0.1,0.4-0.1,0.4,0
        c0,0.1,0,0.1,0,0.2c-0.7,5.2,0.9,8.1,4.5,14.3c0.4,0.7,0.8,1.4,1.3,2.2C38.2,47.3,35.2,51.8,32.5,54.4z"></path>
    </g>
  </svg>
);

export default ThermoIcon;
