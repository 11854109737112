
export const getSpecificKeys = ({ name, id }) => ({
  appels: {
    string: 'savId',
    number: 'interventionId',
  }[typeof id],
  idADemarcher: {
    string: 'savId',
    number: 'interventionId',
  }[typeof id],
  history: {
    Devis: 'devisId',
    Interventions: 'osId',
    SavInterventions: 'osId',
  }[name?.toFirstUpperCase()],
});
