import { Paper, Grid, Typography, Button } from '@material-ui/core';
import api from '../../../api';
import notifSystem from '../../../notifSystem';
import React, { useEffect, useState } from 'react';
import { blue, brown, orange, red } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import Dropdown from '../common/dropdown/dropdown';
import { getEquipes } from '../../../utils/function';
import store from '../../../store';
import { setDialog } from '../../../actions/dialog';
import { I_APR, I_AVR, I_ENC } from '@bit/dev-lba.lib.local-globals/iStatus';
import { moment } from '@bit/dev-lba.lib.local-globals/moment';
import { MIN_DURATION } from '@bit/dev-lba.lib.local-globals/minDuration';
import { REPONDEUR } from '@bit/dev-lba.lib.local-globals/callTypes';
import { Replay } from '@material-ui/icons';

const formatData = (data) => [
  {
    title: 'A vérifier',
    color: brown[500],
    stats: data.avrf,
  },
  {
    title: 'A programmer',
    color: blue[500],
    stats: data.aprog,
  },
  {
    title: 'Mis en relation en attente',
    color: red[500],
    stats: data.noMiseEnRelation,
  },
  {
    title: 'Appels à écouter',
    color: orange[500],
    stats: data.needEcoute,
  },
];

const filters = (userId) => ({
  'A vérifier': {
    'login.ajout': userId,
    status: I_AVR,
  },
  'A programmer': {
    'login.ajout': userId,
    status: I_APR,
    'date.ajout': {
      $gt: moment().startOf('day').toDate(),
      $lt: moment().endOf('day').toDate(),
    },
  },
  'Mis en relation en attente': {
    status: { $in: [I_ENC, I_AVR] },
    'login.ajout': userId,
    'date.ajout': {
      $gt: moment().add(-1, 'w').startOf('day').toDate(),
      $lt: moment().endOf('day').toDate(),
    },
    'login.miseEnRelation': { $exists: true },
    communcations: {
      $not: {
        $elemMatch: {
          duration: { $gt: MIN_DURATION },
          type: { $ne: REPONDEUR }
        }
      },
    }
  },
  'Appels à écouter': {
    status: { $in: [I_ENC, I_AVR] },
    'login.ajout': userId,
    'date.ajout': {
      $gt: moment().startOf('day').toDate(),
      $lt: moment().endOf('day').toDate(),
    },
    'login.miseEnRelation': { $exists: true },
    appel: true,
    communcations: {
      $elemMatch: {
        duration: { $gt: MIN_DURATION },
        type: { $not: { $all: [REPONDEUR] } },
        played: { $not: { $all: [true] } }
      }
    },
  },
});

const useStyles = makeStyles((theme) => ({
  paperTrust: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  statsAppels: {
    margin: 10,
    padding: 5,
  },
  titleStats: {
    marginBottom: 5,
    fontSize: 10,
    color: '#fff',
  },
  stats: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#fff',
  },
}));

const UserDailyStat = ({ users, user = {} }) => {
  const [data, setData] = useState({});
  const classes = useStyles();
  const [selectedUser, setSelectedUser] = useState(user._id);
  const equipe = getEquipes({ user, users });
  const userObj = users.find(e => e._id === selectedUser);

  const getUserDailyStat = () => {
    api.stats
      .custom('commercial/userDailyStat')
      .get({ userId: selectedUser })
      .then((res) => setData(res.body().data()))
      .catch(() =>
        notifSystem.error('Erreur', 'Impossible de recuperer les statistiques')
      );
  };

  useEffect(() => {
    getUserDailyStat();
  }, []);

  useEffect(() => {
    getUserDailyStat();
  }, [selectedUser]);

  const handleClickInterventions = (filter) =>
    store.dispatch(
      setDialog({
        name: 'InterListDialog',
        open: true,
        hideClose: true,
        dialogProps: {
          title: 'Liste d\'intervention',
          maxWidth: 'xl',
        },
        contentProps: {
          filter: filters(selectedUser)[filter],
        },
        actions: [
          {
            children: 'Fermer',
            color: 'secondary',
            onClick: (r, close) => close(),
          },
        ],
      })
    );

  return (
    <React.Fragment>
      <Paper className={classes.paperTrust}>
        <Grid container justifyContent="center" align="center" spacing={1}>
          <Grid item xs={3}></Grid>
          <Grid item xs={6}>
            <Typography variant="h6" align="center">
              Objectifs à compléter
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Button >
              <Replay onClick={() => getUserDailyStat()} />
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Dropdown
              style={{ width: '100%' }}
              title={userObj ? userObj.login : 'Selectionnez un utilisateur'}
              multiple={false}
              data={equipe}
              renderKey="login"
              onChange={(e) => setSelectedUser(e.target.value)}
            />
          </Grid>
          {Object.keys(data).length
            ? <Grid item xs={12}>
              <Grid container justifyContent="center">
                {formatData(data).map((e) => (
                  <Grid item xs={5}>
                    <Paper
                      className={classes.statsAppels}
                      style={{ background: e.color }}
                      onClick={() => handleClickInterventions(e.title)}
                    >
                      <Typography
                        align="center"
                        className={classes.titleStats}
                        children={e.title}
                      />
                      <Typography
                        align="center"
                        className={classes.stats}
                        children={e.stats}
                      />
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            : ''}
        </Grid>
      </Paper>
    </React.Fragment>
  );
};

export default UserDailyStat;
