const Csv = require('./Csv');
class CombinedConverter {
  constructor(...converters) {
    this.converters = converters;
  }
  toCsv(json) {
    const res = this.converters.map((converter) =>
      converter.toCsv(json).toArray()
    );
    return new Csv(
      res.reduce((accu, newElem) => accu.map((e, id) => [...e, ...newElem[id]]))
    );
  }
  toJson(csv) {
    const res = this.converters.map((converter) => converter.toJson(csv));
    return res.reduce((accu, newElem) =>
      accu.map((e, id) => Object.assign({}, e, newElem[id]))
    );
  }
}


export default CombinedConverter;
