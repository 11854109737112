import {
  FiberManualRecord,
  AccountBalance
} from '@material-ui/icons/';
import {
  S_INT,
  S_SATCL,
  S_PART,
  S_COMPTA,
  S_JUR,
  S_DEVIS,
  S_BTOB,
  S_RH,
  S_ACHAT,
  S_LEAD,
} from '@bit/dev-lba.lib.local-globals/services';

import intervention from './intervention';
import partenariat from './partenariat';
import compta from './compta';
import recouvrement from './recouvrement';
import devis from './devis';
import btob from './btob';
import utils from './utils';
import cti from './cti';
import rh from './rh';
import achat from './achat';
import demandes from './demandes';

// LibraryBooks

export default [
  {
    name: 'Service Intervention',
    icon: FiberManualRecord,
    childs: intervention,
    services: [S_INT],
  },
  {
    name: 'Service Partenariat',
    icon: FiberManualRecord,
    childs: partenariat,
    services: [S_PART],
  },
  {
    name: 'Service Comptabilité',
    icon: FiberManualRecord,
    childs: compta,
    services: [S_COMPTA],
  },
  {
    name: 'Service Juridique',
    icon: AccountBalance,
    childs: recouvrement,
    services: [S_JUR, S_SATCL],
  },
  {
    name: 'Service Support',
    icon: FiberManualRecord,
    childs: devis,
    services: [S_DEVIS],
  },
  {
    name: 'Service BtoB',
    icon: FiberManualRecord,
    childs: btob,
    services: [S_BTOB],
  },
  {
    name: 'Service RH',
    icon: FiberManualRecord,
    childs: rh,
    services: [S_RH],
  },
  {
    name: 'Service Achat',
    icon: FiberManualRecord,
    childs: achat,
    services: [S_ACHAT],
  },
  {
    name: 'Service Demandes',
    icon: FiberManualRecord,
    childs: demandes,
    services: [S_INT, S_LEAD],
  },
  {
    name: 'Cti',
    icon: FiberManualRecord,
    childs: cti,
    services: [],
  },
  {
    name: 'Outils',
    icon: FiberManualRecord,
    childs: utils,
    services: [],
  },
];

