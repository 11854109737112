import {
  R_EC,
  R_ECR,
  R_PER,
  R_RES,
  R_PRE_RES,
} from '@bit/dev-lba.lib.local-globals/rStatus';
import { I_ANN } from '@bit/dev-lba.lib.local-globals/iStatus';
import { RESOLU_DATAS } from '@bit/dev-lba.lib.local-globals/ResoluStatus';
import { moment } from '@bit/dev-lba.lib.local-globals/moment';
import { L_CLI } from '@bit/dev-lba.lib.local-globals/recStatus';
import { modes, modeIndex } from '@bit/dev-lba.lib.local-globals/stats';

const revisionFilter = {
  $multiply: [
    {
      $cond: [
        {
          $gt: [
            {
              $subtract: [
                { $divide: ['$finalPrice', 100] },
                { $divide: ['$recovery.montantEncaisse', 100] },
              ],
            },
            0,
          ],
        },
        {
          $divide: [
            {
              $subtract: [
                { $divide: ['$finalPrice', 100] },
                { $divide: ['$recovery.montantEncaisse', 100] },
              ],
            },
            { $divide: ['$finalPrice', 100] },
          ],
        },
        0,
      ],
    },
    100,
  ],
};

export default ({ displayType, from, to, date, mode, user }, dataIndex = 0) => {
  const basicMatchDate = {
    $gte: moment(from).toDate(),
    $lte: moment(to).toDate(),
  };
  let typeMode = modes[mode];
  let index = Object.keys(modeIndex[mode]).find(
    (e) => modeIndex[mode][e] === dataIndex
  );
  if ((typeMode === 'day' && mode === 'week') || typeMode === 'month') {
    index = index - 1;
  } else if (typeMode === 'day') {
    typeMode = 'date';
  }
  return {
    Annulées: {
      [date]: basicMatchDate,
      status: I_ANN,
      'recovery.recStatus': R_RES,
      'recovery.status': L_CLI,
      ...(user._id ? { 'login.recouvrement': user._id } : {}),
    },
    'En cours': {
      [date]: basicMatchDate,
      'recovery.recStatus': R_EC,
      'recovery.status': L_CLI,
      ...(user._id ? { 'login.recouvrement': user._id } : {}),
    },
    'En cours de reglement': {
      [date]: basicMatchDate,
      'recovery.recStatus': R_ECR,
      'recovery.status': L_CLI,
      ...(user._id ? { 'login.recouvrement': user._id } : {}),
    },
    Pertes: {
      [date]: basicMatchDate,
      'recovery.recStatus': R_PER,
      'recovery.status': L_CLI,
      ...(user._id ? { 'login.recouvrement': user._id } : {}),
    },
    'Prises de contact': {
      'date.priseDeContactRecouvrement': {
        $gte:
          mode !== 'day'
            ? moment(from).endOf('day').toDate()
            : moment(from)
              .add(-+displayType, 'hours')
              .endOf('day')
              .toDate(),
        $lte: moment(to).toDate(),
      },
      'recovery.status': L_CLI,
      ...(user._id ? { 'login.priseDeContactRecouvrement': user._id } : {}),
    },
    'Prises en charge': {
      'recovery.status': L_CLI,
      'date.recouvrement': {
        $gte:
          mode !== 'day'
            ? moment(from).endOf('day').toDate()
            : moment(from)
              .add(-+displayType, 'hours')
              .endOf('day')
              .toDate(),
        $lte: moment(to).toDate(),
      },
      ...(user._id ? { 'login.recouvrement': user._id } : {}),
    },
    'Délai de résolution': {
      'date.solovedRecouvrement': basicMatchDate,
      'recovery.status': L_CLI,
      'recovery.recStatus': { $in: [R_RES, R_PRE_RES] },
      ...(user._id ? { 'login.recouvrement': user._id } : {}),
    },
    'Révision légère': {
      [date]: basicMatchDate,
      'recovery.recStatus': R_RES,
      'recovery.montantEncaisse': { $gt: 0 },
      finalPrice: { $gt: 0 },
      'recovery.status': L_CLI,
      ...(user._id ? { 'login.recouvrement': user._id } : {}),
      $expr: {
        $lt: [revisionFilter, 15],
      },
    },
    'Révision lourde': {
      [date]: basicMatchDate,
      'recovery.recStatus': R_RES,
      'recovery.montantEncaisse': { $gt: 0 },
      finalPrice: { $gt: 0 },
      ...(user._id ? { 'login.recouvrement': user._id } : {}),
      $expr: {
        $gte: [revisionFilter, 15],
      },
    },
    ...RESOLU_DATAS.reduce(
      (acc, curr) => ({
        [curr.name]: {
          [date]: {
            $gte: moment(from).set(typeMode, index).toDate(),
            $lte: moment(to).set(typeMode, index).toDate(),
          },
          'recovery.status': L_CLI,
          'recovery.recStatus': R_RES,
          ...(user._id ? { 'login.recouvrement': user._id } : {}),
          'recovery.resuloStatus': curr._id,
        },
        ...acc,
      }),
      {}
    ),
  };
};
