import React from 'react';

import {
  USER
} from '@bit/dev-lba.lib.local-globals/permissions';
import componentLoader from '../../componentLoader';

export const ListSAVIntervention = React.lazy(() =>
  componentLoader(() => import('../../views/List/ListSAVIntervention'))
);
export const DisplaySavIntervention = React.lazy(() =>
  componentLoader(() => import('../../views/DisplaySavIntervention'))
);

export default [
  {
    path: '/savIntervention/list/:filter/:query',
    component: ListSAVIntervention,
    restrictions: () => USER,
  },
  {
    path: '/savIntervention/list/:filter',
    component: ListSAVIntervention,
    restrictions: () => USER,
  },
  {
    path: '/savIntervention/list',
    component: ListSAVIntervention,
    restrictions: () => USER,
  },
  {
    path: '/savIntervention/:id',
    component: DisplaySavIntervention,
    restrictions: () => USER,
  },
];
