/* eslint-disable max-len */


import React from 'react';

const WindowIcon = ({ w = '80', h = '70', color = '#000', moreStyle = null }) => (
  <svg height={h} viewBox="0 0 84 84" width={w} fill={color} style={{ ...moreStyle }}>
    <g>
      <path d="M67.2,8.6H14c-1.3,0-2.4,1.1-2.4,2.4v62.2c0,1.3,1.1,2.4,2.4,2.4h53.2c1.3,0,2.4-1.1,2.4-2.4V11
        C69.6,9.7,68.5,8.6,67.2,8.6z M67.9,73.2c0,0.4-0.3,0.7-0.7,0.7H14c-0.4,0-0.7-0.3-0.7-0.7V11c0-0.4,0.3-0.7,0.7-0.7h53.2
        c0.4,0,0.7,0.3,0.7,0.7C67.9,11,67.9,73.2,67.9,73.2z"></path>
      <path d="M38.7,47.6c-0.5,0-0.9,0.4-0.9,0.8v20.8c0,0.4-0.3,0.7-0.6,0.7H19.9c-0.3,0-0.6-0.3-0.6-0.7V16
        c0-0.4,0.3-0.7,0.6-0.7h17.3c0.3,0,0.6,0.3,0.6,0.7v17.2c0,0.5,0.4,0.8,0.9,0.8s0.9-0.4,0.9-0.8V16c0-1.3-1-2.4-2.3-2.4H19.9
        c-1.3,0-2.3,1.1-2.3,2.4v53.2c0,1.3,1,2.4,2.3,2.4h17.3c1.3,0,2.3-1.1,2.3-2.4V48.4C39.6,48,39.2,47.6,38.7,47.6z"></path>
      <path d="M61.3,13.6H43.9c-1.3,0-2.3,1.1-2.3,2.4v53.2c0,1.3,1,2.4,2.3,2.4h17.3c1.3,0,2.3-1.1,2.3-2.4V16
        C63.6,14.7,62.6,13.6,61.3,13.6z M61.9,69.2c0,0.4-0.3,0.7-0.6,0.7H43.9c-0.3,0-0.6-0.3-0.6-0.7V16c0-0.4,0.3-0.7,0.6-0.7h17.3
        c0.3,0,0.6,0.3,0.6,0.7v53.2H61.9z"></path>
      <path d="M39.5,37.8c-0.5-0.7-1.3-1.2-2.1-1.2c-0.5,0-1,0.2-1.4,0.4l-8.2,5.7c-0.6,0.4-0.9,1-1.1,1.7
        c-0.1,0.7,0,1.4,0.4,2l0.7,1.1c0.5,0.7,1.3,1.2,2.1,1.2c0.5,0,1-0.2,1.4-0.4l8.2-5.7c0.6-0.4,0.9-1,1.1-1.7c0.1-0.7,0-1.4-0.4-2
        L39.5,37.8z M38.9,40.5c0,0.2-0.2,0.4-0.4,0.6l-8.2,5.7c-0.1,0.1-0.3,0.1-0.5,0.1c-0.3,0-0.6-0.1-0.7-0.4l-0.7-1.1
        c-0.1-0.2-0.2-0.4-0.1-0.7c0-0.2,0.2-0.4,0.4-0.6l8.2-5.7c0.1-0.1,0.3-0.1,0.5-0.1c0.3,0,0.5,0.1,0.7,0.4l0.7,1.1
        C38.9,40.1,38.9,40.3,38.9,40.5z"></path>
      <path d="M56.4,45c0.2-0.2,0.2-0.4,0.2-0.6s-0.1-0.4-0.2-0.6c-0.2-0.2-0.4-0.2-0.6-0.2s-0.4,0.1-0.6,0.2l-7.4,7.4
        c-0.1,0.2-0.2,0.4-0.2,0.6s0.1,0.4,0.2,0.6c0.2,0.2,0.4,0.2,0.6,0.2c0.2,0,0.4-0.1,0.6-0.2L56.4,45z"></path>
      <path d="M56.7,49.6c-0.2,0-0.5,0.1-0.6,0.3l-7.2,7.2c-0.2,0.2-0.3,0.4-0.3,0.6c0,0.2,0.1,0.5,0.3,0.6
        c0.2,0.2,0.4,0.3,0.6,0.3c0.2,0,0.5-0.1,0.6-0.3l7.2-7.2c0.2-0.2,0.3-0.4,0.3-0.6c0-0.2-0.1-0.5-0.3-0.6
        C57.2,49.7,56.9,49.6,56.7,49.6z"></path>
    </g>
  </svg>
);

export default WindowIcon;
