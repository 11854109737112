import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import PropTypes from 'prop-types';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import SchudleStats from '../SchudleStats';
import CustomAvatar from '../../Avatar/CustomAvatar';

const styles = (theme) => ({
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  card: {
    display: 'flex',
    alignItems: 'center',
    minWidth: '100%',
    height: '100%',
  },
  cardBottom: {
    marginBottom: '20px',
  },
  content: {
    marginTop: theme.spacing(4),
    display: 'row',
    alignItems: 'center',
  },
  text: {
    color: 'rgb(141, 126, 119)',
    fontSize: 25,
    fontWeight: '500',
  },
  textSub: {
    color: 'rgb(141, 126, 119)',
    fontSize: 15,
  },
  avatar: {
    marginTop: theme.spacing(5),
    marginLeft: theme.spacing(5),
  },
});

class UserCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: '',
    };
  }

  render() {
    const { user, classes } = this.props;
    let { upload, schudleStats = null, children = null } = this.props;
    if (upload) {
      upload = upload.bind(this);
    }
    return (
      <Card className={classes.card}>
        <Grid container>
          <Grid container item xs={children !== null ? 5 : 12}>
            {(!schudleStats || schudleStats === null) && [
              <Grid key={1} item xs={3} md={3} lg={3}>
                <CustomAvatar
                  size={80}
                  user={user}
                  upload={true}
                />
              </Grid>,
              <Grid key={2} item xs={6} md={6} lg={6}>
                <div className={classes.details}>
                  <CardContent className={classes.content}>
                    <Typography className={classes.text}>
                      Bonjour {user.login.toUpperCase()},
                    </Typography>
                    <Typography className={classes.textSub}>
                      Ligne: {user.ligne}
                    </Typography>
                  </CardContent>
                </div>
              </Grid>
            ]}
          </Grid>
          {children && (
            <Grid items md={7}>
              {children}
            </Grid>
          )}
          {(schudleStats || schudleStats === null) && (
            <Grid container spacing={1} item xs={12} md={12}>
              <SchudleStats SchudleStatsData={user} />
            </Grid>
          )}
        </Grid>
      </Card>
    );
  }
}

UserCard.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

export default withStyles(styles)(UserCard);
