import React, { Component } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';

export default function withHeaderTab(options = {}) {
  const {
    tabs = [],
    propsTabs = {
      indicatorColor: 'primary',
      textColor: 'primary',
      centered: true
    }
  } = options;

  return WrappedComponent =>
    class withHeaderTab extends Component {
      state = { tab: 0 };

      setTab = (e, tab) => this.promisifiedSetState({ tab });

      render() {
        const { tab } = this.state;
        return [
          !!tabs.length && (
            <Paper>
              <Tabs
                key={'Tabs'}
                onChange={this.setTab}
                value={tab}
                {...propsTabs}
                children={tabs.map((e, i) => (
                  <Tab key={i} {...e} />
                ))}
              />
            </Paper>
          ),
          <WrappedComponent
            key={'WrappedComponent'}
            {...this.props}
            tab={tab}
          />,
        ];
      }
    };
}
