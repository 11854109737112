import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import {
  Avatar,
  Tooltip,
} from '@material-ui/core';

const styles = {
  toolbar: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    padding: 4
  },
  avatar: {
    height: 28,
    width: 28,
    margin: 3,
    cursor: 'pointer'
  },
  icon: {
    color: 'white',
    height: 18,
    width: 18
  },
};

const IconToolbar = ({
  classes,
  elems,
  handleClick,
  style
}) => !!elems.length &&
  <div className={classes.toolbar}>
    {elems.map((e, i) =>
      <Tooltip title={e.tooltip || ''}>
        <Avatar
          style={typeof style === 'function' ? style(e, i) : style}
          className={classes.avatar}
          onClick={e.action || (() => handleClick(e, i))}
          children={<e.Icon className={classes.icon}/>}
        />
      </Tooltip>
    )}
  </div>;

IconToolbar.propTypes = {
  open: PropTypes.bool.isRequired,
  elems: PropTypes.arrayOf(PropTypes.shape({
    color: PropTypes.string.isRequired,
    Icon: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
    tooltip: PropTypes.string,
    action: PropTypes.func
  })),
  toggle: PropTypes.func,
  handleClick: PropTypes.func,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
};

IconToolbar.defaultProps = {
  elems: [],
  handleClick: f => f,
  style: e => ({ backgroundColor: e.color })
};

export default withStyles(styles)(IconToolbar);
