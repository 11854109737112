import React from 'react';

import DialogEnvoiDevis from './DialogEnvoiDevis';

const EnvoiDevisFromMenu = ({ open, defaultText: data, callback }) => (
  <DialogEnvoiDevis
    open={open}
    data={data}
    send={callback.send}
    history={callback.history}
    onClose={callback.onClose}
  />
);

export default EnvoiDevisFromMenu;
