import React, { Component } from 'react';

export default function withPagination(defaultState = {}) {
  const {
    ascOrder = true,
    page = 0,
    sortBy = 'id',
    rowsPerPage = 10
  } = defaultState;

  return WrappedComponent => class WithPagination extends Component {
    state = {
      ascOrder,
      page,
      sortBy,
      rowsPerPage: this.props.rowsPerPage || rowsPerPage,
    }

    handleChange = field => value =>
      this.setState({
        [field]: value
      })

    handleChangeSortBy = key =>
      this.setState(({ sortBy, ascOrder }) => ({
        sortBy: key,
        ascOrder: key === sortBy ? !ascOrder : true
      }))

    render() {
      const {
        ascOrder,
        page,
        sortBy,
        rowsPerPage
      } = this.state;

      return <WrappedComponent
        {...this.props}
        ascOrder={ascOrder}
        page={page}
        sortBy={sortBy}
        rowsPerPage={rowsPerPage}
        handleChangePage={this.handleChange('page')}
        handleChangeSortBy={this.handleChangeSortBy}
        handleChangeRowsPerPage={this.handleChange('rowsPerPage')}
      />;
    }
  };
}
