import React from 'react';

import MuiTypography from '@material-ui/core/Typography';
import DOMPurify from 'dompurify';

export const Typography = ({ ...props }) =>
  <MuiTypography
    variant="subtitle1" style={{ fontWeight: 500 }}
    {...props}
  />;

export const genHTMLContent = (text = '', noLimit) => (
  <Typography
    style={{
      overflow: 'hidden',
      lineHeight: '1.2',
      maxHeight: noLimit ? 'auto' : '10.8em',
      borderLeft: noLimit ? '' : '1px solid #adadad',
      paddingLeft: 5,
    }}
    className="editorHtml"
    dangerouslySetInnerHTML={{
      __html: DOMPurify.sanitize(text),
    }}
  />
);
