import React from 'react';

import { Button, Grid } from '@material-ui/core';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { setDialogContentProp } from '../../actions/dialog';
import CommentsList from '../Comments/CommentsList';
import { fromJS } from 'immutable';

const setComment = setDialogContentProp('comment');
const setStatus = setDialogContentProp('status');

const mapStateToProps = ({
  dialog: {
    contentProps: {
      buttons = [],
      status,
      withComment = true,
      withColor = true,
      multiSelect = false,
      comments,
      justify,
    },
  },
  users = [],
  userId
}) => ({
  justify,
  buttons,
  status,
  withComment,
  withColor,
  multiSelect,
  comments,
  users,
  userId
});

const styles = {
  gridItem: {
    display: 'flex',
    flexGrow: 1,
    minHeight: 80
  }
};

const mapDispatchToProps = {
  setComment: e => setComment(e.target.value),
  setStatus: e => setStatus(e),
};

const DialogStatusWithComment = ({
  buttons, classes, status, setStatus, justify = '',
  setComment, comments, withComment,
  withColor, multiSelect, users, userId
}) =>
  <Grid container spacing={3} justify={justify}>
    {buttons ? buttons.map((e, i) =>
      <Grid item sm={4} className={classes.gridItem}>
        <Button
          key={i}
          fullWidth
          color={(Array.isArray(status) ?
            status : [status]).includes(e._id) ? 'secondary' : 'primary'}
          style={withColor && e.color ? { backgroundColor: e.color[500] } : {}}
          variant={'contained'}
          children={e.name}
          onClick={() =>
            setStatus(multiSelect ? status.toggleValue(e._id) : e._id)
          }/>
      </Grid>
    ) : ''}
    {withComment ?
      <Grid item xs={12}>
        <CommentsList
          setOnChange={setComment}
          users={users}
          userId={userId}
          comments={fromJS(comments || [])}
        />
      </Grid> : null}
  </Grid>;

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(DialogStatusWithComment);
