import React from 'react';
import { compose } from 'recompose';

import withWidth from '@material-ui/core/withWidth';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import SMSPanel from '../../../components/Sms/management/index';

import {
  ClassementTable,
  TransformCall,
  StatsCalls,
} from '../../../components/Stats';
import Planning from '../../../components/Stats/SchudleStats/planning';
import GraphCATelepro
from '../../../components/Stats/ChiffreDaffaires/GraphCATelepro';
import GraphStatsObjectif
from '../../../components/Stats/ChiffreDaffaires/GraphStatsObjectif';
import AppelsStats from '../../../components/Stats/Commercial/appels';
import PriorityFollow
from '../../../components/Stats/Commercial/priorityFollow';

import { CHIEF, ADMIN } from '@bit/dev-lba.lib.local-globals/permissions';

import { S_INT, S_LEAD } from '@bit/dev-lba.lib.local-globals/services';
import TodayStats from '../../../components/Stats/Commercial/todayStats';
import UserHolidaysConfig from '../../../components/Users/holidays';
import AverifCounter from '../../../components/Stats/Commercial/AverifCounter';
import InterSend from '../../../components/Stats/Objectifs/InterSend';
import { getEquipes } from '../../../utils/function';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2) * 2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    overflow: 'auto',
  },
  displayNoneRWD: {
    display: 'flex',
    alignItems: 'stretch',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  conditionalDisplayNoneRWD: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    [theme.breakpoints.between('md', 'lg')]: {
      display: 'block',
    },
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
    },
  },
  displayFlex: {
    display: 'flex',
  },
  displayPersonnalComponent: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  marginTop: {
    marginTop: 10,
  },
  fullWidth: {
    width: '100%',
  },
});

const CommercialDashboard = ({ user, users, classes, allUsers }) => {
  const isAdmin = user.permission <= CHIEF;
  const equipe = getEquipes({ user, users });
  return (
    <>
      <Grid container spacing={2} item xs={12}>
        <Grid
          container
          item
          xs={12}
          sm={12}
          md={6}
          spacing={1}
          className={classes.displayNoneRWD}
        >
          <Grid item md={12} lg={12}>
            <AverifCounter />
          </Grid>
          <Grid item md={12} lg={12}>
            <InterSend />
          </Grid>
          <Grid item md={12} lg={12}>
            <Paper className={classes.paper}>
              <ClassementTable />
            </Paper>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <GraphCATelepro users={equipe} user={user} withTrust />
        </Grid>
      </Grid>
      <Grid container spacing={2} item xs={12}>
        <Grid
          item
          xs={12}
          md={isAdmin && equipe.length > 1 ? 6 : 12}
          className={classes.displayNoneRWD}
        >
          <Paper className={classes.fullWidth}>
            <AppelsStats users={equipe} />
          </Paper>
        </Grid>
        {equipe.length > 1 && (
          <Grid item xs={12} md={6}>
            <Paper>
              <TodayStats users={equipe} />
            </Paper>
          </Grid>
        )}
      </Grid>
      <Grid spacing={2} item xs={user.permission === ADMIN ? 6 : 12}>
        <GraphStatsObjectif allUsers={allUsers} />
      </Grid>
      {user.permission === ADMIN && (
        <Grid spacing={2} item xs={6}>
          <PriorityFollow />
        </Grid>
      )}
      {user && (
        <Grid
          container
          spacing={2}
          item
          xs={12}
          className={classes.displayNoneRWD}
        >
          <Grid item xs={12}>
            <TransformCall
              query={{
                'record.agent': {
                  $in: equipe
                    .map((e) => e.multiLignes)
                    .flat()
                    .map((e) => e.replace('09', '00339')),
                },
                $or: [
                  {
                    $expr: {
                      $gt: ['$record.duration', '$record.minDuration'],
                    },
                  },
                ],
                serviceDisabled: { $ne: true },
              }}
            />
          </Grid>
        </Grid>
      )}
      <Grid container spacing={2} item xs={12} sm={12} md={12}>
        <Grid item xs={12} sm={12} md={6}>
          <StatsCalls users={users} user={user} />
        </Grid>
        <Grid item xs={12} sm={12} md={6} className={classes.displayNoneRWD}>
          <Planning SchudleStatsData={user} />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <UserHolidaysConfig />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        item
        xs={12}
        sm={12}
        md={12}
        className={classes.marginTop}
      >
        <Grid item xs={12} sm={12} md={12}>
          <SMSPanel limit={10} dashboard services={[S_INT, S_LEAD]} />
        </Grid>
      </Grid>
    </>
  );
};

export default compose(withStyles(styles), withWidth())(CommercialDashboard);
