import React, { PureComponent } from 'react';
import { Button, Table, TableBody, TableRow,
  TableCell, TableHead, Paper
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { red, purple, green } from '@material-ui/core/colors';
import api from '../../../api';
import { moment } from '@bit/dev-lba.lib.local-globals/moment';
import ReactEcharts from 'echarts-for-react';
import notifSystem from '../../../notifSystem';
import {
  AttachMoneyOutlined,
  MoneyOffOutlined
} from '@material-ui/icons';
import { getOption } from '../common/graph/options/globalOptions';
import { connect } from 'react-redux';

import StatsPanel from '../common/panel/StatsPanel';
import GrandCompteStats from './popup';

const styles = {
  inputStyle: {
    width: '100%',
  },
  gridStyle: {
    margin: 5
  }
};

const mapStateToProps = (state) => ({
  grandCompte: state.grandCompte.toJS(),
});

const charts = [
  (isCA) => ({
    data: 'data',
    colors: [red[500], green[500], purple[500]],
    title: isCA ? 'Chiffre d\'affaire' : 'Nombres'
  }),
  () => ({
    data: 'data2',
    colors: [green[500], red[500]],
    title: 'Nombre de grand compte'
  })
];

const ROWS = {
  'CA (m - 1)': 'CA_LAST €',
  'CA': 'CA €',
  'CA moyen': 'averageCA €',
  'Nombre d\'interventions en cours': 'currentInterventions',
  'Nombre de devis en attente': 'adevis',
  'Taux de transformation': 'transformation %',
  'Panier moyen': 'averageCard €'
};

class BtobStats extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      type: 0,
      mode: 'year',
      from: moment().startOf('year').toDate(),
      to: moment().endOf('year').toDate(),
      from2: moment().startOf('month').toDate(),
      to2: moment().endOf('month').toDate(),
      data: {},
      data2: {},
      data3: [],
      isCA: true,
    };
  }

  componentDidMount() {
    this.doRequests(true);
  }

  onDatesChange = (from, to, mode) => {
    this.setState({ from, to, mode }, () => this.doRequests());
  }

  onDatesChange2 = (from2, to2) => {
    this.setState({ from2, to2 }, () => this.doRequests());
  }

  doRequests = () => {
    const { from, to, mode, isCA, from2, to2 } = this.state;
    const { grandCompte } = this.props;

    api.stats.custom('grandCompte').get({
      from: from.getTime(),
      to: to.getTime(),
      mode,
      isCA: isCA ? 1 : 0,
      nonIncludes: grandCompte.filter(e => e.stats).map(e => e._id)
    }).then(response => {
      const data = response.body().data().response;
      this.setState({ data });
    }).catch(() => notifSystem.error('Erreur',
      'Impossible de charger les statistiques'));

    api.stats.custom('btob').get({
      from: from.getTime(),
      to: to.getTime(),
      mode,
    }).then(response => {
      const data = response.body().data().response;
      this.setState({ data2: data });
    }).catch(() => notifSystem.error('Erreur',
      'Impossible de charger les statistiques'));

    api.stats.custom('btob/follow/').get({
      from: from2,
      to: to2,
    }).then(response => {
      const data = response.body().data().response;

      this.setState({ data3: data });
    }).catch(() => notifSystem.error('Erreur',
      'Impossible de charger les statistiques'));
  }

  getGrandCompteName(_id) {
    const { grandCompte } = this.props;
    const gc = grandCompte.find(g => g._id === _id);
    return gc ? gc.companyName : '';
  }

  handelChange = (name) => (e) => {
    this.setState({
      [name]: e.target.value
    });
  }

  openBtobStats = (key) => {
    const gc = this.props.grandCompte.find(g =>
      g._id === key);
    if (gc) {
      this.setState({ selectedGC: gc });
    }
  }

  render() {
    const {
      mode, from, data3, to, isCA,
      from2, to2, selectedGC
    } = this.state;

    return (
      <Paper>
        <GrandCompteStats grandCompte={selectedGC}
          onClose={() => this.setState({ selectedGC: null })}/>
        <StatsPanel
          onDatesChange={this.onDatesChange}
          title={'Statistiques grand compte'}
          defaultState={{
            mode,
            from,
            to,
          }}
          body={
            <Button
              onClick={() =>
                this.setState({ isCA: !isCA }, () => this.doRequests())
              }
              variant="outlined"
              color={isCA ? 'primary' : 'secondary'}
            >
              {isCA ? <AttachMoneyOutlined /> : <MoneyOffOutlined />}
            </Button>
          }
        >
          {charts.map((d, i) => {
            const value = d(isCA);
            const data = this.state[value.data];
            return (
              <ReactEcharts
                key={i}
                notMerge={true}
                lazyUpdate={true}
                style={{ width: '100%' }}
                option={getOption({
                  data,
                  labels: Object.keys(data),
                  mode,
                  colors: value.colors,
                  dates: [moment(from), moment(to)],
                  title: value.title,
                })}
              />
            );
          })}
        </StatsPanel>
        <StatsPanel
          onDatesChange={this.onDatesChange2}
          title={'Tableau de suivie'}
          defaultState={{
            mode: 'month',
            from: from2,
            to: to2,
          }}
          enabledModes={[false, false, true, false]}
        >
          <div style={{ height: 800, overflow: 'auto' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Grand compte</TableCell>
                  {Object.keys(ROWS).map(row => <TableCell children={row} />)}
                </TableRow>
              </TableHead>
              <TableBody>
                {data3 && data3
                  .filter(c => this.getGrandCompteName(c._id))
                  .sort((a, b) => b.CA - a.CA)
                  .map(value => (
                    <TableRow key={value._id}>
                      <TableCell
                        component="th"
                        scope="row"
                        onClick={() => this.openBtobStats(value._id)}
                        children={this.getGrandCompteName(value._id)}
                      />
                      {Object.values(ROWS).map(v => {
                        const [k, o] = v.split(' ');

                        return <TableCell
                          component="th"
                          scope="row"
                          children={`${value[k] || 0}${o || ''}`}
                        />;
                      })}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </div>
        </StatsPanel>
      </Paper>
    );
  }
}

export default withStyles(styles)(connect(mapStateToProps)(BtobStats));

