/* eslint-disable max-lines */
import React, { PureComponent } from 'react';
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Chip from '@material-ui/core/Chip';
import { Description, InsertDriveFile, Person } from '@material-ui/icons';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';

import getDataById from '@bit/dev-lba.lib.local-globals/getDataById';
import { USER } from '@bit/dev-lba.lib.local-globals/permissions';
import { permit } from '@bit/dev-lba.lib.local-globals/restrictions';

import api from '../api';
import { shortDate, getTime } from '../utils/date';
import styles from './global/searchStyle';
import Button from '@material-ui/core/Button';
import WhenBottom from '../components/Utils/WhenBottom';
import links from '../constants/links';

const mapStateToProps = ({ users, userId }) => ({
  users,
  userId,
  user: users.find(u => u._id === userId),
});

function genChip(elem, short) {
  return (elem &&
    <Chip key={elem.name} label={short ? elem.shortName : elem.name} style={{
      backgroundColor: elem.color['500'],
      color: elem.color.contrastDefaultColor === 'light' ? 'white' : 'black',
      display: 'inline-flex',
      height: '20px',
    }} />);
}

function sort(a, b) {
  return b.id - a.id;
}

const path = (data, user) => ({
  'artisans': `/artisan/${data.id}/recap`,
  'candidats': `/candidat/${data.id}`,
  'interventions': `/intervention/${data.id}`,
  'savInterventions': `/savIntervention/${data.id}`,
  'demandes': `/demandes/list/all?query="{"client.tel1":"${data.tel1}"}"`,
  'sms': `/mms/recipient=${user.login}&demandePhoto=${data.demandePhoto}`
})[data.type];

const classesData = (classes) => ({
  'artisans': classes.brown,
  'candidats': classes.grey
});

const dataIds = {
  'artisans': 'artisanAStatus',
  'candidats': 'candidatStatus',
  'demandes': 'demandesStatus'
};

const resources = (user) => permit(user, { key: 'externeSAV' })
  ? [
    'interventions',
    'devis',
    'artisans',
    'candidats',
    'savInterventions',
    'demandes',
    'sms'
  ]
  : ['interventions', 'artisans'];

const resetResources = (user) => resources(user).reduce((a, v) => {
  a[v] = [];
  a[`${v}Display`] = 0;
  a[`${v}CanBeLoaded`] = true;
  return a;
}, {});

export class Search extends PureComponent {
  static step = 50

  state = {
    ...resetResources(this.props.user),
    cards: [],
    viewCurrentCard: {
      interventions: { state: false, },
      devis: { state: false, up: true },
      artisans: { state: false, up: true },
    },
    switchViews: [],
  }

  componentDidMount() {
    this.animationStart(this.props.classes);
    resources(this.props.user).asyncForEach(async (collection) => {
      await this.search({
        text: this.props.match.params.text,
        collection,
      });
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.text !== this.props.match.params.text) {
      this
        .promisifiedSetState(resetResources(this.props.user))
        .then(() =>
          resources(this.props.user).forEach(collection => this.search({
            text: nextProps.match.params.text,
            collection
          }))
        );
    }
  }

  search = ({ text, collection }) =>
    api.elasticsearch
      .getAll({
        limit: Search.step,
        collection,
        display: this.state[`${collection}Display`],
        value: encodeURI(text)
      })
      .then(res => {
        const data = res.body().map(e => e.data()._source);
        if (collection === 'savInterventions') {
          collection = 'interventions';
        } else if (collection === 'candidats') {
          collection = 'artisans';
        }
        return this.setState(prevState => ({
          [collection]: [
            ...prevState[collection],
            ...data
          ],
          [`${collection}CanBeLoaded`]: !!data.length
        }));
      })

  loadMore = collection => {
    const key = `${collection}Display`;

    return this.state[`${collection}CanBeLoaded`] &&
      this
        .promisifiedSetState(prevState => ({
          [key]: prevState[key] + Search.step
        }))
        .then(() => this.search({
          text: this.props.match.params.text,
          collection
        }));
  }

  userAjout = (data) => {
    const {
      users,
      classes,
    } = this.props;
    const user = users.find(user => user._id === data.ajout);

    return (
      user ?
        <span className={classes.bot}>
          Ajouté par : {user.login || 0}
        </span> : ''
    );
  }

  genItemInter = (data) => {
    const {
      classes,
      history,
      user
    } = this.props;

    const name = (data.clientSociety
      || `${data.firstname} ${data.name}`).toUpperCase();
    const primary = <span className={classes.top}>
      {data.id} - {data.civility.toUpperCase()}{' '}{name}
    </span>;
    const status = (data.type === 'savInterventions'
      ? getDataById.savIntervention
      : getDataById.intervention
    )[data.status];
    const cat = getDataById.categories[data.categorie];
    const date = new Date(+data.date);
    const secondary = <span className={classes.bot}>
      {genChip(cat, true)} - {genChip(status, true)} - {shortDate(date)} à
      {` ${getTime(date)} `}- {
        this.userAjout(data)} Prix : {data.price || 0}{' '}
      {data.currentArtisanCompanyName &&
        <Link
          to={`/artisan/${data.currentArtisanId}/recap`}
          onClick={e => e.stopPropagation()}
          children={data.currentArtisanCompanyName || ''}
        />
      }
    </span>;

    return <ListItem
      key={data._id}
      button
      onClick={() => history.push(path(data, user))}
    >
      <Avatar className={classes.green} children={<Description />} />
      <div className={classes.container}>
        {primary}
        {secondary}
      </div>
    </ListItem>;
  }

  genItemDevis = (data) => {
    const {
      classes,
      history,
    } = this.props;
    const status = getDataById.devis[data.status];
    const cat = getDataById.categories[data.categorie];
    const date = new Date(+data.date);

    return <ListItem
      key={data._id}
      button
      onClick={() => history.push(`/devis/${data.id}`)}
    >
      <Avatar className={classes.blue} children={<InsertDriveFile />} />
      <div className={classes.container}>
        <span className={classes.top}>
          {data.id} - {data.civility.toUpperCase()}
          {' '}{data.firstname.toUpperCase()} {data.name.toUpperCase()}
        </span>
        <span className={classes.bot}>
          {genChip(cat)} - {genChip(status)}{' '}{data.date
            ? `${shortDate(date)} à ${getTime(date)} - Prix: ${data.price || 0}`
            : ''}
        </span>
        {this.userAjout(data)}
      </div>
    </ListItem>;
  }

  genItemArtisans = (data) => {
    const { classes, user } = this.props;
    const primaryFilter = <span className={classes.top}>
      {data.firstname.toUpperCase()} {data.name.toUpperCase()}
    </span>;
    const primary = <span className={classes.top}>
      {data.id} - {data.firstname.toUpperCase()} {data.name.toUpperCase()}
    </span>;
    const status = getDataById[dataIds[data.type]][data.status];
    const secondary = <span className={classes.bot}>
      {data.companyName ?
        data.companyName.toUpperCase() : ''} - {genChip(status)}
    </span>;
    switch (user.permission) {
      case USER:
        return (
          <ListItem key={data._id} button onClick={() => {
            this.props.history.push(path(data, user));}}>
            <Avatar className={classesData[data.type]}>
              <Person />
            </Avatar>
            <div className={classes.container}>
              {primaryFilter}
              {this.userAjout(data)}
            </div>
          </ListItem>
        );
      default:
        return (
          <ListItem key={data._id} button onClick={() => {
            this.props.history.push(path(data, user));
          }}>
            <Avatar className={classesData[data.type]}>
              <Person />
            </Avatar>
            <div className={classes.container}>
              {primary}
              {secondary}
              {this.userAjout(data)}
            </div>
          </ListItem>);
    }
  }

  genItemDemandes = (data) => {
    const {
      classes,
      history,
      user
    } = this.props;
    let status = getDataById[dataIds[data.type]][data.status];
    status = status ? status : getDataById[dataIds[data.type]][0];
    const date = new Date(+data.date);
    return <ListItem
      key={1}
      button
      onClick={() => history.push(path(data, user))}
    >
      <Avatar className={classes.blue} children={<InsertDriveFile />} />
      <div className={classes.container}>
        <span className={classes.titleAutres}>
          DEMANDE
        </span>
        <span className={classes.top}>
          {data.id ? `${data.id} - ` : data.id}
          {(data.firstname || '').toUpperCase()} {
            (data.name || '').toUpperCase()}
        </span>
        <span className={classes.bot}>
          {status ? genChip(status) : genChip()}
        </span>
        <span className={classes.bot}>
          {shortDate(date)} à {getTime(date)}
        </span>
      </div>
    </ListItem>;
  }

  genItemMms = (data) => {
    const {
      classes,
      history,
      user
    } = this.props;
    const date = new Date(+data.date);
    return (
      <ListItem key={1} button onClick={() => history.push(path(data, user))}>
        <div className={classes.mmsContainer}>
          {new Array(data.numberOfImg || 1).fill(0).map((_, i) => (
            <Link
              key={i}
              to={
                '/api/imageFromMms?mmsId=' + `${data.underscoreId}&index=${i}`
              }
              target="_blank"
              rel="noreferrer noopener"
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              <Button key={i}>
                <img
                  src={
                    `${links.origin}/api/imageFromMms?mmsId=` +
                    `${data.underscoreId}&index=${i}`
                  }
                  alt="mms"
                  style={{ height: '50px' }}
                />
              </Button>
            </Link>
          ))}
        </div>
        <div className={classes.container}>
          <span className={classes.titleAutres}>MMS</span>
          <span className={classes.top}>{data.from || ''}</span>
          <span className={classes.bot}>
            {shortDate(date)} à {getTime(date)}
          </span>
        </div>
      </ListItem>
    );
  }

  determinatePos = currentCard => {
    const { classes } = this.props;
    let counter = -1;
    let tab = [];
    let arr = this.state.switchViews;

    switch (currentCard) {
      case 'interventions':
        tab = [classes.devisDown, classes.artisansDown];
        break;
      case 'devis':
        tab = [classes.devisUp, classes.artisansDown];
        break;
      case 'artisans':
        tab = [classes.devisUp, classes.artisansUp];
        break;
      default:
        tab = [];
        break;
    }

    const intervalSwitch = setInterval(() => {
      if (counter < 1) {
        counter++;
        arr[counter] = tab[counter];
        this.setState({
          switchViews: Array.of(...arr),
        });
      }
      else {
        clearInterval(intervalSwitch);
      }
    }, 100);

  }

  handleCurrentCardShow = (e) => {
    e.stopPropagation();
    const obj = Object.assign({}, this.state.viewCurrentCard);
    const currentCard = e.currentTarget.dataset.card;
    for (const key in obj) {
      if (key === currentCard) {
        obj[currentCard].state = true;
        obj[currentCard].up = true;
      } else {
        obj[key].state = false;
        obj[key].up = false;
      }
    }
    this.determinatePos(currentCard);
  }

  animationStart = (classes) => {
    let counter = 0;

    const interval = setInterval(() => {
      if (counter < 3) {
        counter++;
        const classe = `animationCardUp${counter}`;
        this.setState({ cards: [...this.state.cards, classes[classe]] });
      } else {
        clearInterval(interval);
      }
    }, 400);
  }

  generateLists = () => {
    const {
      interventions = [],
      artisans = [],
      devis = [],
      demandes = [],
      sms = []
    } = this.state;

    return [
      {
        list: interventions
          .filter(e => e.date)
          .sort(sort)
          .map(this.genItemInter),
        title: 'Interventions',
        collection: 'interventions'
      },
      {
        list: devis.sort(sort).map(this.genItemDevis),
        title: 'Devis',
        collection: 'devis'
      },
      {
        list: artisans.map(this.genItemArtisans),
        title: 'Artisans',
        collection: 'artisans'
      },
      {
        list: sms.map(this.genItemMms)
          .concat(demandes.map(this.genItemDemandes)),
        title: 'Autres',
        collection: 'demandes'
      }
    ];
  }

  render() {
    const {
      cards,
      switchViews,
    } = this.state;
    const { classes } = this.props;
    const lists = this.generateLists();

    return <Grid container spacing={2} className={classes.fullHeight}>
      {lists.some(({ list }) => list.length) ?
        lists.map(({ list, title, collection }, i) => !!list.length &&
          <Grid item sm xs={12} className={classes.fullHeight}>
            <WhenBottom
              offset={500}
              onTrigger={() => this.loadMore(collection)}
            >
              <Card
                className={classnames(classes.mobileCardSearch, {
                  [cards[i]]: !switchViews.length || i === 0,
                  [switchViews[i]]: i !== 0
                })}
                onClick={this.handleCurrentCardShow}
                data-card={title}
              >
                <CardContent>
                  <Typography variant="h5" children={title} />
                  <List children={list} />
                </CardContent>
              </Card>
            </WhenBottom>
          </Grid>
        ) :
        <Grid item xs>
          <Typography
            variant="h3"
            align="center"
            children="Pas d'informations"
          />
        </Grid>
      }
    </Grid>;
  }
}

export default compose(
  withRouter,
  connect(mapStateToProps),
  withStyles(styles)
)(Search);
