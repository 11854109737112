import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  MinimalDashboard,
  RHDashboard,
  SupportDashboard,
  DemandesDashboard,
  CommercialDashboard
} from './Dashboard/';
import PartenariatDashboard from '../views/Dashboard/PartenariatDashboard';
import {
  S_INT,
  S_PART,
  S_RH,
  S_DEVIS,
  S_LEAD,
} from '@bit/dev-lba.lib.local-globals/services';
import { SHOW_ENR } from '@bit/dev-lba.lib.local-globals/configTypes';
import { permit } from '@bit/dev-lba.lib.local-globals/restrictions';
import {
  Redirect,
} from 'react-router-dom';

const styles = () => ({
  root: {
    flexGrow: 1
  }
});

const mapStateToProps = (state) => ({
  userId: state.userId,
  users: state.users,
  showRecords:
    !state.config.some(c => c.type === SHOW_ENR)
});

class Stats extends Component {

  constructor(props) {
    super(props);
    this.state = {
      userId: props.userId,
    };
  }


  static getDerivedStateFromProps(nP, nS) {
    if (nP.users && nS.users && nP.users !== nS.users) {
      return {
        users: nP.users
      };
    }
    return null;
  }

  getDashboard(props) {
    const { user, users, userId, showRecords } = props;
    switch (user.service) {
      case S_LEAD:
        return (
          <DemandesDashboard
            user={user}
            users={users.filter((e) =>
              e.act && [S_INT, S_LEAD].includes(e.service)
            )}
            allUsers={users.filter((e) => e.act)}
            userId={userId}
            showRecords={showRecords}
          />
        );
      case S_INT:
        return permit(user, { key: 'interventionIntern' }) ? (
          <CommercialDashboard
            user={user}
            users={users.filter((e) =>
              [S_INT, S_LEAD].includes(e.service) && e.act)}
            allUsers={users.filter((e) => e.act)}
            userId={userId}
            showRecords={showRecords}
          />
        ) : <MinimalDashboard users={users} user={user} />;
      case S_PART:
        return permit(user, { key: 'partenariatIntern' }) ? (
          <PartenariatDashboard user={user} users={users} userId={userId} />
        ) : (
          <Redirect to={'/partenariat/demarchMarket'} />
        );
      case S_RH:
        return (
          <RHDashboard
            allUsers={users.filter(e => e.act)}
            user={user} users={users} userId={userId} />
        );
      case S_DEVIS:
        return (
          <SupportDashboard
            user={user}
            users={users}
            allUsers={users.filter(e => e.act)}
            userId={userId}
          />
        );
      default:
        return <MinimalDashboard users={users} user={user} />;
    }
  }

  render() {
    const { classes, users, showRecords } = this.props;
    const { userId } = this.state;
    let user = users.find(e => e._id === userId);
    return (
      <div className={classes.root}>
        {
          user &&
          <this.getDashboard showRecords={showRecords}
            user={user} users={users} userId={userId} />
        }
      </div>);
  }
}

Stats.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(connect(mapStateToProps)(Stats));
