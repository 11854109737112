import React, { PureComponent } from 'react';
import TablePagination from '@material-ui/core/TablePagination';
import PropTypes from 'prop-types';

class StatsTableFooter extends PureComponent {

  render() {
    const { count, page, rowsPerPage } = this.props;
    return (
      <tfoot>
        <tr>
          <TablePagination
            component="td"
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}
            onChangePage={this.props.handleChangePage}
            onChangeRowsPerPage={this.props.handleChangeRowsPerPage}
            count={count}
            page={page}
            rowsPerPage={rowsPerPage}
          />
        </tr>
      </tfoot>
    );
  }
}


StatsTableFooter.propTypes = {
  count: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
};


export default StatsTableFooter;
