import { red, green } from '@material-ui/core/colors';

export const getOption = function(PieDoughnutData) {
  let total = 0;
  if (PieDoughnutData) {
    delete PieDoughnutData.login;
    delete PieDoughnutData._id;
    delete PieDoughnutData.sumInter;
    total = Object.values(PieDoughnutData).reduce((a, b) => a + b);
  }
  return ({
    color: [red[500], green[500]],
    tooltip: {
      trigger: 'item',
      formatter: '{a} <br/>{b}: ({d}%)'
    },
    series: [
      {
        name: '% Annulation',
        type: 'pie',
        radius: ['70%', '90%'],
        avoidLabelOverlap: false,
        label: {
          normal: {
            show: false,
            position: 'center',
            formatter: '{b}: {d}%'
          },
          emphasis: {
            show: true,
            textStyle: {
              fontSize: '30',
              fontWeight: 'bold'
            }
          }
        },
        labelLine: {
          normal: {
            show: false
          }
        },
        data: [
          { value: total.toFixed(), name: 'Annulés' },
          { value: 100 - total.toFixed(), name: 'Autres' },
        ]
      }
    ]
  });
};
