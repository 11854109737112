import {
  projectLink,
  projectLink_stage,
} from '@bit/dev-lba.lib.local-globals/company';

export default {
  origin:
    {
      production: projectLink,
      stage: projectLink_stage,
    }[process.env.NODE_ENV] || window.location.origin,
};
