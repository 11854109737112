import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RecouvrementFooter from '../RecouvrementFooter';
import InterventionsArray from './InterventionsArray';
import {
  artisan as artisanCauses,
  b2b as b2bCauses,
} from '@bit/dev-lba.lib.local-globals/rCauses';
import { RT_ART } from '@bit/dev-lba.lib.local-globals/rTypes';

import Grid from '@material-ui/core/Grid';

import { fromJS } from 'immutable';
import { compose } from 'recompose';
import { withLoading } from '../../../../hoc';


const styles = theme => ({
  center: {
    textAlign: 'left'
  },
  chip: {
    marginLeft: theme.spacing(1) / 2,
  },
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
});
class PriseEnChargeDialogSst extends Component {
  state = {
    inters: fromJS(this.props.defaultText.interventions),
    quarentine: false,
  }

  cancel = () => {
    if (typeof this.props.callback === 'function') {
      this.props.callback(null);
    }
  }

  onValidate = (bool) => {
    const { inters, quarentine } = this.state;
    const { callback, setLoading } = this.props;

    if (typeof callback === 'function') {
      setLoading(true);
      callback(inters, bool, quarentine);
    }
  }


  handleCauseRecouvrement = (e) => {
    this.setState({ cause: Number(e.target.value) });
  }

  setData = (path, value) => {
    this.setState({
      inters: this.state.inters.setIn(path, value)
    });
  }

  setDataGlobal = (path, value) => {
    this.setState({
      inters: this.state.inters.map(e => e.setIn(path, value))
    });
  }

  setQuarentine = quarentine => this.setState({ quarentine })

  render() {
    const {
      open,
      classes,
      defaultText: { type },
    } = this.props;
    const {
      inters,
      loading,
      quarentine,
    } = this.state;
    const causes = type === RT_ART ? artisanCauses : b2bCauses;

    return (
      <Dialog open={open} maxWidth='md'>
        <DialogTitle>
          <Grid container spacing={4}>
            <Grid item xs={8}>
              <Typography className={classes.center} variant="h6">
                Prise en charge
              </Typography>
            </Grid>
            <Grid item xs={2}>
              {type === RT_ART &&
                <FormControlLabel
                  control={
                    <Switch
                      checked={quarentine || false}
                      onChange={e => this.setQuarentine(e.target.checked)}
                      value="quarentine"
                      color="primary"
                    />
                  }
                  label="Quarentine"
                />
              }
            </Grid>
            <Grid item xs={2}>
              <Typography className={classes.center} variant="h6">
                <Select
                  value={inters.getIn([0, 'recouvrement', 'cause'], '')}
                  onChange={e => this.setDataGlobal(
                    ['recouvrement', 'cause'],
                    e.target.value
                  )}
                  children={causes.map(c =>
                    <MenuItem value={c._id} children={c.name} />
                  )}
                />
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <InterventionsArray
            classes={classes}
            data={inters}
            setData={this.setData}
            causes={causes}
          />
        </DialogContent>
        <RecouvrementFooter
          key="footer"
          send={this.onValidate}
          cancel={this.cancel}
          loading={loading}
        />
      </Dialog>
    );
  }
}
PriseEnChargeDialogSst.defaultProps = {
  validateText: 'Valider',
  cancelText: 'Annuler',
  maxWidth: 'sm',
  fullWidth: false
};

export default compose(
  withLoading(),
  withStyles(styles),
)(PriseEnChargeDialogSst);
