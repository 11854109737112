import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import CustomMaterialTable from '../../CustomMaterialTable';
import { Grid, Typography } from '@material-ui/core';
import { moment } from '@bit/dev-lba.lib.local-globals/moment';
import { getLoginFromPhone, getLogin } from '../../../utils/function';
import AudioPlayer from '../../Calls/AudioPlayer';
import DetailPanel from './DetailPanel';
import { styles } from '../../../views/Cti/utils';
import { getAllCalls } from '../../../actions/calls';
import constant from '../../Stats/StatsCalls/constant';
const { groupBy } = require('@bit/dev-lba.lib.local-globals/functions');

const mapStateToProps = ({
  dialog: { contentProps: {
    title, calls, startAt, endAt
  } },
  users,
  userId,
}) => ({
  title, calls, startAt, endAt, users, userId,
  user: users.find(e => e._id === userId),
});

const filterCalls = (calls = []) => {
  const allCalls = calls.sort(
    (e, v) =>
      moment(e.record.callStart).toDate() - moment(v.record.callStart).toDate()
  );
  const records = allCalls.map(e => ({ ...e, ...e.record }));
  const uniqueCalls = Object.values(groupBy(records, 'callerIdNumber'))
    .map(e => ({ records: e }));

  return { allCalls, uniqueCalls };
};

const fetchCalls = ({ title, ...props }) =>
  getAllCalls(props)
    .then(statsCalls => {
      const nCalls = constant({ statsCalls }).find(e => e.title === title);
      return nCalls ? filterCalls(nCalls) : [];
    });

const findCall = (calls, record) =>
  calls.find(c => c.record.id === record.records[0].id);

const columns = (calls) => [
  {
    title: 'Date',
    editable: 'never',
    render: (call) => (
      <Typography>
        {moment(call.records[call.records.length - 1].callStart)
          .format('DD/MM [à] HH[h]mm')}
      </Typography>
    ),
    headerStyle: { textAlign: 'center' },
    cellStyle: { textAlign: 'center' },
  },
  {
    title: 'Service',
    editable: 'never',
    render: (call) => (
      <Typography>
        {findCall(calls, call).service}
      </Typography>
    ),
    headerStyle: { textAlign: 'center' },
    cellStyle: { textAlign: 'center' },
  },
  {
    title: 'Numéro',
    render: (call) => (
      <Typography>
        {findCall(calls, call).group}
      </Typography>
    ),
    headerStyle: { textAlign: 'center' },
    cellStyle: { textAlign: 'center' },
  },
  {
    title: 'Appellant',
    render: (call) => (
      <Typography>
        {call.records[call.records.length - 1].callerIdNumber}
      </Typography>
    ),
    headerStyle: { textAlign: 'center' },
    cellStyle: { textAlign: 'center' },
  },
  {
    title: 'Nb. appels',
    render: (call) => call.records.length,
    headerStyle: { textAlign: 'center' },
    cellStyle: { textAlign: 'center' },
  },
  {
    title: 'Agent',
    render: (call) => (
      <Typography>
        {getLoginFromPhone(call.records[call.records.length - 1].agent.replace(/^0033/, '0'))}
      </Typography>
    ),
    headerStyle: { textAlign: 'center' },
    cellStyle: { textAlign: 'center' },
  },
  {
    title: 'Raison',
    render: (call) => {
      const history = call.records[call.records.length - 1]?.history || [];
      return (
        <Grid>
          {history.length
            ? `${history[history.length - 1].action} par ${
              getLogin(history[history.length - 1].userId)}`
            : 'N/A'
          }
        </Grid>
      );
    },
    headerStyle: { textAlign: 'center' },
    cellStyle: { textAlign: 'center' },
  },
  {
    title: 'Audio',
    render: (call) => (
      <Grid style={{ minWidth: 380 }}>
        {call.records[call.records.length - 1].fileUrl
          ? <AudioPlayer url={call.records[call.records.length - 1].fileUrl} />
          : 'N/A'
        }
      </Grid>
    ),
    headerStyle: { textAlign: 'center' },
    cellStyle: { textAlign: 'center' },
  }
];

const ListCallsDialog = ({
  title, calls, classes,
  startAt, endAt,
  users, userId, user
}) => {
  const [original, setOriginal] = useState(calls);
  const [loading, setLoading] = useState(false);
  const { allCalls, uniqueCalls } = filterCalls(original);
  const loadData = () => {
    setLoading(true);
    return fetchCalls({ title, user, userId, users, endAt, startAt })
      .then(e => setOriginal(e))
      .finally(() => setLoading(false));
  };
  return (
    <CustomMaterialTable
      columns={columns(allCalls)}
      data={uniqueCalls}
      detailPanel={({ rowData }) =>
        <DetailPanel classes={classes} rowData={rowData} />
      }
      title={title}
      isLoading={loading}
      actions={[
        {
          tooltip: 'Rafraishir',
          isFreeAction: !(endAt && startAt),
          icon: 'replay',
          onClick: loadData
        },
      ]}
      options={{
        search: false,
        sorting: true,
        pageSize: 25,
        pageSizeOptions: [25, 50, 100]
      }}
    />
  );
};


export default connect(mapStateToProps)(withStyles(styles)(ListCallsDialog));
