import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Paper, Grid, Button } from '@material-ui/core';
import { getSimpleSignalements } from '../../../actions/signalements';
import { R_NO, D_REF } from '@bit/dev-lba.lib.local-globals/recStatus';
import { withAPISubscription } from '../../../hoc';
import { E_ENC, E_DEC } from '@bit/dev-lba.lib.local-globals/encaissementType';
import Encaissement from '../../Reglement/Encaissement';
import Decaissement from '../../Reglement/Decaissement';
import InterMenuInfo from '../Intervention/InterMenuInfo';
import { moment } from '@bit/dev-lba.lib.local-globals/moment';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Resulo from '../../Dialogs/recouvrement/ResoluComponent';
import {
  R_RES,
  R_RES_REF,
  R_PRE_RES,
  R_PER,
  R_PRE_PER,
  R_PER_REF,
} from '@bit/dev-lba.lib.local-globals/rStatus';
import api from '../../../api';
import notifSystem from '../../../notifSystem';

const mapStateToProps = ({ users, userId }) => ({ users, userId });

const findUserLogin = (id, users) => {
  const user = users.find((u) => u._id === id);
  return user ? user.login : '';
};

const styles = {
  timeLineLimit: {
    maxHeight: 300,
    overflow: 'auto',
  },
};

const changeStatus = {
  [R_PRE_RES]: (value) => (value ? R_RES : R_RES_REF),
  [R_PRE_PER]: (value) => (value ? R_PER : R_PER_REF),
};
class RecouvrementResolutionsAVerifMenu extends React.Component {
  state = {
    recouvrement: this.props.recouvrement,
  };

  setRecouvrement = (path, e) => {
    const { recouvrement } = this.state;
    this.setState({
      recouvrement: {
        ...recouvrement,
        [path]: e,
      },
    });
  };

  updateRecouvrement = (value) => () => {
    const { recouvrement } = this.state;
    return api.recouvrements
      .patch(recouvrement._id, {
        recouvrement: {
          id: recouvrement.id,
          resuloStatus: recouvrement.resuloStatus,
          montantEncaisse: recouvrement.montantEncaisse,
          status: changeStatus[recouvrement.status](value),
          isPris: recouvrement.isPris,
        },
      })
      .then(() => notifSystem.success('Message', 'Le status a bien été changé'))
      .catch(() =>
        notifSystem.success('Erreur', 'Veulliez réessayer ultérieurement')
      );
  };

  render() {
    const {
      encaissements,
      users,
      signalements,
      classes,
      elem: intervention,
    } = this.props;
    const { recouvrement } = this.state;
    const { enc, dec } = encaissements.reduce(
      (acc, curr) => {
        if (curr.type === E_ENC) {
          acc.enc.push(curr);
        } else if (curr.type === E_DEC) {
          acc.dec.push(curr);
        }
        return acc;
      },
      { enc: [], dec: [] }
    );
    return (
      <Paper>
        <Grid container spacing={2} justify="space-between">
          <Grid item xs={8} container spacing={2}>
            <Grid item xs={12}>
              {!!enc.length && <Encaissement encaissements={enc} justDisplay />}
              {!!dec.length && <Decaissement decaissements={dec} justDisplay />}
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6">Commentaires</Typography>
              <div className={classes.timeLineLimit}>
                {intervention.comments.map((e) => (
                  <InterMenuInfo
                    type={e.commentValue}
                    date={moment(e.date).format('L [à] HH:mm')}
                    user={findUserLogin(e.userId, users)}
                  />
                ))}
              </div>
            </Grid>
            {!!recouvrement.historique?.length && (
              <Grid item xs={6}>
                <Typography variant="h6">
                  Commentaires de recouvrements
                </Typography>
                <div className={classes.timeLineLimit}>
                  {recouvrement.historique.map((data) => (
                    <InterMenuInfo {...data} />
                  ))}
                </div>
              </Grid>
            )}
            {!!signalements.length && (
              <Grid item xs={12}>
                <Typography variant="h6">Signalements</Typography>
                <div className={classes.timeLineLimit}>
                  {signalements.map((data) => (
                    <InterMenuInfo {...data} />
                  ))}
                </div>
              </Grid>
            )}
          </Grid>
          <Grid
            container
            spacing={1}
            item
            xs={4}
            justify="center"
            alignItems="center"
          >
            {recouvrement.status === R_PRE_RES && (
              <Resulo
                key="1"
                setData={this.setRecouvrement}
                recouvrement={recouvrement}
              />
            )}
            <Grid item xs={4}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={this.updateRecouvrement(true)}
              >
                Valider
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                onClick={this.updateRecouvrement(false)}
              >
                Refuser
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

const fetch = (api, { elem, users }) => {
  const promies = [
    {
      name: 'signalements',
      promise:
        !isNaN(elem.id) &&
        getSimpleSignalements({ 'intervention.id': elem.id }).then((e) => ({
          data: e.body().map((e) => e.data()),
          name: 'signalements',
          format: (date) =>
            date.map((report) => ({
              type: report.signal.name,
              date: moment(report.date.creation).format('L [à] HH:mm'),
              user: findUserLogin(report.login.creation, users),
              message: `${report.description}`,
              obj: { style: { color: report.quarantaine ? 'red' : 'inherit' } },
            })),
        })),
    },
    {
      name: 'recouvrement',
      promise:
        ![R_NO, D_REF].includes(elem.recovery.status) &&
        !isNaN(elem.id) &&
        api.recouvrements
          .getAll({
            query: JSON.stringify({
              id: elem.id,
            }),
            limit: 1,
          })
          .then((e) => ({
            data: e.body().map((e) => e.data())[0],
            name: 'recouvrement',
            format: (data) => ({
              ...data,
              historique: data.historique
                .flat()
                .filter((e) => e.comments)
                .reverse()
                .map((res) => ({
                  type: res.comments,
                  date: moment(res.date).format('L [à] HH:mm'),
                  user: findUserLogin(res.userId, users),
                })),
            }),
          })),
    },
    {
      name: 'encaissements',
      promise: api.encaissements
        .getAll({
          query: JSON.stringify({
            $or: [{ id: elem.id }, { multis: { $elemMatch: { id: elem.id } } }],
          }),
        })
        .then((e) => ({
          data: e.body().map((e) => e.data()),
          name: 'encaissements',
        })),
    },
  ];
  return Promise.all(
    promies.reduce(
      (acc, curr) => (curr.promise ? acc.concat(curr.promise) : acc),
      []
    )
  ).then((results) =>
    results.reduce(
      (v, res) => ({
        ...v,
        [res.name]:
          typeof res.format === 'function' ? res.format(res.data) : res.data,
      }),
      {}
    )
  );
};
export default compose(
  connect(mapStateToProps),
  withAPISubscription(fetch, null, {
    default: {
      signalements: [],
      recouvrement: {},
      encaissements: [],
    },
    deverse: true,
    shouldReload: (props, prevProps) => props.elem.id !== prevProps.elem.id,
  }),
  withStyles(styles)
)(RecouvrementResolutionsAVerifMenu);
