import React from 'react';
import { connect } from 'react-redux';

import {
  formatReports,
  headersDisplayers,
  displayers,
  searchDisplayers,
  s_COLUMNS,
  s_KEYS,
  S_FILTERKEYS,
  styles,
  actions
} from './utils';
import ListRowContent from './RowContent';
import ListTable from '../GenericList/ListTable';

const mapStateToProps = ({ users, userId }) => ({
  users,
  userId,
  user: users.find(u => u._id === userId)
});

const ListContainer = ({
  users,
  userId,
  user,
  fields,
  reports,
  filter,
  sortBy,
  ascOrder,
  count,
  rowsPerPage,
  page,
  currentRow,
  launchRequest,
  reloadCurrent,
  handleChangeFilter,
  handleChangeField,
  handleChangeSortBy,
  handleChangeRowsPerPage,
  handleChangePage,
  handleClickRow
}) => {
  const elems = formatReports(reports, users, userId, fields);
  const elem = !!currentRow &&
    elems.find(e => e._id === currentRow);
  return <ListTable
    actions={actions}
    styles={styles}
    elems={elems}
    rowElem={elem}
    columns={s_COLUMNS(user)}
    keys={s_KEYS(user)}
    filterKeys={S_FILTERKEYS}
    filter={filter}
    fields={fields}
    sortBy={sortBy}
    ascOrder={ascOrder}
    count={count}
    rowsPerPage={rowsPerPage}
    page={page}
    currentRow={currentRow}
    displayers={displayers(user)}
    inputDisplayers={searchDisplayers}
    headersDisplayers={headersDisplayers}
    launchRequest={launchRequest}
    reloadCurrent={reloadCurrent}
    handleChangeFilter={handleChangeFilter}
    handleChangeField={handleChangeField}
    handleChangeSortBy={handleChangeSortBy}
    handleChangeRowsPerPage={handleChangeRowsPerPage}
    handleChangePage={handleChangePage}
    handleClickRow={handleClickRow}
    RowContent={ListRowContent}
  />;
};

export default connect(mapStateToProps)(ListContainer);
