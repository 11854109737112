import { List, fromJS } from 'immutable';

import { S_JUR, S_SATCL } from '@bit/dev-lba.lib.local-globals/services';
import { QUA_HIGH } from '@bit/dev-lba.lib.local-globals/aLevel';
import {
  getTypeRecouvrement,
  getStatusRecouvrement,
} from '@bit/dev-lba.lib.local-globals/recStatus';
import { RT_ART, RT_BTOB, RT_CLI } from '@bit/dev-lba.lib.local-globals/rTypes';
import PriseEnChargeDialog
from '../components/Dialogs/recouvrement/PriseEnChargeDialog';

import ConfirmBox from '../components/Dialogs/ConfirmBox';
import PriseDeContactDialog
from '../components/Dialogs/recouvrement/PriseDeContactDialog';
import AddDialog from '../components/Dialogs/AddDialog';
import DialogStatus
from '../components/Dialogs/recouvrement/DialogStatus';
import PriseEnChargeDialogSst
from '../components/Dialogs/recouvrement/Artisan/PriseEnChargeDialogSst';
import PriseDeContactDialogSst
from '../components/Dialogs/recouvrement/Artisan/PriseDeContactDialogSst';
import notifSystem from '../notifSystem';
import api from '../api';
import interFilters
from '@bit/dev-lba.lib.local-globals/Filters/InterFilters';
import { MAIL_REGEX } from '../utils/regexps';

const getData = async (filter) => {
  const params = window.location.pathname.split('/');
  const filters = interFilters()[params[params.length - 1]];
  const recouvrements = (await api
    .recouvrements
    .getAll({ query: JSON.stringify(filter) })
  )
    .body()
    .map(e => e.data());
  const requests = await Promise.all(
    recouvrements
      .chunk(50)
      .map(recouvrements => api
        .interventions
        .getAll({
          query: JSON.stringify({
            id: { $in: recouvrements.map(r => r.id) },
            ...(filters ? filters.match : {})
          })
        })
        .then(e => e.body())
      )
  );

  return requests
    .reduce((container, array) => {
      container.push(...array);
      return container;
    }, [])
    .map(e => {
      const intervention = e.data();

      return {
        ...intervention,
        checked: true,
        recouvrement: {
          ...(recouvrements.find(r => r.id === intervention.id) || {}),
          conclusions: [],
        },
      };
    });
};

export const middlewareRecouvrement = async (props, inter, cb) => {
  try {
    inter.recouvrement = await api
      .recouvrements
      .get(inter.id)
      .then(e => e.body().data());
    return cb(props, inter);
  } catch (error) {
    notifSystem.error('Erreur', 'Le dossier n\'a pas pu etre recuperé');
  }
};

const checkMail = str => str !== undefined && str !== ''
  && MAIL_REGEX.test(str);

export async function priseDeContact({ setDialog }, inter) {
  async function callback(inter, mail) {
    try {
      if (inter === null) {
        setDialog(null, false, '', null);
        return;
      }
      if (mail && mail.text && !checkMail(mail.desMail)) {
        return notifSystem.error('Erreur', 'L\'email saisi est incorrect.');
      }
      const { _id, ...NewRecouvrement } = inter.recouvrement;
      await api.recouvrements.patch(_id, {
        recouvrement: NewRecouvrement,
        mail
      });
      if (inter._id) {
        await api.interventions.patch(inter._id, {
          commissionValide: inter.commissionValide
        });
      }
      setDialog(null, false, '', null);
      return notifSystem.success('Succès', 'Prise de contact a été envoyée');
    } catch (e) {
      notifSystem.error(
        'Erreur',
        'La prise de contact n\'a pas été envoyée'
      );
    }
  }
  if (!inter.recouvrement) {
    try {
      inter.recouvrement = await api.recouvrements.get(inter.id)
        .then(e => e.body().data());
      return !inter.recouvrement.isPris ?
        setDialog(PriseEnChargeDialog, true, inter, callback) :
        setDialog(PriseDeContactDialog, true, inter, callback);
    } catch (error) {
      notifSystem.error('Erreur',
        'Prise de contact n\'a pas été envoyée');
    }
  }
  setDialog(PriseDeContactDialog, true, inter, callback);
}

export const generateRecouvrementFilterByType = (intervention) => {
  const type = getTypeRecouvrement(intervention.recovery.status);
  if (type === RT_BTOB) {
    return {
      grandCompte: intervention.recovery.grandCompte,
      type,
    };
  }
  if (type === RT_ART) {
    return {
      artisan: intervention.artisan,
      type,
    };
  }
};

export async function priseDeContactArtisan({ setDialog }, inter) {
  let type;

  if (!List.isList(inter)) {
    if (!inter.artisan) {
      return;
    }
    try {
      const filter = {
        isPris: true,
        ...generateRecouvrementFilterByType(inter)
      };

      type = filter.type;
      inter = fromJS(await getData(filter))
        .map(e => e.set('checked', e.get('_id') === inter._id));
    } catch (error) {
      return notifSystem.error(
        'Erreur',
        'Prise de contact n\'a pas été envoyée'
      );
    }
  }
  if (!type) {
    type = inter.getIn([0, 'recouvrement', 'type']);
  }

  async function callback(interventions = new List(), mail) {
    setDialog(null, false, '', null);
    try {
      if (mail && mail.text && !checkMail(mail.desMail)) {
        return notifSystem.error('Erreur', 'L\'email saisi est incorrect.');
      }
      if (interventions && interventions.size) {
        const { promises, interventionsFilterd } = interventions.reduce(
          (a, v) => {
            if (v.get('checked')) {
              const { _id, ...recouvrement } = v.get('recouvrement').toJS();
              a.promises.push(api.recouvrements.patch(_id, { recouvrement }));
              a.interventionsFilterd.push(v);
            }
            return a;
          },
          { promises: [], interventionsFilterd: [] }
        );

        if (promises.length) {
          await Promise.all(promises);
          await api.recouvrements
            .custom('sendMailSst')
            .post({ mail, interventions: interventionsFilterd });
        }
        notifSystem.success(
          'Succès',
          'La prise de contact a été envoyée'
        );
      }
    } catch (e) {
      notifSystem.error('Erreur', 'Prise de contact n\'a pas été envoyée');
    }
  }

  return setDialog(
    inter.getIn([0, 'recouvrement', 'isPris'])
      ? PriseDeContactDialogSst
      : PriseEnChargeDialogSst,
    true,
    { interventions: inter, type },
    callback
  );
}

export async function priseEnChargeArtisan({ setDialog, user }, inter, cb) {
  function callback(obj = new List(), priseDeContact, quarentine) {
    setDialog(null, false, '', null);
    if (obj && obj.size) {
      try {
        const promises = [];

        if (quarentine) {
          promises.push(api.artisans.patch(inter.artisan, {
            level: QUA_HIGH,
          }));
        }
        obj = obj.map(v => {
          if (v.get('checked')) {
            promises.push(
              api.recouvrements.patch(v.getIn(['recouvrement', '_id']), {
                recouvrement: {
                  id: v.getIn(['recouvrement', 'id']),
                  priseEnCharge: {
                    ...v.getIn(['recouvrement', 'priseEnCharge'], new Map()),
                    date: new Date(),
                    userId: user._id,
                  },
                  cause: v.getIn(['recouvrement', 'cause']),
                  isPris: true,
                },
              })
            );
            return v.setIn(['recouvrement', 'isPris'], true);
          }
          return v;
        });

        Promise
          .all(promises)
          .then(() => {
            notifSystem.success(
              'Succès',
              'Prise en charge envoyée'
            );
            if (cb) {
              cb();
            }
            if (priseDeContact) {
              return priseDeContactArtisan(
                { setDialog },
                obj
                  .filter(e => e.get('checked'))
                  .map(e => e.set('checked', e.get('_id') === inter._id)),
                null
              );
            }
          });
      } catch (e) {
        notifSystem.error('Erreur',
          'Prise en charge n\'a pas été envoyée');
      }
    }
  }
  const filter = {
    isPris: false,
    ...generateRecouvrementFilterByType(inter)
  };
  setDialog(
    PriseEnChargeDialogSst,
    true,
    { interventions: await getData(filter), type: filter.type },
    callback
  );
}

export function priseEnCharge({ setDialog, user }, inter, cb) {
  function callback(obj) {
    setDialog(null, false, '', null);
    if (obj) {
      try {
        obj.recouvrement.isPris = true;
        obj.recouvrement.priseEnCharge = {
          date: new Date(),
          ...obj.recouvrement.priseEnCharge,
          userId: user._id,
        };
        api.recouvrements.patch(obj.recouvrement._id, {
          recouvrement: {
            id: obj.recouvrement.id,
            priseEnCharge: obj.recouvrement.priseEnCharge,
            cause: obj.recouvrement.cause,
            isPris: obj.recouvrement.isPris
          }
        });
        notifSystem.success('Succès prise en charge',
          'Prise en charge envoyée');
        if (cb) {
          cb();
        }
        if (obj.priseDeContact) {
          return priseDeContact(
            { setDialog },
            {
              ...inter,
              recouvrement: { ...inter.recouvrement, ...obj },
            },
            null
          );
        }
      } catch (e) {
        notifSystem.error('Erreur prise en charge',
          'Prise en charge n\'a pas été envoyée');
      }
    }
  }
  setDialog(PriseEnChargeDialog, true, inter, callback);
}

export async function addRecouvrementAPI(
  { setDialog, user },
  { id,
    title,
    type,
    isLitige,
    pris,
    cause = null,
    attributionRecouvrement,
    delay }) {
  try {
    if (id === '') {
      notifSystem.error('Erreur', 'Merci d\'ajouter un numero OS');
    } else if (!id) {
      setDialog(null, false, '', null);
    } else {
      const res = await api.recouvrements
        .custom('addRecouvrement')
        .post({
          id,
          type,
          isLitige,
          cause,
          attributionRecouvrement,
          delay
        });
      const data = res.body().data();

      if (data.error) {
        notifSystem.error('Erreur', 'Numero OS introuvable');
      } else if (data.re.lastErrorObject.updatedExisting) {
        notifSystem.error('Erreur', `${title} a déjà été ajouté`);
      } else {
        setDialog(null, false, '', null);
        notifSystem.success('Succès', `${title} a été ajouté`);
        if (pris && (user.service === S_JUR || user.service === S_SATCL)) {
          return type === RT_CLI
            ? priseEnCharge({ setDialog, user }, {
              ...data.inter,
              recouvrement: data.re.value
            }, null)
            : priseEnChargeArtisan({ setDialog, user }, {
              ...data.inter,
              recovery: {
                ...data.inter.recovery,
                status: getStatusRecouvrement({ type, isLitige })
              },
            }, null);
        }
      }
    }
  } catch (e) {
    notifSystem.error('Erreur', `${title} n'a pas pu être ajouté`);
  }
}

export function addRecouvrement(
  { setDialog, user },
  { type, isLitige, title }) {
  setDialog(AddDialog, true, { title: `Ajout ${title}` },
    (id = false, pris, attributionRecouvrement, delay) =>
      addRecouvrementAPI({ setDialog, user },
        { id, title, type, isLitige, pris, attributionRecouvrement, delay })
  );
}

export async function recouvrementStatus(props, inter, cb) {
  async function callback(recouvrement) {
    props.setDialog(null, false, '', null);
    if (recouvrement) {
      try {
        await api.recouvrements.patch(recouvrement._id, {
          recouvrement: {
            id: recouvrement.id,
            status: recouvrement.status,
            priseEnCharge: recouvrement.priseEnCharge,
            montantEncaisse: recouvrement.montantEncaisse,
            resuloStatus: recouvrement.resuloStatus,
            isPris: true
          },
        });
        notifSystem.success('Succès',
          'Status modifé');
        if (cb) {
          cb();
        }
      } catch (e) {
        notifSystem.error('Erreur',
          'Status n\'a pas été modifé');
      }
    }
  }
  if (!inter.recouvrement && inter.artisan) {
    inter.recouvrement = await api.recouvrements
      .get(inter.id)
      .then(e => e.body().data());
  }
  props.setDialog(DialogStatus, true, inter, callback);
}

export function deletePriseDeContact(props, inter) {
  props.setDialog(ConfirmBox, true, {
    title: 'Etes-vous sûr de vouloir supprimer le dossier ?',
    confirmBox: 'Oui',
    closeBox: 'Annuler'
  }, confirmed => {
    if (confirmed) {
      api.recouvrements.delete(inter.recouvrement.id).then(() => {
        notifSystem.success('Succès', 'Le dossier a bien été supprimé');
      }).catch(() =>
        notifSystem.error('Erreur', 'Le dossier n\'a été supprimé'));
    }
    props.setDialog(null, false, null, null);
  });
}
