import React, { useState } from 'react';

import {
  Box,
  Grid,
  Tab,
  Tabs,
} from '@material-ui/core';
import ClientHelps from './ClientHelps';
import ArtisanHelps from './ArtisanHelps';

export default (props) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (e, newValue) => {
    setSelectedTab(newValue);
  };

  return [
    <Grid item xs={8}>
      <Tabs
        fullWidth
        centered
        value={selectedTab}
        onChange={handleTabChange}
        aria-label="tabs example"
      >
        <Tab label="Client" />
        <Tab label="Artisan"
          disabled={(
            !props.element.artisanQuestions &&
            !props.element.helpArtisan &&
            !props.element.artisanSpeech
          )}
        />
      </Tabs>
      {[ClientHelps, ArtisanHelps].map((Component, i) =>
        <Box
          key={i}
          role="tabpanel"
          hidden={selectedTab !== i}
          id={`wrapped-tabpanel-${i}`}
          aria-labelledby={`wrapped-tab-${i}`}
          children={<Component {...props} />}
        />
      )}
    </Grid>,

  ];
};
