import React from 'react';
import { Grid } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import GSMCounter from './counter';
import { counters, getColorByGSM } from '../utils';

const GSMCard = ({ values, card, classes }) => (
  <Card className={classes.card}>
    <div className={classes.stateDiv}
      style={{ backgroundColor: getColorByGSM(card) }} />
    <CardContent>
      <Typography align="center" variant="h5"
        component="h2" className={classes.cardTitle}>
        {card.number}
      </Typography>
      <Grid container spacing={4}>
        {counters.map((value, i) => (
          <Grid item xs={6} key={i}>
            <GSMCounter
              key={i}
              color={value.color}
              classes={classes}
              value={values[i]}
              Icon={value.Icon}
            />
          </Grid>
        ))}
      </Grid>
    </CardContent>
  </Card>
);

export default GSMCard;
