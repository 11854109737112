import React from 'react';
import { moment } from '@bit/dev-lba.lib.local-globals/moment';

import { paymentMethods } from '@bit/dev-lba.lib.local-globals/getDataById';

import CollapsedTwoColumnList from
'../../components/List/CollapsedTwoColumnList';

export const formatBoolean = (bool) => {
  if (bool !== undefined) {
    return bool ? 'Oui' : 'Non';
  }
};

export const formatPrice = (price) => {
  if (price !== undefined) {
    return `${(price / 100).toFixed(2)} €`;
  }
};

export const formatDate = (date, hour = true) =>
  date && moment(date).format(hour ? 'L [à] HH:mm' : 'L');

export const formatPaymentMethod = (paymentMethod) =>
  paymentMethods[paymentMethod].fullName;

export const formatProducts = (products) =>
  products &&
  products.map(({ ref, name, desc, pa, pu, qt }) => (
    <CollapsedTwoColumnList
      key={ref}
      title={`#${ref}`}
      data={{
        Nom: name,
        Description: desc,
        'Prix annoncé': formatPrice(pa),
        'Prix unitaire': formatPrice(pu),
        Quantité: qt,
      }}
    />
  ));

export const formatSupplies = (supplies) =>
  supplies &&
  supplies.map(
    ({ bl, montant, pro, qt, suppliesDate, suppliesName, ut, validate }, i) => (
      <CollapsedTwoColumnList
        key={i}
        title={suppliesName}
        data={{
          'Ajouté par': suppliesDate,
          BL: bl,
          'Prix unitaire': formatPrice(ut),
          Montant: formatPrice(montant),
          Fournisseur: pro,
          Quantité: qt,
          Validée: formatBoolean(validate),
        }}
      />
    )
  );
