import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { List } from 'immutable';

import { withStyles } from '@material-ui/core/styles';

import { withDisplaying } from '../../hoc';
import BodySupplies from './BodySupplies';
import withExpansionPanel from '../../hoc/withExpansionPanel';

const styles = (theme) => ({
  root: {
    width: '100%',
    marginBottom: theme.spacing(1),
  },
});

const ExpansionSupplies = withExpansionPanel(BodySupplies);

const Supplies = ({ classes, ...props }) => (
  <div className={classes.root}>
    <ExpansionSupplies expansionTitle={'Fournitures'} {...props} />
  </div>
);

Supplies.propTypes = {
  value: PropTypes.instanceOf(List).isRequired,
  setData: PropTypes.func.isRequired,
};

Supplies.defaultProps = {
  value: new List([]),
};

Supplies.displayName = 'Supplies';

export default compose(withStyles(styles), withDisplaying())(Supplies);
