import React from 'react';
import { displayMetierDialog } from '../../../actions/metiersSteps';
import { withStyles } from '@material-ui/core/styles';
import { Button, Grid, Typography } from '@material-ui/core';
import { genHTMLContent } from '../helpers';
import { withExpansionPanel } from '../../../hoc';
import AudioPlayer from '../../Calls/AudioPlayer';

const styles = (theme) => ({
  image: {
    width: '100%',
    maxHeight: 450,
    boxShadow: theme.shadows[2],
  },
});

const ExpansAttachements = withExpansionPanel(
  ({ classes, attachements }) =>
    <Grid container spacing={3}>
      {attachements.map(image =>
        <Grid item xs={12} sm={6}>
          <img
            className={classes.image}
            src={image.link || `data:image/png;base64, ${image.data}`}
            alt='illustrations'
          />
        </Grid>)}
    </Grid>);

const ExpansAudio = withExpansionPanel(
  ({ audio }) =>
    <Grid container spacing={3}>
      <Typography children='' variant='subtitle2' />
      {audio.map(e =>
        <Grid item xs={12}>
          <AudioPlayer
            url={e.link || `data:audio/wav;base64, ${e.data}` }
            noCopyIcon
          />
        </Grid>)}
    </Grid>);

const ShowMore = ({ classes, element, text, title }) =>
  <Grid style={{ paddingTop: 0, paddingBottom: 0 }} item xs={12} align="right">
    <Button
      variant='contained'
      color='primary'
      children="En savoir plus"
      onClick={() =>
        displayMetierDialog({
          content: (
            <>
              {genHTMLContent(text, true)}
              {element.audio && element.audio.length ? (
                <ExpansAudio
                  expansionTitle="Audio à écouter"
                  audio={element.audio}
                  expanded
                />
              ) : (
                ''
              )}
              {element.attachements && element.attachements.length ? (
                <ExpansAttachements
                  expansionTitle={`Illustrations à voir (${
                    element.attachements.length})`}
                  attachements={element.attachements}
                  classes={classes}
                />
              ) : (
                ''
              )}
            </>
          ),
          title,
        })
      }
    />
  </Grid>;

export default withStyles(styles)(ShowMore);
