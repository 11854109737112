import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import { Cancel } from '@material-ui/icons';
import red from '@material-ui/core/colors/red';
import { connect } from 'react-redux';

const styles = {
  avatar: {
    backgroundColor: red[100],
    color: red[600],
  },
};

const mapStateToProps = ({
  dialog: {
    contentProps: { inter = [] },
  },
}) => ({
  inter,
});

const DialogCheckList = ({ inter, classes }) => (
  <List>
    {(inter || []).map(e => (
      <ListItem>
        <ListItemAvatar>
          <Avatar className={classes.avatar}>
            <Cancel />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={e.name} />
      </ListItem>
    ))}
  </List>
);


export default connect(
  mapStateToProps
)(withStyles(styles)(DialogCheckList));
