import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import StatsPanel from '../common/panel/StatsPanel';
import { getOption } from '../common/graph/options/globalOptions';
import { moment } from '@bit/dev-lba.lib.local-globals/moment';
import { blue, red, green, grey, purple } from '@material-ui/core/colors';
import api from '../../../api';
import notifSystem from '../../../notifSystem';
import { getAllSourceOfDemande } from '../../../actions/demande';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import EchartsWithTotal from '../../Utils/EchartsWithTotal';
import { Money, MoneyOff } from '@material-ui/icons';
import { withAPISubscription } from '../../../hoc';
import Dropdown from '../common/dropdown/dropdown';

const mapStateToProps = ({ users, userId }) => ({
  users,
  userId,
  user: users.find(s => s._id === userId)
});

class StatsDemandes extends Component {
  state = {
    from: moment().startOf('week').toDate(),
    to: moment().endOf('week').toDate(),
    mode: 'week',
    isCA: false,
    data: {},
    usersSelected: [],
    users: [],
    checkedSources: []
  };

  componentDidMount() {
    const { users, sources, user } = this.props;
    const usersFiltered = users.filter((e) => e.act && (e.market || []).length);
    this.setState(
      {
        users: usersFiltered,
        checkedSources: Array.isArray(user.market) && user.market.length
          ? user.market
          : sources,
      },
      this.doRequests
    );
  }

  handleChange(e) {
    this.setState({ checkedSources: e.target.value }, () => this.doRequests());
  }

  onDatesChange = (from, to, mode) => {
    this.setState({ from, to, mode }, () => this.doRequests());
  };

  handleToggle = this.toggleArray('usersSelected');

  doRequests = () => {
    const { from, to, mode, isCA, checkedSources, usersSelected } = this.state;

    return api.stats
      .custom(`commercial/${isCA ? 'demandesCA' : 'statsDemandes'}`)
      .get({
        from,
        mode,
        isCA,
        to,
        checkedSources,
        usersSelected,
      })
      .then((response) => {
        this.setState({ data: response.body().data().result });
      })
      .catch(() =>
        notifSystem.error('Erreur', 'Impossible de charger les statistiques')
      );
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(prevState.usersSelected) !==
      JSON.stringify(this.state.usersSelected)
    ) {
      this.doRequests();
    }
  }

  handleClick = (e) => {
    const value = e.target.value;
    const { sources } = this.props;
    let checkedSources = [];
    if (value === 'all') {
      checkedSources = [...sources];
    } else if (value === 'notAll') {
      checkedSources = [];
    } else {
      checkedSources = [...this.state.checkedSources];
      if (!checkedSources.includes(value)) {
        checkedSources.push(value);
      } else {
        checkedSources.splice(checkedSources.indexOf(value), 1);
      }
    }
    this.setState({ checkedSources }, this.doRequests);
  };

  render() {
    const {
      from,
      to,
      mode,
      checkedSources,
      data = {},
      isCA,
      usersSelected,
      users,
    } = this.state;
    const { sources } = this.props;
    return (
      <StatsPanel
        onDatesChange={this.onDatesChange}
        defaultState={{ from, to, mode }}
        title={'Statistiques de demandes'}
        body={
          <React.Fragment>
            <Select
              value={checkedSources}
              onChange={(e) => this.handleClick(e)}
              renderValue={(selected) => selected.length ? selected[0] :
                'Choisissez les sources'}
              displayEmpty
            >
              <MenuItem
                value={
                  checkedSources.length === sources.length ? 'notAll' : 'all'
                }
                key={'all'}
              >
                <Checkbox checked={checkedSources.length === sources.length} />
                {checkedSources.length === sources.length
                  ? 'Désélectionner'
                  : 'Tout sélectionner'}
              </MenuItem>
              {sources &&
                sources.map((e, i) => (
                  <MenuItem value={e} key={i}>
                    <Checkbox checked={checkedSources.includes(e)} />
                    {e}
                  </MenuItem>
                ))}
            </Select>
            <Dropdown
              title={'Utilisateurs'}
              renderKey={'login'}
              data={users}
              isSelected={(e) => usersSelected.includes(e)}
              onChange={(e) => {
                this.handleToggle(e.target.value[1]);
              }}
            />
            <form>
              <Button
                onClick={() =>
                  this.setState({ isCA: !isCA }, () => this.doRequests())
                }
                variant="outlined"
                color={isCA ? 'primary' : 'secondary'}
              >
                {isCA ? <Money /> : <MoneyOff />}
              </Button>
            </form>
          </React.Fragment>
        }
      >
        <EchartsWithTotal
          notMerge={true}
          style={{ width: '100%' }}
          option={getOption({
            data,
            mode,
            labels: Object.keys(data),
            dates: [moment(from).startOf(mode), moment(to).endOf(mode)],
            colors: isCA
              ? [green[500], blue[500], red[500], grey[500], purple[500]]
              : [grey[500], blue[500], green[500], purple[500], red[500]],
          })}
        />
      </StatsPanel>
    );
  }
}

export default compose(
  withAPISubscription(getAllSourceOfDemande, null, {
    default: [],
    name: 'sources',
    shouldReload: (props, prevProps) => props.values !== prevProps.values,
  }),
  connect(mapStateToProps)
)(StatsDemandes);
