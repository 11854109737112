import React from 'react';
import {
  deepOrange,
  green,
  lightGreen,
  red,
  yellow,
} from '@material-ui/core/colors';
import {
  SentimentVerySatisfiedTwoTone,
  SentimentSatisfiedTwoTone,
  SentimentVeryDissatisfiedTwoTone,
  SentimentDissatisfiedTwoTone,
  MoodBadTwoTone,
} from '@material-ui/icons';

export const getEmoji = (ratio, fontSize = 100) => {
  if (ratio < 20) {
    return {
      component: (
        <SentimentVeryDissatisfiedTwoTone
          style={{ fontSize, color: red[500] }}
        />
      ),
      title: 'Très mauvais!',
      sentence:
        'Trop de client te dise non\n Tu devrais adapter ton discours',
    };
  }
  if (ratio >= 20 && ratio < 35) {
    return {
      component: (
        <SentimentDissatisfiedTwoTone
          style={{ fontSize, color: deepOrange[500] }}
        />
      ),
      title: 'Pas terrible ...',
      sentence: 'La clé c\'est un appel = un client',
    };
  }
  if (ratio >= 35 && ratio < 55) {
    return {
      component: (
        <MoodBadTwoTone style={{ fontSize, color: yellow[700] }} />
      ),
      title: 'Moyen ...',
      sentence: 'Tu dois rentabiliser chaque appel! ',
    };
  }
  if (ratio >= 55 && ratio < 80) {
    return {
      component: (
        <SentimentSatisfiedTwoTone
          style={{ fontSize, color: lightGreen[500] }}
        />
      ),
      title: 'Bien !',
      sentence: 'Tu es digne de ta place chez LBA',
    };
  }
  if (ratio >= 80) {
    return {
      component: (
        <SentimentVerySatisfiedTwoTone
          style={{ fontSize, color: green[500] }}
        />
      ),
      title: 'Tu es génial !',
      sentence: 'Très bon ratio appel / chiffre d\'affaire',
    };
  }
};
