import React from 'react';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import {
  Checkbox, List, ListItem, ListItemIcon
} from '@material-ui/core';

const UserSelection = ({
  classes, users, selectedUsers,
  updateSearch, changeSelectedUsers,
}) => <List className={classes.userList}>
  <ListItem>
    <TextField
      placeholder="Rechercher"
      onChange={(e) => updateSearch(e.target.value)}
    />
  </ListItem>
  {
    users.map((user, i) => (
      <ListItem key={i} dense button
        onClick={() => changeSelectedUsers(user)}>
        <ListItemIcon>
          <Checkbox
            edge="start"
            checked={selectedUsers.includes(user._id)}
          />
        </ListItemIcon>
        <ListItemText primary={user.login} />
      </ListItem>
    ))
  }
</List>;

export default UserSelection;
