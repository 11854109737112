import {
  List,
  AccountBalance,
  Build,
  Business,
  Event,
  Map,
  Send,
  Assignment,
} from '@material-ui/icons/';
import { todayToString } from '../../../utils/date';

export default [
  {
    name: 'Demarche BtoB',
    icon: Map,
    link: '/btob/demarche',
  },
  {
    name: 'Liste de BtoB',
    icon: AccountBalance,
    link: '/btob/list',
  },
  {
    name: 'Mes demandes',
    icon: List,
    count: true,
    link: (e) =>
      `/demandes/list/all?query="${JSON.stringify({
        source: { $in: e.market },
      }).replace(/\./g, '~')}"`,
  },
  {
    name: 'Mise à jour du partenaire',
    icon: Assignment,
    childs: [
      {
        name: 'En attente (général)',
        count: false,
        icon: List,
        link: () => '/intervention/list/propositionAv',
      },
      {
        name: 'En attente',
        count: true,
        icon: List,
        link: (e) =>
          `/intervention/list/propositionAv/login-ajout=${e.login}=${e._id}`,
      },
      {
        name: 'Validées',
        count: true,
        icon: List,
        link: (e) =>
          `/intervention/list/propositionVal/login-ajout=${e.login}=${e._id}`,
      },
      {
        name: 'Refusées',
        count: true,
        icon: List,
        link: (e) =>
          `/intervention/list/propositionRef/login-ajout=${e.login}=${e._id}`,
      },
    ],
  },
  {
    name: () => 'Mes Interventions',
    icon: Event,
    childs: [
      {
        name: todayToString(new Date()),
        bold: true,
        count: true,
        icon: List,
        link: e => `/intervention/list/btobAjdApr/login-ajout=${
          e.login}=${e._id}`,
      },
      {
        name: 'Envoyés',
        icon: List,
        count: true,
        link: (e) =>
          `/intervention/list/btobAjdSend/login-ajout=${e.login}=${e._id}`,
      },
      {
        name: 'A programmer',
        count: true,
        icon: List,
        link: e => `/intervention/list/btobAprog/login-ajout=${
          e.login
        }=${e._id}`,
      },
      {
        name: 'En cours',
        count: true,
        icon: List,
        link: (e) =>
          `/intervention/list/ecours/login-ajout=${e.login}=${e._id}`,
      },
      {
        name: 'A vérifier',
        icon: List,
        count: true,
        link: (e) => `/intervention/list/avr/login-ajout=${e.login}=${e._id}`,
      },
      {
        name: 'Devis à faire',
        icon: List,
        count: true,
        link: (e) =>
          `/devis/list/fromSignalement/login-ajout=${e.login}=${e._id}`,
      },
    ],
  },
  {
    name: 'Mes Devis',
    icon: Business,
    childs: [
      {
        name: 'Tous les Devis',
        icon: List,
        link: '/devis/list',
      },
      {
        name: todayToString(new Date()),
        bold: true,
        icon: List,
        count: true,
        link: (e) => `/devis/list/ajd/login-ajout=${e.login}=${e._id}`,
      },
      {
        name: 'A envoyer',
        icon: List,
        count: true,
        link: (e) => `/devis/list/btobEnv/login-ajout=${e.login}=${e._id}`,
      },
      {
        name: 'En attente',
        icon: List,
        count: true,
        link: (e) => `/devis/list/btobAtt/login-ajout=${e.login}=${e._id}`,
      },
      {
        name: 'Acceptés',
        icon: List,
        count: true,
        link: (e) => `/devis/list/acc/login-ajout=${e.login}=${e._id}`,
      },
      {
        name: 'Refusés',
        icon: List,
        count: true,
        link: (e) => `/devis/list/refu/login-ajout=${e.login}=${e._id}`,
      },
    ],
  },
  {
    name: 'Mes BtoB',
    icon: Event,
    childs: [
      {
        name: 'BtoB intéressé',
        icon: List,
        count: true,
        link: (e) => `/btob/list/intGC/login-ajout=${e.login}=${e._id}`,
      },
    ],
  },
  {
    name: 'Grand Comptes',
    icon: Build,
    childs: [
      {
        name: 'Liste',
        icon: List,
        link: '/config/table/grandcompte',
      },
      {
        name: 'Gestion',
        icon: List,
        link: '/config/grandCompte',
      },
    ],
  },
  {
    name: 'Import BtoB',
    icon: Build,
    link: '/btob/csv',
  },
  {
    name: 'Campagne BtoB',
    icon: Send,
    link: '/btob/campaign',
  },
];
