import { List } from 'immutable';
import { NOTIFICATIONS_FETCH } from '../constants/ActionTypes';
import notifSystem from '../notifSystem';

const initialState = new List();

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case NOTIFICATIONS_FETCH: {
      if (List.isList(action.payload)) {
        return action.payload;
      }
      if (
        ['sms', 'email', 'popUp'].includes(action.payload.type) &&
        action.payload.socket &&
        !action.payload.click
      ) {
        notifSystem[action.payload.type](
          action.payload.text,
          action.payload.data?.text,
          100000,
          {
            link: action.payload.link,
            _id: action.payload._id,
            path: action.payload.data?.path,
            audio: action.payload.data?.audio,
            artisan: action.payload.artisan,
            interId: action.payload.data?.interId,
            selectable: action.payload.data?.selectable,
          }
        );
      }
      return new List([
        ...(action.payload
          ? state.filter(elem => elem._id !== action.payload._id)
          : state),
        action.payload,
      ]);
    }
    default:
      return state;
  }
};
