import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { moment } from '@bit/dev-lba.lib.local-globals/moment';
import { List } from 'immutable';

import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  MenuItem,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  Typography,
  Fab,
} from '@material-ui/core';
import { Add, Delete, Lock, LockOpen } from '@material-ui/icons';
import { green, blue } from '@material-ui/core/colors';
import {
  E_HIPAY,
  E_PAYPAL,
  E_ONEY,
  E_LYD,
  E_VIR,
  E_CAU,
  E_ALMA,
  data as modes
} from '@bit/dev-lba.lib.local-globals/encaissementMode';

import CustomSelect from '../CustomInputs/CustomSelect';
import MoneyInput from '../CustomInputs/MoneyInput';
import DatePicker from '../DatePicker';
import CustomTextField from '../CustomInputs/CustomTextField';
import { TVA } from '@bit/dev-lba.lib.local-globals/comptaCalcules';
import { PE_ENC } from '@bit/dev-lba.lib.local-globals/paidStatusEncaissements';

const styles = {
  paper: {
    width: '100%',
  },
  addLine: {
    float: 'right',
    color: blue[500],
    margin: '1%',
  },
  table: {
    overflowY: 'visible',
    backgroundColor: green[500],
  },
  headtr: {
    height: 56,
  },
  tableCell: {
    padding: '10px',
    border: '1px solid #000000',
  },
  tableCellCrossed: {
    backgroundImage: 'linear-gradient(to bottom right, ' +
    'transparent calc(50% - 1px), black, ' +
    'transparent calc(50% + 1px))'
  },
  button: {
    width: '40px',
    height: '40px',
  },
  disabled: {
    pointerEvents: 'none',
  },
  alignCenter: {
    textAlign: 'center'
  }
};

const mapStateToProps = ({ users, userId }) => ({ users, userId });

class Encaissement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      line: props.line,
      data: {},
      encaissements: new List(),
      decaissements: new List(),
    };
    this.addMulti = this.addMulti.bind(this);
    this.getLine = this.getLine.bind(this);
    this.updateLine = this.updateLine.bind(this);
    this.delLine = this.delLine.bind(this);
    this.addLine = this.addLine.bind(this);
    this.getCompenent = this.getCompenent.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.encaissements !== state.encaissements
      || props.decaissements !== state.decaissements ) {
      return {
        line: props.line,
        data: props.data,
        encaissements: props.encaissements,
        decaissements: props.decaissements,
      };
    }
    return null;
  }

  addMulti(i) {
    const { userId, line, setData } = this.props;
    const tmp = this.state.encaissements.toJS();

    tmp[i].multis.push(
      line({
        numChq: tmp[i].numChq,
        id: tmp[i].id,
        tva: tmp[i].tva,
        encaissement: tmp[i].encaissement,
        saisie: (tmp[i].saisie || new Date()),
        mode: tmp[i].mode,
        userId,
        type: 2,
      })
    );
    setData('encaissements', new List(tmp));
  }

  updateLine(i, l, mIndex) {
    let line = this.state.encaissements.toJS();
    if (mIndex >= 0) {
      line[i].multis[mIndex] = l;
    } else {
      line[i] = l;
      if (line[i].multis) {
        line[i].multis.forEach((e) => {
          e.numChq = line[i].numChq;
        });
      }
    }
    this.props.setData('encaissements', new List(line));
  }
  addLine() {
    const { data, tva } = this.props;
    let line = this.state.encaissements.toJS();
    line.push(this.props.line({ tva, id: data ? data.id : 0 }));
    this.props.setData('encaissements', new List(line));
  }
  delLine(index, mIndex) {
    let tmp = this.state.encaissements.toJS();
    if (mIndex >= 0) {
      tmp[index].multis.splice(mIndex, 1);
    } else if (index >= 0) {
      this.props.deleteCaissement(tmp[index]);
      tmp.splice(index, 1);
    }
    this.props.setData('encaissements', new List(tmp));
  }
  addLineDecaissementAuto(index) {
    const {
      cardInfo,
      mode,
      numChq,
      tva
    } = this.state.encaissements.toJS()[index];
    const { data } = this.props;
    let decaissementLines = this.state.decaissements.toJS();
    decaissementLines.push(
      this.props.decaissementLine({
        ...(cardInfo && { cardInfo }),
        montant: 0,
        tva,
        id: data?.id,
        mode,
        numChq,
        decaissementAuto: true,
        paidStatus: PE_ENC,
      })
    );
    this.props.setData('decaissements',
      new List(decaissementLines));
  }

  getCompenent(line, index, mIndex = -1) {
    const { classes, justDisplay = false, users } = this.props;
    const user = line.userId && users.find((u) => u._id === line.userId);
    const checkMode = ![E_CAU, E_VIR, E_LYD]
      .includes(line.mode);
    const disabled = justDisplay || (line.isArchive && checkMode);
    const disabledMulti = mIndex >= 0 || line.isArchive || justDisplay;
    const arrayModes = [E_HIPAY, E_PAYPAL, E_ONEY, E_ALMA];
    return (
      <TableRow key={`${index} and ${mIndex}`}>
        <TableCell className={classes.tableCell}>
          <TextField
            type="string"
            onChange={(v) => {
              line.id = Number(v.target.value);
              this.updateLine(index, line, mIndex);
            }}
            fullWidth
            disabled={mIndex < 0 || line.isArchive || justDisplay}
            value={line.id}
          />
        </TableCell>
        <TableCell className={classes.tableCell}>
          <Typography children={(user && user.login) || 'Automatique'} />
        </TableCell>
        <TableCell className={classes.tableCell}>
          <CustomTextField
            fullWidth
            type="string"
            value={line.numChq || ''}
            path={['']}
            disabled={disabledMulti}
            setData={(p, v) => {
              line.numChq = v;
              this.updateLine(index, line, mIndex);
            }}
          />
        </TableCell>
        <TableCell
          className={classes.tableCell}
          style={{ pointerEvents: 'none' }}
        >
          <DatePicker
            disabled={true}
            style={{ width: '120px' }}
            startDate={moment(line.saisie).toDate()}
          />
        </TableCell>
        <TableCell className={classes.tableCell}>
          <DatePicker
            disabled={disabled}
            startDate={moment(line.encaissement).toDate()}
            onChange={(v) => {
              line.encaissement = moment(v).toISOString();
              this.updateLine(index, line, mIndex);
            }}
            before={['virement', 'lydia', 'hipay'].includes(line.mode)}
          />
        </TableCell>
        <TableCell className={classes.tableCell}>
          <CustomSelect
            disabled={disabled}
            path={['']}
            value={line.mode}
            setData={(p, v) => {
              line.mode = v;
              this.updateLine(index, line, mIndex);
            }}
            fullWidth
          >
            {modes.map((e) => (
              <MenuItem key={e._id} value={e.value}>
                {e.name}
              </MenuItem>
            ))}
          </CustomSelect>
        </TableCell>
        {this.state.encaissements.some((e) =>
          arrayModes.includes(e.mode)
        ) &&
          ((arrayModes.includes(line.mode) &&
            typeof line.threeDSecure === 'boolean' && (
            <TableCell
              className={`${classes.tableCell} ${classes.alignCenter}`}
              children={line.threeDSecure ? <Lock /> : <LockOpen />}
            />
          )) || (
            <TableCell
              className={`${classes.tableCell} ${classes.tableCellCrossed}`}
            />
          ))}
        {this.state.encaissements.some((e) =>
          arrayModes.includes(e.mode)
        ) &&
          ((arrayModes.includes(line.mode) &&
            line?.cardInfo?.expiry && (
            <TableCell
              className={`${classes.tableCell} ${classes.alignCenter}`}
              children={line.cardInfo.expiry}
            />
          )) || (
            <TableCell
              className={`${classes.tableCell} ${classes.tableCellCrossed}`}
            />
          ))}
        <TableCell className={classes.tableCell}>
          <MoneyInput
            disabled={disabled}
            fullWidth
            type="number"
            defaultValue={line.montant}
            path={['']}
            setData={(p, v) => {
              line.montant = v;
              this.updateLine(index, line, mIndex);
            }}
          />
        </TableCell>
        <TableCell className={classes.tableCell}>
          <CustomSelect
            path={['']}
            value={line.tva}
            setData={(p, v) => {
              line.tva = v;
              this.updateLine(index, line, mIndex);
            }}
            disabled={disabled}
            fullWidth
          >
            {TVA.map((e) => (
              <MenuItem key={e.number} value={e.number}>
                TVA {e.name}
              </MenuItem>
            ))}
          </CustomSelect>
        </TableCell>
        <TableCell className={classes.tableCell}>
          <Fab
            className={classes.button}
            disabled={disabledMulti}
            onClick={() => this.addMulti(index)}
            children={<Add />}
          />
        </TableCell>
        {this.state.encaissements.some((e) =>
          arrayModes.includes(e.mode)
        ) &&
          ((arrayModes.includes(line.mode) && (
            <TableCell className={classes.tableCell} align="center">
              <Fab
                className={classes.button}
                onClick={() => this.addLineDecaissementAuto(index)}
                children={<Add />}
              />
            </TableCell>
          )) || (
            <TableCell
              className={`${classes.tableCell} ${classes.tableCellCrossed}`}
            />
          ))}
        <TableCell className={classes.tableCell}>
          <Fab
            className={classes.button}
            disabled={disabled}
            onClick={() => this.delLine(index, mIndex)}
            children={<Delete />}
          />
        </TableCell>
      </TableRow>
    );
  }

  getLine() {
    const encaissements = this.state.encaissements || new List();
    return encaissements.map((line, index) => {
      if (line.multis && line.multis.length) {
        return [
          this.getCompenent(line, index),
          line.multis.map((e, i) => this.getCompenent(e, index, i)),
        ];
      }
      return this.getCompenent(line, index);
    });
  }

  render() {
    const { classes, justDisplay } = this.props;
    const hasHipay = this.state.encaissements.some((e) =>
      [E_HIPAY, E_PAYPAL, E_ONEY, E_ALMA].includes(e.mode)
    );
    return (
      <Paper className={classes.paper}>
        {!justDisplay && (
          <Button className={classes.addLine} onClick={this.addLine}>
            Ajouter une ligne
          </Button>
        )}
        <Table className={classes.table}>
          <TableHead>
            <TableRow className={classes.headtr}>
              <TableCell
                style={{ minWidth: '100px' }}
                className={classes.tableCell}
              >
                OS
              </TableCell>
              <TableCell className={classes.tableCell}>Utilisateur</TableCell>
              <TableCell className={classes.tableCell}>Label</TableCell>
              <TableCell
                className={classes.tableCell}
                style={{ minWidth: '85px' }}
              >
                Date de saisie
              </TableCell>
              <TableCell className={classes.tableCell}>
                Date d'encaissement
              </TableCell>
              <TableCell className={classes.tableCell}>Mode</TableCell>
              {hasHipay && [
                <TableCell className={classes.tableCell}>3D Secure</TableCell>,
                <TableCell className={classes.tableCell}>Expiration</TableCell>
              ]}
              <TableCell className={classes.tableCell}>Montant</TableCell>
              <TableCell className={classes.tableCell}>TVA</TableCell>
              <TableCell className={classes.tableCell}>Multi</TableCell>
              {hasHipay && [
                <TableCell className={classes.tableCell}>
                  Décaissement auto.
                </TableCell>,
              ]}
              <TableCell className={classes.tableCell}>Supp</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{this.getLine()}</TableBody>
        </Table>
      </Paper>
    );
  }
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps)
)(Encaissement);
