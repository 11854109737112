import React from 'react';

import {
  ADMIN,
  NOT_CONFIRMED,
  SENIOR,
  HEADCHIEF,
  CHIEF,
} from '@bit/dev-lba.lib.local-globals/permissions';
import { S_RH } from '@bit/dev-lba.lib.local-globals/services';
import componentLoader from '../../componentLoader';

const SuppliesInvoicesManager = React.lazy(() =>
  componentLoader(() => import('../../views/SuppliesInvoices'))
);

const GrandCompteListManager = React.lazy(() =>
  componentLoader(() => import('../../views/GrandCompte'))
);
const ConfigSSMetier = React.lazy(() =>
  componentLoader(() => import('../../views/ConfigSSMetier'))
);
const SmsManagement = React.lazy(() =>
  componentLoader(() => import('../../components/Sms/management'))
);
const IpManager = React.lazy(() =>
  componentLoader(() => import('../../components/TableConfigs/IpManager'))
);
const FournisseursManager = React.lazy(() =>
  componentLoader(() =>
    import('../../components/TableConfigs/FournisseursManager'))
);

const CallNotTransform = React.lazy(() =>
  componentLoader(() =>
    import('../../components/TableConfigs/CallNotTransform'))
);
const CommissionsGlobals = React.lazy(() =>
  componentLoader(() => import('../../views/Commissions/Globals'))
);
const StatsList = React.lazy(() =>
  componentLoader(() => import('../../components/Stats/common/stats/StatsList'))
);
const UtilsSms = React.lazy(() =>
  componentLoader(() => import('../../views/UtilsSms'))
);
const ConfigTables = React.lazy(() =>
  componentLoader(() => import('../../views/ConfigTables'))
);
const ConfigUsers = React.lazy(() =>
  componentLoader(() => import('../../views/ConfigUsers'))
);
const ConfigCombos = React.lazy(() =>
  componentLoader(() => import('../../views/ConfigCombos'))
);
const ConfigMetiersSteps = React.lazy(() =>
  componentLoader(() => import('../../views/ConfigMetiersSteps'))
);
const SmsCampagne = React.lazy(() =>
  componentLoader(() => import('../../components/Sms/SmsCampagne/index'))
);
const ConfigGeneral = React.lazy(() =>
  componentLoader(() => import('../../views/ConfigGeneral'))
);
const FournisseursCSV = React.lazy(() =>
  componentLoader(() => import('../../views/FournisseursCSV'))
);

const UsersService = React.lazy(() =>
  componentLoader(() => import('../../views/UsersService'))
);
const UpdateLignes = React.lazy(() =>
  componentLoader(() => import('../../views/UpdateLignes/index'))
);
const Mms = React.lazy(() =>
  componentLoader(() => import('../../views/Mms'))
);

export default [
  {
    path: '/suppliesInvoice',
    component: SuppliesInvoicesManager,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/config/grandCompte',
    component: GrandCompteListManager,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/config/table/:type',
    component: ConfigTables,
    restrictions: () => SENIOR,
  },
  {
    path: '/config/combinations',
    component: ConfigCombos,
    restrictions: () => ADMIN,
  },
  {
    path: '/config/configMetiersSteps',
    component: ConfigMetiersSteps,
    restrictions: () => ADMIN
  },
  {
    path: '/config/sousmetiers',
    component: ConfigSSMetier,
    restrictions: () => ADMIN,
  },
  {
    path: '/config/users/service',
    component: UsersService,
    restrictions: () => CHIEF,
  },
  {
    path: '/config/users',
    component: ConfigUsers,
    restrictions: ({ service }) =>
      ({
        [S_RH]: NOT_CONFIRMED,
      }[service] || ADMIN),
  },
  {
    path: '/config/commissions',
    component: CommissionsGlobals,
    restrictions: () => ADMIN,
  },
  {
    path: '/sms/edit',
    component: UtilsSms,
    restrictions: () => CHIEF,
  },
  {
    path: '/config/sms',
    component: SmsManagement,
    restrictions: () => ADMIN,
  },
  {
    path: '/config/ip',
    component: IpManager,
    restrictions: () => ADMIN,
  },
  {

    path: '/config/fournisseursManager',
    component: FournisseursManager,
    restrictions: () => ADMIN,
  },
  {
    path: '/config/callNotTransform',
    component: CallNotTransform,
    restrictions: () => ADMIN,
  },
  {
    path: '/sms/campagnes',
    component: SmsCampagne,
    restrictions: () => CHIEF,
  },
  {
    path: '/statistiques/',
    component: StatsList,
    restrictions: () => HEADCHIEF,
  },
  {
    path: '/config/general',
    component: ConfigGeneral,
    restrictions: () => ADMIN,
  },
  {
    path: '/config/fournisseursCSV',
    component: FournisseursCSV,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/config/updateLignes',
    component: UpdateLignes,
    restrictions: () => ADMIN,
  },
  {
    path: '/mms/:query',
    component: Mms,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/mms',
    component: Mms,
    restrictions: () => NOT_CONFIRMED,
  },
];
