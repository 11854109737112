import React from 'react';
import { connect } from 'react-redux';

import Input from '@material-ui/core/Input';

import { setDialogContentProp } from '../../actions/dialog';

const setText = setDialogContentProp('text');

const mapStateToProps = ({
  dialog: {
    contentProps: { placeholder, text }
  }
}) => ({ placeholder, text });

const mapDispatchToProps = {
  setText: e => setText(e.target.value)
};

const CommentDialog = ({
  placeholder = 'Commenter...',
  text = '',
  setText
}) =>
  <Input
    fullWidth
    placeholder={placeholder}
    onContextMenu={e => e.stopPropagation()}
    disableUnderline
    onChange={setText}
    value={text}
    multiline
    rows={10}
  />;

export default connect(mapStateToProps, mapDispatchToProps)(CommentDialog);
