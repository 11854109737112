import React, { Component } from 'react';
import { Grid, Typography } from '@material-ui/core';
import background from '../../public/errorPage.svg';
import { datadogRum } from '@datadog/browser-rum';
import compose from 'recompose/compose';
import { withRouter } from 'react-router-dom';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    if (['stage', 'production'].includes(process.env.NODE_ENV)) {
      datadogRum.addError(error, {
        errorInfo,
      });
      if (this.props.location.pathname !== '/') {
        setTimeout(() => {
          if (this.props.history) {
            this.props.history.push('/');
          }
          setTimeout(() => window.location.reload(true), 1000);
        }, 1000);
      }
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            backgroundImage: `url(${background})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <Grid container justify="center" spacing={3}>
            <Grid item xs={10} align="center">
              <Typography
                variant="h2"
                color="inherit"
                children={'Une erreur s\'est produite !'}
              />
              <Typography
                variant="h5"
                color="inherit"
                children={'Actualisation en cours ...'}
              />
            </Grid>
          </Grid>
        </div>
      );
    }
    return this.props.children;
  }
}

export default compose(withRouter)(ErrorBoundary);
