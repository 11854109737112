import React, { Component } from 'react';
import ListTable from '../../components/GenericList/ListTable';

const defaultRearrange = (data, {
  sorters,
  ascOrder,
  sortBy,
  page,
  rowsPerPage
}) =>
  data
    .sort((a, b) => (sorters && sortBy in sorters ?
      sorters[sortBy](a, b) :
      b[sortBy] - a[sortBy]) * (ascOrder || -1)
    )
    .slice(page * rowsPerPage, (page + 1) * rowsPerPage);

export default function withList(
  topLevelFormats = {},
  topLevelDisplayers = () => ({}),
  options = {}
) {
  const {
    keys: topLevelKeys = [],
    columns: topLevelColumns = []
  } = topLevelFormats;
  const {
    actions: topLevelActions = () => null,
    styles,
    rearrange = defaultRearrange,
    RowContent = null,
    sorters = {},
    withPagination = true,
  } = options;

  return WrappedComponent => class extends Component {
    state = { row: null }

    handleClickRow = id =>
      this.setState(({ row }) => ({ row: row === id ? null : id }))

    generateRow = () => {
      const { row } = this.state;
      const {
        data,
        reload,
        children
      } = this.props;
      const elem = !!row && data.find(d => d._id === row);

      if (!elem || (!RowContent && typeof children !== 'function')) {
        return null;
      }
      if (typeof children === 'function') {
        return () => children({ data: elem, reload });
      }
      return () => <RowContent {...this.props} data={elem} reload={reload} />;
    }

    render() {
      const { row } = this.state;
      const {
        actions = topLevelActions(this.props),
        data,
        sortBy,
        ascOrder,
        rowsPerPage,
        page,
        handleChangeSortBy,
        handleChangePage,
        handleChangeRowsPerPage,
        reload,
        formats: {
          keys = topLevelKeys,
          columns = topLevelColumns
        } = topLevelFormats,
        displayers = topLevelDisplayers(this.props)
      } = this.props;
      const Content = this.generateRow();

      return <WrappedComponent {...this.props}>
        <ListTable
          actions={actions}
          styles={styles}
          elems={withPagination ?
            rearrange(data, {
              sorters,
              sortBy,
              ascOrder,
              rowsPerPage,
              page
            }) : data}
          columns={columns}
          filterKeys={keys}
          displayers={displayers}
          sortBy={sortBy}
          ascOrder={ascOrder}
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          RowContent={Content}
          currentRow={Content && row}
          handleClickRow={this.handleClickRow}
          launchRequest={reload}
          handleChangeSortBy={handleChangeSortBy}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </WrappedComponent>;
    }
  };
}
