import React from 'react';
import { compose } from 'recompose';

import { CHIEF } from '@bit/dev-lba.lib.local-globals/permissions';
import withWidth from '@material-ui/core/withWidth';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import ObjectifsContainer from './utils/ObjectifsContainer';
import SMSPanel from '../../components/Sms/management/index';
import { ClassementTable, UserCard, StatsCalls } from '../../components/Stats';
import Planning from '../../components/Stats/SchudleStats/planning';
import GraphCATelepro from
'../../components/Stats/ChiffreDaffaires/GraphCATelepro';
import { S_INT, S_LEAD } from '@bit/dev-lba.lib.local-globals/services';
import TodayStats from '../../components/Stats/Commercial/todayStats';
import UserHolidaysConfig from '../../components/Users/holidays';
import AverifCounter from '../../components/Stats/Commercial/AverifCounter';
import InterSend from '../../components/Stats/Objectifs/InterSend';
import { getEquipes } from '../../utils/function';
import DemandesBySource from '../../components/Stats/Demandes/demandesBySource';
import { DemandePresence } from '../../components/Demandes';

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(2) * 2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    overflow: 'auto',
  },
  displayNoneRWD: {
    display: 'flex',
    alignItems: 'stretch',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  conditionalDisplayNoneRWD: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    [theme.breakpoints.between('md', 'lg')]: {
      display: 'block',
    },
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
    },
  },
  marginTop: {
    marginTop: 10,
  },
  fullWidth: {
    width: '100%',
  },
});

const DemandesDashboard = ({ user, users, classes, allUsers }) => {
  const isAdmin = user.permission <= CHIEF;
  const equipe = getEquipes({ user, users });
  return (
    <Grid container spacing={2}>
      <Grid container spacing={2} item xs={12}>
        <Grid item md={12} lg={4} className={classes.conditionalDisplayNoneRWD}>
          {UserCard && <UserCard user={user} />}
        </Grid>
        <Grid item md={12} lg={8}>
          <ObjectifsContainer user={user} personal allUsers={allUsers} />
        </Grid>
      </Grid>
      <Grid container spacing={2} item xs={12}>
        <Grid
          container
          item
          xs={12}
          sm={12}
          md={6}
          spacing={1}
          className={classes.displayNoneRWD}
        >
          <Grid item md={8} lg={8}>
            <AverifCounter />
          </Grid>
          <Grid item md={4} lg={4}>
            <DemandePresence />
          </Grid>
          <Grid item md={12} lg={12}>
            <InterSend />
          </Grid>
          <Grid item md={12} lg={12}>
            <Paper className={classes.paper}>
              <ClassementTable />
            </Paper>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <GraphCATelepro users={users} user={user} withTrust />
        </Grid>
      </Grid>
      <Grid container spacing={2} item xs={12}>
        <Grid
          item
          xs={12}
          md={isAdmin && equipe.length > 1 ? 6 : 12}
          className={classes.displayNoneRWD}
        >
          <Paper className={classes.fullWidth}>
            <DemandesBySource />
          </Paper>
        </Grid>
        {equipe.length > 1 && (
          <Grid item xs={12} md={6}>
            <Paper>
              <TodayStats users={equipe} />
            </Paper>
          </Grid>
        )}
      </Grid>
      <Grid container spacing={2} item xs={12} sm={12} md={12}>
        <Grid item xs={12} sm={12} md={6}>
          <StatsCalls users={users} user={user} />
        </Grid>
        <Grid item xs={12} sm={12} md={6} className={classes.displayNoneRWD}>
          <Planning SchudleStatsData={user} />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <UserHolidaysConfig />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        item
        xs={12}
        sm={12}
        md={12}
        className={classes.marginTop}
      >
        <Grid item xs={12} sm={12} md={12}>
          <SMSPanel limit={10} dashboard services={[S_INT, S_LEAD]} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default compose(withStyles(styles), withWidth())(DemandesDashboard);
