import React from 'react';

import {
  ADMIN,
  NOT_CONFIRMED,
  USER,
} from '@bit/dev-lba.lib.local-globals/permissions';

import { S_COMPTA, S_JUR } from '@bit/dev-lba.lib.local-globals/services';
import componentLoader from '../../componentLoader';

const ListWaitingPayment = React.lazy(() =>
  componentLoader(() => import('../../views/Compta/ListWaitingPayment'))
);
const ListArchivesReglement = React.lazy(() =>
  componentLoader(() => import('../../views/Compta/ListArchivesReglement'))
);
const ListArchivesAvoirs = React.lazy(() =>
  componentLoader(() => import('../../views/Compta/ListArchivesAvoirs'))
);
const ListArchivesRemises = React.lazy(() =>
  componentLoader(() => import('../../views/Compta/ListArchivesRemises'))
);
const ListAvoir = React.lazy(() =>
  componentLoader(() => import('../../views/Compta/ListAvoir'))
);
const ListRglmtNoOs = React.lazy(() =>
  componentLoader(() => import('../../views/Compta/ListRglmtNoOs'))
);
const ListRemiseCheque = React.lazy(() =>
  componentLoader(() => import('../../views/Compta/ListRemiseCheque'))
);
const ListArchivesPaiements = React.lazy(() =>
  componentLoader(() => import('../../views/Compta/ListArchivesPaiements'))
);
const ListRemiseChq = React.lazy(() =>
  componentLoader(() => import('../../views/Compta/ListRemiseChq'))
);

const ListeInterventionApayer = React.lazy(() =>
  componentLoader(() => import('../../views/Compta/ListeInterventionApayer'))
);

const ListPaymentAnnexes = React.lazy(() =>
  componentLoader(() => import('../../views/Compta/ListPaymentAnnexes'))
);

const StatPayAnn = React.lazy(() =>
  componentLoader(() => import('../../views/Compta/StatPayAnn'))
);

export default [
  {
    path: '/compta/lpa/:date',
    component: ListWaitingPayment,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/compta/lpa',
    component: ListWaitingPayment,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/compta/archivesReglement',
    component: ListArchivesReglement,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/compta/archivesAvoirs',
    component: ListArchivesAvoirs,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/compta/archivesRemises',
    component: ListArchivesRemises,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/compta/listAvoir/:date',
    component: ListAvoir,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/compta/listAvoir',
    component: ListAvoir,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/compta/listRglmtNoOs',
    component: ListRglmtNoOs,
    restrictions: ({ service }) =>
      ({
        [S_COMPTA]: NOT_CONFIRMED,
        [S_JUR]: NOT_CONFIRMED,
      }[service] || ADMIN),
  },
  {
    path: '/compta/archivesPaiements',
    component: ListArchivesPaiements,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/compta/remiseChq',
    component: ListRemiseChq,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/compta/listRemiseCheque',
    component: ListRemiseCheque,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/compta/listInterventionApayer',
    component: ListeInterventionApayer,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/compta/relFour',
    component: ListeInterventionApayer,
    restrictions: () => USER,
  },
  {
    path: '/compta/listPaymentAnnexes/:filter',
    component: ListPaymentAnnexes,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/compta/listPaymentAnnexes',
    component: ListPaymentAnnexes,
    restrictions: () => NOT_CONFIRMED,
  },
  {
    path: '/compta/statPaymentAnnexes',
    component: StatPayAnn,
    restrictions: () => NOT_CONFIRMED,
  }
];
