/* eslint-disable max-len */
const { formatDate } = require('@bit/dev-lba.lib.local-globals/moment');
const company = require('@bit/dev-lba.lib.local-globals/company');
const { externeNumber } = require('@bit/dev-lba.lib.local-globals/numbers');

export const defaultTextClient = (data) => ([{
  title: 'LES BONS ARTISANS : Confirmation de votre rendez-vous en SAV - Facture {id}',
  body: `${data.client.civility} ${data.client.name},

  Nous vous confirmons, après étude de votre demande, et des éléments transmis, la prise en charge de votre dossier dans le cadre d’un S.A.V.
  
  A cet effet, nous vous informons que le numéro de votre dossier SAV est le suivant : ${data.id}${data.date &&
    data.date.savDate ? ` <u>Le passage en sav est prévu pour le : ${formatDate(data.date && (data.date.savDate
    || data.date.intervention))}</u>` : ''}, notre technicien reviendra vers vous dans les meilleurs délais afin d’organiser un passage selon vos disponibilités. 
  
  Dans ce sens nous vous demandons de bien vouloir rester joignable par téléphone au ${data.client.tel1 || ''}. 
  Vous pouvez également nous contacter par téléphone au ${company.lines.telSAV} ou par e-mail à ${company.sav} 
  
  En vous remerciant une nouvelle fois pour votre confiance.`
},
{
  title: `LES BONS ARTISANS : Cloture SAV - Facture ${data.id}`,
  body: `Bonjour,\n
Nous revenons vers vous pour donner suite au passage de notre technicien dans le cadre d'un Service Après-Vente.
Ayant solutionné le problème signalé, nous considérons donc le Service Après-Vente réalisé et clôturons votre dossier.

Nos services restent disponibles pour toutes informations complémentaires.

Vous pouvez joindre notre Service Après-Vente par téléphone au ${company.lines.telSAV.replace(/\./g, ' ')} ou par mail à ${company.sav}.\n
Cordialement.`
}, {
  title: `LES BONS ARTISANS : Nous vous confirmons l'annulation de votre SAV - Facture ${data.id}`,
  body: `Bonjour, 

Nous accusons bonne réception de votre demande d'annulation de S.A.V.

Nous restons bien évidemment joignable et disponible pour répondre à l'ensemble de vos questions.

En vous souhaitant une bonne journée.

Cordialement `
}, {
  title: 'LES BONS ARTISANS : IMPOSSIBILITE DE CONTACT - Facture {id}',
  body: `Bonjour, 

Nous nous permettons de revenir vers vous au sujet de votre demande de Services Après-Vente.
Notre technicien nous informe ne pas réussir à vous joindre afin de programmer l'intervention.

Nous vous demandons donc afin de pouvoir répondre à vos attentes de nous recontacter afin de nous communiquer un numéro sur lequel vous êtes joignables et disponibles.

Sans retour de votre part sous huitaine, nous serons contraints de clôturer votre demande.
En vous remerciant par avance de votre compréhension.

Vous pouvez joindre notre Service Après-Vente par téléphone au ${company.lines.telSAV.replace(/\./g, ' ')} ou par mail à sav@lesbonsartisans.fr.

Cordialement.`
}, {
  title: `LES BONS ARTISANS : Suivi de dossier en SAV - Facture - ${data.id}`,
  body: `Bonjour ${data.client.civility} ${data.client.name || ''} ${data.client.firstname || ''},

Soyez assuré que la satisfaction de nos clients, par lesquels nous vivons est notre priorité au quotidien.
Toutefois, nous tenons à nous excuser pour le délai exceptionnel de traitement de votre demande de SAV. 

Nous vous prions de nous en excuser, nous reviendrons vers vous afin de vous apporter entière satisfaction dans les meilleurs délais. 

En vous remerciant par avance pour votre confiance. 
`
}, {
  title: `LES BONS ARTISANS : Suivi de dossier en SAV - Facture - ${data.id}`,
  body: `${data.client.civility} ${data.client.name || ''}, 

Afin de faire suite au passage de notre technicien en date du  ${formatDate(data.date && (data.date.savDate
  || data.date.intervention))} dans le cadre d’un service après-vente (${data.id}), ce dernier nous informe avoir solutionné le problème signalé. 

Nous tenons à vous informer que sans retour de votre part dans un délais de 48 heures nous considérons votre dossier comme étant clôturé. 

Notre service après-vente est évidemment à votre entière disposition au ${company.lines.telSAV} ou par e-mail à ${company.sav}`
}, {
  title: `LES BONS ARTISANS : Suivi de dossier en SAV - Facture - ${data.id}`,
  body: `${data.client.civility} ${data.client.name || ''},

Pour donner suite à notre dernier échange, nous confirmons la prise en compte de votre demande d’annulation de SAV (${data.id}). 

Nous restons évidemment disponibles et joignables au ${data.client.tel1 || ''}
En vous souhaitant une bonne journée. `
}, {
  title: `LES BONS ARTISANS : ${data.id} REFUS`,
  body: `Chère Madame, Cher Monsieur, 

Nous avons bien reçu votre message, concernant une demande de SAV. 
Cependant, après étude de votre dossier, ainsi que l’avis technique de notre intervenant, nous ne pouvons pas donner une suite favorable à votre demande pour le motif suivant : 

Nous sommes intervenus sur votre demande le : ${formatDate(data.date && data.date.intervention)}
Au terme de notre passage, 

Vous revenez vers nous le : ${formatDate(data.date && data.date.ajout)}
Afin de nous signaler `

}
]);

export const defaultTextArtisan = (data, artisan) => {
  const { client } = data;
  const { address } = client;
  const civility = client.civility || '';
  const name = client.name || '';
  const firstname = client.firstname ? ` ${client.firstname}` : '';
  const clientName = client.civility === 'Société' ?
    `${civility}. ${client.society || ''}\n(${name}${firstname})` :
    `${civility}. ${name}${firstname}`;
  const fullAddress = `${address.number || ''} ${address.road || ''}, ${
    address.zipcode || ''} ${address.city || ''}`;

  return [
    {
      title: `LES BONS ARTISANS : Nouvel demande de SAV - OS ${data.id}`,
      body: `Monsieur ${artisan.name},
  
Pour donner suite à notre échange téléphonique concernant la demande de SAV concernant l’OS : ${data.id}. 
Comme convenu, vous trouverez ci après les coordonnées du client, ainsi que l’objet du SAV afin de pouvoir les contacter et organiser un R.D.V. dans les plus brefs délais. 
  ${data.date && data.date.savDate ? `
  <u>Il a conjointement été convenu d’un passage en SAV le ${formatDate(data.date && (data.date.savDate
  || data.date.intervention), 'L[ vers ] HH:mm')}, le client a déjà été averti, merci de lui confirmer un créneau de passage, il attend votre appel</u>.
  ` : ''} 
<b><u>Cordoonées client :</u></b> 

- ${clientName}
  ${fullAddress}
- ${externeNumber(data)}

Merci d’avance pour votre rapidité et votre professionnalisme dans l’exécution de cette intervention.`
    },
    {
      title: `LES BONS ARTISANS : Confirmation de la clôture du dossier - OS ${data.id}`,
      body: `Monsieur ${artisan.name},

Suite à notre dernier échange, nous vous confirmons la prise en compte de la clôture du 
dossier en service après-vente concernant l’OS ${data.id} chez ${clientName} à ${address.city} pour le SAV : ${(data.infoDescSAV || {}).desc || ''} 

Afin de permettre l’envoie d’un rapport au client et assurer une clôture définitive du 
dossier, merci de nous transmettre tous les éléments en votre possession susceptible de 
nous aider dans la gestion du dossier (photos, rapport, attestation ou factures).

Dans l’attente de vous lire, nous vous remercions une nouvelle fois pour votre aide dans 
la gestion de ce dossier. `
    }
  ];
};


export const defaultTextAttestation = (data) => {
  const { name, firstname, address } = data.client || {};
  const { number, road, zipcode, city } = address || {};
  const fullAddr = `${number || ''} ${road || ''
  }, ${zipcode || ''} ${city || ''}`;
  const obj = {
    objMail: `LES BONS ARTISANS : Votre rapport d'intervention ${data.id}`,
    title: `LES BONS ARTISANS : Votre rapport d'intervention ${data.id}`,
    body:
      'Bonjour,\n\n' +
      'Nous faisons suite à votre précédente demande,\n' +
      'Vous trouverez ci-joint l’attestation demandée. ' +
      'Nos services restent disponibles pour toutes demandes informations complémentaires.\n\n' +
      'Cordialement.\n',
    desc: `Attestation / Rapport d’intervention lié à la facture ${data.id}`,
    observ:
      'Je soussigné Monsieur LEFEBVRE Bryan Responsable du Service Après Vente, ' +
      'agissant pour le compte de la société Les Bons Artisans ' +
      `enregistrée au greffe du tribunal de commerce de Paris sous le numéro SIRET ${company.siret}, atteste que :\n\n` +
      `{tab} La société Les Bons Artisans est intervenue au ${fullAddr}, ` +
      `chez ${name || ''} ${firstname || ''}, ` +
      `pour effectuer ${data.infoDesc.desc}{tabEnd}`,
  };
  return obj;
};
