import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Card from '@material-ui/core/Card';
import PropTypes from 'prop-types';
import {
  PhoneCallback,
  PhoneMissed,
  SettingsPhone,
} from '@material-ui/icons';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

import { green, red, lightBlue } from '@material-ui/core/colors';

const styles = () => ({
  UniqueCalls: {
    width: 30,
    height: 30,
    color: green[200],
  },
  MissedCalls: {
    width: 30,
    height: 30,
    color: red[200],
  },
  TransformCalls: {
    width: 30,
    height: 30,
    color: lightBlue[200],
  },
  card: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 222,
    maxHeight: 222,
  },
  textUniqueCalls: {
    fontSize: 25,
    fontWeight: 'bold',
    color: green[600],
    textShadow: `3px 0 0 ${green[200]}`,
    marginLeft: 3
  },
  textMissedCalls: {
    fontSize: 25,
    fontWeight: 'bold',
    color: red[600],
    textShadow: `3px 0 0 ${red[200]}`,
    marginLeft: 3
  },
  textTransformCalls: {
    fontSize: 25,
    fontWeight: 'bold',
    color: lightBlue[600],
    textShadow: `3px 0 0 ${lightBlue[200]}`,
    marginLeft: 3
  },
  listItem: {
    maxHeight: 60,
    paddingLeft: 0,
    paddingRight: 0,
    textALign: 'center'
  }
});

class UserCalls extends Component {

  render() {
    const {
      UserMissCalls,
      UserTransformCalls,
      UserUniqueCalls,
      classes } = this.props;
    return (
      <Card className={classes.card}>
        <List >
          <Tooltip title="Appels uniques">
            <ListItem className={classes.listItem}>
              <PhoneCallback className={classes.UniqueCalls}/>
              <Typography className={classes.textUniqueCalls}>
                {UserUniqueCalls}
              </Typography>
            </ListItem>
          </Tooltip>
          <Tooltip title="Appels manqués">
            <ListItem className={classes.listItem}>
              <PhoneMissed className={classes.MissedCalls}/>
              <Typography className={classes.textMissedCalls}>
                {UserMissCalls}
              </Typography>
            </ListItem>
          </Tooltip>
          <Tooltip title="Appels non transformés">
            <ListItem className={classes.listItem}>
              <SettingsPhone className={classes.TransformCalls}/>
              <Typography className={classes.textTransformCalls}>
                {UserTransformCalls}
              </Typography>
            </ListItem>
          </Tooltip>
        </List>
      </Card>
    );
  }
}

UserCalls.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(UserCalls);

