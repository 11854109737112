import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { grey } from '@material-ui/core/colors';
import { moment } from '@bit/dev-lba.lib.local-globals/moment';
import DoughnutChart from 'echarts-for-react';
import { commercial } from '@bit/dev-lba.lib.local-globals/serviceOVH';
import api from '../../../api';
import notifSystem from '../../../notifSystem';
import DatePicker from '../../DatePicker';
import { permit } from '@bit/dev-lba.lib.local-globals/restrictions';
import classNames from 'classnames';
import { setDialog } from '../../../actions/dialog';
import store from '../../../store';
import {
  I_ENC,
  I_AVR,
  I_VRF,
  I_APR,
} from '@bit/dev-lba.lib.local-globals/iStatus';
import CallChips from './CallChips';
import { IconButton, Tooltip } from '@material-ui/core';
import { Search } from '@material-ui/icons';

const styles = (theme) => ({
  paper: {
    overflow: 'hidden',
  },
  padding: {
    marginBottom: theme.spacing(3),
    textAlign: 'center',
    height: 300,
  },
  textAlign: {
    textAlign: 'center',
  },
  title: {
    margin: '20px 20px 5px',
    fontSize: '1.2rem',
    color: grey[500],
  },
  progress: {
    width: 70,
    height: 70,
    margin: '120px 0 120px',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  statsAppels: {
    width: '100%',
    margin: 10,
    padding: 3,
    textAlign: 'center',
  },
  titleStats: {
    marginBottom: 5,
    fontSize: 10,
    color: '#fff',
  },
  stats: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#fff',
  },
  [theme.breakpoints.down('sm')]: {
    sizePaperRWD: {
      padding: theme.spacing(1),
      width: '100%',
    },
    displayNoneRWD: {
      display: 'none',
    },
    doughnutChartMobile: {
      margin: '30px 0',
      height: '140px !important',
    },
    'doughnutChartMobile > div:nth-of-type(1)': {
      height: '128px',
    },
    chartHeight: {
      height: 300,
    },
  },
});

const dates = [
  { name: 'Date de début', value: 'startAt' },
  { name: 'Date de fin', value: 'endAt' },
];

const option = (optionDoughnutChart) => ({
  tooltip: {
    trigger: 'item',
    formatter: '{a} <br/>{b} : {c} ({d}%)',
  },
  series: [
    {
      name: 'Appels uniques',
      type: 'pie',
      radius: '80%',
      center: ['50%', '60%'],
      data: optionDoughnutChart,
      itemStyle: {
        emphasis: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)',
        },
      },
    },
  ],
});

const filters = (props, state) => {
  const dateFilter = {
    $gt: moment(state.startAt).startOf('day').toDate(),
    $lt: moment(state.endAt).endOf('day').toDate(),
  };
  return ({
    'Interventions démarchées et envoyées': {
      'date.demarchage': { $exists: true },
      'date.envoi': dateFilter,
    },
    'Interventions ajoutées': {
      'date.ajout': dateFilter,
    },
    'Interventions en cours': {
      'date.ajout': dateFilter,
      status: { $in: [I_ENC, I_AVR, I_VRF] },
    },
    'Interventions a prog': {
      'date.ajout': dateFilter,
      status: I_APR,
    }
  });
};
class StatsCalls extends PureComponent {
  state = {
    optionDoughnutChart: [],
    load: true,
    startAt: moment().startOf('day').toDate(),
    endAt: moment().endOf('day').toDate(),
    reloadCalls: false
  };

  getData = ({ endAt, startAt }) => {
    this.setState({ load: true });
    api.records
      .custom('getStats')
      .get({
        startAt,
        endAt,
        service: [commercial],
      })
      .then((attributes) => {
        this.setState({
          optionDoughnutChart: attributes
            .body()
            .map((e) => e.data())
            .filter((e) => e.uniquesCalls)
            .map((agent) => ({
              name: agent.name,
              value: agent.uniquesCalls,
            })),
          load: false,
        });
      })
      .catch((e) =>
        notifSystem.error(e.name, e.response ? e.response.data : e.message)
      );
  };

  componentDidMount() {
    const { endAt, startAt } = this.state;
    this.getData({ endAt, startAt });
  }

  handleClickInterventions = (filter) =>
    store.dispatch(
      setDialog({
        name: 'InterListDialog',
        open: true,
        hideClose: true,
        dialogProps: {
          title: 'Liste d\'intervention',
          maxWidth: 'xl',
        },
        contentProps: {
          filter: {
            ...filters(this.props, this.state)[filter],
          },
        },
        actions: [
          {
            children: 'Fermer',
            color: 'secondary',
            onClick: (r, close) => close(),
          },
        ],
      })
    );

    openUniques = (calls, title) =>
      store.dispatch(
        setDialog({
          name: 'ListCallsDialog',
          open: true,
          hideClose: true,
          dialogProps: {
            maxWidth: 'lg',
          },
          contentProps: {
            title, calls,
            startAt: this.state.startAt,
            endAt: this.state.endAt,
          },
          actions: [
            {
              children: 'Fermer',
              color: 'secondary',
              onClick: (r, close) => close(),
            },
          ],
        })
      );

  setDateParams = (date, params) => {
    const dateToCheck =
      params === 'startAt'
        ? moment(date).startOf('day')
        : moment(date).endOf('day');
    this.setState(
      { [params]: dateToCheck.toDate() },
    );
  };

  render() {
    const { classes, user, users } = this.props;
    const {
      optionDoughnutChart, load, endAt, startAt
    } = this.state;

    return (
      <React.Fragment>
        <Paper className={classNames(classes.paper, classes.sizePaperRWD)}>
          <Grid container justify="space-around">
            <Grid item xs={12}>
              <Typography className={classes.title} variant="h6" align="left">
                Statistiques appels
              </Typography>
            </Grid>
            {permit(user, { key: 'adminAcces' }) && [
              dates.map((e) => (
                <Grid key={e.name} item xs={5} className={classes.container}>
                  <DatePicker
                    before={true}
                    label={e.name}
                    startDate={this.state[e.value]}
                    onChange={(v) => this.setDateParams(v, e.value)}
                  />
                </Grid>
              )),
              <Grid key={2} item xs={2} className={classes.container}>
                <Tooltip title='Rechercher'>
                  <IconButton onClick={() => this.getData({ endAt, startAt })}>
                    <Search />
                  </IconButton>
                </Tooltip>
              </Grid>
            ]}
            <CallChips
              users={users}
              exclude={['Chiffre d\'affaire']}
              startAt={this.state.startAt}
              endAt={this.state.endAt}
              xs={4}
              withEmoji={false}
              openInter={this.handleClickInterventions}
              openUniques={this.openUniques}
              load={load}
            />
          </Grid>
          <Grid container justify="center">
            <Grid item xs={12} className={classes.padding} justify="center">
              {(load && (
                <CircularProgress
                  className={classes.progress}
                  color="secondary"
                />
              )) ||
                (optionDoughnutChart.length !== 0 && (
                  <DoughnutChart
                    option={option(optionDoughnutChart)}
                    className={classes.doughnutChartMobile}
                  />
                ))}
            </Grid>
          </Grid>
        </Paper>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(StatsCalls);
