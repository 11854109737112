import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    background: 'rgba(255,255,255,0.75)',
    zIndex: 1301,
    display: 'flex',
  },
  title: {
    margin: theme.spacing(2),
  }
}));

export default function SuspenseLoading({ message = '' }) {
  const classes = useStyles();
  return (
    <Backdrop className={classes.backdrop} open>
      <CircularProgress size={50} color="secondary" />
      <h3 className={classes.title}
        children={message || 'Chargement en cours...'}
      />
    </Backdrop>
  );
}
