/* eslint-disable max-lines */
import Immutable from 'immutable';
import {
  D_NOT,
  D_PRE,
  D_MAR,
} from '@bit/dev-lba.lib.local-globals/deStatus';

import {
  Assignment,
  Call,
  MailOutline,
  Comment,
  Schedule,
  Person,
  Close,
  Check,
  CheckCircle,
  AttachMoney,
  Payment,
  ReportProblem,
  Email,
  Grade,
  Send,
  NotificationImportant,
  Delete,
  DeleteForever,
  AddCircle,
  CancelPresentation,
  Sms,
  SpeakerNotesOff,
  Edit,
  Alarm,
  TouchApp,
  AssignmentInd,
  Search,
  RingVolume,
  AccountBalanceWallet,
  CreditCard,
  BusinessCenter,
  HowToReg,
  Brightness1
} from '@material-ui/icons';

import {
  S_PART,
  S_SATCL,
  S_COMPTA,
  S_DEVIS,
  S_JUR,
} from '@bit/dev-lba.lib.local-globals/services';
import {
  I_APR,
  I_ENC,
  I_AVR,
  I_ANN,
  I_REF,
  I_DVG,
} from '@bit/dev-lba.lib.local-globals/iStatus';
import {
  SAV_DSAV,
  SAV_APP,
  SAV_DAE,
  SAV_DAREF,
  SAV_DF,
  SAV_DISAV,
  SAV_DISAVE,
  SAV_DFC
} from '@bit/dev-lba.lib.local-globals/ListSAVInters';
import {
  HEADCHIEF,
  ADMIN,
} from '@bit/dev-lba.lib.local-globals/permissions';
import { permit } from '@bit/dev-lba.lib.local-globals/restrictions';
import {
  M_REG,
  M_PAY,
  M_PAYAN,
} from '@bit/dev-lba.lib.local-globals/comptaCommentes';
import { VT } from '@bit/dev-lba.lib.local-globals/categories';
import {
  checkSupportAjout
} from '@bit/dev-lba.lib.local-globals/verifications';
import {
  cancel,
  openVerifDialog,
  send,
  comment,
  appel,
  schedule,
  reactivated,
  signalement,
  openAwaitingBillDialog,
  reglement,
  payment,
  favoris,
  priorityFollow,
  refuser,
  showSMS,
  comptaComments,
  middlewareDialogCompta,
  callPriseDeCoteDialog,
  callReminderDialog,
  callReminderHistoryDialog,
  addToSupportActionList,
  deleteFromSupportDialog,
  sendUploadSms,
  sendToMarket,
  abPlusCb,
  paiementAnnexes,
  openAttachmentsDialog,
  modifyTransactions,
  openAssignInterDialog,
  addInterToMajSupport,
  changeSupplieProgress,
} from '../../../actions/inter';
import {
  checkAwaitingBillRequirements,
  displayVerifButtonOnMAJ
} from '../../../actions/verifications';
import { artisanNumbers } from '../Actions';
import {
  demandeSAV,
  DeleteSAV,
  updateStatusSAV,
  noContactSAV,
} from '../../../actions/savIntervention';
import { openPropositionDialog } from '../../../actions/proposition';
import recouvrementActions from '../Recouvrement/recouvrementActions';
import store from '../../../store';
import creditAideActions from '../Recouvrement/creditAideActions';
import {
  L_PAYDIF,
  L_PAYMULTI
} from '@bit/dev-lba.lib.local-globals/paymentCBActionTypes';
import {
  P_NONE,
} from '@bit/dev-lba.lib.local-globals/isValidStatus';
export default [
  {
    name: 'Modifier',
    icon: Assignment,
    link: (data) => {
      window.open(`/intervention/${data.id}`);
    },
    action: (props, elem) => {
      props.history.push(`/intervention/${elem.id}`);
    },
  },
  {
    name: 'Suivi prioritaire',
    icon: NotificationImportant,
    action: priorityFollow,
    customVisible: (elem, props) =>
      props.selected &&
      (!props.selected.priorityFollow ||
      !props.selected.priorityFollow.includes(props.userId)) &&
      permit(
        props.users.find((u) => u._id === props.userId),
        { key: 'displayInterActions' }
      ),
  },
  {
    name: 'Supprimer le Suivi prioritaire',
    icon: NotificationImportant,
    action: priorityFollow,
    customVisible: (elem, props) =>
      props.selected &&
      props.selected.priorityFollow &&
      props.selected.priorityFollow.includes(props.userId) &&
      permit(
        props.users.find((u) => u._id === props.userId),
        { key: 'displayInterActions' }
      ),
  },
  {
    name: 'SMS',
    icon: Sms,
    sub: [
      {
        name: 'Conversation Client ',
        value: 'client.tel1',
        action: (props, elem) => {
          showSMS(props, elem, 'inter');
        },
      },
      {
        name: 'Conversation Artisan ',
        customVisible: (props, elem) => elem.selected && elem.selected.artisan,
        value: 'currentArtisan.tel1',
        action: (props, elem) => {
          showSMS(props, elem, 'artisan');
        },
      },
    ]
  },
  {
    name: 'Appels',
    icon: Call,
    sub: [
      {
        name: 'Téléphone Client 1',
        value: 'client.tel1',
        action: (props, elem) => {
          appel(
            props,
            elem.client.tel1,
            { client: elem.client }
          );
        },
      },
      {
        name: 'Téléphone Client 2',
        value: 'client.tel2',
        action: (props, elem) => {
          appel(
            props,
            elem.client.tel2,
            { client: elem.client }
          );
        },
      },
      {
        name: 'Téléphone Client 3',
        value: 'client.tel3',
        action: (props, elem) => {
          appel(
            props,
            elem.client.tel3,
            { client: elem.client }
          );
        },
      },
      {
        name: 'Payeur tel 1',
        value: 'billing.tel1',
        action: (props, elem) => {
          appel(props, elem.billing.tel1, {
            client: elem.client,
            billing: elem.billing,
          });
        },
      },
      {
        name: 'Payeur tel 2',
        value: 'billing.tel2',
        action: (props, elem) => {
          appel(props, elem.billing.tel2, {
            client: elem.client,
            billing: elem.billing,
          });
        },
      },
      {
        name: 'Payeur tel 3',
        value: 'billing.tel3',
        action: (props, elem) => {
          appel(props, elem.billing.tel3, {
            client: elem.client,
            billing: elem.billing,
          });
        },
      },
      ...artisanNumbers,
    ],
  },
  {
    name: 'Mise à jour du partenaire',
    icon: Person,
    customVisible: (elem, props) =>
      props.selected &&
      props.selected.artisan &&
      props.selected.proposition &&
      props.selected.proposition.isValid === P_NONE,
    action: (props, inter) => openPropositionDialog(
      props.idMenu === 'addInter' ? inter.id : inter),
  },
  {
    name: 'Commenter',
    icon: Comment,
    action: comment,
    customVisible: (elem, props) =>
      props.selected &&
      permit(
        props.users.find((u) => u._id === props.userId),
        {
          key: 'displayInterActions',
        }
      ),
  },
  {
    name: 'Support',
    icon: TouchApp,
    customVisible: (e, p) => checkSupportAjout(p),
    action: (props, cb) => addToSupportActionList(props, cb),
  },
  {
    name: 'Demande de mise à jour (support)',
    icon: BusinessCenter,
    customVisible: (elem, props) => false && props.selected &&
      [I_ENC, I_AVR].includes(props.selected.status) &&
      !props.selected.login.majSupport,
    action: (props) => addInterToMajSupport(props),
  },
  {
    name: 'Supprimer du support',
    icon: Delete,
    customVisible: (elem, props) => {
      const user = props.users.find((e) => e._id === props.userId);
      const points =
        props.counter[`interventions.countSupportDelete.${user.login}`] || 0;
      return (
        props.selected &&
        props.selected.login.support &&
        !props.selected.login.deleteSupport && (
          permit(user, { key: 'deleteSupport' }) ||
            ((user.service === S_DEVIS ||
              user._id === props.selected.login.support ||
              user.supportIds.includes(props.selected.login.support)) &&
              !props.selected.login.deleteSupport &&
              points < 3)
        )
      );
    },
    action: deleteFromSupportDialog,
  },
  {
    name: 'Prise de côte',
    icon: Edit,
    action: callPriseDeCoteDialog,
    customVisible: (elem, { users, selected, userId }) =>
      permit(
        users.find((u) => u._id === userId),
        {
          key: 'displayInterActions',
        }
      ) &&
      selected &&
      selected.categorie === VT &&
      !selected.measurePerformedPayment,
  },
  {
    name: 'Favoris',
    icon: Grade,
    customVisible: (elem, props) => {
      const user = props.users.find((e) => e._id === props.userId);
      return (
        props.selected &&
        props.selected.aDemarcher > D_NOT &&
        user &&
        permit(user, { auth: HEADCHIEF })
      );
    },
    action: favoris,
  },
  {
    name: 'Prospection',
    icon: Search,
    customVisible: (elem, props) => {
      const user = props.users.find((e) => e._id === props.userId);
      return props.selected && props.selected.aDemarcher > D_NOT && user;
    },
    link: (elem) => {
      window.open(`/prosp/list?interSearch=${elem.id}`);
    },
    action: (props, elem) => {
      props.history.push(`/prosp/list?interSearch=${elem.id}`);
    },
  },
  {
    name: 'Changer l\'avancement',
    icon: Brightness1,
    customVisible: (elem, props) => {
      const user = props.users.find((e) => e._id === props.userId);
      return (
        Immutable.List.isList(props.users) &&
        permit(user, { key: 'achatComments' }) &&
       props.selected &&
        props.selected.supplieProgress
      );
    },
    action: (props, elem) => changeSupplieProgress(elem)
  },
  ...recouvrementActions,
  ...creditAideActions,
  {
    name: 'Réglement Client',
    icon: AttachMoney,
    customVisible: (elem, props) =>
      permit(
        props.users.find((u) => u._id === props.userId),
        {
          key: 'displayCommentaire',
        }
      ),
    sub: [
      {
        name: (props) => {
          const user = props.users.find((e) => e._id === props.userId);
          return permit(user, { key: 'menuReglementClient' })
            ? 'Ajouter/Modifier'
            : 'Voir réglement';
        },
        action: (...arg) => middlewareDialogCompta(...arg, M_REG, reglement),
        customVisible: (elem, props) =>
          props.selected && props.selected.status !== I_APR,
      },
      {
        name: 'Commentaire',
        action: (...arg) => comptaComments(...arg, M_REG),
      },
    ],
  },
  {
    name: 'Prélèvements automatiques',
    icon: CreditCard,
    customVisible: (elem, props) =>
      (props?.selected?.transaction?.transactionExists ||
      [L_PAYMULTI, L_PAYDIF].includes(props?.selected?.transaction?.type)) &&
      permit(
        props.users.find((u) => u._id === props.userId),
        {
          key: 'modifyTransactions',
        }
      ),
    action: (...arg) => modifyTransactions(...arg),
  },
  {
    name: 'Paiement SST',
    icon: Payment,
    customVisible: (elem, props) =>
      props.selected &&
      props.selected.status !== I_APR &&
      permit(
        props.users.find((u) => u._id === props.userId),
        {
          key: 'displayInterActions',
        }
      ),
    sub: [
      {
        name: 'Ajouter/Modifier',
        action: (...arg) => middlewareDialogCompta(...arg, M_PAY, payment),
        customVisible: (elem, props) => {
          const user = props.users.find((e) => e._id === props.userId);
          return (
            Immutable.List.isList(props.users) &&
            permit(user, { key: 'menuReglementClient' }) &&
            props.selected &&
            props.selected.status !== I_APR
          );
        },
      },
      {
        name: 'Commentaire',
        action: (...arg) => comptaComments(...arg, M_PAY),
        customVisible: (elem, props) =>
          props.selected && props.selected.status !== I_APR,
      },
    ],
  },
  {
    name: 'Paiements annexes',
    icon: AccountBalanceWallet,
    customVisible: (elem, props) => {
      const user = props.users.find((e) => e._id === props.userId);
      return (
        Immutable.List.isList(props.users) &&
        permit(user, { key: 'menuReglementClient' }) &&
        props.selected &&
        props.selected.status !== I_APR
      );
    },
    sub: [
      {
        name: 'Ajouter/Modifier',
        action: (...arg) =>
          middlewareDialogCompta(...arg, M_PAYAN, paiementAnnexes),
        customVisible: (elem, props) => {
          const user = props.users.find((e) => e._id === props.userId);
          return (
            Immutable.List.isList(props.users) &&
            permit(user, { key: 'menuReglementClient' }) &&
            props.selected &&
            props.selected.status !== I_APR
          );
        },
      },
      {
        name: 'Commentaire',
        action: (...arg) => comptaComments(...arg, M_PAYAN),
        customVisible: (elem, props) =>
          props.selected && props.selected.status !== I_APR,
      },
    ],
  },
  {
    name: 'Récap. Artisan',
    icon: Person,
    visible: 'artisan',
    customVisible: (elem, props) =>
      props.selected &&
      props.selected.artisan &&
      permit(
        props.users.find((u) => u._id === props.userId),
        {
          key: 'externSAV',
        }
      ),
    link: ({ artisan }) => {
      window.open(`/artisan/${artisan}/recap`);
    },
    action: (props, elem) => {
      props.history.push(`/artisan/${elem.artisan}/recap`);
    },
  },
  {
    name: 'Demande de mise à jour (artisan)',
    icon: AssignmentInd,
    customVisible: (elem, props) =>
      props.selected &&
      [I_AVR, I_ENC].includes(props.selected.status) &&
      !props.selected.proposition &&
      permit(
        props.users.find((u) => u._id === props.userId),
        {
          key: 'displayInterActions',
        }
      ),
    action: sendUploadSms,
  },
  {
    name: 'Je prends!',
    icon: Schedule,
    customVisible: (elem, props) =>
      Immutable.List.isList(props.users) &&
      props.selected &&
      props.selected.status === I_APR &&
      props.users.find((e) => e._id === props.userId).service === S_PART &&
      props.selected.aDemarcher === D_MAR,
    action: (props, elem) => schedule(elem),
  },
  {
    name: 'Envoyer dans market',
    icon: Send,
    customVisible: (elem, props) =>
      Immutable.List.isList(props.users) &&
      props.selected &&
      props.selected.status === I_APR &&
      props.users.find((e) => e._id === props.userId).service === S_PART &&
      props.selected.aDemarcher === D_PRE,
    action: sendToMarket,
  },
  {
    name: 'Envoyer',
    icon: MailOutline,
    customVisible: (elem, props) =>
      props.selected &&
      props.selected.artisan &&
      [I_APR, I_ENC, I_AVR, I_DVG].includes(props.selected.status) &&
      permit(
        props.users.find((u) => u._id === props.userId),
        {
          key: 'displayInterActions',
        }
      ),
    action: (props, inter) =>
      openAttachmentsDialog(props.setConnectedDialog).then((attachments) =>
        send({ ...props, attachments }, inter)
      ),
  },
  {
    name: 'Mettre en relation',
    icon: RingVolume,
    visible: 'artisan',
    customVisible: (elem, props) => {
      const user = props.users.find((u) => u._id === props.userId);
      return (
        props.selected &&
        props.selected.artisan &&
        [I_APR, I_ENC, I_AVR, I_DVG].includes(props.selected.status) &&
        permit(user, {
          key: 'displayInterActions',
        }) &&
        permit(user, { key: 'abplusCall' })
      );
    },
    action: (props) => abPlusCb(props, props.selected, 0),
  },
  {
    name: 'Signalement',
    icon: ReportProblem,
    customVisible: (elem, props) =>
      Immutable.List.isList(props.users) && props.selected,
    action: signalement,
  },
  {
    name: 'Envoyer une demande SAV',
    icon: Send,
    customVisible: (elem, props) =>
      Immutable.List.isList(props.users) &&
      props.selected &&
      props.selected.statusSAV &&
      props.selected.statusSAV.includes(SAV_DSAV),
    action: (...arg) => demandeSAV(...arg, false),
  },
  {
    name: 'Impossibilité de contact',
    icon: SpeakerNotesOff,
    customVisible: (inter, props) =>
      Immutable.List.isList(props.users) &&
      [S_COMPTA, S_SATCL].includes(
        props.users.find((e) => e._id === props.userId).service
      ) &&
      props.selected &&
      props.selected.statusSAV &&
      props.selected.statusSAV.includes(SAV_DSAV),
    action: noContactSAV,
  },
  {
    name: 'Supprimer le dossier',
    icon: Delete,
    customVisible: (inter, props) =>
      Immutable.List.isList(props.users) &&
      [S_COMPTA, S_SATCL].includes(
        (props.users.find((e) => e._id === props.userId) || {}).service
      ) &&
      props.selected &&
      props.selected.statusSAV &&
      props.selected.statusSAV
        .some(e => [SAV_APP, SAV_DF, SAV_DSAV, SAV_DISAV, SAV_DFC].includes(e)),
    action: DeleteSAV,
  },
  {
    name: 'Demande de SAV à refuser',
    icon: DeleteForever,
    customVisible: (inter, props) =>
      Immutable.List.isList(props.users) &&
      [S_COMPTA, S_SATCL].includes(
        (props.users.find((e) => e._id === props.userId) || {}).service
      ) &&
      props.selected &&
      props.selected.statusSAV &&
      props.selected.statusSAV.includes(SAV_DSAV),
    action: (...arg) => updateStatusSAV(...arg, SAV_DAREF, SAV_DSAV),
  },
  {
    name: 'Ajouter en SAV',
    icon: AddCircle,
    customVisible: (inter, props) =>
      Immutable.List.isList(props.users) &&
      [S_COMPTA, S_SATCL].includes(
        props.users.find((e) => e._id === props.userId).service
      ) &&
      props.selected &&
      props.selected.statusSAV &&
      props.selected.statusSAV.includes(SAV_DSAV),
    action: (...arg) => demandeSAV(...arg, true),
  },
  {
    name: 'Attestation envoyée',
    icon: Send,
    customVisible: (inter, props) =>
      Immutable.List.isList(props.users) &&
      [S_COMPTA, S_SATCL, S_JUR, S_DEVIS].includes(
        props.users.find((e) => e._id === props.userId).service
      ) &&
      props.selected &&
      props.selected.statusSAV &&
      props.selected.statusSAV.includes(SAV_APP),
    action: (...arg) => updateStatusSAV(...arg, SAV_DAE, SAV_APP),
  },
  {
    name: 'Demande d\'information envoyée',
    icon: Send,
    customVisible: (inter, props) =>
      Immutable.List.isList(props.users) &&
      [S_COMPTA, S_SATCL].includes(
        (props.users.find((e) => e._id === props.userId) || {}).service
      ) &&
      props.selected &&
      props.selected.statusSAV &&
      props.selected.statusSAV.includes(SAV_DISAV),
    action: (...arg) => updateStatusSAV(...arg, SAV_DISAVE, SAV_DISAV),
  },
  {
    name: 'Refuser',
    icon: CancelPresentation,
    customVisible: (elem, props) => {
      const user = props.users.find((e) => e._id === props.userId);
      return (
        Immutable.List.isList(props.users) &&
        props.selected &&
        props.selected.status === I_APR &&
        props.selected.status !== I_REF &&
        user.service === S_PART &&
        props.selected.aDemarcher > D_NOT
      );
    },
    action: refuser,
  },
  {
    name: 'Réactiver',
    icon: CheckCircle,
    customVisible: (elem, props) => {
      const user = props.users.find((e) => e._id === props.userId);
      return (
        Immutable.List.isList(props.users) &&
        props.selected &&
        ((props.selected.status === I_REF && user.service === S_PART) ||
          (props.selected.status === I_ANN && permit(user, { auth: ADMIN })))
      );
    },
    action: reactivated,
  },
  {
    name: 'Vérifier',
    icon: Check,
    customVisible: (elem, props) =>
      props.selected &&
      props.selected.artisan &&
      (props.selected.onSitePayment || props.selected.date.envoiFacture) &&
      [I_AVR, I_ENC].includes(props.selected.status) &&
      displayVerifButtonOnMAJ(props) &&
      permit(
        props.users.find((u) => u._id === props.userId),
        {
          key: 'displayInterActions',
        }
      ) &&
      (!props.selected.proposition || (
        !!(props.selected.proposition || {}).isValid &&
        props.selected.proposition.isValid !== P_NONE)
      ),
    action: (...args) => store.dispatch(openVerifDialog(...args)),
  },
  {
    name: 'Vérif + envoi facture en attente',
    icon: Email,
    customVisible: (elem, props) =>
      props.selected &&
      props.selected.artisan &&
      !props.selected.onSitePayment &&
      [I_AVR, I_ENC].includes(props.selected.status) &&
      permit(
        props.users.find((u) => u._id === props.userId),
        {
          key: 'displayInterActions',
        }
      ) &&
      (!props.selected.proposition || (
        !!(props.selected.proposition || {}).isValid &&
        props.selected.proposition.isValid !== P_NONE)
      ),
    action: (props, inter) =>
      store.dispatch(
        openVerifDialog(
          props,
          inter,
          (elem, verifFunc) =>
            checkAwaitingBillRequirements(inter, elem.finalPrice) &&
            store.dispatch(openAwaitingBillDialog(inter, verifFunc))
        )
      ),
  },
  {
    name: 'Relance',
    icon: Alarm,
    customVisible: (elem, props) =>
      props.selected &&
      props.selected.artisan &&
      permit(
        props.users.find((u) => u._id === props.userId),
        {
          key: 'displayInterActions',
        }
      ),
    sub: [
      {
        name: 'Ajouter/Programmer',
        action: (...arg) => callReminderDialog(...arg),
      },
      {
        name: 'Historique',
        action: (...arg) => callReminderHistoryDialog(...arg),
      },
    ],
  },
  {
    name: 'Annuler',
    customVisible: (elem, props) =>
      props.idMenu !== 'addInter' &&
      props.selected &&
      props.selected.status !== I_ANN &&
      permit(
        props.users.find((u) => u._id === props.userId),
        {
          key: 'displayInterActions',
        }
      ) &&
      (!props.selected.proposition || (
        !!(props.selected.proposition || {}).isValid &&
        props.selected.proposition.isValid !== P_NONE)
      ) &&
      (permit(props.users.find((u) => u._id === props.userId)) ||
        !props.selected.noPaymentReceived),
    icon: Close,
    action: cancel,
  },
  {
    name: 'Attribuer l\'intervention',
    customVisible: (elem, props) => permit(
      props.users.find((u) => u._id === props.userId)
    ),
    icon: HowToReg,
    action: (props, inter) =>
      openAssignInterDialog(props, inter, 'interventions')
  }
];
