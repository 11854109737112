import React, { Component } from 'react';
import Autosuggest from 'react-autosuggest';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';

import promisify from '@bit/dev-lba.lib.local-globals/promisify';

import CustomTextField from '../CustomInputs/CustomTextField';

const styles = () => ({
  container: {
    position: 'relative',
  },
  suggestion: {
    display: 'block',
  },
  textField: {
    width: '100%',
    paddingTop: 16,
  },
});

const defaultRenderInput = ({ ref, onChange, ...props }) => (
  <CustomTextField
    inputRef={ref}
    {...props}
    setData={(p, value) => onChange({ target: { value } })}
  />
);

const defaultRenderSuggestion = s => (
  <MenuItem button component="div" children={s} />
);

const defaultRenderSuggestionsContainer = ({ containerProps, children }) => (
  <Paper {...containerProps} square children={children} />
);

class AutoSuggestInput extends Component {
  state = {
    value: this.props.value || '',
    prevValue: this.props.value,
    prevPropsValue: this.props.value,
    suggestions: [],
  };

  static getDerivedStateFromProps(props, state) {
    const update = {};

    if (props.value !== state.prevPropsValue) {
      update.prevPropsValue = props.value;
      if (props.value !== state.prevValue) {
        update.value = props.value;
        update.prevValue = props.value;
      }
      return update;
    }
    return null;
  }

  handleSuggestionsClear = () => this.setState({ suggestions: [] });

  handleSuggestionsFetch = ({ value }) =>
    promisify(this.props.getSuggestions(value)).then(suggestions => {
      if (suggestions && Array.isArray(suggestions)) {
        this.setState({ suggestions });
      }
    });

  queueUpdate = () => {
    const { value } = this.state;
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(() => this.props.onChange(value), 200);
  };

  onChange = (e, { newValue }) =>
    newValue !== undefined &&
    this.setState({ value: newValue }, this.queueUpdate);

  render() {
    const {
      getSuggestionValue = s => s,
      onContextMenu = f => f,
      renderInputComponent = defaultRenderInput,
      renderSuggestion = defaultRenderSuggestion,
      renderSuggestionsContainer = defaultRenderSuggestionsContainer,
      label,
      placeholder,
      type = 'search',
      classes,
    } = this.props;
    const { suggestions, value } = this.state;
    return (
      <Autosuggest
        inputProps={{
          placeholder,
          type,
          onChange: this.onChange,
          value,
          label,
          onContextMenu,
        }}
        theme={classes}
        renderInputComponent={renderInputComponent}
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.handleSuggestionsFetch}
        onSuggestionsClearRequested={this.handleSuggestionsClear}
        renderSuggestionsContainer={renderSuggestionsContainer}
        renderSuggestion={renderSuggestion}
        getSuggestionValue={getSuggestionValue}
        focusInputOnSuggestionClick={false}
      />
    );
  }
}

AutoSuggestInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  getSuggestions: PropTypes.func.isRequired,
  getSuggestionValue: PropTypes.func,
  renderInput: PropTypes.func,
  renderSuggestion: PropTypes.func,
  renderSuggestionsContainer: PropTypes.func,
};

export default withStyles(styles)(AutoSuggestInput);
