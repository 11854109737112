import { moment } from '@bit/dev-lba.lib.local-globals/moment';

export const getPieOptions =
function({ data, title, colors, subtext, isDate }) {

  if (!data) {
    return [];
  }

  const option = {
    color: colors ? colors : undefined,
    title: {
      text: title,
      subtext,
      x: 'center'
    },
    legend: {
      orient: 'vertical',
      right: '0%',
      top: '0%',
      data: Object.keys(data),
      formatter: isDate ?
        (name) => +data[name] ?
          `${name} - ${moment.duration(+data[name], 'ms').humanize()}`
          : `${name} - `
        : (name) => `${name} - ${data[name]}`,
    },
    tooltip: {
      trigger: 'item',
      formatter: ({ percent }) => `${percent}%`,
    },
    series: [{
      name: '',
      type: 'pie',
      radius: ['20%', '70%'],
      center: ['50%', '58%'],
      data: Object.keys(data).map(e => ({ name: e, value: data[e] })),
      label: {
        normal: {
          formatter: '{d}%',
        },
      },
      itemStyle: {
        formatter: '{a} <br/>{b} : {c} ({d%})',
        normal: {
          position: 'inner'
        },
        emphasis: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)'
        }
      }
    }]
  };

  return option;
};
