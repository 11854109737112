import React from 'react';
import MinCustomDialog from '../../../Dialogs/MinCustomDialog';
import {
  Checkbox,
  FormControlLabel,
  DialogContent,
  DialogContentText,
  Button
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  linkA: {
    color: 'dodgerblue',
    textDecoration: 'underline',
    cursor: 'pointer',
    display: 'inline'
  },
};

const url = 'https://www.dropbox.com/s/h48cabtcb6e50co/M%C3%A9mo%20cong%C3%A9s.pdf?dl=0';

const TEXT = (({ classes }) =>
  [
    'Aucun congé ne sera accordé sans accord verbal ' +
      'préalable de votre manager direct ; ',
    {
      Component: () => [
        <DialogContentText key={1} variant="subtitle1">
          {`• Seuls les jours de congé acquis peuvent être
          pris durant la période en cours, pour savoir plus cliquez `}
          <p
            key={2}
            onClick={() => window.open(url)}
            className={classes.linkA}
          >
            ici
          </p>
        </DialogContentText>,
      ],
    },
    'La Direction se réserve le droit de refuser une demande de' +
      ' congé pour des raisons d’organisation de l’activité ;',
    'La Direction se réserve le droit de refuser toute' +
      ' demande envoyée dans un délai inférieur à deux semaines ' +
      'précédant le premier jour de congé.',
  ].map((e, i) =>
    e.Component ? (
      <e.Component key={i} />
    ) : (
      <DialogContentText key={i} variant="subtitle1" children={`• ${e}`} />
    )
  ));
class PreventionPopup extends React.Component {

  state = { checked: false };


  handleChange = () => this.setState({ checked: !this.state.checked })

  render() {
    const { open, closePopup, validate, classes } = this.props;
    const { checked } = this.state;
    return (
      <MinCustomDialog
        open={open}
        maxWidth={'md'}
        title={'⚠️ Attention ⚠️'}
        actions={[
          <Button key={0} color="secondary" onClick={closePopup}>
            Annuler
          </Button>,
          <Button
            key={1}
            disabled={!checked}
            color="primary"
            onClick={validate}
          >
            Accepter
          </Button>,
        ]}
      >
        <DialogContent>
          <TEXT classes={classes} />
          <FormControlLabel
            className={classes.textForm}
            control={
              <Checkbox checked={checked} onChange={this.handleChange} />
            }
            label={
              'Je confirme avoir pris connaissance et/ou ' +
              'respecté ces règles de fonctionnement en validant' +
              ' ma demande de prise de congés.'
            }
          />
        </DialogContent>
      </MinCustomDialog>
    );
  }
}
export default withStyles(styles)(PreventionPopup);

