import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';

import { Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import { KeyboardBackspace } from '@material-ui/icons';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

const styles = theme => ({
  content: {
    display: 'flex',
    justifyContent: 'center'
  },
  button: {
    margin: theme.spacing(1),
  },
  dialogtitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
});

const MinCustomDialog = ({
  title,
  children,
  actions,
  middle,
  middleAll,
  classes,
  dialogClasses,
  returnButton,
  width,
  fullScreenOn,
  customHeader,
  dividers = false,
  ...props
}) => (
  <Dialog
    fullScreen={!!fullScreenOn && isWidthDown(fullScreenOn, width)}
    {...props}
    classes={dialogClasses}
  >
    {title ? (
      <DialogTitle
        className={classes.dialogtitle}
        style={middleAll ? { justifyContent: 'space-around' } : null}
        disableTypography={!!customHeader}
      >
        <div>
          {returnButton && returnButton.visibility ? (
            <IconButton className={classes.button}>
              <KeyboardBackspace onClick={() => returnButton.func()} />
            </IconButton>
          ) : (
            ''
          )}
          <Typography variant="h6" align="center">
            {title}
          </Typography>
        </div>
        {customHeader ? customHeader : ''}
      </DialogTitle>
    ) : (
      ''
    )}
    {Array.isArray(children) ? (
      children.map((e, i) => (
        <DialogContent
          key={i}
          className={middleAll || middle ? classes.content : null}
          children={e}
          dividers={dividers}
        />
      ))
    ) : (
      <DialogContent
        className={middleAll || middle ? classes.content : null}
        children={children}
        dividers={dividers}
      />
    )}
    <DialogActions children={actions} />
  </Dialog>
);

MinCustomDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  children: PropTypes.element,
  actions: PropTypes.array.isRequired,
  middle: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  fullScreenOn: PropTypes.string
};

MinCustomDialog.defaultProps = {
  open: false,
  maxWidth: 'sm',
  fullScreenOn: 'sm',
  title: '',
  children: <div>Pas de contenu</div>,
  actions: [],
  middle: false,
  fullWidth: true,
  onClose: f => f
};

export default compose(
  withStyles(styles),
  withWidth()
)(MinCustomDialog);
