import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import UIList from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Input from '@material-ui/core/Input';
import IconButton from '@material-ui/core/IconButton';
import { Clear } from '@material-ui/icons';
import { permit } from '@bit/dev-lba.lib.local-globals/restrictions';
import { getService } from '@bit/dev-lba.lib.local-globals/services';
import { getLogin } from '../../utils/function';
import { Tooltip } from '@material-ui/core';


const styles = {
  UIList: {
    minHeight: 35,
    maxHeight: 321,
    overflow: 'auto'
  },
  ListItem: {
    minHeight: 15,
    padding: '5px 20px'
  },
  ListItemInfo: {
    color: 'grey',
    fontSize: 14,
    whiteSpace: 'nowrap'
  },
  ListItemCom: {
    color: 'black',
    fontSize: 14,
    paddingLeft: 5
  },
  icon: {
    width: 20,
    height: 20,
    padding: 0
  },
  input: {
    padding: 10
  }
};

const CommentsList = ({
  classes,
  comments,
  userId,
  users = [],
  setComment = e => e,
  setOnChange = e => e,
  confirmBox,
  hideInput,
  customPadding = '5px 20px'
}) => {
  const user = users.find(e => e._id === userId);
  return <>
    <UIList className={classes.UIList}>
      {comments && comments.map((e, id) => {
        const service = getService(users, e.get('userId'));
        return <ListItem key={id}
          className={classes.ListItem}
          style={{ padding: customPadding }}
          divider>
          {confirmBox ? <Grid>
            <IconButton
              disabled={!confirmBox || (user &&
                (user._id !== e.get('userId') &&
                !permit(user, { key: 'deleteComments' })))}
              style={{ color: user && (user._id !== e.get('userId') &&
                !permit(user, { key: 'deleteComments' }))
                ? 'gray' : 'red' }}
              className={classes.icon}
              aria-label='Clear'
              onClick={() => confirmBox(e.get('userId'),
                e.get('date'))}
            >
              <Clear className={classes.icon}/>
            </IconButton>
          </Grid> : null}
          <Grid>
            <Tooltip title={service.name || ''}>
              <ListItemText
                className={classes.ListItemInfo}
                style={{
                  color: service.color[500]
                }}
                disableTypography={true}
                primary={`${new Date(e.get('date')).toLocaleString('fr-FR')
                } - ${getLogin(e.get('userId'))} `}
              />
            </Tooltip>
          </Grid>
          <Grid>
            <ListItemText
              className={classes.ListItemCom}
              disableTypography={true}
              primary={ e.get('commentValue') }
            />
          </Grid>
        </ListItem>;
      })
      }
    </UIList>
  {!hideInput ? <Grid>
    <Input
      disabled={typeof setComment !== 'function'}
      className={classes.input}
      disableUnderline
      multiline
      placeholder="Commentaire..."
      fullWidth
      onKeyPress={e => setComment(e)}
      onChange={e => setOnChange(e)}
      onContextMenu={e => e.stopPropagation()}
    />
  </Grid> : null}
  </>;
};

export default withStyles(styles)(CommentsList);
