import React from 'react';
import {
  Grid,
  Paper,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import ReactEcharts from 'echarts-for-react';

const GenericStats = ({
  show,
  option,
  classes,
  size,
  onStatusChange,
  status,
}) => (
  <React.Fragment>
    <Grid item xs={12} sm={6} md={size ? size : 6}>
      <Paper className={classes.card}>
        {show && (
          <ReactEcharts notMerge={true} lazyUpdate={true} option={option} />
        )}
        {onStatusChange && (
          <RadioGroup
            row
            value={status || '-1'}
            onChange={e => onStatusChange(e.currentTarget.value)}
          >
            <FormControlLabel
              value={'SAV'}
              control={<Radio />}
              label="SAV"
            />
            <FormControlLabel
              value={'FACTURE'}
              control={<Radio />}
              label="FACTURE"
            />
            <FormControlLabel
              value={'ATTESTATION'}
              control={<Radio />}
              label="ATTESTATION"
            />
            <FormControlLabel
              value={'-1'}
              control={<Radio />}
              label="TOTAL"
            />
          </RadioGroup>
        )}
      </Paper>
    </Grid>
  </React.Fragment>
);

export default GenericStats;
