import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const styles = theme => ({
  IconTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#FAFAFA'
  },
  iconCard: {
    padding: theme.spacing(1),
    textAlign: 'center'
  }
});

function getSorting(orderBy) {
  return (a, b) => (b[orderBy] < a[orderBy] ? -1 : 1);
}

function pushArray(array) {
  for (let i = 0; array.length < 4; i++) {
    array.push({});
  }
  return array.reverse();
}


class DisplayCardTable extends PureComponent {

  render() {
    const { headerData, bodyData, name, orderBy,
      classes, ToFitch, color } = this.props;
    let array = bodyData.length < 4 ? pushArray(bodyData) : bodyData;
    return (
      <Grid item xs>
        <Paper elevation={8} style={{ backgroundColor: color }}
          className={classes.iconCard}>
          <Typography className={classes.IconTitle} color="textSecondary">
            {name}
          </Typography>
        </Paper>
        <Paper elevation={4} className={classes.iconCard}>
          <Table>
            <TableHead>
              <TableRow>
                {headerData.map((e, i) =>
                  <TableCell key={i} align={i >= 1 ? 'right' : 'inherit'}
                    padding={'none'}>{e}</TableCell>)}
              </TableRow>
            </TableHead>
            <TableBody>
              {array.slice(0, 4).sort(getSorting(orderBy))
                .map((e, i) => <TableRow key={i}>
                  {ToFitch.map((v, n) =>
                    (<TableCell key={n} align={n >= 1 ? 'right' : 'inherit'}
                      padding={'none'}>{e[v]}</TableCell>))}
                </TableRow>
                ) }
            </TableBody>
          </Table>
        </Paper>
      </Grid>
    );
  }
}

DisplayCardTable.propTypes = {
  classes: PropTypes.object.isRequired,
  headerData: PropTypes.array.isRequired,
  bodyData: PropTypes.array.isRequired,
  ToFitch: PropTypes.array.isRequired,
  color: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired
};

export default withStyles(styles)(DisplayCardTable);
