import { grey } from '@material-ui/core/colors';

export default (theme) => ({
  btnFilter: {
    padding: 0,
    position: 'absolute',
    fontSize: '15px',
  },
  arrowDown: {
    bottom: '17px',
  },
  arrowUp: {
    top: '17px',
  },
  masterDiv: {
    position: 'relative',
    padding: '0 12px!important',
    whiteSpace: 'nowrap',
  },
  posBtn: {
    marginLeft: '5px',
  },
  bold: {
    fontWeight: 'bold',
    overflow: 'hidden',
  },
  avatarContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
  },
  icon: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  price: {
    marginTop: '6px',
    display: 'table',
    whiteSpace: 'nowrap',
  },
  extendedIcon: {
    marginRight: 8,
  },
  button: {
    margin: 8,
    height: 30,
  },
  smallCell: {
    width: 60,
    textAlign: 'center',
  },
  maxWidth: {
    width: 100,
  },
  priceDiv: {
    display: 'flex',
    justifyContent: 'space-evenly',
    minWidth: 52,
  },
  centerText: {
    textAlign: 'center',
    fontSize: '1.2rem',
    fontWeight: '500',
  },
  spacer: {
    flex: '1 1 100%',
  },
  title: {
    flex: '0 0 auto',
  },
  table: {
    width: '',
  },
  tr: {
    height: '48px',
    color: 'inherit',
    display: 'table-row',
    outline: 'none',
    verticalAlign: 'middle',
  },
  ArrowHeader: {
    height: '56px',
  },
  th: {
    fontWeight: 'bold',
    minWidth: 20,
  },
  cat: { width: '5%' },
  td: {
    minWidth: 50,
    maxWidth: 90,
  },
  reduce: {
    minWidth: 20,
  },
  load: {
    width: 100,
    height: 100,
    margin: '200px 0 20px',
  },
  align: {
    textAlign: 'center',
  },
  loadTitle: {
    fontSize: 20,
    color: grey[500],
    marginBottom: 250,
  },
  noDataTitle: {
    fontSize: 20,
    color: grey[500],
    margin: '200px 0 250px',
  },
  clientCell: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: 300,
    textOverflow: 'ellipsis',
  },
  input: {
    padding: '10px 12px',
  },
  smallAvatar: {
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    fontSize: 10,
    backgroundColor: grey[900],
  },
  blink: {
    padding: '10px 0 10px 0',
    'border-radius': '4px',
    animation: '$blink 1.5s linear infinite'
  },
  '@keyframes blink': {
    '50%': {
      opacity: 0.4
    }
  },
  grandCompteTooltip: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 13,
    lineHeight: 'normal'
  }
});
