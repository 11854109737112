import React from 'react';
import Grid from '@material-ui/core/Grid';
import CustomTextField from '../../CustomInputs/CustomTextField';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
const MailRecouvrement = ({
  setMail, mail, facture = false
}) => [
  <Grid key={0} item xs={12} style={{ marginTop: 20 }}>
    <Button variant="outlined" color="secondary" fullWidth>
      Mail ({mail.name})
    </Button>
  </Grid>,
  <CustomTextField style={{ marginTop: 20 }}
    grid xs={12} key='Dest'
    texttransform='none'
    rows={1}
    label="Destinataire"
    value={mail.desMail || ''}
    setData={(p, v) => setMail('desMail', v)}
    path={['mail']}
  />,
  <CustomTextField style={{ marginTop: 20 }}
    grid xs={12} key={'title'}
    texttransform='none'
    rows={1}
    label="Objet"
    value={mail.title || ''}
    setData={(p, v) => setMail('title', v)}
    path={['mail']}
  />,
  facture && <Grid key={'Switch'} item xs={6}>
    <FormControlLabel control={
      <Switch
        disabled={mail.facture}
        checked={mail.facturePaid || false}
        onChange={(e) => setMail('facturePaid', e.target.checked)}
        value="facturePaid"
        color="primary"
      />
    }
    label="Facture acquittée en pièce jointe"
    />
  </Grid>,
  facture && <Grid key={'Switch'} item xs={6}>
    <FormControlLabel control={
      <Switch
        disabled={mail.facturePaid}
        checked={mail.facture || false}
        onChange={(e) => setMail('facture', e.target.checked)}
        value="facture"
        color="primary"
      />
    }
    label="Facture en pièce jointe"
    />
  </Grid>,
  <CustomTextField
    grid xs={12}
    texttransform='none'
    rows={15}
    label="Corps du Mail"
    value={mail.text || ''}
    setData={(p, v) => setMail('text', v)}
    path={['mail']}
  />,
];

export default MailRecouvrement;
