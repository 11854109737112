
export const getClassementOptions =
    ({ title = '', labels, data, color, vertical = true }) => {
      const option = {
        title: {
          text: title,
          x: 'center'
        },
        color,
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        [vertical ? 'xAxis' : 'yAxis']: [
          {
            type: 'category',
            data: labels,
            axisTick: {
              alignWithLabel: true
            }
          }
        ],
        [!vertical ? 'xAxis' : 'yAxis']: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            type: 'bar',
            barWidth: '60%',
            data
          }
        ]
      };


      return option;
    };
