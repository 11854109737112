import React from 'react';
import { moment } from '@bit/dev-lba.lib.local-globals/moment';
import {
  getLoginFromPhone,
  getLogin
} from '../../utils/function';
import {
  Grid,
  Icon,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { callTypes } from '@bit/dev-lba.lib.local-globals/callTypes';

const directions = {
  outbound: 'sortant',
  inbound: 'entrant',
  undefined: 'entrant',
};

export const keyUrls = {
  inter: {
    key: 'fileUrl',
    text: (call) =>
      `${moment(call.record.callStart).format('L à HH:mm')} ${
        call.record.duration ? `- ${call.record.duration} sec` : ' '
      } ${
        call.record.callerIdNumber ? ` - ${call.record.callerIdNumber}` : ' '
      } ${call.service} | ${
        !call.record.userId
          ? getLoginFromPhone(call.record.agent.replace(/^0033/, '0'), false)
          : getLogin(call.record.userId)
      } | ${directions[call.record.direction] || ''}`,
  },
  candidat: {
    key: 'fileUrl',
    text: (call) =>
      `${moment(call.record.callStart).format('DD/MM/YY à HH:mm')} ${
        call.duration ? `- ${call.duration} sec` : ' '
      } ${
        call.record.agent
          ? ` - ${getLoginFromPhone(
            call.record.agent.replace(/^0033/, '0'),
            false
          )} a appelé le candidat`
          : ' '
      } `,
  },
  url: {
    key: 'url',
    text: (call) => (
      <Grid container justifyContent="space-between" alignContent="center">
        <Grid item>
          {`${moment(call.date).format('LLL')} : ${
            call.companyName
          } a été contacté par ${getLogin(call.userId)}`}
        </Grid>
        {call.comment ? (
          <Grid item>
            <Typography
              variant="caption"
              color="textSecondary"
              children={`Commentaire: ${call.comment}`}
            />
          </Grid>
        ) : (
          ''
        )}
      </Grid>
    ),
  },
  urlEnregistrement: {
    key: 'urlEnregistrement',
    text: (call) => {
      const type = call.type && callTypes.find(e => e.id === call.type);
      return (
        <Grid container justifyContent="space-between" alignContent="center">
          <Grid item>
            {`${moment(call.date).format('DD/MM/YY à HH:mm')} ${
              call.duration ? `- ${call.duration} sec` : ' '
            } ${call.numeroAppelant ? ` - ${call.numeroAppelant}` : ' '}`}
          </Grid>
          {type && type.icon ? (
            <Tooltip title={type.label}>
              <Icon
                style={{ marginInlineEnd: 'auto', paddingLeft: 10 }}
                fontSize="small"
                children={type.icon}
              />
            </Tooltip>
          ) : (
            ''
          )}
          {call.companyName ? (
            <Grid item>
              <Typography
                variant="caption"
                color="textSecondary"
                children={call.companyName}
              />
            </Grid>
          ) : (
            ''
          )}
          {call.played && call.datePlayed && call.userPlayed ? (
            <Grid item xs={12}>
              <Typography
                variant="caption"
                color="textSecondary"
                children={`écouter par ${getLogin(call.userPlayed)} - ${moment(
                  call.datePlayed
                ).format('DD/MM à HH[h]mm')}`}
              />
            </Grid>
          ) : (
            ''
          )}
        </Grid>
      );
    },
  },
  fileUrl: {
    key: 'fileUrl',
    text: (call) => `${moment(call.callStart).format('DD/MM/YY à HH:mm')} ${
      call.duration ? `- ${call.duration} sec` : ' '
    } ${call.callerIdNumber ? ` - ${call.callerIdNumber}` : ' '}
    ${call.agent ? ` - ${getLogin(call.userId, call.agent)}` : ' '} `,
  },
  ringover: {
    key: 'url',
    text: (call) =>
      `${moment(call.startTime).format('L à HH:mm')} ${
        call.duration ? `- ${call.duration} sec` : ' '
      } ${call.toNumber ? ` - ${call.toNumber}` : ' '} Ringover | ${
        !call.userId
          ? getLoginFromPhone(call.fromNumber.replace(/^0033/, '0'), false)
          : getLogin(call.userId)
      } | ${directions[call.direction] || ''}`,
  },
  lastCalls: {
    key: 'url',
    text: (call) =>
      `${
        call.direction === 'inbound'
          ? 'L\'artisan a contacté'
          : 'L\'artisan a été contacté par'
      } ${getLogin(call.userId)} ${
        call.savId || call.interventionId
          ? `depuis ${call.isSAV ? 'le dossier sav' : 'l\'intervention'} n°${
            call.savId || call.interventionId
          }`
          : ''
      } le ${moment(call.date).format('L à HH:mm')}`,
  },
};
