import api from '../api';
import notifSystem from '../notifSystem';
import { E_TRA } from '@bit/dev-lba.lib.local-globals/encaissementMode';

export const deleteCaissement = async (line) => {
  try {
    if (line._id) {
      await api.encaissements.delete(line._id, {
        multis: line.multis,
        id: line.id,
      });
    }
    return true;
  } catch (error) {
    notifSystem.error('Erreur', 'L\'encaissement n\'a pas été supprimé');
    return false;
  }
};

export const getDataWithoutOS = () =>
  api.encaissements
    .getAll({
      query: JSON.stringify({
        id: 0, mode: { $ne: E_TRA }, isTransfered: { $ne: true }
      }),
      field: JSON.stringify({
        id: 1,
        montant: 1,
        libelle: 1,
        userId: 1,
        multis: 1,
        numChq: 1,
        encaissement: 1,
        mode: 1,
      }),
    })
    .then((data) => data.body().map((e) => e.data()))
    .catch((e) => notifSystem.error('Erreur', e.message));

export const unFlushRemiseByDate = (flushDate) =>
  api.encaissements.custom('unFlushRemise')
    .post({ flushDate })
    .catch(() => {
      notifSystem.error('Erreur', 'Le flush n\'a pas pu être effectué');
    });
