const getDataById =
  require('@bit/dev-lba.lib.local-globals/getDataById');
const {
  I_AVR, I_ANN, I_VRF
} = require('@bit/dev-lba.lib.local-globals/iStatus');
const { C_VOL } =
  require('@bit/dev-lba.lib.local-globals/typesCancels');

const { amber, deepPurple, grey } = require('@material-ui/core/colors');
const { default: collections } = require('../../constants/collections');

export const changeStatusColor = (data, type) =>
  ({
    [collections.interventions]: {
      ...getDataById.intervention,
      [I_AVR]: {
        ...getDataById.intervention[I_AVR],
        color:
          (data.noPaymentReceived && amber) ||
          getDataById.intervention[I_AVR].color,
      },
      [I_VRF]: {
        ...getDataById.intervention[I_VRF],
        color:
          (data.isProforma && grey) || getDataById.intervention[I_VRF].color,
        name:
          (data.isProforma && 'A Vérifier') ||
          getDataById.intervention[I_VRF].name,
      },
      [I_ANN]: {
        ...getDataById.intervention[I_ANN],
        color:
          data.typeCancel === C_VOL
            ? deepPurple
            : getDataById.intervention[I_ANN].color,
      },
    },
    [collections.recouvrements]: {
      ...getDataById.intervention,
      [I_AVR]: {
        ...getDataById.intervention[I_AVR],
        color:
          (data.noPaymentReceived && amber) ||
          getDataById.intervention[I_AVR].color,
      },
      [I_VRF]: {
        ...getDataById.intervention[I_VRF],
        color:
          (data.isProforma && grey) || getDataById.intervention[I_VRF].color,
        name:
          (data.isProforma && 'A Vérifier') ||
          getDataById.intervention[I_VRF].name,
      },
      [I_ANN]: {
        ...getDataById.intervention[I_ANN],
        color:
          data.typeCancel === C_VOL
            ? deepPurple
            : getDataById.intervention[I_ANN].color,
      },
    },
  }[type] || getDataById[type]);
