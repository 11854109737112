import { moment } from '@bit/dev-lba.lib.local-globals/moment';
import {
  getOption as getOptionGlobal
} from '../../../../Stats/common/graph/options/globalOptions';

export const getOption = ({ data, title, labels, colors }) => {
  const keys = moment.monthsShort();
  let otherCustomData = [],
    decoteData = [];

  Object.keys(data).forEach((v) => {
    if (v === 'decote') {
      decoteData = data[v];
    } else {
      const element = {
        type: 'bar',
        animation: false,
        name: v,
        itemStyle: { opacity: 0.5 },
        data: data[v],
      };
      otherCustomData.push(element);
    }
  });
  return {
    ...(Object.keys(data).length
      ? getOptionGlobal({ data, title, labels, colors, mode: 'year' })
      : {}),
    title: { text: title, left: 'center' },
    legend: { show: true, data: labels, top: '10%' },
    tooltip: { trigger: 'axis' },
    xAxis: { data: keys },
    yAxis: [
      { type: 'value', scale: true, name: '(€)' },
      { type: 'value', scale: true, name: '(%)' },
    ],
    color: colors,
    series: otherCustomData
      .map((e) => e)
      .concat({
        name: 'Decote',
        type: 'line',
        yAxisIndex: 1,
        data: decoteData,
      }),
  };
};
