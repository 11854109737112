import React from 'react';
import { Paper, Tab, Tabs } from '@material-ui/core';
import DefaultPage from './default';
import AdminPage from './admin';
import { permit } from '@bit/dev-lba.lib.local-globals/restrictions';
import { withDisplaying } from '../../../hoc';
import RecouvStats from '../Recouvrement/RecouvStats';
import GlobalStats from './GlobalStats';

class StatsComptabilite extends React.Component {
  state = {
    tab: 0
  }

  render() {
    const { tab } = this.state;
    const { user } = this.props;
    const Display = [DefaultPage, AdminPage, RecouvStats, GlobalStats][tab];
    return (
      <Paper>
        {permit(user, { key: 'statsDashboardCompta' })
          && <Tabs value={tab} centered onChange={(e, tab) =>
            this.setState({ tab })}>
            <Tab key={'1'} value={0} label={'General'}/>
            {permit(user) && <Tab key={'2'} value={1} label={'Admin'}/> }
            <Tab key={'3'} value={2} label={'Recouvrement'}/>
            {permit(user, { key: 'updateValidationBq' }) && (
              <Tab key={'4'} value={3} label={'Comptabilité GLOBAL'} />) }
          </Tabs>}
        {Display && <Display user={user} {...this.props}/>}
      </Paper>
    );
  }
}

StatsComptabilite.displayName = 'StatsComptabilite';
export default withDisplaying()(StatsComptabilite);
