import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import ReactEcharts from 'echarts-for-react';
import { getOption } from './getOptions';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  paper: {
    padding: theme.spacing(3),
  },
});

class PieDoughnutStats extends PureComponent {

  render() {
    const { PieDoughnutData, classes, mode } = this.props;
    let modes = { day: 'jour', week: 'semaine', month: 'mois', year: 'année' };
    return (
      <Paper className={classes.paper}>
        <Typography variant="h6" gutterBottom align="center">
          {'% Annulation par'} { modes[mode]}
        </Typography>
        <ReactEcharts
          style={{ height: 100, width: '100%' }}
          option={getOption(PieDoughnutData)} />
      </Paper>
    );
  }
}

PieDoughnutStats.propTypes = {
  classes: PropTypes.object.isRequired,
  mode: PropTypes.string.isRequired
};

export default withStyles(styles)(PieDoughnutStats);
