/* eslint-disable max-len */
import React from 'react';

const AirIcon = ({ w = '80', h = '70', color = '#000', moreStyle = null }) => (
  <svg
    height={h}
    width={w}
    fill={color}
    viewBox="0 0 84 84"
    style={{ ...moreStyle }}
  >
    <g>
      <path d='M63,18.1c-2-5.6-8.4-9.9-13.6-9.3C46.9,9,45.1,9.9,44,11.4c-1.6,2.2-1,4.9-0.8,5.9l0.1,0.3
        c0.4,2,0.9,4.3-1.3,7.9c-0.2,0-0.4,0-0.6,0c-0.5-1.8-1.3-3.6-2.4-5.4c-3.7-5.7-8.8-7.9-14.1-6c-5.6,2-9.9,8.4-9.3,13.6
        c0.3,2.5,1.2,4.3,2.6,5.3c2.2,1.6,4.9,1,5.9,0.8l0.3-0.1c2.1-0.4,4.5-0.9,8.3,1.6c0,0.1,0,0.2,0,0.2c-1.9,0.5-3.9,1.3-5.8,2.5
        c-5.7,3.7-7.9,8.8-6,14.1c1.9,5.2,7.6,9.4,12.6,9.4c0.3,0,0.7,0,1-0.1c2.5-0.3,4.3-1.2,5.3-2.6c1.6-2.2,1-4.9,0.8-5.9l-0.1-0.3
        c-0.4-2.1-0.9-4.4,1.4-8.1c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.3,0c0.5,1.9,1.3,3.7,2.5,5.5c2.8,4.4,6.5,6.7,10.4,6.7
        c1.2,0,2.4-0.2,3.7-0.7c5.6-2,9.9-8.4,9.3-13.6c-0.3-2.5-1.2-4.3-2.6-5.3c-2.2-1.6-4.9-1-5.9-0.8l-0.3,0.1c-2,0.4-4.2,0.8-7.7-1.2
        c0-0.3,0-0.5,0-0.8c1.7-0.5,3.5-1.3,5.1-2.4C62.7,28.4,64.9,23.3,63,18.1z M24.1,32.1l-0.3,0.1c-0.9,0.2-3,0.6-4.5-0.5
        c-1-0.8-1.7-2.1-1.9-4.1c-0.5-4.5,3.3-10,8.2-11.7c4.5-1.6,8.7,0.3,11.9,5.3c1,1.5,1.7,3.1,2.2,4.7c-2.2,0.7-4.1,2.1-5.4,4.1
        c-0.7,1.1-1.1,2.2-1.3,3.4C28.9,31.2,26.2,31.7,24.1,32.1z M39,53.1l0.1,0.3c0.2,0.9,0.6,3-0.5,4.5c-0.8,1-2.1,1.7-4.1,1.9
        c-4.5,0.5-10-3.3-11.7-8.2C21.1,47.2,23,43,28,39.7c1.6-1.1,3.4-1.8,5.1-2.2c0.6,2.4,2.1,4.4,4.2,5.7c0.9,0.6,1.9,1,2.9,1.2
        C38,48.3,38.5,51,39,53.1z M42.3,42.8c-1.4,0-2.9-0.4-4.1-1.2c-1.8-1.1-3-2.8-3.4-4.8c-0.5-2-0.1-4.1,1-5.8
        c1.4-2.3,3.9-3.6,6.6-3.6c1.4,0,2.9,0.4,4.1,1.2c3.6,2.3,4.7,7,2.5,10.7C47.5,41.5,45,42.8,42.3,42.8z M59.9,38.2l0.3-0.1
        c0.9-0.2,3-0.6,4.5,0.5c1,0.8,1.7,2.1,1.9,4.1c0.5,4.5-3.3,10-8.1,11.7c-4.5,1.6-8.7-0.3-11.9-5.3c-1-1.5-1.7-3.2-2.2-4.8
        c2.5-0.5,4.6-2.1,6-4.3c0.6-0.9,1-1.9,1.2-3C55.3,39.1,57.9,38.6,59.9,38.2z M51.5,32.7c-0.6-2.3-2-4.4-4.2-5.7
        c-1.1-0.7-2.2-1.1-3.4-1.3c2-3.8,1.5-6.3,1.1-8.4L45,16.9c-0.2-0.9-0.6-3,0.5-4.5c0.8-1,2.1-1.7,4.1-1.9c4.5-0.5,10,3.3,11.7,8.2
        c1.6,4.5-0.3,8.7-5.3,11.9C54.5,31.5,53,32.2,51.5,32.7z'></path>
      <path d="M41.8,1.8C24,1.8,9.5,16.3,9.5,34.1c0,17.6,14.1,31.9,31.6,32.3v5.5C34.2,72,28,74.5,24.5,78.6
        c-0.7,0.9-0.9,1.8-0.6,2.7c0.4,0.9,1.4,1.4,2.7,1.4h30.5c1.3,0,2.2-0.5,2.7-1.4c0.4-0.8,0.2-1.8-0.6-2.7c-3.5-4-9.6-6.5-16.3-6.7
        v-5.5c17.3-0.5,31.3-14.8,31.3-32.3C74.1,16.3,59.6,1.8,41.8,1.8z M57.8,79.7c0.3,0.3,0.3,1.1-0.7,1.1H26.5c-1,0-1-0.9-0.7-1.1
        c3.3-3.8,9.4-6.1,16-6.1C48.4,73.6,54.5,76,57.8,79.7z M41.8,64.6c-16.8,0-30.5-13.7-30.5-30.5C11.3,17.3,25,3.6,41.8,3.6
        s30.5,13.7,30.5,30.5C72.3,50.9,58.6,64.6,41.8,64.6z"></path>
    </g>
  </svg>
);
export default AirIcon;


