import React from 'react';
import PrintFiles from '../PrintFiles';
import { DINTER } from '@bit/dev-lba.lib.local-globals/docTypes';
import { fromJS } from 'immutable';
import { withAPISubscription } from '../../hoc';

const PaymentAnxMenu = ({ inter }) => (
  <PrintFiles
    type={DINTER}
    path="interventions"
    id={inter.id}
    underscoreId={inter._id}
    dropbox={fromJS(inter.dropbox || [])}
    setData={(p, v) => {
      inter.dropbox = v;
    }}
  />
);

const fetch = (api, { data }) => api.interventions
  .get(data.rowData.id, { query: JSON.stringify({ id: data.rowData.id }) })
  .then(res => res.body().data());

const shouldReload = (props, prevProps) => props.data.id !== prevProps.data.id;

export default withAPISubscription(fetch, null, {
  default: {},
  name: 'inter',
  shouldReload,
})(PaymentAnxMenu);
