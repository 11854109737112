import {
  data as categoriesData
} from '@bit/dev-lba.lib.local-globals/categories';
import { data as paidStatus } from '@bit/dev-lba.lib.local-globals/paidStatus';
import { data as AStatus } from '@bit/dev-lba.lib.local-globals/aStatus';
import {
  data as ASubStatus, AS_QUA
} from '@bit/dev-lba.lib.local-globals/aSubStatus';
import { data as aLevel, NOT_QUA } from '@bit/dev-lba.lib.local-globals/aLevel';
import { data as ADossier } from '@bit/dev-lba.lib.local-globals/dossier';
import {
  data as IStatus, I_AVR, I_VRF, I_ANN
} from '@bit/dev-lba.lib.local-globals/iStatus';
import {
  data as demandeStatus,
  D_REF,
} from '@bit/dev-lba.lib.local-globals/demandeStatus';
import { R_POR, R_FOR } from '@bit/dev-lba.lib.local-globals/remStatus';
import store from '../../store';
import { firstCallFilters } from '../../utils/function';
import { MIN_DURATION } from '@bit/dev-lba.lib.local-globals/minDuration';
import { REPONDEUR } from '@bit/dev-lba.lib.local-globals/callTypes';

export function convertiFilterData(value, data, findField, toReturnField) {
  return data.reduce((v, c) => {
    if (
      findField.some(e => new RegExp(`^${value}`, 'gi').test(c[e]))
    ) {
      v.push(c[toReturnField]);
    }
    return v;
  }, []);
}

export const callBackFunction = {
  categorie: (value) => {
    const data = convertiFilterData(
      value,
      categoriesData,
      ['name', 'shortName'],
      '_id'
    );
    return data.length ? { categorie: { $in: data } } : null;
  },
  categories: (value) => {
    const data = convertiFilterData(
      value,
      categoriesData,
      ['name', 'shortName'],
      '_id'
    );
    return data.length ? { categories: { $in: data } } : null;
  },
  paid: (value) => {
    const data = convertiFilterData(
      value,
      paidStatus,
      ['name', 'shortName'],
      '_id'
    );
    return data.length ? { paid: { $in: data } } : null;
  },
  grandCompte: (value, dataToFind) => {
    const data = convertiFilterData(
      value,
      dataToFind,
      ['companyName', 'name', 'firstname'],
      '_id'
    );
    return data.length ? { 'billing.grandCompte': { $in: data } } : null;
  },
  source: (value) => ({
    source: { $regex: `${value.escapeRegExp()}`, $options: 'i' },
  }),
  companyName: (value) => ({
    companyName: { $regex: `^${value.toUpperCase().escapeRegExp()}` },
  }),
  sStatus: (value) => {
    if (isNaN(value)) {
      return { 'sStatus.path':
      { $regex: `/${value.escapeRegExp()}`, $options: 'i' } };
    }
    const confCand = store.getState().confCand.toJS()
      .find(e => e.id === Number(value) && e.path !== '');
    const path = confCand && confCand.path === '/Refusé' ?
      confCand.name : confCand.path;
    return confCand &&
    { 'sStatus.path': { $regex: `${confCand && path}` } };
  },
  AStatus: (value) => {
    const data = convertiFilterData(
      value,
      AStatus,
      ['name', 'shortName'],
      '_id'
    );
    return data.length
      ? { status: { $in: data } }
      : null;
  },
  ASubStatus: (value) => {
    const data = convertiFilterData(
      value,
      ASubStatus,
      ['name', 'shortName'],
      '_id'
    );
    let query = null;
    if (data.length) {
      query = { subStatus: { $in: data } };
      if (data.includes(AS_QUA)) {
        query = { level: { $ne: NOT_QUA } };
      }
    }
    return query;
  },
  AStatusCandidat: (value) => {
    const data = convertiFilterData(
      value,
      AStatus,
      ['name', 'shortName'],
      '_id'
    );
    return data.length
      ? { 'currentArtisan.status': { $in: data } }
      : null;
  },
  ASubStatusCandidat: (value) => {
    const data = convertiFilterData(
      value,
      ASubStatus,
      ['name', 'shortName'],
      '_id'
    );
    let query = null;
    if (data.length) {
      query = { 'currentArtisan.subStatus': { $in: data } };
    }
    return query;
  },
  ALevel: (value) => {
    const data = convertiFilterData(value, aLevel);
    return data.length ? { level: data } : null;
  },
  dossier: (value) => {
    const data = ADossier.find((d) =>
      d.name.toLowerCase().startsWith(value.toLowerCase())
    );
    return { _dossier: data._id };
  },
  remuneration: (value) => {
    if (isNaN(value)) {
      if (value === 'POR') {
        return { 'remuneration.status': R_POR };
      } else if (value === 'FOR') {
        return { 'remuneration.status': R_FOR };
      }
    }
    return {
      'remuneration.pourcentage.labor': Number(value),
      'remuneration.status': R_POR,
    };
  },
  iStatus: (value) => {
    IStatus.push({
      _id: IStatus.length + 1,
      value: { isProforma: true, status: I_VRF }
    });
    IStatus.push({
      _id: IStatus.length + 1,
      value: { noPaymentReceived: true, status: I_AVR },
    });
    IStatus.push({
      _id: IStatus.length + 1,
      value: { emptyCancel: true, status: I_ANN },
    });
    const data = IStatus.find((d) =>
      d._id === +value
    );
    if (data && typeof data.value === 'object') {
      return data.value;
    }
    return value ? { status: +value } : null;
  },
  debriefUser: (value) => {
    const users = store
      .getState()
      .users
      .filter(e =>
        e.login
          .toUpperCase()
          .match(`^${value.toUpperCase().escapeRegExp()}`)
      )
      .map(e => e._id);
    return {
      'login.debrief': { $in: users.toArray() }
    };
  },
  equipeAreasArtisan: (value) => {
    if ((/candidat/i).test(value)) {
      return { interPayer: { $lte: 9 }, candidatId: { $exists: true } };
    }
    const user = store
      .getState()
      .users.find((e) =>
        e.act &&
        e.login.toUpperCase().match(`^${value.toUpperCase()}`)
      );
    return {
      'billingAddress.zipcode': {
        $in:
          user ? user.areas?.map((e) => `/^${e}/`) : []
      },
    };
  },
  equipeAreasClient: (value) => {
    const user = store
      .getState()
      .users.find((e) =>
        e.act &&
        e.login.toUpperCase().match(`^${value.toUpperCase()}`)
      );
    return {
       'client.address.zipcode': { $in: user ? user.areas?.map((e) => `/^${e}/`) : [] },
    };
  },
  dStatus: (value) => {
    const data = convertiFilterData(
      value,
      demandeStatus,
      ['name', 'shortName'],
      '_id'
    );
    if (value) {
      return data && data.length
        ? { status: { $in: data } }
        : { status: D_REF, raisonRefus: { $regex: value, $options: 'i' } };
    }
    return null;
  },
  firstCall: (value) => firstCallFilters(value),
  appel: (value) => ({
    1: {
      'login.miseEnRelation': { $exists: false },
      'communcations.duration': { $gt: MIN_DURATION },
    },
    2: {
      'login.miseEnRelation': { $exists: false },
      'communcations.duration': { $not: { $gt: MIN_DURATION } },
    },
    3: {
      'login.miseEnRelation': { $exists: true },
      communcations: {
        $elemMatch: {
          duration: { $gt: MIN_DURATION },
          type: { $ne: REPONDEUR }
        }
      }
    },
    4: {
      'login.miseEnRelation': { $exists: true },
      communcations: {
        $not: {
          $elemMatch: {
            duration: { $gt: MIN_DURATION },
            type: { $ne: REPONDEUR }
          }
        },
      }
    },
  }[value] || {})
};
