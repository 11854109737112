import React, { PureComponent } from 'react';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Immutable from 'immutable';
import {
  KeyboardArrowRight,
  KeyboardArrowDown
} from '@material-ui/icons';
import {
  Link
} from 'react-router-dom';
class ListButtons extends PureComponent {
  render() {
    const {
      data,
      classes,
      users,
      open,
      userId,
      genCounter
    } = this.props;
    if (data.link) {
      return (<Link to={data.link}
        style={{ display: 'inherit', textDecoration: 'none' }}>
        <ListItemIcon className={classes.ListIcon}>
          {data.icon && <data.icon className={classes.svg} />}
        </ListItemIcon>
        <ListItemText primary={data.name} className={classes.linkTxt}
          classes={{ primary: classes[data.bold ? 'bold' : 'text'] }}
        />
        {data.count && users instanceof Immutable.List && userId &&
          genCounter(classes, data)
        }
        {data.childs && <ListItemIcon className={classes.ListIcon}>
          {open ?
            <KeyboardArrowDown
              style={{ paddingRight: 3, marginRight: 0,
                color: 'white', height: 20, width: 20 }}/> :
            <KeyboardArrowRight
              style={{ paddingRight: 3, marginRight: 0,
                color: 'white', height: 20, width: 20 }}/>}
        </ListItemIcon>}
      </Link>);
    }
    return [<ListItemIcon key={1} className={classes.ListIcon}>
      {data.icon && <data.icon className={classes.svg} />}
    </ListItemIcon>,
    <ListItemText key={2} primary={data.name} className={classes.linkTxt}
      classes={{ primary: classes[data.bold ? 'bold' : 'text'] }}
    />,
    data.count && users instanceof Immutable.List && userId &&
      genCounter(classes, data),
    data.childs && <ListItemIcon key={3} className={classes.ListIcon}>
      {open ?
        <KeyboardArrowDown
          style={{ paddingRight: 3, marginRight: 0,
            color: 'white', height: 20, width: 20 }}/> :
        <KeyboardArrowRight
          style={{ paddingRight: 3, marginRight: 0,
            color: 'white', height: 20, width: 20 }}/>}
    </ListItemIcon>];
  }
}

export default ListButtons;
