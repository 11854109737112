import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux';
import { setDialogContentProp } from '../../actions/dialog';
import { data as categories } from '@bit/dev-lba.lib.local-globals/categories';
import CustomSelect from '../CustomInputs/CustomSelect';
import MenuItem from '@material-ui/core/MenuItem';
const setCategorie = setDialogContentProp('categorie');
const setZipCode = setDialogContentProp('zipCode');
const setTel = setDialogContentProp('tel');

const mapStateToProps = ({
  dialog: {
    contentProps: { tel, categorie, zipCode },
  },
  users,
}) => ({
  tel,
  users,
  categorie,
  zipCode,
});

const mapDispatchToProps = {
  setTel,
  setCategorie,
  setZipCode
};

const DemandePhoto = ({
  tel,
  zipCode,
  categorie,
  setTel,
  setCategorie,
  setZipCode
}) => (
  <Grid container spacing={4} justify="center" alignItems="flex-end">
    <Grid item xs={6}>
      <CustomSelect
        grid
        xs={12}
        label="Catégorie"
        value={categorie}
        setData={(p, v) => setCategorie(v)}
      >
        {categories.map((e, i) =>
          <MenuItem
            key={i}
            value={e.name}
            children={e.name}
          />
        )}
      </CustomSelect>
    </Grid>
    <Grid item xs={6}>
      <TextField
        type={'number'}
        label="Code postal"
        onChange={(e) => setZipCode(e.target.value)}
        fullWidth
        value={zipCode}
      />
    </Grid>
    <Grid item xs={12}>
      <TextField
        type={'number'}
        label="Numéro du client"
        onChange={(e) => setTel(e.target.value)}
        fullWidth
        value={tel}
      />
    </Grid>
  </Grid>
);

export default connect(mapStateToProps, mapDispatchToProps)(DemandePhoto);
