import React, { Component } from 'react';

import { Grid } from '@material-ui/core';
import Calls from './Calls';
import TabsWithCounter from '../Utils/TabsWithCounter';

class CallToAndBack extends Component {
  state={ tab: 0 }

  handleChangeTab = (event, tab) => this.setState({ tab });

  getElemFiltered = (tab) => {
    const { elem } = this.props;
    return {
      0: elem.filter((e) => !e.callTo),
      1: elem.filter((e) => e.callTo)
    }[tab];
  }

  render() {
    const { sm } = this.props;
    const { tab } = this.state;
    return (
      <Grid item xs={12} sm={sm || 4}>
        <TabsWithCounter
          value={tab}
          centered
          indicatorColor="primary"
          noAppBar
          variant="standard"
          textColor="primary"
          onChange={this.handleChangeTab}
          values={[
            { name: 'CALL TO', counter: this.getElemFiltered(0).length },
            { name: 'CALL BACK', counter: this.getElemFiltered(1).length },
          ]}
        />
        <Calls
          sm={12}
          elem={this.getElemFiltered(tab)}
          keyUrl="urlEnregistrement"
        />
      </Grid>
    );
  }

}


export default CallToAndBack;
