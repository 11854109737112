import { data as categories } from '@bit/dev-lba.lib.local-globals/categories';
import { needToComment } from '@bit/dev-lba.lib.local-globals/demandeStatus';
import api from '../api';
import notifSystem from '../notifSystem';
import store from '../store';
import { setDialog, setDialogComment } from './dialog';
import { call } from '../utils/call';
import IphoneDialog from '../components/Dialogs/IphoneDialog';
import SMSDialog from '../components/Dialogs/SMSDialog';
import ConfirmBox from '../components/Dialogs/ConfirmBox';
import { moment } from '@bit/dev-lba.lib.local-globals/moment';
import { generateTime } from '../utils/date';
import { createGoogleCalendar } from './googleCalendar';
import { RE_1 } from '@bit/dev-lba.lib.local-globals/grandCompteConstants';
import {
  DINTER,
  DDEV,
} from '@bit/dev-lba.lib.local-globals/docTypes';
import company from '@bit/dev-lba.lib.local-globals/company';
import collections from '../constants/collections';

const relanceClient = (
  { demandeId, clientTel, clientName, smsContent, clientEmail },
  mode = 'sms'
) => {
  const action = mode === 'sms' ? 'SMS' : 'Mail';
  return api.demandes
    .custom(`relance/${demandeId}`)
    .post({
      id: demandeId,
      clientTel,
      clientName,
      smsContent,
      mode,
      clientEmail,
    })
    .then(() =>
      notifSystem.success(`${action} envoyé`, `Le ${action} a bien été envoyé`)
    )
    .catch(() =>
      notifSystem.error('Erreur', `Le ${action} n'a pas pû être envoyé`)
    );
};

export const changeStatus = (elem, status, userId) =>
  api
    .demandes
    .patch(elem._id, {
      status, login: {
        ...(!elem?.login?.ajout ? { ajout: userId } : {}),
        modification: userId
      }
    }
    )
    .then(() => notifSystem.success(
      'Opération réussie',
      'Le statut a bien été modifié'
    ))
    .catch(() => notifSystem.error(
      'Erreur',
      'Le statut n\'a pas pû être modifié'
    ));

export const commentDemande = (id, commentValue) =>
  api
    .demandes
    .custom(`comment/${id}`)
    .patch({ commentValue })
    .then(() => notifSystem.success(
      'Opération réussie',
      'Le commentaire a bien été ajouté'
    ))
    .catch(() => notifSystem.error(
      'Erreur',
      'Le commentaire n\'a pas pû être ajouté'
    ));

const changeRefusReason = ({ id, selected, userId, status, raisonRefus }) =>
  api.demandes.patch(
    id,
    { status, raisonRefus, login: {
      ...(!selected?.login?.ajout ? { ajout: userId } : {}),
      modification: userId
    } }
  ).then(() => notifSystem.success(
    'Opération réussie',
    'Le statut a bien été modifié'
  )).catch(() => notifSystem.error(
    'Erreur',
    'Le statut n\'a pas pû être modifié'
  ));

const dialogRaison = (cb, contentProps, dialogProps = {}) =>
  store.dispatch(
    setDialogComment(
      ({ text }, close) => {
        if (!text || (text && !text.trim())) {
          return notifSystem.error('Erreur', 'Refus vide');
        }
        if (cb) {
          cb(text);
        }
        close();
      },
      contentProps,
      dialogProps,
      'Ajouter'
    )
  );
export const displayRelanceDialog = (mode = 'sms', args) => ({
  setDialog: setDialogFromProps, selected, user
}) => {
  const categorie = selected.choice.toLowerCase();
  const num = selected.source === 'STAROFLBA'
    ? company.lines.starOfLba
    : user.ligne;
  const smsContent =
      'Bonjour,\n\nJ\'ai tenté de vous joindre à plusieurs reprises\n' +
      `pour votre problème de ${categorie}.\n` +
      `Vous pouvez me joindre au ${num}\n\n` +
      `${user.alias}`;
  return mode === 'sms'
    ? setDialogFromProps(IphoneDialog, true, { text: smsContent },
      (message) => {
        if (message) {
          relanceClient({
            demandeId: selected._id,
            clientTel: selected.client.tel1,
            clientName: selected.client.fullName,
            smsContent: message
          }, 'sms');
        }
        setDialogFromProps(null, false, null, null);
      })
    : dialogRaison(
      (text) => {
        changeRefusReason({
          id: selected._id,
          selected,
          userId: user._id,
          ...args
        });
        relanceClient({
          demandeId: selected._id,
          clientEmail: selected.client.email1,
          clientName: selected.client.fullName,
          smsContent: text
        }, 'mail');
      },
      {
        text: smsContent,
        placeholder: 'Entrez le mail de relance'
      },
      { title: 'Mail de relance' }
    );
};

export const callChangeStatus = (status, raisonRefus, mode = 'sms') =>
  ({ setDialog, selected, userId, user }) => {
    if (mode === 'mail') {
      if (selected.client.email1) {
        return displayRelanceDialog(mode, { status, raisonRefus })({
          setDialog,
          selected,
          user
        });
      }
    }
    else if (!raisonRefus && needToComment.includes(status)) {
      return dialogRaison(
        (text) => changeRefusReason({
          id: selected._id,
          userId,
          status,
          raisonRefus: text
        }),
        { placeholder: 'Entrez la raison du refus...' },
      );
    }
    return changeRefusReason({ id: selected._id, userId, status, raisonRefus });
  };


export function callAppel({ selected, user }) {
  Promise.all([
    api.appels.custom('add').post({
      demandeId: selected._id,
      number: selected.client.tel1,
      companyName: selected.client.firstname,
      data: {
        userLogin: user.login,
        userLigne: user.ligne,
        userAlias: user.alias,
        userEmail: user.email,
        choice: selected.choice,
        source: selected.source,
        status: selected.status,
        externalId: selected.externalId,
      },
    }),
    !selected.date.firstCall &&
      api.demandes.patch(selected._id, { date: { firstCall: new Date() } }),
  ])
    .then(() => call(selected.client.tel1))
    .catch((e) => notifSystem.error(e.name, e.message));
}

export function cancelDemande({ selected, ...props }) {
  function cancelCallback(obj) {
    props.setDialog(null, false, '', null);
    if (obj) {
      api.demandes
        .delete(selected._id)
        .then(() => notifSystem.success(
          'Opération réussie',
          'La demande a bien été supprimée'
        ))
        .catch(() => notifSystem.error(
          'Erreur',
          'La demande n\'a pas pû être supprimée'
        ));
    }
  }

  props.setDialog(
    ConfirmBox,
    true,
    {
      title: 'Etes-vous sûr de vouloir supprimer la demande ?',
      confirmBox: 'Oui',
      closeBox: 'Annuler',
    },
    cancelCallback
  );
}

export const transferTo = (redirectTo, newTab = false) => ({
  selected,
  history
}) => {
  const idExist = ((selected || {})[redirectTo] || {}).id;
  if (idExist) {
    if (!newTab) {
      return history.push(`/${redirectTo}/${idExist}`);
    }
    return window.open(`/${redirectTo}/${idExist}`);

  }
  const categorie =
    (
      categories.find(
        (c) =>
          selected.categorie === c._id ||
          (c.match).test(selected.choice)
      ) || {}
    )._id || null;
  const name = {
    intervention: 'Inter',
    devis: 'Devis'
  };
  const data = {
    date: {
      ajout: new Date(),
      intervention: generateTime(new Date()),
    },
    demande: {
      id: selected._id,
      source: selected.source,
      externalId: String(selected.externalId),
      pdfName: selected.pdfName || '',
      ...(selected.statusSignature
        ? { statusSignature: selected.statusSignature }
        : {}),
    },
    client: {
      civility: selected.client.civility || '',
      name: selected.client.name || selected.client.fullName,
      firstname: selected.client.firstname || '',
      society: selected.client.society || '',
      tel1: selected.client.tel || selected.client.tel1,
      tel2: selected.client.tel2 || '',
      tel3: selected.client.tel3 || '',
      email1: selected.client.email1 || '',
      email2: selected.client.email2 || '',
      address: {
        number: selected.client.number || selected.client.housenumber || '',
        road: selected.client.road || '',
        zipcode: selected.client.zipCode || '',
        city: selected.client.city || '',
        location: {
          type: 'Point',
          coordinates: selected.client.location?.coordinates || [],
        },
      },
      code: selected.client.code || '',
      compl: selected.client.compl || '',
      building: selected.client.building || '',
      floor: selected.client.floor || '',
      ...(selected.client.grandCompte ?
        { grandCompte: selected.client.grandCompte } : {})
    },
    infoDesc: {
      desc: selected.description || '',
    },
    categorie: selected.categorie || categorie,
    message: selected.choice,
    login: {
      authorisedArtisan: selected.artisan,
    },
    createdFrom: selected?.createdFrom?.id ? {
      ...selected.createdFrom,
      path: [
        ...selected?.createdFrom?.path || [],
        {
          'collection': collections.demandes,
          _id: selected._id,
          duplicate: false
        }
      ]
    } : {},
    maxHour: (selected.date || {}).maxHour,
    flexHour: !!selected.flexHour,
    flexDate: !!selected.flexDate,
    definHClient: !!selected.definHClient,
    definDClient: !!selected.definDClient,
    onSitePayment: 'onSitePayment' in selected ? selected.onSitePayment : true,
    description: selected.description || '',
    context: selected.context || '',
    consignes: selected.consignes || '',
    products: {
      list: selected.products || [],
      tva: 10,
    },
    billing: selected.billing || {},
    remindFacture: selected?.billing?.remindDelay === RE_1,
    dropbox: (selected.dropbox || []).map((e) => ({
      ...e,
      type: {
        devis: DDEV,
        intervention: DINTER,
      }[redirectTo],
    })),
    comments: selected.comments || [],
    finalPrice: selected.finalPrice || 0,
  };
  if (!newTab) {
    return history.push({
      pathname: `/${redirectTo}`,
      state: {
        market: data
      },
    });
  }
  localStorage.setItem(
    `tmpData${name[redirectTo]}_new`,
    JSON.stringify(data)
  );
  return window.open(redirectTo);
};

export function showSMS(props, demande) {
  props.setDialog(SMSDialog, true, { demandeId: demande.id }, () =>
    props.setDialog(null, false, '', null)
  );
}


export function getAllSourceOfDemande() {
  return api.demandes
    .custom('sources')
    .get()
    .then((e) => e.body().data().resultat)
    .catch(() => notifSystem.error('Erreur', 'Récupération des sources'));
}

export const createReminder = (demandeId, comment, date) =>
  api.demandes.custom(`addReminder/${demandeId}`).post({
    comment: comment.trim(),
    date,
  });

export const reminderAction = status => ({ selected, userId }) => {
  const title = `Rappel concernant la demande pour ${selected.client.fullName}`;
  store.dispatch(setDialog({
    name: 'ReminderDialog',
    open: true,
    dialogProps: {
      title
    },
    actions: [
      {
        children: 'Enregistrer',
        color: 'primary',
        onClick: ({ date = new Date() }, close) => {
          createGoogleCalendar(
            date,
            title,
            `Demande pour: ${selected.client.fullName}\n` +
              `Numéro de téléphone: ${selected.client.tel1}`);
          Promise.all([
            createReminder(
              selected._id,
              `Rappeler le ${moment(date).format('LLL')}`,
              new Date(date).getTime()
            ),
            changeStatus(selected, status, userId, date)
          ]);
          close();
        },
      }
    ]
  }));
};

export const sendGCDemandeSign = (interId) => {
  api.demandes.custom('demandeSign').post({ interId })
    .then(e => (e.statusCode() === 202) ?
      notifSystem.warning('⚠ Document signé',
        'Le client a déjà signé le document') :
      notifSystem.success('Demande signature',
        'La demande a bien été envoyé')
    );
};

export const sendMail = (demande, userSelected) => api.demandes
  .custom(`sendMail/${demande._id}`)
  .post({ userSelected })
  .then(() => notifSystem.success('Mail envoyé', 'Le Mail a bien été envoyé'))
  .catch(() => notifSystem.error('Erreur', 'Le Mail n\'a pas pû être envoyé'));

export const sendNoResponseMail = (demande) => api.demandes
  .custom('sendNoResponseMail')
  .post({ demande })
  .then(() => notifSystem.success('Mail envoyé', 'Le Mail a bien été envoyé'))
  .catch(() => notifSystem.error('Erreur', 'Le Mail n\'a pas pû être envoyé'));
