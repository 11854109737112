import React, { PureComponent } from 'react';
import Avatar from '@material-ui/core/Avatar';
import { withStyles } from '@material-ui/core/styles';
import api from '../../api';
import notifSystem from '../../notifSystem';
import { DLUSER } from '@bit/dev-lba.lib.local-globals/docTypes';

const styles = () => ({
  avatarText: {
    fontSize: 30,
    fontWeight: '300',
    cursor: 'pointer',
  },
  divStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  }
});

class CustomAvatar extends PureComponent {
  state = {
    image: ((this.props.user || {}).dropbox || {}).length
      ? `${this.props.user.dropbox[0].link}`
      : '',
  };

  AddFile = e => {
    const { user } = this.props;
    const file = e.target.files[0];
    const data = new FormData();
    let name = file.name;
    data.append('file', file);
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      e.target.value = '';
      reader.onload = async () => {
        try {
          const res = await api.dropbox.custom('upload').post({
            type: DLUSER,
            fileName: name,
            file: reader.result,
            baseName: `logo-${user._id}`,
            underscoreId: user._id,
            path: 'users',
          });
          if (res.body().data().status === 'OK') {
            notifSystem.success('Upload', 'La photo a bien été ajoutée');
            return this.setState({ image: reader.result });
          }
          notifSystem.error('Erreur', 'La photo n\'a pas pu être ajouté');
        } catch (e) {
          notifSystem.error('Erreur', 'La photo n\'a pas pu être ajouté');
        }
      };
    }
  };

  render() {
    const { image } = this.state;
    const { classes, user, upload, size } = this.props;

    return (
      <div className={classes.divStyle}>
        {upload && (
          <input
            type="file"
            style={{ display: 'none' }}
            id="logo"
            onChange={this.AddFile}
          />
        )}
        <label htmlFor="logo">
          <Avatar
            src={image}
            className={image ? classes.avatar : classes.avatarText}
            style={{ width: size, height: size }}
            imgProps={{
              style: {
                objectFit: 'contain',
                cursor: 'pointer',
              },
            }}
          >
            {!image &&
              `${user && user.login.charAt(0)}${user &&
                user.login.substr(user.login.length - 1)}`.toUpperCase()}
          </Avatar>
        </label>
      </div>
    );
  }
}

export default withStyles(styles)(CustomAvatar);
