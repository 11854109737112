import React, { Component } from 'react';
import { compose } from 'recompose';
import { moment } from '@bit/dev-lba.lib.local-globals/moment';

import withWidth from '@material-ui/core/withWidth';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import { S_INT, S_LEAD } from '@bit/dev-lba.lib.local-globals/services';
import { ADMIN } from '@bit/dev-lba.lib.local-globals/permissions';

import RHStats from '../../components/Stats/RH/';
import ChangePanel from '../../components/Stats/Objectifs/popup/change';
import { UserCard, StatsCalls } from '../../components/Stats';
import Planning from '../../components/Stats/SchudleStats/planning';
import UserHolidaysConfig from '../../components/Users/holidays';
import ObjectifsContainer from './utils/ObjectifsContainer';

const styles = (theme) => ({
  displayNoneRWD: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  conditionalDisplayNoneRWD: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    [theme.breakpoints.between('md', 'lg')]: {
      display: 'block',
    },
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
    },
  },
  displayFlex: {
    display: 'flex',
  },
  displayPersonnalComponent: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
});

class RHDashboard extends Component {
  state = {
    mode: 'day',
    data: [],
    startAt: moment().startOf('day').toDate(),
    endAt: moment().endOf('day').toDate(),
    service: S_INT,
    route: 'commercial',
    selectedUser: this.props.allUsers.find((u) =>
      [S_INT, S_LEAD].includes(u.service)
    )._id,
  };

  handleOpen = (open) => {
    this.setState({ panelOpen: open });
  };

  render() {
    const { route, panelOpen, goals } = this.state;
    const { user, users, classes, allUsers } = this.props;

    const isCommercial = route === 'commercial';

    return (
      <Grid container spacing={2}>
        <ChangePanel
          open={panelOpen}
          handleOpen={this.handleOpen}
          dataKey={isCommercial ? 'commercialGoals' : 'supportGoals'}
          goals={goals || {}}
          user={user}
        />
        <Grid container spacing={2} item xs={12}>
          <Grid
            item
            md={12}
            lg={4}
            className={classes.conditionalDisplayNoneRWD}
            children={UserCard && <UserCard user={user} />}
          />
          <Grid item md={12} lg={8}>
            <ObjectifsContainer user={user} personal allUsers={allUsers} />
          </Grid>
        </Grid>
        <Grid container spacing={4} item xs={12}>
          <Grid item xs={6} sm={6} md={6}>
            <StatsCalls user={user} users={users} />
          </Grid>
          <Grid item xs={6} sm={6} md={6} className={classes.displayNoneRWD}>
            <Planning SchudleStatsData={user} />
          </Grid>

          <Grid item xs={12}>
            <UserHolidaysConfig />
          </Grid>
          {user.permission === ADMIN && (
            <Grid item xs={12}>
              <RHStats
                users={users.filter(
                  (u) => [S_INT, S_LEAD].includes(u.service) && u.act
                )}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  }
}

export default compose(withStyles(styles), withWidth())(RHDashboard);
