import React from 'react';
import ReactEcharts from 'echarts-for-react';

import Dropdown from '../common/dropdown/dropdown';
import StatsPanel from '../common/panel/StatsPanel';
import { moment } from '@bit/dev-lba.lib.local-globals/moment';
import api from '../../../api';
import { Grid } from '@material-ui/core';
import { getOption } from '../common/graph/options/globalOptions';
import { modeIndex, modes } from '@bit/dev-lba.lib.local-globals/stats';
import { setDialog } from '../../../actions/dialog';
import { I_VRF } from '@bit/dev-lba.lib.local-globals/iStatus';
import Button from '@material-ui/core/Button';
import {
  Money,
  MoneyOff
} from '@material-ui/icons';

import {
  SOCIETE,
  CURATELLE,
  AGENCE_IMMO,
} from '@bit/dev-lba.lib.local-globals/statusClient';

const datesOptions = [
  {
    _id: 'date.ajout',
    name: 'Ajout',
  },
  {
    _id: 'date.intervention',
    name: 'Intervention',
  },
  {
    _id: 'date.envoi',
    name: 'Envoi',
  },
  {
    _id: 'date.verif',
    name: 'Verif',
  },
];

export const formatterEchartLabel = ({
  values,
  data,
  ratioKey,
  keysRationToDisplay = [],
}) =>
  values
    .map((e) => {
      const ratioValue = data[ratioKey(e.seriesName)];
      return [
        `${e.seriesName}: ${e.value.toFixed(2)} ${
          keysRationToDisplay.includes(e.seriesName) && ratioValue
            ? `(${ratioValue[e.dataIndex] ? (
              (e.value / ratioValue[e.dataIndex]) * 100
            ).toFixed(2) : 0}%)`
            : ''
        }`,
      ];
    })
    .join('<br/>');

const getStats = (query) =>
  api.stats
    .get('recouvrement/ratio', query)
    .then((res) => res.body().data());

const filters = {
  Société: {
    isRegle: false,
    $or: [
      { 'client.civility': 'Société' },
      { 'billing.clientStatus': { $in: [SOCIETE, AGENCE_IMMO, CURATELLE] } },
    ],
    'billing.grandCompte': { $exists: false },
    status: I_VRF,
  },
  Btob: {
    'billing.grandCompte': { $exists: true },
    isRegle: false,
    status: I_VRF,
  },
  Particulier: {
    'client.civility': { $ne: 'Société' },
    'billing.clientStatus': { $nin: [SOCIETE, AGENCE_IMMO, CURATELLE] },
    'billing.grandCompte': { $exists: false },
    isRegle: false,
    status: I_VRF,
  },
};

class DisplayPartenariatRatio extends React.Component {
  state = {
    from: moment().startOf('week').toDate(),
    to: moment().endOf('week').toDate(),
    date: 'date.ajout',
    data: {},
    mode: 'week',
    display: true,
  };
  componentDidMount() {
    this.doRequests();
  }
  onDatesChange = (from, to, mode) =>
    this.setState(
      {
        from,
        to,
        mode,
      },
      this.doRequests
    );

  handleDialogOpen = ({ dataIndex, seriesName }) => {
    const { mode, date, from } = this.state;
    const { dispatch } = this.props;
    let typeMode = modes[mode];
    let index = Object.keys(modeIndex[mode]).find(
      (e) => modeIndex[mode][e] === dataIndex
    );
    if ((typeMode === 'day' && mode === 'week') || typeMode === 'month') {
      index = index - 1;
    } else if (typeMode === 'day') {
      typeMode = 'date';
    }
    const data = {
      ...filters[seriesName],
      [date]: {
        $gt: moment(from).set(typeMode, index).startOf(typeMode).toDate(),
        $lt: moment(from).set(typeMode, index).endOf(typeMode).toDate(),
      },
    };
    return dispatch(
      setDialog({
        name: 'InterListDialog',
        open: true,
        hideClose: true,
        dialogProps: {
          title: 'Liste d\'intervention',
          maxWidth: 'lg',
        },
        contentProps: {
          filter: data,
        },
        actions: [
          {
            children: 'Fermer',
            color: 'secondary',
            onClick: (r, close) => close(),
          },
        ],
      })
    );
  };

  doRequests = () => {
    const { from, to, mode, date, display } = this.state;
    getStats({
      mode,
      isCA: display ? 1 : 0,
      from,
      to,
      date,
    }).then((data) => {
      this.setState({
        data,
      });
    });
  };

  render() {
    const { from, to, date, mode, data, display } = this.state;
    return (
      <>
        <StatsPanel
          title={'Ratio service recouvrement'}
          onDatesChange={this.onDatesChange}
          enabledModes={[true, true, true, true]}
          defaultState={{
            mode,
            from,
            to,
          }}
          body={
            <>
              <Dropdown
                title={'Dates'}
                data={datesOptions}
                isSelected={(e) => date === e}
                onChange={(e) =>
                  this.setState({ date: e.target.value }, this.doRequests)
                }
                multiple={false}
              />
              <Button
                onClick={() =>
                  this.setState({ display: !display }, this.doRequests)
                }
                variant="outlined"
                color={display ? 'primary' : 'secondary'}
              >
                {display ? <Money /> : <MoneyOff />}
              </Button>
            </>
          }
        >
          <Grid container>
            <Grid item xs={12}>
              {
                <ReactEcharts
                  notMerge={true}
                  style={{ width: '100%' }}
                  option={getOption({
                    title: 'Reçu',
                    formatter: (values) =>
                      formatterEchartLabel({
                        values,
                        data,
                        ratioKey: () => 'Reçu',
                        keysRationToDisplay: Object.keys(data),
                      }),
                    data,
                    mode,
                    labels: Object.keys(data),
                    dates: [moment(from).startOf(mode), moment(to).endOf(mode)],
                  })}
                  onEvents={{ click: this.handleDialogOpen }}
                />
              }
            </Grid>
          </Grid>
        </StatsPanel>
      </>
    );
  }
}

export default DisplayPartenariatRatio;
