import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import { Chip, IconButton, Grid, Typography } from '@material-ui/core';
import { CloudUpload } from '@material-ui/icons';

import { attachFile } from '@bit/dev-lba.lib.local-globals/utils';
import { grey } from '@material-ui/core/colors';

import {
  MAX_FILES,
  MAX_FILES_SIZE,
  isMaxSizeOk,
  isMaxFileOk
} from '../../utils/function';
import notifSystem from '../../notifSystem';

const styles = {
  input: { display: 'none' },
  emptyData: {
    color: grey[500]
  }
};

const Attachments = ({
  classes,
  data = [],
  onAdd,
  onDelete,
  maxFiles = MAX_FILES,
  maxFilesSize = MAX_FILES_SIZE,
  disableAll,
  inputProps,
  hideChips,
  name = ''
}) => {
  const add = e => {
    attachFile(e.target.files[0])
      .then(file => {
        const files = data.concat(file);

        if (!isMaxSizeOk(files, maxFilesSize)) {
          return notifSystem.error(
            'Erreur',
            'La taille totale dépasse la limite autorisée'
          );
        }
        if (!isMaxFileOk(files.length, maxFiles)) {
          return notifSystem.error(
            'Erreur',
            'Le nombre de fichiers dépasse la limite autorisée'
          );
        }
        onAdd(file, files);
      })
      .catch(e => notifSystem.error(
        'Erreur',
        e.message
      ));
  };

  return <Grid container spacing={1}>
    {!hideChips ? <Grid item xs={10}>
      {data && data.length ? data.map((d, n) =>
        <Chip
          key={n}
          label={d.name}
          onDelete={() => !disableAll &&
            onDelete(d, n, data.delete((e, i) => i === n))}
        />
      ) : <Typography
        className={classes.emptyData}
        children="Pièces jointes"
      />}
    </Grid> : null}
    <Grid item xs={2}>
      <input
        className={classes.input}
        id={`add-attachment${name}`}
        type="file"
        onChange={add}
        {...inputProps}
      />
      <label htmlFor={`add-attachment${name}`}>
        <IconButton
          component="span"
          color='primary'
          disabled={disableAll}
          children={<CloudUpload />}
        />
      </label>
    </Grid>
  </Grid>;
};

export default withStyles(styles)(Attachments);
