import { moment } from '@bit/dev-lba.lib.local-globals/moment';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import { data as modStatus } from '@bit/dev-lba.lib.local-globals/moStatus';

import { withAPISubscription, withPagination, withList } from '../../hoc';
import { genChip } from '../../utils/chips';
import ModificationsRowContent from './ModificationsRowContent';
import {
  acceptModification,
  declineModification
} from '../../actions/modifications';

const mapStateToProps = ({ users }) => ({ users });

const mapDispatchToProps = (d, { collection }) => ({
  acceptModification: (id, dataId, data, reload) =>
    acceptModification(id, collection, dataId, data)
      .then(() => reload()),
  declineModification: (id, reload) => declineModification(id)
    .then(() => reload())
});

const columns = [
  'ID',
  'Date de création',
  'Utilisateur',
  'Statut'
];

const keys = [
  'id',
  'date',
  'user',
  'status'
];

const formatModification = (
  { _id, data = {}, date, login, modifications, status },
  { schema, users }
) => ({
  _id,
  id: data.id,
  dataId: data._id,
  date: new Date(date.ajout),
  user: users.find(u => u._id === login.ajout),
  status,
  modifications: schema(modifications),
  changes: modifications
});

const displayers = () => ({
  date: v => moment(v).format('L'),
  user: v => v ? v.login : 'indisponible',
  status: v => genChip(modStatus.find(s => s._id === v)),
});

const fetch = (api, { collection, schema, users }) =>
  api.modifications
    .getAll({
      query: JSON.stringify({ collection }),
    })
    .then(res => res.body().map(e =>
      formatModification(e.data(), { schema, users })
    ));

const actions = [{ action: 'new_modifications' }];

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withAPISubscription(fetch, actions, { default: [] }),
  withPagination({ rowsPerPage: 100, sortBy: 'date' }),
  withList(
    { keys, columns },
    displayers,
    { RowContent: ModificationsRowContent }
  )
)(({ children }) => children);
