import React, { PureComponent } from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import PropTypes from 'prop-types';
import { green, red, grey } from '@material-ui/core/colors';
import iStatus from '@bit/dev-lba.lib.local-globals/iStatus';
import {
  Remove,
  TrendingUp,
  TrendingDown,
} from '@material-ui/icons';
const styles = theme => ({
  iconCard: {
    padding: theme.spacing(1) * 3.5,
    display: 'flex',
    alignItems: 'center',
  },
  details: {
    display: 'flex',
  },
  card: {
    display: 'flex',
    minWidth: 100,
  },
  text: {
    color: 'rgb(141, 126, 119)',
    fontSize: 16,
    fontWeight: '500',
  },
  content: {
    flex: '1 0 auto',
  },
  IconTitle: {
    fontSize: 30,
    fontWeight: 'bold',
    color: '#FAFAFA'
  },
  title: {
    display: 'flex',
    justifyContent: 'space-evenly'
  },
  titleText: {
    fontSize: '2rem'
  },
  icon: {
    margin: theme.spacing(1),
  }
});

class CardIcon extends PureComponent {
  constructor(props) {
    super(props);
    this.getIcon = this.getIcon.bind(this);
    this.getColor = this.getColor.bind(this);
  }

  getIcon(value) {
    if (value === 0) {
      return ( <Remove style={{ color: grey[500], fontSize: 36 }} />);
    }
    return (value > 0 ?
      <TrendingUp style={{ fontSize: 36, color: green[500] }} /> :
      <TrendingDown style={{ fontSize: 36, color: red[500] }} />
    );
  }

  getColor(value) {
    if (value === 0) {
      return grey[500];
    }
    return (value > 0 ? green[500] : red[500]);
  }

  render() {
    const { classes, cardStats } = this.props;
    return iStatus.data.map((item, index) => index !== 1 && (
      <Grid item xs key={index}>
        <Card className={classes.card}>
          <Paper style={{ backgroundColor: item.color[500] }}
            className={classes.iconCard}>
            <Typography className={classes.IconTitle} color="textSecondary">
              {item.shortName}
            </Typography>
          </Paper>
          <div className={classes.details}>
            <br />
            <CardContent className={classes.content}>
              <div className={classes.title}>
                { this.getIcon(cardStats && cardStats[item.shortName] &&
                cardStats[item.shortName].hasOwnProperty('porcentage')
                  ? cardStats[item.shortName].porcentage : 0) }
                <Typography className={classes.titleText}
                  style={{ color:
                    this.getColor(cardStats && cardStats[item.shortName] &&
                      cardStats[item.shortName].hasOwnProperty('porcentage') ?
                      cardStats[item.shortName].porcentage : 0) }}
                  variant="h6" align='right'>
                  {(cardStats && cardStats[item.shortName] &&
                    cardStats[item.shortName].porcentage.toFixed()) || 0}%
                </Typography>
              </div>
              <br />
              <Typography className={classes.text}
                align="center" variant="body1">
                {`${(cardStats && cardStats.dateLastWeek) || 0} : `}
                {`${(cardStats && cardStats[item.shortName] &&
                  cardStats[item.shortName].lastWeek) || 0}`}</Typography>
              <Typography className={classes.text}
                align="center" variant="body1">
                Aujourd'hui
                {` ${(cardStats && cardStats[item.shortName] &&
                  cardStats[item.shortName].today) || 0}`}</Typography>
            </CardContent>
          </div>
        </Card>
      </Grid>
    ) );
  }
}

CardIcon.propTypes = {
  classes: PropTypes.object.isRequired,
  cardStats: PropTypes.object.isRequired
};

export default withStyles(styles)(CardIcon);
